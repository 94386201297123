export default (error) ->
  console.log(error)
  try
    fetch(
      Routes.proxy_notify_error_path()
      method: 'POST'
      body: JSON.stringify(message: error.message, payload: JSON.stringify(error, Object.getOwnPropertyNames(error)))
      headers:
        'X-Requested-With': 'XMLHttpRequest'
        'Content-Type': 'application/json'
        'Accept': 'application/json'
        'Authorization': "Bearer #{Globals.projectToken}"
    )
