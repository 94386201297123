
  export default
    props:
      config: Object
      styles: Object

    data: ->
      state: 'open'
      minifiedTeaserButtonHover: false
      openTeaserButtonHover: false
      postbackButtonsHover: []
      quickreplyButtonsHover: []

    computed:
      rootStyles: ->
        fontFamily: @styles.bodyFont
        position: 'sticky'
        top: '7rem'
      chatWidgetStyles: ->
        position: 'relative'
        bottom: 0
        right: 0
        maxHeight: 'none'
        zIndex: 'auto'
        backgroundColor: @styles.bodyColor
        borderRadius: "#{@styles.bodyBorderRadius}px"
        boxShadow: "#{@styles.bodyShadowColor} 1px 1px #{@styles.bodyShadowSize}px 0px"
      headerStyles: ->
        background: @styles.headerColor
      brandStyles: ->
        backgroundImage: "url(\"#{@config.logoUrl}\")"
      avatarStyles: ->
        return {} if !@config.avatarUrl
        backgroundImage: "url(\"#{@config.avatarUrl}\")"
      generalBubbleStyles: ->
        borderRadius: "#{@styles.bubbleCornerRadius}px"
        fontSize: "#{@styles.bubbleFontSize}px"
      botBubbleStyles: ->
        Object.assign({}, @generalBubbleStyles,
          backgroundColor: @styles.bubbleBotBackgroundColor
          color: @styles.bubbleBotFontColor
        )
      userBubbleStyles: ->
        Object.assign({}, @generalBubbleStyles,
          backgroundColor: @styles.bubbleUserBackgroundColor
          color: @styles.bubbleUserFontColor
        )
      agentBubbleStyles: ->
        Object.assign({}, @generalBubbleStyles,
          backgroundColor: @styles.bubbleAgentBackgroundColor
          color: @styles.bubbleAgentFontColor
        )
      knowledgeBubbleStyles: ->
        Object.assign({}, @generalBubbleStyles,
          backgroundColor: @styles.bubbleKnowledgeBackgroundColor
          color: @styles.bubbleKnowledgeFontColor
        )
      dateBubbleStyles: ->
        background: @styles.bubbleDateBackgroundColor
        color: @styles.bubbleDateFontColor
      timestampStyles: ->
        color: @styles.bubbleTimestampFontColor
      teaserStyles: ->
        position: 'relative'
        right: 0
      openTeaserStyles: ->
        backgroundColor: @styles.bubbleTeaserBackgroundColor
        color: @styles.bubbleTeaserFontColor
      openTeaserButtonStyles: ->
        Object.assign(
          borderRadius: "#{@styles.bubbleTeaserButtonCornerRadius}px"
          if @openTeaserButtonHover
            backgroundColor: @styles.bubbleTeaserButtonHoverBackgroundColor
            color: @styles.bubbleTeaserButtonHoverFontColor
          else
            backgroundColor: @styles.bubbleTeaserButtonBackgroundColor
            color: @styles.bubbleTeaserButtonFontColor
        )
      minifiedTeaserStyles: ->
        backgroundColor: @styles.bubbleTeaserBackgroundColor
        color: @styles.bubbleTeaserFontColor
      minifiedTeaserButtonStyles: ->
        Object.assign(
          borderRadius: "#{@styles.teaserButtonCornerRadius}px"
          boxShadow: "#{@styles.teaserButtonShadowColor} 0 0 #{@styles.teaserButtonShadowBlur}px 0"
          if @minifiedTeaserButtonHover
            backgroundColor: @styles.teaserButtonHoverBackgroundColor
            color: @styles.teaserButtonHoverFontColor
          else
            backgroundColor: @styles.teaserButtonBackgroundColor
            color: @styles.teaserButtonFontColor
        )
      footerStyles: ->
        display: if @config.removeFooter then 'none' else 'flex'
      toggleClosedStyles: ->
        styles =
          position: 'relative'
          top: '20px'
          bottom: 0
          right: 0
          marginLeft: 'auto'
          backgroundColor: @styles.toggleBackgroundColor
        return styles if !@config.toggleClosedUrl
        Object.assign(
          styles
          backgroundImage: "url(\"#{@config.toggleClosedUrl}\")"
        )
      toggleOpenStyles: ->
        styles =
          position: 'relative'
          top: '20px'
          bottom: 0
          right: 0
          marginLeft: 'auto'
          backgroundColor: @styles.toggleBackgroundColor
        return styles if !@config.toggleOpenUrl
        Object.assign(
          styles
          backgroundImage: "url(\"#{@config.toggleOpenUrl}\")"
        )

    methods:
      postbackStyles: (i) ->
        Object.assign(
          fontSize: "#{@styles.bubbleFontSize}px"
          if @postbackButtonsHover[i]
            backgroundColor: @styles.bubblePostbackHoverBackgroundColor
            color: @styles.bubblePostbackHoverFontColor
          else
            backgroundColor: @styles.bubblePostbackBackgroundColor
            color: @styles.bubblePostbackFontColor
        )
      quickreplyStyles: (i) ->
        Object.assign(
          boxShadow: "#{@styles.bubbleQuickShadowColor} 0 0 #{@styles.bubbleQuickShadowBlur}px 0"
          borderRadius: "#{@styles.bubbleQuickCornerRadius}px"
          fontSize: "#{@styles.bubbleFontSize}px"
          if @quickreplyButtonsHover[i]
            backgroundColor: @styles.bubbleQuickHoverBackgroundColor
            color: @styles.bubbleQuickHoverFontColor
          else
            backgroundColor: @styles.bubbleQuickBackgroundColor
            color: @styles.bubbleQuickFontColor
        )
