
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {ViewGridIcon, ChatAlt2Icon, ChatIcon, UsersIcon, ClipboardListIcon, LightningBoltIcon, FastForwardIcon, } from "@heroicons/vue/outline/esm";

export default
  props: ['menuItem']

  components:
    Popover: Popover
    PopoverButton: PopoverButton
    PopoverPanel: PopoverPanel
    ViewGridIcon: ViewGridIcon
    ChatAlt2Icon: ChatAlt2Icon
    ChatIcon: ChatIcon
    UsersIcon: UsersIcon
    ClipboardListIcon: ClipboardListIcon
    FastForwardIcon: FastForwardIcon
    LightningBoltIcon: LightningBoltIcon

  computed:
    display: ->
      @menuItem.children.some (child) -> child.display
    active: ->
      @menuItem.children.some (child) -> child.active
