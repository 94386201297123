
  import { CheckIcon, RefreshIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      status: String
      size:
        type: String
        default: 'sm'

    computed:
      statusColorClasses: ->
        {
          OK: 'bg-MercuryGreen-600'
          FAILED: 'bg-MercuryRed-500'
          UNKNOWN: 'bg-MercuryYellow-600'
          RUNNING: 'bg-gray-400'
          true: 'bg-MercuryGreen-600'
          false: 'bg-gray-400'
        }[@status]
      outerSizeClasses: ->
        switch @size
          when 'sm' then 'w-6 h-6'
          when 'lg' then 'w-8 h-8'
      innerSizeClasses: ->
        switch @size
          when 'sm' then 'w-5 h-5 text-base leading-[22px]'
          when 'lg' then 'w-6 h-6 text-xl leading-6'

    components:
      CheckIcon: CheckIcon
      RefreshIcon: RefreshIcon
      XIcon: XIcon
