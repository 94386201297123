
  import DialogModule from 'models/dialog_module.coffee'
  import Payload from 'models/payload.coffee'
  import UserIntent from 'models/user_intent.coffee'
  import WebChannel from 'models/web_channel.coffee'
  import WidgetTeaserConfig from 'models/widget_teaser_config.coffee'

  import PayloadEditor from 'components/botbuilder/nlg/payload_editor.vue'
  import ConditionalTeaserEditor from './conditional_teaser_editor.vue'
  import VMdEditor from 'v_md_editor'
  import { ActionMenu, Button, H3, Input, Label } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: WebChannel

    data: ->
      channel: @modelValue
      defaultButtonPayloadIsSet: false
      defaultButtonPayload: null
      PlusIcon: PlusIcon
      actionMenuItems: [
        {icon: XIcon, label: 'Remove', method: @remove}
      ]

    computed:
      teaserConfig: ->
        @channel.defaultLanguageTeaser

    watch:
      teaserConfig: ->
        @setDefaultButtonPayload()

    created: ->
      @setDefaultButtonPayload()

    methods:
      addTeaser: ->
        @channel.defaultLanguageTeaser = new WidgetTeaserConfig(@channel)
      setDefaultButtonPayload: ->
        return if !@teaserConfig?
        @defaultButtonPayload = new Payload(
          {dialogModule: new DialogModule(@$root.currentBot.config)}
          target:
            moduleKey: @teaserConfig.defaultButton.payload.game
            nodeKey: @teaserConfig.defaultButton.payload.intent
          slots: []
        )
        @defaultButtonPayloadIsSet = true
      updateDefaultButtonPayload: ->
        @teaserConfig.defaultButton.payload.game = @defaultButtonPayload.target.moduleKey
        @teaserConfig.defaultButton.payload.intent = @defaultButtonPayload.target.nodeKey
        @teaserConfig.defaultButton.payload.slots = @defaultButtonPayload.slots.map (slot) ->
          {label: slot.slotRole, value: slot.value}
        @update()
      update: ->
        @$emit('update:modelValue', @channel)
      removeConditionalTeaser: (index) ->
        @teaserConfig.additionalTeaserMessages.splice(index, 1)
        @update()
      remove: ->
        @channel.removeDefaultLanguageTeaser()
        @update()

    components:
      ActionMenu: ActionMenu
      Button: Button
      ConditionalTeaserEditor: ConditionalTeaserEditor
      H3: H3
      Input: Input
      Label: Label
      PayloadEditor: PayloadEditor
      VMdEditor: VMdEditor
