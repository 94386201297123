
import BotConfig from 'models/bot_config.coffee'
import DialogModule from 'models/dialog_module.coffee'
import { Badge, Button, H4 } from 'components/generic'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { PlusIcon } from '@heroicons/vue/solid'
import { ChevronRightIcon, TerminalIcon } from '@heroicons/vue/outline'

export default
  props:
    selectionMode: String
    config: BotConfig
    dialogModule: DialogModule

  emits: ['select-node', 'create-node', 'cancel']

  data: ->
    open: true
    query: ''
    selectedItem: null
    filteredItems: []
    debounceTimeout: null

  computed:
    mayCreateBoth: -> @selectionMode == 'all' && @dialogModule?
    mayCreateEither: -> @selectionMode == 'forBotIntent' || @selectionMode == 'forUserIntent'
    typeToBeCreated: ->
      switch @selectionMode
        when 'forBotIntent' then 'UserIntent'
        when 'forUserIntent' then 'BotIntent'
    creationViaEnterEnabled: ->
      @query != '' && @filteredItems.length == 0 && @mayCreateEither
    prompt: ->
      if @mayCreateEither || @mayCreateBoth
        'Search for intents or create new ones on the go ...'
      else
        'Search for intents'

  watch:
    'filteredItems.length': ->
      if @filteredItems.length == 1
        @selectedItem = @filteredItems[0]
    query: ->
      clearTimeout(@debounceTimeout)
      @debounceTimeout = setTimeout(@fetchFilteredItems, 300)

  created: ->
    return if !@dialogModule?
    @fetchFilteredItems()

  mounted: ->
    @$nextTick => @$refs.input.$el.focus()

  methods:
    fetchFilteredItems: ->
      types = switch @selectionMode
        when 'userIntent'
          ['UserIntent']
        when 'forBotIntent'
          ['TriggerIntent', 'UserIntent']
        when 'forUserIntent'
          ['BotIntent']
        when 'all'
          ['TriggerIntent', 'UserIntent', 'BotIntent', 'DialogAction']
      if @dialogModule?
        @dialogModule.searchNodes(@query, types: types)
          .then (nodes) => @filteredItems = nodes
      else if @config?
        @config.searchNodes(@query, types: types)
          .then (nodes) => @filteredItems = nodes
    onInputEnter: ->
      if @filteredItems.length == 1
        @$emit('select', @filteredItems[0])
        return
      return unless @creationViaEnterEnabled
      @emitCreate()
    emitCreate: (type = @typeToBeCreated) ->
      @$emit('create-node', type, query: @query, connect: true)
    afterLeave: ->
      @query = ''
      @$emit('cancel')

  components:
    Badge: Badge
    Button: Button
    ChevronRightIcon: ChevronRightIcon
    Combobox: Combobox
    ComboboxInput: ComboboxInput
    ComboboxOptions: ComboboxOptions
    ComboboxOption: ComboboxOption
    Dialog: Dialog
    DialogOverlay: DialogOverlay
    H4: H4
    TerminalIcon: TerminalIcon
    TransitionChild: TransitionChild
    TransitionRoot: TransitionRoot
    PlusIcon: PlusIcon
