Vue.component 'nlg-editor',
  props: [
    'nlg'
    'isResultNlg', 'autogeneratedQuickrepliesEnabled', 'inSearchAttribute'
    'fixedMessage', 'mustBeFilled', 'intent'
  ]

  data: ->
    state: state
    currentMedium: state.media[0]
    copyFrom: null
    sliderOptions:
      infinite: false
      accessibility: false # disables arrow key navigation (we need the arrow keys in the input fields)
      swipe: false
      prevArrow: '<button class="slick-prev"><span class="fa fa-chevron-left"></span></button>'
      nextArrow: '<button class="slick-next"><span class="fa fa-chevron-right"></span></button>'
    currentSlide: null
    channelTypeLogos: Globals.channelTypeLogos
    componentId: GuidGenerator.newGuid()
    renderSlider: true
    variationSliderRefs: {}
    variationEditorRefs: {}

  computed:
    messageTemplates: ->
      allTemplates = ObjectProcessor.clone(@state.allTemplates)
      unless @isResultNlg
        Vue.delete(allTemplates, 'result')
      if @currentMedium in ['phone', 'alexa']
        Vue.delete(allTemplates, 'image')
        Vue.delete(allTemplates, 'video')
        Vue.delete(allTemplates, 'button')
        Vue.delete(allTemplates, 'card')
        Vue.delete(allTemplates, 'gallery')
      allTemplates
    currentVariationList: ->
      @nlg[@state.currentLanguage][@currentMedium]
    currentVariationEditor: ->
      @variationEditorRefs[@currentMedium][@currentSlide]
    currentSlider: ->
      @variationSliderRefs[@currentMedium]
    currentVariation: ->
      @currentVariationList[@currentSlide]
    lastSlide: ->
      @currentSlide == @currentVariationList.length - 1 || @currentVariationList.length == 0
    intentsForCopy: ->
      return unless @intent?
      @state.gameConfig.botIntents.filter((intent) => intent != @intent)
    languagesForCopy: ->
      Object.keys(@state.languages).filter (lang) =>
        return false if lang == @state.currentLanguage
        Object.values(@nlg[lang] || {}).some((mediumMessages) ->
          (mediumMessages || []).some((variation) -> variation.messages.length)
        )
    mediaForCopy: ->
      @state.media.filter (medium) =>
        return false if medium == @currentMedium
        (@nlg[@state.currentLanguage]?[medium] || []).some((mediumMessages) -> mediumMessages.messages?.length)

  created: ->
    # amend old format
    for languageCode of state.languages
      for medium in @state.media
        if @nlg[languageCode]? && @nlg[languageCode][medium]?
          for messageList, index in @nlg[languageCode][medium]
            if messageList instanceof Array
              Vue.set(@nlg[languageCode][medium], index, {messages: messageList})
    @initForCurrentLanguage()
    @validate()

  beforeUpdate: ->
    @variationSliderRefs = {}
    @variationEditorRefs = {}

  watch:
    nlg:
      handler: -> @validate()
      deep: true
    'state.currentLanguage': ->
      @initForCurrentLanguage()
      @refreshSlider(0)
    currentMedium: ->
      # fix rendering of slider as soon as it becomes visible
      Vue.nextTick =>
        @currentSlider.$el.slick.setPosition()
        # fix all embedded sliders (need to do it twice for impeccable display -- no idea why)
        $(@currentSlider.$el).find('.slick-slider').each ->
          this.slick.setPosition()
          this.slick.setPosition()

  methods:
    validate: ->
      Vue.set(@nlg, '$valid', @state.media.every(@mediumValid))
      Vue.set(@nlg, '$filled', @nlg[@state.currentLanguage]?[@state.media[0]]?[0]?.messages?.length)
    mediumValid: (medium) ->
      GameValidator.allValid(@nlg[@state.currentLanguage][medium])
    initForCurrentLanguage: ->
      Vue.setDefault(@nlg, @state.currentLanguage, {})
      # amending old format: move content from medium attributes to channel type attributes
      for channelType, medium of {web: 'richtext', phone: 'voice'}
        if (channelType in @state.media) && !@nlg[@state.currentLanguage][channelType]? && @nlg[@state.currentLanguage][medium]?
          Vue.set(@nlg[@state.currentLanguage], channelType, @nlg[@state.currentLanguage][medium])
          Vue.delete(@nlg[@state.currentLanguage], medium)
      Vue.delete(@nlg[@state.currentLanguage], 'text')
      # continue with regular setting of defaults
      for medium in @state.media
        Vue.setDefault(@nlg[@state.currentLanguage], medium, [])
        @nlg[@state.currentLanguage][medium].push({}) if @nlg[@state.currentLanguage][medium].length == 0
      if @fixedMessage?
        Vue.setDefault(@fixedMessage.message, @state.currentLanguage, '')
        if @fixedMessage.buttons?
          for key, button of @fixedMessage.buttons
            Vue.setDefault(button, @state.currentLanguage, '')
    addVariation: ->
      @currentVariationList.push({})
      @refreshSlider(@currentSlide, =>
        @currentSlider.goTo(@currentVariationList.length - 1)
      )
    removeVariation: (index) ->
      @currentVariationList.splice(index, 1)
      @refreshSlider(Math.min(index, @currentVariationList.length - 1))
    addTemplate: (template) ->
      @currentVariationEditor.addTemplate(template)
    copyMessages: (event) ->
      return if !@copyFrom?
      @toggleActionMenu()
      mode = @copyFrom.split(':')[0]
      if mode == 'intent'
        intentLabel = @copyFrom.split(':')[1]
        intent = @state.gameConfig.botIntents.find((intent) -> intent.label == intentLabel)
        Vue.set(@intent, 'nlg', ObjectProcessor.clone(intent.nlg))
      else if mode == 'language'
        language = @copyFrom.split(':')[1]
        Vue.set(
          @nlg,
          @state.currentLanguage,
          ObjectProcessor.clone(@nlg[language])
        )
      else if mode == 'medium'
        medium = @copyFrom.split(':')[1]
        Vue.set(
          @nlg[@state.currentLanguage],
          @currentMedium,
          ObjectProcessor.clone(@nlg[@state.currentLanguage][medium])
        )
        # filter non-text messages for text-only media
        if @currentMedium in ['phone', 'alexa']
          for variation, index in @currentVariationList
            @currentVariationList[index].messages = variation.messages.filter (message) => @messageTemplates[message.template]?
    refreshSlider: (initialSlide, callback) ->
      @sliderOptions.initialSlide = initialSlide
      @renderSlider = false
      Vue.nextTick =>
        @renderSlider = true
        if typeof callback == 'function'
          Vue.nextTick => callback()
    sliderChange: (event, slick) ->
      try
        @currentSlide = @currentSlider.currentSlide()
    toggleActionMenu: ->
      $dropdown = $('.copy-menu')
      if $dropdown.hasClass('open')
        $dropdown.removeClass('open')
        $(document).off("click.#{@componentId}")
      else
        $dropdown.addClass('open')
        # handle click outside:
        $(document).on "click.#{@componentId}", (e) ->
          return if $dropdown.is(e.target) || $dropdown.has(e.target).length
          return if $(e.target).closest('#main-modal').length || $(e.target).closest('.modal-backdrop').length
          $dropdown.removeClass('open')
    setVariationSliderRef: (medium) ->
      return (-> null) if !medium?
      (el) =>
        @variationSliderRefs[medium] = el if el?
    setVariationEditorRef: (medium, index) ->
      return (-> null) if !(medium? && index?)
      (el) =>
        @variationEditorRefs[medium] ||= {}
        @variationEditorRefs[medium][index] = el if el?

  template: '
    <div class="nlg-editor">
      <div class="form-group">
        <label v-show="mustBeFilled && !nlg.$filled" class="text-normal text-danger">
          You have to specify at least one message!
        </label>
      </div>
      <div class="panel panel-tabbed">
        <div class="panel-heading-container">
          <div
            v-for="medium in state.media"
            :class="{active: currentMedium == medium}"
            class="panel-heading tab"
            >
            <a @click="currentMedium = medium" class="flex-container center-items">
              <img v-if="channelTypeLogos[medium.toUpperCase()]" class="logo" :src="channelTypeLogos[medium.toUpperCase()]"/>
              <span v-else class="text-capitalize">{{ medium }}</span>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!mediumValid(medium)"></span>
              </div>
            </a>
          </div>

          <div class="action-menu dropdown copy-menu">
            <div class="btn btn-icon" type="button" aria-haspopup="true" @click="toggleActionMenu">
              <span class="fa fa-ellipsis-h"/>
            </div>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="title">Actions</li>
              <li v-if="intent">
                <span class="far fa-fw fa-clone"></span>
                <span class="text-normal">
                  Copy messages from other intent
                  <info-button info-key="copy_messages_from_intent"/>
                </span>
                <div v-if="intentsForCopy.length > 0" class="flex-container">
                  <span class="fa fa-fw"></span>
                  <select v-model="copyFrom" class="form-control">
                    <option v-for="intent in intentsForCopy" :value="\'intent:\' + intent.label">
                      {{ intent.label }}
                    </option>
                  </select>
                </div>
                <div v-if="intentsForCopy.length == 0">
                  <span class="fa fa-fw"></span>
                  no intents available
                </div>
              </li>
              <li>
                <span class="far fa-fw fa-clone"></span>
                <span class="text-normal">
                  Copy messages from other language
                  <info-button info-key="copy_messages_from_language"/>
                </span>
                <div v-for="lang in languagesForCopy" class="radio">
                  <label class="text-light">
                    <span class="fa fa-fw"></span>
                    <input type="radio" :value="\'language:\' + lang" v-model="copyFrom">
                    {{ state.languages[lang] }}
                  </label>
                </div>
                <div v-if="languagesForCopy.length == 0">
                  <span class="fa fa-fw"></span>
                  no messages available
                </div>
              </li>
              <li>
                <span class="far fa-fw fa-clone"></span>
                <span class="text-normal">
                  Copy messages from other channel type
                  <info-button info-key="copy_messages_from_medium"/>
                </span>
                <div v-for="medium in mediaForCopy" class="radio">
                  <label class="text-light">
                    <span class="fa fa-fw"></span>
                    <input type="radio" :value="\'medium:\' + medium" v-model="copyFrom">
                    {{ medium.capitalize() }}
                  </label>
                </div>
                <div v-if="mediaForCopy.length == 0">
                  <span class="fa fa-fw"></span>
                  no messages available
                </div>
              </li>
              <li>
                <div class="button-container">
                  <button @click="copyMessages" :disabled="copyFrom ? null : true" class="btn btn-primary">copy</button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div v-for="medium in state.media" :class="{hide: medium != currentMedium}" class="panel-body">
          <div class="form-group">
            <!--<label class="text-light">
              Frontend channel types are ordered by expressivity and fall back along this chain from right to left.
              <info-button info-key="channel_type_specific_messages"/>
            </label>-->
            <div class="form-group">&nbsp;</div>
          </div>
          <div class="responsive-grid-container no-gutter">
            <div class="responsive-grid-item item-70">
              <div class="flex-container wrap">
                <div v-for="image, template in messageTemplates" @click="addTemplate(template)" class="btn add-message-btn">
                  <img :src="image">
                  <div>{{ template.capitalize() }}</div>
                </div>
              </div>
            </div>
            <div class="responsive-grid-item item-250">
              <div class="variation-slider-container">
                <div class="inset-shadow"></div>
                <slick
                  v-if="renderSlider"
                  :options="sliderOptions"
                  :ref="setVariationSliderRef(medium)"
                  @after-change="sliderChange"
                  @set-position="sliderChange"
                  class="variation-slider"
                  >
                  <nlg-variation-editor
                    v-for="(variation, index) in nlg[state.currentLanguage][medium]"
                    :variation="variation"
                    :index="index"
                    :count="nlg[state.currentLanguage][medium].length"
                    :medium="medium"
                    :ref="setVariationEditorRef(medium, index)"
                    :autogenerated-quickreplies-enabled="autogeneratedQuickrepliesEnabled"
                    :in-search-attribute="inSearchAttribute"
                    :fixed-message="fixedMessage"
                    @remove-variation="removeVariation"
                    />
                </slick>
                <button v-if="lastSlide" class="add-button" @click="addVariation" title="add variation"><span class="fa fa-plus"></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  '
