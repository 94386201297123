Vue.component 'api-editor',
  props: ['api', 'hideNothingOption']
  created: ->
    Vue.setDefault(@api, 'type', 'HTTP')
    # for API:
    Vue.setDefault(@api, 'method', 'GET')
    Vue.setDefault(@api, 'url', '')
    Vue.setDefault(@api, 'headers', [])
    Vue.setDefault(@api, 'data', '')
    Vue.setDefault(@api, 'dataFormat', 'application/json')
    # for EMAIL:
    Vue.setDefault(@api, 'recipient', '')
    Vue.setDefault(@api, 'subject', '')
    Vue.setDefault(@api, 'body', '')
  template: '
    <div>
      <div class="form-group">
        <label class="non-bold inline-radio">
          <input type="radio" v-model="api.type" value="HTTP">
          API
        </label>
        <label class="non-bold inline-radio">
          <input type="radio" v-model="api.type" value="EMAIL">
          E-mail
        </label>
        <label v-if="!hideNothingOption" class="non-bold inline-radio">
          <input type="radio" v-model="api.type" value="NONE">
          Nothing
        </label>
      </div>
      <template v-if="api.type == \'HTTP\'">
        <div class="form-group">
          <label class="non-bold inline-radio">
            <input type="radio" v-model="api.method" value="GET">
            GET
          </label>
          <label class="non-bold inline-radio">
            <input type="radio" v-model="api.method" value="POST">
            POST
          </label>
          <input v-model="api.url" class="form-control" placeholder="URL">
        </div>
        <div v-if="api.method == \'POST\'" class="form-group">
          <label class="non-bold block">POST data</label>
          <label class="non-bold inline-radio">
            <input type="radio" v-model="api.dataFormat" value="application/json">
            json
          </label>
          <label class="non-bold inline-radio">
            <input type="radio" v-model="api.dataFormat" value="application/x-www-form-urlencoded">
            x-www-form-urlencoded
          </label>
          <textarea v-model="api.data" class="form-control" style="resize: vertical"></textarea>
        </div>
        <label class="non-bold">
          Headers
        </label>
        <headers-editor :headers="api.headers"/>
      </template>
      <template v-if="api.type == \'EMAIL\'">
        <div class="form-group">
          <label class="non-bold">Recipient</label>
          <input v-model="api.recipient" class="form-control">
        </div>
        <div class="form-group">
          <label class="non-bold">Subject</label>
          <input v-model="api.subject" class="form-control">
        </div>
        <div class="form-group">
          <label class="non-bold">Body</label>
          <textarea v-model="api.body" class="form-control"/>
        </div>
      </template>
    </div>
  '
