
  import Project from 'models/project.coffee'
  import { availableLanguages as languageLabels } from 'rails_data'

  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Badge, Button, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  availableLanguages = [label: 'Select a language'].concat(Project.AVAILABLE_LANGUAGES)

  export default
    props:
      modelValue: Project

    data: ->
      project: @modelValue
      languageLabels: languageLabels
      availableLanguages: availableLanguages
      selectedLanguage: null

    computed:
      totalLanguages: ->
        @project.maxLanguages
      usedLanguages: ->
        @project.languages.length
      mayAddLanguage: ->
        @usedLanguages < @totalLanguages
      mayRemoveLanguage: ->
        @usedLanguages > 1

    watch:
      modelValue: ->
        @project = @modelValue

    methods:
      addLanguage: ->
        return unless @selectedLanguage?
        return if @project.languages.includes(@selectedLanguage)
        @project.languages.push(@selectedLanguage)
        @selectedLanguage = null
        @update()
      removeLanguage: (index) ->
        return if @project.languages.length < 2
        @project.languages.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @project)

    components:
      Badge: Badge
      Button: Button
      KeyListbox: KeyListbox
      UpgradePrompt: UpgradePrompt
      XIcon: XIcon
