
  import NoParseMessage from 'models/no_parse_message'

  import { ActionMenu, Checkbox, CommandPalette, TableCell, TableRow } from 'components/generic'
  import { ArrowUpIcon, BookOpenIcon, PlusIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      message: NoParseMessage

    data: ->
      actionMenuItems: [
        {icon: PlusIcon, label: 'Select intent', method: => @$emit('select-intent')}
        {icon: BookOpenIcon, label: 'Add knowledge', method: => @$emit('add-knowledge')}
        {icon: ArrowUpIcon, iconClass: 'rotate-45', label: 'View in inbox', href: Routes.inbox3_project_path(Globals.projectId, Globals.stageLevel) + '/logs#' + @message.userId}
        {icon: XIcon, label: 'Discard message', method: => @$emit('remove')}
      ]

    methods:
      select: ->
        # wait for @test.selected to be changed
        setTimeout =>
          @$emit('select', @message.selected)
        , 0

    components:
      ActionMenu: ActionMenu
      Checkbox: Checkbox
      CommandPalette: CommandPalette
      TableCell: TableCell
      TableRow: TableRow
