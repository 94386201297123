
  import DialogTest from 'models/dialog_test.coffee'
  import { confirm } from 'helpers'

  import StatusIcon from './status_icon.vue'
  import { ActionMenu, Checkbox, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/solid'

  export default
    props:
      test: DialogTest

    emits: ['remove', 'select']

    computed:
      actionMenuItems: ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(name: 'test', params: {testId: @test.id})},
          {icon: XIcon, label: 'Delete', method: @remove},
        ].filter (item) -> item?

    methods:
      select: ->
        # wait for @test.selected to be changed
        setTimeout =>
          @$emit('select', @test.selected)
        , 0
      remove: (test) ->
        return unless await confirm("Do you really want do delete test “#{@test.label}”?")
        @$emit('remove')

    components:
      ActionMenu: ActionMenu
      Checkbox: Checkbox
      StatusIcon: StatusIcon
      TableCell: TableCell
      TableRow: TableRow
