import fileuploadImageUrl from 'images/file_upload.png'

Vue.component 'file-upload',
  props: ['name', 'url']

  emits: ['reset']

  data: ->
    fileuploadImageUrl: fileuploadImageUrl
    filename: ''
    id: GuidGenerator.newGuid()

  methods:
    replaceFileName: (e) ->
      @filename = e.target.value.split('\\').pop()
    deleteFile: ->
      $("#" + @id).val('')
      @filename = ''

  template: '
    <div class="flex items-center">
      <label :for="id" class="custom-file-upload flex items-center">
        <img :src="fileuploadImageUrl"/> {{ filename || \'Upload from your computer\' }}
      </label>
      <input :name="name" :id="id" class="hidden" type="file" @change="replaceFileName" />
      <div class="refresh-button">
        <div v-if="filename" class="clickable" @click="deleteFile"></div>
        <div v-if="!filename && url" class="clickable" @click="$emit(\'reset\')"></div>
        <div v-if="!filename && !url" class="unclickable"></div>
      </div>
    </div>
  '
