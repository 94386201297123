
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default
  props:
    title: String

  data: ->
    isOpen: false

  methods:
    open: ->
      @isOpen = true
    close: ->
      @$emit('close')
      @isOpen = false

  components:
    Dialog: Dialog
    DialogOverlay: DialogOverlay
    DialogTitle: DialogTitle
    TransitionChild: TransitionChild
    TransitionRoot: TransitionRoot
    XIcon: XIcon
