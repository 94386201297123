import { createApp } from 'vue_shims'

import Settings from 'components/publishing/settings.vue'

initPublishingSettingsApp = (element) ->
  createApp(
    components:
      Settings: Settings

    template: '
      <Settings/>
    '
  )
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('publishing-settings-app')
      initPublishingSettingsApp(element)
