
  import Bot from 'models/bot.coffee'
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import BotConfig from 'models/bot_config.coffee'
  import { railsEnv } from 'rails_data'
  import { download } from 'helpers'

  import Navigation from '../navigation.vue'
  import CommandPalette from './command_palette.vue'
  import ModuleTableRow from './module_table_row.vue'
  import ModuleGenerator from './module_generator.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Button, DocumentationLink, PageHeader, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot

    data: ->
      search: false
      moduleRowRefs: []
      PlusIcon: PlusIcon

    computed:
      isCoreBehaviourList: ->
        @$route.name == 'coreBehaviourList'
      modules: ->
        if @isCoreBehaviourList
          @bot.config.coreBehaviours.sortByString('label')
            .filter (module) => !(railsEnv == 'production' && ['Inactivity'].includes(module.type))
        else
          @bot.config.dialogModules.sortByString('label')
      tooManyModules: ->
        Globals.restrictions.dialog_modules < @modules.length
      upgradePromptMessage: ->
        "Your subscription plan doesn’t allow to publish a bot with this many modules to live (#{Globals.restrictions.dialog_modules} max)."

    beforeUpdate: ->
      @moduleRowRefs = []

    methods:
      create: ->
        @bot.config.addDialogModule(type: 'DialogModule')
        @$nextTick =>
          @moduleRowRefs[0].edit()
      remove: (index) ->
        @bot.config.removeDialogModuleByIndex(index)
      downloadConfig: ->
        BotbuilderApi.getConfig(@bot.id, light: false)
          .then (data) =>
            download(
              'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2)),
              "#{@bot.label}.json"
            )
      goTo: (node) ->
        @$router.push(name: 'module', params: {moduleKey: node.moduleKey}, hash: "##{node.key}")
        @search = false
      addModule: (moduleData) ->
        @bot.config.addDialogModule(moduleData)
      setModuleRowRef: (index, el) ->
        @moduleRowRefs[index] = el if el?

    components:
      Button: Button
      CommandPalette: CommandPalette
      DocumentationLink: DocumentationLink
      Navigation: Navigation
      ModuleGenerator: ModuleGenerator
      ModuleTableRow: ModuleTableRow
      PageHeader: PageHeader
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      UpgradePrompt: UpgradePrompt
