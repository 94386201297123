Vue.component 'subscription-config',
  props: ['subscription']
  data: ->
    state: state
    triggerTypes:
      userIntent: 'User intent'
      botIntent: 'Bot intent'
    triggerFrequencies:
      firstTime: 'only the first time'
      everyTime: 'every time'
      never: 'never – I’ll build the trigger manually'
  computed:
    intentSignatureSide: ->
      return 'user' if @subscription.triggerType == 'userIntent'
      return 'bot' if @subscription.triggerType == 'botIntent'
  created: ->
    Vue.setDefault(@subscription, 'triggerType', 'userIntent')
    Vue.setDefault(@subscription, 'triggeringIntent', {})
    Vue.setDefault(@subscription, 'frequency', 'firstTime')
    Vue.setDefault(@subscription, 'subscribingUtterances', {})
    Vue.setDefault(@subscription, 'unsubscribingUtterances', {})
    Vue.setDefault(@subscription, 'invitation', {})
    Vue.setDefault(@subscription, 'subscriptionConfirmation', {})
    Vue.setDefault(@subscription, 'cancellationConfirmation', {})
  template: '
    <div>
      <div class="panel-heading">
        <h3>Edit {{ subscription.subscriptionListName }}</h3>
      </div>
      <div class="panel-body">
        <div class="form-group flex-container wrap baseline-items">
          <div>What should trigger a call-to-action message that invites the user to subscribe?</div>&ensp;
          <select class="styled-form-field" v-model="subscription.triggerType">
            <option :value="option" v-for="label, option in triggerTypes">{{ label }}</option>
          </select>&emsp;
          <payload-editor v-if="intentSignatureSide" :payload="subscription.triggeringIntent" :side="intentSignatureSide"/>
        </div>
        <div class="form-group">
          How often would you like to send the call-to-action message?
          &ensp;
          <select class="styled-form-field" v-model="subscription.frequency">
            <option :value="option" v-for="label, option in triggerFrequencies">{{ label }}</option>
          </select>
        </div>

        <div class="form-group" v-if="subscription.subscribingUtterances">
          <label class="non-bold">Users can actively subscribe to the list with these utterances:</label>
          <multilang-variation-field :dictionary="subscription.subscribingUtterances"/>
        </div>
        <div class="form-group" v-if="subscription.unsubscribingUtterances">
          <label class="non-bold">Users can actively cancel a subscription of the list with these utterances:</label>
          <multilang-variation-field :dictionary="subscription.unsubscribingUtterances"/>
        </div>

        <div class="panel-group">
          <panel-togglable>
            <template v-slot:togglingheader>
              <div class="expand">
                Bot CTA: this is how the bot will invite a subscription
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!subscription.invitation.$valid"></span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="subscription.invitation"/>
            </template>
          </panel-togglable>

          <panel-togglable>
            <template v-slot:togglingheader>
              <div class="expand">
                This is how the bot will confirm a subscription
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!subscription.subscriptionConfirmation.$valid"></span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="subscription.subscriptionConfirmation"/>
            </template>
          </panel-togglable>

          <panel-togglable>
            <template v-slot:togglingheader>
              <div class="expand">
                This is how the bot will confirm the cancellation of a subscription
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!subscription.cancellationConfirmation.$valid"></span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="subscription.cancellationConfirmation"/>
            </template>
          </panel-togglable>
        </div>
      </div>
    </div>
  '
