
  import { Button, Input } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Array

    data: ->
      domains: @modelValue
      inputRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @domains = @modelValue

    beforeUpdate: ->
      @inputRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @domains)
      addDomain: ->
        @domains.push('')
        @$nextTick =>
          @inputRefs.last.focus()
      removeDomain: (index) ->
        @domains.splice(index, 1)
        @update()
      setInputRef: (el) ->
        @inputRefs.push(el) if el?

    components:
      Button: Button
      Input: Input
      XIcon: XIcon
