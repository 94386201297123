Vue.component 'composed-attribute',
  props: ['attribute', 'index', 'languages', 'signature', 'primitiveAttributes']
  data: ->
    currentLanguage: @languages[0]
    statuses: []
    infoUrl: ''
  created: ->
    Vue.setDefault(@attribute, '$key', GuidGenerator.newGuid())
    @attribute.attribute.conditions.push(@emptyCondition()) if @attribute.attribute.conditions.length == 0
    @statuses = ({valid: true} for [1..@attribute.attribute.conditions.length])
  mounted: ->
    @projectID = $(@$el).closest('#content-objects-app').data('projectId')
    @infoUrl = Routes.condition_value_format_info_project_content_object_types_path(@projectID, Globals.stageLevel)
  computed:
    valid: ->
      !@statuses.find((e) -> e.valid == false)
  methods:
    addCondition: ->
      @attribute.attribute.conditions.push(@emptyCondition())
      @statuses.push({valid: true})
    emptyCondition: ->
      attribute: ''
      operator: ''
      value: ''
    removeCondition: (condition) ->
      index = @attribute.attribute.conditions.indexOf(condition)
      @attribute.attribute.conditions.splice(index, 1)
      @statuses.splice(index, 1)
    remove: ->
      $(@$el).find('.collapse').collapse('hide')
      @$emit('remove-composed-attribute', @attribute)
  template: '
    <panel-togglable parent-selector="#accordion">
      <template v-slot:header>
        <div class="expand">
          <inline-edit
            v-model:string="attribute.attribute.label"
            placeholder="attribute label"
            warning="Changing the attibute’s label will break existing references to it. Continue?"
            focus="true"
            bold="true"
            />
        </div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!valid"></span>
        </div>
      </template>
      <template v-slot:body>
        <h5>
          Conditions
          <a :href="infoUrl" data-modal="true">
            <span class="fa fa-info-circle"></span>
          </a>
        </h5>
        <condition
          v-for="(condition, index) in attribute.attribute.conditions"
          v-on:remove-condition="removeCondition"
          :condition="condition"
          :status="statuses[index]"
          :signature="signature"
          :primitive-attributes="primitiveAttributes"
          :last-condition="attribute.attribute.conditions.length == 1"
          >
        </condition>
        <plus-button label="add another condition" :method="addCondition"/>
        <h5>Lexicon entry</h5>
        <div class="form-group">
          <select class="styled-form-field" v-model="currentLanguage">
            <option :value="lang" v-for="lang in languages">{{ lang }}</option>
          </select>
        </div>
        <dictionary :dictionary="attribute.dictionary" :language="currentLanguage"/>
        <div class="btn btn-caution pull-right" v-on:click="remove()">Remove composed attribute</div>
      </template>
    </panel-togglable>
  '
