export default class ContextUpdate
  @OPERATOR_TYPES = [
    'set'
    'delete'
    'add'
    'subtract'
    'invert'
    'clear'
  ]

  @isUnaryOperator: (operator) ->
    ['delete', 'invert', 'clear'].includes(operator)

  constructor: (data = {}) ->
    @id = GuidGenerator.newGuid() # needed for Vue list rendering
    @parameterKey = data.parameterKey || ''
    @operator = data.operator || 'set'
    @value = data.value || ''

  Object.defineProperties @prototype,
    isUnary:
      get: ->
        ContextUpdate.isUnaryOperator(@operator)
    export:
      get: ->
        parameterKey: @parameterKey
        operator: @operator
        value: @value

