
  import Label from './label.vue'

  export default
    props:
      modelValue: [String, Boolean] # order matters! else `""` will be cast to `true`
      asString:
        type: Boolean
        default: true
      label: String

    emits: ['update:modelValue']

    data: ->
      value: @modelValue

    computed:
      trueValue: ->
        if @asString then 'true' else true
      falseValue: ->
        if @asString then 'false' else false

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      focus: ->
        null
      update: ->
        @$emit('update:modelValue', @value)

    components:
      Label: Label
