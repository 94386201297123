Vue.component 'context-parameters-table',
  props: ['parameters']
  data: ->
    contextParameterRefs: []
  beforeUpdate: ->
    @contextParameterRefs = []
  methods:
    add: ->
      @parameters.push({})
      Vue.nextTick =>
        @contextParameterRefs[-1..][0].edit()
    save: (parameter, index) ->
      @$emit('save-parameter', parameter, index)
    remove: (index) ->
      @parameters.splice(index, 1)
      @$emit('remove-parameter', index)
    cancelEditing: ->
      row.cancel() for row in @contextParameterRefs
    setContextParameterRef: (el) ->
      @contextParameterRefs.push(el) if el?
  template: '
    <div>
      <table class="table">
        <thead>
          <tr>
            <th style="width: 15%">Label</th>
            <th style="width: 15%">Label for display</th>
            <th style="width: 25%">Description</th>
            <th style="width: 10%">Display in inbox</th>
            <th style="width: 20%">Type</th>
            <th style="width: 15%">Initial value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <context-parameter
            :ref="setContextParameterRef"
            v-for="parameter, index in parameters"
            :parameter="parameter"
            :index="index"
            :key="parameter.$key"
            @remove-parameter="remove"
            @save-parameter="save"
            @edit-parameter="cancelEditing"
            />
        </tbody>
      </table>
      <button @click="add" class="btn btn-primary">add parameter</button>
    </div>
  '
