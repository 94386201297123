Vue.component 'checkbox',
  props:
    modelValue:
      required: true
    name: String

  data: ->
    checkboxValue: @modelValue

  watch:
    modelValue: ->
      @checkboxValue = @modelValue

  methods:
    update: ->
      @$emit('update:modelValue', @checkboxValue)
      @$emit('change')

  template: '
    <label class="custom-checkbox">
      <input
        type="checkbox"
        v-model="checkboxValue"
        :name="name"
        @change="update"
        >
      <div class="box">
        <span class="checkmark fa fa-check"/>
      </div>
      <slot/>
    </label>
  '
