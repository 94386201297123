import rangy from 'rangy'
import 'rangy/lib/rangy-textrange.js'
import 'rangy/lib/rangy-classapplier.js'
import 'rangy/lib/rangy-selectionsaverestore.js'

characterCount = (element, stopElement) ->
  if element.nodeType == Node.TEXT_NODE
    if element.textContent == "\ufeff"
      return 0
    else
      return element.textContent.length
  count = 0
  for child in element.childNodes
    if child == stopElement
      return count
    count += characterCount(child, stopElement)
  count

rangy.rangePrototype.startOffsetRelativeTo = (ancestor) ->
  characterCount(ancestor, this.commonAncestorContainer) + this.startOffset

rangy.rangePrototype.endOffsetRelativeTo = (ancestor) ->
  characterCount(ancestor, this.commonAncestorContainer) + this.endOffset

export default rangy
