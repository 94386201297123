Vue.component 'message-editor-settings',
  props: ['messengerLogo', 'whatsappLogo', 'optIn', 'languages', 'defaultLanguage', 'facebookChannels', 'whatsappChannels']
  data: ->
    state: state
    config:
      entryGateFields_name: true
      entryGateFields_email: true
      entryGateFields_company: false
      entryGateFields_phone_number: false
    emoji: false
    entryGate: false
    optInMessage: ''
    activateFbChannel: false
    activateWpChannel: false
    fbChannel: null
    wpChannel: null
  mounted: ->
    configData = @state.config
    if configData
      @config = {
        entryGateFields_name: configData.entryGateFields.userDataFields.name
        entryGateFields_email: configData.entryGateFields.userDataFields.email
        entryGateFields_company: configData.entryGateFields.userDataFields.company
        entryGateFields_phone_number: configData.entryGateFields.userDataFields.phone_number
      }
      @emoji = configData.emoji
      @entryGate = configData.entryGate
      @optInMessage = configData.entryGateFields?.optIn?.message
      @fbChannel = @facebookChannels.find (channel) -> channel.name == configData.messenger_channel_name
      @wpChannel = @whatsappChannels.find (channel) -> channel.name == configData.whatsapp_channel_name
      @activateFbChannel = if configData.messenger_channel_url then true else false
      @activateWpChannel = if configData.whatsapp_channel_url then true else false
  methods:
    setFacebookChannel: (value) ->
      @fbChannel = value
    setWhatsappChannel: (value) ->
      @wpChannel = value
    fbChannelUrl: (pageId) ->
      url = ''
      if pageId
        url = 'https://m.me/' + pageId
      return url
    wpChannelUrl: (number) ->
      url = ''
      if number
        if number != ''
          url = 'https://wa.me/' + number
        else
          url = 'https://wa.me/#'
      return url
  template: '
    <div class="message-editor-settings">
      <div v-if="facebookChannels.length || whatsappChannels.length" class="form-group row">
        <label for="font-size" class="col-sm-2 col-form-label">
          Activate Channel
        </label>
        <div class="col-sm-10">

          <div class="flex items-center mb-4">
            <img :src="messengerLogo" class="social-icon" />
            <template v-if="facebookChannels.length">
              <span class="ml-4">Facebook messenger</span>
              <label class="sliding-switch non-bold ml-4">
                <input type="checkbox" v-model="activateFbChannel">
                <span class="slider"></span>
              </label>
              <input v-if="activateFbChannel" type="hidden" name="umh_channel[config][messenger_channel_name]" :value="fbChannel?.name">
              <input v-if="activateFbChannel" type="hidden" name="umh_channel[config][messenger_channel_url]" :value="fbChannelUrl(fbChannel?.config.FACEBOOK_PAGE_ID)">
              <dropdown
                v-if="activateFbChannel"
                :chosen="fbChannel?.name || \'Select a facebook channel\'"
                align="left"
                class="ml-14"
              >
                <li v-for="channel in facebookChannels">
                  <a v-on:click="setFacebookChannel(channel)">{{ channel.name }}</a>
                </li>
              </dropdown>
            </template>
            <template v-else>
              <span class="ml-4 italic">Add a Facebook channel in order to link it here!</span>
            </template>
          </div>

          <div class="flex items-center">
            <img :src="whatsappLogo" class="social-icon" />
            <template v-if="whatsappChannels.length">
              <span class="ml-4">WhatsApp</span>
              <label class="sliding-switch non-bold ml-4">
                <input type="checkbox" v-model="activateWpChannel">
                <span class="slider"></span>
              </label>
              <input v-if="activateWpChannel" type="hidden" name="umh_channel[config][whatsapp_channel_name]" :value="wpChannel?.name">
              <input v-if="activateWpChannel" type="hidden" name="umh_channel[config][whatsapp_channel_url]" :value="wpChannelUrl(wpChannel?.config.WHATSAPP_COREAPP_PHONE_NUMBER)">
              <dropdown
                v-if="activateWpChannel"
                :chosen="wpChannel?.name || \'Select a whatsapp channel\'"
                align="left"
                class="ml-14"
              >
                <li v-for="channel in whatsappChannels">
                  <a v-on:click="setWhatsappChannel(channel)">{{ channel.name }}</a>
                </li>
              </dropdown>
            </template>
            <template v-else>
              <span class="ml-4 italic">Add a WhatsApp channel in order to link it here!</span>
            </template>
          </div>

        </div>
      </div>

      <template v-if="optIn">
        <div class="form-group row"><div class="dotted-row"></div></div>
        <div class="form-group row">
          <label for="font-size" class="col-sm-2 col-form-label">
            Entry Gate
          </label>
          <div class="col-sm-10">
            <div class="flex">
              <label for="entryGate" class="sliding-switch non-bold">
                <input type="checkbox" name="umh_channel[config][entryGate]" id="entryGate" v-model="entryGate">
                <span class="slider"></span>
              </label>
            </div>
            <br />
            <div class="flex">
              <checkbox name="umh_channel[config][entryGateFields_name]" v-model="config.entryGateFields_name">
                Name
              </checkbox>
              <checkbox name="umh_channel[config][entryGateFields_email]" v-model="config.entryGateFields_email">
                Email
              </checkbox>
              <checkbox name="umh_channel[config][entryGateFields_company]" v-model="config.entryGateFields_company">
                Company
              </checkbox>
              <checkbox name="umh_channel[config][entryGateFields_phone_number]" v-model="config.entryGateFields_phone_number">
                Phone number
              </checkbox>
            </div>
            <br />
            <div class="flex">
              <p class="property">Opt-In message</p>
              <textarea class="opt-in-message" name="umh_channel[config][entryGateFields_optIn_message]" style="resize: vertical" v-model="optInMessage"></textarea>
              <input type="hidden" name="umh_channel[config][entryGateFields_optIn_language]" :value="defaultLanguage">
              <dropdown
                :chosen="languages[defaultLanguage]"
                align="left"
                class="filter-element"
                >
                <li v-for="(label, value) in languages">
                  <a>{{ label }}</a>
                </li>
              </dropdown>
            </div>
          </div>
        </div>
      </template>

      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="font-size" class="col-sm-2 col-form-label">
          Emoji
        </label>
        <div class="col-sm-10">
          <label for="emoji" class="sliding-switch non-bold">
            <input type="checkbox" name="umh_channel[config][emoji]" id="emoji" v-model="emoji">
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>
  '
