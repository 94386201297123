
  import { RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      audience: Object
      status: String

    components:
      RefreshIcon: RefreshIcon
