
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import ContentEntity from 'models/content_entity.coffee'
  import Synonym from 'models/synonym.coffee'

  import EntityAttributeEditor from './entity_attribute_editor.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import SynonymEditor from './synonym_editor.vue'
  import Wrapper from './wrapper.vue'
  import { BackLink, Button, H3, Input, Label, Modal, Panel, Textarea } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      entity: null
      schema: null
      debounceTimeout: null
      synonymEditorRefs: []
      languagesForSelector: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}
      PlusIcon: PlusIcon

    watch:
      '$root.entitySchemas': ->
        return if @schema?
        @loadEntity()
      '$route.params.entityKey': ->
        @loadEntity()
      '$root.currentLanguage': ->
        @loadEntity()

    created: ->
      @loadEntity()

    beforeUpdate: ->
      @synonymEditorRefs = []

    methods:
      loadEntity: ->
        return unless @$route.params.entityKey
        @schema = @$root.entitySchemas.find (schema) => schema.key == @$route.params.schemaKey
        return unless @schema?
        BotbuilderApi.getEntity(@$root.currentLanguage, @$route.params.entityKey, noAlert: true)
          .then (entity) =>
            @entity = entity
            window.breadcrumbs.strip()
              .append(text: 'Content', callback: => @$router.push(name: 'content'))
              .append(text: (@schema?.label || @$route.params.schemaKey), callback: => @$router.push(name: 'contentList'))
              .append(text: @entity.label)
          .catch =>
            @entity = null
            window.breadcrumbs.strip()
              .append(text: 'Content', callback: => @$router.push(name: 'content'))
              .append(text: (@schema?.label || @$route.params.schemaKey), callback: => @$router.push(name: 'contentList'))
              .append(text: @$route.params.entityKey)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @entity.save()), 1000)
      create: ->
        @entity = new ContentEntity(
          @$root.currentLanguage
          schemaKey: @$route.params.schemaKey
          key: @$route.params.entityKey
          label: @$route.params.entityKey
        )
        @entity.create()
      addSynonym: ->
        @entity.synonyms.push(new Synonym())
        @$nextTick => @synonymEditorRefs.last.focus()
      removeSynonym: (index) ->
        @entity.synonyms.splice(index, 1)
        @update()
      setSynonymEditorRef: (index, el) ->
        @synonymEditorRefs[index] = el if el?

    components:
      BackLink: BackLink
      Button: Button
      EntityAttributeEditor: EntityAttributeEditor
      H3: H3
      Input: Input
      Label: Label
      LanguageSelector: LanguageSelector
      Modal: Modal
      Panel: Panel
      SynonymEditor: SynonymEditor
      Textarea: Textarea
      Wrapper: Wrapper
