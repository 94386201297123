Vue.component 'radiobutton',
  props:
    modelValue:
      required: true
    valueValue:
      required: true

  data: ->
    radiobuttonValue: @value

  watch:
    modelValue: ->
      @radiobuttonValue = @modelValue
    value: ->
      @radiobuttonValue = @value

  methods:
    update: ->
      @$emit('update:modelValue', @radiobuttonValue)
      @$emit('change')

  template: '
    <label class="custom-radiobutton">
      <input
        type="radio"
        v-model="radiobuttonValue"
        :value="valueValue"
        @change="update"
        >
      <div class="box">
        <span class="checkmark fa fa-circle"/>
      </div>
      <slot/>
    </label>
  '
