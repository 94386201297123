import BotbuilderApi from 'models/botbuilder_api.coffee'
import GuidGenerator from 'models/guid_generator.coffee'
import Synonym from 'models/synonym.coffee'

export default class AttributeMapping
  constructor: (data = {}) ->
    if data.id
      @id = data.id
    else
      @id = GuidGenerator.newUUID()
      @_isNew = true
    @parameterKey = data.parameterKey
    @value = ''
    @synonyms = {}
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @id = data.id if data.id?
    @value = data.value if data.value?
    @component = data.component if data.component?
    if data.synonyms?
      @synonyms = Object.fromEntries(
        Object.entries(data.synonyms).map ([language, list]) =>
          [language, list.map((synonymData) -> new Synonym(synonymData))]
      )
    this

  save: ->
    if @_isNew
      BotbuilderApi.createAttributeMapping(@)
        .then (data) =>
          @_isNew = false
          @update(data)
    else
      BotbuilderApi.updateAttributeMapping(@)

  delete: ->
    return Promise.resolve() if !@id?
    BotbuilderApi.deleteAttributeMapping(@)

  Object.defineProperties @prototype,
    export:
      get: ->
        id: @id
        value: @value
        parameterKey: @parameterKey
        component: @component
        synonyms: Object.fromEntries(
          Object.entries(@synonyms).map ([language, list]) =>
            [language, list.map((synonym) -> synonym.export)]
        )
