# Helper that checks for a given object, a given language code and a given medium
# if the multi-language fields (specified by given selectors) are filled in.
# It provides only the method "filledIn"
# which returns one of three states: "none", "some", "all".
#
# You must specify an array of selectors that may have one of two forms:
# either a property path, i.e. a string of property names separated by dots,
# or an object with three properties: "base", "suffix" and one of "condition" and "iterator"
#   where base and suffix are property paths
#   and condition is a function
#     taking as argument the object that is found after the base property path has been followed to its end
#     and returning true or false; only in the former case the suffix path will be checked
#   and iterator is a function
#     taking as argument the object that is found after the base property path has been followed to its end
#     and returning an array of objects to be checked (following the "suffix" path inside the objects)

class window.LanguageValidator

  class Validator
    constructor: (@language, @medium) ->
      @states = []
    check: (object, selector, level) ->
      if object instanceof Array
        for item in object
          @check(item, @cloneSelector(selector), level)
      else if object instanceof Object
        if selector[level].length == 0
          if level == 'base'
            #  iterator
            if selector.iterator?
              for item in selector.iterator(object)
                @check(item, @cloneSelector(selector), 'suffix')
            # check condition
            else if selector.condition(object)
              @check(object, @cloneSelector(selector), 'suffix')
          else if level == 'suffix'
            # target object has been found
            @checkObject(object)
          return
        # dig deeper
        nextProperty = @shiftSelector(selector, level)
        @check(object[nextProperty], @cloneSelector(selector), level)
    checkObject: (object) ->
      return unless object?
      if object instanceof Array
        for item in object
          @checkObject(item)
      else
        @checkLanguage(object)
    checkLanguage: (object) ->
      if !object?
        @states.push(false)
      else if object[@language] instanceof Array # variation fields
        if object[@language].length == 0
          @states.push(false)
        else
          for variation in object[@language]
            @addState(variation)
      else if object[@language] instanceof Object # nlg
        if object[@language][@medium]?
          for variation in object[@language][@medium]
            if variation.messages?.length
              for message in variation.messages
                @checkMessage(message)
            else
              @states.push(false)
        else
          @states.push(false)
      else # single string fields
        @addState(object[@language])
    checkMessage: (message) ->
      if message.type == 'Bubble'
        for attachment in message.attachments
          @checkAttachment(attachment)
      else if message.type == 'SelectionTemplate'
        for bubble in [message.resultBubble, message.moreBubble]
          return unless bubble.attachments?
          for attachment in bubble.attachments
            @checkAttachment(attachment)
      else
        return unless message.bubbles?
        for bubble in message.bubbles
          return unless bubble.attachments?
          for attachment in bubble.attachments
            @checkAttachment(attachment)
    checkAttachment: (attachment) ->
      if attachment.type == 'Text'
        @addState(attachment.text)
      else if attachment.type == 'Button'
        @addState(attachment.text?.text)
    addState: (string) ->
      @states.push(
        typeof string == 'string' && string.length > 0
      )
    cloneSelector: (selector) ->
      base: selector.base.slice()
      condition: selector.condition
      iterator: selector.iterator
      suffix: selector.suffix.slice()
    shiftSelector: (selector, level) ->
      selector[level].shift()

  @split: (path) ->
    path.split('.').filter((segment) -> segment.length > 0)

  @filledIn: (object, language, medium, selectors) ->
    validator = new Validator(language, medium)

    for rawSelector in selectors
      # prepare selector
      selector = if typeof rawSelector == 'string'
        base: @split(rawSelector)
        condition: -> true
        suffix: []
      else
        base: @split(rawSelector.base)
        condition: rawSelector.condition
        iterator: rawSelector.iterator
        suffix: @split(rawSelector.suffix)
      # start check
      validator.check(object, selector, 'base')

    if validator.states.length == 0
      'none'
    else if validator.states.every((filled) -> filled)
      'all'
    else if validator.states.some((filled) -> filled)
      'some'
    else
      'none'
