
  import { Dropdown, Switch } from 'components/generic'

  export default
    props:
      settings: Object

    data: ->
      debounceTimeout: null

    methods:
      update: ->
        @$emit('change')
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @$root.saveSettings()), 1000)

    components:
      Dropdown: Dropdown
      Switch: Switch
