import Conversation from 'models/conversation'
import { alert } from 'helpers'

Vue.component 'inbox2-conversation-details-context-parameter',
  props:
    conversation: Conversation
    paramKey: String
    context: Object

  data: ->
    editing: false
    valueCopy: ''

  computed:
    label: ->
      if @contextParameter
        if @$root.isBotcore2 && @contextParameter.key.includes("user.")
          t('inbox.conversations.' + @contextParameter.key) || @contextParameter.label
        else if !@$root.isBotcore2 && @contextParameter.label.includes("user.")
          t('inbox.conversations.' + @contextParameter.label) || @contextParameter.humanizedLabel || @contextParameter.label
        else
          @contextParameter.humanizedLabel || @contextParameter.label
      else
        @paramKey
    contextParameter: ->
      if @$root.isBotcore2
        @$root.contextParameters.find (cp) => cp.key == @paramKey
      else
        @$root.contextParameters[@conversation.stageId]?.read.find (cp) => cp.label == @paramKey
    placeholder: ->
      placeholder = if @$root.isBotcore2
        @contextParameter.valueTypeLabel
      else
        TypeValidator.typeLabel(@contextParameter?.type, 'set')
      if placeholder == 'String' then '' else placeholder
    valid: ->
      if @$root.isBotcore2
        true
      else
        TypeValidator.valueValid(
          @valueCopy,
          @contextParameter?.type,
          'set', false
        )
    isWritable: ->
      if @$root.isBotcore2
        !@contextParameter.readOnly
      else
        return false unless @$root.contextParameters[@conversation.stageId]?
        @paramKey in @$root.contextParameters[@conversation.stageId].write.map((cp) -> cp.label)

  watch:
    editing: ->
      @$emit('edit', @paramKey) if @editing

  methods:
    edit: ->
      @valueCopy = @context[@paramKey] || ''
      @editing = true
      Vue.nextTick =>
        $(@$el).find('input').focus()
    save: ->
      @$root.api.updateConversationContext(@conversation, "#{@paramKey}": @valueCopy)
        .then => @context[@paramKey] = @valueCopy
        .catch => await alert('Sorry, your changes couldn’t be saved.')
        .finally => @editing = false
    cancel: ->
      @editing = false

  template: '
    <div>
      <div class="user-data">
        <div class="user-data-label ellipsis">{{ label }}</div>
        <div class="user-data-value ellipsis">
          <template v-if="!editing">
            <span v-if="context[paramKey] == \'true\'" class="fa fa-check-circle"/>
            <span v-if="context[paramKey] == \'false\'" class="fa fa-times-circle"/>
            <span v-if="context[paramKey] != \'true\' && context[paramKey] != \'false\'">{{ context[paramKey] }}</span>
          </template>
          <div v-if="editing" :class="{\'has-error\': !valid}">
            <input
              type="text"
              v-model="valueCopy"
              @keyup.enter="save"
              @keyup.esc="cancel"
              :placeholder="placeholder"
              class="form-control"
              >
          </div>
        </div>
        <div
          class="user-data-edit"
          v-if="isWritable && !editing"
          @click="edit"
          >
          <span class="fa fa-pen"></span>
        </div>
      </div>
    </div>
  '
