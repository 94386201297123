import { createApp } from 'vue_shims'
import { confirm } from 'helpers'

import textIcon from 'images/icn_message_builder_text.svg'
import imageIcon from 'images/icn_message_builder_image.svg'
import videoIcon from 'images/icn_message_builder_video.svg'
import buttonIcon from 'images/icn_message_builder_button.svg'
import cardIcon from 'images/icn_message_builder_card.svg'
import galleryIcon from 'images/icn_message_builder_gallery.svg'
import resultIcon from 'images/icn_message_builder_result.svg'

initPushMessagingEditorApp = (element) ->
  window.state =
    channelTypes: $(element).data('channel-types')
    media: $(element).data('channel-types').map (medium) -> medium.toLowerCase()
    languages: $(element).data('languages')
    currentLanguage: Object.keys($(element).data('languages'))[0]
    contentObjectTypes: $(element).data('content-object-types')
    references: Object.keys($(element).data('content-object-types'))
    projectId: $(element).data('project-id')
    plan: $(element).data('plan')
    type: $(element).data('type')
    infoUrl: (key) -> Routes.game_info_path(key)
    contextParameters: $(element).data('context-parameters')
    computedContextParameters: $(element).data('context-parameters') # this is needed for the context-update component
    intentSignatures: $(element).data('intent-signatures')
    intentSignaturesError: false
    intentSignaturesLoading: false
    reloadIntentSignatures: null
    allTemplates:
      text: textIcon
      image: imageIcon
      video: videoIcon
      button: buttonIcon
      card: cardIcon
      gallery: galleryIcon
      result: resultIcon

  createApp(
    data: ->
      state: state
      messages: $(element).data('messages')
      currentIndex: null
      currentMessage: null
      errorMessage: null

    computed:
      broadcast: ->
        @state.type == 'BROADCAST'
      activation: ->
        @state.type == 'ACTIVATION'

    methods:
      add: ->
        return if @currentMessage?
        @currentMessage =
          name: ''
          status: 'DRAFT'
          message: {}
          sendAt: {}
          # for BROADCAST
          filters: []
          # for ACTIVATION
          trigger: {}
      edit: (index) ->
        @cancel()
        Vue.nextTick =>
          @currentIndex = index
          @currentMessage = ObjectProcessor.clone(@messages[index])
      deleteMessage: (index) ->
        return unless await confirm("Do you really want do delete message “#{@messages[index].name}”?")
        $.ajax(
          Routes.delete_message_project_push_messaging_path(Globals.projectId, Globals.stageLevel, @state.plan.id, push_messaging_message: @messages[index]),
          type: 'DELETE'
          success: =>
            @cancel() if @currentIndex == index
            @messages.splice(index, 1)
        )
      cancel: ->
        @currentIndex = null
        @currentMessage = null
      save: (message) ->
        $.postJSON(
          Routes.save_message_project_push_messaging_path(Globals.projectId, Globals.stageLevel, @state.plan.id)
          push_messaging_message: ObjectProcessor.clone(message)
        ).done((data) =>
          index = @messages.findIndex (m) -> m.id == data.id
          if index >= 0
            Vue.set(@messages, index, data)
          else
            @messages.push(data)
          @cancel()
        ).fail =>
          @errorMessage = 'Sorry, an error occurred. The message could not be saved'
      formattedTrigger: (message) ->
        return '' unless message.trigger?.triggerIntent
        "#{message.trigger.triggerIntentType.toLowerCase()} intent: #{message.trigger.triggerIntent.game}/#{message.trigger.triggerIntent.intent}()"
      formattedSendAt: (message) ->
        switch message.sendAt.mode
          when 'delay'
            "after #{@padded(message.sendAt.hours)}:#{@padded(message.sendAt.minutes)}"
          when 'absolute'
            message.sendAt.timestamp
          when 'relative'
            direction = if message.sendAt.direction == '-' then 'before' else 'after'
            "#{@padded(message.sendAt.days)}d #{@padded(message.sendAt.hours)}:#{@padded(message.sendAt.minutes)} #{direction} #{message.sendAt.contextParameter}"
      formattedFilters: (message) ->
        return [] if !message?.filters
        message.filters.map(@filterLabel)
      filterLabel: (filter) ->
        "#{filter.mode}: #{filter.field.split('.')[1..-1].join('.')} #{filter.operator} #{filter.value}"
      statusLabelClass: (message) ->
        {
          DRAFT: 'label-warning'
          ACTIVE: 'label-success'
          DONE: 'label-default'
        }[message.status]
      padded: (num) ->
        ('00' + num).slice(-2)
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('push-messaging-editor-app')
  initPushMessagingEditorApp(element) if element?
