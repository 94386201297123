
  import Conversation from 'models/conversation.coffee'

  import ConversationDetails from './conversation_details.vue';
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    emits: ['close', 'set-answer-template', 'unset-conversation']

    props:
      conversation: Conversation
      open: Boolean

    components:
      ConversationDetails: ConversationDetails
      Dialog: Dialog
      DialogOverlay: DialogOverlay
      TransitionChild: TransitionChild
      TransitionRoot: TransitionRoot
      XIcon: XIcon
