Vue.component 'support-prompt',
  props: ['prompt']
  mixins: [WizardComponent]
  data: ->
    entity: @prompt
    role: 'mid'
    attributes:
      explanation:
        type: 'NLG'
        default: {}
      question:
        type: 'MultilangString'
        default: {}
    explanationCopy: {}
    questionCopy: {}
  computed:
    fixedMessageCopy: ->
      title: 'The bot will ask for the parameter'
      description: 'If the chatbot needs parameters it will automatically ask for them at this point.'
      message: @questionCopy
      placeholder: 'How should the bot ask?'
  created: ->
    if @prompt.explanation?
      @pristine = false
    else
      Vue.set(@prompt, 'explanation', {})
      Vue.set(@prompt, 'question', {})
    @setCopy()
  methods:
    deepComplete: ->
      @complete && @$refs.answers?.complete
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">If the parameter <i>{{ prompt.contextParameter }}</i> is not set yet, how should the bot ask for it?</h5>
          <nlg-editor :nlg="explanationCopy" :fixed-message="fixedMessageCopy"/>
          <support-confirm-button/>
        </div>
      </div>

      <support-prompt-answers ref="answers" v-if="!pristine" :prompt="prompt" @finished="finished" @discard="discard"/>
    </div>
  '
