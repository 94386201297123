
  import ProjectUser from 'models/project_user.coffee'
  import UserRow from 'components/project_users/user_row.vue'
  import { Button, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      org: Object

    data: ->
      role: null
      teams: null
      editing: false
      userRowRefs: []

    computed:
      displayAddButton: ->
        @$root.mayEditTeam && @org.all_users.length > @org.projectUsers.length

    beforeUpdate: ->
      @userRowRefs = []

    methods:
      add: ->
        @org.projectUsers.push(
          new ProjectUser(
            projectId: @$root.projectId,
            stageLevel: @$root.stageLevel,
            isNew: true,
            organization_id: @org.organization.id,
          )
        )
        @$nextTick =>
          @userRowRefs[-1..][0].edit()
      remove: (projectUser) ->
        @org.projectUsers = @org.projectUsers.filter (u) ->
          u.id != projectUser.id
      setUserRowRef: (el) ->
        @userRowRefs.push(el) if el?

    components:
      Button: Button
      PlusIcon: PlusIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      UserRow: UserRow
