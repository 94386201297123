
  import WebChannel from 'models/web_channel.coffee'
  import { railsEnv } from 'rails_data'

  import WidgetSnippet from './widget_snippet.vue'

  export default
    props:
      channel: WebChannel
      userId: String

    data: ->
      widgetVersion = if railsEnv == 'production' then 'v4' else 'staging'

      srcdoc: """
        <!DOCTYPE html>
        <html>
          <head>
            <link href='https://cdn.mercury.ai/widget/#{widgetVersion}/widget.css' rel='stylesheet'>
            <link rel="stylesheet" type="text/css" href="https://cdn.mercury.ai/channels/widget/#{@channel.nativeId}/style.css">
            <script src='https://cdn.mercury.ai/widget/#{widgetVersion}/widget-main.js' id= 'widget-js'><\/script>
          </head>
          <body>
            <div style="width: 900px; height: 900px;">
              <div id="mercury-widget"></div>
            </div>
            <script>
              let config = {
                apiKey: '#{@channel.nativeId}',
                apiServer: '#{@channel.widgetServer}',
                pollingInterval: 3,
              };
              let user = {userId: '#{@userId}'};
              Mercury.initChatWidget(document.getElementById('mercury-widget'), config, user
              )
            <\/script>
          </body>
        </html>
      """

    computed:
      demoPageUrl: ->
        Routes.widget_path(@channel.nativeId)

    mounted: ->
      @$refs.iframeWrapper.scrollTo(9999, 9999)

    components:
      WidgetSnippet: WidgetSnippet
