import { createApp } from 'vue_shims'
import PlatformApi from 'models/platform_api.coffee'
import WhatsappTemplate from 'models/whatsapp_template.coffee'
import { confirm } from 'helpers'
import TemplateList from 'components/whatsapp_templates/template_list.vue'
import TemplateBuilder from 'components/whatsapp_templates/template_builder.vue'
import { Button, Listbox, PageHeader } from 'components/generic'
import { ChevronRightIcon } from '@heroicons/vue/outline'

initWhatsappTemplatesApp = (element) ->
  createApp(
    data: ->
      action: 'home'
      channels: $(element).data('channels')
      selectedChannel: $(element).data('channels')[0]
      templates: []
      languages: $(element).data('languages')
      currentTemplate: null
      projectIsKim: Globals.projectUuid == '5931a71f-9988-42a9-bfa4-0f5e5d225917'

    created: ->
      if @projectIsKim # KIM 2.0
        $.getJSON Routes.kim_whatsapp_templates_project_path(Globals.projectId, Globals.stageLevel), (templates) =>
          @templates = @channels
            .map (channel) ->
              ObjectProcessor.clone(templates).map (templateData) ->
                new WhatsappTemplate(
                  Object.assign({channelId: channel.uuid}, templateData)
                )
            .flat()
        return
      @channels.forEach (channel) =>
        PlatformApi.WaTemplates.getTemplates(channel.uuid).then (data) =>
          @templates = @templates.concat(data.map (templateData) ->
            new WhatsappTemplate(
              Object.assign({channelId: channel.uuid}, templateData)
            )
          )

    methods:
      channelLabel: (channel) ->
        "#{channel.name} | #{channel.phone_number} | #{channel.stage_label}"
      buildTemplate: ->
        @currentTemplate = new WhatsappTemplate(
          channelId: @selectedChannel.uuid
          components: [type: 'BODY', text: '']
        )
        @action = 'builder'
      editTemplate: (template) ->
        @currentTemplate = template
        @action = 'builder'
      cancelTemplate: ->
        return unless await confirm('Do you really want to discard this template?')
        @action = 'home'
      goHome: ->
        @action = 'home'

    components:
      Button: Button
      ChevronRightIcon: ChevronRightIcon
      Listbox: Listbox
      PageHeader: PageHeader
      TemplateBuilder: TemplateBuilder
      TemplateList: TemplateList

    template: '
      <div class="sm:px-6 lg:px-8">
        <PageHeader title="WhatsApp promotion templates">
          <template v-if="action == \'home\' && !projectIsKim" #cta>
            <Listbox
              label="Select WhatsApp number"
              v-model="selectedChannel"
              idAttribute="uuid"
              :collection="channels"
              :name-function="channelLabel"
              class="mr-4"
            />

            <Button type="primary" @click="buildTemplate">
              <ChevronRightIcon class="h-5 w-5 -ml-1 mr-1"/>
              Create new template
            </Button>
          </template>
        </PageHeader>

        <TemplateList v-if="action == \'home\'"/>

        <TemplateBuilder v-if="action == \'builder\'" :template="currentTemplate"/>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('whatsapp-templates-app')
      initWhatsappTemplatesApp(element)
