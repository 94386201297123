import { createApp } from 'vue_shims'
import { Alert, Button, Modal } from 'components/generic'

initConfirmApp = (element, message, options, resolve) ->
  createApp(
    data: ->
      message: message
      alert: options.alert
      confirmed: false

    mounted: ->
      @$refs.modal.open()
      document.addEventListener 'keyup', @keyHandler

    methods:
      ok: ->
        @confirmed = true
        @$refs.modal.close()
      cancel: ->
        @$refs.modal.close()
      remove: ->
        resolve(@confirmed)
        element.remove()
        document.removeEventListener 'keyup', @keyHandler
      keyHandler: (e) ->
        return if e.key != 'Enter'
        @ok()

    components:
      Alert: Alert
      Button: Button
      Modal: Modal

    template: '
      <Modal ref="modal" @closed="remove">
        <template #body>
          <Alert v-if="alert" :color="alert" class="whitespace-pre-wrap">
            {{ message }}
          </Alert>
          <div v-else class="whitespace-pre-wrap">
            {{ message }}
          </div>
        </template>
        <template #buttons>
          <Button @click="ok" type="primary" class="ml-3">Ok</Button>
          <Button @click="cancel">Cancel</Button>
        </template>
      </Modal>
    '
  )
    .mount(element)

export default (message, options = {}) =>
  new Promise (resolve, reject) =>
    element = document.createElement('div')
    document.body.appendChild(element)
    initConfirmApp(element, message, options, resolve)
