export default class EdgeVariable
  constructor: (data = {}) ->
    @reference = data.reference # for use in property expressions
    @key = data.key # name

  Object.defineProperties @prototype,
    computedCategory:
      get: ->
        'edge'
    label:
      get: ->
        @key
