Vue.component 'headers-editor',
  props: ['headers', 'label']
  methods:
    addHeader: ->
      @headers.push(key: '', value: '')
      Vue.nextTick =>
        $(@$el).find('.item:last input').first().focus()
    removeHeader: (index) ->
      @headers.splice(index, 1)
  template: '
    <div>
      <div v-for="(header, index) in headers" class="flex-container center-items sub-form-group item">
        <input v-model="header.key" type="text" class="form-control width-30">:&nbsp;
        <input v-model="header.value" type="text" class="form-control expand">
        <div v-on:click="removeHeader(index)" class="btn btn-remove">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>
      <plus-button label="add header" :method="addHeader"/>
    </div>
  '
