import { createApp } from 'vue_shims'

import Root from 'components/project_home/root.vue'

initProjectHomeApp = (element) ->
  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data').replaceAll('&#39;', "'"))
      project: elementData.project
      infoTiles: elementData.info_tiles

    components:
      Root: Root

    template: '<Root/>'
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('project-home-app')
      initProjectHomeApp(element)
