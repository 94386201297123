
  import { Button, Listbox, ParameterFiltering } from 'components/generic'
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Array

    data: ->
      value: @modelValue

    computed:
      nextStepPossible: ->
        @$parent.steps[0].completed

    watch:
      modelValue: -> @value = @modelValue
      value: -> @$emit('update:modelValue', @value)

    methods:
      nextStep: ->
        return unless @nextStepPossible
        @$emit('next')

    components:
      Button: Button
      ChevronRightIcon: ChevronRightIcon
      ParameterFiltering: ParameterFiltering
