
  import { Button } from 'components/generic'
  import { CheckIcon, ExclamationIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      text: String
      mode: String

    computed:
      classes: ->
        switch @mode
          when 'FAILED'
            'bg-MercuryRed-500 text-white'
          else
            'bg-gray-50 text-gray-700'

    components:
      Button: Button
      CheckIcon: CheckIcon
      ExclamationIcon: ExclamationIcon
      RefreshIcon: RefreshIcon
