
  import PlatformApi from 'models/platform_api.coffee'
  import { confirm } from 'helpers'
  import WhatsappPromotionJob from 'models/whatsapp_promotion_job.coffee'
  import AudienceDetails from './audience_details.vue'
  import AudienceFiltering from './audience_filtering.vue'
  import AudienceList from './audience_list.vue'
  import PromotionPreviewSend from './promotion_preview_send.vue'
  import ProgressBar from './progress_bar.vue'
  import PromotionSent from './promotion_sent.vue'
  import TemplateInstantiation from './template_instantiation.vue'

  export default
    props:
      step: Number
      givenJob: WhatsappPromotionJob

    data: ->
      steps: [
        { name: 'Select Audience', completed: true }
        { name: 'Create Message', completed: true }
        { name: 'Preview and send', completed: true }
      ]
      currentStep: @step || 0
      job: {status: 'UPDATING'}
      audience:
        size: { name: 'Audience Size', value: '0' }
        cost: { name: 'Estimated Cost', value: '0.00 €' }
        users: []
      statusTimeout: null
      stopPolling: false
      filters: []
      selectedTemplate: null

    watch:
      filters: ->
        @audience =
          size: { name: 'Audience Size', value: '0' }
          cost: { name: 'Estimated Cost', value: '0.00 €' }
          users: []
        return unless @job.id?
        @job.status = 'UPDATING'
        PlatformApi.WaTemplates.setFilters(@job.id, @filters)
          .then => @getStats()

    created: ->
      @$root.loadContextParameters()
      if @givenJob?
        @job = @givenJob
        @audience.size.value = @job.statistics.audienceSize
        @audience.cost.value = @job.estimatedCostFormatted
        @selectedTemplate = @$root.templates.find (template) =>
          template.id? && @job.config.templateId == template.id || @job.config.templateName == template.name && @job.config.templateNamespace == template.namespace
        @pollSendingStatus()
      else
        PlatformApi.WaTemplates.createJob(@$root.selectedChannel.uuid)
          .then (data) =>
            @job = new WhatsappPromotionJob(data)
            @selectedTemplate = @$root.templates[0]
            @setTemplate(@selectedTemplate)
            @getStats()

    methods:
      previousStep: ->
        @currentStep -= 1
      cancel: ->
        if @givenJob? || @job.sent || @job.status == 'SENDING'
          @cancelPolling()
          @$root.action = 'home'
        else
          return unless await confirm('Do you really want to discard this promotion?')
          @cancelPolling()
          PlatformApi.WaTemplates.deleteJob(@job.id)
            .then => @$root.action = 'home'
      getStats: ->
        PlatformApi.WaTemplates.getStats(@job.id).then (data) =>
          @job.update(status: data.status, statistics: data.statistics)
          @audience.size.value = @job.statistics.audienceSize
          @audience.cost.value = @job.estimatedCostFormatted
          PlatformApi.WaTemplates.getAudience(@job.id).then (data) =>
            @audience.users = data.audience
      setTemplate: (template) ->
        @selectedTemplate = template
        return unless @selectedTemplate?
        PlatformApi.WaTemplates.setTemplate(@job.id, @selectedTemplate)
      nextStep: ->
        @currentStep += 1
      setParams: (parameters) ->
        PlatformApi.WaTemplates.setParams(@job.id, parameters)
          .then (data) => @job.update(parameters: data.parameters)
        @currentStep += 1
      send: ->
        @job.status = 'SENDING'
        @currentStep += 1
        PlatformApi.WaTemplates.sendPromotionMessage(@job.id)
          .then => @pollSendingStatus()
          .catch => @job.status = 'FAILED'
      pollSendingStatus: ->
        PlatformApi.WaTemplates.getStats(@job.id).then (data) =>
          @job.update(status: data.status, statistics: data.statistics, messages: data.messages)
          return if @job.status == 'FAILED' || @stopPolling
          @statusTimeout = setTimeout(@pollSendingStatus, 2000)
      cancelPolling: ->
        clearTimeout(@statusTimeout)
        @stopPolling = true

    components:
      AudienceDetails: AudienceDetails
      AudienceFiltering: AudienceFiltering
      AudienceList: AudienceList
      PromotionPreviewSend: PromotionPreviewSend
      ProgressBar: ProgressBar
      PromotionSent: PromotionSent
      TemplateInstantiation: TemplateInstantiation
