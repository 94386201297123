import Message from 'models/message.coffee'

export default class MessageVariation
  constructor: (@languageNlg, data = {}) ->
    data ||= {}
    @medium = (data.medium || 'web').toLowerCase()
    @messages = (data.messages|| []).map (messageData) => new Message(@, messageData)

  addTemplateMessage: (index, templateType) ->
    if templateType == 'delay'
      return if !@messages[index]?
      @messages[index].delay = 500
    else
      newMessage = Message.template(@, templateType)
      newMessage.pristine = true
      @messages.splice(index, 0, newMessage)

  Object.defineProperties @prototype,
    export:
      get: ->
        messages: @messages.map (message) -> message.export
    buttons:
      get: ->
        @messages.map((message) -> message.buttons).flat()
