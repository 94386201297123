import PlatformApi2 from 'models/platform_api2.coffee'
import Status from 'models/status.coffee'

export default class Channel
  @TYPES = [
    {key: 'WEB', label: 'Web chat'}
    {key: 'WHATSAPP', label: 'WhatsApp'}
    {key: 'FACEBOOK', label: 'Facebook'}
    {key: 'INSTAGRAM', label: 'Instagram'}
  ]

  @typed: (data) ->
    channelClass = switch data.type
      when 'FACEBOOK'
        require('./facebook_channel.coffee')
      when 'INSTAGRAM'
        require('./instagram_channel.coffee')
      when 'PHONE'
        require('./phone_channel.coffee')
      when 'WEB'
        require('./web_channel.coffee')
      when 'WHATSAPP'
        require('./whatsapp_channel.coffee')
    new channelClass.default(data)

  constructor: (data = {}) ->
    @enabled = true
    @optInRequired = false
    @status = new Status(@)
    @contextUpdates = {}
    @update(data)

  update: (data = {}) ->
    @id = data.id
    @nativeId = data.nativeId
    @type = data.type
    @subtype = data.subtype
    @environment = data.environment
    @stageId = data.stageId # the stage the channel is connected to
    @label = data.label
    @description = data.description
    @enabled = data.enabled if data.enabled?
    @retired = data.retired
    @connected = data.connected
    @optInRequired = data.optInRequired if data.optInRequired?
    @defaultLanguage = data.defaultLanguage
    @contextUpdates = data.contextUpdates if data.contextUpdates?
    @status = new Status(@, data.status) if data.status?

  loadConfig: ->
    PlatformApi2.getChannelConfig(@)
      .then (data) =>
        @setConfig(data)
        return @
      .catch =>
        @setConfig()
        return @

  # should be overridden in child class
  setConfig: (data) ->
    null

  save: ->
    if @id?
      PlatformApi2.updateChannel(@)
    else
      PlatformApi2.createChannel(@).then (channelData) => @update(channelData)

  saveConfig: ->
    PlatformApi2.updateChannelConfig(@)

  delete: ->
    PlatformApi2.deleteChannel(@)

  connect: (credentials = {}) ->
    PlatformApi2.connectChannel(@, credentials)
      .then (channelData) => @update(channelData)

  Object.defineProperties @prototype,
    typeLabel:
      get: ->
        typeConfig = Channel.TYPES.find (type) => type.key == @type
        typeConfig?.label || @type
    export:
      get: ->
        id: @id
        nativeId: @nativeId
        type: @type.toUpperCase()
        subtype: @subtype
        stageId: @stageId
        environment: @environment
        label: @label
        description: @description
        enabled: @enabled
        retired: @retired
        optInRequired: @optInRequired
        defaultLanguage: @defaultLanguage
        contextUpdates: @contextUpdates
