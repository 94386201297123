import { createApp } from 'vue_shims'
import { timeoutMessage } from 'rails_data'
import { alert } from 'helpers'
import { Button, Modal } from 'components/generic'
import { ExclamationIcon } from '@heroicons/vue/outline'

initTimeoutTrackerApp = (element) ->
  createApp(
    data: ->
      turbolinksPermanentSelector: 'timeout-tracker-app'
      maxSessionDuration: element.getAttribute('data-timeout-in')
      remaining: element.getAttribute('data-timeout-in')

    watch:
      remaining: ->
        return if @remaining != 60
        @$refs.warningModal.open()

    created: ->
      @startTime = new Date().getTime()
      @interval = setInterval(@update, 1000)
      # handle AJAX requests
      window.addEventListener 'ajaxComplete', @handleResponse
      window.addEventListener 'fetchComplete', @handleResponse
      # keep session alive even if the user interacts only with the FE
      $(window).on('click.timeout-tracker keyup.timeout-tracker', @throttledServerReset)

    unmounted: ->
      clearInterval(@interval)
      $(window).off('click.timeout-tracker keyup.timeout-tracker')

    methods:
      handleResponse: (e) ->
        if e.detail.status == 401 && e.detail.response.indexOf(timeoutMessage) >= 0
          @redirect()
        else if e.detail.url.indexOf(Routes.status_jobs_path()) >= 0 # don't reset when polling job status
          null
        else
          @reset()
      reset: ->
        @startTime = new Date().getTime()
        @update()
      serverReset: ->
        $.get Routes.ping_path()
      throttledServerReset: ->
        @serverReset() if @remaining <= @maxSessionDuration - 60
      update: ->
        @remaining = Math.round((@startTime + (@maxSessionDuration * 1000) - new Date().getTime()) / 1000)
        if @remaining == 0
          @redirect()
      redirect: ->
        clearInterval(@interval)
        @$refs.warningModal.close()
        Vue.nextTick =>
          await alert(timeoutMessage)
          window.location = Routes.new_user_session_path(redirect_to: window.location.pathname)

    components:
      Button: Button
      ExclamationIcon: ExclamationIcon
      Modal: Modal

    template: '
      <div>
        <Modal ref="warningModal" title="Session timeout" @close="serverReset">
          <template #body>
            <div class="sm:flex sm:items-center">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-MercuryRed-100 sm:mr-4 sm:h-10 sm:w-10">
                <ExclamationIcon class="h-6 w-6 text-MercuryRed-600" aria-hidden="true" />
              </div>
              <div class="mt-3 sm:mt-0 w-full sm:text-base">
                Are you still active? We are about to log you out.
              </div>
            </div>
          </template>

          <template #buttons="{ close }">
            <Button type="primary" @click="close">Still active!</Button>
          </template>
        </Modal>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('timeout-tracker-app')
      initTimeoutTrackerApp(element)
