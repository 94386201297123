
  import DialogNode from 'models/dialog_node'

  import { Badge, Button, Modal } from 'components/generic'
  import { ChevronDoubleDownIcon, ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      message: Object
      expanded: Boolean
      togglable:
        type: Boolean
        default: true
      nodeLinks:
        type: Boolean
        default: true

    data:
      contextIndex: 0

    computed:
      context: ->
        i = 0
        ctx = JSON.parse(JSON.stringify(@message.contextBefore || {}))
        until i == @contextIndex
          Object.assign(ctx, (@message.steps[i].contextUpdates || {}))
          i++
        ctx

    methods:
      openContextModal: (index) ->
        @contextIndex = index
        @$refs.contextModal.open()
      clickable: (step) ->
        return false if !@nodeLinks
        DialogNode.TYPES.includes(step.resourceType)
      goToNode: (step) ->
        return if !@clickable(step)
        if step.moduleKey == @$route.params.moduleKey
          @$emit('set-node', step.nodeKey)
        else
          @$router.push(name: 'module', params: {moduleKey: step.moduleKey}, hash: '#' + step.nodeKey)
      dotColor: (step) ->
        switch step?.resourceType
          when 'BotIntent' then 'text-MercuryGreen-600'
          when 'DialogAction' then 'text-MercuryYellow-600'
          when 'TriggerIntent' then 'text-MagentaViolet-600'
          when 'UserIntent' then 'text-MercuryBlue-600'

    components:
      Badge: Badge
      Button: Button
      ChevronDoubleDownIcon: ChevronDoubleDownIcon
      ChevronDownIcon: ChevronDownIcon
      ChevronUpIcon: ChevronUpIcon
      InformationCircleIcon: InformationCircleIcon
      Modal: Modal
