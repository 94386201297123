
  export default
    props:
      to: Object
      method: Function
      colspan:
        type: Number
        default: 1
      wrap:
        type: Boolean
        default: false

    computed:
      hasLink: ->
        @to? || @method?
