import { confirm } from 'helpers'

Vue.component 'nlg-variation-editor',
  props: [
    'variation', 'index', 'count', 'medium'
    'autogeneratedQuickrepliesEnabled', 'inSearchAttribute', 'fixedMessage'
  ]

  data: ->
    state: state
    messageEditorRefs: []

  computed:
    fixedMessageValid: ->
      @validForCurrentLanguage(@fixedMessage.message) &&
        (!@fixedMessage.buttons? || Object.values(@fixedMessage.buttons).every((btn) => @validForCurrentLanguage(btn)))

  watch:
    variation:
      handler: -> @validate()
      deep: true
    fixedMessage:
      handler: -> @validate()
      deep: true

  created: ->
    Vue.setDefault(@variation, 'messages', [])
    Vue.setDefault(@variation, 'callbacks', [])
    for message in @variation.messages
      Vue.setDefault(message, '$key', GuidGenerator.newGuid())
    @validate()

  beforeUpdate: ->
    @messageEditorRefs = []

  methods:
    validate: ->
      Vue.set @variation, '$valid',
        GameValidator.allValid(@variation.messages) &&
        GameValidator.allValid(@variation.callbacks) &&
        (
          !@fixedMessage? ||
            @fixedMessage.message[@state.currentLanguage]?.length &&
            (
              !@fixedMessage.buttons ||
                Object.values(@fixedMessage.buttons).every((button) => button[@state.currentLanguage]?.length)
            )
        )
    validForCurrentLanguage: (multilangString) ->
      multilangString[@state.currentLanguage]
    addTemplate: (template) ->
      @variation.messages.push({})
      Vue.nextTick =>
        @messageEditorRefs[-1..][0].setTemplate(template)
        # scroll to new message (only after it has been expanded):
        Vue.nextTick =>
          $messageElement = $(@$el).find('.message').last()
          $(@$el).closest('.variation-slide').scrollTop($messageElement.position().top)
          $messageElement.find('.message-input').first().focus()
    removeMessage: (index) ->
      @variation.messages.splice(index, 1)
    pushUpMessage: (index) ->
      return if index == 0
      @variation.messages.splice(index - 1, 0, @variation.messages.splice(index, 1)[0])
    pushDownMessage: (index) ->
      return if index == @variation.messages.length - 1
      @variation.messages.splice(index + 1, 0, @variation.messages.splice(index, 1)[0])
    cloneMessage: (index) ->
      @variation.messages.splice(index, 0, ObjectProcessor.clone(@variation.messages[index]))
    defaultMessage: (delay = 0) ->
      type: 'Bubble'
      attachments: []
      delay: delay
    remove: ->
      return unless await confirm('Do you really want delete this variation?')
      @$emit('remove-variation', @index)
    setMessageEditorRef: (el) ->
      @messageEditorRefs.push(el) if el?

  template: '
    <div class="variation-slide">
      <div :class="{invisible: variation.$valid}" class="validation-warning text-danger text-small">
        This variation contains incomplete messages!
      </div>
      <div v-if="variation.messages.length == 0" class="message">
        <div class="message-container message-placeholder">
          Add a message by choosing a template on the left
        </div>
      </div>
      <message-editor
        v-for="(message, index) in variation.messages"
        :key="message.$key"
        :message="message"
        :index="index"
        :count="variation.messages.length"
        :medium="medium"
        :autogenerated-quickreplies-enabled="autogeneratedQuickrepliesEnabled"
        :in-search-attribute="inSearchAttribute"
        :ref="setMessageEditorRef"
        @remove-message="removeMessage"
        @push-up-message="pushUpMessage"
        @push-down-message="pushDownMessage"
        @clone-message="cloneMessage"
        />
      <div class="panel-body">
        <label>Expects global intent as answer</label>
        <callbacks-editor :callbacks="variation.callbacks"/>
      </div>
      <div v-if="fixedMessage" class="panel-body fixed-message">
        <label v-show="fixedMessage.title" class="block text-center">{{ fixedMessage.title }}</label>
        <p v-show="fixedMessage.description" class="text-center">{{ fixedMessage.description }}</p>
        <message-delay v-if="fixedMessage.delay != null" v-model="fixedMessage.delay"/>
        <div class="fixed-message-panel" :class="{\'has-error\': !fixedMessageValid}">
          <inline-edit
            v-model:string="fixedMessage.message[state.currentLanguage]"
            :display-string="fixedMessage.placeholder"
            :no-initial-edit="true"
            :wrapper-class="{\'center-content\': true, \'has-error\': !validForCurrentLanguage(fixedMessage.message)}"
            />
          <div v-if="fixedMessage.buttons" class="flex-container center-content">
            <template v-for="button, key in fixedMessage.buttons">
              <div class="btn btn-default">
                <inline-edit
                  v-model:string="button[state.currentLanguage]"
                  :display-string="(fixedMessage.buttonPlaceholders || {})[key]"
                  :no-initial-edit="true"
                  :wrapper-class="{\'center-content\': true, \'has-error\': !validForCurrentLanguage(button)}"
                  />
              </div>&nbsp;
            </template>
          </div>
        </div>
      </div>
      <div v-if="count > 1" class="panel-body non-first">
        <div class="btn btn-caution pull-right" @click="remove()">Remove variation</div>
      </div>
    </div>
  '
