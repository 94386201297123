<template>
  <Disclosure v-if="legacyMenu" as="nav" class="bg-BlackOlive fixed inset-x-0 z-30" v-slot="{ open }">
    <div class=" px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">

        <div class="tw-hidden md:flex items-center w-1/2">
          <a class="w-1/4" :href="projectHomeUrl">
            <div style="width: 212px"></div>
            <InlineSvg v-show="status != 'running' && status != 'failed'" class="w-full pointer-events-none" type="image/svg+xml" :src="logos.static"/>
            <AnimatedLogo v-show="status == 'running'" class="w-full pointer-events-none"/>
            <InlineSvg v-show="status == 'failed'" class="w-full pointer-events-none" type="image/svg+xml" :src="logos.error"/>
          </a>
          <div class="ml-4 xl:ml-10 flex items-baseline space-x-2">
            <SubMenu v-for="menuItem in menu" :key="menuItem.label" :menu-item="menuItem"/>
          </div>
        </div>

        <div class="w-3/4 sm:w-1/2 md:w-1/4">
          <div class="pr-2 py-2 mr-4">
            <LegacyProjectStageDropdown :projects="projects"/>
          </div>
        </div>

        <div class="flex items-baseline">
          <ActiveToggle v-if="checkedIn != null" v-model="checkedIn"/>
        </div>

        <div class="flex items-end mx-2 items-baseline space-x-4">
          <!--
          <div class="xl:pr-4 py-2">
            <Notifications />
          </div>
          -->
          <div class="tw-hidden sm:block py-2">
            <ProfileDropdown />
          </div>
        </div>

        <div class="hidden -mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="hidden md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <template v-for="(item, itemIdx) in navigation" :key="item">
          <template v-if="(itemIdx === 0)">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-light">{{ item }}</a>
          </template>
          <a v-else href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-light">{{ item }}</a>
        </template>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="coffee">
  import { Disclosure, DisclosureButton, DisclosurePanel, } from '@headlessui/vue'
  import InlineSvg from 'vue-inline-svg'
  import {
    AdjustmentsIcon, AtSymbolIcon,
    BookOpenIcon,
    ChatAlt2Icon, ChatIcon, ClipboardListIcon, ClockIcon,
    DatabaseIcon, DocumentIcon,
    FastForwardIcon,
    IdentificationIcon,
    LibraryIcon, LightningBoltIcon,
    MailIcon, MenuIcon,
    PresentationChartLineIcon, PuzzleIcon,
    ShareIcon, ShieldExclamationIcon,
    TemplateIcon, TerminalIcon,
    UserGroupIcon,
    XIcon,
  } from '@heroicons/vue/outline'
  import {
    AnimatedLogo,
    LegacyProjectStageDropdown,
    ProfileDropdown,
    Notifications,
    ActiveToggle,
    SubMenu,
  } from '.'
  import staticLogo from 'images/logo_main_menu.svg'
  import errorLogo from 'images/logo_main_menu_red.svg'

  projectMenu = (projectId, stageLevel, display) ->
    return [] if !projectId?
    stageLevel = 'live' if !stageLevel
    menu = [
      {
        label: 'Messaging',
        current: false,
        children: [
          label: 'Inbox',
          description: '',
          href: Routes.inbox2_project_path(projectId, stageLevel),
          icon: ChatAlt2Icon,
          display: display.inbox,
        ,
          label: 'WhatsApp templates',
          description: '',
          href: Routes.whatsapp_templates_project_path(projectId, stageLevel),
          icon: ChatIcon,
          display: display.whatsapp_templates,
        ,
          label: 'WhatsApp promotions',
          description: '',
          href: Routes.whatsapp_promotions_project_path(projectId, stageLevel),
          icon: ChatIcon,
          display: display.whatsapp_promotions,
        ,
          label: 'Live-chat teams',
          description: '',
          href: Routes.project_agent_teams_path(projectId, stageLevel),
          icon: UserGroupIcon,
          display: display.agent_teams,
        ,
          label: 'Live-chat routing',
          description: '',
          href: Routes.handover_tags_project_path(projectId, stageLevel),
          icon: AtSymbolIcon,
          display: display.handover_tags,
        ,
          label: 'Live-chat templates',
          description: '',
          href: Routes.project_answer_templates_path(projectId, stageLevel),
          icon: DocumentIcon,
          display: display.answer_templates,
        ]
      },
      {
        label: 'Conversational AI',
        current: false,
        children: [
          label: 'Dialog creator 2',
          description: '',
          href: Routes.automation_project_path(projectId, stageLevel),
          icon: ChatAlt2Icon,
          display: display.creator2,
        ,
          label: 'Dialog creator',
          description: '',
          href: Routes.project_bots_path(projectId, stageLevel),
          icon: ChatAlt2Icon,
          display: display.creator,
        ,
          label: 'Library',
          description: '',
          href: Routes.project_library_games_path(projectId, stageLevel),
          icon: LibraryIcon,
          display: display.library,
        ,
          label: 'NLP debugger',
          description: '',
          href: Routes.nlp_debugger_project_path(projectId, stageLevel),
          icon: TerminalIcon,
          display: display.debugger,
        ,
          label: 'Training data',
          description: '',
          href: Routes.training_data_project_path(projectId, stageLevel),
          icon: DatabaseIcon,
          display: display.training_data,
        ,
          label: 'Dialog tests',
          description: '',
          href: Routes.unit_testing_project_path(projectId, stageLevel),
          icon: ShieldExclamationIcon,
          display: display.unit_testing,
        ,
          label: 'Model history',
          description: '',
          href: Routes.model_history_project_path(projectId, stageLevel),
          icon: ClockIcon,
          display: display.history,
        ,
          label: 'Triggered messages',
          description: '',
          href: Routes.push_messaging_project_path(projectId, stageLevel),
          icon: MailIcon,
          display: display.push_messaging,
        ,
          label: 'Content lexicon',
          description: '',
          href: Routes.content_project_path(projectId, stageLevel),
          icon: BookOpenIcon,
          display: display.content,
        ]
      },
      {
        label: 'Analytics',
        current: false,
        children: [
          label: 'Analytics dashboard',
          description: '',
          href: Routes.analytics_project_path(projectId, stageLevel),
          icon: PresentationChartLineIcon,
          display: display.analytics,
        ,
          label: 'User data',
          description: '',
          href: Routes.project_bot_users_path(projectId, stageLevel),
          icon: IdentificationIcon,
          display: display.bot_users,
        ]
      },
      {
        label: 'Project settings',
        current: false,
        children: [
          label: 'Settings',
          description: '',
          href: Routes.edit_project_path(projectId, stageLevel),
          icon: AdjustmentsIcon,
          display: display.settings,
        ,
          label: 'Environment',
          description: '',
          href: Routes.stages_project_path(projectId, stageLevel),
          icon: TemplateIcon,
          display: display.stages,
        ,
          label: 'Channels',
          description: '',
          href: Routes.project_channels_path(projectId, stageLevel),
          icon: ShareIcon,
          display: display.channels,
        ,
          label: 'Integrations',
          description: '',
          href: Routes.integrations_project_path(projectId, stageLevel),
          icon: PuzzleIcon,
          display: display.integrations,
        ,
          label: 'Project team',
          description: '',
          href: Routes.project_users_path(projectId, stageLevel),
          icon: UserGroupIcon,
          display: display.project_users,
        ]
      }
    ]
    menu.forEach (topLevelItem) =>
      topLevelItem.children.forEach (item) =>
        item.active = true if window.location.pathname.match("^#{item.href}")
    menu

  profileMenu = (display) ->
    [
      label: 'Profile'
      url: Routes.profile_path()
      display: true,
    ,
      label: 'Account settings'
      url: Routes.organization_path(Globals.currentUser.organizationId)
      display: display.organization_settings,
    ,
      label: 'Customer service'
      url: Routes.service_organizations_path()
      display: display.service,
    ,
      label: 'Administration'
      url: Routes.rails_admin_path()
      display: display.admin,
    ,
      label: 'Sign out'
      url: Routes.destroy_user_session_path(id: Globals.currentUser.id, _options: true)
      rel: 'nofollow'
      dataMethod: 'delete'
      display: true,
    ]

  export default
    data: ->
      turbolinksPermanentSelector: 'legacy-main-menu-app'
      legacyMenu: null
      menu: []
      profileMenu: []
      projects: []
      currentProject: {}
      status: 'none'
      mayCreateProject: false
      checkedIn: null
      projectHomeUrl: Routes.root_path()
      logos:
        static: staticLogo
        error: errorLogo

    created: ->
      @setMenu()
      $(window).on 'turbolinks:load', @setMenu
      @pollJobs()

    methods:
      setMenu: ->
        @legacyMenu = $('#main-menu-data').data('legacy-menu')
        menuItemsDisplay = $('#main-menu-data').data('menu-items-display')
        @projects = $('#main-menu-data').data('projects')
        @menu = projectMenu(Globals.projectId, Globals.stageLevel, menuItemsDisplay)
        @profileMenu = profileMenu(menuItemsDisplay)
        @currentProject = if Globals.projectId?
          @projects.find (proj) -> proj.id == Globals.projectId
        else
          {}
        @mayCreateProject = $('#main-menu-data').data('may-create-project')
        @checkedIn = $('#main-menu-data').data('checked-in')
        @projectHomeUrl = if Globals.projectId && Globals.stageLevel
          Routes.project_path(Globals.projectId, Globals.stageLevel)
        else
          Routes.root_path()
      pollJobs: ->
        if Globals.currentUser && Globals.currentUser.may('create_bots', Globals.project)
          # short-circuit if this menu is inactive
          if !@legacyMenu
            setTimeout(@pollJobs, 6000)
            return
          url = if Globals.projectId
            Routes.status_jobs_path(project_id: Globals.projectId)
          else
            Routes.status_jobs_path()
          fetch(
            url
            method: 'GET'
            headers:
              'X-Requested-With': 'XMLHttpRequest'
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
              'Content-Type': 'application/json'
              'Accept': 'application/json'
          )
            .then (response) -> response.json()
            .then (data) => @status = data.status
            .finally => setTimeout(@pollJobs, 6000)

    components:
      AnimatedLogo: AnimatedLogo,
      Disclosure: Disclosure,
      DisclosureButton: DisclosureButton,
      DisclosurePanel: DisclosurePanel,
      InlineSvg: InlineSvg,
      MenuIcon: MenuIcon,
      XIcon: XIcon,
      LegacyProjectStageDropdown: LegacyProjectStageDropdown,
      ProfileDropdown: ProfileDropdown,
      Notifications: Notifications,
      ActiveToggle: ActiveToggle,
      SubMenu: SubMenu,
</script>
