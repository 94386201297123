Vue.component 'privacy-game',
  props: ['config']
  data: ->
    state: state
    attributes:
      legalMessage:
        label: 'Legal message text'
        placeholder:
          de: 'Hier findest du unser Impressum'
      legalUrl:
        label: 'Link to legal page'
        addSpaceAfter: true
        placeholder: 'URL'
      privacyMessage:
        label: 'Privacy message text'
        placeholder:
          de: 'Mehr zum Datenschutz erfährst du hier'
      privacyUrl:
        label: 'Link to privacy page'
        addSpaceAfter: true
        placeholder: 'URL'
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(Object.keys(@attributes).concat('goodbye'))
      deep: true
      immediate: true
  created: ->
    for attribute of @attributes
      Vue.setDefault(@config, attribute, {})
    Vue.setDefault(@config, 'goodbye', {})
  methods:
    placeholderFor: (attribute) ->
      if typeof @attributes[attribute].placeholder == 'string'
        @attributes[attribute].placeholder
      else
        @attributes[attribute].placeholder[@state.currentLanguage]
  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Legal and privacy information</h3>
      </div>

      <div class="panel-body">
        <div v-for="(attributeConfig, attribute) in attributes" class="form-group">
          <label class="non-bold">{{ attributeConfig.label }}</label>
          <multilang-field
            :dictionary="config[attribute]"
            :placeholder="placeholderFor(attribute)"
            class="form-control"
            />
          <br v-if="attributeConfig.addSpaceAfter">
        </div>

        <br>

        <panel-togglable parent-selector="#messages-accordion">
          <template v-slot:togglingheader>
            <div class="expand">
              Goodbye message
            </div>
            <div class="check">
              <span class="fa fa-exclamation-circle text-danger" v-if="!config.goodbye.$valid"></span>
            </div>
          </template>
          <template v-slot:body>
            <nlg-editor :nlg="config.goodbye"/>
          </template>
        </panel-togglable>
      </div>
    </div>
  '
