<template>
<div class="flex">
  <button class="bg-OliveBlack p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
    <span class="sr-only">View notifications</span>
    <BellIcon class="h-5 xl:h-6 w-5 xl:w-6" aria-hidden="true" />
  </button>
</div>
</template>

<script>
import { BellIcon, } from '@heroicons/vue/outline'

export default {
  name: "Notifications",
  components: {
    BellIcon,
  },
  setup() {
    return {
    }
  },
}
</script>