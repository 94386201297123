
  import ContextParameter from 'models/context_parameter.coffee'

  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import { Button } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: ContextParameter

    data: ->
      contextParameter: @modelValue
      expectedValueInputRefs: []
      PlusIcon: PlusIcon

    beforeUpdate: ->
      @expectedValueInputRefs = []

    methods:
      addExpectedValue: ->
        @contextParameter.expectedValues.push('')
        @$nextTick =>
          @expectedValueInputRefs.last.focus()
      setExpectedValueInputRef: (el) ->
        @expectedValueInputRefs.push(el) if el?
      remove: (index) ->
        @contextParameter.expectedValues.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @contextParameter)

    components:
      Button: Button
      DataTypeDependentInput: DataTypeDependentInput
      XIcon: XIcon
