import { createApp } from 'vue_shims'

import AgentTeam from 'models/agent_team'
import HandoverTag from 'models/handover_tag'
import PlatformApi from 'models/platform_api.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'

import HandoverTagComponent from 'components/handover_tags/tag.vue'
import { Button, PageHeader } from 'components/generic'
import { PlusIcon } from '@heroicons/vue/solid'

initHandoverTagsApp = (element) ->
  createApp(
    data: ->
      agentTeams: $(element).data('agent-teams').map (data) -> new AgentTeam(data)
      tags: $(element).data('handover-tags').map (tagData) -> new HandoverTag(tagData)
      moduleNames: []
      contextParameters: []
      colors: [
        '#dc2626'
        '#d97706'
        '#059669'
        '#2563eb'
        '#4f46e5'
        '#7c3aed'
        '#db2777'
        # '#991b1b'
        # '#92400e'
        # '#065f46'
        # '#1e40af'
        # '#3730a3'
        # '#5b21b6'
        # '#9d174d'
      ]
      tagRefs: []

    computed:
      nextColor: ->
        colorCounts = @colors.map (color) =>
          @tags.reduce((count, tag) =>
            if tag.color == color then count + 1 else count
          , 0)
        minCount = Math.min(...colorCounts)
        index = colorCounts.findIndex (count) -> count == minCount
        @colors[index]

    created: ->
      stageIds = $(element).data('stage-uuids')
      @loadModuleNames()
      @loadContextParameters(stageIds)

    beforeUpdate: ->
      @tagRefs = []

    methods:
      loadContextParameters: (stageIds) ->
        PlatformApi.Webapp.contextParametersRead()
          .then (data) => @contextParameters = data
      loadModuleNames: ->
        PlatformApi.Webapp.gameNames()
          .then (data) => @moduleNames = data.map (name) => {key: name, label: name}
      cloneTag: (originalTag) ->
        newTag = new HandoverTag(ObjectProcessor.clone(originalTag))
        newTag.color = @nextColor
        delete newTag.id
        @tags.push(newTag)
        Vue.nextTick =>
          @tagRefs[-1..][0].edit()
      deleteTag: (index) ->
        if !@tags[index].id?
          @tags.splice(index, 1)
          return
        PlatformApi.Handover.deleteTag(@tags[index])
          .then => @tags.splice(index, 1)
      addTag: ->
        @tags.push(new HandoverTag(color: @nextColor))
        @$nextTick =>
          @tagRefs.last.edit()
      setTagRef: (el) ->
        @tagRefs.push(el) if el?

    components:
      HandoverTag: HandoverTagComponent
      PlusIcon: PlusIcon
      Button: Button
      PageHeader: PageHeader

    template: '
      <div class="sm:px-6 lg:px-8">
        <PageHeader title="Live-chat routing">
          <template #description>
            Send users to the right team of live agents to get help.
            Tags allow you to make this match between conversations and agent teams during handover.
            You define a tag as a set of context conditions.
            And you assign the tag to all agent teams that should receive this conversation.
          </template>
          <template #cta>
            <Button type="primary" @click="addTag">
              <PlusIcon class="h-5 w-5 -ml-1 mr-1" aria-hidden="true"/>
              New Tag
            </Button>
          </template>
        </PageHeader>

        <ul role="list" class="grid grid-cols-1 gap-6 pt-4">
          <HandoverTag
            v-for="tag, index in tags"
            :ref="setTagRef"
            :key="tag.id"
            :tag="tag"
            :index="index"
            :teams="agentTeams"
            @delete-tag="deleteTag"
            @clone-tag="cloneTag"
          />
        </ul>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    element = document.getElementById('handover-tags-app')
    initHandoverTagsApp(element) if element? && !element.getAttribute('data-v-app')?
