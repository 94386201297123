
  import { ChevronDownIcon } from '@heroicons/vue/outline'

  export default
    props:
      rows: Array
      open:
        type: Boolean
        default: true

    data: ->
      isOpen: @open

    mounted: ->
      @setRowsVisibility()

    methods:
      toggle: ->
        @isOpen = !@isOpen
        @setRowsVisibility()
        @$emit('toggle', @isOpen)
      setRowsVisibility: ->
        @rows.forEach (row) => row.visible = @isOpen

    components:
      ChevronDownIcon: ChevronDownIcon
