
  import UserIntent from 'models/user_intent.coffee'

  import HintList from './hint_list.vue'
  import TargetEditor from './target_editor.vue'
  import UtteranceCollectionEditor from '../utterances/utterance_collection_editor.vue'
  import { Input, KeyListbox, Label, Switch, Tabs } from 'components/generic'

  nlpAccessModes = UserIntent.NLP_ACCESS_MODES.map (mode) -> {key: mode, label: mode}

  export default
    props:
      intent: UserIntent
      hints: Array

    emits: ['change']

    data: ->
      nlpAccessModes: nlpAccessModes
      debounceTimeout: null
      debounceTimeoutForMetaInfo: null
      currentTab: 'general'

    computed:
      tabs: ->
        [
          { id: 'general', name: 'General' }
          { id: 'hints', name: 'Hints & errors', badge: @hints.length }
        ]

    watch:
      '$root.currentLanguage': -> @initCanonical()

    created: ->
      @initCanonical()

    methods:
      initCanonical: ->
        @intent.canonicals[@$root.currentLanguage] ||= ''
      update: (change = false) ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(
          =>
            @intent.save().then =>
              @$emit('change') if change
        , 1000
        )
      updateMetaInfo: ->
        clearTimeout(@debounceTimeoutForMetaInfo)
        @debounceTimeoutForMetaInfo = setTimeout(
          =>
            @intent.saveMetaInfo().then => @$emit('change')
        , 1000
        )

    components:
      HintList: HintList
      Input: Input
      Label: Label
      KeyListbox: KeyListbox
      Switch: Switch
      Tabs: Tabs
      TargetEditor: TargetEditor
      UtteranceCollectionEditor: UtteranceCollectionEditor
