Vue.component 'support-prompt-answer',
  props: ['answer', 'parameter', 'index', 'count']
  data: ->
    state: state
    bareCallbacks: []
  computed:
    valueType: ->
      @parameter?.type
    availableOperators: ->
      TypeValidator.availableUpdateOperators(@valueType)
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @answer.update.operator)
    textValid: ->
      @answer.text[@state.currentLanguage]?.length
    operatorValid: ->
      @availableOperators.indexOf(@answer.update.operator) >= 0
    valueValid: ->
      GameValidator.placeholder(@answer.update.value) ||
        TypeValidator.valueValid(
          @answer.update.value,
          @valueType,
          @answer.update.operator,
          false
        )
    intentLabels: ->
      return [] if !@state.intentSignatures?
      @state.intentSignatures['user'].filter((signature) => signature.gameLabel == 'Hooks')
        .map((signature) -> signature.intentLabel)
    dropdownLabel: ->
      if @answer.callbacks.length > 0
        "<span class=\"text-primary\">#{@answer.callbacks.length} hooks</span>"
      else
        'hooks'
  watch:
    answer:
      handler: -> @validate()
      deep: true
    bareCallbacks:
      handler: ->
        @answer.callbacks = @bareCallbacks.map((intent) -> "Hooks/#{intent}()")
      deep: true
  created: ->
    # amend old format
    if typeof @answer.text == 'string'
      text = @answer.text
      @answer.text = {}
      for code, lang of @state.languages
        Vue.set(@answer.text, code, text)
    # continue
    Vue.setDefault(@answer, 'text', {})
    Vue.setDefault(@answer, 'callbacks', [])
    Vue.setDefault(@answer, 'update', {})
    Vue.setDefault(@answer.update, 'operator', '')
    Vue.setDefault(@answer.update, 'value', '')
    @validate()
    @bareCallbacks = @answer.callbacks.map((intent) -> intent.match(/Hooks\/(.*)\(\)/)[1])
  methods:
    validate: ->
      Vue.set @answer, '$valid',
        @textValid &&
        @operatorValid &&
        @valueValid
    remove: ->
      @$emit('remove', @index)
  template: '
    <div class="form-group">

      <div class="flex-container center-items sub-form-group">
        <div class="user-message width-50" :class="{\'has-error\': !textValid}">
          <multilang-field :dictionary="answer.text" placeholder="user message" class="form-control"/>
        </div>&nbsp;

        <div :class="{\'has-error\': !operatorValid}" class="width-20">
          <select class="form-control" v-model="answer.update.operator">
            <option disabled="" value="">operator</option>
            <option :value="operator" v-for="operator in availableOperators">{{ operator }}</option>
          </select>
        </div>&nbsp;

        <div :class="{\'has-error\': !valueValid}" class="expand">
          <input
            v-if="answer.update.operator != \'clear\'"
            type="text"
            v-model="answer.update.value"
            :placeholder="valueLabel"
            class="form-control"
            >
        </div>&nbsp;

        <div v-if="count > 1" @click="remove" class="btn btn-remove">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>

      <div class="flex-container">
        <dropdown
          :chosen="dropdownLabel"
          align="left"
          button-class="form-control flex-container center-items spread-content"
          class="width-50"
          >
          <li v-for="intent in intentLabels" class="checkbox" @click.stop="">
            <label>
              <input type="checkbox" v-model="bareCallbacks" :value="intent">
              {{ intent }}
            </label>
          </li>
        </dropdown>&nbsp;
        <div class="width-20"></div>&nbsp;
        <div class="width-30"></div>&nbsp;
        <div v-if="count > 1" class="btn btn-remove invisible">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>

    </div>
  '
