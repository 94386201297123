import { hexToRgba } from 'helpers'

export default class HandoverTag
  constructor: (data = {}) ->
    @id = data.id
    @name = ''
    @color = ''
    @agentTeamIds = []
    @conditions = [{lastActiveGame: ''}]
    @update(data)

  update: (data) ->
    @name = data.name if data.name?
    @color = data.color if data.color?
    @lastUsed = data.lastUsed if data.lastUsed?
    @agentTeamIds = data.agentTeamIds if data.agentTeamIds?
    @conditions = data.conditions if data.conditions?
    return this

  clone: ->
    new HandoverTag(ObjectProcessor.clone(@))

  agentTeams: (existingTeams) ->
    @agentTeamIds.map (id) ->
      existingTeams.find (team) -> team.id == id
    .filter (team) -> team?

  Object.defineProperties @prototype,
    lastUsedFormatted:
      get: ->
        return '' unless @lastUsed?
        l(@lastUsed, 'dateTime')
    labelStyles:
      get: ->
        color: @color
        backgroundColor: hexToRgba(@color, 0.2)

