
  export default
    props:
      sortingAttribute: String
      sortingFunction: Function
      collection: Array
      initialDirection:
        type: String
        default: null

    data: ->
      direction: @initialDirection

    computed:
      sortable: ->
        @collection? && (@sortingAttribute? || @sortingFunction?)

    created: ->
      if @direction?
        @collection.sort(@compare)
        @collection.reverse() if @direction == 'desc'

    methods:
      sort: ->
        @$emit('sort', @)
        @direction = if @direction == 'asc' then 'desc' else 'asc'
        @collection.sort(@compare)
        @collection.reverse() if @direction == 'desc'
      compare: (a, b) ->
        if @sortingAttribute?
          valA = a[@sortingAttribute]
          valB = b[@sortingAttribute]
        else if @sortingFunction?
          valA = @sortingFunction(a)
          valB = @sortingFunction(b)
        if !isNaN(valA) && !isNaN(valB) then valA - valB else valA.toString().localeCompare(valB)
