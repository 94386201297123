
  export default
    props:
      color:
        type: String
        default: 'green'
      draggable:
        type: Boolean
        default: false

    computed:
      outerClasses: ->
        "relative p-2 rounded-xl flex items-stretch bg-#{@mercuryColor}-50 hover:bg-#{@mercuryColor}-100"
      barClasses: ->
        "flex-shrink-0 w-2 mr-4 rounded-lg bg-#{@mercuryColor}-500 #{if @draggable then 'bar cursor-move' else ''}"
      mercuryColor: ->
        # we need this for TailwindCss to generate the classes
        availableColors = 'bg-MercuryGrey-500 bg-MercuryBlue-500 bg-MercuryGreen-500 bg-MercuryYellow-500 bg-MercuryRed-500 bg-Skobeloff-500 bg-Avocado-500 bg-MagentaViolet-500'
        availableColors = 'bg-MercuryGrey-50 bg-MercuryBlue-50 bg-MercuryGreen-50 bg-MercuryYellow-50 bg-MercuryRed-50 bg-Skobeloff-50 bg-Avocado-50 bg-MagentaViolet-50'
        availableColors = 'hover:bg-MercuryGrey-100 hover:bg-MercuryBlue-100 hover:bg-MercuryGreen-100 hover:bg-MercuryYellow-100 hover:bg-MercuryRed-100 hover:bg-Skobeloff-100 hover:bg-Avocado-100 hover:bg-MagentaViolet-100'
        availableColors = 'focus-withing:ring-MercuryGrey-700 focus-withing:ring-MercuryBlue-700 focus-withing:ring-MercuryGreen-700 focus-withing:ring-MercuryYellow-700 focus-withing:ring-MercuryRed-700 focus-withing:ring-Skobeloff-700 focus-withing:ring-Avocado-700 focus-withing:ring-MagentaViolet-700'
        if ['grey', 'blue', 'green', 'yellow', 'red'].includes(@color)
          "Mercury#{@color.capitalize()}"
        else
          @color.capitalize()
