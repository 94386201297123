Vue.component 'info-button',
  props: ['infoKey']
  data: ->
    state: state
  methods:
    click: (e) ->
      $.get state.infoUrl(@infoKey), (data)-> showModal(data)
  template: '
    <a href="" @click.prevent="click">
      <span class="fa fa-info-circle"></span>
    </a>
  '
