
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappPromotionJob from 'models/whatsapp_promotion_job.coffee'
  import PromotionJob from './promotion_job.vue'
  import { Button, H3, Listbox, Pagination, Table, TableHeadCell, TableHeadRow } from 'components/generic'

  export default
    emits: ['digest-page']

    data: ->
      jobs: []

    created: ->
      @loadJobs()

    methods:
      loadJobs: ->
        PlatformApi.WaTemplates.getJobs()
          .then (data) =>
            @jobs = data.map (jobData) -> new WhatsappPromotionJob(jobData)
            # load context parameters for jobs
            stageIds = @jobs.map((job) =>
              channel = @$root.channels.find((ch) -> ch.uuid == job.channelId)
              channel?.stage_uuid
            ).filter (stageId) -> stageId?
            @$root.loadContextParameters(stageIds)
      remove: (jobId) ->
        @jobs = @jobs.filter (job) -> job.id != jobId
      channel: (job) ->
        @$root.channels.find((ch) => ch.uuid == job.channelId)
      phoneNumber: (job) -> # for sorting
        @channel(job)?.phone_number
      filterLabels: (job) -> # for sorting
        return '' unless job.config?.filters instanceof Array
        job.config.filters.map (filter) =>
          contextParameter = @$root.parameterByLabel(filter.field)
          (contextParameter?.humanizedLabel || contextParameter?.label || filter.field) +
            filter.operator +
            filter.value
        .join(' ')

    components:
      Btn: Button
      H3: H3
      PromotionJob: PromotionJob
      Listbox: Listbox
      Pagination: Pagination
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
