import AnswerTemplate from 'models/answer_template'
import { alert } from 'helpers'
import VMdEditor from 'v_md_editor'

Vue.component 'inbox2-answer-template-editor',
  props:
    template: AnswerTemplate
    conversationId: String

  data: ->
    exampleQuestion: @template.exampleQuestion
    messageText: @template.messageText
    teams: @template.teams
    tags: @template.tags
    newTag: undefined
    availableTeams: @$root.agentTeams.map((team) -> { id: team.id, name: team.name })
    availableTags: []

  created: ->
    @$root.api.loadAnswerTemplateTags(@conversationId)
      .then (tags) => @availableTags = tags
      .catch => @availableTags = []

  methods:
    addTeam: (team) ->
      @teams.push(team) if !@teams.some((other) -> other.id == team.id)
    addTag: (tag) ->
      @tags.push(tag) unless @tags.some((other) -> other.toLowerCase() == tag.toLowerCase())
    addNewTag: ->
      @addTag(@newTag) if @newTag
      @newTag = undefined
    removeTeam: (teamId) ->
      @teams = @teams.filter((t) -> t.id != teamId)
    removeTag: (tag) ->
      @tags = @tags.filter((t) -> t != tag)
    save: ->
      if @teams.length == 0
        await alert('Please assign this template to at least one team.')
        return
      @template.update({
        exampleQuestion: @exampleQuestion
        message:
          type: 'Bubble'
          attachments: [
            type: 'Text'
            format: 'MARKDOWN'
            role: 'NONE'
            text: @messageText
          ]
        tags: @tags
        teams: @teams
      })
      @$root.api.updateAnswerTemplate(@conversationId, @template)
        .finally => @$emit('close')
    cancel: ->
      @$emit('close')

  components:
    'markdown-editor': VMdEditor

  template: '
    <div class="modal-mask">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Edit answer template</h3>
          </div>

          <div class="modal-body">
            <div class="sub-form-group">
              <label for="sample-question">Sample question</label>
              <input id="sample-question" type="text" v-model="exampleQuestion" class="styled-form-field-block" />
            </div>

            <div class="sub-form-group">
              <label for="message-text">Message</label>
              <div id="message-text" class="message-editor">
                <markdown-editor
                  v-model="messageText"
                  left-toolbar="bold italic link"
                  right-toolbar=""
                  :codemirror-config="{lineNumbers: false, scrollbarStyle: \'simple\'}"
                  />
              </div>
            </div>

            <div class="sub-form-group">
              <label for="template-tags">Tags</label>
              <div id="template-tags" class="template-tags">
                <ul class="tag-list">
                  <li v-for="tag in tags">
                    <span class="tag">
                      {{ tag }}
                      <i class="far fa-times-circle remove-tag" @click="removeTag(tag)"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="sub-form-group">
              <dropdown id="template-tag" align="left" chosen="Add tag">
                <li v-for="tag in availableTags">
                  <a @click="addTag(tag)">{{ tag }}</a>
                </li>
              </dropdown>

              <input id="new-tag" type="text" class="styled-form-field"
                placeholder="New tag"
                v-model="newTag"
                @keydown.enter="addNewTag"
                />
              <label for="new-tag">
                <div @click="addNewTag" class="btn btn-primary">
                  Create
                </div>
              </label>
            </div>

            <div class="sub-form-group">
              <label for="template-teams">Assigned to</label>
              <div id="template-teams" class="template-tags">
                <ul class="tag-list">
                  <li v-for="team in teams">
                    <span class="tag">
                      {{ team.name }}
                      <i class="far fa-times-circle remove-tag" @click="removeTeam(team.id)"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="sub-form-group">
              <dropdown id="new-team" align="left" chosen="Add team">
                <li v-for="team in availableTeams">
                  <a @click="addTeam(team)">
                    {{ team.name }}
                  </a>
                </li>
              </dropdown>
            </div>

            <div class="button-container">
              <div @click="cancel" class="btn btn-default" data-dismiss="modal">
                Discard
              </div>
              <button type="submit" @click="save" class="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  '
