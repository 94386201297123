import GuidGenerator from 'models/guid_generator.coffee'

export default class FacebookMenuItem
  constructor: (@menu, data = {}) ->
    @id = GuidGenerator.newGuid()
    @type = data.type || 'postback' # 'postback' | 'web_url'
    @title = data.title || ''
    @payload = data.payload || ''
    @url = data.url || ''

  Object.defineProperties @prototype,
    moduleKey:
      get: ->
        return if @payload.length == 0
        @payload.split('/')[0]
    nodeKey:
      get: ->
        return if @payload.length == 0
        @payload.split('/').last
    export:
      get: ->
        Object.assign(
          type: @type
          title: @title
          if @type == 'postback'
            payload: @payload
          else if @type == 'web_url'
            url: @url
        )
