
  import Integration from 'models/integration.coffee'
  import IntegrationParameter from 'models/integration_parameter'
  import IntegrationParameterMapping from 'models/integration_parameter_mapping'
  import { alert } from 'helpers'

  import SecretsEditor from './secrets_editor.vue'
  import { Button, H3, Input, KeyListbox, Label, Panel, Textarea } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Integration

    data: ->
      integration: @modelValue
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @integration = @modelValue

    methods:
      addResponseParameter: ->
        @integration.responseParameters.push(new IntegrationParameter())
        @$refs.addResponseParameterButton.blur()
        @update()
      removeResponseParameter: (index) ->
        @integration.responseParameters.splice(index, 1)
        @update()
      addMapping: ->
        @integration.inputMapping.push(new IntegrationParameterMapping())
        @$refs.addMappingButton.blur()
        @update()
      removeMapping: (index) ->
        @integration.inputMapping.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @integration)
      copyWebhookUrl: (e) ->
        navigator.clipboard.writeText(@integration.url)
          .then =>
            e.target.blur()
            await alert('Copied webhook URL to clipboard')

    components:
      Button: Button
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      Panel: Panel
      SecretsEditor: SecretsEditor
      Textarea: Textarea
      XIcon: XIcon
