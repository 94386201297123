import marked from 'marked'

Vue.component 'inbox2-bubble',
  props: ['bubbleData', 'message']
  computed:
    innerHtml: ->
      attachmentTypes = @bubbleData.attachments.map((attachment) -> attachment.type.toLowerCase()).sort()
      if Array.equal(attachmentTypes, ['payload', 'text'])
        return @attachmentHtml(
          type: 'postbackbutton'
          text: @bubbleData.attachments.find((attachment) -> attachment.type.toLowerCase() == 'text')
        )
      html = @bubbleData.attachments
        .map((attachment) => @attachmentHtml(attachment))
        .join('')
      html = @bubbleData.url if html.length == 0 && @bubbleData.url # This is relevant in WhatsApp, where we have bubbles that are empty except for the bubble URL. In that case, it really helps to see the URL in the inbox.
      return "<a href='#{@bubbleData.url}'>#{html}</a>" if @bubbleData.url
      html
  methods:
    isEmbeddedVideoPath: (data) ->
      (data.url || data.path).match(/^(https?\:\/\/)?(www\.)?youtube\.com\/embed\/.+/) ||
        (data.url || data.path).match(/^(https?\:\/\/)?player\.vimeo\.com\/video\/.+/)
    attachmentHtml: (data) ->
      type = if data.type?.toLowerCase() == 'button' # for message as defined in message editor
        "#{data.buttonType}button"
      else
        data.type
      switch (type || 'text').toLowerCase()
        when 'text'
          if data.format == 'MARKDOWN'
            marked(data.text)
          else
            tag = {
              none: 'p'
              title: 'h5'
              subtitle: 'h6'
            }[(data.role || 'NONE').toLowerCase()]
            "<#{tag} class='text'>#{data.text}</#{tag}>"
        when 'image'
          "<img src='#{data.url || data.path}'>"
        when 'video'
          if @isEmbeddedVideoPath(data)
            "<iframe src='#{data.url || data.path}'>"
          else
            "<video src='#{data.url || data.path}'>"
        when 'document'
          "<a href='#{data.path}' target='_blank'><span class='fa fa-file text-white' style='font-size:40px'></span></a>"
        when 'audio'
          "<a href='#{data.path}' target='_blank'><span class='fa fa-volume-up text-white' style='font-size:40px'></span></a>"
        when 'location'
          "<p><span class='fa fa-map-marker'></span> #{data.geolocation}</p>"
        when 'linkbutton'
          # instagram story mentions
          if data.url.startsWith('https://lookaside.fbsbx.com/ig_messaging_cdn') && @message && (moment() - moment(@message.timestamp)) / (60*60*1000) < 24
            "<a class='btn btn-default' href='#{data.url}' target='_blank'>#{@attachmentHtml(data.text)}</a><iframe src='#{data.url}'>"
          else
            "<a class='btn btn-default' href='#{data.url}' target='_blank'>#{@attachmentHtml(data.text)}</a>"
        when 'postbackbutton', 'quickreplybutton'
          "<div class='btn btn-default'>#{@attachmentHtml(data.text)}</div>"
        when 'payload'
          "<em>#{data.content}</em>"
  template: '
    <div class="bubble" v-html="innerHtml"></div>
  '
