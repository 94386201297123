<template>
  <li class="col-span-1 bg-white rounded-lg shadow">
    <div class="grid grid-cols-12 p-6 pt-8">

      <div class="col-span-2">
        <input
          v-model="tagCopy.name"
          :disabled="!editing"
          :style="tagCopy.labelStyles"
          placeholder="Tag name"
          class="inline-flex text-center px-3 py-2 rounded-full text-sm font-medium placeholder-gray-400 outline-none"
          >
        <p class="text-xs text-gray-400 pt-2">
          LAST USED: {{ tagCopy.lastUsedFormatted }}
        </p>

        <listbox
          v-model="selectedTeam"
          :collection="teamsWithPrompt"
          @selected="addTeam"
          :disabled="!editing"
        />

        <!-- Teams with this Tag -->
        <div class="mt-3">
          <span
            v-for="team, index in tagCopy.agentTeams(teams)"
            :key="team.id"
            class="inline-flex items-center py-0.5 pl-2 pr-0.5 mb-1 rounded-md text-xs font-medium bg-gray-100 text-gray-700"
            >
            {{ team.name }}
            <button
              v-if="editing"
              type="button"
              class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-500 hover:bg-gray-200 hover:text-gray-600 focus:outline-none focus:bg-gray-500 focus:text-white"
              @click="removeTeam(index)"
              >
              <span class="sr-only">Remove Team</span>
              <XIcon class="h-2 w-2"/>
            </button>
          </span>
        </div>

      </div>

      <!-- when -->
      <div class="col-span-1 text-center ">
          <div class="mt-2">
          <span class="text-gray-500" >WHEN</span>
          </div>
      </div>

      <!-- Filter Definition"  -->
      <div class="col-span-8">
          <div class="flex flex-col">
              <div class="sm:-mx-6 lg:-mx-8">
                  <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 -mt-11">
                      <tbody class="">
                        <condition
                          v-for="condition, index in tagCopy.conditions"
                          :key="condition.$key"
                          :condition="condition"
                          :index="index"
                          :count="tagCopy.conditions.length"
                          :last-game-condition-present="lastGameConditionPresent"
                          :editing="editing"
                          @add-condition="addCondition"
                          @remove-condition="removeCondition"
                        />
                      </tbody>
                    </table>
                  </div>
                  </div>
              </div>
          </div>
      </div>

      <ActionMenu v-if="true" :items="actionMenuItems" class="pt-1"/>

    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform scale-y-0"
      enter-to-class="transform scale-y-100"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="transform scale-y-100"
      leave-to-class="transform scale-y-0"
    >
      <div v-if="editing" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse origin-top rounded-b-lg">
        <Button type="primary" @click="save" class="ml-3" :disabled="sending">
          Save
        </Button>
        <Button @click="cancel" :disabled="sending">
          Cancel
        </Button>
        <div v-if="errors.length" class="mr-auto text-MercuryRed-600 text-sm font-medium">
          Please fix the following error(s):
          {{ errors.join(', ') }}
        </div>
      </div>
    </transition>
  </li>
</template>

<script lang="coffee">
import HandoverTag from 'models/handover_tag'
import PlatformApi from 'models/platform_api.coffee'
import { alert, confirm } from 'helpers'
import { ActionMenu, Button, Listbox } from 'components/generic'
import HandoverTagConditionComponent from './tag_condition.vue'
import { XIcon, XCircleIcon, DuplicateIcon, PencilIcon } from '@heroicons/vue/outline'

export default
  props:
    tag: HandoverTag
    index: Number
    teams: Array

  data: ->
    selectedTeam: @teams[0]
    editing: false
    sending: false
    tagCopy: @tag.clone()
    actionMenuItems: [
      {icon: PencilIcon, label: 'Edit', method: @edit},
      {icon: DuplicateIcon, label: 'Clone', method: @clone},
      {icon: XCircleIcon, label: 'Delete', method: @deleteTag, hr: true},
    ]
    errors: {}

  computed:
    teamsWithPrompt: ->
      @selectedTeam = {name: 'Add teams'}
      [@selectedTeam].concat(@teams)
    lastGameConditionPresent: ->
      @tagCopy.conditions.some (condition) ->
        condition.lastActiveGame?

  mounted: ->
    @selectedTeam = @teamsWithPrompt[0]

  methods:
    addTeam: (team) ->
      return if @tagCopy.agentTeamIds.includes(team.id)
      @tagCopy.agentTeamIds.push(team.id)
    removeTeam: (index) ->
      @tagCopy.agentTeamIds.splice(index, 1)
    addCondition: ->
      if @lastGameConditionPresent
        @tagCopy.conditions.push({})
      else
        @tagCopy.conditions.push({lastActiveGame: ''})
    removeCondition: (index) ->
      @tagCopy.conditions.splice(index, 1)
    edit: ->
      @editing = true
      Vue.nextTick =>
        $(@$el).find('input').focus()
    cancel: ->
      @editing = false
      if !@tagCopy.id?
        @$emit('delete-tag', @index)
        return
      @tagCopy = @tag.clone()
    save: ->
      return if !@validate()
      @sending = true
      PlatformApi.Handover.saveTag(@tagCopy)
        .then (data) =>
          @editing = false
          for attr, value of data
            @tag[attr] = value
        .catch (error) -> await alert("Sorry, tag could not be saved.\nError message:\n" + error?.message)
        .finally =>
          @sending = false
    clone: ->
      @$emit('clone-tag', @tag)
    deleteTag: ->
      return unless await confirm("Do you really want do delete handover tag “#{@tagCopy.name}”?")
      @$emit('delete-tag', @index)
    validate: ->
      @errors = []
      @errors.push('tag needs a name') if @tagCopy.name.length == 0
      @errors.push('name is taken') if @$root.tags.some (tag, index) => tag.name == @tagCopy.name && index  != @index
      @errors.push('tag must be assigned at least one team') if @tagCopy.agentTeamIds.length == 0
      return false if @errors.length > 0
      true

  components:
    ActionMenu: ActionMenu
    Button: Button
    listbox: Listbox
    condition: HandoverTagConditionComponent
    XIcon: XIcon
</script>
