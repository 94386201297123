
  import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid'

  export default
    props:
      items: Array

    computed:
      computedItems: ->
        @items.map (item) =>
          Object.assign({}, item,
            currentLabel: @currentLabel(item),
            previousLabel: @previousLabel(item),
            change: @change(item)
            changeLabel: @changeLabel(item)
          )
      colClass: ->
        switch @items.length
          when 1 then ''
          when 2 then 'md:grid-cols-2'
          when 3 then 'md:grid-cols-3'
          when 4 then 'md:grid-cols-4'
          when 5 then 'md:grid-cols-5'
          when 6 then 'md:grid-cols-3'

    methods:
      currentLabel: (item) ->
        return '&nbsp;' if !item.current?
        switch item.type
          when 'rate'
            @rateLabel(item.current)
          when 'absolute'
            @numberLabel(item.current)
          else
            item.current
      previousLabel: (item) ->
        return '&nbsp;' if !item.previous?
        switch item.type
          when 'rate'
            @rateLabel(item.previous)
          when 'absolute'
            @numberLabel(item.previous)
          else
            item.previous
      changeLabel: (item) ->
        return null if !@change(item)?
        @rateLabel(@change(item))
      change: (item) ->
        return null if item.type == 'string'
        return null if !item.previous? || !item.current?
        return null if item.previous == 0
        item.current / item.previous - 1
      numberLabel: (value) ->
        return '&nbsp;' if !value?
        integerPart = Math.trunc(value)
        fractionalPart = value - Math.trunc(value)
        [foo, firstPart, middlePart] = integerPart.toString().match(/^(\d{1,3})((\d{3})*)$/)
        label = firstPart + (if firstPart.length && middlePart.length then '&#x202F;' else '') + ("#{middlePart}".match(/\d{3}/g) || []).join('&#x202F;')
        label += ('.' + String(Math.round(fractionalPart * 100) / 100).split('.')[-1..][0]) if fractionalPart > 0
        label
      rateLabel: (value) ->
        return '&nbsp;' if !value?
        Math.round(value * 10000) / 100 + '%'
      itemClass: (index) ->
        cl = []
        cl.push('border-t') if index > 0
        if @items.length == 6
          cl.push('md:border-l') if index % 3 > 0
          if index > 2
            cl.push('md:border-t')
          else
            cl.push('md:border-t-0')
        else if index > 0
          cl.push('md:border-t-0')
          cl.push('md:border-l')
        cl

    components:
      ArrowSmDownIcon: ArrowSmDownIcon
      ArrowSmUpIcon: ArrowSmUpIcon
