
import { timeZones } from 'rails_data'
import { alert } from 'helpers'
import PlatformApi from 'models/platform_api.coffee'
import PasswordChanging from 'components/profile/password_changing.vue'
import TfaSettings from 'components/profile/tfa_settings.vue'
import SlackSettings from 'components/profile/slack_settings.vue'
import UserProjectSettings from 'components/profile/user_project_settings.vue'
import { Alert, Avatar, BackLink, Button, H1, H3, Input, Listbox, PageHeader, Panel } from 'components/generic'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { PlusIcon } from '@heroicons/vue/solid'

export default
  props:
    originalUser: Object
    context: String # 'users' or 'profile'

  emits: ['cancel', 'update']

  data: ->
    user: {}
    languages: [
      {id: 'en', name: 'English'}
      {id: 'de', name: 'German'}
    ]
    timeZones: timeZones
    language: {}
    maxConcurrentSessions: null
    organizationRole: null
    addingProject: false
    projectToAdd: {}
    roleToAdd: {}
    editing: false
    sending: false
    errors: {}
    avatarErrorMessage: null
    projectSettingsRefs: []
    maySetMaxConcurrentSessions: Globals.restrictions.agent_chat_limit
    PlusIcon: PlusIcon

  computed:
    availableProjects: ->
      @$root.projects.filter (project) =>
        !@originalUser.projects.map((p) -> p.id).includes(project.id) &&
          Globals.currentUser.permissionComplementIdsFor('grant_project_permissions').includes(project.id)

  watch:
    originalUser:
      deep: true
      handler: ->
        return if @editing
        @resetUser()
    language: ->
      @user.language = @language.id
    time_zone: ->
      @user.time_zone = @timeZone.id
    'originalUser.max_concurrent_sessions': ->
      @user.max_concurrent_sessions = @originalUser.max_concurrent_sessions
      @setMaxConcurrentSessions()
    maxConcurrentSessions: ->
      @$nextTick =>
        @maxConcurrentSessions = null if @maxConcurrentSessions <= 0
        @user.max_concurrent_sessions = @maxConcurrentSessions || 0

  created: ->
    @resetUser()

  beforeUpdate: ->
    @projectSettingsRefs = []

  methods:
    resetUser: ->
      @user = ObjectProcessor.clone(@originalUser)
      @user.role = @$root.organizationRoles.find (role) => role.id == @originalUser.role.id
      @setMaxConcurrentSessions()
      @language = @languages.find (lang) => lang.id == (@user.language || 'en')
      @timeZone = @timeZones.find (tz) => tz.id == @user.time_zone
    setMaxConcurrentSessions: ->
      @maxConcurrentSessions = @user.max_concurrent_sessions
    setAvatar: (e) ->
      files = e.target.files
      @avatarErrorMessage = null
      if !['image/bmp', 'image/gif', 'image/jpeg', 'image/png'].includes(files[0].type)
        @avatarErrorMessage = 'Only the following images types are supported: .bmp, .gif, .jpg/.jpeg and .png'
        return
      maxAvatarSizeInKb = 20
      if files[0].size > maxAvatarSizeInKb * 1024
        @avatarErrorMessage = "The image can’t be bigger than #{maxAvatarSizeInKb} KB."
        return
      fileReader = new FileReader()
      fileReader.addEventListener('load', =>
        @user.avatarDataUri = fileReader.result
      )
      fileReader.readAsDataURL(files[0])
      @user.avatar = files[0]
    removeAvatar: ->
      @user.avatar = 'remove'
      @user.avatar_url = null
      @user.avatarDataUri = null
      @avatarErrorMessage = null
    cancel: ->
      @resetUser()
      @editing = false
      @errors = {}
      @avatarErrorMessage = null
    save: ->
      @sending = true
      { avatarDataUri, avatar_url, role, ...userDataForSaving } = @user
      userDataForSaving.role_id = role.id if @context == 'users' || @user.id == @$root.organization.owner_id
      apiMethod = if @context == 'profile' then 'saveProfile' else 'saveUser'
      PlatformApi.Webapp[apiMethod](userDataForSaving)
        .then (data) =>
          @$emit('update', data)
          @editing = false
          @errors = {}
        .catch (response) =>
          Object.keys(response.errors).forEach (attribute) =>
            @errors[attribute] = response.errors[attribute].join(', ')
        .finally =>
          @sending = false
    addProject: ->
      PlatformApi.Webapp.addProjectUser(@projectToAdd.id, Globals.stageLevel, id: @user.id, role: @roleToAdd.export)
        .then (data) =>
          @addingProject = false
          @originalUser.projects.push(data.project)
       .catch (response) =>
          await alert(response.message)
    setProjectSettingsRef: (el) ->
      @projectSettingsRefs.push(el) if el?
    closeProjectSettingsExcept: (project) ->
      @projectSettingsRefs.forEach (component) ->
        component.close() unless component.project.id == project.id

  components:
    Alert: Alert
    Avatar: Avatar
    BackLink: BackLink
    Button: Button
    ChevronLeftIcon: ChevronLeftIcon
    H1: H1
    H3: H3
    Input: Input
    Listbox: Listbox
    Panel: Panel
    PasswordChanging: PasswordChanging
    SlackSettings: SlackSettings
    TfaSettings: TfaSettings
    UserProjectSettings: UserProjectSettings
