
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappTemplate from 'models/whatsapp_template.coffee'
  import { alert, confirm } from 'helpers'
  import { ActionMenu, Badge, TableCell, TableRow } from 'components/generic'
  import { DuplicateIcon, TranslateIcon, XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      template: Object
      index: Number

    data: ->
      actionMenuItems: [
        {icon: TranslateIcon, label: 'New language', method: @newLanguage},
        {icon: DuplicateIcon, label: 'Clone', method: @clone},
        {icon: XCircleIcon, label: 'Delete', method: @deleteTemplate, hr: true},
      ]

    computed:
      phoneNumber: ->
        @$root.channels.find((ch) => ch.uuid == @template.channelId)?.phone_number
      badgeColor: ->
        switch @template.status.toUpperCase()
          when 'APPROVED' then 'green'
          when 'REJECTED', 'DELETED' then 'red'
          else 'yellow'

    methods:
      newLanguage: ->
        @$root.editTemplate(
          new WhatsappTemplate(ObjectProcessor.cloneWithout(@template, ['language']))
        )
      clone: ->
        @$root.editTemplate(
          new WhatsappTemplate(ObjectProcessor.cloneWithout(@template, ['name']))
        )
      deleteTemplate: ->
        return unless await confirm("Do you really want to delete template “#{@template.name}”?")
        PlatformApi.WaTemplates.deleteTemplate(@template)
          .then => @$emit('remove', @index)
          .catch (error) -> await alert("Sorry, template could not be deleted.\nError message:\n" + error?.message)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      TableCell: TableCell
      TableRow: TableRow
