import { createApp } from 'vue_shims'
import { alert } from 'helpers'

initTrainingDataSuggestionsApp = (element) ->
  createApp(
    data: ->
      items: $(element).data('items')
      projectId: $(element).data('project-id')
      stageIds: $(element).data('stage-uuids')
      languages: $(element).data('languages')
      currentIndex: 0

    computed:
      currentItem: ->
        return null if @items.lenth == 0
        @items[@currentIndex]
      resolvedItems: ->
        @items.filter (item) -> item.resolved

    methods:
      previous: ->
        @currentIndex = Math.max(0, @currentIndex - 1)
      next: ->
        @currentIndex = Math.min(@currentIndex + 1, @items.length - 1)
      solve: (action) ->
        return if @currentItem.resolved
        suggestion = @currentItem
        $.post(
          Routes["#{action}_project_training_data_path"](@projectId, Globals.stageLevel),
          _method: 'patch'
          suggestion_id: suggestion.id
          problem_kind: 'suggestion'
        ).done(->
          Vue.set(suggestion, 'resolved', true)
        ).fail(@handleError)
        null
      handleError: (response) ->
        if response.status == 418
          await alert(response.responseText)
        else
          await alert('Sorry, an internal error occurred.')
      retrain: ->
        $.post(Routes.retrain_project_training_data_path(@projectId, Globals.stageLevel, stage_uuids: @stageIds))
        $(@$el).closest('.modal').modal('hide')

    template: '
      <div class="training-data training-data-suggestions">

        <template v-if="currentItem">

          <section class="row">
            <div class="col-sm-4">
              <span class="text-normal">Game:</span>
              <span class="label label-equal label-topborder">
                {{ currentItem.interpretation.game }}
              </span>
            </div>
            <div class="col-sm-4">
              <span class="text-normal">Intent:</span>
              <span class="label label-equal label-gray">
                {{ currentItem.interpretation.intent }}
              </span>
            </div>
            <div class="col-sm-4">
              <span class="text-normal">Language:</span>
              {{ languages[currentItem.interpretation.language] }}
            </div>
          </section>
          <div class="spacer"></div>

          <div class="flex-container start-items">
            <div class="text-right" style="width: 21em">The message ...&emsp;&emsp;</div>
            <div class="label label-info label-equal text-normal label-paragraph">
              {{currentItem.userUtterance}}
            </div>
          </div>
          <div class="spacer"></div><div class="spacer"></div>
          <div class="flex-container start-items">
            <div class="text-right" style="width: 21em">... triggers the same intent as this message:&emsp;&emsp;</div>
            <div class="label label-info label-equal label-paragraph text-normal suggestion-canonical-utterance">
              {{ currentItem.interpretation.canonicalUtterance }}
            </div>
          </div>

          <div class="spacer"></div><div class="spacer"></div><div class="spacer"></div>
          <div class="suggestion-resolution flex-container center-content">
            <template v-if="currentItem.resolved">
              suggestion has been resolved
            </template>
            <template v-if="!currentItem.resolved">
              <div @click="solve(\'reject\')" class="btn btn-caution">reject</div>
              &ensp;
              <div @click="solve(\'accept\')" class="btn btn-default">accept</div>
            </template>
          </div>

          <div class="pagination">
            <button @click="previous" class="btn btn-default" :class="{invisible: currentIndex == 0}">←</button>
            <div class="text-normal">{{ currentIndex + 1 }} of {{ items.length }}</div>
            <div
              class="resolved-items label label-equal"
              :class="\'label-\' + (resolvedItems.length == 0 ? \'default\' : \'success\')"
              >
              {{ resolvedItems.length }} resolved
            </div>
            <button @click="next" class="btn btn-default" :class="{invisible: currentIndex == items.length - 1}">→</button>
          </div>

        </template>

        <div v-if="!currentItem" class="text-center text-large">
          There are no learning suggestions at the moment.
          <div class="spacer"></div>
        </div>

        <div class="button-container">
          <button class="btn btn-default auto-margin-right" data-dismiss="modal">Close</button>
          <button class="btn btn-primary" @click="retrain">Close and retrain</button>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'shown.bs.modal', ->
  element = document.getElementById('training-data-suggestions-app')
  initTrainingDataSuggestionsApp(element) if element?
