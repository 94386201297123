
  import { CheckIcon } from '@heroicons/vue/outline'

  export default
    computed:
      currentStep: ->
        @$parent.steps[@$parent.currentStep]

    methods:
      goTo: (stepIndex) ->
        return if stepIndex > @$parent.currentStep && !@currentStep.completed
        @$parent.currentStep = stepIndex

    components:
      CheckIcon: CheckIcon
