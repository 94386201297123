
  import PlatformApi from 'models/platform_api.coffee'
  import TemplateDisplay from './template_display.vue'
  import { Button } from 'components/generic'
  import { BadgeCheckIcon, ChevronLeftIcon, MailOpenIcon, UserGroupIcon, XCircleIcon } from '@heroicons/vue/outline'
  import { BadgeCheckIcon as BadgeCheckIconSolid, ExclamationIcon, MailIcon, RefreshIcon } from '@heroicons/vue/solid'

  export default
    props:
      job: Object
      selectedTemplate: Object

    emits: ['back', 'cancel']

    computed:
      stats: ->
        return [] unless @job.statistics?
        [
          label: 'Total messages sent'
          icon: UserGroupIcon
          value: @job.statistics.messagesSent
        ,
          label: 'Successfully delivered'
          icon: BadgeCheckIcon
          value: @job.statistics.messagesDelivered
          rate: @job.statistics.messagesDelivered / @job.statistics.messagesSent
        ,
          label: 'Messages read'
          icon: MailOpenIcon
          value: @job.statistics.messagesRead
          rate: @job.statistics.messagesRead / @job.statistics.messagesSent
        ,
          label: 'Failed to deliver'
          icon: XCircleIcon
          value: @job.statistics.messagesFailed
          rate: (@job.statistics.messagesFailed) / @job.statistics.messagesSent
        ]
      jobEditable: ->
        return false if ['FAILED', 'SENDING', 'SUCCEEDED'].includes(@job.status)
        true

    methods:
      downloadReport: ->
        PlatformApi.WaTemplates.downloadReport(@job.id).then (string) ->
          element = document.createElement('a')
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(string))
          element.setAttribute('download', 'delivery_report.csv')
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

    components:
      BadgeCheckIconSolid: BadgeCheckIconSolid
      Button: Button
      ChevronLeftIcon: ChevronLeftIcon
      ExclamationIcon: ExclamationIcon
      MailIcon: MailIcon
      RefreshIcon: RefreshIcon
      TemplateDisplay: TemplateDisplay
