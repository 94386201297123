
  import Bot from 'models/bot'
  import Hook from 'models/hook'

  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import UtteranceCollectionEditor from './utterances/utterance_collection_editor.vue'
  import { BackLink, Input, PageHeader, Panel } from 'components/generic'

  export default
    props:
      bot: Bot

    data: ->
      hook: new Hook(@bot.config)
      editing: null
      sending: false
      pristine: false
      debounceTimeout: null

    computed:
      pageTitle: ->
        "Hook “#{@hook.label}”"

    watch:
      '$route.params.hookKey': -> @setHook()

    created: ->
      @setHook()

    methods:
      setHook: ->
        if !@$route.params.hookKey?
          @pristine = true
          @editing = true
          return
        @hook = @bot.config.hooks.find (hook) => hook.key == @$route.params.hookKey
        if @pristine = !@hook.key
          @editing = true
        else
          window.breadcrumbs.append(text: @hook.label)
      save: ->
        @sending = true
        @hook.save().then =>
          @sending = false
          if @pristine
            @hook.botConfig.hooks.push(@hook)
          @pristine = false
          @editing = null
      cancel: ->
        if @pristine
          @$router.push(name: 'hookList')
        else
          @editing = false
      update: ->
        return if @pristine
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @hook.save()), 1000)

    components:
      BackLink: BackLink
      Input: Input
      LanguageSelector: LanguageSelector
      PageHeader: PageHeader
      Panel: Panel
      UtteranceCollectionEditor: UtteranceCollectionEditor
