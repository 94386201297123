Vue.component 'datepicker',
  props:
    value: ''
  mounted: ->
    $(@$el).datepicker(
      format: 'yyyy-mm-dd'
      weekStart: 1
      autoclose: true
      endDate: '0d'
    ).on('show', (e) ->
      # display active date even when it is disabled
      # need timout to outsmart datepicker
      setTimeout(
        => $(".datepicker.datepicker-dropdown .day[data-date=#{new Date(this.value).valueOf()}]").addClass('active'),
        0
      )
      return if $(this).data('open')
      # set a flag so we can check whether the show event occurred on show or on some other occasion (like month change)
      $(this).data('open', true)
      # cache current value
      $(this).data('sticky-date', e.target.value)
      # set preselected date to current value
      $(this).datepicker('update')
    ).on('hide', (e) ->
      # remove the open flag
      $(this).data('open', false)
      # restore current value if none is chosen
      stickyDate = $(this).data('sticky-date')
      if !e.date && stickyDate
        $(this).val(stickyDate)
    ).on('changeDate', (e) =>
      @$emit('set-date', e.target.value)
    )
  beforeUnmount: ->
    $(@$el).datepicker('hide').datepicker('destroy')
  methods:
    setDate: (date) ->
      $(@$el).datepicker('update', date)
    show: ->
      $(@$el).datepicker('show')
    focus: ->
      @$refs.input.focus()
  template: '
    <input ref="input" type="text" :value="value">
  '
