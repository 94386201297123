import LanguageNLG from 'models/language_nlg.coffee'

export default class NLG
  constructor: (@host, data = {}) ->
    data ||= {}
    @nlgPerLanguage = {}
    @update(data)

  update: (data = {}) ->
    Object.entries(data).forEach ([lang, languageNlgData]) =>
      @nlgPerLanguage[lang] = new LanguageNLG(@, languageNlgData)

  forLanguage: (lang) ->
    @nlgPerLanguage[lang] ||= new LanguageNLG(@)
    @nlgPerLanguage[lang]

  clone: ->
    new NLG(@host, @export)

  Object.defineProperties @prototype,
    empty:
      get: ->
        Object.keys(@nlgPerLanguage).length == 0
    export:
      get: ->
        obj = {}
        Object.entries(@nlgPerLanguage).forEach ([lang, languageNlg]) =>
          obj[lang] = languageNlg.export
        obj
    messages:
      get: ->
        Object.values(@nlgPerLanguage).map (languageNlg) -> languageNlg.messages
        .flat()
    buttons:
      get: ->
        @messages.map((message) -> message.buttons).flat()
