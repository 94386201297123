import AnswerTemplate from 'models/answer_template'

Vue.component 'inbox2-answer-template',
  props:
    template: AnswerTemplate
    conversationId: String

  data: ->
    deletionTimeout: null
    feedbackDuration: 3000
    virtuallyDeleted: false
    editing: false
    mayManageAnswerTemplates: Globals.currentUser.may('manage_answer_templates', Globals.project, Globals.stageLevel)
    actionMenuItems: [
      label: 'Edit'
      icon: 'fa fa-pen'
      method: @edit
    ,
      label: 'Delete'
      icon: 'far fa-times-circle'
      method: @initDeletion
      hr: true
    ]

  methods:
    use: (e) ->
      return if @editing
      @$root.track('Inbox answer template used', conversationId: @conversationId, templateId: @template.id)
      @$emit('use', @template)
    edit: ->
      @editing = true
    initDeletion: ->
      @virtuallyDeleted = true
      Vue.nextTick =>
        @deletionTimeout = setTimeout((=> @deletionTimeout = null), @feedbackDuration)
    deleteIt: ->
      @$emit('delete', @template)
    abortDeletion: ->
      @virtuallyDeleted = false
      clearTimeout(@deletionTimeout)
      @deletionTimeout = null

  template: '
    <div>
      <div v-if="!virtuallyDeleted" @click.self="use" class="answer-template">
        <div @click="use" class="answer-template-content">
          <strong>{{ template.exampleQuestion }}</strong>
          <inbox2-bubble v-for="bubble in template.bubbles" :bubble-data="bubble"/>
          <ul class="tag-list">
            <li v-for="tag in template.tags">
              <span class="tag">{{ tag }}</span>
            </li>
          </ul>
        </div>

        <action-menu v-if="mayManageAnswerTemplates" :items="actionMenuItems"/>

        <inbox2-answer-template-editor
          v-if="editing"
          @close="editing = false"
          :template="template"
          :conversationId="conversationId"
          />
      </div>

      <fade-out v-else :toggle="deletionTimeout" @faded-out="deleteIt">
        <div class="feedback-panel success">
          <span class="far fa-check-circle"></span>
          Answer deleted
          &emsp;&ensp;
          <button @click="abortDeletion" class="btn btn-default auto-margin-left">
            Undo
            &ensp;
            <span class="fa fa-times"></span>
          </button>
          <div class="progress-bar" :style="{\'animation-duration\': feedbackDuration + \'ms\'}"></div>
        </div>
      </fade-out>
    </div>
  '
