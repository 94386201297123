
  import BubbleEditor from './bubble_editor.vue'

  export default
    props:
      modelValue: Array

    data: ->
      bubbles: @modelValue
      bubbleEditorRefs: []

    watch:
      modelValue: ->
        @bubbles = @modelValue

    beforeUpdate: ->
      @bubbleEditorRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @bubbles)
      focus: ->
        @bubbleEditorRefs[0].focus()
      setBubbleEditorRef: (el) ->
        @bubbleEditorRefs.push(el) if el?

    components:
      BubbleEditor: BubbleEditor
