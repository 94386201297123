
  import ContentEntity from 'models/content_entity'

  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import { Combobox } from 'components/generic'

  export default
    props:
      modelValue: [String, Number, Boolean, Array]
      type: String
      valueType: String
      label: String

    data: ->
      value: @modelValue
      entity: null
      initialItems: []
      pageSize: 25

    computed:
      isReference: ->
        @type == 'entityReference'

    watch:
      modelValue: ->
        @value = @modelValue

    created: ->
      return unless @isReference
      @setEntity()
      @initialItems = await ContentEntity.list(
        @$root.currentLanguage
        @$root.entitySchemas
        entitySchemaKey: @valueType, pageSize: @pageSize
      )

    methods:
      setEntity: ->
        return if !@value
        @entity = await ContentEntity.load(@$root.currentLanguage, @$root.entitySchemas, @value.split('/').last)
      update: ->
        @$emit('update:modelValue', @value)
      focus: ->
        @$refs.input.focus()
      entityLabel: (entity) ->
        return '' if !entity?
        entity.label
      searchEntities: (query) ->
        ContentEntity.list(
          @$root.currentLanguage
          @$root.entitySchemas
          entitySchemaKey: @valueType, query: query, pageSize: @pageSize
        )
      updateEntity: ->
        @value = if @entity? then @valueType + '/' + @entity.key else null
        @update()

    components:
      Combobox: Combobox
      DataTypeDependentInput: DataTypeDependentInput
