import StatusMessage from './status_message.coffee'

export default class Status
  @LEVELS = [
    {key: 'OK', label: 'OK'}
    {key: 'INFO', label: 'INFO'}
    {key: 'WARN', label: 'WARN'}
    {key: 'ERROR', label: 'ERROR'}
    {key: 'UNKNOWN', label: 'UNKNOWN'}
  ]

  constructor: (@host, data = {}) ->
    @level = data.level || 'UNKNOWN'
    @messages = (data.messages || []).map (messageData) -> new StatusMessage(@, messageData)
