export default
  data: ->
    parts: []
    selection: null
    caretPosition: 0
    caretNodeName: ''
    focusNode: null
    focusOffset: 0
    displayButton: false
    componentRoot: null
    showText: true
    revisions: []

  mounted: ->
    @componentRoot = @$el
    document.addEventListener('selectionchange', @onSelectionchange)

  unmounted: ->
    document.removeEventListener('selectionchange', @onSelectionchange)

  methods:
    updateWithDisplay: ->
      @update()
      @showText = false
      @$nextTick =>
        @showText = true
        @$nextTick => @setCaret()
    setCaret: ->
      return if @editing == false
      return if !@$refs.editable?
      return unless document.activeElement == document.body || document.activeElement == @$refs.editable
      anchorNode = null
      anchorOffset = 0
      charIndex = 0
      @$refs.editable.childNodes.forEach (node) =>
        textNode = switch node.nodeName
          when '#text'
            node
          when 'SPAN'
            Array.from(node.childNodes).find((n) -> n.nodeName == '#text' && n.length > 0)
        return if !textNode?
        caretAtStart = @caretPosition == charIndex == 0
        caretAfterCharIndex = @caretPosition > charIndex
        caretAtStartOfNode = @caretPosition == charIndex && node.nodeName == @caretNodeName && @getText(node).length > 0
        if caretAtStart || caretAfterCharIndex || caretAtStartOfNode
          anchorOffset = Math.min(@caretPosition - charIndex + 1, textNode.length) # add one because of zero-width space inserted at start
          anchorNode = textNode
        charIndex += textNode.length
      range = document.createRange()
      range.setStart(anchorNode, anchorOffset)
      range.collapse(true)
      @selection.removeAllRanges()
      @selection.addRange(range)
    insert: ->
      clearTimeout(@debounceTimeout)
      @resetParts()
      newExpressionIndex = 0
      Array.from(@$refs.editable.childNodes).forEach (node) =>
        if node == @focusNode
          nodeText = @getText(node)
          @addTextPart(nodeText[0...@focusOffset])
          @parts.push(new ContentEditableSpan(type: 'expression', text: ''))
          newExpressionIndex = @parts.length - 1
          @addTextPart(nodeText[@focusOffset..])
        else
          if node.nodeName == 'SPAN'
            @parts.push(new ContentEditableSpan(type: 'expression', text: node.dataset['text']))
          else
            @addTextPart(@getText(node))
      if @parts.filter((part) -> part.type == 'expression').length == 0
        @parts.push(new ContentEditableSpan(type: 'expression', text: ''))
        newExpressionIndex = @parts.length - 1
      @addEmptyTextPart() if @parts.last?.type == 'expression'
      @showText = false
      @$nextTick =>
        @showText = true
        @$nextTick =>
          @expressionEditorRefs[newExpressionIndex].edit()
    remove: (index) ->
      @parts.splice(index, 1)
      @update()
    focus: ->
      @$refs.editable.focus()
    focusAfter: (index) ->
      @$nextTick =>
        anchorNode = @expressionEditorRefs[index]?.$el.nextSibling
        if !anchorNode?
          @focus()
          return
        anchorNode = anchorNode.nextSibling until @getText(anchorNode).length > 0
        @setCaretTo(anchorNode, 1)
    setCaretTo: (anchorNode, anchorOffset) ->
      range = document.createRange()
      range.setStart(anchorNode, anchorOffset)
      range.collapse(true)
      @selection.removeAllRanges()
      @selection.addRange(range)
    paste: (event) ->
      clipboardData = event.clipboardData || window.clipboardData
      input = clipboardData.getData('Text')
      document.execCommand('insertText', false, input)
    linebreak: ->
      selection = window.getSelection()
      if selection.getRangeAt && selection.rangeCount
        range = selection.getRangeAt(0)
        range.deleteContents()
        textNode = document.createTextNode("\n")
        range.insertNode(textNode)
        # set caret
        range = document.createRange()
        range.setStart(textNode, 1)
        range.collapse(true)
        @selection.removeAllRanges()
        @selection.addRange(range)
      @updateText(target: @$refs.editable)
    getText: (node) ->
      switch node.nodeName
        when '#text'
          node.textContent
        when 'SPAN'
          Array.from(node.childNodes).filter((n) -> n.nodeName == '#text').map((n) -> n.textContent).join('')
        else
          ''
    catchRevision: ->
      @revisions.push(parts: @parts, caretPosition: @caretPosition)
    undo: ->
      return if @revisions.length < 2
      @revisions.pop()
      @parts = @revisions.last.parts
      @caretPosition = @revisions.last.caretPosition
      @updateWithDisplay()
