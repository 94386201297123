Vue.component 'fade-out',
  props: ['toggle']
  data: ->
    display: false
    fading: false
  watch:
    toggle: ->
      if @toggle
        @display = true
      if !@toggle
        @fading = true
        setTimeout =>
          @fading = false
          @display = false
          @$emit('faded-out')
        , 400
  template: '
    <div v-if="display" style="transition: opacity .4s" :style="{opacity: fading ? 0 : 1}">
      <slot/>
    </div>
  '
