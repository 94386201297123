
  import Bot from 'models/bot.coffee'
  import { confirm } from 'helpers'

  import { Button, H3, Input, PageHeader, Panel, Textarea } from 'components/generic'

  export default
    props:
      bot: Bot

    emits: ['remove']

    data: ->
      debounceTimeout: null

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @bot.save()), 1000)
      deleteBot: ->
        return unless await confirm('Do you really want do delete this bot?')
        @bot.delete().then =>
          @$emit('remove', @bot)

    components:
      Button: Button
      H3: H3
      Input: Input
      PageHeader: PageHeader
      Panel: Panel
      Textarea: Textarea
