import GuidGenerator from 'models/guid_generator.coffee'

Array.prototype.sum = ->
  @reduce((prev, curr) -> prev + curr)

class Node
  @ATTRIBUTES = [
    'type'
    'entity'
    'target'
    'hasInitiative'
    'ofOtherModule'
    'refNode'
    'col'
  ]

  constructor: (@graph, data = {}) ->
    if data.ofOtherModule
      @id = "#{data.type}/#{data.entity.intentIdentifier}"
      @id += '#' while @graph.nodes.find (n) => n.id == @id
    else if data.entity?
      @id = "#{data.type}/#{data.entity.key}"
      @id += '#' while @graph.nodes.find (n) => n.id == @id
    else
      @id = "#{data.type}/#{GuidGenerator.newGuid()}"
    @subgraph = null
    @in = []
    @out = []
    @style = {}
    @ofOtherModule = false
    @update(data)

  update: (data = {}) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  assignToSubgraph: (subgraph) ->
    return if @subgraph?
    @subgraph = subgraph
    @subgraph.nodes.push(@)
    @parents.concat(@children).forEach (n) =>
      n.assignToSubgraph(@subgraph)

  setCol: (currentCol, ancestors = []) ->
    colAlreadySet = @col?
    return if colAlreadySet && @col >= currentCol
    @col = currentCol
    @out.forEach (outEdge) =>
      child = outEdge.endNode
      newAncestors = ancestors.concat([@])
      if child.refNode?
        child.col = currentCol + 1
        return
      if (child.entity?.pinned && !child.ofOtherModule) || !colAlreadySet && (newAncestors.includes(child) || (child.entity?.pinned && !@ofOtherModule && !child.ofOtherModule))
        @graph.addEdge(
          @
          @graph.getOrCreateShadowNode(child, currentCol + 1)
          entity: outEdge.entity
          kind: 'shadow'
        )
        outEdge.remove()
        if @graph.displaySettings.showIncomingNodes
          incomingNode = @graph.addNode(Object.assign({}, @, col: currentCol - 1, refNode: @))
          @graph.addEdge(incomingNode, child, entity: outEdge.entity, kind: 'shadow')
        return
      child.setCol(currentCol + 1, newAncestors)

  shiftRight: ->
    @col += 1
    @children.forEach (child) =>
      child.shiftRight() if @col == child.col

  Object.defineProperties @prototype,
    children:
      get: ->
        @out.map (e) -> e.endNode
    parents:
      get: ->
        @in.map (e) -> e.startNode
    hasNoSuccessor:
      get: ->
        @children.every (n) -> n.isMissing
    isTrigger:
      get: -> @type == 'trigger'
    isUser:
      get: -> @type == 'user'
    isAction:
      get: -> @type == 'action'
    isBot:
      get: -> @type == 'bot'
    isDummy:
      get: -> @type == 'dummy'
    isMissing:
      get: -> @type == 'missing'
    label:
      get: -> @entity?.label
    x:
      get: ->
        sum = 0
        [0...@col].forEach (index) =>
          sum += (if @graph.cols[index] == 'hasInitiative' then @graph.dims.nodeHeight + @graph.dims.initiativeGap + @graph.dims.colWidth else @graph.dims.colWidth)
        sum += @graph.dims.nodeHeight + @graph.dims.initiativeGap if !@hasInitiative && @graph.cols[@col] == 'hasInitiative'
        sum
    y:
      get: ->
        @row * @graph.dims.rowHeight
    width:
      get: ->
        return 0 if @isDummy
        return @graph.dims.nodeHeight + @graph.dims.initiativeGap + @graph.dims.nodeWidth if @hasInitiative
        @graph.dims.nodeWidth
    height:
      get: ->
        @graph.dims.nodeHeight


class Edge
  @ATTRIBUTES = [
    'entity'
    'kind'
  ]

  constructor: (@graph, @startNode, @endNode, data = {}) ->
    @startNode.out.push(@)
    @endNode.in.push(@)
    @update(data)

  update: (data = {}) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  remove: ->
    @startNode.out = @startNode.out.filter (e) => e.id != @id
    @endNode.in = @endNode.in.filter (e) => e.id != @id
    delete @graph.edges[@id]

  Object.defineProperties @prototype,
    id: # only needed for DOM tracking in Vue
      get: -> @startNode.id + ':' + @endNode.id
    isRepresentative:
      get: -> !@startNode.isDummy
    connection:
      get: ->
        return [@] if !@endNode.isDummy
        [@].concat(@endNode.out[0].connection)
    startRow:
      get: -> @startNode.row
    endRow:
      get: -> @endNode.row
    startPoint:
      get: ->
        xOffset = if @startNode.isDummy
            @graph.dims.nodeWidth / 2
          else
            @startNode.width
        x: @startNode.x + xOffset
        y: @startNode.y + (@startNode.height / 2)
    endPoint:
      get: ->
        xOffset = if @endNode.isDummy
            @graph.dims.nodeWidth / 2
          else
            0
        x: @endNode.x + xOffset
        y: @endNode.y + (@startNode.height / 2)
    left:
      get: -> @startPoint.x
    top:
      get: ->
        top = currentTop = @startPoint.y
        @connection.forEach (edge) ->
          currentTop += edge.ownHeight
          top = Math.min(top, currentTop)
        top
    ownWidth:
      get: -> @endPoint.x - @startPoint.x
    width:
      get: -> @connection.reduce(((sum, edge) -> sum + edge.ownWidth), 0)
    ownHeight:
      get: -> @endPoint.y - @startPoint.y
    height:
      get: ->
        top = bottom = @startPoint.y
        @connection.forEach (edge) ->
          if edge.ownHeight < 0
            top += edge.ownHeight
          else if edge.ownHeight > 0
            bottom += edge.ownHeight
        bottom - top
    path:
      get: ->
        path = "M 0 #{@startPoint.y - @top} "
        connection = @connection
        connection.forEach (edge, i) =>
          cPointXOffset = edge.ownWidth / 2
          cPoint1Offset = 0 # if !edge.ownHeight || !connection[i - 1]?.ownHeight then 0 else (edge.ownHeight + connection[i - 1].ownHeight) / 2
          if edge.ownHeight != 0 && connection[i + 1]? && Math.sign(edge.ownHeight) == Math.sign(connection[i + 1].ownHeight)
            cPoint2Offset = (edge.ownHeight + connection[i + 1].ownHeight) / 2
            # shorten control point distance if necessary:
            if Math.sign(edge.ownHeight) > 0 && cPoint2Offset > Math.min(edge.ownHeight, connection[i + 1].ownHeight)
              factor = Math.min(edge.ownHeight, connection[i + 1].ownHeight) / cPoint2Offset
              cPoint2Offset = factor * cPoint2Offset
              cPointXOffset = factor * cPointXOffset
            else if Math.sign(edge.ownHeight) < 0 && cPoint2Offset > Math.max(edge.ownHeight, connection[i + 1].ownHeight)
              factor = Math.max(edge.ownHeight, connection[i + 1].ownHeight) / cPoint2Offset
              cPoint2Offset = factor * cPoint2Offset
              cPointXOffset = factor * cPointXOffset
          else
            cPoint2Offset = 0
          cPoint1 =
            x: edge.startPoint.x + cPointXOffset - @left
            y: edge.startPoint.y + cPoint1Offset - @top
          cPoint2 =
            x: edge.endPoint.x - cPointXOffset - @left
            y: edge.endPoint.y - cPoint2Offset - @top
          targetPoint =
            x: edge.endPoint.x - @left
            y: edge.endPoint.y - @top
          if i == 0
            path += "C #{cPoint1.x} #{cPoint1.y} #{cPoint2.x} #{cPoint2.y} #{targetPoint.x} #{targetPoint.y} "
          else
            path += "S #{cPoint2.x} #{cPoint2.y} #{targetPoint.x} #{targetPoint.y} "
        path


class Subgraph
  constructor: (@graph) ->
    @nodes = []
    @columns = []

  addNode: (data = {}) ->
    n = @graph.addNode(data)
    @nodes.push(n)
    n

  shouldSwap: (a, b, dir) ->
    return false if !a? || !b? || a.rowIndex != b.rowIndex
    edgesAttr = if dir == 'rtl' then 'out' else 'in'
    rowAttr = if dir == 'rtl' then 'endRow' else 'startRow'
    return false if !a[edgesAttr].length || !b[edgesAttr].length
    currentCount = a[edgesAttr].map((e_a) ->
      b[edgesAttr].filter((e_b) -> e_b[rowAttr] < e_a[rowAttr]).length
    ).sum()
    swappedCount = a[edgesAttr].map((e_a) ->
      b[edgesAttr].filter((e_b) -> e_b[rowAttr] > e_a[rowAttr]).length
    ).sum()
    currentCount > swappedCount

  setRows: (dir) ->
    if @columns.length == 1
      @columns[0].forEach (n) -> n.row = 0
      return
    indices = [0..(@columns.length - 1)]
    indices.reverse() if dir == 'rtl'
    indices[1..-1].forEach (index) =>
      nodes = @columns[index]
      @columns[index] = []
      return if !nodes?
      nodes.forEach (n) =>
        n.row = 0
        if dir == 'rtl' && n.children.length == 0 || dir == 'ltr' && n.parents.length == 0
          n.rowIndex = 0
          return
        nextColIndices = if dir == 'rtl'
          n.children.map (child) =>
            @columns[index + 1].findIndex((n) -> n == child)
        else
          n.parents.map (parent) =>
            @columns[index - 1].findIndex((n) -> n == parent)
        n.rowIndex = nextColIndices.sum() / nextColIndices.length
      nodes.sort((a, b) -> a.rowIndex - b.rowIndex).forEach (n) =>
        i = Math.floor(n.rowIndex)
        i += 1 while @columns[index][i]?
        @columns[index][i] = n
      # reduce edge crossings
      @columns[index].forEach (n, i) =>
        [a, b] = [@columns[index][i], @columns[index][i + 1]]
        if @shouldSwap(a, b, dir)
          [@columns[index][i], @columns[index][i + 1]] = [b, a]
      @columns[index].forEach (n, i) -> n.row = i

  setYs: ->
    @nodes.forEach (n) =>
      @columns[n.col] ||= []
      @columns[n.col].push(n)
    [0..1].forEach (i) =>
      @setRows(if i % 2 == 0 then 'rtl' else 'ltr')


export default class Graph
  TYPE_MAP:
    DialogAction: 'action'
    BotIntent: 'bot'
    TriggerIntent: 'trigger'
    UserIntent: 'user'

  constructor: (dialogModule, @displaySettings) ->
    @nodes = []
    @edges = {}
    @subgraphs = []
    @nodeIndex = {}
    @cols = {}
    @dims = {}
    @initFromModule(dialogModule) if dialogModule?

  initFromModule: (@dialogModule) ->
    @createNodes()
    @connectNodes()

  createNodes: ->
    @dialogModule.nodes.forEach (entity) =>
      @nodeIndex[entity.key] = @addNode(
        type: @TYPE_MAP[entity.type]
        entity: entity
        hasInitiative: entity.triggeredInitiatives?.length > 0
      )
      if @displaySettings.showIncomingNodes
        entity.externalIncomingNodes.forEach (externalEntity) =>
          @nodeIndex[@nodeIdentifier(externalEntity, entity)] ||= @addNode(type: @TYPE_MAP[externalEntity.type], entity: externalEntity, ofOtherModule: true)
          @addEdge(
            @nodeIndex[@nodeIdentifier(externalEntity, entity)],
            @nodeIndex[entity.key],
          )

  connectNodes: ->
    @dialogModule.triggerAndUserIntents.forEach (intent) =>
      @connectToTarget(intent, intent)
    @dialogModule.actions.forEach (action) =>
      action.targetsWithType.forEach (obj) =>
        @connectToTarget(action, obj)
    @dialogModule.botIntents.forEach (botIntent) =>
      botIntent.targetsWithType.forEach (obj) =>
        @connectToTarget(botIntent, obj)

  connectToTarget: (entity, targetHost) ->
    targetNode = @getOrCreateTargetNode(entity, targetHost)
    if targetNode?
      @addEdge(
        @nodeIndex[entity.key],
        targetNode,
        entity: targetHost.target
        kind: targetHost.kind
      )

  getOrCreateTargetNode: (entity, targetHost) ->
    if targetHost.target.node? && targetHost.target.ofOtherModule
      return if !@displaySettings.showOutgoingExternalNodes
      nodeType = @TYPE_MAP[targetHost.target.node.type]
      @nodeIndex[@nodeIdentifier(targetHost.target.node, entity)] ||= @addNode(
        type: nodeType
        entity: targetHost.target.node
        ofOtherModule: true
        hasInitiative: targetHost.target.node.triggeredInitiatives?.length > 0
      )
      @nodeIndex[@nodeIdentifier(targetHost.target.node, entity)]
    else if targetHost.target.node?
      @nodeIndex[targetHost.target.node.key]
    else if targetHost.target.nodeKey? # target node does not exist
      @addNode(type: 'missing')

  getOrCreateShadowNode: (child, col) ->
    if @displaySettings.groupNodeReferences
      identifier = "reference:#{child.entity.key}"
      @nodeIndex[identifier] ||= @addNode(Object.assign({}, child, col: col, refNode: child))
      @nodeIndex[identifier]
    else
      @addNode(Object.assign({}, child, col: col, refNode: child))

  addNode: (data = {}) ->
    n = new Node(@, data)
    @nodes.push(n)
    n

  addEdge: (startNode, endNode, data = {}) ->
    return null if !startNode? || !endNode?
    e = new Edge(@, startNode, endNode, data)
    @edges[e.id] = e
    e

  insertDummyNodes: ->
    Object.values(@edges).forEach (edge) =>
      return if Math.abs(edge.startNode.col - edge.endNode.col) < 2
      startNode = edge.startNode
      for col in [(edge.startNode.col + 1)..(edge.endNode.col - 1)]
        dummyNode = @addNode(col: col, type: 'dummy')
        @addEdge(startNode, dummyNode, entity: edge.entity, kind: edge.kind)
        startNode = dummyNode
      @addEdge(startNode, edge.endNode, entity: edge.entity, kind: edge.kind)
      edge.remove()

  layout: (@dims) ->
    return if @nodes.length == 0
    # set start column
    @nodes.filter((n) -> n.entity?.pinned).forEach (n) -> n.setCol(0)
    @nodes.filter((n) -> n.isUser || n.isTrigger).forEach (n) -> n.setCol(0)
    # set column for yet unplaced nodes
    @nodes.filter((n) -> !n.ofOtherModule && !n.col?).forEach (n) -> n.setCol(1)
    # handle incoming nodes
    potentialFirstColExternalNodes = @nodes.filter (n) ->
      n.out.some((outEdge) -> outEdge.endNode.col == 0)
    otherExternalNodes = @nodes.filter (n) ->
      n.ofOtherModule && !n.out.some((outEdge) -> outEdge.endNode.col == 0)
    if potentialFirstColExternalNodes.length
      if @displaySettings.alignNodeTypes
        # shift all nodes right
        @nodes.forEach (n) -> n.col += 1 if n.col?
      else
        # shift only where overlapping
        @nodes.forEach (n) -> n.shiftRight() if n.col == 0 && n.in.length > 0
      potentialFirstColExternalNodes.forEach (n) -> n.col = 0
    otherExternalNodes.forEach (n) ->
      maxChildCol = Math.max(...n.out.map((outEdge) -> outEdge.endNode.col))
      n.setCol(maxChildCol - 1)

    if @displaySettings.alignNodeTypes
      # align columns wrt action nodes
      maxColIndex = Math.max(...@nodes.map((n) -> n.col))
      startingActionShifted = false
      [0..maxColIndex].forEach (col) =>
        colNodes = @nodes.filter (n) -> n.col == col
        return unless colNodes.some (n) => n.isAction
        if !startingActionShifted && colNodes.some((n) => !n.isAction) && colNodes.some((n) => n.isAction && n.in.length == 0)
          colNodes.filter((n) -> n.isAction).forEach (n) -> n.shiftRight()
          startingActionShifted = true
        else
          colNodes.filter((n) -> !n.isAction && !n.isMissing).forEach (n) -> n.shiftRight()
    # insert dummy nodes
    @insertDummyNodes()
    # find unconnected subgraphs
    @subgraphs.push(new Subgraph(@))
    @nodes.forEach (n) =>
      n.assignToSubgraph(@subgraphs[-1..][0])
      @subgraphs.push(new Subgraph(@))
    @subgraphs = @subgraphs.filter (subgraph) -> subgraph.nodes.length
    # set nodes' y positions
    @subgraphs.forEach (subgraph) -> subgraph.setYs()
    # arrange subgraphs
    bottom = -1
    @subgraphs.forEach (subgraph) =>
      top = Math.min(...subgraph.nodes.map((n) -> n.row))
      subgraph.nodes.forEach (n) => n.row = n.row - top + bottom + 1
      bottom = Math.max(...subgraph.nodes.map((n) -> n.row))
    # set column types
    @cols = {}
    @nodes.forEach (n) =>
      n.hasInitiative = false if n.in.length == 0
      @cols[n.col] = 'hasInitiative' if n.hasInitiative

  height: (dims) ->
    Math.max(...@nodes.map((n) -> n.row)) * dims.rowHeight + dims.nodeHeight
  width: (dims) ->
    Math.max(...@nodes.map((n) -> n.x)) + dims.nodeWidth + 60

  nodeIdentifier: (dialogNode, hostDialogNode) ->
    if @displaySettings.groupNodeReferences
      dialogNode.intentIdentifier
    else
      "#{hostDialogNode.key}:#{dialogNode.intentIdentifier}"
