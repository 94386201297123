import { createApp } from 'vue_shims'
import { alert } from 'helpers'

initTrainingDataWeakIntentsApp = (element) ->
  createApp(
    data: ->
      items: $(element).data('items')
      projectId: $(element).data('project-id')
      stageIds: $(element).data('stage-uuids')
      languages: $(element).data('languages')
      currentIndex: 0

    computed:
      currentItem: ->
        return null if @items.lenth == 0
        @items[@currentIndex]
      canonicalUtterances: ->
        return [] unless @currentItem?.canonicalUtterances?
        Array.unique(
          @currentItem.confusedUtterances.map((utt) -> utt.language)
        ).map((language) =>
          {language: language, text: @currentItem.canonicalUtterances[language]}
        ).filter((utt) -> utt.text?)

    methods:
      utteranceHtml: (trainingDatum) ->
        TrainingDataHelper.utteranceHtml(trainingDatum)
      severityClass: (trainingDatum) ->
        if trainingDatum.severity < 1/3
          'text-success'
        else if trainingDatum.severity >= 2/3
          'text-danger'
        else
          'text-warning'
      previous: ->
        @currentIndex = Math.max(0, @currentIndex - 1)
      next: ->
        @currentIndex = Math.min(@currentIndex + 1, @items.length - 1)
      reassign: (index) ->
        trainingDatum = @currentItem.confusedUtterances[index]
        return if trainingDatum.resolved
        $.post(
          Routes.reassign_project_training_data_path(@projectId, Globals.stageLevel),
          _method: 'patch'
          training_datum: trainingDatum
          new_game: @currentItem.game
          new_intent: @currentItem.intent
          problem_id: @currentItem.id
          problem_kind: 'weak_intent'
        ).done(->
          trainingDatum.resolved = true
        ).fail(@handleError)
        null
      deleteIt: (index) ->
        trainingDatum = @currentItem.confusedUtterances[index]
        return if trainingDatum.resolved
        $.post(
          Routes.delete_project_training_data_path(@projectId, Globals.stageLevel),
          _method: 'delete'
          training_datum: trainingDatum
          problem_id: @currentItem.id
          problem_kind: 'weak_intent'
        ).done(->
          trainingDatum.resolved = true
        ).fail(@handleError)
        null
      handleError: (response) ->
        if response.status == 418
          await alert(response.responseText)
        else
          await alert('Sorry, an internal error occurred.')
      retrain: ->
        $.post(Routes.retrain_project_training_data_path(@projectId, Globals.stageLevel, stage_uuids: @stageIds))
        $(@$el).closest('.modal').modal('hide')

    template: '
      <div class="training-data training-data-conflicts">

        <template v-if="currentItem">
          <section>
            <span class="text-normal">Intent:</span>
            <span class="label label-equal label-gray">
              {{ currentItem.game }}/{{ currentItem.intent }}()
            </span>
          </section>

          <div class="table-wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 30%">Utterance</th>
                  <th style="width: 8%">Origin</th>
                  <th style="width: 10%">Language</th>
                  <th style="width: 8%" class="text-center">Severity</th>
                  <th style="width: 23%">Conflicting intent</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="utterance in canonicalUtterances">
                  <td>{{ utterance.text }}</td>
                  <td>creator</td>
                  <td>{{ languages[utterance.language] }}</td>
                  <td class="text-center text-large">
                    <span class="fa fa-circle text-success"></span>
                  </td>
                  <td></td>
                  <td><div class="utterance-buttons"></div></td>
                </tr>
                <tr v-for="trainingDatum, index in currentItem.confusedUtterances">
                  <td v-html="utteranceHtml(trainingDatum)" class="ellipsis"></td>
                  <td class="text-lowercase">{{ trainingDatum.origin }}</td>
                  <td>{{ languages[trainingDatum.language] }}</td>
                  <td class="text-center text-large">
                    <span class="fa fa-circle" :class="severityClass(trainingDatum)"></span>
                  </td>
                  <td>
                    <span class="label label-equal label-gray">
                      {{ trainingDatum.confusedWithGame }}/{{ trainingDatum.confusedWithIntent }}()
                    </span>
                  </td>
                  <td>
                    <div v-if="!trainingDatum.resolved" class="utterance-buttons flex-container center-items end-content">
                      <div @click="deleteIt(index)" class="btn btn-caution">delete</div>
                      &ensp;
                      <div @click="reassign(index)" class="btn btn-default">reassign</div>
                    </div>
                    <div v-if="trainingDatum.resolved" class="utterance-buttons flex-container center-items center-content">
                      done
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <button @click="previous" class="btn btn-default" :class="{invisible: currentIndex == 0}">←</button>
            <div class="text-normal">{{ currentIndex + 1 }} of {{ items.length }}</div>
            <button @click="next" class="btn btn-default" :class="{invisible: currentIndex == items.length - 1}">→</button>
          </div>
        </template>

        <div v-if="!currentItem" class="text-center text-large">
          There are no weak intents to be cured at the moment.
          <div class="spacer"></div>
        </div>

        <div class="button-container">
          <button class="btn btn-default auto-margin-right" data-dismiss="modal">Close</button>
          <button class="btn btn-primary" @click="retrain">Close and retrain</button>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'shown.bs.modal', ->
  element = document.getElementById('training-data-weak-intents-app')
  initTrainingDataWeakIntentsApp(element) if element?
