import DialogTarget from 'models/dialog_target.coffee'

export default class InstagramStoryAction
  constructor: (data = {}) ->
    data ||= {}
    @handover = if data.handover? then data.handover else false
    @target = new DialogTarget({}, data.target)

  Object.defineProperties @prototype,
    export:
      get: ->
        handover: @handover
        target: @target.export
