import { createApp } from 'vue_shims'
import { confirm } from 'helpers'

initContentObjectTypesApp = (element) ->
  createApp(
    data: ->
      dirty: false
      projectId: $(element).data('project-id')
      cotId: $(element).data('cot-id')
      cotConfig: $(element).data('config')
      count: $(element).data('count')
      currentTab: 'data'
      currentDataSourceTab: 'upload'
      currentLanguage: undefined
      updateAction: 'config'
      updateMedium: ''
      submitted: false
      config:
        type: ''
        description: ''
        apiUrl: ''
        apiInterval: ''
        updateMode: 'update'
        languages: []
        dictionary: {}
        signature: {}
        attributes: []
      maxEditableCount: 200
      coData: undefined
      jsonConfig: undefined
      jsonData: undefined

    watch:
      clonedConfig:
        handler: (newValue, oldValue) ->
          return if @dirty
          # ignore changes in transient properties:
          return if JSON.stringify(ObjectProcessor.clone(oldValue)) == JSON.stringify(ObjectProcessor.clone(newValue))
          @dirty = true
        deep: true

    computed:
      # needing this in order to get old value in config watcher:
      clonedConfig: ->
        ObjectProcessor.clone(@config, removeTransientProperties: false)
      primitiveAttributes: ->
        @config.attributes.filter (attribute) -> !attribute.attribute.conditions
      composedAttributes: ->
        @config.attributes.filter (attribute) -> attribute.attribute.conditions

    mounted: ->
      for attr of @config
        @config[attr] = @cotConfig[attr] if @cotConfig[attr]
      @currentLanguage = @config.languages[0]
      if @cotId? && @count <= @maxEditableCount
        $.getJSON Routes.data_project_content_object_type_path(Globals.projectId, Globals.stageLevel, @cotId), (data) =>
          @coData = data
      Vue.nextTick =>
        @dirty = false

    methods:
      addComposedAttribute: ->
        @config.attributes.push(
          attribute:
            label: ''
            conditions: []
          dictionary: {}
        )
        @$refs.composedAttributesPagination.setLast()
        Vue.nextTick =>
          @$refs.composedAttributesPagination.setLast()
          $(@$el).find('.collapse')
            .collapse('hide')
            .last().collapse('show')
      addNewLabel: (item) ->
        return if item.newLabel.length == 0
        Vue.setDefault(item, 'labels', {})
        Vue.setDefault(item.labels, @currentLanguage, [])
        item.labels[@currentLanguage].push(item.newLabel)
        item.newLabel = ''
      removeLabel: (item, index) ->
        item.labels[@currentLanguage].splice(index, 1)
      removeComposedAttribute: (attribute) ->
        index = @config.attributes.indexOf(attribute)
        @config.attributes.splice(index, 1)
        Vue.nextTick =>
          @$refs.composedAttributesPagination.setPage()
      submit: ->
        @jsonConfig = JSON.stringify(ObjectProcessor.clone(@config))
        @jsonData = JSON.stringify(@coData)
        # wait until DOM is updated
        Vue.nextTick =>
          @$refs.form.submit()
      updateData: (medium) ->
        if @dirty
          return unless await confirm('WARNING: You have unsaved changes that will be discarded if you proceed!')
        @submitted = true
        @updateAction = 'data'
        @updateMedium = medium
        @jsonConfig = JSON.stringify(@config)
        # wait until DOM is updated
        Vue.nextTick =>
          @$refs.form.submit()
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('content-objects-app')
  initContentObjectTypesApp(element) if element?
