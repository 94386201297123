export default class WorkflowParameterMapping
  constructor: (data = {}) ->
    data ||= {}
    @key = data.key || ''
    @expression = data.expression || ''
    @defaultValue = data.defaultValue || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        key: @key
        expression: @expression
        defaultValue: @defaultValue
