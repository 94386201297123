
  import * as htmlToImage from 'html-to-image'
  import StatusOverlay from './status_overlay.vue'
  import { ActionMenu, H3 } from 'components/generic'
  import { DownloadIcon } from '@heroicons/vue/outline'
  import { DotsHorizontalIcon } from '@heroicons/vue/solid'

  export default
    props:
      title: String
      subtitle: String
      data: Object
      absoluteNumbers:
        type: Boolean
        default: false
      statusObject:
        type: Object
        default: {}

    data: ->
      weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      timeRanges: [
        '00:00 – 02:00',
        '02:00 – 04:00',
        '04:00 – 06:00',
        '06:00 – 08:00',
        '08:00 – 10:00',
        '10:00 – 12:00',
        '12:00 – 14:00',
        '14:00 – 16:00',
        '16:00 – 18:00',
        '18:00 – 20:00',
        '20:00 – 22:00',
        '22:00 – 24:00',
      ]
      actionMenuItems: [
        {icon: DownloadIcon, label: 'Download data', method: @downloadData},
        {icon: DownloadIcon, label: 'Download as image', method: @downloadImage},
      ]

    computed:
      maxValue: ->
        Math.max(...Object.values(@data).flat())

    methods:
      opacity: (day, index) ->
        return {opacity: 0} if !@data[day]? || @maxValue == 0
        value = @data[day][index]
        opacity: value / @maxValue
      percentLabel: (day, index) ->
        return '' unless @data[day]?
        value = @data[day][index]
        if @absoluteNumbers
          value.toFixed(2)
        else
          (value * 100).toFixed(2) + '%'
      downloadData: ->
        rows = [[''].concat(@weekdays)]
        @timeRanges.forEach (label, index) =>
          rows.push(
            [label].concat(@weekdays.map (day) => @percentLabel(day, index))
          )
        csvString = rows.map((row) -> row.join(',')).join("\n")
        @download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), "#{@title}.csv")
      downloadImage: ->
        setTimeout =>
          htmlToImage.toPng(@$refs.table).then (pngString) =>
            @download(pngString, "#{@title}.png")
        , 500
      download: (fileString, fileName) ->
        a = document.createElement('a')
        a.href = fileString
        a.download = fileName
        a.click()
        a.remove()

    components:
      ActionMenu: ActionMenu
      H3: H3
      StatusOverlay: StatusOverlay
