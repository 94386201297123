
  import Bot from 'models/bot'
  import PlatformApi2 from 'models/platform_api2'
  import User from 'models/user'

  import BotSelector from 'components/publishing/bot_selector.vue'
  import StatusPanel from 'components/publishing/status_panel.vue'
  import PublishingLayout from 'components/publishing/layout.vue'
  import { Button, DocumentationLink, EnvironmentBadge, H3, Modal, PageHeader } from 'components/generic'
  import { CheckIcon, ExclamationIcon, InformationCircleIcon, RefreshIcon } from '@heroicons/vue/outline'
  import { CheckCircleIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot

    data: ->
      allEnvironments: []
      currentEnvironment: null
      pollingTimeout: null
      publishers: {}
      approving: false
      loading: false

    computed:
      mayPublishToTest: ->
        Globals.currentUser.may('edit_project_settings_group2', Globals.project)
      mayPublishToLive: ->
        Globals.currentUser.may('edit_project_settings_group3', Globals.project)
      environments: ->
        DEV: @allEnvironments.find (env) -> env.environment == 'DEV'
        TEST: @allEnvironments.find (env) -> env.environment == 'TEST'
        LIVE: @allEnvironments.find (env) -> env.environment == 'LIVE'
      environmentInfo: ->
        [
          {label: 'Bot name', value: @currentEnvironment.botLabel}
          {label: 'Config last changed:', value: @currentEnvironment.timeLastConfigChangeFormatted}
          {label: 'Config published:', value: @currentEnvironment.timePublishedFormatted}
          {label: 'Last model update:', value: @currentEnvironment.timeLastModelChangeFormatted}
          {label: 'Project ID', value: Globals.projectUuid}
          {label: 'Bot ID', value: @currentEnvironment.botId}
          {label: 'Bot version', value: @currentEnvironment.botVersion}
        ]
      publishingStatusTexts: ->
        Object.fromEntries(
          ['TEST', 'LIVE'].map (env) =>
            text = if @environments[env].approvalRequired == 'asdf'
              'Ready for being published'
            else if @environments[env].publishedByUserId?
              if @publishers[env]?
                "Published by #{@publishers[env].label}"
              else
                "Published by ..."
            else
              'Published automatically'
            [env, text]
        )

    watch:
      'environments.TEST.publishedByUserId': (newValue, oldValue) ->
        if newValue?
          User.load(newValue.split('-').last)
            .then (user) => @publishers.TEST = user
            .catch => null
        else
          @publishers.TEST = null
      'environments.LIVE.publishedByUserId': (newValue, oldValue) ->
        if newValue?
          User.load(newValue.split('-').last)
            .then (user) => @publishers.LIVE = user
            .catch => null
        else
          @publishers.LIVE = null

    created: ->
      @pollEnvironments()

    unmounted: ->
      clearTimeout(@pollingTimeout)

    methods:
      pollEnvironments: ->
        @allEnvironments = await PlatformApi2.getEnvironments()
        @loading = false if @approving == false
        @pollingTimeout = setTimeout(@pollEnvironments, 3000)
      openInfo: (env) ->
        @currentEnvironment = @environments[env]
        @$refs.modal.open()
      publish: (env) ->
        @approving = true
        @loading = true
        PlatformApi2.approveDeployment(@environments[env].deploymentId)
          .finally => @approving = false
      testStatusText: (env) ->
        switch @environments[env].testingStatus
          when 'OK' then 'Validation passed successfully'
          when 'RUNNING' then 'Validation is running'
          when 'FAILED' then 'Validation failed'
      trainingStatusText: (env) ->
        switch @environments[env].trainingStatus
          when 'OK' then 'Model trained successfully'
          when 'RUNNING' then 'Model training in progress'
          when 'FAILED' then 'Model training failed'

    components:
      BotSelector: BotSelector
      Button: Button
      CheckCircleIcon: CheckCircleIcon
      CheckIcon: CheckIcon
      ChevronDoubleLeftIcon: ChevronDoubleLeftIcon
      DocumentationLink: DocumentationLink
      EnvironmentBadge: EnvironmentBadge
      ExclamationIcon: ExclamationIcon
      StatusPanel: StatusPanel
      H3: H3
      InformationCircleIcon: InformationCircleIcon
      Modal: Modal
      PageHeader: PageHeader
      PublishingLayout: PublishingLayout
      RefreshIcon: RefreshIcon
