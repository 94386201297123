Vue.component 'sliding-switch',
  props:
    modelValue:
      required: true
    label:
      type: String
    trueValue:
      default: true
    falseValue:
      default: false
  data: ->
    checkboxValue: @value
  methods:
    update: ->
      @$emit('update:modelValue', @checkboxValue)
      @$emit('change')
  template: '
    <label class="sliding-switch">
      <input
        type="checkbox"
        v-model="checkboxValue"
        @change="update"
        :true-value="trueValue"
        :false-value="falseValue"
        >
      <span class="slider"></span>
      <span v-if="label" class="switch-label-right" v-html="label"></span>
    </label>
  '
