import Conversation from 'models/conversation'
import Message from 'models/inbox_message'

Vue.component 'inbox2-message-list',
  props:
    conversation: Conversation
    initialUrl: # loading url
      default: null
    dataKey: # may me used if new items are nested in response data
      default: null
  data: ->
    url: @initialUrl
    params: {pageSize: 20}
    loading: false # current loading state
    error: false # current error state
    threshold: 400 # in pixels (if end of inner conatainer is nearer than threshold, new item will be loaded)

  computed:
    lastMessageId: ->
      @conversation.messages[-1..][0]?.id

  watch:
    lastMessageId: ->
      # keep scrolled to bottom
      scrolledToBottom = $(@$el).scrollTop() + 20 >= @$el.scrollHeight - $(@$el).height()
      if scrolledToBottom
        Vue.nextTick(@scrollToBottom)

  mounted: ->
    if @conversation.messages.length < @params.pageSize && !@conversation.finished
      @load()
        .then(@scrollToBottom)
    else
      Vue.nextTick =>
        @scrollToBottom()
    $(@$el).on 'scroll', @check

  unmounted: ->
    @conversation.messages.forEach (message) -> Vue.delete(message, 'annotatable')

  methods:
    # check whether new items should be loaded
    check: ->
      new Promise (resolve, reject) =>
        if @loading || @conversation.finished
          resolve()
        else if $(@$el).scrollTop() < @threshold
          @load().then(resolve)
        else
          resolve()
    # load new messages
    load: ->
      new Promise (resolve, reject) =>
        if @conversation.finished
          resolve()
          return
        @loading = true
        @error = false
        @params.beforeId = @conversation.messages[0]?.id
        @$root.api.loadMessages(@conversation, @params)
          .then (data) =>
            @insert(data).then(resolve)
          .catch =>
            @loading = false
            @error = true
            reject()
    insert: (data) ->
      newMessages = data.map((data) -> new Message(data))
      oldOffset = @$el.scrollTop - @$el.scrollHeight
      # prepend new messages
      @conversation.messages.unshift.apply(@conversation.messages, newMessages)
      Vue.nextTick =>
        # keep scroll position
        @$el.scrollTop = @$el.scrollHeight + oldOffset
      new Promise (resolve, reject) =>
        Vue.nextTick =>
          # update status
          @conversation.finished = if data.finished? then data.finished else newMessages.length < @params.pageSize
          @loading = false
          @check().then(resolve)
    # also for external use
    scrollToBottom: ->
      $(@$el).scrollTop(@$el.scrollHeight - $(@$el).height())
    scrollToItem: (id) ->
      item = $(@$el).find("[data-id=\"#{id}\"]")
      top = $(@$el).scrollTop() + item.position().top
      offset = ($(@$el).height() - item.height()) / 2
      $(@$el).animate(scrollTop: top - offset, 200)

    goToAnnotatable: (direction) ->
      new Promise (resolve, reject) =>
        if @loading || !@$parent.expanded
          tryAgain = =>
            return if @loading || !@$parent.expanded
            unwatchLoading()
            unwatchExpanded()
            resolve(@goToAnnotatable(direction))
          unwatchLoading = @$watch 'loading', tryAgain
          unwatchExpanded = @$watch '$parent.expanded', tryAgain
        else
          @findAnnotatable(direction)
            .then (annotatable) =>
              Vue.set(annotatable, 'annotatable', true)
              resolve(annotatable)
              @scrollToItem(annotatable.id)
            .catch(reject)
    findAnnotatable: (direction) ->
      currentIndex = @conversation.messages.findIndex (message) -> message.annotatable
      if currentIndex >= 0
        currentAnnotatable = @conversation.messages[currentIndex]
        Vue.delete(currentAnnotatable, 'annotatable')
      new Promise (resolve, reject) =>
        @findRecursively(1, currentIndex, direction)
          .then(resolve)
          .catch(reject)
    findRecursively: (i, lastIndex, direction) ->
      new Promise (resolve, reject) =>
        # first check if next annotatable message is in current message list
        if direction == 'up'
          if lastIndex == -1 then lastIndex = @conversation.messages.length
          annotatable = @conversation.messages.slice(0, lastIndex).reverse()
            .find (message) -> message.interpretation?.annotationSuggested && !message.annotated
        else if direction == 'down'
          if lastIndex == -1
            if @conversation.annotatableMessages.length == 0
              reject()
              return
            if @conversation.messages[0].timestamp > @conversation.annotatableMessages[0].timestamp
              annotatable = null
            else
              annotatable = @conversation.messages
                .find (message) -> message.interpretation?.annotationSuggested && !message.annotated
          else
            annotatable = @conversation.messages.slice(lastIndex + 1)
              .find (message) -> message.interpretation?.annotationSuggested && !message.annotated
        # if it is not, load more messages and search again
        if annotatable?
          resolve(annotatable)
        else if i < 9
          collectionLength = @conversation.messages.length
          @load()
            .then =>
              if @conversation.messages.length - collectionLength == 0 # no more messages
                reject()
              else
                newLastIndex = if direction == 'down' then lastIndex else @conversation.messages.length - collectionLength
                @findRecursively(i + 1, newLastIndex, direction)
                  .then(resolve)
                  .catch(reject)
        else
          reject()

  template: '
    <div class="infinite-scroll" :class="{loading: loading && conversation.messages.length == 0}">
      <div v-if="error" class="alert alert-danger">Server error – could not load messages.</div>
      <slot v-bind="conversation.messages"></slot>
    </div>
  '
