
  import BotIntent from 'models/bot_intent.coffee'
  import Priming from 'models/priming.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import PrimingEditor from './priming_editor.vue'
  import { Button } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Array
      host: [BotIntent, Verbalization]

    data: ->
      primings: @modelValue
      primingEditorRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @primings = @modelValue

    beforeUpdate: ->
      @primingEditorRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @primings)
      addPriming: ->
        @primings.push(new Priming(@host))
        @$nextTick => @primingEditorRefs.last.edit()
      removePriming: (index) ->
        @primingEditorRefs[index].close()
        @primings.splice(index, 1)
        @update()
      setPrimingEditorRef: (el) ->
        @primingEditorRefs.push(el) if el?

    components:
      Button: Button
      PrimingEditor: PrimingEditor
