import { createApp } from 'vue_shims'

initNlpDebuggerApp = (element) ->
  createApp(
    data: ->
      stages: $(element).data('stages')
      input: ''
      currentStage: null
      sending: false
      result: null
      errorMessage: null
      infoMessage: null

    computed:
      activeStages: ->
        return [] unless @stages
        @stages.filter (stage) -> stage.instanceUuid
      hasInterpretations: ->
        @result?.intents?.length

    methods:
      run: ->
        return unless @input
        return unless @currentStage
        @result = null
        @errorMessage = null
        @infoMessage = null
        @sending = true
        $.getJSON(
          Routes.run_nlp_project_unit_tests_path(Globals.projectId, Globals.stageLevel),
          instance_uuid: @currentStage.instanceUuid, utterance: @input
        ).done((response) =>
          if response.success
            if (@result = JSON.parse(response.data))?
              @result.intents.sort (a, b) -> b.confidence - a.confidence
            else
              @infoMessage = response.message
          else
            @errorMessage = response.message
        ).fail(=>
          @errorMessage = 'A server error occurred.'
        ).always(=>
          @sending = false
        )

    template: '
      <div>
        <div class="panel-body">
          <div class="form-group">
            <select v-model="currentStage" class="styled-form-field">
              <option :value="null" disabled>select a stage</option>
              <option v-for="stage in activeStages" :value="stage" v-text="stage.label"></option>
            </select>
          </div>
          <div class="form-group" style="position: relative">
            <input type="text" v-model="input" placeholder="Type a message ..." @keyup.enter="run" class="form-control">
            <div @click="run" :class="{disabled: sending}" class="btn btn-icon-light send-message-button">
              <div v-if="!sending" class="fa fa-paper-plane"></div>
              <div v-if="sending" class="fa fa-sync fa-spin"></div>
            </div>
          </div>
        </div>

        <div class="panel-body" v-cloak>
          <h4>NLP interpretation summary</h4>
          <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
          <div v-if="infoMessage" class="alert alert-info">{{ infoMessage }}</div>
          <pre v-if="hasInterpretations">
            <table class="table-simple">
              <tr v-for="interpretation in result.intents">
                <td>Confidence: {{ interpretation.confidence }}&ensp;</td>
                <td>Language: {{ interpretation.language.toLowerCase().capitalize() }}&ensp;</td>
                <td>Intent: {{ interpretation.intent }}&ensp;</td>
                <td>Source: {{ interpretation.source }}&ensp;</td>
              </tr>
            </table>
          </pre>
          <pre v-if="result && !hasInterpretations">
            No interpretations found
          </pre>
          <div v-if="sending" class="loading"></div>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('nlp-debugger-app')
  initNlpDebuggerApp(element) if element?
