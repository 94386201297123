import GuidGenerator from 'models/guid_generator.coffee'

export default class PayloadSlot
  constructor: (@payload, data) ->
    data ||= {}
    @id = GuidGenerator.newGuid()
    @slotRole = data.slotRole || ''
    @value = data.value || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        slotRole: @slotRole
        value: @value
    dialogNode:
      get: -> @payload.dialogNode
