import 'javascripts/bootstrap3-datetimepicker.min.js' # from vendor dir

export default {
  props:
    modelValue:
      type: String
      required: true
    config:
      type: Object
      default: {}

  mounted: ->
    $(@$el).datetimepicker(
      Object.assign({locale: 'en_GB'}, @config)
    )
    $(@$el).on 'dp.change', (e) =>
      @$emit('update:modelValue', @$el.value)

  template: '
    <input type=\'text\' :value="modelValue"/>
  '
}
