
  import PlatformApi from 'models/platform_api.coffee'
  import { CloudDownloadIcon, } from '@heroicons/vue/solid'

  export default
    computed:
      invoices: ->
        @$root.organization.invoices || []

    methods:
      dateFormatted: (timestamp) ->
        moment(timestamp).format(t('formats.date'))
      downloadPdf: (invoiceId) ->
        PlatformApi.Webapp.getInvoiceUrl(@$root.organization.id, invoiceId)
          .then (data) ->
            window.location = data

    components:
      CloudDownloadIcon: CloudDownloadIcon
