import BotbuilderApi from 'models/botbuilder_api.coffee'
import IntegrationInspection from 'models/integration_inspection.coffee'
import IntegrationParameter from 'models/integration_parameter'
import IntegrationParameterMapping from 'models/integration_parameter_mapping'
import Status from 'models/status.coffee'

export default class Integration
  @TYPES =
    InboundApi:
      label: 'inbound'
    OutboundApi:
      label: 'outbound'

  @REQUEST_METHODS = [
    {key: 'GET'}
    {key: 'POST'}
    {key: 'PATCH'}
    {key: 'PUT'}
    {key: 'DELETE'}
  ]

  @NO_CONTENT_TYPE = '---none---'
  @CONTENT_TYPES = [
    {key: 'application/json'}
    {key: 'application/x-www-form-urlencoded'}
    {key: 'multipart/form-data'}
    {key: @NO_CONTENT_TYPE, label: '< none (custom value may be set below) >'}
  ]

  constructor: (data = {}) ->
    @id = data.id
    @type = data.type || 'InboundApi'
    @secretAttachments = []
    switch @type
      when 'OutboundApi'
        @inputParameters = []
        @outputMapping = []
        @method = 'GET'
        @headers = {}
      when 'InboundApi'
        @inputMapping = []
        @responseParameters = []
        @responseBody = ''
    @inspection = new IntegrationInspection(@)
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @id ||= data.id
    @label = data.label if data.label?
    @description = data.description if data.description?
    @url = data.url if data.url?
    @secretAttachments = data.secretAttachments if data.secretAttachments?
    switch @type
      when 'OutboundApi'
        if data.inputParameters?
          @inputParameters = data.inputParameters.map (parameterData) -> new IntegrationParameter(parameterData)
        if data.outputMapping?
          @outputMapping = data.outputMapping.map (mappingData) -> new IntegrationParameterMapping(mappingData)
        @method = data.method if data.method?
        @headers = data.headers if data.headers?
        @body = data.body if data.body?
      when 'InboundApi'
        if data.inputMapping?
          @inputMapping = data.inputMapping.map (mappingData) -> new IntegrationParameterMapping(mappingData)
        if data.responseParameters?
          @responseParameters = data.responseParameters .map (parameterData) -> new IntegrationParameter(parameterData)
        @responseBody = data.responseBody if data.responseBody?

  setType: (type) ->
    @type = type
    switch @type
      when 'OutboundApi'
        @inputParameters = []
        @outputMapping = []
        @method = 'GET'
        @headers = {}
      when 'InboundApi'
        @inputMapping = []
        @responseParameters = []
        @responseBody = ''

  clone: ->
    new Integration(@export)

  save: ->
    if @id
      BotbuilderApi.updateIntegration(@)
    else
      BotbuilderApi.createIntegration(@).then (integrationData) => @id = integrationData.id

  delete: ->
    BotbuilderApi.deleteIntegration(@)

  inspect: ->
    BotbuilderApi.inspectIntegration(@)
      .then (data) => @inspection = new IntegrationInspection(@, data)

  execute: ->
    BotbuilderApi.executeIntegration(@)
      .then (data) => @executionResult = data

  setHeaders: (headersArrayWithoutContentType, contentTypeHeaderForSelector) ->
    headers = {}
    if Integration.CONTENT_TYPES.map((ct) -> ct.key).includes(contentTypeHeaderForSelector) && contentTypeHeaderForSelector != Integration.NO_CONTENT_TYPE
      headers['Content-Type'] = contentTypeHeaderForSelector
    @headers = Object.assign(
      headers,
      Object.fromEntries(
        headersArrayWithoutContentType.filter((header) -> header.key).map (header) -> [header.key, header.value]
      )
    )

  Object.defineProperties @prototype,
    pristine:
      get: -> !@id?
    typeLabel:
      get: -> Integration.TYPES[@type]?.label
    timeLastRequestFormatted:
      get: ->
        return '' if !@timeLastRequest?
        moment(@timeLastRequest).format(t('formats.dateTime'))
    contentTypeHeader:
      get: ->
        @headers?['Content-Type']
    contentTypeHeaderForSelector:
      get: ->
        if Integration.CONTENT_TYPES.map((ct) -> ct.key).includes(@contentTypeHeader)
          @contentTypeHeader
        else Integration.NO_CONTENT_TYPE
    headersArrayWithoutContentType:
      get: ->
        return [] unless @headers?
        result = Object.entries(@headers).map ([key, value]) -> {key: key, value: value}
        if Integration.CONTENT_TYPES.map((ct) -> ct.key).includes(@contentTypeHeader)
          result = result.filter (header) -> header.key != 'Content-Type'
        result
    export:
      get: ->
        obj =
          id: @id
          type: @type
          label: @label
          description: @description
          url: @url
          secretAttachments: @secretAttachments
        switch @type
          when 'OutboundApi'
            obj.inputParameters = @inputParameters.map (parameter) -> parameter.export
            obj.outputMapping = @outputMapping.map (mapping) -> mapping.export
            obj.method = @method
            obj.headers = @headers
            obj.body = @body
          when 'InboundApi'
            obj.inputMapping = @inputMapping.map (mapping) -> mapping.export
            obj.responseParameters = @responseParameters.map (parameter) -> parameter.export
            obj.responseBody = @responseBody
            obj.examplePayload = @examplePayload
        obj
