Vue.component 'result-labels',
  props: ['attributes']
  computed:
    wrappedAttributes: ->
      for attribute in @attributes
        if typeof attribute == 'string'
          label: attribute
          level: 0
        else
          attribute
  template: '
    <div class="flex-container">
      <div>
        <div v-for="attribute in wrappedAttributes" class="api-result-cell">
          <span v-if="attribute.klass == \'Array\'">List →&nbsp;</span>
        </div>
      </div>
      <div style="flex-grow: 1">
        <div
          v-for="attribute in wrappedAttributes"
          class="flex-container api-result-cell"
          :style="{paddingLeft: (attribute.level * 20) + \'px\'}"
          >
          <input
            type="text"
            :value="\'${\' + attribute.label + \'}\'"
            readonly=""
            class="form-control"
            >
          <copy-button/>
        </div>
      </div>
    </div>
  '
