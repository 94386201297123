import BotbuilderApi from 'models/botbuilder_api.coffee'
import ParameterComponent from 'models/parameter_component.coffee'
import Validation from 'models/validation.coffee'
import ValueType from 'models/value_type.coffee'

export default class ContextParameter
  @VALUE_TYPES = Object.assign({SearchFilter: 'Search filter'}, ValueType.TYPES)

  constructor: (data = {}) ->
    @update(data)

  update: (data) ->
    data ||= {}

    @key = data.key || ''
    @_label = data.label || ''
    @description = data.description || ''
    @category = data.category || ''
    @collectionType = data.collectionType || 'none'
    @valueType = data.valueType || 'string'
    @entitySchemaKey = data.entitySchemaKey
    @readOnly = if data.readOnly? then data.readOnly else false
    @expectedValues = data.expectedValues || []
    @validationRules = (data.validationRules || []).map (validationData) => new Validation(validationData)
    @settings = data.settings || {displayInInbox: false}
    @deleted = !!data.dateDisabled
    if data.components
      @components = data.components.map (componentData) => new ParameterComponent(@, componentData)

    this

  clone: ->
    new ContextParameter(@export)

  create: ->
    BotbuilderApi.createContextParameter(@)
      .then (data) => @update(data)

  save: ->
    BotbuilderApi.updateContextParameter(@)

  delete: ->
    BotbuilderApi.deleteContextParameter(@)

  searchExpectedValues: (botId, query) ->
    BotbuilderApi.search(botId, query, types: ['ExpectedContextValue'], parameterKey: @key)
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) -> matchData.entity

  Object.defineProperties @prototype,
    label:
      get: ->
        @_label || @key
      set: (label) ->
        @_label = label
    fullLabel:
      get: -> @label
    parameter: # just to be able to do `componentOrContextParameter.parameter`
      get: -> @
    isPredefined:
      get: ->
        @key.length > 0 && !@key.startsWith('global.')
    pristine:
      get: ->
        @key.length == 0
    computedCategory:
      get: ->
        return @category if @category
        return @key.split('.')[0] if @isPredefined
        'custom'
    contentSearchSchemaKey:
      get: ->
        return null unless @key.startsWith('contentSearch.')
        @key.replace('contentSearch.', '').replace(/\..+/, '')
    contentSearchBasicAttr:
      get: ->
        return null unless @contentSearchSchemaKey?
        return null if @contentSearchAttribute?
        @key.match(new RegExp("contentSearch\\.#{@contentSearchSchemaKey}\\.(.+)"))?[1]
    contentSearchAttribute:
      get: ->
        return null unless @contentSearchSchemaKey?
        @key.match(new RegExp("contentSearch\\.#{@contentSearchSchemaKey}\\.attributes\\.(.+)"))?[1]
    export:
      get: ->
        key: @key
        label: @_label
        description: @description
        category: @category
        collectionType: @collectionType
        valueType: @valueType
        entitySchemaKey: @entitySchemaKey
        expectedValues: @expectedValues
        validationRules: @validationRules.map (validation) -> validation.export
        settings: @settings
        components: if @components?
          @components.map (component) -> component.export
        else
          null
    valueTypeLabel:
      get: ->
        "#{ContextParameter.VALUE_TYPES[@valueType]}#{if @collectionType != 'none' then " #{@collectionType}" else ''}"
    reference:
      get: -> @key
