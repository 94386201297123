import draggable from 'vuedraggable'

Vue.component 'form-filling-game',
  props: ['config']
  data: ->
    state: state
    gameValidator: GameValidator
    apiCallData: {}
    errorMessage: null
  computed:
    availableContextParameters: ->
      @state.globalContextParameters.map((cp) -> Object.assign({}, cp, {label: "global.#{cp.label}"}))
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'invocationUtterance',
            'introductoryMessage',
            'formFields.nlg',
            # result messages:
            'emptyResultPresentation',
            'resultPresentation',
          ]
        )
      deep: true
  created: ->
    # amend old format
    if typeof @config.apiUrl == 'string'
      Vue.set(@config, 'apiUrl', {url: @config.apiUrl})
    # make sure game config has all required properties
    Vue.setDefault(@config, 'invocationUtterance', {})
    Vue.setDefault(@config, 'introductoryMessage', {})
    Vue.setDefault(@config, 'formFields', [])
    Vue.setDefault(@config, 'order', 'order')
    Vue.setDefault(@config, 'apiUrl', {})
    Vue.setDefault(@config, 'contextUpdates', [])
    Vue.setDefault(@config, 'emptyResultPresentation', {})
    Vue.setDefault(@config, 'resultPresentation', {})
    # amend old format
    if Object.values(@config.introductoryMessage)[0] instanceof Array # i.e. multilang variation string
      introductionNlg = {}
      for language, texts of @config.introductoryMessage
        introductionNlg[language] = {web: []}
        for text in texts
          introductionNlg[language].web.push(
            messages: [
              type: 'Bubble'
              template: 'text'
              attachments: [{type: 'Text', text: text, format: 'PLAIN', role: 'NONE'}]
            ]
          )
       @config.introductoryMessage = introductionNlg
  mounted: ->
    $('#introduction').on 'show.bs.collapse', @collapseAll
  methods:
    addFormField: ->
      @config.formFields.push({})
      @openLastFormField()
    cloneFormField: (field) ->
      clonedField = JSON.parse(JSON.stringify(field))
      Vue.set(clonedField, 'label', '')
      @config.formFields.push(clonedField)
      @openLastFormField()
    removeFormField: (index) ->
      @config.formFields.splice(index, 1)
    openLastFormField: ->
      Vue.nextTick =>
        $(@$el).find('#form-field-accordion .collapse:last').collapse('show')
    prepareApiCall: ->
      @$refs.apiCallPreparationModal.show()
    makeApiCall: ->
      url = Routes.call_api_project_bot_game_path(Globals.projectId, Globals.stageLevel, @state.botId, @state.gameId)
      params =
        api: @config.apiUrl
        slots: @apiCallData
      $.get url, params, (data) =>
        Vue.set(@config, 'apiResultData', data)
        @$refs.apiCallPreparationModal.close()
        @$refs.apiCallResultModal.show()
      .fail (request) =>
        if request.responseJSON?.message?
          @errorMessage = request.responseJSON.message
        else
          @errorMessage = 'The API call didn’t succeed. Please check your API settings and your example data!'
        @$refs.apiCallPreparationModal.close()
        @$refs.apiCallErrorModal.show()
      null
    showApiCallResults: ->
      @$refs.apiCallResultModal.show()
    collapseAll: ->
      $(@$el).find('#introduction, #form-field-accordion .collapse').collapse('hide')
  components:
    draggable: draggable
  template: '
    <div>
      <div class="panel panel-default wizard-like-panel">
        <div class="panel-heading">
          <a class="flex-container center-items" href="#introduction" data-toggle="collapse">
            <h3 class="expand">Introduction</h3>
            <div class="chevron"></div>
          </a>
        </div>
        <div id="introduction" class="collapse">
          <div class="panel-body">
            <div class="responsive-grid-container">
              <div class="responsive-grid-item item-400" v-if="config.invocationUtterance">
                <h5 class="no-margin-top">How do users start this conversation?</h5>
                <multilang-variation-field :dictionary="config.invocationUtterance"/>
              </div>
              <div class="responsive-grid-item item-400" v-if="config.introductoryMessage">
                <h5 class="no-margin-top">How does the bot reply to start the form filling dialogue?</h5>
                <nlg-editor :nlg="config.introductoryMessage"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading">
          <h3>Form fields</h3>
        </div>
        <div class="panel-body">
          <div class="form-group">
            <span class="text-normal">Ask for the form fields&ensp;</span>
            <label class="non-bold inline-radio">
              <input type="radio" v-model="config.order" value="order">
              in the order defined here
            </label>
            <label class="non-bold inline-radio">
              <input type="radio" v-model="config.order" value="random">
              in random order
            </label>
          </div>
          <div class="panel-group" id="form-field-accordion">
            <draggable :list="config.formFields" :options="{handle: \'.handle\'}" item-key="$key">
              <template #item="{ element, index }">
                <form-filling-field
                  :field="element"
                  :index="index"
                  :fields="config.formFields"
                  @remove-form-field="removeFormField"
                  @clone-form-field="cloneFormField"
                  @show="collapseAll"
                  />
              </template>
            </draggable>
          </div>
          <div class="form-group flex-container">
            <div class="btn btn-primary" v-on:click="addFormField()">Add form field</div>
          </div>
        </div>
      </div>

      <div class="panel panel-default wizard-like-panel">
        <div class="panel-heading">
          <h3>Form submission</h3>
        </div>
        <div class="panel-body">
          <div class="responsive-grid-container">

            <div class="responsive-grid-item item-400">
              <h4>Data destination</h4>
              <div class="form-group">
                Action to be taken when all form fields are collected.
                This can be calling an API, sending an e-mail or nothing.
                You may use <code>${formFieldLabel}</code> as placeholder for form field values in API URL, API data or e-mail body.
                <info-button info-key="form_filling_data_destination"/>
              </div>
              <api-editor :api="config.apiUrl" class="form-group"/>
              <div v-if="config.apiUrl.type == \'HTTP\'" class="form-group">
                <div @click="prepareApiCall()" class="btn btn-primary">Get API result labels</div>
                <div v-if="config.apiResultData" @click="showApiCallResults()" class="btn btn-default">
                  Show API results
                </div>
              </div>
              <modal ref="apiCallPreparationModal" title="Provide example data" v-slot="slotProps">
                <div class="form-group">
                  Please provide example data to make a call to the API.
                  We will send the data to the API as if it was collected by the agent in a conversation with a user.
                </div>
                <div class="form-horizontal">
                  <div v-for="field in config.formFields" class="form-group">
                    <label class="col-sm-2 control-label non-bold">{{ field.label }}</label>
                    <div class="col-sm-10">
                      <input v-model="apiCallData[field.label]" class="form-control">
                    </div>
                  </div>
                  <div class="button-container">
                    <div @click="slotProps.close()" class="btn btn-default">Cancel</div>
                    <button @click="makeApiCall()" class="btn btn-primary">Make API call</button>
                  </div>
                </div>
              </modal>
              <modal ref="apiCallResultModal" title="Response labels" v-slot="slotProps">
                <div class="form-group">
                  Here is the structure of your API call’s response.
                  The labels on the left hand side can be used to build the bot’s result presentation.
                </div>
                <div class="form-group">
                  <h4>API response</h4>
                  <div class="flex-container">
                    <div>
                      <div class="api-result-cell"><h5>Label</h5></div>
                      <div v-for="path in config.apiResultData" class="api-result-cell" :style="{paddingLeft: (path.level * 20) + \'px\'}">
                        {{ path.label }}
                      </div>
                    </div>
                    <div style="overflow: hidden">
                      <div class="api-result-cell"><h5>Response value (example value in arrays)</h5></div>
                      <div v-for="path in config.apiResultData" class="api-result-cell" :style="{paddingLeft: (path.level * 20) + \'px\'}">
                        <div class="form-control ellipsis">{{ path.value }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="button-container">
                    <div @click="slotProps.close()" class="btn btn-default">Close</div>
                  </div>
                </div>
              </modal>
              <modal ref="apiCallErrorModal" title="Step 1 of 2 – provide example data" v-slot="slotProps">
                <div class="alert alert-danger">{{ errorMessage }}</div>
                <div class="button-container">
                  <div @click="slotProps.close()" class="btn btn-default">Close</div>
                </div>
              </modal>

              <div class="spacer"></div>
              <h4>Context update</h4>
              <label class="non-bold">These updates are triggered after a successful API call.</label>
              <context-update-list :updates="config.contextUpdates" :context-parameters="availableContextParameters"/>
            </div>

            <div class="responsive-grid-item item-400">
              <h4>Bot messages</h4>
              <div class="panel-group" id="messages-accordion">
                <result-nlg :nlg="config.resultPresentation" title="API success" info-key="form_filling_result_presentation">
                  <div v-if="config.apiResultData">
                    <p>Available labels:</p>
                    <result-labels :attributes="config.apiResultData"/>
                  </div>
                </result-nlg>
                <empty-result-nlg :nlg="config.emptyResultPresentation" title="API error"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  '
