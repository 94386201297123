import BotbuilderApi from 'models/botbuilder_api.coffee'
import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'

export default class EntitySchema
  constructor: (data) ->
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key || ''
    @label = data.label || ''
    @description = data.description || ''
    @enableKeyForSearch = if data.enableKeyForSearch? then data.enableKeyForSearch else false
    @enableLabelForSearch = if data.enableLabelForSearch? then data.enableLabelForSearch else false
    @attributes = (data.attributes || []).map (attributeData) => new EntitySchemaAttribute(@, attributeData)
    this

  create: ->
    BotbuilderApi.createEntitySchema(@)
      .then (data) => @update(data)

  save: ->
    BotbuilderApi.updateEntitySchema(@)
      .then (data) => @update(data)

  delete: ->
    BotbuilderApi.deleteEntitySchema(@)

  deleteContent: (language) ->
    BotbuilderApi.deleteEntitySchemaContent(@, language)

  Object.defineProperties @prototype,
    export:
      get: ->
        key: @key
        label: @label
        description: @description
        enableKeyForSearch: @enableKeyForSearch
        enableLabelForSearch: @enableLabelForSearch
        attributes: @attributes.map (attribute) -> attribute.export
    templateFileString:
      get: ->
        attributesString = @attributes
          .map (attribute) -> attribute.templateString
          .join(",\n      ")
        fileContent = """
          [
            {
              "schemaKey": "#{@key}",
              "key": "",
              "label": "",
              "description": "",
              "attributes": {
                #{attributesString}
              },
              "synonyms": [
                {"text": ""},
                {"pattern": ""}
              ]
            }
          ]
        """
        'data:application/json;charset=utf-8,' + encodeURIComponent(fileContent)
