export default class ConfigHint
  @SEVERITY_LEVELS = [
    'fatal'
    'error'
    'warn'
    'info'
    'suggestion'
  ]

  @RESOURCE_TYPES = [
    'Config'
    'DialogModule'
    'Hook'
    'TriggerIntent'
    'UserIntent'
    'BotIntent'
    'DialogAction'
    'ContextParameter'
    'Workflow'
    'Integration'
  ]

  constructor: (data = {}) ->
    @coordinate = data.coordinate
    @validationRule = data.validationRule
    @category = data.category
    @timeCreated = data.timeCreated
    @severity = data.severity.toLowerCase()
    @genericMessage = data.genericMessage
    @specificMessage = data.specificMessage
    @disambiguationId = data.disambiguationId
    @documentation = data.documentation
    @description = data.description
