
import PaginationLink from 'components/generic/pagination_link.vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

export default
  props:
    collection: Array
    perPage:
      type: Number
      default: 50

  data: ->
    currentPage: 0

  computed:
    offset: ->
      @currentPage * @perPage
    pageCount: ->
      Math.ceil(@collection.length / @perPage)
    pageNumbers: ->
      [0..(@pageCount - 1)]
    paginatedCollection: ->
      @collection.slice(@offset, @offset + @perPage)

  methods:
    goToPrevious: ->
      @currentPage = Math.max(@currentPage - 1, 0)
    goToNext: ->
      @currentPage = Math.min(@currentPage + 1, @pageCount - 1)
    goToPage: (page) ->
      @currentPage = page
    showPageLink: (page) ->
      return true if page <= 1
      return true if page >= @pageCount - 2
      return true if page >= @currentPage - 1 && page <= @currentPage + 1
      false
    showEllipsis: (page) ->
      return false if @showPageLink(page)
      return true if @showPageLink(page - 1)
      false

  components:
    ChevronLeftIcon: ChevronLeftIcon
    ChevronRightIcon: ChevronRightIcon
    PaginationLink: PaginationLink
