
  import ValueType from 'models/value_type.coffee'
  import { KeyListbox } from 'components/generic'

  export default
    props:
      modelValue: Object
      schemas: Array

    data: ->
      attribute: @modelValue
      valueTypeOrSchemaKey: @modelValue.valueTypeOrSchemaKey
      valueTypes: Object.entries(ValueType.TYPES)
        .concat(@schemas.map (schema) -> ['!--' + schema.key, schema.label])
        .filter ([key, label]) => key != 'entity'
        .map ([key, label]) -> {key: key, label: label}
      collectionTypes: Object.entries(ValueType.COLLECTION_TYPES).map ([key, label]) -> {key: key, label: label}

    methods:
      update: ->
        @$emit('update:modelValue', @attribute)
      updateValueTypeOrSchemaKey: ->
        if @valueTypeOrSchemaKey.startsWith('!--')
          @attribute.type = 'entityReference'
          @attribute.valueTypeOrSchemaKey = @valueTypeOrSchemaKey[3..]
        else
          @attribute.type = 'valueTyped'
          @attribute.valueTypeOrSchemaKey = @valueTypeOrSchemaKey
        @update()

    components:
      KeyListbox: KeyListbox
