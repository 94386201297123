Vue.component 'preferences-game',
  props: ['config']
  data: ->
    state: state
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'preferences.settingUserUtterances',
            'preferences.deletingUserUtterances',
            'preferences.botStatement',
          ]
        )
      deep: true
  created: ->
    Vue.setDefault(@config, 'preferences', [])
  methods:
    add: ->
      @config.preferences.push($key: GuidGenerator.newGuid(), editing: true)
    remove: (index) ->
      @config.preferences.splice(index, 1)
  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Behavior settings</h3>
      </div>
      <div class="panel-body">
        <div class="form-group">
          Preferences play out as either filters or ranking factors in search games.
          So preferences always relate to the attribute of a specific content object.
          You can leave the value field empty if a preference is not tied to a specific value
          but should be applicable to any value.
        </div>
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Preference name</th>
              <th>Content object type</th>
              <th>Type</th>
              <th>Attribute</th>
              <th>Operator</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <preference-row
              v-for="preference, index in config.preferences"
              :preference="preference"
              :index="index"
              :key="preference.$key"
              @remove-preference="remove"
              />
          </tbody>
        </table>
        <div class="form-group flex-container">
          <div class="btn btn-primary" @click="add">add preference</div>
        </div>
      </div>

      <div class="responsive-grid-container">

        <div class="responsive-grid-item item-300">
          <div class="panel-heading">
            <h3>User messages</h3>
          </div>
          <div class="panel-body">
            <div v-for="preference in config.preferences" :key="preference.$key">
              <div class="form-group">
                <label class="block">User utterance to <span class="text-primary">set</span> {{ preference.name }}</label>
                <multilang-variation-field v-if="preference.settingUserUtterances" :dictionary="preference.settingUserUtterances"/>
              </div>
              <div class="form-group">
                <label class="block">User utterance to <span class="text-danger">delete</span> {{ preference.name }}</label>
                <multilang-variation-field v-if="preference.deletingUserUtterances" :dictionary="preference.deletingUserUtterances"/>
              </div>
            </div>
          </div>
        </div>

        <div class="responsive-grid-item item-300">
          <div class="panel-heading">
            <h3>Bot messages</h3>
          </div>
          <div class="panel-body">
            <div v-for="preference in config.preferences" :key="preference.$key" class="form-group">
              <label class="block">Bot preference statement for {{ preference.name }}</label>
              <multilang-field v-if="preference.botStatement" :dictionary="preference.botStatement" placeholder="You are a big fan of ${values}." class="form-control"/>
              <span class="text-muted text-small">
                This message is how the bot will express the preference.
                If the value of the underlying attribute is not fixed, you can use <code>${values}</code> as placeholder.
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  '
