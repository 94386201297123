import speechbubbleImage from 'images/logo_speechbubble_bluegreen.png'

export default class DesktopNotifier
  @needToReqestPermission: ->
    return false unless Notification?
    !['granted', 'denied'].includes(Notification.permission)

  @requestPermission: ->
    return unless Notification?

    if Notification.permission != 'granted'
      Notification.requestPermission()

  @notify: (message) ->
    return unless Notification?

    showNotification = ->
      new Notification('Mercury.ai Webapp',
        icon: speechbubbleImage
        body: message
        requireInteraction: true
      )

    if Notification.permission != 'granted'
      Notification.requestPermission().then(showNotification)
    else
      showNotification()
