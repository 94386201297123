// originally taken from https://github.com/jeffreyguenther/vue-turbolinks
// adjusted for Vue 3, and only for permanent elements

function handleVueElementReplacementOn(turbolinksEvent, vue) {
  document.addEventListener(turbolinksEvent, function replaceElement() {
    let element = document.getElementById(vue.turbolinksPermanentSelector)
    if (element) {
      element.setAttribute('data-v-app', '');
      if (element.replaceChildren) {
        element.replaceChildren(vue.$el);
      } else { // for older versions of Edge (<= 85) and Vivaldi (<= ?)
        element.innerHTML = '';
        element.appendChild(vue.$el);
      }
    }
  });
}

Vue.mixin({
  beforeMount: function() {
    // If this is the root component, we want to cache the original element contents to replace later
    // We don't care about sub-components, just the root
    if (this == this.$root) {
      if (this.turbolinksPermanentSelector) {
        handleVueElementReplacementOn('turbolinks:load', this);
      } else {
        const vue = this;
        document.addEventListener('turbolinks:visit', function teardown() {
          vue.$.appContext.app.unmount();
          document.removeEventListener('turbolinks:visit', teardown);
        });
      }
    }
  }
})
