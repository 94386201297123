
  import AttributeMapping from 'models/attribute_mapping.coffee'
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import ConditionType from 'models/condition_type.coffee'
  import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'

  import AttributeMappingEditor from './attribute_mapping_editor.vue'
  import Wrapper from './wrapper.vue'
  import { BackLink, Button, Checkbox, H3, Input, Label, Panel, Switch, Table, TableHeadCell, TableHeadRow, TableRow, TableWrapper, Textarea } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      schema: null
      attribute: null
      activeComponents: {}
      mappings: []
      debounceTimeout: null
      mappingEditorRefs: []
      reloadContextParametersAfterUpdate: false
      conditionTypes: ConditionType.TYPES.concat(['ignore'])
      PlusIcon: PlusIcon

    computed:
      parameterKey: ->
        "contentSearch.#{@schema.key}.attributes.#{@attribute.key}"

    watch:
      '$root.entitySchemas': -> @setSchemaAndAttribute()
      '$route.params.schemaKey': -> @setSchemaAndAttribute()
      '$route.params.attributeKey': -> @setSchemaAndAttribute()
      attribute: ->
        return unless @attribute?
        @activeComponents = Object.fromEntries(
          @conditionTypes.map (op) => [op, @attribute.activeComponents.includes(op)]
        )

    created: ->
      @setSchemaAndAttribute()

    beforeUpdate: ->
      @mappingEditorRefs = []

    methods:
      setSchemaAndAttribute: ->
        @schema = @$root.entitySchemas.find (schema) => schema.key == @$route.params.schemaKey
        @setAttribute()
        return unless @attribute?
        window.breadcrumbs.strip()
          .append(text: 'Content schema', callback: => @$router.push(name: 'entitySchemaList'))
          .append(text: @schema.label, callback: => @$router.push(name: 'entitySchema'))
          .append(text: @attribute.label)
        @mappings = await BotbuilderApi.getAttributeMappings(@parameterKey)
      setAttribute: ->
        return unless @schema?
        @attribute = @schema.attributes.find (attr) => attr.key == @$route.params.attributeKey
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @schema.save().then =>
            @setAttribute()
            return if !@reloadContextParametersAfterUpdate
            @$root.loadContextParameters()
            @reloadContextParametersAfterUpdate = false
        , 1000
        )
      updateSearchFilter: ->
        @reloadContextParametersAfterUpdate = true
        @update()
      updateActiveComponents: ->
        @attribute.activeComponents = Object.keys(@activeComponents).filter (op) => @activeComponents[op]
        @reloadContextParametersAfterUpdate = true
        @update()
      addMapping: ->
        @mappings.push(new AttributeMapping(
          parameterKey: @parameterKey
          synonyms: {"#{@$root.currentLanguage}": []}
        ))
        @$nextTick => @mappingEditorRefs.last.open()
      removeMapping: (index) ->
        @mappings.splice(index, 1)
      setMappingEditorRef: (index, el) ->
        @mappingEditorRefs[index] = el if el?

    components:
      AttributeMappingEditor: AttributeMappingEditor
      BackLink: BackLink
      Button: Button
      Checkbox: Checkbox
      H3: H3
      Input: Input
      Label: Label
      Panel: Panel
      Switch: Switch
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      Textarea: Textarea
      Wrapper: Wrapper
