Vue.component 'hero-button-editor',
  props: ['message', 'messageState']
  data: ->
    editing: false
  computed:
    showError: ->
      !@messageState.pristine && @button? && !@button.$valid
    bubble: ->
      lastBubble = @message.bubbles[-1..][0]
      return null if lastBubble.attachments.length != 1 || lastBubble.attachments[0].type != 'Button'
      lastBubble
    button: ->
      return null unless @bubble?
      @bubble.attachments[0]
  watch:
    bubble:
      handler: -> @validate()
      deep: true
  created: ->
    @validate()
  methods:
    validate: ->
      return unless @bubble?
      Vue.set(@bubble, '$valid', @button.$valid)
    add: ->
      @message.bubbles.push(
        type: 'Bubble'
        attachments: [
          type: 'Button'
        ]
      )
      Vue.nextTick =>
        $(@$el).find('.message-input').focus()
    remove: ->
      @message.bubbles.pop()
  template: '
    <div class="hero-button" :class="{\'with-error\': showError}">
      <attachment-editor
        v-if="button"
        :attachment="button"
        :message-state="messageState"
        @remove-attachment="remove"
        />
      <div v-else @click="add" class="btn btn-add-button">Add hero button</div>
    </div>
  '
