import { createApp as originalCreateApp, nextTick } from 'vue'
import { trackError } from 'helpers'

const Vue = {
  component: function(name, config) {
    window.vueComponents ||= [];
    window.vueComponents.push([name, config]);
  },

  mixin: function(config) {
    window.vueMixins ||= [];
    window.vueMixins.push(config);
  },

  nextTick: nextTick,

  set: function(object, index, value) {
    object[index] = value;
  },

  setDefault: function(object, index, value) {
    if (object[index] == null) object[index] = value;
  },

  delete: function(object, index) {
    delete object[index];
  },
}

const createApp = function(config) {
  const app = originalCreateApp(config);
  window.vueComponents.forEach(nameAndConfig => {
    app.component(...nameAndConfig);
  });
  window.vueMixins.forEach(config => {
    app.mixin(config);
  });
  app.config.errorHandler = trackError
  return app;
}

export default Vue

export { createApp }
