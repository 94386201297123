
  import { chargebeeSite, countries } from 'rails_data'
  import PlatformApi from 'models/platform_api.coffee'
  import Subscription from 'models/subscription'
  import BillingHistory from './billing_history.vue'
  import { Alert, Badge, Button, H1, H3, Input, Listbox, Modal, Panel } from 'components/generic'
  import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      organization: {}
      country: {}
      editing: false
      sending: false
      errors: {}
      invoicePaymentRequested: false
      countries: countries

    computed:
      subscription: ->
        @$root.organization.subscription || new Subscription({})
      trialAlert: ->
        @subscription.status == 'in_trial' && !@subscription.hasPaymentMethod
      trialRemainingDays: ->
        return unless @trialAlert
        moment(@subscription.trial_end).diff(moment(), 'days')
      paymentMethodLabel: ->
        (@subscription.payment_method || '').replaceAll('_', ' ').capitalize()
      creditCardButtonLabel: ->
        if @$root.organization.card
          'Edit credit card details'
        else
          'Add credit card details'

    watch:
      country: -> @organization.billing_address.country = @country.code

    created: ->
      @cloneOrganization()
      @loadBillingData()

    mounted: ->
      @$refs.scrollingContainer.style.height = document.documentElement.clientHeight -
        @$refs.scrollingContainer.getBoundingClientRect().top - 24 + 'px'

    methods:
      loadBillingData: ->
        PlatformApi.Webapp.getBillingData(@$root.organization.id)
          .then (data) =>
            Object.assign(@$root.organization, data)
            @$root.organization.subscription = new Subscription(data.subscription)
            @cloneOrganization()
      cloneOrganization: ->
        @organization = ObjectProcessor.clone(@$root.organization)
        @country = @countries.find((country) => @organization.billing_address.country == country.code) || {}
      cancel: ->
        @cloneOrganization()
        @editing = false
        @errors = {}
      save: ->
        @sending = true
        PlatformApi.Webapp.saveOrganization(@organization, save_address: 'billing')
          .then (data) =>
            Object.assign(@$root.organization, data)
            @cloneOrganization()
            @editing = false
            @errors = {}
          .catch (response) =>
            Object.keys(response.errors).forEach (attribute) =>
              @errors[attribute] = response.errors[attribute].join(', ')
          .finally =>
            @sending = false
      openCreditCardModal: ->
        chargebeeInstance = Chargebee.init(site: chargebeeSite)
        chargebeeInstance.openCheckout(
          hostedPage: => PlatformApi.Webapp.paymentHostedPage(@$root.organization.id)
          close: => @loadBillingData()
        )
      requestInvoicePayment: ->
        PlatformApi.Webapp.requestInvoicePayment(@$root.organization.id)
          .then =>
            @invoicePaymentRequested = true
            @$refs.invoicePaymentRequestedModal.open()

    components:
      Alert: Alert
      Badge: Badge
      BillingHistory: BillingHistory
      Button: Button
      CheckIcon: CheckIcon
      ChevronDownIcon: ChevronDownIcon
      H1: H1
      H3: H3
      Input: Input
      Listbox: Listbox
      Modal: Modal
      Panel: Panel
