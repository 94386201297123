
  import Message from 'models/message'

  import ButtonEditor from './button_editor.vue'
  import ButtonMover from './button_mover.vue'
  import MediaAttachmentEditor from './media_attachment_editor.vue'
  import TextAttachmentEditor from './text_attachment_editor.vue'
  import { Button } from 'components/generic'

  export default
    props:
      modelValue: Message

    emits: ['update:modelValue']

    data: ->
      message: @modelValue
      titleEditorRefs: []

    watch:
      modelValue: ->
        @message = @modelValue

    beforeUpdate: ->
      @titleEditorRefs = []

    methods:
      addButton: (bubble) ->
        bubble.addButton()
      removeButton: (bubbleIndex, buttonIndex) ->
        @message.bubbles[bubbleIndex].attachments.splice(buttonIndex + 3, 1)
        @update()
      addBubble: (index) ->
        @message.addSlide(index)
        @update()
        @$nextTick =>
          @titleEditorRefs[index + 1].focus()
      removeBubble: (index) ->
        @message.bubbles.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @message)
      focus: ->
        @titleEditorRefs[0].focus()
      setTitleEditorRef: (index, el) ->
        @titleEditorRefs[index] = el if el?

    components:
      Button: Button
      ButtonEditor: ButtonEditor
      ButtonMover: ButtonMover
      MediaAttachmentEditor: MediaAttachmentEditor
      TextAttachmentEditor: TextAttachmentEditor
