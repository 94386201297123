Vue.component 'info-game',
  props: ['config', 'contentObjectType']
  data: ->
    gameValidator: GameValidator
    attributeToBeAdded: undefined
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'attributes.contentQuestions',
            # messages:
            'attributes.nlg',
            'contentObjectPresentation',
          ]
        )
      deep: true
  created: ->
    # make sure game config has all required properties
    Vue.setDefault(@config, 'attributes', [])
    Vue.setDefault(@config, 'contentObjectPresentation', {})
  methods:
    addAttribute: ->
      @config.attributes.push(
        name: @attributeToBeAdded.attribute.label
      )
      Vue.nextTick =>
        $(@$el).find('#attributes-accordion .collapse:last').collapse(parent: '#attributes-accordion', show: true)
          .find('input').first().focus()
    removeAttribute: (index) ->
      $(@$el).find('#attributes-accordion .collapse').collapse('hide')
      @config.attributes.splice(index, 1)
  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Attributes to provide information about</h3>
      </div>
      <div class="panel-body">
        <div class="panel-group" id="attributes-accordion">
          <info-game-attribute
            v-for="(attribute, index) in config.attributes"
            :key="attribute.$key"
            :attribute="attribute"
            :index="index"
            @remove-attribute="removeAttribute"
            />
        </div>
        <div class="form-group flex-container" v-if="contentObjectType">
          <select class="styled-form-field" v-model="attributeToBeAdded">
            <option :value="undefined" selected="true" disabled="true">Select attribute</option>
            <option :value="attr" v-for="attr in contentObjectType.attributes">{{ attr.attribute.label }}</option>
          </select>
          &nbsp;
          <div class="btn btn-primary" v-on:click="addAttribute()">add</div>
        </div>
      </div>

      <div class="panel-heading">
        <h3>Bot messages</h3>
      </div>
      <div class="panel-body">
        <p>
          When building the answer messages you may use the attribute labels
          to insert result item data into your message.
          For inserting a label’s data, just paste the corresponding markup
          (the label enclosed in square brackets as shown below) into a text field.
        </p>
        <div class="form-group">
          <result-labels :attributes="[\'name\', \'id\'].concat(Object.keys(contentObjectType.signature))"/>
        </div>
        <div class="panel-group" id="messages-accordion">
          <panel-togglable parent-selector="#messages-accordion">
            <template v-slot:togglingheader>
              <div class="expand">Present content object</div>
              <div class="check">
                <span
                  v-if="!config.contentObjectPresentation.$valid"
                  class="fa fa-exclamation-circle text-danger"
                  >
                </span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="config.contentObjectPresentation"/>
            </template>
          </panel-togglable>
          <template v-for="(attribute, index) in config.attributes">
            <panel-togglable v-if="attribute.nlg" parent-selector="#messages-accordion">
              <template v-slot:togglingheader>
                <div class="expand">
                  Answer for attribute <i>{{ attribute.name }}</i>
                </div>
                <div class="check">
                  <span class="fa fa-exclamation-circle text-danger" v-if="!attribute.nlg.$valid"></span>
                </div>
              </template>
              <template v-slot:body>
                <nlg-editor :nlg="attribute.nlg"/>
              </template>
            </panel-togglable>
          </template>
        </div>
      </div>
    </div>
  '
