$.fn.copyButton = ->
  this
    .tooltip(title: 'Copy to clipboard')
    .on 'click', (e) ->
      $container = $(e.target).parent().find('input, textarea, pre')
      if $container.is('pre')
        $textarea = $("<textarea readonly=\"\" style=\"position: fixed; left: -9999px\">#{$container.text()}</textarea>")
        $textarea.appendTo('body').select()
      else
        $container.select()
      try
        if document.execCommand('copy')
          message = 'Copied!'
        else
          message = 'Sorry, your browser doesn’t support this button.'
      catch
        message = 'Sorry, your browser doesn’t support this button.'
      $(e.target)
        .attr('title', message)
        .tooltip('fixTitle')
        .tooltip('show')
        .attr('title', 'Copy to clipboard')
        .tooltip('fixTitle')
      $textarea.remove() if $textarea?
  this

$(window).on 'DOMContentLoaded turbolinks:load', ->
  $('.btn-copy').copyButton()
