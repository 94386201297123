
  import { Badge, H4 } from 'components/generic'

  export default
    props:
      hints: Array

    data: ->
      colorPerSeverity:
        fatal: 'red'
        error: 'red'
        warn: 'yellow'
        info: 'blue'
        suggestion: 'green'

    components:
      Badge: Badge
      H4: H4
