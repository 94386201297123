import Condition from 'models/condition.coffee'
import OperationalHook from 'models/operational_hook.coffee'
import SlotDefinition from 'models/slot_definition.coffee'
import UtteranceCollection from 'models/utterance_collection.coffee'
import Verbalization from 'models/verbalization.coffee'

export default class ContextInitiative
  constructor: (@contextInterface, data = {}) ->
    data ||= {}
    @componentKey = data.componentKey
    Object.keys(Verbalization.TYPES).forEach (type) =>
      @[type.decapitalize()] = new Verbalization(@contextInterface, data[type.decapitalize()] || {type: type})
    @relevantIf = if data.relevantIf? then new Condition(data.relevantIf) else new Condition(type: 'AlwaysTrue')
    @mandatory = if data.mandatory? then new Condition(data.mandatory) else new Condition(type: 'AlwaysTrue')
    @useFulltextAsInput = if data.useFulltextAsInput? then data.useFulltextAsInput else false
    @negativeConfirmationHookKey = data.negativeConfirmationHookKey || 'NotAcknowledge'
    @positiveConfirmationHookKey = data.positiveConfirmationHookKey || 'Acknowledge'
    @interpretationMode = data.interpretationMode || 'local' # local | competing,
    @utterances = new UtteranceCollection(data.utterances, componentAsLabel: true)
    @operationalHooks = (data.operationalHooks || []).map (opHookData) => new OperationalHook(@, opHookData)

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    Object.keys(Verbalization.TYPES).forEach (type) =>
      @[type.decapitalize()].partialUpdate(data[type.decapitalize()])

  Object.defineProperties @prototype,
    parameterKey:
      get: ->
        @contextInterface.parameterKey
    components:
      get: ->
        @contextInterface?.contextParameter?.components
    slotDefinitions:
      get: ->
        return unless @components
        @contextInterface.contextParameter.components.map (component) =>
          new SlotDefinition(
            slotRole: @contextInterface.contextParameter.key + '#' + component.key
            valueType: component.valueType
            label: component.key
          )
    export:
      get: ->
        obj =
          relevantIf: @relevantIf.export
          mandatory: @mandatory.export
          useFulltextAsInput: @useFulltextAsInput
          negativeConfirmationHookKey: @negativeConfirmationHookKey
          positiveConfirmationHookKey: @positiveConfirmationHookKey
          interpretationMode: @interpretationMode
          utterances: @utterances.export
          operationalHooks: @operationalHooks.map (opHook) -> opHook.export
        Object.keys(Verbalization.TYPES).forEach (type) =>
          obj[type.decapitalize()] = @[type.decapitalize()].export
        obj
