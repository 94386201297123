import { confirm } from 'helpers'

Vue.component 'additional-teaser-message-list',
  props: ['itemList', 'contextParameters']

  data: ->
    state: state

  methods:
    removeItem: (index) ->
      return unless await confirm('Do you really want do delete this teaser?')
      @itemList.splice(index, 1)
    addItem: ->
      @itemList.push(@defaultItem())
    defaultItem: ->
      message: ''
      button:
        payload:
          $valid: false
          game: ''
          intent: ''
          slots: []
        text: ''
      urlPattern: ''
      contextConditions: []
      $key: GuidGenerator.newGuid()

  template: '
    <div>
      <additional-teaser-message
        v-for="(item, index) in itemList"
        :item="item"
        :index="index"
        :item-list="itemList"
        :key="item.$key"
        :conditions="itemList[index].contextConditions"
        :contextParameters="contextParameters"
        @remove-item="removeItem"
      />
      <div class="btn btn-default" v-if="!itemList.length && !state.disabled" @click="addItem">
        Add additional teaser message
      </div>
    </div>'
