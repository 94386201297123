
  import WebChannel from 'models/web_channel.coffee'
  import { alert } from 'helpers'
  import { Button, Pre } from 'components/generic'
  import { ClipboardIcon } from '@heroicons/vue/outline'


  export default
    props:
      channel: WebChannel

    methods:
      copySnippet: (e) ->
        navigator.clipboard.writeText(@channel.snippet)
          .then =>
            e.target.blur()
            await alert('Copied snippet to clipboard')

    components:
      Button: Button
      ClipboardIcon: ClipboardIcon
      Pre: Pre
