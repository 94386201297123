import ContextParameter from 'models/context_parameter.coffee'

export default class ParameterComponent
  constructor: (@parameter, data = {}) ->
    @key = data.key
    @_label = data.label
    @_fullLabel = data.fullLabel
    @_collectionType = data.collectionType
    @_valueType = data.valueType
    @_entitySchemaKey = data.entitySchemaKey

  clone: ->
    new ParameterComponent(@parameter, @export)

  withKey: (key) ->
    @key = key
    this

  Object.defineProperties @prototype,
    fullKey:
      get: ->
        @parameter.key + '#' + @key
    label:
      get: ->
        @_label || @key
    fullLabel:
      get: ->
        if @_fullLabel
          @_fullLabel
        else
          @parameter.label + ' ' + @label
    collectionType:
      get: ->
        return @_collectionType if @_collectionType?
        @parameter.collectionType
    valueType:
      get: ->
        return @_valueType if @_valueType?
        @parameter.valueType
    entitySchemaKey:
      get: ->
        return @_entitySchemaKey if @_entitySchemaKey?
        @parameter.entitySchemaKey
    valueTypeLabel:
      get: ->
        "#{ContextParameter.VALUE_TYPES[@valueType]} #{if @collectionType != 'none' then @collectionType else ''}"
    reference:
      get: -> @key
    export:
      get: ->
        key: @key
        label: @_label
        fullLabel: @_fullLabel
        collectionType: @_collectionType
        valueType: @_valueType
        entitySchemaKey: @_entitySchemaKey
