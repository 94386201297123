export default class InboxMessage
  @ATTRIBUTES = [
    'origin'
    'timestamp'
    'handoverAgentId'
    'message'
    'annotatable'
    'annotated'
    'interpretation'
  ]

  constructor: (data) ->
    @id = data.id
    @setAttributes(data)

  update: (data) ->
    @setAttributes(data)

  setAttributes: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] ||= data[attribute] if data[attribute]?

  Object.defineProperties @prototype,
    formattedDate:
      get: ->
        return t('inbox.date.today') if moment(@timestamp).isSame(moment(), 'day')
        return t('inbox.date.yesterday') if moment(@timestamp).isSame(moment().subtract(1, 'day'), 'day')
        l(@timestamp, 'date')
    time:
      get: ->
        l(@timestamp, 'time')
    bubbles:
      get: ->
        if @message.type == 'Bubble'
          [@message]
        else
          @message.bubbles
