
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import { Button, Input, Textarea } from 'components/generic'

  export default
    props:
      conversation: Conversation

    data: ->
      name: ''
      content: ''
      sending: false

    methods:
      createTicket: ->
        @sending = true
        fetch(
          "https://customers.mercury.ai/ravensburger/sapintegration/users/#{@conversation.id}/tickets/create"
          method: 'POST',
          body: JSON.stringify(name: @name, content: @content),
          headers:
            'X-Requested-With': 'XMLHttpRequest'
            'Content-Type': 'application/json'
            'Accept': 'application/json'
            'Authorization': "Bearer #{Globals.projectToken}"
        )
          .then (response) =>
            if response.ok
              @name = ''
              @content = ''
              await alert('Ticket has been created.')
            else
              response.json()
                .then (response) -> await alert(response.message)
          .catch -> await alert('Sorry, ticket couldn’t be created.')
          .finally => @sending = false

    components:
      Button: Button
      Input: Input
      Textarea: Textarea
