Vue.component 'support-area',
  props: ['config']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    entity: @config
    role: 'head'
    name: 'the support area'
    attributes:
      name:
        type: 'String'
        default: ''
      autostart:
        type: 'Boolean'
        default: false
    nameCopy: ''
    autostartCopy: false
  computed:
    showRightColumn: ->
      return false if @pristine
      return true if @editing
      false
  mounted: -> # use mounted so we can immediately focus the input field
    if !@config.name?
      Vue.set(@config, 'fresh', true)
      @edit()
    else
      @pristine = false
    @setCopy()
  methods:
    finished: ->
      return unless @complete
      @confirm()
      @editing = false
      if @config.fresh
        Vue.delete(@config, 'fresh')
        @config.topics.push({fresh: true})
  template: '
    <div>
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-0" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container center-items">
                <div class="expand">
                  <h5 v-if="editing" class="no-margin-top">Problem area</h5>
                  <label v-else class="text-light">Problem area</label>
                  <input
                    v-if="editing"
                    type="text"
                    v-model="nameCopy"
                    @keyup.enter="confirm"
                    class="form-control wizard-style"
                    placeholder="Describe your problem"
                    >
                  <div v-if="!editing" class="panel-label">{{ config.name }}</div>
                </div>
                <div v-if="!editing" @click="edit" class="btn btn-icon">
                  <span class="fa fa-pen"></span>
                </div>
              </div>
              <label v-if="editing" class="sliding-switch align text-light">
                Autostart
                <input type="checkbox" v-model="autostartCopy">
                <span class="slider"></span>
              </label>
              <support-confirm-button v-if="editing"/>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-utterances :config="config" @finished="finished" @discard="discard"/>
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>
    </div>
  '
