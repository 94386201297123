import { confirm } from 'helpers'

Vue.component 'bot-intent',
  props: ['intent', 'index', 'intentList', 'contextParameters', 'isRecoveryIntent']
  data: ->
    state: state
    render: false
    labelUnique: true
  created: ->
    # make sure intent has all required properties
    Vue.setDefault(@intent, 'label', '')
    Vue.setDefault(@intent, 'contextUpdates', []) unless @isRecoveryIntent
    Vue.setDefault(@intent, 'nlg', {})
    Vue.setDefault(@intent, '$key', GuidGenerator.newGuid())
    @validate()
    # defer rendering of panel content so page can be built up fastser
    setTimeout (=> @render = true), 0
  watch:
    intent:
      handler: -> @validate()
      deep: true
  computed:
    labelValid: ->
      @labelUnique && GameValidator.string(@intent.label)
  methods:
    validate: ->
      @labelUnique = GameValidator.unique(@intent.label, @intentList.map (intent) -> intent.label)
      Vue.set @intent, '$valid',
        @labelValid &&
        @intent.nlg.$valid &&
        (@isRecoveryIntent || GameValidator.allValid(@intent.contextUpdates))
    remove: (index) ->
      return unless await confirm("Do you really want to delete intent “#{@intent.label}”?")
      $(@$el).find('.collapse').collapse('hide')
      @$emit('remove-intent', index)
    addApiCall: ->
      Vue.set(@intent, 'apiCall', {})
    removeApiCall: ->
      return unless await confirm('Do you really want to delete the data destination?')
      Vue.delete(@intent, 'apiCall')
    scrollToAndOpen: ->
      @$el.scrollIntoView(behavior: 'smooth')
      @$refs.togglable.expand()
  template: '
    <panel-togglable ref="togglable">
      <template v-slot:header>
        <div class="width-50 expand" :class="{\'has-error\': !labelValid}">
          <inline-edit
            v-model:string="intent.label"
            placeholder="intent label"
            warning="Changing the intents’s label will break existing references to this intent. Continue?"
            focus="true"
            bold="true"
            />
        </div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!intent.$valid"></span>
        </div>
      </template>

      <template v-if="render" v-slot:body>

        <div v-if="!isRecoveryIntent" class="form-group clearfix">
          <label class="block">Send data</label>
          <plus-button v-if="!intent.apiCall" label="add data destination" :method="addApiCall"/>
          <div v-if="intent.apiCall">
            <div class="form-group">
              <api-editor :api="intent.apiCall" :hide-nothing-option="true"/>
              <div class="btn btn-caution pull-right" @click="removeApiCall">remove data destination</div>
            </div>
          </div>
        </div>

        <div v-if="!isRecoveryIntent" class="form-group">
          <label>Context update</label>
          <context-update-list :updates="intent.contextUpdates" :context-parameters="contextParameters"/>
        </div>

        <label v-if="!isRecoveryIntent">Bot messages</label>
        <nlg-editor :nlg="intent.nlg" :intent="intent" :is-result-nlg="true" class="form-group"/>
        <div class="btn btn-caution pull-right" @click="remove(index)">Remove intent</div>
      </template>

      <template v-if="!render" v-slot:body>
        <span class="fa fa-sync fa-spin"></span>&nbsp;
        loading...
      </template>
    </panel-togglable>
  '
