# $(window).on 'DOMContentLoaded', ->
foo = ->
  initWidget = ->
    $widgetElement = $('<div id="mercury-widget" data-turbolinks-permanent></div>').appendTo($('body'))
    Mercury.initChatWidget(
      $widgetElement.get(0),
      {apiKey: 'Ozd3eQiOS2yj2CZvp4c4xv9fyMb5NwUMKdRD9gAA', pollingInterval: 5},
      {
        userId: Globals.currentUser.widgetId
        firstName: Globals.currentUser.firstName
        lastName: Globals.currentUser.lastName
        context:
          'user.firstName': Globals.currentUser.firstName
          'user.lastName': Globals.currentUser.lastName
          'user.email': Globals.currentUser.email
          'user.phone': Globals.currentUser.phoneNumber
          'user.company': Globals.currentUser.organizationName
      }
    )

  if window.Mercury?
    initWidget()
  else
    $('#widget-js').get(0).onload = initWidget

  # preserving widget’s scroll position across turbolinks visits
  cacheScrollPosition = ->
    $widgetElement = $('body > #mercury-widget')
    $widgetElement.attr('scrollTop', $widgetElement.find('.message-area').scrollTop())
  restoreScrollPosition = ->
    $widgetElement = $('body > #mercury-widget')
    $widgetElement.find('.message-area').scrollTop($widgetElement.attr('scrollTop'))
  $(window).on 'turbolinks:before-visit', cacheScrollPosition
  $(window).on 'turbolinks:render', restoreScrollPosition

  # preserve widget across turbolinks visits
  $(window).on 'turbolinks:visit', ->
    $widgetElement = $('body > #mercury-widget')
    $(window).one 'turbolinks:load', ->
      $('body > #mercury-widget').remove()
      $widgetElement.appendTo($('body'))
      restoreScrollPosition()
