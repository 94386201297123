import { createApp } from 'vue_shims'
import { alert } from 'helpers'

initChannelSettingsApp = (element) ->
  window.state =
    projectId: $(element).data('project-id')
    type: $(element).data('type')
    channelKey: $(element).data('channel-key')
    channelTypes: $(element).data('channel-types')
    languages: $(element).data('languages')
    currentLanguage: Object.keys($(element).data('languages'))[0]
    intents: $(element).data('intents')
    predefinedItems: $(element).data('predefined-items')
    stages: $(element).data('stages')
    menuErrors: $(element).data('menu-errors')
    disabled: $(element).data('disabled')
    media: $(element).data('channel-types').map (medium) -> medium.toLowerCase()
    channelName: $(element).data('channel-name')
    autocreatedChannels: $(element).data('autocreated-channels')

  createApp(
    data: ->
      state: state
      whitelistedDomains: $(element).data('whitelisted-domains')
      menuStructure: $(element).data('menu-structure') || {}
      greeting: $(element).data('greeting')
      getStarted: $(element).data('get-started')
      languageStates: {}
      stageUuid: $(element).data('stage-uuid')
      stageInitiallySet: !!$(element).data('stage-uuid')
      stageLevel: Globals.stageLevel

    computed:
      hasMenu: ->
        @state.type in ['FACEBOOK', 'WEB']
      menuOutput: ->
        JSON.stringify(
          ObjectProcessor.clone(@menuStructure)
        )
      whitelistedDomainsOutput: ->
        JSON.stringify(
          @whitelistedDomains.filter (string) -> string.length
        )

    watch:
      stageUuid: ->
        if !@stageUuid
          @state.intents = []
          @state.predefinedItems = []
          return
        $.getJSON Routes.data_for_channels_project_stage_path(Globals.projectId, Globals.stageLevel, @stageUuid), (data) =>
          @state.intents = data.intents
          @state.predefinedItems = data.predefined_menu_entries
          if @menuStructure.call_to_actions?.length
            await alert('The available intents may have changed. Please check you channel menu!')

    methods:
      stages: (level) ->
        @state.stages.filter (stage) -> stage.stage_level == level

    template: '
      <div>
        <input type="hidden" name="umh_channel[stage_uuid]" v-model="stageUuid">
        <input type="hidden" name="umh_channel[persistent_menu]" v-model="menuOutput">

        <div v-if="state.type == \'FACEBOOK\'" class="panel-body non-first">
          <input type="hidden" name="umh_channel[config][FACEBOOK_WHITELISTED_DOMAINS]" v-model="whitelistedDomainsOutput">
          <div class="form-group">
            <label class="control-label col-sm-2">
              Whitelisted domains
            </label>
            <div class="col-sm-10">
              <variation-field :list="whitelistedDomains"/>
            </div>
          </div>
        </div>

        <div v-if="!state.autocreatedChannels.indexOf(state.channelName) > -1" class="panel-body">
          <div class="form-group">
            <label class="control-label col-sm-2">
              Connected stage
            </label>
            <div class="col-sm-10">
              <table class="table-simple">
                <tr>
                  <td class="align-bottom">
                    {{ stageLevel.capitalize() }}
                  </td>
                  <td v-for="stage in stages(stageLevel)" class="align-bottom">
                    <label class="non-bold no-margin inline-radio">
                      <input type="radio" :disabled="stageInitiallySet" :value="stage.uuid" v-model="stageUuid">
                      {{ stage.version }}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td class="align-bottom text-danger">None</td>
                  <td class="align-bottom">
                    <label class="non-bold no-margin inline-radio">
                      <input type="radio" disabled :checked="!stageUuid">
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="form-group">
            <div v-if="hasMenu" class="col-sm-12">
              <panel-togglable :open="state.menuErrors.length">
                <template v-slot:togglingheader>
                  <div class="expand">Persistent menu</div>
                </template>
                <template v-slot:body>
                  <div v-if="state.menuErrors.length" class="alert alert-danger">
                    <p>Please fix the following error(s):</p>
                    <ul>
                      <li v-for="message in state.menuErrors">{{ message }}</li>
                    </ul>
                  </div>
                  <persistent-menu :menu-structure="menuStructure"/>
                </template>
              </panel-togglable>
            </div>

            <div v-if="state.type == \'FACEBOOK\'" class="col-sm-12">
              <panel-togglable :open="state.menuErrors.length">
                <template v-slot:togglingheader>
                  <div class="expand">Welcome message</div>
                </template>
                <template v-slot:body>
                  <label class="non-bold">
                    Enter a welcome message (max. 160 characters) to introduce your bot to new users
                  </label>
                  <textarea
                    name="umh_channel[greeting]"
                    v-model="greeting"
                    maxlength="160"
                    class="form-control"
                    :disabled="state.disabled ? true : null"
                    />
                </template>
              </panel-togglable>
            </div>

            <div v-if="state.type == \'FACEBOOK\'" class="col-sm-12">
              <panel-togglable :open="state.menuErrors.length">
                <template v-slot:togglingheader>
                  <div class="expand">Get started intent</div>
                </template>
                <template v-slot:body>
                  <label class="non-bold">
                    Choose a start intent that will be used if opt-in is disabled
                    <small>(currently only intents without obligatory slots)</small>
                  </label>
                  <select name="umh_channel[get_started]" v-model="getStarted" class="form-control" :disabled="state.disabled ? true : null">
                    <option disabled="disabled" value=""></option>
                    <option v-for="option in state.intents" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </template>
              </panel-togglable>
            </div>
          </div>

        </div>

        <widget-config v-if="state.type == \'WEB\'"/>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('channel-settings-app')
  initChannelSettingsApp(element) if element?
