import { confirm } from 'helpers'

Vue.component 'user-intent-slot',
  props: ['intentSlot', 'index']
  data: ->
    gameValidator: GameValidator
  computed:
    availableOperators: ->
      TypeValidator.availableOperators(@intentSlot.type)
  created: ->
    Vue.setDefault(@intentSlot, 'label', '')
    Vue.setDefault(@intentSlot, 'type', {})
    Vue.setDefault(@intentSlot, '$key', GuidGenerator.newGuid())
    @validate()
  watch:
    intentSlot:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      Vue.set @intentSlot, '$valid',
        GameValidator.string(@intentSlot.label) &&
        GameValidator.type(@intentSlot.type)
    remove: (index) ->
      return unless await confirm("Do you really want to delete slot “#{@intentSlot.label}”?")
      @$emit('remove-slot', index)
  template: '
    <div>
      <div class="form-group flex-container center-items" :class="{\'has-error\': !gameValidator.string(intentSlot.label)}">
        <inline-edit
          v-model:string="intentSlot.label"
          placeholder="slot label"
          warning="Changing the slots’s label will break existing references to this slot. Continue?"
          focus="true"
          bold="true"
          />
        <div class="check auto-margin-left">
          <span class="fa fa-exclamation-circle text-danger" v-if="!intentSlot.$valid"></span>
        </div>
      </div>
      <div class="form-group" :class="{\'has-error\': !gameValidator.type(intentSlot.type)}">
        <label class="non-bold">What kind of entity fills this slot?</label>
        <type-select :model-type="intentSlot.type"/>
      </div>
      <div class="btn btn-caution pull-right" @click="remove(index)">remove slot</div>
    </div>
  '
