import BotbuilderApi from 'models/botbuilder_api'

export default class KnowledgeSourceDocument
  constructor: (@source, data) ->
    data ||= {}
    @update(data)

  update: (data) ->
    return if !data?
    @id = data.id
    @hash = data.hash
    @url = data.url
    @_title = data.title
    @filename = data.filename
    @filesize = data.filesize
    @mimeType = data.mimeType
    @timeCreated = data.timeCreated
    @description = data.description

  getUrl: () ->
    BotbuilderApi.getKnowledgeSourceDocumentUrl(@)

  replace: (file) ->
    BotbuilderApi.updateKnowledgeSourceDocument(@, file)

  delete: () ->
    BotbuilderApi.deleteKnowledgeSourceDocument(@)

  Object.defineProperties @prototype,
    timeCreatedFormatted:
      get: ->
        return '' if !@timeCreated
        moment(@timeCreated).format(t('formats.dateTime'))
    filesizeFormatted:
      get: ->
        return "#{(@filesize / 1000).toFixed(1)} KB" if @filesize < 1000000
        "#{(@filesize / 1000000).toFixed(1)} MB"
    title:
      get: ->
        (@_title || '').replaceAll('/', "​/")
