import GuidGenerator from 'models/guid_generator.coffee'

export default class WorkflowBotInput
  constructor: (@workflowTrigger, data = {}) ->
    @id = GuidGenerator.newGuid()
    @key = data.key || ''
    @description = data.description || ''
    @valueType = data.valueType || 'string'
    @collectionType = data.collectionType || 'none'
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key if data.key?
    @description = data.description if data.description?
    @valueType = data.valueType if data.valueType?
    @collectionType = data.collectionType if data.collectionType?

  Object.defineProperties @prototype,
    export:
      get: ->
        key:  @key
        description: @description
        valueType: @valueType
        collectionType: @collectionType
