Vue.component 'support-prompt-answers',
  props: ['prompt']
  mixins: [WizardComponent]
  data: ->
    entity: @prompt
    role: 'tail'
    attributes:
      answers:
        type: 'Array'
        default: [{}]
    answersCopy: [{}]
  computed:
    parameter: ->
      @state.availableContextParameters.find((param) => param.label == @prompt.contextParameter)
  created: ->
    if @prompt.answers?
      @pristine = false
    @setCopy()
  methods:
    addAnswer: ->
      @answersCopy.push({})
    removeAnswer: (index) ->
      @answersCopy.splice(index, 1)
    close: ->
      new Promise (resolve, reject) =>
        if !(@head.editing || @head.viewing)
          resolve()
          return
        if @someDirty
          @state.showDirtyModal(
            @head.name,
            @finished if @allComplete,
            null
          ).then(resolve).catch(reject)
        else
          @finished()
          resolve()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">How will user replies translate to parameter values?</h5>
          <support-prompt-answer
            v-for="answer, index in answersCopy"
            :answer="answer"
            :parameter="parameter"
            :index="index"
            :count="answersCopy.length"
            @remove="removeAnswer"
            />
            <plus-button label="add new parameter value" :method="addAnswer"/>
          <support-confirm-button/>
        </div>
      </div>
      <div class="button-container">
        <button v-if="!pristine" @click="finished" class="btn btn-primary">confirm and close</button>
      </div>
    </div>
  '
