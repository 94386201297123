import WidgetMenuItem from 'models/widget_menu_item.coffee'

export default class WidgetMenu
  constructor: (@channel, data = {}) ->
    @locale = data.locale || 'default'
    @items = (data.call_to_actions || []).map (itemData) => new WidgetMenuItem(@, itemData)

  addItem: ->
    @items.push(new WidgetMenuItem(@))

  Object.defineProperties @prototype,
    export:
      get: ->
        locale: @locale
        call_to_actions: @items.map (item) -> item.export
