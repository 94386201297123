
  export default
    props:
      show: Boolean
      position:
        type: String
        default: 'center'
      background:
        type: String
        default: 'shadow'

    computed:
      positionClass: ->
        switch @position
          when 'center' then 'items-center'
          when 'top' then 'items-start'
      backgroundClass: ->
        switch @background
          when 'shadow' then 'bg-gray-50 bg-opacity-75'
          when 'none' then ''
