export default class ValueType
  @TYPES = 
    string: 'String'
    number: 'Number'
    bool: 'Boolean'
    date: 'Date'
    gram: 'Gram'
    kilogram: 'Kilogram'
    hour: 'Hour'
    minute: 'Minute'
    second: 'Second'
    year: 'Year'
    meter: 'Meter'
    decimeter: 'Decimeter'
    centimeter: 'Centimeter'
    millimeter: 'Millimeter'
    kilometer: 'Kilometer'
    inch: 'Inch'
    entity: 'Entity'
    knowledgeSource: 'Knowledge source'

  @COLLECTION_TYPES =
    none: 'Single'
    set: 'Set of'
    list: 'List of'

  @isNumberWithUnit: (type) ->
    [
      'gram'
      'kilogram'
      'hour'
      'minute'
      'second'
      'year'
      'meter'
      'decimeter'
      'centimeter'
      'millimeter'
      'kilometer'
      'inch'
    ].includes(type)

  @isNumber: (type) ->
    type == 'number' || @isNumberWithUnit(type)
