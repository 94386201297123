
import ConditionType from 'models/condition_type.coffee'
import Input from './input.vue'
import Dropdown from './dropdown.vue'
import FilterPopup from './parameter_filter_popup.vue'
import { XIcon } from '@heroicons/vue/outline'

export default
  props:
    parameters: Array
    filter: Object
    index: Number
    filters: Array
    context: String
    disabled:
      type: Boolean
      default: false

  emits: ['apply', 'remove-applied', 'remove-draft', 'reset']

  data: ->
    searchExpression: ''
    currentParameter: null
    appliedFilter: null
    paramRefs: []

  computed:
    parameter: ->
      @parameterByLabel(@filter.field)
    parameterLabel: ->
      return 'Select parameter' if !@parameter?
      @parameter.humanizedLabel || @parameter.label
    filteredParams: ->
      availableParameters = if @$root.isBotcore2
        @parameters.filter (param) =>
          @filter.field == param.key || !(param.fix && @filters.map((filter) -> filter.field).includes(param.key))
      else
        @parameters.filter (param) =>
          @filter.field == param.label || !(param.fix && @filters.map((filter) -> filter.field).includes(param.label))
      return availableParameters if @searchExpression.length == 0
      availableParameters.filter (parameter) =>
        labelParts = (parameter.humanizedLabel || parameter.label).toLowerCase().split(/[\s\.]/)
        labelParts.some (part) => part.startsWith(@searchExpression.toLowerCase())
    currentIndex: ->
      return -1 if !@currentParameter?
      if @$root.isBotcore2
        @filteredParams.findIndex (param) => param.key == @currentParameter.key
      else
        @filteredParams.findIndex (param) => param.label == @currentParameter.label
    label: ->
      return '' if !@parameter? || !@filter.operator
      if @parameter.type == 'Selection'
        return @parameter.collection.find((item) => item.id == @filter.value)?.name
      if @$root.isBotcore2
        label = ConditionType.TYPES_FOR_OLD_FILTERS[@filter.operator]
        if @filter.value
          if @parameter.valueType == 'string'
            label += " “#{@filter.value}”"
          else
            label += " #{@filter.value}"
      else
        operators = Object.assign({}, TypeValidator.isSetOperator(), TypeValidator.availableOperators(@parameter.type))
        label = operators[@filter.operator]
        if @filter.value
          if ['Boolean', 'Date', 'Number'].includes(TypeValidator.valueType(@parameter.type))
            label += " #{@filter.value}"
          else
            label += " “#{@filter.value}”"
      label
    filterApplied: ->
      @appliedFilter? && @filter.equals(@appliedFilter)

  watch:
    searchExpression: ->
      @currentParameter = @filteredParams[0] if @currentIndex < 0

  created: ->
    @appliedFilter = @filter.clone() if @filter.valid

  beforeUpdate: ->
    @paramRefs = []

  methods:
    initialize: ->
      @paramRefs = []
      Vue.nextTick =>
        setTimeout =>
          @$refs.searchInput.focus()
          @currentParameter = if @parameter? then @parameter else @filteredParams[0]
          @scrollIntoView(@parameter) if @parameter?
        , 0
    key: (e) ->
      switch e.key
        when 'Esc'
         @close()
        when 'Enter'
         @setParameter(@currentParameter)
        when 'ArrowUp'
          return if @currentIndex <= 0
          @currentParameter = @filteredParams[@currentIndex - 1]
          @scrollIntoView()
        when 'ArrowDown'
          return if @currentIndex == @filteredParams.length
          @currentParameter = @filteredParams[@currentIndex + 1]
          @scrollIntoView()
        when 'Home'
          @currentParameter = @filteredParams[0]
          @scrollIntoView()
        when 'End'
          @currentParameter = @filteredParams[-1..][0]
          @scrollIntoView()
    scrollIntoView: (parameter = @currentParameter) ->
      return unless parameter?
      currentEl = if @$root.isBotcore2
        @paramRefs.find (el) -> el.getAttribute('data-key') == parameter.key
      else
        @paramRefs.find (el) -> el.getAttribute('data-label') == parameter.label
      return unless currentEl?
      if currentEl.offsetTop < @$refs.scrollContainer.scrollTop
        @$refs.scrollContainer.scrollTop = currentEl.offsetTop
      else if currentEl.offsetTop + currentEl.offsetHeight > @$refs.scrollContainer.scrollTop + @$refs.scrollContainer.offsetHeight
        @$refs.scrollContainer.scrollTop = currentEl.offsetTop + currentEl.offsetHeight - @$refs.scrollContainer.offsetHeight
    parameterByLabel: (keyOrLabel) ->
      if @$root.isBotcore2
        @parameters.find (parameter) -> parameter.key == keyOrLabel
      else
        @parameters.find (parameter) -> parameter.label == keyOrLabel
    setParamRef: (el) ->
      @paramRefs.push(el) if el?
    setParameter: (parameter) ->
      if @$root.isBotcore2
        @filter.field = parameter.key
      else
        @filter.field = parameter.label
      @filter.fix = parameter.fix
    apply: ->
      if !@filter.valid
        @remove()
      else
        @appliedFilter = @filter.clone()
        @$emit('apply')
        @close()
    remove: ->
      if @appliedFilter?
        @$emit('remove-applied', @index)
      else
        @$emit('remove-draft', @index)
    open: ->
      @$refs.dropdown.open()
    close: ->
      @searchExpression = ''
      @$refs.dropdown.close()
    reset: ->
      if !@appliedFilter?
        @remove()
      else
        @$emit('reset', @index, @appliedFilter)
    isCurrent: (parameter) ->
      if @$root.isBotcore2
        @currentParameter? && @currentParameter.key == parameter.key
      else
        @currentParameter? && @currentParameter.label == parameter.label
    isSelected: (parameter) ->
      if @$root.isBotcore2
        @filter.field? && @filter.field == parameter.key
      else
        @filter.field? && @filter.field == parameter.label

  components:
    Dropdown: Dropdown
    FilterPopup: FilterPopup
    Input: Input
    XIcon: XIcon
