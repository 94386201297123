import UtteranceSlot from 'models/utterance_slot.coffee'

export default class Utterance
  @TYPES = [
    'TextUtterance'
    'RegExUtterance'
  ]

  constructor: (data = {}, options = {}) ->
    @type = data.type || 'TextUtterance'
    @slots = (data.slots || []).map (slotData) -> new UtteranceSlot(slotData, options)
    switch @type
      when 'TextUtterance'
        @text = data.text || ''
      when 'RegExUtterance'
        @pattern = data.pattern || ''

  update: (data) ->
    @type = data.type if data.type?
    @slots = data.slots.map (slotData) -> new UtteranceSlot(slotData) if data.slots?
    switch @type
      when 'TextUtterance'
        @text = data.text if data.text?
      when 'RegExUtterance'
        @pattern = data.pattern if data.pattern?
    this

  Object.defineProperties @prototype,
    string:
      get: ->
        switch @type
          when 'RegExUtterance' then @pattern
          when 'TextUtterance' then @text
      set: (val) ->
        switch @type
          when 'RegExUtterance' then @pattern = val
          when 'TextUtterance' then @text = val
    isRegEx:
      get: ->
        @type == 'RegExUtterance'
      set: (val) ->
        if val
          @type = 'RegExUtterance'
          @pattern = @text
        else
          @type = 'TextUtterance'
          @text = @pattern
    export:
      get: ->
        obj =
          type: @type
          slots: @slots.map (slot) -> slot.export
        switch @type
          when 'TextUtterance'
            obj.text = @text
          when 'RegExUtterance'
            obj.pattern = @pattern
        obj
