import { confirm } from 'helpers'

Vue.component 'preference-row',
  props: ['preference', 'index']
  data: ->
    state: state
  computed:
    contentObjectType: ->
      @state.contentObjectTypes[@preference.contentObjectType] || {}
    signature: ->
      @contentObjectType.signature || {}
    valueType: ->
      @signature[@preference.attribute]
    availableOperators: ->
      TypeValidator.availableOperators(@valueType)
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @preference.operator)
    nameValid: ->
      GameValidator.string(@preference.name)
    contentObjectTypeValid: ->
      @state.contentObjectTypes[@preference.contentObjectType]?
    typeValid: ->
      @preference.type in ['hard', 'soft']
    attributeValid: ->
      @signature[@preference.attribute]?
    operatorValid: ->
      @availableOperators[@preference.operator]?
    valueValid: ->
      @preference.value.length == 0 || TypeValidator.valueValid(@preference.value, @valueType, @preference.operator)
  watch:
    preference:
      handler: -> @validate()
      deep: true
  created: ->
    Vue.setDefault(@preference, 'name', '')
    Vue.setDefault(@preference, 'contentObjectType', '')
    Vue.setDefault(@preference, 'type', 'hard')
    Vue.setDefault(@preference, 'attribute', '')
    Vue.setDefault(@preference, 'operator', '')
    Vue.setDefault(@preference, 'value', '')
    Vue.setDefault(@preference, 'settingUserUtterances', {})
    Vue.setDefault(@preference, 'deletingUserUtterances', {})
    Vue.setDefault(@preference, 'botStatement', {})
    Vue.setDefault(@preference, '$key', GuidGenerator.newGuid())
  methods:
    validate: ->
      Vue.set @preference, '$valid',
        @nameValid && @contentObjectTypeValid && @typeValid && @attributeValid && @operatorValid && @valueValid
    edit: ->
      Vue.set(@preference, 'editing', true)
    save: ->
      Vue.delete(@preference, 'editing')
    remove: ->
      return unless await confirm("Do you really want do delete preference “#{@preference.name}”?")
      @$emit('remove-preference', @index)
  template: '
    <tr>
      <template v-if="preference.editing">
        <td>
          <span class="fa fa-exclamation-circle invisible"></span>
        </td>
        <td :class="{\'has-error\': !nameValid}">
          <input type="text" v-model="preference.name" class="form-control">
        </td>
        <td :class="{\'has-error\': !contentObjectTypeValid}">
          <select v-model="preference.contentObjectType" class="form-control">
            <option v-for="config, type in state.contentObjectTypes" :value="type">{{ type }}</option>
          </select>
        </td>
        <td :class="{\'has-error\': !typeValid}">
          <select v-model="preference.type" class="form-control">
            <option value="hard">hard</option>
            <option value="soft">soft</option>
          </select>
        </td>
        <td :class="{\'has-error\': !attributeValid}">
          <select v-model="preference.attribute" class="form-control">
            <option v-for="type, attr in signature" :value="attr">{{ attr }}</option>
          </select>
        </td>
        <td :class="{\'has-error\': !operatorValid}">
          <select v-model="preference.operator" class="form-control">
            <option v-for="text, operator in availableOperators" :value="operator">
              {{ text }}
            </option>
          </select>
        </td>
        <td :class="{\'has-error\': !valueValid}">
          <input type="text" v-model="preference.value" :placeholder="valueLabel" class="form-control">
        </td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button @click="save" class="btn btn-icon primary permanent">
              <span class="fa fa-check"></span>
            </button>
            <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete\', method: remove}]"/>
          </div>
        </td>
      </template>
      <template v-else>
        <td>
          <span class="fa fa-exclamation-circle text-danger" v-if="!preference.$valid"></span>
        </td>
        <td>{{ preference.name }}</td>
        <td>{{ preference.contentObjectType }}</td>
        <td>{{ preference.type }}</td>
        <td>{{ preference.attribute }}</td>
        <td>{{ availableOperators[preference.operator] }}</td>
        <td>{{ preference.value }}</td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button @click="edit" class="btn btn-icon">
              <span class="fa fa-pen"></span>
            </button>
            <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete\', method: remove}]"/>
          </div>
        </td>
      </template>
    </tr>
  '
