import DialogTarget from 'models/dialog_target.coffee'
import GuidGenerator from 'models/guid_generator.coffee'

export default class IceBreakerItem
  constructor: (@iceBreaker, data = {}) ->
    @id = GuidGenerator.newGuid()
    @question = data.question || ''
    @payload = data.payload || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        question: @question
        payload: @payload
    target:
      get: ->
        [moduleKey, nodeKey] = if @payload.length > 0
          @payload.replace(/\(.*\)$/, '').split('/')
        else
          []
        new DialogTarget(
          {}
          moduleKey: moduleKey
          nodeKey: nodeKey
        )
      set: (target) ->
        if target.moduleKey? && target.nodeKey?
          @payload = "#{target.moduleKey}/#{target.nodeKey}"
        else
          @payload = ''
