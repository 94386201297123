import GuidGenerator from 'models/guid_generator.coffee'

export default class WorkflowTrace
  constructor: (@workflow, data = {}) ->
    @id = GuidGenerator.newGuid()
    @timeStarted = data.timeStarted
    @timeFinished = data.timeFinished
    @success = data.success
    @stepTraces = data.stepTraces

  Object.defineProperties @prototype,
    timeStartedFormatted:
      get: ->
        return '' if !@timeStarted?
        moment(@timeStarted).format(t('formats.dateTime'))
    timeFinishedFormatted:
      get: ->
        return '' if !@timeFinished?
        moment(@timeFinished).format(t('formats.dateTime'))
