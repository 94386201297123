Vue.component 'editable',
  props:['content', 'disabled']
  mounted: ->
    @$el.innerText = @content
  methods:
    focus: ->
      @$el.focus()
    clear: ->
      @$el.innerText = ''
    reset: ->
      Vue.nextTick =>
        @$el.innerText = @content
    update: (event) ->
      event.target.innerText = '' if event.target.innerText == "\n"
      @$emit('update', event.target.innerText.trim())
    emitFocus: ->
      if @disabled
        @$el.blur()
        return
      @$emit('focus')
    exit: (event) ->
      @$emit('exit', event)
      event.target.blur()
    handleEnterKey: (event) ->
      return if event.shiftKey
      @exit(event)
    paste: (event) ->
      clipboardData = event.clipboardData || window.clipboardData
      input = clipboardData.getData('Text')
      document.execCommand('insertText', false, input)
  template: '
    <div
      class="editable"
      contenteditable="true"
      @input="update"
      @focus="emitFocus"
      @keydown.enter="handleEnterKey"
      @keydown.esc="exit"
      @drop.prevent=""
      @paste.prevent="paste"
      @blur="$emit(\'blur\', $event)"
      :class="{disabled: disabled}"
      >
    </div>
  '
