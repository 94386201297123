Vue.component 'message-delay',
  props: ['modelValue']

  data: ->
    editing: false

  methods:
    toggle: ->
      return @editing = false if @editing
      @editing = true
      Vue.nextTick =>
        $(@$el).find('input').focus()
    emit: (event) ->
      @$emit('update:modelValue', parseInt(event.target.value) || 0)

  template: '
    <div class="message-delay flex-container center-items">
      <div class="delay-icon" :class="modelValue > 0 ? \'delay\' : \'\'" @click="toggle"></div>
      <div v-show="editing" class="flex-container center-items">
        <input type="text" :value="modelValue" @input="emit" @keyup.enter="editing = false" class="styled-form-field input-sm">
        &nbsp;ms
        <div class="delay-button" @click="editing = false"><span class="fa fa-check"></span></div>
      </div>
    </div>
  '
