
  import Label from './label.vue'

  export default
    props:
      modelValue: Array
      label: String
      errorText: String
      disabled:
        type: Boolean
        default: false

    data: ->
      value: @modelValue

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      emit: ->
        @$emit('update:modelValue', @value)
      focus: ->
        @$refs.select.focus()

    components:
      Label: Label
