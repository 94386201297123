
  import Attachment from 'models/attachment'

  import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/outline'

  export default
    props:
      button: Attachment
      index: Number
      count: Number

    data: ->
      show: true

    watch:
      index: ->
        @show = false
        setTimeout =>
          @show = true
        , 500

    methods:
      pushUp: (index) ->
        @button.buttonPushUp()
        @$emit('moved')
      pushDown: (index) ->
        @button.buttonPushDown()
        @$emit('moved')

    components:
      ArrowSmDownIcon: ArrowSmDownIcon
      ArrowSmUpIcon: ArrowSmUpIcon
