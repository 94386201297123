
import PlatformApi from 'models/platform_api.coffee'

import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
import { Alert, Button, Input, Listbox } from 'components/generic'
import { RefreshIcon } from '@heroicons/vue/outline'

export default
  props:
    mayAddUsers: Boolean

  emits: ['add']

  data: ->
    invitations: []
    errors: []
    succeeded: 0
    status: 'pristine'
    infiniteUsers: Globals.restrictions.users == Infinity

  created: ->
    @addInvitation()

  methods:
    newInvitation: ->
      first_name: ''
      last_name: ''
      email: ''
      project: @$root.projects[0]
      role: @$root.projectRoles[0]
      errors: {}
    reset: ->
      @errors = []
      @succeeded = 0
      @invitations = [@newInvitation()]
      @status = 'pristine'
    addInvitation: ->
      @invitations.push(@newInvitation())
    inviteUsers: ->
      @status = 'sending'
      @errors = []
      @succeeded = 0
      @invitations = @invitations.filter (invitation) ->
        invitation.email.length || invitation.first_name.length || invitation.last_name.length
      Promise.allSettled(
        @invitations.map (invitation) =>
          PlatformApi.Webapp.inviteUser(
            user:
              first_name: invitation.first_name
              last_name: invitation.last_name
              email: invitation.email
              project_role_id: invitation.role.id
            project_id: invitation.project.id
          )
          .then (userData) =>
            @$emit('add', userData)
            @succeeded += 1
          .catch (response) =>
            @errors.push(response)
            Object.keys(response.errors).forEach (attribute) =>
              @errors[-1..][0].errors[attribute] = response.errors[attribute].join(', ')
      ).then =>
        if @errors.length == 0
          @status = 'succeeded'
        else
          @status = 'failed'
          @invitations = @errors.map (errorResponse) =>
            first_name: errorResponse.data.first_name || ''
            last_name: errorResponse.data.last_name || ''
            email: errorResponse.data.email || ''
            # role: @$root.projectRoles.find (r) -> r.id == errorResponse.data.role_id
            project: @$root.projects.find (p) -> p.id == errorResponse.data.project_id
            errors: errorResponse.errors

  components:
    Alert: Alert
    Button: Button
    Input: Input
    Listbox: Listbox
    RefreshIcon: RefreshIcon
    UpgradePrompt: UpgradePrompt
