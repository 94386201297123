Vue.component 'inbox2-message',
  props: ['message', 'conversation']

  computed:
    bubbles: ->
      if @message.message.type == 'Bubble'
        [@message.message]
      else
        @message.message.bubbles
    direction: ->
      if @message.origin == 'USER'
        'in'
      else
        'out'
    messageClasses: ->
      [
        'message'
        @direction
        @message.origin.toLowerCase()
        if @message.annotatable && !@message.annotated then 'highlight' else ''
      ]
    bodyClasses: ->
      "body #{@message.message.type.toLowerCase()}"
    handoverAgentName: ->
      return null if !@message.handoverAgentId
      return null if !(agent = @$root.agents.find (agent) => agent.id == @message.handoverAgentId)
      agent.fullName

  methods:
    textSelected: ->
      @$emit('text-selected', @message)
    markForAnnotation: ->
      @$root.api.markMessageforAnnotation(@conversation, @message)

  template: '
    <div :class="messageClasses" :data-id="message.id">
      <div v-if="message.message.type.toLowerCase() != \'selection\'" :class="bodyClasses" @mouseup="textSelected">
        <div v-if="handoverAgentName" class="handover-agent">
          {{ handoverAgentName }}
        </div>
        <inbox2-bubble v-for="bubble in bubbles" :bubble-data="bubble" :message="message"/>
        <div class="time">{{ message.time }}</div>
      </div>
      <slick v-if="message.message.type.toLowerCase() == \'selection\'" :class="bodyClasses" :options="{infinite: false}">
        <inbox2-bubble v-for="bubble in bubbles" :bubble-data="bubble" :message="message"/>
        <div class="time">{{ message.time }}</div>
      </slick>
      <div v-if="direction == \'in\' && message.interpretation" class="interpretation">
        <div v-if="$root.mayViewForeignConversations || $root.isSuper" @click="markForAnnotation" class="btn no-padding" title="mark for annotation">
          <span class="far fa-bookmark"></span>
        </div>
        <template v-if="$root.activeTab != \'handover\'">
          <div>Confidence: {{ message.interpretation.confidence }}</div>
          <div>Language: {{ message.interpretation.language }}</div>
          <div>Intent: {{ message.interpretation.intent }}</div>
        </template>
      </div>
    </div>
  '
