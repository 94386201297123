
  import WebChannel from 'models/web_channel.coffee'
  import { alert } from 'helpers'

  import SettingPanel from '../setting_panel.vue'
  import ColorPicker from "../color_picker.vue";
  import ImageUpload from '../image_upload.vue'
  import InitialBehaviorEditor from './initial_behavior_editor.vue'
  import WidgetStylingPreview from './widget_styling_preview.vue'
  import WidgetSnippet from './widget_snippet.vue'
  import { Input, Label, KeyListbox, NumberStepper, Switch } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
  import { ChevronDownIcon, UserCircleIcon } from '@heroicons/vue/solid'


  export default
    props:
      modelValue: WebChannel

    data: ->
      fontFamilies = [
        {key: '"Inter", sans-serif', label: '"Inter", sans-serif'}
        {key: 'sans-serif', label: 'sans-serif'}
        {key: 'serif', label: 'serif'}
        {key: 'monospace', label: 'monospace'}
        {key: 'system-ui', label: 'system-ui'}
        {key: 'ui-sans-serif', label: 'ui-sans-serif'}
        {key: 'ui-serif', label: 'ui-serif'}
        {key: 'ui-monospace', label: 'ui-monospace'}
      ]
      bodyFont = fontFamilies.find (fam) => fam.key == @modelValue.widgetStyles.bodyFont
      if !bodyFont?
        fontFamilies.unshift(
          key: @modelValue.widgetStyles.bodyFont
          label: @modelValue.widgetStyles.bodyFont
        )
      defaultModes = [
        {key: 'open', label: 'Open'}
        {key: 'closed', label: 'Closed'}
        {key: 'minified', label: 'Minified'}
      ]

      channel: @modelValue
      logo: null
      avatar: null
      toggleOpen: null
      toggleClosed: null
      fontFamilies: fontFamilies
      defaultModes: defaultModes
      UserCircleIcon: UserCircleIcon

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      updateImage: (fileName) ->
        @channel.uploadAsset(fileName, @[fileName])

    components:
      ChevronDownIcon: ChevronDownIcon
      ColorPicker: ColorPicker
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      ImageUpload: ImageUpload
      InitialBehaviorEditor: InitialBehaviorEditor
      Label: Label
      KeyListbox: KeyListbox
      NumberStepper: NumberStepper
      SettingPanel: SettingPanel
      Switch: Switch
      WidgetStylingPreview: WidgetStylingPreview
      WidgetSnippet: WidgetSnippet
