
  export default
    props:
      modelValue: String

    data: ->
      text: @modelValue

    watch:
      modelValue: ->
        @text = @modelValue

    computed:
      remainingChars: ->
        4000 - @text.length

    methods:
      updateText: (event) ->
        inputValue = event.target.value
        if inputValue.length <= 4000
          @text = inputValue
          @$emit('update:modelValue', @text)
      focus: ->
        @$refs.textarea.focus()
