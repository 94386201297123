
  import { confirm } from 'helpers'

  import { ActionMenu, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      context: String

    methods:
      routerTarget: (schema) ->
        switch @context
          when 'content'
            name: 'contentList'
            params: {schemaKey: schema.key}
          when 'schema'
            name: 'entitySchema'
            params: {schemaKey: schema.key}
      actionMenuItems: (schema) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(name: 'entitySchema', params: {schemaKey: schema.key})}
          {icon: XIcon, label: 'Delete', method: => @remove(schema)}
        ]
      remove: (schema) ->
        return unless await confirm("Do you really want do delete entity schema “#{schema.label}”? The schema and all content of its type will be deleted!")
        schema.delete().then =>
          @$root.entitySchemas = @$root.entitySchemas.filter (s) => s.key != schema.key

    components:
      ActionMenu: ActionMenu
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
