import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'
import ProjectUser from 'models/project_user.coffee'
import Role from 'models/role.coffee'
import UserTable from 'components/project_users/user_table.vue'
import { PageHeader } from 'components/generic'

initProjectUsersApp = (element) ->
  # dummy router needed to register router components
  router = createRouter(history: createWebHistory(), routes: [])

  createApp(
    data: ->
      organizationUsers = $(element).data('organization-users')
      organizationUsers.forEach (org) ->
        org.projectUsers = org.project_users.map (projectUserData) ->
          new ProjectUser(
            Object.assign({projectId: Globals.projectId, stageLevel: Globals.stageLevel}, projectUserData)
          )
      roles = Object.fromEntries(
        Object.entries($(element).data('roles')).map ([orgId, roleList]) ->
          [
            orgId
            roleList.map (roleData) -> new Role(roleData)
          ]
      )

      organizationUsers: organizationUsers
      roles: roles
      teams: $(element).data('teams')
      projectId: Globals.projectId
      stageLevel: Globals.stageLevel
      mayEditTeam: Globals.currentUser.may('grant_project_permissions', {id: Globals.projectId})

    components:
      PageHeader: PageHeader
      UserTable: UserTable

    template: '
      <div class="sm:px-6 lg:px-8">
        <PageHeader title="Project team"/>

        <UserTable v-for="org in organizationUsers" :key="org.organization.id" :org="org"/>
      </div>
    '
  )
    .use(router)
    .mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('project-users-app')
      initProjectUsersApp(element)
