import VMdEditor from 'v_md_editor'

Vue.component 'additional-teaser-message',
  props: ['item', 'index', 'itemList', 'conditions', 'contextParameters']
  data: ->
    state: state
    defaultMessage: ''
    urlPattern: ''
  created: ->
    Vue.setDefault(@item, '$key', GuidGenerator.newGuid())
    @urlPattern = @item.urlPattern
    @defaultMessage = @item.message
    Vue.setDefault(@item, 'defaultTeaserMode', 'closed')
    Vue.setDefault(@item, 'displayInMode', 'minifiedAndOpen')
    Vue.setDefault(@item, 'conversationDisplay', 'before')
  methods:
    addItemAfter: ->
      @itemList.splice(@index + 1, 0, @$parent.defaultItem())
    removeItem: ->
      @$emit('remove-item', @index)
    removeCondition: (index) ->
      @item.contextConditions.splice(index, 1)
    addCondition: ->
      @item.contextConditions.push(parameter: '', operator: '', value: '')
  watch:
    defaultMessage: -> @item.message = @defaultMessage
    urlPattern: -> @item.urlPattern = @urlPattern
    # conditions: -> @item.contextConditions = @conditions
  components:
    'markdown-editor': VMdEditor
  template: '
    <div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <div class="col-sm-6">
          <markdown-editor
            v-model="defaultMessage"
            placeholder="Type teaser message ..."
            left-toolbar=""
            right-toolbar=""
            :codemirror-config="{lineNumbers: false, scrollbarStyle: \'simple\', extraKeys: {Tab: false}}"
            height="100px"
            />

          <teaser-payload-editor :payload="item.button"/>
        </div>

        <div class="col-sm-6">
          <input v-model="urlPattern" type="text" placeholder="URL pattern to be matched" class="form-control mb-5">
          <teaser-url-condition
            v-for="condition, index in conditions"
            :key="condition.$key"
            :condition="condition"
            :index="index"
            :count="conditions.length"
            :parameters="contextParameters"
            allow-delete-last="true"
            @remove="removeCondition"
            />
          <plus-button label="add condition" :method="addCondition"/>
          <div class="flex items-center mt-4 mb-3">
            <label class="width-40">If the widget is closed ...</label>
            <select class="flex-grow form-control ml-4" v-model="item.defaultTeaserMode">
              <option value="closed">stay closed</option>
              <option value="open">open to full mode to display message</option>
              <option value="minified">open to minified to display message</option>
            </select>
          </div>
          <div class="flex items-center mb-3">
            <label class="width-40">Show in ...</label>
            <select class="flex-grow form-control ml-4" v-model="item.displayInMode">
              <option value="minifiedAndOpen">minified mode and open mode</option>
              <option value="minified">minified mode</option>
              <option value="open">open mode</option>
            </select>
          </div>
          <div class="flex items-center mb-3">
            <label class="width-40">Display ...</label>
            <select class="flex-grow form-control ml-4" v-model="item.conversationDisplay">
              <option value="before">before conversations</option>
              <option value="during">during conversations</option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="!state.disabled" class="panel-body non-first">
        <div class="btn btn-default" @click="addItemAfter">Add additional teaser message</div>
        <div class="btn btn-caution pull-right" @click="removeItem">
          <span class="fa fa-trash-alt"></span>
          Remove teaser message
        </div>
      </div>
    </div>
  '
