
  import DialogTarget from 'models/dialog_target.coffee'
  import TriggerIntent from 'models/trigger_intent.coffee'

  import HintList from './hint_list.vue'
  import TargetEditor from './target_editor.vue'
  import { Input, Label, KeyListbox, Switch, Tabs } from 'components/generic'
  import { InformationCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      intent: TriggerIntent
      hints: Array

    emits: ['change']

    data: ->
      debounceTimeout: null
      moduleDebounceTimeout: null
      actionsForSelector: [
        {key: 'startOver', label: 'start over'}
        {key: 'handover', label: 'initiate handover'}
      ]
      currentTab: 'general'

    computed:
      tabs: ->
        [
          { id: 'general', name: 'General' }
          { id: 'hints', name: 'Hints & errors', badge: @hints.length }
        ]

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(
          =>
            @intent.save().then => @$emit('change')
        , 1000
        )
      updateModule: ->
        clearTimeout(@moduleDebounceTimeout)
        @moduleDebounceTimeout = setTimeout((=> @intent.dialogModule.saveSettings()), 1000)

    components:
      HintList: HintList
      InformationCircleIcon: InformationCircleIcon
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      Switch: Switch
      Tabs: Tabs
      TargetEditor: TargetEditor
