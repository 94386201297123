import { createApp } from 'vue_shims'
import GuidGenerator from 'models/guid_generator.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import WebChannel from 'models/web_channel.coffee'
import { alert } from 'helpers'
import { railsEnv } from 'rails_data'

import { Button, H3 } from 'components/generic'
import { ClipboardIcon } from '@heroicons/vue/outline'

initWidgetTestApp = (element) ->
  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))
      widgetVersion = if railsEnv == 'production' then 'v4' else 'staging'
      channel = new WebChannel(nativeId: elementData.channel_key)
      userId = GuidGenerator.newGuid()

      channel: channel
      projectId: elementData.project_id
      srcdoc: """
        <!DOCTYPE html>
        <html>
          <head>
            <link href='https://cdn.mercury.ai/widget/#{widgetVersion}/widget.css' rel='stylesheet'>
            <link rel="stylesheet" type="text/css" href="https://cdn.mercury.ai/channels/widget/#{channel.nativeId}/style.css">
            <script src='https://cdn.mercury.ai/widget/#{widgetVersion}/widget-main.js' id= 'widget-js'><\/script>
          </head>
          <body>
            <div style="width: 900px; height: 900px;">
              <div id="mercury-widget"></div>
            </div>
            <script>
              let config = {
                apiKey: '#{channel.nativeId}',
                apiServer: '#{channel.widgetServer}',
                pollingInterval: 3,
              };
              let user = {userId: '#{userId}'};
              Mercury.initChatWidget(document.getElementById('mercury-widget'), config, user
              )
            <\/script>
          </body>
        </html>
      """

    computed:
      projectHomePath: ->
        Routes.project_path(@projectId, 'dev')

    mounted: ->
      @$refs.iframeWrapper.scrollTo(9999, 9999)

    methods:
      copySnippet: (e) ->
        navigator.clipboard.writeText(@channel.snippet)
          .then =>
            e.target.blur()
            await alert('Copied snippet to clipboard')
      copyUrl: (e) ->
        navigator.clipboard.writeText(window.location.href.split('?')[0])
          .then =>
            e.target.blur()
            await alert('Copied URL to clipboard')

    components:
      Button: Button
      ClipboardIcon: ClipboardIcon
      H3: H3

    template: '
      <div v-if="channel" v-html="channel.styleLinkTag"></div>

      <div class="flex flex-col items-center">
        <h2 class="mt-6 mb-3 text-center text-3xl font-extrabold text-gray-800">
          Test your bot
        </h2>

        <p class="mb-8 text-gray-600">Ask your bot about the knowledge source you have added!</p>

        <div class="shrink-0 overflow-hidden" style="height: 730px; width: 430px; margin-right: -30px;" ref="iframeWrapper">
          <iframe :srcdoc="srcdoc" style="height: 1000px; width: 1000px;"/>
        </div>

        <Button v-if="projectId" :href="projectHomePath" type="primary" class="-mt-2 mb-8">
          Go to project dashboard
        </Button>

        <div class="w-96 bg-gray-50 shadow sm:rounded-lg px-6 py-4 space-y-4">
          <H3>Share this page</H3>
          <Button @click="copyUrl" size="sm">
            <ClipboardIcon class="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true"/>
            copy url
          </Button>
        </div>

      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('widget-test-app')
      initWidgetTestApp(element)
