
  import { Switch } from '@headlessui/vue'

  export default
    props:
      modelValue: Boolean
      disabled: Boolean
      invert:
        type: Boolean
        default: false

    data: ->
      value: if @invert then !@modelValue else @modelValue

    watch:
      modelValue: ->
        @value = if @invert then !@modelValue else @modelValue
      value: ->
        realValue = if @invert then !@value else @value
        @$emit('update:modelValue', realValue)
        @$emit('change', realValue)

    mounted: ->
      @$refs.switchComponent.$el.addEventListener 'click', => @$emit('click')

    components:
      Switch: Switch
