import { confirm } from 'helpers'

Vue.component 'inline-edit',
  props: ['string', 'placeholder', 'warning', 'focus', 'bold', 'displayString', 'wrapperClass', 'noInitialEdit']
  data: ->
    copy: null
  computed:
    computedDisplayString: ->
      return @string if @string?.length
      @displayString || ''
  watch:
    string: ->
      @copy = null
  created: ->
    @edit(@focus) if !(@string?.length || @noInitialEdit)
  methods:
    edit: (focus) ->
      @copy = @string
      Vue.nextTick =>
        $(@$el).on 'blur', => @save() # end editing mode when clicked outside
        $(@$el).focus() if focus? && focus
      true
    save: ->
      if @copy != @string && @string != '' # so the warning won't be displayed when the string wasn't changed or for newly created items
        return if @warning && !(await confirm(@warning))
      @$emit('update:string', @copy)
      $(@$el).off 'blur'
      @copy = null
    cancel: ->
      $(@$el).off 'blur'
      @copy = null
  template: '
    <input
      v-if="copy !== null"
      type="text"
      class="form-control aligned-input inline-edit"
      :class="{\'text-bold\': bold}"
      :placeholder="placeholder"
      v-model="copy"
      @keydown.enter="save"
      @keydown.esc="cancel"
      >
    <div v-else class="flex-container center-items inline-edit" :class="wrapperClass">
      <span :class="{\'text-bold\': bold}" class="ellipsis" v-html="computedDisplayString"></span>
      <div class="btn btn-icon-light" @click="edit"><span class="fa fa-pen"></span></div>
    </div>
  '
