import { confirm } from 'helpers'

Vue.component 'bubble-editor',
  props: ['bubble', 'index', 'count', 'multiple', 'title', 'messageState', 'message']

  data: ->
    urlEnabled: false

  created: ->
    Vue.setDefault(@bubble, 'type', 'Bubble')
    Vue.setDefault(@bubble, 'attachments', [])
    Vue.setDefault(@bubble, '$key', GuidGenerator.newGuid())
    @urlEnabled = @bubble.url?
    @validate()

  watch:
    bubble:
      handler: -> @validate()
      deep: true
    urlEnabled: ->
      Vue.delete(@bubble, 'url') if !@urlEnabled
      Vue.setDefault(@bubble, 'url', '') if @urlEnabled

  computed:
    urlValid: ->
      !@bubble.url? || GameValidator.url(@bubble.url) || GameValidator.placeholder(@bubble.url)
    canHaveButtons: ->
      @messageState.template in ['button', 'card', 'gallery', 'result']
    hasNumberOfSlides: ->
      @index == 0 && @message?.numberOfSlides?
    attachmentsWithoutQuickreplyButtons: ->
      @bubble.attachments.filter (attachment) =>
        @canHaveButtons || attachment.type != 'Button'
    bubbleName: ->
      if @messageState.template == 'card' then 'section' else 'slide'
  methods:
    validate: ->
      Vue.set @bubble, '$valid',
        @bubble.attachments?.length > 0 &&
        @urlValid &&
        GameValidator.allValid(@bubble.attachments)
    add: ->
      @$emit('add-bubble', @index)
    remove: ->
      return unless await confirm("Do you really want to delete this #{@bubbleName}?")
      @$emit('remove-bubble', @index, @bubble.$key)
    addButton: ->
      @bubble.attachments.push(type: 'Button', isNew: true, '$key': GuidGenerator.newGuid())
    removeAttachment: ($key) ->
      @bubble.attachments = @bubble.attachments.filter (attachment) => attachment.$key != $key
    endEditing: ->
      @messageState.editing = false
      @messageState.pristine = false

  template: '
    <div>
      <div v-if="title" class="panel-heading">
        <span class="text-primary text-bold">{{ title }}</span>&nbsp;
      </div>
      <div v-if="hasNumberOfSlides" class="panel-heading no-padding-top">
        <input type="number" v-model="message.numberOfSlides" class="styled-form-field" style="width: 5em"/>
        &ensp;slides per message
      </div>
      <attachments-editor
        :attachments="attachmentsWithoutQuickreplyButtons"
        :message-state="messageState"
        :small-image="index > 0 && messageState.template == \'card\'"
        :class="{\'attachment-list-with-buttons\': canHaveButtons}"
          @remove-attachment="removeAttachment"
        />
      <div v-if="messageState.template == \'gallery\' || messageState.template == \'result\'" class="bubble-link" :class="{active: urlEnabled}">
        <label class="sliding-switch align non-bold">
          Add link to slide
          <input type="checkbox" v-model="urlEnabled">
          <span class="slider"></span>
        </label>
        <div :class="{\'has-error\': !urlValid && !messageState.pristine}" class="bubble-link-input-container">
          <input
            type="text"
            v-model="bubble.url"
            class="form-control"
            placeholder="Enter URL"
            :disabled="urlEnabled ? null : true"
            @focusout="endEditing"
            @keyup.enter="endEditing(); $event.target.blur()"
            @keyup.esc="endEditing(); $event.target.blur()"
            >
        </div>
      </div>
      <div v-if="canHaveButtons" @click="addButton" class="btn btn-add-button">
        add button
      </div>
      <div v-if="multiple" class="bubble-buttons flex-container space-around-content wrap">
        <plus-button
          v-if="multiple && count > 1"
          :label="\'delete \' + bubbleName"
          :method="remove"
          icon-class="fa fa-minus-circle"
          color-class="text-danger"
          class="text-small"
          />
        <plus-button
          v-if="multiple"
          :label="\'add \' + bubbleName"
          :method="add"
          class="text-small"
          />
      </div>
    </div>
  '
