export default class ObjectProcessor

  @removeTransientProperties: (object) ->
    if object instanceof Array
      @removeTransientProperties(newObject) for newObject in object
    else if object instanceof Object
      delete object.$filled
      delete object.$key
      delete object.$valid
      for property of object
        @removeTransientProperties(object[property])

  @clone: (object, options = {}) ->
    if !options.removeTransientProperties?
      options.removeTransientProperties = true
    newObject = JSON.parse(JSON.stringify(object))
    @removeTransientProperties(newObject) if options.removeTransientProperties
    newObject

  @cloneWithout: (object, keys) ->
    newObject = JSON.parse(JSON.stringify(object))
    for key in keys
      delete newObject[key]
    newObject

  # taken from https://gist.github.com/davidjbeveridge/3881906
  @equal: (obj1, obj2) ->
    [type1, type2] = [typeof obj1, typeof obj2]
    return false if type1 != type2
    if type1 != 'object'
      return obj1 == obj2
    else
      if obj1 instanceof Array
        if obj2 instanceof Array
          if obj1.length == obj2.length
            return false for i in [0..obj1.length] when !@equal(obj1[i], obj2[i])
          else
            return false
        else
          return false
      else
        # this is a hash-like thing
        keys = []
        (keys.push key unless key in keys) for own key, value of obj1
        (keys.push key unless key in keys) for own key, value of obj2
        return false for key in keys when !@equal(obj1[key], obj2[key])
    true
