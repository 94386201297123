
  import { tfaMethods } from 'rails_data'
  import PlatformApi from 'models/platform_api.coffee'
  import { Alert, Button, H3, Input, Modal, Panel } from 'components/generic'
  import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

  export default
    props:
      originalUser: Object

    data: ->
      user: {}
      editing: false
      sending: false
      errorMessage: null
      confirmationErrorMessage: null
      confirmationCode: ''
      tfaMethods: tfaMethods

    created: ->
      @resetUser()

    methods:
      resetUser: ->
        @user = ObjectProcessor.clone(@originalUser)
      updateOriginalUser: (data) ->
        Object.assign(@originalUser, data)
        @resetUser()
      cancel: ->
        @resetUser()
        @editing = false
        @errorMessage = null
      save: ->
        @sending = true
        PlatformApi.Webapp.saveTfaMethod(@user.tfa_method)
          .then (data) =>
            @updateOriginalUser(data)
            @editing = false
            @errorMessage = null
            @$refs.comfirmTfaModal.open()
          .catch (response) =>
            @resetUser()
            @errorMessage = response.message
          .finally =>
            @sending = false
      confirmTfa: ->
        @sending = true
        PlatformApi.Webapp.confirmTfaMethod(@confirmationCode)
          .then (data) =>
            @updateOriginalUser(data)
            @confirmationErrorMessage = null
            @$refs.comfirmTfaModal.close()
          .catch (response) =>
            @confirmationErrorMessage = response.message
          .finally =>
            @sending = false
            @confirmationCode = ''
      promptTfaConfirmation: ->
        @sending = true
        PlatformApi.Webapp.promptTfaConfirmation()
          .then (data) =>
            @updateOriginalUser(data)
            @$refs.comfirmTfaModal.open()
          .catch (response) =>
            @confirmationErrorMessage = response.message
          .finally =>
            @sending = false
      createBackupCodes: ->
        @sending = true
        PlatformApi.Webapp.createBackupCodes()
          .then (data) =>
            @backupCodes = data
            @$refs.backupCodesModal.open()
          .finally =>
            @sending = false

    components:
      Alert: Alert
      Button: Button
      H3: H3
      Input: Input
      Modal: Modal
      Panel: Panel
      RadioGroup: RadioGroup
      RadioGroupLabel: RadioGroupLabel
      RadioGroupOption: RadioGroupOption
