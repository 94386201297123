
  import { Button } from 'components/generic'
  import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { SearchIcon, SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      placeholder: String
      items: Array
      filterFunction: Function
      disabled:
        type: Boolean
        default: false
      align:
        type: String
        default: 'left'

    emits: ['apply', 'cancel']

    data: ->
      $key: GuidGenerator.newGuid()
      query: ''
      open: true

    computed:
      filteredItems: ->
        return @items unless @query
        @items.filter (item) => @filterFunction(item, @query)

    mounted: ->
      @$nextTick => @$refs.input.focus()

    methods:
      apply: ->
        @query = ''
        @open = false
        @$emit('apply')
      cancel: ->
        @query = ''
        @$emit('cancel')

    components:
      Button: Button
      Dialog: Dialog
      DialogPanel: DialogPanel
      SearchIcon: SearchIcon
      SelectorIcon: SelectorIcon,
      TransitionChild: TransitionChild
      TransitionRoot: TransitionRoot
