import Analytics from 'analytics.coffee'

export default (router) ->
  router.afterEach(->
    try
      Analytics.track(
        'Page opened',
        url: window.location.href,
        is_botcore_2: Globals.projectIsBotcore2,
        project_role: Globals.projectRole
      )
  )
