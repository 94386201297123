
  import Status from 'models/status.coffee'

  import Alert from './alert.vue'

  export default
    props:
      status: Status
      displayAll:
        type: Boolean
        default: false

    data: ->
      colors:
        OK: 'green'
        INFO: 'blue'
        WARN: 'yellow'
        ERROR: 'red'
        UNKNOWN: 'yellow'

    computed:
      messagesToDisplay: ->
        return [] unless @status?.messages
        if @displayAll
          @status.messages
        else
          [
            @status.messages.find (message) => message.level == @status.level && message.message
          ].compact()

    components:
      Alert: Alert
