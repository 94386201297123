
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import ContextParameter from 'models/context_parameter.coffee'
  import DialogNode from 'models/dialog_node.coffee'
  import KnowledgeSource from 'models/knowledge_source.coffee'
  import WorkflowStep from 'models/workflow_step.coffee'
  import ContextUpdate from 'models/context_update.coffee'
  import Operation from 'models/operation.coffee'

  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Input, Listbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: [ContextUpdate, Operation]
      host: [DialogNode, KnowledgeSource, WorkflowStep]
      deletable:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      contextUpdate: @modelValue
      contextParameter: null
      component: null
      operator: null
      operatorsForSelect: ContextUpdate.OPERATOR_TYPES.map (operator) -> id: operator, name: operator

    watch:
      modelValue: ->
        @contextUpdate = @modelValue

    created: ->
      @setContextParameterAndComponent()
      @operator = @operatorsForSelect.find (op) => op.id == @contextUpdate.operator
      @operator ||= @operatorsForSelect[0]

    methods:
      setContextParameterAndComponent: ->
        @contextParameter = @$root.contextParameterByKey(@contextUpdate.parameterKey)
        @component = @$root.componentByKey(@contextUpdate.parameterKey)
      update: ->
        @$emit('update:modelValue', @contextUpdate)
      updateParameterKey: ->
        @setContextParameterAndComponent()
        @update()
      updateOperator: ->
        @contextUpdate.operator = @operator.id if @operator?.id?
        @update()

    components:
      ContextParameterSelector: ContextParameterSelector
      Input: Input
      Listbox: Listbox
      UniversalInput: UniversalInput
      XIcon: XIcon
