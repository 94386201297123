
  import { Badge } from 'components/generic'

  export default
    props:
      status: Object

    data: ->
      colorPerStatus:
        ERROR: 'red'
        UNKNOWN: 'yellow'
        WARN: 'yellow'
        INFO: 'blue'
        OK: 'green'

    components:
      Badge: Badge
