
  import { Overlay } from 'components/generic'
  import { ExclamationIcon } from '@heroicons/vue/outline'
  import RetentionTable from './retention_table.vue'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    data: ->
      timeSettingsDisabled:
        ranges:
          today: true
          yesterday: true
        resolutions:
          '1month':
            day: true
          '3months':
            day: true
          '6months':
            day: true
          '12months':
            day: true
            week: true

    computed:
      resolution: ->
        @$root.settings.resolution
      duration: ->
        @$root.settings.durationInDays
      retentionAvailable: ->
        return false if @resolution == 'hour'
        return false if @resolution == 'day' && @duration > 31
        return false if @resolution == 'week' && @duration > 200
        true
      rehashedData: ->
        if @data.retention.status == 'ready'
          @data.retention.data
        else
          []

    components:
      ExclamationIcon: ExclamationIcon
      Overlay: Overlay
      RetentionTable: RetentionTable
