import { createApp } from 'vue_shims'
import { alert } from 'helpers'

initTrainingDataConflictsApp = (element) ->
  createApp(
    data: ->
      items: $(element).data('items')
      projectId: $(element).data('project-id')
      stageIds: $(element).data('stage-uuids')
      currentIndex: 0

    computed:
      currentItem: ->
        return null if @items.lenth == 0
        @items[@currentIndex]
      resolvedItems: ->
        @items.filter (item) -> item.resolved
      distanceNumber: ->
        Math.round((@currentItem.distance + Number.EPSILON) * 100) / 100
      distanceClass: ->
        if @currentItem.distance < 1/3
          'danger'
        else if @currentItem.distance >= 2/3
          'success'
        else
          'warning'

    methods:
      utteranceHtml: (trainingDatum) ->
        TrainingDataHelper.utteranceHtml(trainingDatum)
      previous: ->
        @currentIndex = Math.max(0, @currentIndex - 1)
      next: ->
        @currentIndex = Math.min(@currentIndex + 1, @items.length - 1)
      reassign: (index, otherIndex) ->
        conflict = @currentItem
        trainingDatum = @currentItem.interpretations[index]
        otherTrainingDatum = @currentItem.interpretations[otherIndex]
        $.post(
          Routes.reassign_project_training_data_path(@projectId, Globals.stageLevel),
          _method: 'patch'
          training_datum: trainingDatum
          new_game: otherTrainingDatum.game
          new_intent: otherTrainingDatum.intent
          problem_id: conflict.id
          problem_kind: 'conflict'
        ).done(->
          conflict.resolved = true
        ).fail(@handleError)
        null
      handleError: (response) ->
        if response.status == 418
          await alert(response.responseText)
        else
          await alert('Sorry, an internal error occurred.')
      keep: ->
        conflict = @currentItem
        $.post(
          Routes.keep_project_training_data_path(@projectId, Globals.stageLevel),
          _method: 'patch'
          problem_id: conflict.id
          problem_kind: 'conflict'
        ).done(-> conflict.resolved = true)
      retrain: ->
        $.post(Routes.retrain_project_training_data_path(@projectId, Globals.stageLevel), stage_uuids: @stageIds)
        $(@$el).closest('.modal').modal('hide')

    template: '
      <div class="training-data training-data-conflicts">

        <div v-if="currentItem">

          <div class="flex-container stretch-items">
            <template v-for="interpretation, index in currentItem.interpretations.slice(0, 2)">
              <div class="conflict-interpretation">
                <p class="conflict-utterance">
                  <b>Utterance: </b>
                  <span v-html="utteranceHtml(interpretation)"></span>
                </p>
                <p>
                  <b>Intent: </b>
                  <span class="conflict-intent label label-equal label-gray">
                    {{ interpretation.game }}/{{ interpretation.intent }}()
                  </span>
                </p>
                <p>
                  <b>Origin:</b>
                  <span class="text-lowercase">{{ interpretation.origin }}</span>
                </p>
              </div>
              <div v-if="index == 0" class="conflict-resolution flex-container columns center-items center-content">
                <div class="upper-arrow"></div>
                <button v-if="!currentItem.resolved" @click="reassign(0, 1)" class="btn btn-default upper-merge">merge</button>
                <div class="distance-indicator" :class="distanceClass">{{ distanceNumber }}</div>
                <button v-if="!currentItem.resolved" @click="keep" class="btn btn-caution keep">reject</button>
                <button v-if="!currentItem.resolved" @click="reassign(1, 0)" class="btn btn-default lower-merge">merge</button>
                <div v-if="currentItem.resolved">conflict has been resolved</div>
                <div class="lower-arrow"></div>
              </div>
            </template>
          </div>

          <div class="pagination">
            <button @click="previous" class="btn btn-default" :class="{invisible: currentIndex == 0}">←</button>
            <div class="text-normal">{{ currentIndex + 1 }} of {{ items.length }}</div>
            <div
              class="resolved-items label label-equal"
              :class="\'label-\' + (resolvedItems.length == 0 ? \'default\' : \'success\')"
              >
              {{ resolvedItems.length }} resolved
            </div>
            <button @click="next" class="btn btn-default" :class="{invisible: currentIndex == items.length - 1}">→</button>
          </div>

        </div>
        <div v-if="!currentItem" class="text-center text-large">
          There are no training data conflicts at the moment.
          <div class="spacer"></div>
        </div>

        <div class="button-container">
          <button class="btn btn-default auto-margin-right" data-dismiss="modal">Close</button>
          <button class="btn btn-primary" @click="retrain">Close and retrain</button>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'shown.bs.modal', ->
  element = document.getElementById('training-data-conflicts-app')
  initTrainingDataConflictsApp(element) if element?
