Vue.component 'bubble-design',
  props: ['cornerRadius', 'fontsize']

  data: ->
    defaultStyle =
      bubbleFontSize: '15'
      bubbleCornerRadius: '5'
      bubbleBotBackgroundColor: '#E8E8E8'
      bubbleBotFontColor: '#1c1c1c'
      bubbleUserBackgroundColor: '#009ee2'
      bubbleUserFontColor: '#ffffff'
      bubbleAgentBackgroundColor: '#86c400'
      bubbleAgentFontColor: '#ffffff'
      bubbleTeaserBackgroundColor: '#9000e1'
      bubbleTeaserFontColor: '#ffffff'
      bubbleDateBackgroundColor: '#e1f5fd'
      bubbleDateFontColor: '#1c1c1c'
      bubbleTimestampFontColor: '#969696'
      bubbleQuickCornerRadius: '24'
      bubbleQuickBackgroundColor: '#ffffff'
      bubbleQuickFontColor: '#009EE2'
      bubbleQuickHoverBackgroundColor: '#009EE2'
      bubbleQuickHoverFontColor: '#ffffff'
      bubbleQuickShadowColor: '#000000FF'
      bubbleQuickShadowBlur: '3'

    config: state.config
    defaultStyle: defaultStyle
    style: ObjectProcessor.clone(defaultStyle)

  mounted: ->
    configData = @config
    if configData.style
      @style =
        bubbleFontSize: configData.style.bubble_font_size || @style.bubbleFontSize
        bubbleCornerRadius: configData.style.bubble_corner_radius || @style.bubbleCornerRadius
        bubbleBotBackgroundColor: configData.style.bubble_bot_background_color || @style.bubbleBotBackgroundColor
        bubbleBotFontColor: configData.style.bubble_bot_font_color || @style.bubbleBotFontColor
        bubbleUserBackgroundColor: configData.style.bubble_user_background_color || @style.bubbleUserBackgroundColor
        bubbleUserFontColor: configData.style.bubble_user_font_color || @style.bubbleUserFontColor
        bubbleAgentBackgroundColor: configData.style.bubble_agent_background_color || @style.bubbleAgentBackgroundColor
        bubbleAgentFontColor: configData.style.bubble_agent_font_color || @style.bubbleAgentFontColor
        bubbleTeaserBackgroundColor: configData.style.bubble_teaser_background_color || @style.bubbleTeaserBackgroundColor
        bubbleTeaserFontColor: configData.style.bubble_teaser_font_color || @style.bubbleTeaserFontColor
        bubbleDateBackgroundColor: configData.style.bubble_date_background_color || @style.bubbleDateBackgroundColor
        bubbleDateFontColor: configData.style.bubble_date_font_color || @style.bubbleDateFontColor
        bubbleTimestampFontColor: configData.style.bubble_timestamp_font_color || @style.bubbleTimestampFontColor
        bubbleQuickCornerRadius: configData.style.bubble_quick_corner_radius || @style.bubbleQuickCornerRadius
        bubbleQuickBackgroundColor: configData.style.bubble_quick_background_color || @style.bubbleQuickBackgroundColor
        bubbleQuickFontColor: configData.style.bubble_quick_font_color || @style.bubbleQuickFontColor
        bubbleQuickHoverBackgroundColor: configData.style.bubble_quick_hover_background_color || @style.bubbleQuickHoverBackgroundColor
        bubbleQuickHoverFontColor: configData.style.bubble_quick_hover_font_color || @style.bubbleQuickHoverFontColor
        bubbleQuickShadowColor: configData.style.bubble_quick_shadow_color || @style.bubbleQuickShadowColor
        bubbleQuickShadowBlur: configData.style.bubble_quick_shadow_blur || @style.bubbleQuickShadowBlur

  methods:
    resetColor: (color) ->
      @color = color
    bubbleColorChanged: (source) ->
      @style["bubble#{source}BackgroundColor"] != @defaultStyle["bubble#{source}BackgroundColor"] ||
        @style["bubble#{source}FontColor"] != @defaultStyle["bubble#{source}FontColor"]
    resetBubbleColor: (source) ->
      @style["bubble#{source}BackgroundColor"] = @defaultStyle["bubble#{source}BackgroundColor"]
      @style["bubble#{source}FontColor"] = @defaultStyle["bubble#{source}FontColor"]

  template: '
    <div class="bubble-design">
      <div class="form-group row">
        <label for="font-size" class="col-sm-2 col-form-label">
          Font Size
        </label>
        <div class="col-sm-10 flex items-center">
          <div class="icon-image"><img :src="fontsize" /></div>
          <number-input v-model="style.bubbleFontSize" name="umh_channel[config][style][bubble_font_size]" />
          <refresh-button
            :clickable="style.bubbleFontSize != defaultStyle.bubbleFontSize"
            @click="style.bubbleFontSize = defaultStyle.bubbleFontSize"
          />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="corner-radius" class="col-sm-2 col-form-label">
          Corner Radius
        </label>
        <div class="col-sm-10 flex items-center">
          <div class="icon-image"><img :src="cornerRadius" /></div>
          <number-input v-model="style.bubbleCornerRadius" name="umh_channel[config][style][bubble_corner_radius]" />
          <refresh-button
            :clickable="style.bubbleCornerRadius != defaultStyle.bubbleCornerRadius"
            @click="style.bubbleCornerRadius = defaultStyle.bubbleCornerRadius"
          />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Bubble Color
        </label>
        <div class="col-sm-10 flex bubble-color">
          <div>
            <color-picker v-model="style.bubbleBotBackgroundColor" colorpicker="chatbot" property="Chatbot" name="umh_channel[config][style][bubble_bot_background_color]" />
            <color-picker v-model="style.bubbleUserBackgroundColor" colorpicker="user" property="User" name="umh_channel[config][style][bubble_user_background_color]" />
            <color-picker v-model="style.bubbleAgentBackgroundColor" colorpicker="agent" property="Agent" name="umh_channel[config][style][bubble_agent_background_color]" />
            <color-picker v-model="style.bubbleTeaserBackgroundColor" colorpicker="teaser" property="Teaser" name="umh_channel[config][style][bubble_teaser_background_color]" />
          </div>
          <div>
            <color-picker v-model="style.bubbleBotFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_bot_font_color]" />
            <color-picker v-model="style.bubbleUserFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_user_font_color]" />
            <color-picker v-model="style.bubbleAgentFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_agent_font_color]" />
            <color-picker v-model="style.bubbleTeaserFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_teaser_font_color]" />
          </div>
          <div>
            <refresh-button :clickable="bubbleColorChanged(\'Bot\')" @click="resetBubbleColor(\'Bot\')" />
            <refresh-button :clickable="bubbleColorChanged(\'User\')" @click="resetBubbleColor(\'User\')" />
            <refresh-button :clickable="bubbleColorChanged(\'Agent\')" @click="resetBubbleColor(\'Agent\')" />
            <refresh-button :clickable="bubbleColorChanged(\'Teaser\')" @click="resetBubbleColor(\'Teaser\')" />
          </div>
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Date Color
        </label>
        <div class="col-sm-10 flex">
          <color-picker v-model="style.bubbleDateBackgroundColor" colorpicker="background" property="Background" name="umh_channel[config][style][bubble_date_background_color]" />
          <color-picker v-model="style.bubbleDateFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_date_font_color]" />
          <refresh-button :clickable="bubbleColorChanged(\'Date\')" @click="resetBubbleColor(\'Date\')" />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="timestamp" class="col-sm-2 col-form-label">
          Timestamp
        </label>
        <div class="col-sm-10 flex">
          <color-picker v-model="style.bubbleTimestampFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_timestamp_font_color]" />
          <refresh-button
            :clickable="style.bubbleTimestampFontColor != defaultStyle.bubbleTimestampFontColor"
            @click="style.bubbleTimestampFontColor = defaultStyle.bubbleTimestampFontColor"
          />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="quick-reply" class="col-sm-2 col-form-label">
          Quick reply
        </label>
        <div class="col-sm-10">
          <div class="flex">
            <div class="icon-image"><img :src="cornerRadius" /></div>
            <number-input v-model="style.bubbleQuickCornerRadius" name="umh_channel[config][style][bubble_quick_corner_radius]" />
            <refresh-button
              :clickable="style.bubbleQuickCornerRadius != defaultStyle.bubbleQuickCornerRadius"
              @click="style.bubbleQuickCornerRadius = defaultStyle.bubbleQuickCornerRadius"
            />
          </div>
          <br />
          <div class="flex">
            <color-picker v-model="style.bubbleQuickBackgroundColor" colorpicker="background" property="Background" name="umh_channel[config][style][bubble_quick_background_color]" />
            <color-picker v-model="style.bubbleQuickFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_quick_font_color]" />
            <refresh-button :clickable="bubbleColorChanged(\'Quick\')" @click="resetBubbleColor(\'Quick\')" />
          </div>
          <br />
          <div class="flex">
            <color-picker v-model="style.bubbleQuickHoverBackgroundColor" colorpicker="mouseover" property="Mouseover" name="umh_channel[config][style][bubble_quick_hover_background_color]" />
            <color-picker v-model="style.bubbleQuickHoverFontColor" colorpicker="font" property="Font" name="umh_channel[config][style][bubble_quick_hover_font_color]" />
            <refresh-button :clickable="bubbleColorChanged(\'QuickHover\')" @click="resetBubbleColor(\'QuickHover\')" />
          </div>
          <br />
          <div class="flex">
            <color-picker v-model="style.bubbleQuickShadowColor" colorpicker="shadow" property="Shadow" name="umh_channel[config][style][bubble_quick_shadow_color]" />
            <p class="property">Blur</p>
            <number-input v-model="style.bubbleQuickShadowBlur" name="umh_channel[config][style][bubble_quick_shadow_blur]" />
            <refresh-button
              :clickable="style.bubbleQuickShadowColor != defaultStyle.bubbleQuickShadowColor || style.bubbleQuickShadowBlur != defaultStyle.bubbleQuickShadowBlur"
              @click="style.bubbleQuickShadowColor = defaultStyle.bubbleQuickShadowColor; style.bubbleQuickShadowBlur = defaultStyle.bubbleQuickShadowBlur"
            />
          </div>
        </div>
      </div>
    </div>
  '
