
  import BotbuilderApi from 'models/botbuilder_api'
  import InboxMessage from 'models/inbox_message'
  import Project from 'models/project'

  import InitialContextEditor from 'components/channels/initial_context_editor.vue'
  import Message from 'components/inbox3/message.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import Wrapper from './wrapper.vue'
  import { Alert, Button, DocumentationLink, H3, Input, KeyListbox, Label } from 'components/generic'
  import { RefreshIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      question: ''
      environment: @$root.appData.knowledgExplorer?.environment || 'DEV'
      trymeResponse: null
      asking: false
      Project: Project
      RefreshIcon: RefreshIcon

    created: ->
      @$root.appData.knowledgExplorer ||= {
        context: []
        environment: @environment
      }
      if @$route.hash
        @question = decodeURIComponent(@$route.hash[1..])
        @ask()

    watch:
      environment: ->
        @$root.appData.knowledgExplorer.environment = @environment

    methods:
      ask: ->
        return if @asking
        @asking = true
        @$router.push(hash: '#' + encodeURIComponent(@question))
        BotbuilderApi.knowledgeTryme(
          userId: @$root.sessionId
          question:
            templateType: 'text'
            bubbles: [
              attachments: [type: 'Text', text: @question]
            ]
          language: @$root.currentLanguage
          environment: @environment
          context: Object.fromEntries(
            @$root.appData.knowledgExplorer.context.map (ctx) -> [ctx.parameterKey, ctx.value]
          )
        )
          .then (response) =>
            @trymeResponse = Object.assign(
              response,
              message: new InboxMessage(
                origin: 'BOT'
                message: response.message
              )
            )
          .catch =>
            @trymeResponse = null
          .finally =>
            @asking = false
      addContext: ->
        @$root.appData.knowledgExplorer.context.push(parameterKey: '', value: '')
      removeContext: (index) ->
        @$root.appData.knowledgExplorer.context.splice(index, 1)

    components:
      Alert: Alert
      Button: Button
      DocumentationLink: DocumentationLink
      H3: H3
      InitialContextEditor: InitialContextEditor
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      LanguageSelector: LanguageSelector
      Message: Message
      Wrapper: Wrapper
