
  import { Button, Table, TableCell, TableHeadCell, TableHeadRow, TableRow } from 'components/generic'

  export default
    props:
      users: Array

    data: ->
      pageSize = 40

      pageSize: pageSize
      displayCount: pageSize

    watch:
      'users.length': ->
        @displayCount = @pageSize if @users.length == 0

    methods:
      parameterByLabel: (label) ->
        @$root.parameterByLabel(label)
      columnValue: (user, parameterLabel) ->
        switch parameterLabel
          when 'userId', 'firstName', 'lastName', 'lastInteractionTime'
            user[parameterLabel]
          else
            if user.properties?[parameterLabel] == 'true'
              '<span class="fa fa-check-circle"><span>'
            else if user.properties?[parameterLabel] == 'false'
              '<span class="fa fa-times-circle"><span>'
            else
              user.properties?[parameterLabel]
      showMore: ->
        @displayCount += @pageSize

    components:
      Btn: Button
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
