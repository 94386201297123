import GuidGenerator from 'models/guid_generator.coffee'
import SlotDefinition from 'models/slot_definition.coffee'

export default class UtteranceSlot

  constructor: (data = {}, options = {}) ->
    @id = GuidGenerator.newGuid()
    @startPos = 0
    @text = ''
    if options.componentAsLabel
      label = data.definition.slotRole.split('#')[1]
      @definition = new SlotDefinition(Object.assign({label: label}, data.definition))
    else
      @definition = new SlotDefinition(data.definition)
    @update(data)

  update: (data) ->
    @startPos = data.startPos if data.startPos?
    @text = data.text if data.text?
    @definition.update(data.definition) if data.definition?
    this

  Object.defineProperties @prototype,
    export:
      get: ->
        startPos: @startPos
        text: @text
        definition: @definition.export
