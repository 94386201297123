import VueBootstrapDatetimePicker from 'components/vue-bootstrap-datetimepicker.coffee'

Vue.component 'push-messaging-message-editor',
  props: ['message']
  data: ->
    state: state
    languageStates: {}
    datetimePickerConfig:
      format: 'YYYY-MM-DD HH:mm:ss'
      icons:
        time: 'far fa-clock'
        date: 'far fa-calendar'
    currentFilter: null
    audience: null
  computed:
    broadcast: ->
      @state.type == 'BROADCAST'
    activation: ->
      @state.type == 'ACTIVATION'
    availableOperators: ->
      parameter = @state.contextParameters.read.find((parameter) => parameter.label == @currentFilter.field)
      TypeValidator.availableOperators(parameter?.type || 'None')
    unrestrictedDelay: ->
      @state.plan.deliveryOptions?.facebook24plus1 ||
        @state.plan.deliveryOptions?.facebookMessageTag in ['CONFIRMED_EVENT_UPDATE', 'POST_PURCHASE_UPDATE']
    maxDelayHours: ->
      if @unrestrictedDelay then 24 * 366 - 1 else 23
    messageValid: ->
      @message.message.$valid && GameValidator.allValid(@message.contextUpdates)
  watch:
    'message.message':
      handler: ->
        @setLanguageStates(['message'])
      deep: true
    'message.sendAt.mode': ->
      if @message.sendAt.mode == 'absolute'
        Vue.setDefault(@message.sendAt, 'timestamp', moment().startOf('m').add(1, 'd').format('YYYY-MM-DD HH:mm:ss'))
      if @message.sendAt.mode == 'delay'
        Vue.setDefault(@message.sendAt, 'hours', 0)
        Vue.setDefault(@message.sendAt, 'minutes', 0)
      if @message.sendAt.mode == 'relative'
        Vue.setDefault(@message.sendAt, 'contextParameter', '')
        Vue.setDefault(@message.sendAt, 'direction', '-')
        Vue.setDefault(@message.sendAt, 'days', 0)
        Vue.setDefault(@message.sendAt, 'hours', 0)
        Vue.setDefault(@message.sendAt, 'minutes', 0)
    'message.sendAt.days': -> @message.sendAt.days = 0 if @message.sendAt.days < 0
    'message.sendAt.hours': ->
      @message.sendAt.hours = 0 if @message.sendAt.hours < 0
      if @message.sendAt.mode == 'delay'
        @message.sendAt.hours = @maxDelayHours if @message.sendAt.hours > @maxDelayHours
      else
        @message.sendAt.hours = 23 if @message.sendAt.hours > 23
    'message.sendAt.minutes': ->
      @message.sendAt.minutes = 0 if @message.sendAt.minutes < 0
      @message.sendAt.minutes = 59 if @message.sendAt.minutes > 59
  created: ->
    Vue.setDefault(@message, 'message', {})
    Vue.setDefault(@message, 'filters', [])
    Vue.setDefault(@message, 'trigger', {})
    Vue.setDefault(@message.trigger, 'triggerIntentType', 'USER')
    Vue.setDefault(@message.trigger, 'triggerIntent', {})
    Vue.setDefault(@message, 'contextUpdates', [])
    # amend old format
    if typeof @message.sendAt == 'string'
      @message.sendAt = {mode: 'absolute', timestamp: @message.sendAt}
    # set regular defaults
    Vue.setDefault(@message, 'sendAt', {})
    if @activation
      Vue.setDefault(@message.sendAt, 'mode', 'delay')
    if @broadcast
      Vue.setDefault(@message.sendAt, 'mode', 'absolute')
    # fetch audience
    if @broadcast
      if @message.id?
        $.getJSON Routes.get_message_audience_project_push_messaging_path(Globals.projectId, Globals.stageLevel, @state.plan.id, message_id: @message.id), (data) =>
          @audience = data
  mounted: ->
    # ensure correct slider rendering
    $(@$el).registerShownBsCollapseWatcherForSlickSliders()
  methods:
    activate: ->
      @message.status = 'ACTIVE'
    deactivate: ->
      @message.status = 'DRAFT'
    cancel: ->
      @$emit('cancel-message')
    save: ->
      @$emit('save-message', @message)
    setLanguageStates: (selectors) ->
      states = {}
      for languageCode of @state.languages
        for medium in @state.media
          Vue.setDefault(states, languageCode, {})
          states[languageCode][medium] = LanguageValidator.filledIn(
            @message,
            languageCode,
            medium,
            selectors
          )
      @languageStates = states
    # filter-related methods:
    addFilter: ->
      return if @currentFilter?
      @currentFilter =
        mode: 'include'
        field: ''
        operator: '='
        value: ''
    removeFilter: (index) ->
      @message.filters.splice(index, 1)
    saveFilter: (filter) ->
      @message.filters.push(filter)
      @currentFilter = null
    cancelFilter: ->
      @currentFilter = null
  components:
    'date-picker': VueBootstrapDatetimePicker
  template: '
    <div>
      <div class="responsive-grid-container">
        <div class="responsive-grid-item item-300">
          <div class="form-group">
            <label>Message title (internal)</label>
            <input v-model="message.name" type="text" class="form-control">
          </div>

          <div v-if="activation" class="form-group">
            <label>Trigger</label>
            <div v-if="activation" class="sub-form-group">
              Who will trigger?
              <select class="styled-form-field" v-model="message.trigger.triggerIntentType">
                <option :value="side" v-for="side in [\'USER\', \'BOT\']">{{ side.toLowerCase() }}</option>
              </select>
            </div>
            <div v-if="activation" class="sub-form-group">
              <label class="non-bold">Trigger intent</label>
              <payload-editor :payload="message.trigger.triggerIntent" :side="message.trigger.triggerIntentType.toLowerCase()"/>
            </div>
          </div>

          <div v-if="typeof message.sendAt == \'object\'" class="form-group">
            <label v-if="state.plan.frequency == \'ONCE\' || !state.plan.frequency">When should this message be sent?</label>
            <label v-else="state.plan.frequency">When should this message be sent for the first time?</label>
            <div v-if="activation" class="radio no-margin-top">
              <label>
                <input type="radio" v-model="message.sendAt.mode" value="delay">
                immediately or delayed
              </label>
            </div>
            <div v-if="message.sendAt.mode == \'delay\'">
              <div class="sub-form-group">
                <label class="text-light">How long after the trigger should the message be sent?</label>
                <div class="flex-container">
                  <div class="expand" style="width: 0">
                    <div>hours</div>
                    <input v-model="message.sendAt.hours" type="number" min="0" max="23" class="form-control">
                  </div>
                  <div class="expand" style="width: 0">
                    <div>minutes</div>
                    <input v-model="message.sendAt.minutes" type="number" min="0" max="59" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="activation" class="radio no-margin-top">
              <label>
                <input type="radio" v-model="message.sendAt.mode" value="absolute">
                fixed time
              </label>
            </div>
            <div v-if="message.sendAt.mode == \'absolute\'">
              <date-picker v-model="message.sendAt.timestamp" :config="datetimePickerConfig" class="styled-form-field"/>
            </div>
            <div v-if="activation" class="radio">
              <label>
                <input type="radio" v-model="message.sendAt.mode" value="relative">
                relative time
              </label>
            </div>
            <div v-if="message.sendAt.mode == \'relative\'">
              <div class="sub-form-group">
                <label class="text-light">Which context parameter contains the reference point in time?</label>
                <select v-model="message.sendAt.contextParameter" class="form-control">
                  <option v-for="parameter in state.contextParameters.read" :value="parameter.label">{{ parameter.label }}</option>
                </select>
              </div>
              <div class="sub-form-group">
                <label class="text-light">Should the message be sent before or after the reference point?</label>
                <select v-model="message.sendAt.direction" class="form-control">
                  <option value="-">before</option>
                  <option value="+">after</option>
                </select>
              </div>
              <div class="sub-form-group">
                <label class="text-light">How long before/after the reference point should the message be sent?</label>
                <div class="flex-container">
                  <div class="expand" style="width: 0">
                    <div>days</div>
                    <input v-model="message.sendAt.days" type="number" min="0" class="form-control">
                  </div>
                  <div class="expand" style="width: 0">
                    <div>hours</div>
                    <input v-model="message.sendAt.hours" type="number" min="0" max="23" class="form-control">
                  </div>
                  <div class="expand" style="width: 0">
                    <div>minutes</div>
                    <input v-model="message.sendAt.minutes" type="number" min="0" max="59" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Filters</label>
            <p>Would you like to send the message only to a specific segment of your subscribers?</p>
            <div v-for="filter, index in message.filters" class="sub-form-group">
              <div class="btn btn-default btn-pseudo">
                <span class="fa fa-filter"></span>
                {{ $parent.filterLabel(filter) }}
                <div @click="removeFilter(index)" class="btn btn-remove">
                  <span class="fa fa-times-circle text-danger"></span>
                </div>
              </div>
            </div>
            <div v-if="currentFilter" class="flex-container wrap sub-form-group">
              <select v-model="currentFilter.mode" class="styled-form-field input-sm">
                <option value="include">include</option>
                <option value="exclude">exclude</option>
              </select>&nbsp;
              <select v-model="currentFilter.field" class="styled-form-field expand input-sm">
                <option value="" disabled="">select context parameter</option>
                <option v-for="parameter in state.contextParameters.read" :value="parameter.label">{{ parameter.label }}</option>
              </select>&nbsp;
              <select v-model="currentFilter.operator" class="styled-form-field input-sm">
                <option v-for="(text, operator) of availableOperators" :value="operator">
                  {{ text ?  text + \' (\' + operator + \')\' : operator }}
                </option>
              </select>&nbsp;
              <div class="expand">
                <input placeholder="value" class="form-control input-sm" type="text" v-model="currentFilter.value">
              </div>&nbsp;
              <div @click="cancelFilter" class="btn btn-default">cancel</div>&nbsp;
              <div @click="saveFilter(currentFilter)" class="btn btn-primary">save</div>
            </div>
            <div @click="addFilter" :disabled="currentFilter ? true : null" class="btn btn-default">
              <span class="fa fa-filter"></span>
              add filter
            </div>
          </div>

          <div v-if="audience && audience.targetedUsers >= 0 && audience.subscribedUsers >= 0" class="form-group">
            This message would currently reach <b>{{ audience.targetedUsers }}/{{ audience.subscribedUsers }} users</b>.
            <br>
            <span v-if="audience.disregardedUsers >= 0">(the filters were not applicacable for {{ audience.disregardedUsers }} users )<br></span>
            <span v-if="message.status != \'DONE\'">(numbers will be updated after saving the message)</span>
          </div>

          <div class="form-group">
            <label>Status</label>
              <div v-if="message.status == \'DRAFT\'">
                <div class="label label-warning">draft</div>
                <div @click="activate" class="btn btn-default">set active</div>
              </div>
              <div v-if="message.status == \'ACTIVE\'">
                <div class="label label-success">active</div>
                <div @click="deactivate" class="btn btn-default">set draft</div>
              </div>
              <div v-if="message.status == \'DONE\'">
                <div class="label label-default">done</div>
              </div>
          </div>
        </div>

        <div class="responsive-grid-item item-500">
          <div class="panel">
            <div class="panel-body non-first">
              <language-states-and-selection :language-states="languageStates"/>
            </div>
          </div>
          <div class="panel-body non-first">
            <panel-togglable>
              <template v-slot:togglingheader>
                <div class="expand">Bot message</div>
                <div class="check">
                  <span class="fa fa-exclamation-circle text-danger" v-if="!messageValid"></span>
                </div>
              </template>
              <template v-slot:body>
                <div class="form-group">
                  <label>Context update</label>
                  <context-update-list :updates="message.contextUpdates" :context-parameters="[]"/>
                </div>

                <nlg-editor :nlg="message.message"/>
              </template>
            </panel-togglable>
          </div>
        </div>
      </div>
      <div v-if="message.status != \'DONE\'" @click="save" class="btn btn-primary">Save</div>
      <div @click="cancel" class="btn btn-default">Cancel</div>
    </div>
  '
