import GuidGenerator from 'models/guid_generator.coffee'
import Condition from 'models/condition.coffee'
import Target from 'models/dialog_target.coffee'

export default class BranchOption
  constructor: (@host, data) ->
    data ||= {}
    @id = GuidGenerator.newGuid()
    @target = new Target(@host, data.target)
    @conditions = (data.conditions || []).map (conditionData) -> new Condition(conditionData)

  clone: ->
    new BranchOption(@host, @export)

  Object.defineProperties @prototype,
    summary:
      get: ->
        fallback = {header: @target.label}
        return fallback if !@host?
        index = (@host.branches || []).findIndex (op) => op.id == @id
        return fallback if index == -1
        @host.metaInfo?.branchSummaries?[index] || fallback
    export:
      get: ->
        target: @target.export
        conditions: @conditions.map (condition) -> condition.export
