import { createApp } from 'vue_shims'

initSalesforceMappingApp = (element) ->
  createApp(
    data: ->
      config: $(element).data('config')
      contextParameters: $(element).data('context-parameters')
      mappingData: $(element).data('mapping')
      mappingDataJson: '{}'
      mappings: []
      missingFieldsMessage: null

    computed:
      salesforceObjectTypes: ->
        return [] unless @config.salesforceObjects?
        @config.salesforceObjects.map (objectDefinition) -> objectDefinition.objectType
      mandatoryFields: ->
        mandatoryFields = {}
        for objectType in @salesforceObjectTypes
          mandatoryFields[objectType] = Object.keys(@salesforceObjectFields(objectType)).filter (field) =>
            @salesforceObjectFields(objectType)[field].mandatory == 'true'
        mandatoryFields

    created: ->
      @buildMappings()

    methods:
      salesforceObjectFields: (objectType) ->
        return {} unless objectType
        objectTypeDefinition = @config.salesforceObjects.find (otd) -> otd.objectType == objectType
        return {} unless objectTypeDefinition
        objectTypeDefinition.fields.reduce(
          (fields, field) -> Object.assign(fields, field),
          {}
        )
      add: ->
        @mappings.push(@newMapping())
      edit: (index) ->
        @mappings[index].editing = true
      save: (index) ->
        @validate(index)
        return unless @mappings[index].valid
        @mappings[index].editing = false
      remove: (index) ->
        @mappings.splice(index, 1)
      validate: (index) ->
        mapping = @mappings[index]
        mapping.valid = true
        mapping.errors = []
        # validate presence of fields
        if !mapping.mercury.field
          mapping.valid = false
          mapping.errors.push "Mercury field mustn’t be empty!"
        if !mapping.external.object || !mapping.external.field || !@salesforceObjectFields(mapping.external.object)[mapping.external.field]?
          mapping.valid = false
          mapping.errors.push "Salesforce field mustn’t be empty!"
        # validate updateable status of fields
        if mapping.direction in ['in', 'both'] && !mapping.mercury.field.updateable
          mapping.valid = false
          mapping.errors.push "#{mapping.mercury.field.label} cannot be updated!"
        if mapping.direction in ['out', 'both'] && @salesforceObjectFields(mapping.external.object)[mapping.external.field].updateable == 'false'
          mapping.valid = false
          mapping.errors.push "#{mapping.external.object}/#{mapping.external.field} cannot be updated!"
        # TODO: re-enable type checking when we want it
        # validate equality of types
        # mercuryType = mapping.mercury.field.type
        # externalType = @salesforceObjectFields(mapping.external.object)[mapping.external.field].type
        # sortedTypes = [mercuryType, externalType].sort()
        # if mercuryType != externalType && "#{sortedTypes}" != 'Email,String'
        #   mapping.valid = false
        #   mapping.errors.push "The types of #{mapping.mercury.field.label} and #{mapping.external.object}/#{mapping.external.field} don’t match!
        #     (#{mapping.mercury.field.type} and #{@salesforceObjectFields(mapping.external.object)[mapping.external.field].type}, respectively)"
        # validate uniqueness of target fields
        for otherMapping, innerIndex in @mappings
          continue if innerIndex == index
          bothIn = otherMapping.direction in ['both', 'in'] && mapping.direction in ['both', 'in']
          bothOut = otherMapping.direction in ['both', 'out'] && mapping.direction in ['both', 'out']
          mercurySame = JSON.stringify(otherMapping.mercury.field) == JSON.stringify(mapping.mercury.field)
          externalSame = otherMapping.external.object == mapping.external.object && otherMapping.external.field == mapping.external.field
          if bothIn && mercurySame || bothOut && externalSame
            mapping.valid = false
            mapping.errors.push "You cannot map multiple fields to the same target field!"
            break
      missingMandatoryFields: ->
        missingFields = []
        objectTypes = Array.unique(
          @mappings.map((innerMapping) ->
            if innerMapping.direction in ['out', 'both']
              innerMapping.external.object
            else
              null
          ).filter((object) -> object?)
        )
        for objectType in objectTypes
          for field in @mandatoryFields[objectType]
            if !@mappings.find((mapping) -> mapping.external.object == objectType && mapping.external.field == field)
              missingFields.push(object: objectType, field: field)
        missingFields
      newMapping: ->
        mercury:
          object: 'UserProfile'
          field: ''
        external:
          object: ''
          field: ''
        direction: 'both'
        editing: true
        valid: true
        errors: []
      submit: ->
        @validate(index) for mapping, index in @mappings
        missingMandatoryFields = @missingMandatoryFields()
        if @mappings.some((mapping) -> !mapping.valid) || missingMandatoryFields.length > 0
          @missingFieldsMessage = 'The following mandatory fields are missing:<br><ul>' +
            missingMandatoryFields.map((f) -> "<li>#{f.object}.#{f.field}</li>").join('') +
            '</ul>'
          setTimeout(
            => $(@$el).find('#submit-button').prop('disabled', false),
            50
          )
          return
        @buildMappingDataJson()
        Vue.nextTick =>
          @$refs.form.submit()
      buildMappings: ->
        for mercury, external of @mappingData['external->mercury']
          @mappings.push(
            external:
              object: @getObject(external)
              field: @getField(external)
            mercury:
              field: @contextParameters.find (cp) -> cp.label == mercury
            direction: 'in'
            editing: false
            valid: true
            errors: []
          )
        for externalObject, fieldMappings of @mappingData['mercury->external']
          for external, mercury of fieldMappings
            newMapping =
              mercury:
                field: @getField(mercury)
              external:
                object: externalObject
                field: external
              direction: 'out'
              editing: false
              valid: true
              errors: []
            existingMapping = @mappings.find (mapping) => @sameFields(mapping, newMapping)
            if existingMapping?
              existingMapping.direction = 'both'
            else
              @mappings.push(newMapping)
      buildMappingDataJson: ->
        transientMappingData =
          'external->mercury': {}
          'mercury->external': {}
        for mapping in @mappings
          if mapping.direction in ['both', 'in']
            Vue.set(
              transientMappingData['external->mercury'],
              mapping.mercury.field.label,
              "#{mapping.external.object}.#{mapping.external.field}"
            )
          if mapping.direction in ['both', 'out']
            Vue.setDefault(transientMappingData['mercury->external'], mapping.external.object, {})
            Vue.set(
              transientMappingData['mercury->external'][mapping.external.object],
              mapping.external.field,
              mapping.mercury.field.label
            )
        @mappingDataJson = JSON.stringify(transientMappingData)
      getObject: (string) ->
        string.split('.')[0]
      getField: (string) ->
        string.split('.').slice(1).join('.')
      sameFields: (mapping1, mapping2) ->
        # JSON.stringify(mapping1.mercury.field) == JSON.stringify(mapping2.mercury.field) &&
        mapping1.external.object == mapping2.external.object &&
          mapping1.external.field == mapping2.external.field
      availabelDirections: (mapping) ->
        directions =
          both: '↔'
          in: '←'
          out: '→'
        if !mapping.mercury.field.updateable
          Vue.delete(directions, 'in')
          Vue.delete(directions, 'both')
        if @salesforceObjectFields(mapping.external.object)?[mapping.external.field]?.updateable == 'false'
          Vue.delete(directions, 'out')
          Vue.delete(directions, 'both')
        if !(mapping.direction in Object.keys(directions)) && Object.keys(directions).length > 0
          mapping.direction = Object.keys(directions)[0]
        directions
  ).mount(element)

$(window).on 'shown.bs.modal', ->
  element = document.getElementById('salesforce-mapping-app')
  initSalesforceMappingApp(element) if element?
