import VMdEditor from 'v_md_editor'

Vue.component 'teaser-message',
  props: ['defaultLanguage', 'configTeaserMessages', 'contextParameters'],

  data: ->
    state: state
    currentLanguage: @defaultLanguage
    teaserMessages: if @configTeaserMessages instanceof Array then @configTeaserMessages else []
    defaultMessage: ''
    additionalTeaserMessages: []
    buttonPayload:
      payload:
        $valid: false
        game: ''
        intent: ''
        slots: []
      text: ''

  computed:
    currentTeaserMessage: ->
      @teaserMessages.find (t) => t.language == @currentLanguage

  watch:
    currentLanguage: ->
      @setCurrentValues()
    defaultMessage: ->
      if @currentTeaserMessage?
        @currentTeaserMessage.defaultMessage = @defaultMessage
      else
        @pushNewTeaser()
    buttonPayload: -> 
      if @currentTeaserMessage?
        @currentTeaserMessage.defaultButton = @buttonPayload
      else
        @pushNewTeaser()
    additionalTeaserMessages:
      handler: ->
        if @currentTeaserMessage?
          @currentTeaserMessage.additionalTeaserMessages = @additionalTeaserMessages
        else
          @pushNewTeaser()
      deep: true

  mounted: ->
    @setCurrentValues() if @currentTeaserMessage?

  methods:
    setCurrentValues: ->
      @defaultMessage = @currentTeaserMessage.defaultMessage
      @buttonPayload = @currentTeaserMessage.defaultButton
      @additionalTeaserMessages = @currentTeaserMessage.additionalTeaserMessages
    pushNewTeaser: ->
      @teaserMessages.push(
        language: @defaultLanguage
        defaultMessage: @defaultMessage
        defaultButton: @buttonPayload
        additionalTeaserMessages: @additionalTeaserMessages
      )

  components:
    'markdown-editor': VMdEditor

  template: '
    <div class="teaser-message">
      <div class="form-group row flex items-center">
        <label for="teaserMessage" class="col-sm-2 col-form-label">
          Teaser messages
        </label>
        <div class="col-sm-10">
          <dropdown
            :chosen="state.languages[currentLanguage]"
            align="left"
            class="filter-element"
            >
            <li v-for="(label, value) in state.languages">
              <a @click="currentLanguage = value">{{ label }}</a>
            </li>
          </dropdown>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <markdown-editor
            v-model="defaultMessage"
            placeholder="Type teaser message ..."
            left-toolbar=""
            right-toolbar=""
            :codemirror-config="{lineNumbers: false, scrollbarStyle: \'simple\', extraKeys: {Tab: false}}"
            height="100px"
          />

          <teaser-payload-editor :payload="buttonPayload"/>
        </div>
      </div>

      <additional-teaser-message-list
        :item-list="additionalTeaserMessages"
        :contextParameters="contextParameters"
      />
    </div>
  '
