
  import * as htmlToImage from 'html-to-image'
  import StatusOverlay from './status_overlay.vue'
  import { ActionMenu } from 'components/generic'
  import { DownloadIcon } from '@heroicons/vue/outline'

  export default
    props:
      data: Object
      resolution: String
      labels: Array
      statusObject:
        type: Object
        default: {}

    data: ->
      actionMenuItems: [
        {icon: DownloadIcon, label: 'Download data', method: @downloadData},
        {icon: DownloadIcon, label: 'Download as image', method: @downloadImage},
      ]

    computed:
      rehashedData: ->
        if @data.length == 0
          Array.apply(null, Array(@labels.length)).map =>
            Array.apply(null, Array(@labels.length)).map => {}
        else
          @data

    methods:
      percentLabel: (value) ->
        return '&nbsp;' if !value?
        (value * 100).toFixed(2) + '%'
      downloadData: ->
        rows = [Array.from(@$refs.table.querySelectorAll('th'))[0..-2].map (el) -> el.textContent]
        @data.forEach (row, rowIndex) =>
          rows.push([
            @labels[rowIndex],
            row.map (col, colIndex) =>
              if colIndex < row.length - rowIndex then @percentLabel(col.rate) else ''
          ])
          rows.push([
            @labels[rowIndex],
            row.map (col, colIndex) =>
              if colIndex < row.length - rowIndex then col.count else ''
          ])
        csvString = rows.map((row) -> row.join(',')).join("\n")
        @download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), 'Retention.csv')
      downloadImage: ->
        setTimeout =>
          htmlToImage.toPng(@$refs.table).then (pngString) =>
            @download(pngString, "#{@title}.png")
        , 500
      download: (fileString, fileName) ->
        a = document.createElement('a')
        a.href = fileString
        a.download = fileName
        a.click()
        a.remove()

    components:
      ActionMenu: ActionMenu
      DownloadIcon: DownloadIcon
      StatusOverlay: StatusOverlay
