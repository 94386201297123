Vue.component 'refresh-button',
  props:
    clickable: Boolean,

  template: '
    <div class="refresh-button">
      <div v-if="clickable" class="clickable" @click="$emit(\'click\')"></div>
      <div v-if="!clickable" class="unclickable"></div>
    </div>
  '
