Vue.component 'opt-in-game',
  props: ['config']

  data: ->
    state: state
    gameValidator: GameValidator
    intentKeys:
      intro: 'Intro'
      rejection: 'Response to rejection'
    channelActivations: {}
    softOptIn: @config.optInType == 'soft'

  computed:
    askForOptInValid: ->
      GameValidator.textField(@config.askForOptIn, @state) &&
        GameValidator.textField(@config.yes, @state) &&
        GameValidator.textField(@config.no, @state)

  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'askForOptIn',
            'yes',
            'no',
            'intro.nlg',
            'rejection.nlg',
          ]
        )
      deep: true
    softOptIn: ->
      @config.optInType = if @softOptIn then 'soft' else 'hard'
    channelActivations:
      handler: ->
        Vue.set(@config, 'notRequiredBy', [])
        for channel, active of @channelActivations
          @config.notRequiredBy.push(channel) if !active
      deep: true

  created: ->
    # amend old intent format
    Vue.set(@config, 'startIntent', {}) if typeof @config.startIntent == 'string'
    # amend old intro format
    if !@config.intro?
      Vue.set(@config, 'intro', @config.askForOptIn)
      Vue.delete(@config, 'askForOptIn')
    # set defautls
    Vue.setDefault(@config, 'notRequiredBy', [])
    Vue.setDefault(@config, 'optInType', 'hard')
    Vue.setDefault(@config, 'startIntent', {})
    Vue.setDefault(@config, 'askForOptIn', {})
    Vue.setDefault(@config, 'yes', {})
    Vue.setDefault(@config, 'no', {})
    for intentKey of @intentKeys
      Vue.setDefault(@config, intentKey, {})
    for channelType in @state.channelTypes
      Vue.set @channelActivations, channelType, if channelType in @config.notRequiredBy
        false
      else
        true
    @softOptIn = @config.optInType == 'soft'

  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>
          Opt-in settings
          <info-button info-key="opt_in"/>
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <label>Use opt-in in these channel types:
            <div class="text-normal">(will be activated for newly added types by default)</div>
          </label>
          <div v-if="state.channelTypes.length == 0">
            &lt;no channel available&gt;
          </div>
          <div v-else v-for="channelType in state.channelTypes" class="sub-form-group">
            <label class="sliding-switch non-bold">
              <input type="checkbox" v-model="channelActivations[channelType]">
              <span class="slider"></span>
              <span class="switch-label-right">{{ channelType }}</span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label class="block">Hard or soft opt-in</label>
          <label class="sliding-switch non-bold">
            <input type="checkbox" v-model="softOptIn">
            <span class="slider"></span>
            <span class="switch-label-right">{{ config.optInType }}</span>
          </label>
        </div>
        <div class="form-group">
          <label>
            After a successful opt-in trigger this intent:&ensp;
            <span class="fa fa-exclamation-circle text-danger" v-if="!config.startIntent.$valid"></span>
          </label>
          <payload-editor :payload="config.startIntent"/>
        </div>
      </div>

      <div class="panel-heading">
        <h3>Opt-in messages</h3>
      </div>
      <div class="panel-body">
        <div class="panel-group" id="messages-accordion">
          <panel-togglable parent-selector="#messages-accordion">
            <template v-slot:togglingheader>
              <div class="expand">
                Intro
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!config.intro.nlg.$valid"></span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="config.intro.nlg"/>
            </template>
          </panel-togglable>

          <panel-togglable parent-selector="#messages-accordion">
            <template v-slot:togglingheader>
              <div class="expand">
                Ask for opt-in
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!askForOptInValid"></span>
              </div>
            </template>
            <template v-slot:body>
              <div class="form-group" :class="{\'has-error\': !gameValidator.textField(config.askForOptIn, state)}">
                <label class="non-bold">Ask for opt-in</label>
                <multilang-field :dictionary="config.askForOptIn" class="form-control"/>
              </div>
              <div class="form-group" :class="{\'has-error\': !gameValidator.textField(config.yes, state)}">
                <label class="non-bold">Answer “yes”</label>
                <multilang-field :dictionary="config.yes" class="form-control"/>
              </div>
              <div class="form-group" :class="{\'has-error\': !gameValidator.textField(config.no, state)}">
                <label class="non-bold">Answer “no”</label>
                <multilang-field :dictionary="config.no" class="form-control"/>
              </div>
            </template>
          </panel-togglable>

          <panel-togglable parent-selector="#messages-accordion">
            <template v-slot:togglingheader>
              <div class="expand">
                Response to rejection
              </div>
              <div class="check">
                <span class="fa fa-exclamation-circle text-danger" v-if="!config.rejection.nlg.$valid"></span>
              </div>
            </template>
            <template v-slot:body>
              <nlg-editor :nlg="config.rejection.nlg"/>
            </template>
          </panel-togglable>
        </div>
      </div>
    </div>
  '
