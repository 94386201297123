
  import Label from './label.vue'
  import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: [String, Number]
      items: Array
      label: String
      helpText: String
      errorText: String
      keyAttribute:
        type: String
        default: 'key'
      labelAttribute:
        type: String
        default: 'label'
      labelFunction:
        type: Function
        default: null
      disabled:
        type: Boolean
        default: false
      placeholder:
        type: String
        default: null
      size:
        type: String
        default: 'md'
      hGroupPosition:
        type: String
        default: 'none'
      align:
        type: String
        default: 'left'

    data: ->
      value: @modelValue
      selectedItem: null

    computed:
      supplementedCollection: ->
        if @items.map((item) => item[@keyAttribute]).includes(@selectedItem[@keyAttribute])
          @items
        else
          [@selectedItem].concat(@items)
      borderClasses: ->
        additionalClasses = switch @hGroupPosition
          when 'first' then ['border-l border-t border-b rounded-l-md']
          when 'mid' then ['border-l border-t border-b']
          when 'last' then ['border rounded-r-md']
          else ['border rounded-md']
        ['border-gray-300 shadow-sm focus:ring-1 focus:ring-MercuryBlue-500 focus:border-MercuryBlue-500'].concat(additionalClasses)
      paddingClasses: ->
        switch @size
          when 'sm'
            'pl-3 pr-10 py-1'
          else
            'pl-3 pr-10 py-2'
      buttonClasses: ->
        ['block bg-white relative w-full text-left cursor-pointer focus:outline-none sm:text-sm disabled:bg-gray-50']
          .concat(@borderClasses).concat(@paddingClasses)
      buttonLabel: ->
        @itemLabel(@selectedItem) || @placeholder
      buttonLabelClass: ->
        if !@selectedItem? || !@selectedItem[@keyAttribute]
          'text-gray-400'
        else
          ''

    watch:
      modelValue: ->
        @value = @modelValue
        @setSelectedItem()

    created: ->
      @setSelectedItem()

    methods:
      setSelectedItem: ->
        selectedItem = @items.find (item) => !item[@keyAttribute]? && !@modelValue? || item[@keyAttribute] == @modelValue
        selectedItem ||= "#{@keyAttribute}": @modelValue
        @selectedItem = selectedItem
      itemSelected: (item) ->
        @value = item[@keyAttribute]
        @$emit('update:modelValue', @value)
        @$emit('selected', item)
      itemLabel: (item) ->
        return '' if !item?
        if @labelFunction
          @labelFunction(item)
        else
          item[@labelAttribute] || item[@keyAttribute]
      open: ->
        @$refs.button.$el.click()
      focus: ->
        @open()

    components:
      Label: Label,
      Listbox: Listbox,
      ListboxButton: ListboxButton,
      ListboxOption: ListboxOption,
      ListboxOptions: ListboxOptions,
      CheckIcon: CheckIcon,
      SelectorIcon: SelectorIcon,
