
  import InstagramLogo from 'images/logo_instagram.png'
  import WebLogo from 'images/logo_web.png'
  import WhatsappLogo from 'images/logo_whatsapp.png'
  import { Input } from 'components/generic'

  export default
    props:
      modelValue: Object

    data: ->
      channel = @modelValue
      channel: channel
      stageIsSet: channel.stage_id?
      logo: switch channel.type
        when 'INSTAGRAM' then InstagramLogo
        when 'WEB' then WebLogo
        when 'WHATSAPP' then WhatsappLogo
      stageLevel: Globals.stageLevel

    computed:
      connectedStage: ->
        @$root.stages.find (stage) => stage.uuid == @channel.stage_id

    watch:
      modelValue: ->
        deep: true
        handler: -> @channel = @modelValue
      channel: ->
        deep: true
        handler: -> @$emit('update:modelValue', @channel)

    components:
      Input: Input
