
  import Label from './label.vue'

  export default
    props:
      modelValue: String
      label: String
      placeholder: String
      disabled:
        type: Boolean
        default: false
      maxLength:
        type: Number
        default: null
      counter:
        type: Boolean
        default: false
      height:
        type: Number
        default: 28

    data: ->
      value: @modelValue

    computed:
      heightClass: ->
        {
          28: 'h-28'
          64: 'h-64'
        }[@height]

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      emit: (e) ->
        @$emit('update:modelValue', e.target.value)
      focus: ->
        @$refs.textarea.focus()

    components:
      Label: Label
