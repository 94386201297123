
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import ContextParameterEditor from './context_parameter_editor.vue'

  export default
    props:
      conversation: Conversation

    computed:
      primaryUserParameters: ->
        ['user.firstName', 'user.lastName', 'user.email', 'user.phone']
      otherUserParameters: ->
        keys = (
          if @$root.contextParameters.length > 0
            @$root.contextParameters.map (param) -> param.key
          else
            Object.keys(@conversation.context)
        ).filter (key) =>
          key[0..3] == 'user' && !@primaryUserParameters.includes(key)
        keys.sort (a, b) =>
          if @conversation.context[a] && @conversation.context[a]
            0
          else if @conversation.context[a]
            -1
          else if @conversation.context[b]
            1
          else
            0
        keys
      nonUserParameters: ->
        return [] if @$root.contextParameters.length == 0
        @$root.contextParameters
          .map (cp) =>
            return if cp.key[0..3] == 'user'
            return if !cp.settings.displayInInbox
            cp.key
          .filter (key) -> key?
      fixedParams: ->
        [
          label: @conversation.channelType.toLowerCase().capitalize() + ' ID'
          value: @conversation.nativeUserId
        ,
          label: t('inbox.conversations.firstSeen')
          value: @conversation.timeFirstSeenFormatted
        ,
          label: t('inbox.conversations.lastSeen')
          value: @conversation.timeLastSeenFormatted
        ,
          label: t('inbox.conversations.totalMessages')
          value: @conversation.statistics?.messagesInbound
        ]

    components:
      ContextParameterEditor: ContextParameterEditor
