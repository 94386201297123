
  import Message from 'models/message.coffee'

  import ButtonEditor from './button_editor.vue'
  import ButtonMover from './button_mover.vue'
  import TextAttachmentEditor from './attachment_editor.vue'
  import { Button } from 'components/generic'

  export default
    props:
      modelValue: Message

    emits: ['update:modelValue']

    data: ->
      message: @modelValue

    computed:
      buttonAttachments: ->
        @message.bubbles[0].attachments[1..]
      mayAddButton: ->
        @message.bubbles[0].mayAddButton
      mayDeleteButton: ->
        @buttonAttachments.length > 1

    watch:
      modelValue: ->
        @message = @modelValue

    methods:
      addButton: ->
        @message.bubbles[0].addButton()
      removeAttachment: (attachment) ->
        @message.bubbles[0].removeAttachment(attachment.id)
        @update()
      update: ->
        @$emit('update:modelValue', @message)
      focus: ->
        @$refs.textEditor.focus()

    components:
      Button: Button
      ButtonEditor: ButtonEditor
      ButtonMover: ButtonMover
      TextAttachmentEditor: TextAttachmentEditor
