# taken from https://github.com/staskjs/vue-slick

Vue.component 'slick',
  props:
    options:
      type: Object
      default: -> {}

  data: ->
    id: GuidGenerator.newGuid()

  computed:
    sliderElement: ->
      $(@$el)

  mounted: ->
    @create()

  unmounted: ->
    @sliderElement.slick('unslick')

  methods:
    create: (additionalOptions = {}) ->
      @sliderElement.on('afterChange', @onAfterChange)
      @sliderElement.on('beforeChange', @onBeforeChange)
      @sliderElement.on('breakpoint', @onBreakpoint)
      @sliderElement.on('destroy', @onDestroy)
      @sliderElement.on('edge', @onEdge)
      @sliderElement.on('init', @onInit)
      @sliderElement.on('reInit', @onReInit)
      @sliderElement.on('setPosition', @onSetPosition)
      @sliderElement.on('swipe', @onSwipe)
      @sliderElement.on('lazyLoaded', @onLazyLoaded)
      @sliderElement.on('lazyLoadError', @onLazyLoadError)
      @sliderElement.slick(Object.assign({}, @options, additionalOptions))
    destroy: ->
      @sliderElement.off('afterChange', @onAfterChange)
      @sliderElement.off('beforeChange', @onBeforeChange)
      @sliderElement.off('breakpoint', @onBreakpoint)
      @sliderElement.off('destroy', @onDestroy)
      @sliderElement.off('edge', @onEdge)
      @sliderElement.off('init', @onInit)
      @sliderElement.off('reInit', @onReInit)
      @sliderElement.off('setPosition', @onSetPosition)
      @sliderElement.off('swipe', @onSwipe)
      @sliderElement.off('lazyLoaded', @onLazyLoaded)
      @sliderElement.off('lazyLoadError', @onLazyLoadError)
      @sliderElement.slick('unslick')
    reSlick: ->
      @destroy()
      @create()
    next: ->
      @sliderElement.slick('slickNext')
    prev: ->
      @sliderElement.slick('slickPrev')
    pause: ->
      @sliderElement.slick('slickPause')
    play: ->
      @sliderElement.slick('slickPlay')
    goTo: (index, dontAnimate) ->
      @sliderElement.slick('slickGoTo', index, dontAnimate)
    currentSlide: ->
      @sliderElement.slick('slickCurrentSlide')
    add: (element, index, addBefore) ->
      @sliderElement.slick('slickAdd', element, index, addBefore)
    remove: (index, removeBefore) ->
      @sliderElement.slick('slickRemove', index, removeBefore)
    filter: (filterData) ->
      @sliderElement.slick('slickFilter', filterData)
    unfilter: ->
      @sliderElement.slick('slickUnfilter')
    getOption: (option) ->
      @sliderElement.slick('slickGetOption', option)
    setOption: (option, value, refresh) ->
      @sliderElement.slick('slickSetOption', option, value, refresh)
    setPosition: ->
      @sliderElement.slick('setPosition')
    # Events
    onAfterChange: (event, slick, currentSlide) ->
      @$emit('after-change', event, slick, currentSlide)
    onBeforeChange: (event, slick, currentSlide, nextSlide) ->
      @$emit('before-change', event, slick, currentSlide, nextSlide)
    onBreakpoint: (event, slick, breakpoint) ->
      @$emit('breakpoint', event, slick, breakpoint)
    onDestroy: (event, slick) ->
      @$emit('destroy', event, slick)
    onEdge: (event, slick, direction) ->
      @$emit('edge', event, slick, direction)
    onInit: (event, slick) ->
      @$emit('init', event, slick)
    onReInit: (event, slick) ->
      @$emit('re-init', event, slick)
    onSetPosition: (event, slick) ->
      @$emit('set-position', event, slick)
    onSwipe: (event, slick, direction) ->
      @$emit('swipe', event, slick, direction)
    onLazyLoaded: (event, slick, image, imageSource) ->
      @$emit('lazy-loaded', event, slick, image, imageSource)
    onLazyLoadError: (event, slick, image, imageSource) ->
      @$emit('lazy-load-error', event, slick, image, imageSource)

  template: '
    <div>
      <slot></slot>
    </div>
  '
