Vue.component 'callbacks-editor',
  props: ['callbacks']
  methods:
    add: ->
      @callbacks.push({})
    remove: (index) ->
      @callbacks.splice(index, 1)
  template: '
    <div>
      <div v-for="(callback, index) in callbacks" :key="callback.$key" class="panel panel-bordered">
        <callback-editor
          @remove-callback="remove"
          :callback="callback"
          :index="index"
          class="panel-body"
          />
      </div>
      <plus-button label="add callback" :method="add"/>
    </div>
  '
