
  export default
    props:
      open: Boolean
      closeFunction: Function

    watch:
      open: ->
        @$emit('opened') if @open

    methods:
      close: ->
        @closeFunction()
