import BotbuilderApi from 'models/botbuilder_api'
import WorkflowParameterMapping from 'models/workflow_parameter_mapping'
import WorkflowBotInput from 'models/workflow_bot_input.coffee'
import WorkflowBotOutput from 'models/workflow_bot_output.coffee'

export default class WorkflowTrigger
  @TYPES =
    Bot:
      label: 'Bot'
      attributes:
        botInput: -> []
        botOutput: -> []
    InboundApi:
      label: 'Inbound integration'
      attributes:
        integrationId: -> ''
        outputMapping: -> []

  constructor: (@workflow, data = {}) ->
    @setType(data.type || 'Bot')
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @attributeKeys.forEach (attribute) =>
      if attribute == 'botInput' && data.botInput?
        @botInput = data.botInput.map (botInputData) => new WorkflowBotInput(@, botInputData)
      else if attribute == 'botOutput' && data.botOutput?
        @botOutput = data.botOutput.map (botOutputData) => new WorkflowBotOutput(@, botOutputData)
      else if attribute == 'outputMapping' && data.outputMapping?
        @outputMapping = data.outputMapping.map (outputMappingData) => new WorkflowParameterMapping(outputMappingData)
      else
        @[attribute] = data[attribute] if data[attribute]?

  setType: (type) ->
    @type = type
    Object.entries(@attributes).forEach ([attribute, defaultValueMethod]) =>
      @[attribute] = defaultValueMethod()

  addInput: ->
    @botInput.push(new WorkflowBotInput(@))

  addOutput: ->
    @botOutput.push(new WorkflowBotOutput(@))

  getVariables: ->
    BotbuilderApi.getWorkflowVariables(key: 'output', workflowId: @workflow.id)

  Object.defineProperties @prototype,
    attributes:
      get: ->
        WorkflowTrigger.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    label:
      get: ->
        WorkflowTrigger.TYPES[@type]?.label || @type
    export:
      get: ->
        obj =
          type: @type
        @attributeKeys.forEach (attribute) =>
          if ['botInput', 'botOutput'].includes(attribute)
            obj[attribute] = @[attribute].map (object) -> object.export
          else if attribute == 'outputMapping'
            obj[attribute] = @[attribute].map (mapping) -> mapping.export
          else
            obj[attribute] = @[attribute]
        obj
