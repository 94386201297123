
  import VariableSelector from 'components/botbuilder/inputs/variable_selector.vue'
  import { Input, KeyListbox, Listbox } from 'components/generic'
  import { PlusCircleIcon, XCircleIcon } from '@heroicons/vue/outline'

  keyForLastActiveModule = '!--lastActiveModule--!'

  export default
    props:
      condition: Object
      index: Number
      count: Number
      lastGameConditionPresent:
        type: Boolean
        default: false
      editing:
        type: Boolean
        default: false

    data: ->
      selectedParameter: null

    computed:
      sortedModules: ->
        @$root.currentBot?.config.allModules.sortByString('label') || []
      fixedItemsForContextParameterSelection: ->
        if @lastGameConditionPresent && !@isLastGameCondition
          []
        else
          [reference: keyForLastActiveModule, label: 'last module']
      isLastGameCondition: ->
        @condition.parameter == keyForLastActiveModule
      type: ->
        return undefined if @isLastGameCondition
        @selectedParameter?.type || 'Boolean'
      availableOperators: ->
        operatorMap = TypeValidator.availableOperators(@type)
        Object.keys(operatorMap).map (operator) ->
          {id: operator, name: "#{operatorMap[operator]} (#{operator})"}
      selectedOperator: ->
        operator = @availableOperators.find (operator) => @condition.operator == operator.id
        operator || {id: @condition.operator, name: @condition.operator}
      cellClasses: ->
        if @editing then 'font-medium text-gray-900' else 'font-base text-gray-500'

    watch:
      'condition.parameter': (newParameter, oldParameter) ->
        if newParameter == keyForLastActiveModule
          @condition.lastActiveGame = ''
          delete @condition.operator
          delete @condition.value
        else if oldParameter == keyForLastActiveModule
          @condition.operator = @availableOperators[0]?.id
          @condition.value = ''
          delete @condition.lastActiveGame
        else
          operator = @availableOperators.find (operator) => @condition.operator == operator.id
          @condition.operator = operator?.id || @availableOperators[0]?.id

    created: ->
      Vue.setDefault(@condition, '$key', GuidGenerator.newGuid())
      if @condition.lastActiveGame?
        @condition.parameter = keyForLastActiveModule
      else
        Vue.setDefault(@condition, 'parameter', '')
        Vue.setDefault(@condition, 'operator', @availableOperators[0]?.id)
        Vue.setDefault(@condition, 'value', '')

    methods:
      setOperator: (operator) ->
        @condition.operator = operator.id
      addCondition: ->
        @$emit('add-condition')
      removeCondition: ->
        @$emit('remove-condition', @index)

    components:
      Listbox: Listbox
      Input: Input
      KeyListbox: KeyListbox
      PlusCircleIcon: PlusCircleIcon
      VariableSelector: VariableSelector
      XCircleIcon: XCircleIcon
