
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappChannel from 'models/whatsapp_channel.coffee'
  import WhatsappTemplate from 'models/whatsapp_template.coffee'

  import { alert, confirm } from 'helpers'
  import { ActionMenu, Badge, TableCell, TableRow } from 'components/generic'
  import { DuplicateIcon, TranslateIcon, XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      template: Object
      channel: WhatsappChannel

    emits: ['edit', 'remove']

    data: ->
      actionMenuItems: [
        {icon: TranslateIcon, label: 'New language', method: @newLanguage},
        {icon: DuplicateIcon, label: 'Clone', method: @clone},
        {icon: XCircleIcon, label: 'Delete', method: @deleteTemplate, hr: true},
      ]

    computed:
      badgeColor: ->
        switch @template.status.toUpperCase()
          when 'APPROVED' then 'green'
          when 'REJECTED', 'DELETED' then 'red'
          else 'yellow'

    methods:
      newLanguage: ->
        @$emit(
          'edit'
          new WhatsappTemplate(ObjectProcessor.cloneWithout(@template, ['language']))
        )
      clone: ->
        @$emit(
          'edit'
          new WhatsappTemplate(ObjectProcessor.cloneWithout(@template, ['name']))
        )
      deleteTemplate: ->
        return unless await confirm("Do you really want to delete template “#{@template.name}”?")
        PlatformApi.Webapp.deleteWabaTemplate(@channel.nativeId, @template.id)
          .then => @$emit('remove')
          .catch (error) -> await alert("Sorry, template could not be deleted.\nError message:\n" + error?.message)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      TableCell: TableCell
      TableRow: TableRow
