
  import InstagramChannel from 'models/instagram_channel.coffee'

  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { Button, Input, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: InstagramChannel

    data: ->
      channel: @modelValue
      getStartedTarget: null
      PlusIcon: PlusIcon

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      addIceBreakerItem: ->
        @channel.defaultIceBreaker.addItem()
      removeIceBreakerItem: (index) ->
        @channel.defaultIceBreaker.items.splice(index, 1)
        @update()

    components:
      Button: Button
      Input: Input
      Label: Label
      TargetEditor: TargetEditor
      XIcon: XIcon
