import ParameterComponent from 'models/parameter_component.coffee'

export default class InternalParameter
  constructor: (data = {}) ->
    @key = data.key
    @_label = data.label
    @category = data.category
    @collectionType = data.collectionType
    @valueType = data.valueType
    @entitySchemaKey = data.entitySchemaKey
    if data.components
      @components = data.components.map (componentData) => new ParameterComponent(@, componentData)

  Object.defineProperties @prototype,
    computedCategory:
      get: -> @category
    reference:
      get: -> @key
    label:
      get: -> @_label || @key
