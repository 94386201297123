
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Integration from 'models/integration.coffee'

  import InboundEditor from './inbound_editor.vue'
  import OutboundEditor from './outbound_editor.vue'
  import { BackLink, Button, DocumentationLink, Input, Label, PageHeader, Panel, StatusAlert } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      integration: new Integration()
      editing: false
      debounceTimeout: null
      PencilIcon: PencilIcon

    watch:
      '$route.params.integrationId': ->
        @setIntegration() if @$route.params.integrationId?

    created: ->
      @setIntegration()

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setHeight: ->
        return if !@$refs.mainPanel
        top = @$refs.mainPanel.$el.getBoundingClientRect().top
        @$refs.mainPanel.$el.style.height = window.innerHeight - top + 'px'
      setIntegration: ->
        if @$route.params.integrationId == @integration.id
          return
        BotbuilderApi.getIntegration(@$route.params.integrationId)
          .then (integration) =>
            @integration = integration
            if @integration.type == 'InboundApi'
              @integration.inspect()
            window.breadcrumbs.append(text: @integration.label)
            @$nextTick(@setHeight)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @integration.save()), 1000)

    components:
      BackLink: BackLink
      Button: Button
      DocumentationLink: DocumentationLink
      InboundEditor: InboundEditor
      Input: Input
      Label: Label
      OutboundEditor: OutboundEditor
      PageHeader: PageHeader
      Panel: Panel
      StatusAlert: StatusAlert
