import { confirm } from 'helpers'

Vue.component 'message-editor',
  props: ['message', 'index', 'count', 'medium', 'autogeneratedQuickrepliesEnabled', 'inSearchAttribute']

  data: ->
    state: state
    messageState:
      template: @message.template
      editing: false
      pristine: false
    templates:
      text:
        type: 'Bubble'
        attachments: [
          @newText()
        ]
      image:
        type: 'Bubble'
        attachments: [
          type: 'Image'
          path: ''
        ]
      video:
        type: 'Bubble'
        attachments: [
          type: 'Video'
          path: ''
        ]
      button:
        type: 'Bubble'
        attachments: [
          @newText()
        ,
          @newButton()
        ]
      card:
        type: 'Card'
        bubbles: [
          @newBubble()
        ]
      gallery:
        type: 'Selection'
        bubbles: [
          @newBubble()
        ]
      result:
        type: 'SelectionTemplate'
        resultBubble: @newBubble()
        moreBubble: @newBubble()
        numberOfSlides: 5

  computed:
    showError: ->
      !@messageState.pristine && !@message.$valid
    bubblesWithoutHeroButton: ->
      return @message.bubbles if @message.template != 'card'
      lastBubble = @message.bubbles[-1..][0]
      return @message.bubbles if lastBubble.attachments.length != 1 || lastBubble.attachments[0].type != 'Button'
      @message.bubbles[0..-2]
    canHaveQuickreplies: ->
      @message.template in ['text', 'image', 'video']
    quickreplies: ->
      if @canHaveQuickreplies
        @message.attachments.filter (attachment) -> attachment.type == 'Button'
      else
        []

  created: ->
    Vue.setDefault(@message, 'type', 'Bubble')
    Vue.setDefault(@message, 'delay', 0)
    Vue.setDefault(@message, '$key', GuidGenerator.newGuid())
    # set type dependent defaults
    if @message.type == 'Bubble'
      Vue.setDefault(@message, 'attachments', [])
    else if @message.type in ['Card', 'Selection']
      Vue.setDefault(@message, 'bubbles', [])
      if @message.bubbles.length == 0
        @message.bubbles.push({})
    else if @message.type == 'SelectionTemplate'
      Vue.setDefault(@message, 'resultBubble', {})
      Vue.setDefault(@message, 'moreBubble', {})
      Vue.setDefault(@message, 'numberOfSlides', 5)
    # remove attachments depending on medium
    @removeNonTextAttachments()
    # validate
    @validate()

  watch:
    message:
      handler: ->
        if @message.template == 'button' && @message.attachments.filter((a) -> a.type == 'Button').length == 0
          @message.attachments.push(@newButton())
        @validate()
      deep: true
    'message.template': ->
      @messageState.template = @message.template
  methods:
    validate: ->
      Vue.set @message, '$valid',
        if @message.type == 'Bubble'
          @message.$valid
        else if @message.type in ['Card', 'Selection']
          @message.bubbles?.length > 0 &&
            GameValidator.allValid(@message.bubbles)
        else if @message.type == 'SelectionTemplate'
          @message.resultBubble? && @message.moreBubble? &&
            GameValidator.allValid([@message.resultBubble])
    addQuickreply: ->
      return unless @message.type == 'Bubble'
      @message.attachments.push(type: 'Button', buttonType: 'QuickReply', isNew: true, '$key': GuidGenerator.newGuid())
    removeQuickreply: (key) ->
      return unless @message.type == 'Bubble'
      @message.attachments = @message.attachments.filter (attachment) -> attachment.$key != key
    addBubble: (index) ->
      return unless @message.bubbles?
      @message.bubbles.splice(
        index + 1,
        0,
        @newBubble()
      )
      @removeNonTextAttachments()
    removeBubble: ($key) ->
      @message.bubbles = @message.bubbles.filter (bubble) => bubble.$key != $key
    remove: ->
      return unless await confirm('Do you really want delete this message?')
      @$emit('remove-message', @index)
    pushUp: ->
      @$emit('push-up-message', @index)
    pushDown: ->
      @$emit('push-down-message', @index)
    clone: ->
      @$emit('clone-message', @index)
    setTemplate: (template) ->
      Vue.set(@message, 'template', template)
      Vue.set(@message, 'delay', 0)
      for key, value of @templates[template]
        Vue.set(@message, key, value)
      @removeNonTextAttachments()
      @messageState.editing = true
      @messageState.pristine = true
    removeNonTextAttachments: ->
      return unless @medium in ['phone', 'alexa']
      switch @message.type
        when 'Bubble'
          @message.attachments = @message.attachments.filter (attachment) -> attachment.type == 'Text'
        when 'Card', 'Selection'
          for bubble in @message.bubbles
            bubble.attachments = bubble.attachments.filter (attachment) -> attachment.type == 'Text'
        when 'SelectionTemplate'
          for bubble in [@message.resultBubble, @message.moreBubble]
            bubble.attachments = bubble.attachments.filter (attachment) -> attachment.type == 'Text'
    newText: (role = 'NONE') ->
      type: 'Text'
      text: ''
      format: 'PLAIN'
      role: role
    newButton: (buttonType = 'Postback') ->
      type: 'Button'
      buttonType: buttonType
      text: @newText()
    newBubble: ->
      $key: GuidGenerator.newGuid()
      type: 'Bubble'
      attachments: [
        type: 'Image'
        path: ''
      ,
        @newText('TITLE')
      ,
        @newText('SUBTITLE')
      ]

  template: '
    <div class="message flex-container start-items" :class="{\'with-delay\': index > 0}" @drop.prevent="">
      <div class="template-indicator flex-container columns center-items">
        <img :src="state.allTemplates[message.template]">
        <div class="fa fa-exclamation-circle text-danger" v-if="showError"></div>
      </div>

      <div class="message-container expand">
        <message-delay v-if="index > 0" v-model="message.delay"/>
        <div class="message-panel">
          <bubbles-editor v-if="message.type == \'Bubble\'" :bubbles="[message]" :multiple="false" :message-state="messageState"/>
          <bubbles-editor
            v-else-if="message.type == \'SelectionTemplate\'"
            :bubbles="[message.resultBubble, message.moreBubble]"
            :multiple="false"
            :slider="true"
            :titles="[\'Result slide\', \'Show more slide\']"
            :message-state="messageState"
            :message="message"
            />
          <bubbles-editor
            v-else-if="message.type == \'Selection\'"
            :bubbles="bubblesWithoutHeroButton"
            :multiple="true"
            :slider="true"
            :message-state="messageState"
            @add-bubble="addBubble"
            @remove-bubble="removeBubble"
            />
          <bubbles-editor
            v-else
            :bubbles="bubblesWithoutHeroButton"
            :multiple="true"
            :slider="false"
            :message-state="messageState"
            @add-bubble="addBubble"
            @remove-bubble="removeBubble"
            />
        </div>
        <div class="quickreplies">
          <div v-if="quickreplies.length > 0 && index < count - 1" class="quickreply-warning">
            <div class="fa fa-exclamation-circle text-danger"></div>
            Quick replies between messages will not be displayed in the chat
          </div>
          <quickreply-editor
            v-for="attachment in quickreplies"
            :quickreply="attachment"
            :key="attachment.$key"
            :autogenerated-quickreplies-enabled="autogeneratedQuickrepliesEnabled"
            :in-search-attribute="inSearchAttribute"
            @remove-quickreply="removeQuickreply"
            class="btn btn-quickreply btn-edit"
            />
          <div v-if="canHaveQuickreplies" class="flex-container center-items">
            <button class="btn btn-quickreply btn-add" @click="addQuickreply">add quick reply</button>
            <span v-show="inSearchAttribute">&ensp;</span>
            <info-button v-show="inSearchAttribute" info-key="quick_replies"/>
          </div>
        </div>
        <hero-button-editor v-if="message.template == \'card\'" :message="message" :message-state="messageState"/>
      </div>

      <div class="message-icons flex-container columns">
        <div class="btn btn-icon-light" @click="remove">
          <span class="fa fa-times"></span>
        </div>
        <div v-if="index > 0" class="btn btn-icon-light" @click="pushUp">
          <span class="fa fa-arrow-up"></span>
        </div>
        <div v-if="index < count - 1" class="btn btn-icon-light" @click="pushDown">
          <span class="fa fa-arrow-down"></span>
        </div>
        <div class="btn btn-icon-light" @click="clone">
          <span class="far fa-clone"></span>
        </div>
      </div>
    </div>
  '
