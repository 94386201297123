
  import Message from 'models/message.coffee'
  import TextAttachmentEditor from './text_attachment_editor.vue'
  import WhatsappListTemplateRowEditor from './whatsapp_list_template_row_editor.vue'
  import { Button, Editable } from 'components/generic'
  import listIcon from 'images/message_builder/icn_list.svg'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Message

    emits: ['update:modelValue']

    data: ->
      message: @modelValue
      listIcon: listIcon
      sectionTitleEditorRefs: []
      rowEditorRefs: []
      sectionEditingStatus: []

    computed:
      staticAttachments: ->
        @message.bubbles[0].attachments
      sectionBubbles: ->
        @message.bubbles[1..]
      maxLengthBadgeClasses: ->
        if @staticAttachments[3].text.length > @message.valueFor('maxCtaLabelLength')
          'bg-MercuryRed-50 text-MercuryRed-800 font-normal'
        else
          'bg-gray-100 text-gray-800 font-light'

    watch:
      modelValue: ->
        @message = @modelValue

    created: ->
      @initRefs()

    beforeUpdate: ->
      @initRefs()

    methods:
      initRefs: ->
        @sectionTitleEditorRefs = []
        @rowEditorRefs = [1..@sectionBubbles.length].map -> []
      addSection: (bubbleIndex) ->
        @message.addWhatsappListSection(bubbleIndex + 1)
        @$nextTick =>
          @sectionTitleEditorRefs[bubbleIndex + 1].focus()
      removeSection: (bubbleIndex) ->
        @message.bubbles.splice(bubbleIndex + 1, 1)
        @update()
      addRow: (bubbleIndex) ->
        sectionBubble = @sectionBubbles[bubbleIndex]
        sectionBubble.addWhatsappListRow(sectionBubble.whatsappListRows.length)
        @$nextTick =>
          @rowEditorRefs[bubbleIndex][-1..][0].focus()
      removeRow: (bubbleIndex, rowIndex) ->
        sectionBubble = @sectionBubbles[bubbleIndex]
        sectionBubble.attachments.splice(rowIndex * 3 + 1, 3)
        @update()
      update: ->
        @$emit('update:modelValue', @message)
      focus: ->
        @$refs.bodyEditor.focus()
      setSectionTitleEditorRef: (el) ->
        @sectionTitleEditorRefs.push(el) if el?
      setRowEditorRef: (bubbleIndex, el) ->
        @rowEditorRefs[bubbleIndex].push(el) if el?
      setSectionEditingStatus: (bubbleIndex, editing) ->
        @sectionEditingStatus[bubbleIndex] = editing

    components:
      Button: Button
      Editable: Editable
      TextAttachmentEditor: TextAttachmentEditor
      WhatsappListTemplateRowEditor: WhatsappListTemplateRowEditor
      XIcon: XIcon
