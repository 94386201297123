
  import { Tabs } from 'components/generic'

  export default
    data: ->
      tabs = [
        {id: 'nlpExplorer', name: 'NLP explorer'}
        {id: 'knowledgeExplorer', name: 'Knowledge explorer'}
      ]

      currentTab: @$route.name
      tabs: tabs

    methods:
      pushRoute: ->
        @$router.push(name: @currentTab)

    components:
      Tabs: Tabs
