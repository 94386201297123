import { createApp } from 'vue_shims'
import { Button, Input, Modal } from 'components/generic'

initPromptApp = (element, message, resolve) ->
  createApp(
    data: ->
      message: message
      value: ''

    mounted: ->
      @$refs.modal.open()
      document.addEventListener 'keyup', @keyHandler
      @$nextTick => @$refs.input.focus()

    methods:
      ok: ->
        @$refs.modal.close()
      cancel: ->
        @value = ''
        @$refs.modal.close()
      remove: ->
        resolve(@value)
        element.remove()
        document.removeEventListener 'keyup', @keyHandler
      keyHandler: (e) ->
        return if e.key != 'Enter'
        @ok()

    components:
      Button: Button
      Input: Input
      Modal: Modal

    template: '
      <Modal ref="modal" @closed="remove" @keyup.stop="">
        <template #body>
          <Input ref="input" v-model="value" :label="message" @keyup.enter="ok" @keyup.esc="cancel" class="mt-1"/>
        </template>
        <template #buttons>
          <Button @click="ok" type="primary" class="ml-3">Ok</Button>
          <Button @click="cancel">Cancel</Button>
        </template>
      </Modal>
    '
  )
    .mount(element)

export default (message) =>
  new Promise (resolve, reject) =>
    element = document.createElement('div')
    document.body.appendChild(element)
    initPromptApp(element, message, resolve)
