
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'

  export default
    props:
      parameterKey: String
      conversation: Conversation

    data: ->
      editing: false
      debounceTimeout: null

    computed:
      label: ->
        @contextParameter?.label || @parameterKey
      value: ->
        @conversation.context[@parameterKey]
      contextParameter: ->
        @$root.contextParameters.find (cp) => cp.key == @parameterKey
      editable: ->
        return false unless @contextParameter?
        !@contextParameter.readOnly

    watch:
      editing: ->
        if @editing
          setTimeout =>
            document.addEventListener('click', @clickedOutside)
          , 0
        else
          document.removeEventListener('click', @clickedOutside)

    methods:
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$el)
        @editing = false
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @$root.api.updateConversationContext(@conversation, "#{@parameterKey}": @value)
            .catch => await alert('Sorry, your changes couldn’t be saved.')
        , 1000)
      edit: ->
        return if !@editable
        @editing = true
        @$nextTick =>
          @$refs.input?.focus()

    components:
      DataTypeDependentInput: DataTypeDependentInput
