import Target from 'models/dialog_target.coffee'

export default class Priming
  constructor: (@host, data = {}) ->
    @target = if data.target instanceof Target then data.target else new Target(@host, data.target)
    @hookKeys = data.hookKeys || []

  Object.defineProperties @prototype,
    export:
      get: ->
        target: @target.export
        hookKeys: @hookKeys
