
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import { CheckCircleIcon, CloudDownloadIcon, RefreshIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      source: KnowledgeSource

    components:
      CheckCircleIcon: CheckCircleIcon
      CloudDownloadIcon: CloudDownloadIcon
      RefreshIcon: RefreshIcon
      XCircleIcon: XCircleIcon
