
  import MacroChange from 'models/macro_change'
  import User from 'models/user'
  import { confirm } from 'helpers'

  import { BackLink, Button, EnvironmentBadge, PageHeader } from 'components/generic'
  import { ReplyIcon } from '@heroicons/vue/outline'

  export default
    props:
      changeId:
        type: String
        default: null
      embedded:
        type: Boolean
        default: false

    data: ->
      macroChange: null
      user: null
      ReplyIcon: ReplyIcon

    computed:
      id: ->
        @changeId || @$route.params.changeId

    created: ->
      @setMacroChange()

    methods:
      setMacroChange: ->
        @macroChange = await MacroChange.load(@id)
        @user = await User.load(@macroChange.platformUserId.split('-').last)
      restore: ->
        return unless await confirm("Restore your Chatbot the the last version that does not include the listed changes. All changes included in this version and later ones will be undone, and a new version will be created.")
        @macroChange.restore()
          .then =>
            if @embedded
              Promise.resolve()
            else
              @$router.push(name: 'versionList')
          .then =>
            # reload whole botbuilder app
            Turbolinks.visit(window.location)

    components:
      BackLink: BackLink
      Button: Button
      EnvironmentBadge: EnvironmentBadge
      PageHeader: PageHeader
