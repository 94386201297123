import { confirm } from 'helpers'

Vue.component 'unit-test',
  props: ['test', 'index']
  data: ->
    checked: false
    running: false
  computed:
    statusIcon: ->
      if @running
        return '<span class="fa fa-sync fa-spin">'
      switch @test.status
        when 'pass'
          '<span class="fa fa-check-circle text-success" title="test passed">'
        when 'fail'
          '<span class="fa fa-times-circle text-danger" title="test failed">'
        when 'badformat'
          '<span class="fa fa-exclamation-circle text-danger" title="wrong test definition">'
        else
          ''
    actionMenuItems: ->
      [
        {icon: 'fa fa-running', label: 'Run', method: @run},
        {icon: 'far fa-times-circle', label: 'Delete', method: @deleteTest, hr: true},
      ]
  methods:
    edit: ->
      @$emit('edit-test', @test.id)
    run: ->
      @running = true
      $.getJSON(
        Routes.run_project_unit_test_path(Globals.projectId, Globals.stageLevel, @test.id)
      ).done((data) =>
        @test[k] = v for k, v of data
      ).always(=>
        @running = false
      )
    deleteTest: ->
      return unless await confirm("Do you really want do delete test “#{@test.name}”?")
      $.post(
        Routes.project_unit_test_path(Globals.projectId, Globals.stageLevel, @test.id),
        _method: 'delete'
      ).done(=>
        @$emit('delete-test', @test.id)
      )
  template: '
    <tr>
      <td>
        <input type="checkbox" v-model="checked">
      </td>
      <td> {{ test.name }} </td>
      <td> {{ test.stage.label }} </td>
      <td> {{ test.last_run_at_display }} </td>
      <td v-html="statusIcon"> </td>
      <td>
        <div class="btn-icon-cell-2">
          <div @click="edit" class="btn btn-icon">
            <span class="fa fa-pen"/>
          </div>
          <action-menu :items="actionMenuItems"/>
        </div>
      </td>
    </tr>
  '
