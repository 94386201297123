import { ChatIcon, CubeTransparentIcon, DocumentDuplicateIcon, TranslateIcon } from '@heroicons/vue/outline'

export default class Addon
  ICONS =
    ChatIcon: ChatIcon
    CubeTransparentIcon: CubeTransparentIcon
    DocumentDuplicateIcon: DocumentDuplicateIcon
    TranslateIcon: TranslateIcon

  @ATTRIBUTES = [
    'id'
    'name'
    'description'
    'cancelled'
    'price'
    'period'
    'period_unit'
    'meta_data'
  ]

  constructor: (data) ->
    @meta_data = {}
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  Object.defineProperties @prototype,
    color:
      get: ->
        @meta_data.color || ''
    mercuryColor:
      get: ->
        # we need this for TailwindCss to generate the classes
        availableColors = 'bg-MercuryGrey-500 bg-MercuryBlue-500 bg-MercuryGreen-500 bg-MercuryYellow-500 bg-MercuryRed-500'
        availableColors = 'bg-MercuryGrey-50 bg-MercuryBlue-50 bg-MercuryGreen-50 bg-MercuryYellow-50 bg-MercuryRed-50'
        availableColors = 'hover:bg-MercuryGrey-100 hover:bg-MercuryBlue-100 hover:bg-MercuryGreen-100 hover:bg-MercuryYellow-100 hover:bg-MercuryRed-100'
        availableColors = 'focus-withing:ring-MercuryGrey-700 focus-withing:ring-MercuryBlue-700 focus-withing:ring-MercuryGreen-700 focus-withing:ring-MercuryYellow-700 focus-withing:ring-MercuryRed-700'
        "Mercury#{@color.capitalize()}"
    bgClass:
      get: ->
        "bg-#{@mercuryColor}-500"
    icon:
      get: ->
        return unless @meta_data.icon?
        ICONS[@meta_data.icon]
    longDescription:
      get: ->
        @meta_data.long_description

