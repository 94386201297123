
  import PlatformApi2 from 'models/platform_api2.coffee'

  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import NlpExplorerTableRow from './nlp_explorer_table_row.vue'
  import Wrapper from './wrapper.vue'
  import { Button, DocumentationLink, H3, Input, PageHeader, Spinner, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'

  export default
    data: ->
      utterance: ''
      running: false
      interpretations: []

    created: ->
      if @$route.hash
        @utterance = decodeURIComponent(@$route.hash[1..])
        @run()

    methods:
      run: ->
        return if @running
        @running = true
        @$router.push(hash: '#' + encodeURIComponent(@utterance))
        PlatformApi2.getInterpretation(utterance: @utterance, language: @$root.currentLanguage, botId: @$root.currentBot.id)
          .then (data) =>
            @interpretations = data.interpretations
          .catch =>
            @interpretations = []
          .finally =>
            @running = false

    components:
      Button: Button
      DocumentationLink: DocumentationLink
      Input: Input
      H3: H3
      LanguageSelector: LanguageSelector
      NlpExplorerTableRow: NlpExplorerTableRow
      PageHeader: PageHeader
      Spinner: Spinner
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      Wrapper: Wrapper
