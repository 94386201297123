import { ColorPicker } from 'vue-color-kit'

Vue.component 'color-picker',
  props: ['modelValue', 'colorpicker', 'property', 'name']

  data: ->
    colorValue: @modelValue
    displayPicker: false

  watch:
    modelValue: ->
      @colorValue = @modelValue
    colorValue: ->
      @$emit('input', @colorValue)
      @$emit('update:modelValue', @colorValue)

  methods:
    showPicker: ->
      document.addEventListener 'click', @documentClick
      @displayPicker = true
      return
    hidePicker: ->
      document.removeEventListener 'click', @documentClick
      @displayPicker = false
      return
    togglePicker: ->
      if @displayPicker then @hidePicker() else @showPicker()
      return
    documentClick: (e) ->
      el = @$refs[@colorpicker]
      target = e.target
      if el != target and !el.contains(target)
        @hidePicker()
      return
    updateFromPicker: (color) ->
      @colorValue = color.hex

  components:
    'color-kit': ColorPicker

  template: '
    <div class="color-picker" :ref="colorpicker">
      <input type="hidden" class="form-control" :name="name" v-model="colorValue" @focus="showPicker()" />
      <p class="property">{{ property }}</p>
      <span class="color-picker-container">
        <span class="current-color" :style="{ backgroundColor: colorValue }" @click="togglePicker()"></span>
        <color-kit
          v-if="displayPicker"
          :color="colorValue"
          @changeColor="updateFromPicker"
          theme="light"
          style="width: 218px"
          :colors-default="[]"
          class="relative z-10"
        />
      </span>
    </div>
  '
