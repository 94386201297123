
  import PlatformApi2 from 'models/platform_api2.coffee'

  import FacebookChannelEditor from './facebook_channel/editor.vue'
  import GenericChannelEditor from './generic_channel_editor.vue'
  import InstagramChannelEditor from './instagram_channel/editor.vue'
  import WebChannelEditor from './web_channel/editor.vue'
  import WhatsappChannelEditor from './whatsapp_channel/editor.vue'

  export default
    data: ->
      channel: null
      debounceTimeout: null
      configDebounceTimeout: null

    watch:
      '$route.params.channelId': ->
        return unless @$route.params.channelId?
        @loadChannel()
      'channel.connected': (newValue, oldValue) ->
        return if !oldValue? # when the channel was not set before (loadConfig is called in "created" hook)
        return if !@channel.connected
        @channel.loadConfig()

    created: ->
      @handleRedirectFrom360Dialog()
      @handleRedirectFromFacebook()
      @loadChannel()

    methods:
      handleRedirectFrom360Dialog: ->
        params = new URLSearchParams(window.location.search)
        return unless params.get('channels')?
        return unless window.opener?
        window.opener.postMessage(window.location.search)
        window.close()
      handleRedirectFromFacebook: ->
        params = new URLSearchParams(window.location.search)
        return unless params.get('code')?
        return unless window.opener?
        window.opener.postMessage(window.location.search)
        window.close()
      loadChannel: ->
        PlatformApi2.getChannel(@$route.params.channelId)
          .then (channel) =>
            if channel.connected
              channel.loadConfig()
            else
              Promise.resolve(channel)
          .then (channel) =>
            @channel = channel
            window.breadcrumbs.append(text: @channel.label)
      saveChannel: ->
        @channel.save()
        @channel.saveConfig() if @channel.type == 'WEB'
      saveChannelConfig: ->
        @channel.saveConfig()
      deleteChannel: ->
        @channel.delete().then =>
          @$router.push(name: 'index')
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@saveChannel, 1000)
      updateConfig: ->
        clearTimeout(@configDebounceTimeout)
        @configDebounceTimeout = setTimeout(@saveChannelConfig, 1000)

    components:
      FacebookChannelEditor: FacebookChannelEditor
      GenericChannelEditor: GenericChannelEditor
      InstagramChannelEditor: InstagramChannelEditor
      WebChannelEditor: WebChannelEditor
      WhatsappChannelEditor: WhatsappChannelEditor
