
  import WhatsappTemplate from 'models/whatsapp_template'
  import WorkflowStep from 'models/workflow_step'

  import { Button } from 'components/generic'
  import { DocumentIcon, FilmIcon, PhotographIcon } from '@heroicons/vue/outline'

  export default
    props:
      template: WhatsappTemplate
      step: WorkflowStep

    computed:
      headerIsText: ->
        @step.headerParameters[0]?.type == 'text'
      headerIsImage: ->
        @step.headerParameters[0]?.type == 'image'
      headerIsVideo: ->
        @step.headerParameters[0]?.type == 'video'
      headerIsDocument: ->
        @step.headerParameters[0]?.type == 'document'
      interpolatedHeaderText: ->
        return unless @headerIsText
        @template.header.text.replace(
          @step.headerParameters[0].placeholder,
          "<span class='font-bold'>#{@step.headerParameters[0].interpolationValue}</span>"
        )
      imageUrl: ->
        return unless @headerIsImage
        return @step.headerParameters[0].fallback if @step.headerParameters[0].hasFallback
        @step.headerParameters[0].url
      videoUrl: ->
        return unless @headerIsVideo
        return @step.headerParameters[0].fallback if @step.headerParameters[0].hasFallback
        @step.headerParameters[0].url
      interpolatedBodyText: ->
        string = @template.body.text
        @step.bodyParameters.forEach (parameter) =>
          string = string.replace(
            parameter.placeholder,
            "<span class='font-bold'>#{parameter.interpolationValue}</span>"
          )
        string
      urlButtonValue: ->
        # we know that a template can have only one URL button with one parameter
        return unless @template.urlButton?
        if @step.buttonParameters[0]?
          @template.urlButton.url.replace(
            @step.buttonParameters[0].placeholder,
            "<span class='font-semibold'>#{@step.buttonParameters[0].interpolationValue}</span>"
          )
        else
          @template.urlButton.url

    components:
      Button: Button
      DocumentIcon: DocumentIcon
      FilmIcon: FilmIcon
      PhotographIcon: PhotographIcon
