
  import WorkflowBotInput from 'models/workflow_bot_input.coffee'
  import DataTypeEditor from 'components/botbuilder/data_type_editor.vue'
  import { Input } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: WorkflowBotInput

    emits: ['update:modelValue', 'remove']

    data: ->
      input: @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @input)

    components:
      DataTypeEditor:DataTypeEditor
      Input: Input
      XIcon: XIcon
