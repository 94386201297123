import WhatsappTemplateParameter from 'models/whatsapp_template_parameter'

export default class WhatsappTemplate
  @ATTRIBUTES = [
    'name'
    'namespace'
    'category'
    'language'
    'status'
    'components'
    'channelId' # this attribute is ours
  ]

  @CATEGORIES = [
    {key: 'MARKETING', label: 'Marketing'}
    {key: 'TRANSACTIONAL', label: 'Transactional'}
    {key: 'OTP', label: 'OTP'}
  ]

  @ALL_CATEGORIES = [
    'ACCOUNT_UPDATE'
    'PAYMENT_UPDATE'
    'PERSONAL_FINANCE_UPDATE'
    'SHIPPING_UPDATE'
    'RESERVATION_UPDATE'
    'ISSUE_RESOLUTION'
    'APPOINTMENT_UPDATE'
    'TRANSPORTATION_UPDATE'
    'TICKET_UPDATE'
    'ALERT_UPDATE'
    'AUTO_REPLY'
    'MARKETING'
    'TRANSACTIONAL'
    'OTP'
  ]

  @MEDIA = [
    'TEXT'
    'IMAGE'
    'DOCUMENT'
    'VIDEO'
  ]

  @BUTTON_TYPES = [
    'QUICK_REPLY'
    'URL'
    'PHONE_NUMBER'
  ]

  @getParameters: (originalString) ->
    string = originalString.slice()
    res = []
    while match = string.match(/\{\{\d+\}\}/)
      res.push(new WhatsappTemplateParameter(placeholder: match[0]))
      string = string.slice(match.index + 5)
    res

  constructor: (data) ->
    @id = data.id
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      Vue.set(@, attribute, data[attribute]) if data[attribute]?
    return this

  addHeader: ->
    return if @header?
    @components.push(type: 'HEADER', format: 'TEXT', text: '')
  addFooter: ->
    return if @footer?
    @components.push(type: 'FOOTER', text: '')
  addButtons: ->
    return if @buttons?
    @components.push(type: 'BUTTONS', buttons: [])

  removeHeader: ->
    return unless @header?
    @components = @components.filter (component) -> component.type != 'HEADER'
  removeFooter: ->
    return unless @footer?
    @components = @components.filter (component) -> component.type != 'FOOTER'
  removeButtons: ->
    return unless @buttons?
    @components = @components.filter (component) -> component.type != 'BUTTONS'

  Object.defineProperties @prototype,
    header:
      get: ->
        @components.find (component) -> component.type == 'HEADER'
    body:
      get: ->
        @components.find (component) -> component.type == 'BODY'
    footer:
      get: ->
        @components.find (component) -> component.type == 'FOOTER'
    buttons:
      get: ->
        @components.find (component) -> component.type == 'BUTTONS'
    urlButton: # we know that a template can have only one URL button
      get: ->
        @buttons?.buttons.find (button) -> button.type == 'URL'
    buttonType:
      get: ->
        return unless @buttons?.buttons.length
        if @buttons.buttons.some (button) -> button.type == 'QUICK_REPLY'
          'QR'
        else if @buttons.buttons.some (button) -> button.type == 'URL' || button.type == 'PHONE_NUMBER'
          'CTA'
    humanizedCategory:
      get: ->
        @category.toLowerCase().replace(/_/g, ' ')
    preview:
      get: ->
        bodyText = @body.text
        return bodyText if bodyText.length <= 40
        bodyText[0..39] + '...'
    headerParameters:
      get: ->
        if @header?.format == 'TEXT'
          @constructor.getParameters(@header.text)
        else if @header?
          [new WhatsappTemplateParameter(
            type: @header.format.toLowerCase()
            placeholder: "#{@header.format.toLowerCase()} URL"
          )]
        else
          []
    bodyParameters:
      get: ->
        @constructor.getParameters(@body.text)
    footerParameters:
      get: ->
        []
    buttonParameters:
      get: ->
        if @urlButton
          @constructor.getParameters(@urlButton.url)
        else
          []
    valid:
      get: ->
        @name?.length && @name.match(/^[a-z_]+$/) &&
          @category?.length && @language?.length && @body?.text?.length
    dataForCreate:
      get: ->
        if @headerParameter?
          if @header.format == 'TEXT'
            @header.example = header_text: [@headerParameter.defaultValue]
          else
            @header.example = header_handle: [@headerParameter.defaultValue]
        if @setBodyParameters?.length
          @body.example = body_text: [@setBodyParameters.map (parameter) -> parameter.defaultValue]
        if @buttonParameter?
          urlButton = @buttons.buttons.find (btn) -> btn.type == 'URL'
          urlButton.example = [@buttonParameter.defaultValue]

        components = @components.filter((c) ->
          c.type == 'BODY' ||
            c.type == 'HEADER' && (c.format != 'TEXT' || c.text.length) ||
            c.type == 'FOOTER' && c.text.length ||
            c.type == 'BUTTONS' && c.buttons.length
        ).map (component) -> ObjectProcessor.cloneWithout(component, ['interpolatedText', 'url'])

        name: @name
        category: @category
        language: if @language == 'pt' then 'pt_BR' else @language # WA doesn't accept pt, only pt_PT or pt_BR
        components: components
