Vue.component 'support-option-fail-message',
  props: ['failWithoutOptions']
  mixins: [WizardComponent]
  data: ->
    entity: @failWithoutOptions
    role: 'tail'
    attributes:
      message:
        type: 'NLGfilled'
        default: {}
    messageCopy: {}
  created: ->
    if @failWithoutOptions.message?
      @pristine = false
    @setCopy()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">If things don’t work out and there are no solutions left, the bot will say ...</h5>
          <nlg-editor :nlg="messageCopy" must-be-filled="true"/>
          <support-confirm-button/>
        </div>
      </div>

      <div class="button-container">
        <button v-if="!pristine" :disabled="allComplete ? null : true" @click="finished" class="btn btn-primary">confirm and close</button>
      </div>
    </div>
  '
