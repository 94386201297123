import GuidGenerator from 'models/guid_generator.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import ContextUpdate from 'models/context_update.coffee'
import ParameterFilter from 'models/parameter_filter.coffee'
import WorkflowParameterMapping from 'models/workflow_parameter_mapping'
import WhatsappTemplateParameter from 'models/whatsapp_template_parameter.coffee'

export default class WorkflowStep
  @TYPES =
    JsonTransformJq:
      label: 'JSON transform'
      attributes:
        query: ''
        outputKey: ''
    # QueryUsers:
    #   label: 'Find bot users'
    #   attributes:
    #     conditions: [] # [ <ParameterFilter> ]
    IdentifyOrCreateUser:
      label: 'Identify (or create) bot user'
      attributes:
        nativeUserId: ''
        channelId: null
        createIfMissing: true
    UpdateContext:
      label: 'Update context'
      attributes:
        updates: [] # [ <ContextUpdate> ]
    # Terminate:
    #   label: 'Terminate workflow'
    #   attributes:
    #     conditions: [] # [ <ParameterFilter> ]
    InvokeIntegration:
      label: 'Custom'
      attributes:
        integrationId: ''
        parameterMapping: [] # [ <WorkflowParameterMapping> ]
    SendWhatsAppTemplate:
      label: 'Send Whatsapp template'
      attributes:
        templateName: ''
        templateNamespace: ''
        language: null
        headerParameters: []
        bodyParameters: []
        footerParameters: []
        buttonParameters: []
    # SendEmail:
    #   label: 'send email'
    #   attributes:
    #     address: ''
    #     subject: ''
    #     body: ''
    # CreateImageLink:
    #   label: 'Create image link'
    #   attributes:
    #     base64: ''

  constructor: (@workflow, data = {}) ->
    @id = GuidGenerator.newGuid() # needed for Vue list rendering
    @key = data.key
    @type = data.type
    Object.entries(@attributes).forEach ([attribute, defaultValue]) =>
      @[attribute] = if defaultValue instanceof Array then defaultValue.slice() else defaultValue
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @attributeKeys.forEach (attribute) =>
      if attribute == 'conditions' && data.conditions?
        @conditions = data.conditions.map (conditionData) -> new ParameterFilter(conditionData)
      else if attribute == 'updates' && data.updates?
        @updates = data.updates.map (updateData) -> new ContextUpdate(updateData)
      else if attribute == 'parameterMapping' && data.parameterMapping?
        @parameterMapping = data.parameterMapping.map (mappingData) -> new WorkflowParameterMapping(mappingData)
      else if ['headerParameters', 'bodyParameters', 'footerParameters', 'buttonParameters'].includes(attribute) && data[attribute]?
        @[attribute] = data[attribute].map (paramData) -> new WhatsappTemplateParameter(paramData)
      else
        @[attribute] = data[attribute] if data[attribute]?

  getVariables: ->
    return Promise.reject() if !@key?
    BotbuilderApi.getWorkflowVariables(@)

  Object.defineProperties @prototype,
    label:
      get: ->
        WorkflowStep.TYPES[@type]?.label || @type
    attributes:
      get: ->
        WorkflowStep.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    workflowId:
      get: ->
        @workflow.id
    previousSteps:
      get: ->
        index = @workflow.steps.findIndex (step) => step == @
        @workflow.steps.slice(0, index)
    previousChannelId:
      get: ->
        step = @previousSteps.slice().reverse().find (step) => step.type == 'IdentifyOrCreateUser'
        step?.channelId
    export:
      get: ->
        obj = {key: @key, type: @type}
        @attributeKeys.forEach (attribute) =>
          if attribute == 'conditions'
            obj.conditions = @conditions.map (condition) -> condition.export
          else if attribute == 'updates'
            obj.updates = @updates.map (update) -> update.export
          else if attribute == 'parameterMapping'
            obj.parameterMapping = @parameterMapping.map (mapping) -> mapping.export
          else if ['headerParameters', 'bodyParameters', 'footerParameters', 'buttonParameters'].includes(attribute)
            obj[attribute] = @[attribute].map (parameter) -> parameter.export
          else
            obj[attribute] = @[attribute]
        obj
