Vue.component 'title-with-image-editor',
  props: ['attachments', 'messageState']
  template: '
    <div class="title-with-image">
      <attachment-editor
        :attachment="attachments[0]"
        :index="0"
        :message-state="messageState"
        :small-image="true"
        />
      <attachment-editor
        :attachment="attachments[1]"
        :index="1"
        :message-state="messageState"
        />
    </div>
  '
