
  import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
  import { CheckCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: String
      options: Array

    data: ->
      value: @modelValue

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @value)

    components:
      CheckCircleIcon: CheckCircleIcon
      RadioGroup: RadioGroup
      RadioGroupOption: RadioGroupOption
