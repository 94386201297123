
  import Attachment from 'models/attachment.coffee'
  import TextEditor from './text_editor.vue'

  export default
    props:
      modelValue: Attachment
      index:
        type: Number
        default: 0
      count:
        type: Number
        default: 1
      textSize:
        type: String
        default: 'text-base'
      placeholder:
        type: String
        default: ''
      height:
        type: String
        default: 'md'
      maxLength:
        type: Number
        default: null

    emits: ['update:modelValue', 'focus', 'blur', 'editing']

    data: ->
      attachment: @modelValue
      editing: false

    computed:
      showError: ->
        !@attachment.pristine && !@attachment.valid
      roundedClass: ->
        if @count == 1
          'rounded-lg'
        else if @index == 0
          'rounded-t-lg'
        else if @index == @count - 1
          'rounded-b-lg'
        else
          ''

    watch:
      modelValue: ->
        @attachment = @modelValue
      editing: ->
        @$emit('editing', @editing)

    mounted: ->
      @editing = true if @attachment.pristine

    methods:
      edit: ->
        @editing = true
      endEditing: ->
        @editing = false
        @attachment.pristine = false
        @attachment.bubble.message.pristine = false
      update: ->
        @$emit('update:modelValue', @attachment)
      focus: ->
        @editing = true
        @$refs.textEditor.focus()

    components:
      TextEditor: TextEditor
