
  import Summary from './summary.vue'
  import Chart from './chart.vue'
  import DistributionTable from './distribution_table.vue'
  import { KeyListbox } from 'components/generic'

  allTeamsId = '--all--'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    data: ->
      selectedTeamId: allTeamsId
      summaryConfig:
        activeSessions: {label: 'Live chat sessions', type: 'absolute'}
        sessionDuration: {label: 'Median session duration (minutes)', type: 'absolute'}
        inactiveSessions: {label: 'Unanswered live chats', type: 'absolute'}
        firstReactionTime: {label: 'Median first answer after (seconds)', type: 'absolute'}
        medianReactionDuration: {label: 'Median reaction time (seconds)', type: 'absolute'}
        # capacity: {label: 'Capacity', type: 'string'}

    computed:
      ready: ->
        @data.livechatSessions.current.status == 'ready'
      previousReady: ->
        @data.livechatSessions.previous.status == 'ready'
      summary: ->
        Object.entries(@summaryConfig).map ([key, value]) =>
          key: key
          label: value.label
          type: value.type
          current: if @ready
            if key == 'capacity'
              "#{@data.livechatSessions.current.data.usedCapacity} of #{@data.livechatSessions.current.data.provisionedCapacity}"
            else
              @data.livechatSessions.current.data[key]
          else
            null
          previous: if @previousReady
            if key == 'capacity'
              "#{@data.livechatSessions.previous.data.usedCapacity} of #{@data.livechatSessions.previous.data.provisionedCapacity}"
            else
              @data.livechatSessions.previous.data[key]
          else
            null
      availableAgentTeams: ->
        [name: 'all agent teams', id: allTeamsId].concat(@$root.agentTeams)

    created: ->
      @selectedTeamId = @$root.settings.agentTeamId || allTeamsId

    methods:
      seriesData: (key) ->
        return [{data: []}] if !@ready
        [
          data: @data.livechatSessions.current.data[key]
        ]
      perTimeframeData: (key) ->
        return {} if !@ready
        @data.livechatSessions.current.data[key]
      reload: ->
        @$root.settings.agentTeamId = if @selectedTeamId != allTeamsId then @selectedTeamId else null
        @$root.saveSettings()
        @data.loadLivechatSessionsData(true)

    components:
      Chart: Chart
      DistributionTable: DistributionTable
      KeyListbox: KeyListbox
      Summary: Summary
