
  import ContextInitiative from 'models/context_initiative.coffee'
  import Hook from 'models/hook.coffee'
  import UserIntent from 'models/user_intent.coffee'
  import SlotDefinition from 'models/slot_definition.coffee'
  import ValueType from 'models/value_type.coffee'

  import { Listbox } from 'components/generic'

  export default
    props:
      modelValue: SlotDefinition
      host: [Hook, UserIntent, ContextInitiative]

    data: ->
      slotDefinition: @modelValue

    computed:
      availabelSlotDefinitions: ->
        @host.slotDefinitions.filter (slotDefinition) =>
          !(slotDefinition.type == 'TypedSlotDefinition' && slotDefinition.slotRole.endsWith('#ignore'))

    created: ->
      return if @slotDefinition.slotRole
      @slotDefinition = @host.slotDefinitions[0]
      @update()

    methods:
      update: ->
        @$emit('update:modelValue', @slotDefinition)
      slotDefinitionLabel: (sd) ->
        switch sd.type
          when 'TypedSlotDefinition'
            "#{sd.label} (#{ValueType.TYPES[sd.valueType]})"
          when 'AttributeSlotDefinition'
            contextParameter = @$root.contextParameterByKey(sd.parameterKey)
            if contextParameter?
              "#{sd.label} (#{ValueType.TYPES[contextParameter.valueType]}, #{contextParameter.label})"
            else
              sd.label

    components:
      Listbox: Listbox
