Vue.component 'support-option-done-message',
  props: ['option']
  mixins: [WizardComponent]
  data: ->
    entity: @option.done
    role: 'mid'
    attributes:
      message:
        type: 'NLGfilled'
        default: {}
    messageCopy: {}
  created: ->
    if @option.done.message?
      @pristine = false
    @setCopy()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">How will the bot celebrate success?</h5>
          <nlg-editor :nlg="messageCopy" must-be-filled="true"/>
          <support-confirm-button/>
        </div>
      </div>

      <support-option-fail-with-options v-if="!pristine" :option="option" @finished="finished" @discard="discard"/>
    </div>
  '
