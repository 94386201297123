
  import FacebookMenu from 'models/facebook_menu.coffee'
  import WidgetMenu from 'models/widget_menu.coffee'

  import MenuItemEditor from './menu_item_editor.vue'
  import { Button } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: [FacebookMenu, WidgetMenu]

    data: ->
      menu: @modelValue
      PlusIcon: PlusIcon

    computed:
      mayAddItem: ->
        return true if @menu instanceof WidgetMenu
        @menu.items.length < 5

    methods:
      removeMenuItem: (index) ->
        @menu.items.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @menu)

    components:
      Button: Button
      MenuItemEditor: MenuItemEditor
