import Agent from 'models/agent'
import AgentTeam from 'models/agent_team'
import ContentEditableSpan from 'models/content_editable_span'

export default class AnswerTemplate
  @ATTRIBUTES = [
    'id'
    'createdBy'
    'message'
    'exampleQuestion'
    'teams'
    'tags'
    'confidence'
    'timeCreated'
    'timeLastUsed'
    'timeDeleted'
  ]

  constructor: (data) ->
    @id = data.id
    @update(data)
    @exampleQuestion ||= ''
    @teams ||= []
    @tags ||= []

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      continue if !data[attribute]?
      switch attribute
        when 'createdBy'
          Vue.set(@, attribute, new Agent(data[attribute]))
        when 'teams'
          Vue.set(@, attribute, data[attribute].map((teamData) -> new AgentTeam(teamData)))
        else
          Vue.set(@, attribute, data[attribute])
    return this

  gist: ->
    id: @id
    exampleQuestion: @exampleQuestion
    message: @message
    teams: @teams.map((team) -> { id: team.id, name: team.name })
    tags: @tags

  Object.defineProperties @prototype,
    lowerCaseExampleQuestion:
      get: ->
        @exampleQuestion.toLowerCase()
    bubbles:
      get: ->
        if @message.type == 'Bubble'
          [@message]
        else
          @message.bubbles
    messageText:
      get: ->
        @bubbles.map((bubble) ->
          bubble.attachments.map((attachment) -> attachment.text).join('\n')
        ).join('\n')
    parsedMessageText:
      get: ->
        parts = []
        charIndex = 0
        [...@messageText.matchAll(/\$\{(.*?)\}/g)].forEach (match) =>
          precedingText = @messageText[charIndex...match.index]
          parts.push(new ContentEditableSpan(type: 'text', text: precedingText))
          parts.push(new ContentEditableSpan(type: 'expression', text: match[1]))
          charIndex += precedingText.length + match[0].length
        if charIndex < @messageText.length
          parts.push(new ContentEditableSpan(type: 'text', text: @messageText[charIndex..]))
        parts
