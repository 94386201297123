import Message from 'models/message'
import PlatformApi2 from 'models/platform_api2'

export default class NoParseMessage
  @list: ({environment} = {}) ->
    PlatformApi2.getNoParseMessages(environment: environment)
      .then (listData) -> listData.map (messageData) -> new NoParseMessage(messageData)

  constructor: (data) ->
    @id = data.id
    @userId = data.userId
    @channelId = data.channelId
    @language = data.language
    @origin = data.origin
    @message = new Message(null, data.message)
    @interpretation = data.interpretation
    @timestamp = data.timestamp

  delete: ->
    PlatformApi2.deleteNoParseMessage(@)
      .then => @

  Object.defineProperties @prototype,
    text:
      get: ->
        @message.text
