Vue.component 'multilang-field',
  props: ['dictionary', 'placeholder']
  data: ->
    state: state
    text: ''
  created: ->
    @setText()
  watch:
    dictionary: ->
      @setText()
    'state.currentLanguage': ->
      @setText()
    text: ->
      Vue.set(@dictionary, @state.currentLanguage, @text)
  methods:
    setText: ->
      @text = if @state.currentLanguage? && @dictionary[@state.currentLanguage]?
        @dictionary[@state.currentLanguage]
      else
        ''
  template: '
    <input v-model="text" type="text" :placeholder="placeholder">
  '
