
  import ContextParameter from 'models/context_parameter.coffee'
  import Validation from 'models/validation.coffee'

  import ValidationEditor from './validation_editor.vue'
  import { Button, KeyListbox } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: ContextParameter

    data: ->
      validationTypesForSelect = Object.entries(Validation.TYPES)
        .filter(([type, config]) -> config.label?)
        .map ([type, config]) -> {key: type, label: config.label}

      contextParameter: @modelValue
      validationTypesForSelect: validationTypesForSelect
      selectedValidationType: validationTypesForSelect[0].key
      editorRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @contextParameter = @modelValue

    beforeUpdate: ->
      @editorRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @contextParameter)
      remove: (index) ->
        @contextParameter.validationRules.splice(index, 1)
        @update()
      addValidation: ->
        @contextParameter.validationRules.push(new Validation(type: @selectedValidationType))
        @$nextTick => @editorRefs.last.focus()
      setEditorRef: (el) ->
        @editorRefs.push(el) if el?

    components:
      Button: Button
      KeyListbox: KeyListbox
      ValidationEditor: ValidationEditor
