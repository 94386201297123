
  import { ActionMenu, TableCell, TableRow } from 'components/generic'
  import { ArrowUpIcon } from '@heroicons/vue/outline'

  export default
    props:
      intent: Object

    data: ->
      actionMenuItems: [
        {icon: ArrowUpIcon, iconClass: 'rotate-45', label: 'Show in dialog editor', method: @gotoBotbuilder}
        {icon: ArrowUpIcon, iconClass: 'rotate-45', label: 'Show utterances', method: @gotoTrainingData}
      ]

    methods:
      gotoBotbuilder: ->
        routerTarget = if @intent.intent.moduleKey == 'HOOK'
          name: 'hook'
          params: {hookKey: @intent.intent.nodeKey}
        else if @intent.intent.moduleKey == 'CONTEXT_INITIATIVE'
          name: 'contextParameter'
          params: {parameterKey: @intent.intent.nodeKey}
        else
          name: if @$root.activeBot.config.module(@intent.intent.moduleKey)?.isCoreBehavior then 'coreBehaviour' else 'module'
          params: {moduleKey: @intent.intent.moduleKey}
          hash: '#' + @intent.intent.nodeKey
        @$router.push(routerTarget)
      gotoTrainingData: ->
        @$router.push(params: {tab: 'utterances'}, query: {intents: @intent.intent.nodeKey})

    components:
      ActionMenu: ActionMenu
      TableCell: TableCell
      TableRow: TableRow
