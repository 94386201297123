import DialogNode from 'models/dialog_node.coffee'
import Target from 'models/dialog_target.coffee'
import Operation from 'models/operation.coffee'
import BranchOption from 'models/branch_option.coffee'

export default class DialogAction extends DialogNode
  constructor: (@dialogModule, data = {}) ->
    super()
    @type = 'DialogAction'
    @key = data.key
    @label = data.label || ''
    @description = data.description || ''
    @_defaultBranch = new BranchOption(@, target: @target)
    @target = new Target(@, data.target)
    @_failBranch = new BranchOption(@, target: @failTarget)
    @failTarget = new Target(@, data.failTarget)
    @operations = (data.operations || []).map (operationData) => new Operation(@, operationData)
    @branches = (data.branches || []).map (branchData) => new BranchOption(@, branchData)
    @triggeredInitiatives = data.triggeredInitiatives || []

  update: (data = {}) ->
    @key = data.key if !@key && data.key?
    @label = data.label if data.label?
    @description = data.description if data.description?
    @target = new Target(@, data.target) if data.target?
    @failTarget = new Target(@, data.failTarget) if data.failTarget?
    if data.operations?
      @operations = data.operations.map (operationData) -> new Operation(@, operationData)
    if data.branches?
      @branches = (data.branches || []).map (branchData) => new BranchOption(@, branchData)
    @triggeredInitiatives = data.triggeredInitiatives if data.triggeredInitiatives?
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @triggeredInitiatives = data.triggeredInitiatives || []
    this

  clone: ->
    new Action(@dialogModule, @export)

  removeTarget: (node) ->
    if @target.nodeKey == node.key
      @dialogModule.removeNode(@key)
      return
    @branches = @branches.filter (b) -> b.target.nodeKey != node.key

  Object.defineProperties @prototype,
    color:
      get: -> 'yellow'
    export:
      get: ->
        type: @type
        key: @key
        label: @label
        description: @description
        target: @target.export
        failTarget: @failTarget?.export
        operations: @operations.map (operation) -> operation.export
        branches: @branches.map (branch) -> branch.export
        triggeredInitiatives: @triggeredInitiatives
    target:
      get: ->
        @_target
      set: (target) ->
        @_target = target
        @_defaultBranch.target = target
    defaultBranch:
      get: ->
        @_defaultBranch
      set: (branch) ->
        @_defaultBranch = branch
        @_target = branch.target
    failTarget:
      get: ->
        @_failTarget
      set: (target) ->
        @_failTarget = target
        @_failBranch.target = target
    failBranch:
      get: ->
        @_failBranch
      set: (branch) ->
        @_failBranch = branch
        @_failTarget = branch.target
    conditionedTargetNodes:
      get: ->
        @branches.map (branch) -> branch.target.node
          .filter (node) -> node?
    targets:
      get: ->
        [@target].concat(
          @branches.map (branch) => branch.target
            .filter (target) -> target?
        )
    targetNodes:
      get: ->
        @targets.map (target) -> target.node
          .filter (node) -> node?
    targetsWithType:
      get: ->
        list = [{target: @target, kind: 'default'}, {target: @failTarget, kind: 'fail'}].concat(
          @branches.map (branch) => {target: branch.target, kind: 'conditioned'}
        )
          .filter (obj) -> obj.target?
          .unique (val) -> val.target.identifier
        # make sure the default target is rendered as such:
        list.find((val) => val.target.identifier == @target.identifier)?.kind = 'default'
        list
