import { confirm } from 'helpers'

Vue.component 'info-game-attribute',
  props: ['attribute', 'index']
  computed:
    contentQuestionsValid: ->
      GameValidator.variationField(@attribute.contentQuestions)
  created: ->
    # make sure attribute has all required properties
    Vue.setDefault(@attribute, 'name', '')
    Vue.setDefault(@attribute, 'contentQuestions', {})
    Vue.setDefault(@attribute, 'nlg', {})
    Vue.setDefault(@attribute, '$key', GuidGenerator.newGuid())
    @validate()
  watch:
    attribute:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      Vue.set @attribute, '$valid',
        @contentQuestionsValid
    remove: ->
      return unless await confirm("Do you really want to remove attribute “#{@attribute.name}”?")
      @$emit('remove-attribute', @index)
  template: '
    <panel-togglable parent-selector="#attributes-accordion">
      <template v-slot:togglingheader>
        <div class="expand">
            {{ attribute.name }}
        </div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!attribute.$valid"></span>
        </div>
      </template>
      <template v-slot:body>
        <div class="form-group" :class="{\'has-error\': !contentQuestionsValid}">
          <label class="non-bold">How would a user ask for the value of this attribute?</label>
          <multilang-variation-field :dictionary="attribute.contentQuestions"/>
        </div>
        <div class="btn btn-caution pull-right" v-on:click="remove()">Remove attribute from game</div>
      </template>
    </panel-togglable>
  '
