export default class ConditionType
  @TYPES = [
    'similar'
    'notSimilar'
    'exists'
    'notExists'
    'contains'
    'notContains'
    'equals'
    'notEquals'
    'greater'
    'greaterOrEquals'
    'less'
    'lessOrEquals'
  ]

  @TYPES_FOR_OLD_FILTERS = {
    '~': 'similar'
    '!~': 'notSimilar'
    '?': 'exists'
    '!': 'notExists'
    '+': 'contains'
    '-': 'notContains'
    '=': 'equals'
    '!=': 'notEquals'
    '>': 'greater'
    '>=': 'greaterOrEquals'
    '<': 'less'
    '<=': 'lessOrEquals'
  }

  @TYPES_FOR_OLD_INBOX_FILTERS = {
    '~': 'similar'
    '!~': 'notSimilar'
    '=': 'equals'
    '!=': 'notEquals'
  }

  @isUnary: (operator) ->
    ['exists', 'notExists'].includes(operator)
