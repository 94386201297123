
  import SlotDefinition from 'models/slot_definition.coffee'
  import UtteranceSlot from 'models/utterance_slot.coffee'
  import ValueType from 'models/value_type.coffee'

  import SlotRoleSelector from 'components/botbuilder/slot_role_selector.vue'
  import { Badge, Button, Input, Label, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: UtteranceSlot
      host: Object
      parentElement: Node
      disabled:
        type: Boolean
        default: false
      noNewSlots:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      slot: @modelValue
      editing: false
      useExisting: !@disabled && @host?.slotDefinitions?.length
      valueTypes: []
      valueType: null
      selectingAttribute: false
      attributes: []
      attribute: null
      XIcon: XIcon

    computed:
      showNewSlotButton: ->
        !@noNewSlots && @useExisting
      showSelectExistingSlotButton: ->
        !@noNewSlots && !@showNewSlotButton && !@disabled && @host?.slotDefinitions?.length

    watch:
      '$root.contextParameters': -> @setValueTypes()
      '$root.entitySchemas': -> @setValueTypes()
      modelValue: ->
        @slot = @modelValue
      editing: ->
        if @editing
          @$nextTick =>
            return if !@parentElement?
            if @$refs.popover.getBoundingClientRect().left < @parentElement.getBoundingClientRect().left
              @$refs.popover.style.left = "#{@parentElement.getBoundingClientRect().left - @$el.getBoundingClientRect().left + @$refs.popover.getBoundingClientRect().width / 2}px"
            else if @$refs.popover.getBoundingClientRect().right > @parentElement.getBoundingClientRect().right
              @$refs.popover.style.left = "#{@parentElement.getBoundingClientRect().right - @$el.getBoundingClientRect().left - @$refs.popover.getBoundingClientRect().width / 2}px"
          setTimeout =>
            document.addEventListener('click', @clickedOutside)
          , 0
        else
          document.removeEventListener('click', @clickedOutside)

    created: ->
      @setValueTypes()
      @valueType = switch @slot.definition.type
        when 'TypedSlotDefinition'
          @slot.definition.valueType
        when 'AttributeSlotDefinition'
          @slot.definition.parameterKey.replace('contentSearch.', '')

    methods:
      setValueTypes: ->
        @valueTypes = Object.entries(ValueType.TYPES)
          .map ([type, label]) -> {key: type, label: label}
          .concat(
            @$root.contextParameters
              .filter (cp) -> cp.contentSearchSchemaKey?
              .unique (cp) -> cp.contentSearchSchemaKey
              .map (cp) =>
                key: '!--' + cp.contentSearchSchemaKey
                label: @$root.entitySchemas.find((schema) -> schema.key == cp.contentSearchSchemaKey)?.label
          )
      edit: ->
        @editing = true
      setUseExisting: (value) ->
        @slot.definition = @slot.definition.clone()
        @slot.definition.slotRole = ''
        @useExisting = value
      update: ->
        @$emit('update:modelValue', @slot)
      updateValueType: ->
        if !@valueType.startsWith('!--')
          @slot.definition.type = 'TypedSlotDefinition'
          @slot.definition.valueType = @valueType
          @update()
        else
          @slot.definition.type = 'AttributeSlotDefinition'
          schemaKey = @valueType[3..]
          @attributes = @$root.contextParameters
            .filter (cp) -> cp.contentSearchSchemaKey == schemaKey
            .map (cp) =>
              key: cp.key
              label: if cp.contentSearchBasicAttr
                cp.contentSearchBasicAttr
              else if cp.contentSearchAttribute
                @$root.entitySchemas
                  .find((schema) -> schema.key == schemaKey)
                  ?.attributes
                  .find((attribute) -> attribute.key == cp.contentSearchAttribute)
                  ?.label
          @attribute = @attributes[0]?.key
          @slot.definition.parameterKey = @attribute # set parameter in order to avoid an invalid slot definition
          @update()
          @selectingAttribute = true
          @$nextTick =>
            @$refs.attributeSelector.open()
      updateAttribute: ->
        return if !@attribute?
        @selectingAttribute = false
        @slot.definition.parameterKey = @attribute
        @valueType = @attribute.replace('contentSearch.', '')
        @update()
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$refs.popover)
        @editing = false

    components:
      Badge: Badge
      Button: Button
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      SlotRoleSelector: SlotRoleSelector
      XIcon: XIcon
