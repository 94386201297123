import GuidGenerator from 'models/guid_generator.coffee'

export default class WorkflowBotOutput
  constructor: (@workflowTrigger, data = {}) ->
    @id = GuidGenerator.newGuid()
    @key = data.key || ''
    @jsonPath = data.jsonPath || ''
    @description = data.description || ''
    @valueType = data.valueType || 'string'
    @collectionType = data.collectionType || 'none'
    @testOverrideValue = data.testOverrideValue || null
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key if data.key?
    @jsonPath = data.jsonPath if data.jsonPath?
    @description = data.description if data.description?
    @valueType = data.valueType if data.valueType?
    @collectionType = data.collectionType if data.collectionType?
    @testOverrideValue = data.testOverrideValue if data.testOverrideValue?

  Object.defineProperties @prototype,
    workflowId:
      get: ->
        @workflowTrigger.workflow.id
    export:
      get: ->
        key: @key
        jsonPath: @jsonPath
        description: @description
        valueType: @valueType
        collectionType: @collectionType
        testOverrideValue: @testOverrideValue
