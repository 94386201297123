
  import KnowledgeSource from 'models/knowledge_source'

  import Chart from './chart.vue'
  import { CommandPalette } from 'components/generic'
  import { PencilIcon, PresentationChartLineIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      data: Object
      labels: Array
      intentCharts: Object

    emits: ['update-intents']

    data: ->
      editing: false
      availableIntents: []
      intents:
        bot: []
        user: []
      selectedSide: 'user'
      selectedChartIndex: 0
      selectedIntents: []
      knowledgeSourceLabelMap: {}

    computed:
      userIntentsData: ->
        if @data.intents.counts.status == 'ready'
          @data.intents.counts.data.countUserIntents
        else
          {}
      top10UserIntents: ->
        list = Object.entries(@userIntentsData)
          .filter ([key, count]) =>
            !(
              key.startsWith('HOOK/') ||
              key.startsWith('CONTEXT_INITIATIVE/') ||
              key.startsWith('KNOWLEDGE/') ||
              @$root.intentSignatures.trigger.map((intent) -> intent.intentIdentifier).includes(key)
            )
          .sort((a, b) -> b[1] - a[1])[0..9]
        data: list.map (entry) -> entry[1]
        labels: list.map (entry) -> entry[0]
      top10TriggerIntents: ->
        list = Object.entries(@userIntentsData)
          .filter ([key, count]) =>
            @$root.intentSignatures.trigger.map((intent) -> intent.intentIdentifier).includes(key)
          .sort((a, b) -> b[1] - a[1])[0..9]
        data: list.map (entry) -> entry[1]
        labels: list.map (entry) -> entry[0]
      top10ContextInitiatives: ->
        list = Object.entries(@userIntentsData)
          .filter ([key, count]) -> key.startsWith('CONTEXT_INITIATIVE/')
          .sort((a, b) -> b[1] - a[1])[0..9]
        data: list.map ([key, count]) -> count
        labels: list.map ([key, count]) =>
          parameterKey = key.split('/').last
          @$root.contextParameterByKey(parameterKey)?.label || parameterKey
      top10KnowledgeSourceList: ->
        Object.entries(@userIntentsData)
          .filter ([key, count]) -> key.startsWith('KNOWLEDGE/')
          .sort((a, b) -> b[1] - a[1])[0..9]
      top10KnowledgeSources: ->
        data: @top10KnowledgeSourceList.map ([key, count]) -> count
        labels: @top10KnowledgeSourceList.map ([key, count]) =>
          @parseUuids(key).map((id) => @knowledgeSourceLabelMap[id]).join(', ')
      top10BotIntents: ->
        data = if @data.intents.counts.status == 'ready' then @data.intents.counts.data.countBotIntents else {}
        list = Object.entries(data).sort((a, b) -> b[1] - a[1])[0..9]
        data: list.map (entry) -> entry[1]
        labels: list.map (entry) -> entry[0]

    watch:
      top10KnowledgeSourceList: ->
        ids = @top10KnowledgeSourceList
          .map ([key, count]) => @parseUuids(key)
          .flat()
          .unique()
        ids.forEach (id) =>
          @knowledgeSourceLabelMap[id] = id
          KnowledgeSource.label(id)
            .then (label) =>
              @knowledgeSourceLabelMap[id] = label
              @$refs.knowledgeSourcesChart.redraw()

    methods:
      setAvailableIntents: ->
        @availableIntents = @$root.intentSignatures[@selectedSide].slice().sort (a, b) =>
          labelA = if @$root.isBotcore2 then a.intentIdentifier else "#{ a.gameLabel }/#{ a.intentLabel }"
          labelB = if @$root.isBotcore2 then b.intentIdentifier else "#{ b.gameLabel }/#{ b.intentLabel }"
          if @selectedIntents.includes(labelA) && !@selectedIntents.includes(labelB)
            -1
          else if @selectedIntents.includes(labelB) && !@selectedIntents.includes(labelA)
            1
          else 0
      additionalActions: (side, chartIndex) ->
        [
          {icon: PencilIcon, label: 'Edit', method: (=> @edit(side, chartIndex))},
          {icon: XCircleIcon, label: 'Remove', method: (=> @remove(side, chartIndex))},
        ]
      botDatasets: (intentList) ->
        if @data.intents.series.status != 'ready'
          intentList.map (intentLabel) =>
            label: intentLabel
            data: []
        else
          intentList.map (intentLabel) =>
            resultObject = @data.intents.series.data.botIntents.find (intent) -> intent.intent == intentLabel
            label: intentLabel
            data: if resultObject? then resultObject.occurrence else []
      userDatasets: (intentList) ->
        if @data.intents.series.status != 'ready'
          intentList.map (intentLabel) =>
            label: intentLabel
            data: []
        else
          intentList.map (intentLabel) =>
            resultObject = @data.intents.series.data.userIntents.find (intent) -> intent.intent == intentLabel
            legendText = if resultObject?
              Object.keys(resultObject.canonicalUtterances)
              .map((lang) -> "#{lang.capitalize()}: #{resultObject.canonicalUtterances[lang]}")
              .join("\n")
            label: intentLabel
            data: if resultObject? then resultObject.occurrence else []
            legendTooltip: legendText
      intentFilter: (intent, query) ->
        if @$root.isBotcore2
          intent.key.toLowerCase().startsWith(query.toLowerCase()) ||
            intent.moduleKey.toLowerCase().startsWith(query.toLowerCase())
        else
          intent.gameLabel.toLowerCase().startsWith(query.toLowerCase()) ||
            intent.intentLabel.toLowerCase().startsWith(query.toLowerCase())
      emit: ->
        @$emit('update-intents',
          bot: @intentCharts.bot.filter((list) -> list.length)
          user: @intentCharts.user.filter((list) -> list.length)
        )
      addChart: (side)->
        @edit(side, @intentCharts[side].length)
      edit: (side, chartIndex) ->
        @selectedChartIndex = chartIndex
        @selectedSide = side
        @selectedIntents = @intentCharts[side][chartIndex] || []
        @setAvailableIntents()
        @editing = true
      apply: ->
        @intentCharts[@selectedSide][@selectedChartIndex] = @selectedIntents
        @emit()
      remove: (side, chartIndex) ->
        @intentCharts[side].splice(chartIndex, 1)
        @emit()
      intentLabel: (intent) ->
        if @$root.isBotcore2
          intent.intentIdentifier
        else
          "#{intent.gameLabel}/#{intent.intentLabel}"
      parseUuids: (key) ->
        key.split('/').last.replaceAll('_', '-').split('--')

    components:
      CommandPalette: CommandPalette
      Chart: Chart
      PresentationChartLineIcon: PresentationChartLineIcon
      XCircleIcon: XCircleIcon
