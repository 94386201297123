
import PlatformApi from 'models/platform_api.coffee'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default
  components:
    Switch: Switch
    SwitchGroup: SwitchGroup
    SwitchLabel: SwitchLabel

  props:
    modelValue: Boolean

  data: ->
    enabled: @modelValue
    requestFailed: false

  watch:
    modelValue: ->
      @enabled = @modelValue
    enabled: ->
      if @requestFailed
        @requestFailed = false
        return
      PlatformApi.Handover.agentManualCheck(@enabled)
        .then =>
          @$emit('update:modelValue', @enabled)
        .catch =>
          @requestFailed = true
          @enabled = !@enabled
