export default class WhatsappPromotionJob
  @ATTRIBUTES = [
    'name'
    'description'
    'status'
    'channelId'
    'config'
    'parameters'
    'statistics'
    'messages'
    'dateCreated'
    'dateModified'
    'dateSubmitted'
    'dateFinished'
  ]

  constructor: (data) ->
    @id = data.id
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  Object.defineProperties @prototype,
    sent:
      get: ->
        ['SENDING', 'SUCCEEDED', 'FAILED'].includes(@status)
    estimatedCostFormatted:
      get: ->
        return '0.00 €' if !@statistics?.estimatedCostCents?
        "#{(@statistics.estimatedCostCents / 100).toFixed(2)} €"
    templateName: # convenience accessor for display and sorting
      get: ->
        @config?.templateName
    audienceSize: # convenience accessor for display and sorting
      get: ->
        @statistics?.audienceSize
