Vue.component 'support-option-condition',
  props: ['condition', 'index', 'count']
  data: ->
    state: state
  computed:
    valueType: ->
      contextParameter = @state.availableContextParameters.find((param) => param.label == @condition.label)
      contextParameter?.type
    availableOperators: ->
      TypeValidator.availableOperators(@valueType)
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @condition.operator)
    parameterValid: ->
      @state.availableContextParameters.map((param) -> param.label).indexOf(@condition.label) >= 0
    operatorValid: ->
      @availableOperators[@condition.operator]?
    valueValid: ->
      GameValidator.placeholder(@condition.value) ||
        TypeValidator.valueValid(
          @condition.value,
          @valueType,
          @condition.operator,
          false
        )
  watch:
    condition:
      handler: -> @validate()
      deep: true
  created: ->
    Vue.setDefault(@condition, 'label', '')
    Vue.setDefault(@condition, 'operator', '')
    Vue.setDefault(@condition, 'value', '')
    @validate()
  methods:
    validate: ->
      Vue.set @condition, '$valid',
        @parameterValid && @operatorValid && @valueValid
    remove: ->
      @$emit('remove', @index)
  template: '
    <div class="flex-container center-items sub-form-group">

      <div :class="{\'has-error\': !parameterValid}" class="width-30">
        <select class="form-control" v-model="condition.label">
          <option disabled="" value="">parameter</option>
          <option v-for="param in state.availableContextParameters" :value="param.label">{{ param.humanizedLabel || param.label }}</option>
        </select>
      </div>&nbsp;

      <div :class="{\'has-error\': !operatorValid}" class="width-20">
        <select class="form-control" v-model="condition.operator">
          <option disabled="" value="">operator</option>
          <option :value="operator" v-for="(text, operator) in availableOperators">{{ text }} ({{ operator }})</option>
        </select>
      </div>&nbsp;

      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input :placeholder="valueLabel" class="form-control" type="text" v-model="condition.value">
      </div>&nbsp;

      <div v-if="count > 1" @click="remove" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>

    </div>
  '
