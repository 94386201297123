import { confirm } from 'helpers'

Vue.component 'context-parameter',
  props: ['parameter', 'index']
  data: ->
    editing: false
    copy: null
    properties:
      label: ''
      humanizedLabel: ''
      description: ''
      displayInInbox: false
      type:
        type: ''
        list: false
      initialValue: ''
      $key: GuidGenerator.newGuid()
  created: ->
    # make sure parameter has all required properties
    for property, defaultValue of @properties
      Vue.setDefault(@parameter, property, defaultValue)
    # account for old format
    if @parameter.name?
      @parameter.label = @parameter.name
      delete @parameter.name
  methods:
    edit: ->
      @$emit('edit-parameter')
      @copy = ObjectProcessor.clone(@parameter, removeTransientProperties: false)
      @editing = true
    save: ->
      if @copy.label != @parameter.label && @parameter.label != '' # so the warning won't be displayed when the label wasn't changed or for newly created parameters
        return unless await confirm("Changing the context parameter’s label will break existing references to this parameter. Continue?")
      for property of @properties
        @parameter[property] = @copy[property]
      @editing = false
      @$emit('save-parameter', @parameter, @index)
    cancel: ->
      @editing = false
    remove: ->
      return unless await confirm("Do you really want to remove context parameter #{@parameter.label}?")
      @$emit('remove-parameter', @index)
    valueValid: (param) ->
      param.type.list ||
        param.initialValue == '' ||
        TypeValidator.valueValid(param.initialValue, param.type, '=', false)
  template: '
    <tr>
      <template v-if="!editing">
        <td>{{ parameter.label }}</td>
        <td>{{ parameter.humanizedLabel }}</td>
        <td>{{ parameter.description }}</td>
        <td>{{ parameter.displayInInbox }}</td>
        <td>{{ (parameter.type.list ? "List of " : "") + parameter.type.type }}</td>
        <td>
          {{ parameter.type.list ? "" : parameter.initialValue }}
          <span v-if="!valueValid(parameter)">
            &nbsp;
            <span class="fa fa-exclamation-circle text-danger"></span>
          </span>
        </td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button @click="edit" class="btn btn-icon">
              <span class="fa fa-pen"></span>
            </button>
            <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete\', method: remove}]"/>
          </div>
        </td>
      </template>

      <template v-if="editing">
        <td>
          <input type="text" v-model="copy.label" class="form-control">
        </td>
        <td>
          <input type="text" v-model="copy.humanizedLabel" class="form-control">
        </td>
        <td>
          <input type="text" v-model="copy.description" class="form-control">
        </td>
        <td>
          <input type="checkbox" v-model="copy.displayInInbox" value="true">
        </td>
        <td>
          <type-select :model-type="copy.type"/>
        </td>
        <td :class="{\'has-error\': !valueValid(copy)}">
          <input v-if="!copy.type.list" type="text" v-model="copy.initialValue" class="form-control">
        </td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button @click="save" class="btn btn-icon primary permanent">
              <span class="fa fa-check"></span>
            </button>
            <button @click="cancel" class="btn btn-icon permanent">
              <span class="fa fa-times"></span>
            </button>
          </div>
        </td>
      </template>
    </tr>
  '
