
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, XIcon } from '@heroicons/vue/outline'

export default
  props:
    title: String
    alert: [Boolean, String]
    displayCloseButton:
      type: Boolean
      default: true

  data: ->
    isOpen: false

  methods:
    open: ->
      @isOpen = true
    close: ->
      @$emit('close')
      @isOpen = false

  components:
    Dialog: Dialog
    DialogOverlay: DialogOverlay
    DialogTitle: DialogTitle
    ExclamationIcon: ExclamationIcon
    TransitionChild: TransitionChild
    TransitionRoot: TransitionRoot
    XIcon: XIcon
