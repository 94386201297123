Vue.component 'global-context-parameters',
  props: ['parameters']
  data: ->
    state: state
  methods:
    save: ->
      $.post(Routes.update_context_parameters_project_bot_path(Globals.projectId, Globals.stageLevel, @state.botId),
        _method: 'patch'
        context_parameters: JSON.stringify(ObjectProcessor.clone(@parameters))
      )
  template: '
    <context-parameters-table
      :parameters="parameters"
      @save-parameter="save"
      @remove-parameter="save"
      />
  '
