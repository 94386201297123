Vue.component 'support-option-steps',
  props: ['option']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    entity: @option
    role: 'head'
    name: 'a step'
    attributes: []
    currentStepIndex: null
    isMounted: false
    stepRefs: []
  computed:
    complete: ->
      @option.steps.length > 0
    showRightColumn: ->
      @editing
    somePristine: ->
      return false if !@editing
      return true if !@isMounted
      @stepRefs.some((step) -> step.pristine)
  watch:
    currentStepIndex: ->
      Vue.nextTick =>
        # ensure correct slider rendering of step nlg sliders
        $('.slick-slider').each -> this.slick.setPosition()
  created: ->
    if @option.steps.length == 0
      @edit()
    else
      @pristine = false
    @addStep() if @option.steps.length == 0
  mounted: ->
    @isMounted = true
  beforeUpdate: ->
    @stepRefs = []
  methods:
    edit: ->
      @state.closeRightColumn()
        .then =>
          @editing = true
          @currentStepIndex = 0 if !@pristine
    confirm: ->
      return unless @complete
      @pristine = false
      @currentStepIndex = 0 if !@currentStepIndex?
    addStep: ->
      @option.steps.push({})
    removeStep: (index) ->
      @option.steps.splice(index, 1)
      if @currentStepIndex > @option.steps.length - 1
        @currentStepIndex = @option.steps.length - 1
      Vue.nextTick =>
        # ensure correct slider rendering of step nlg sliders
        $('.slick-slider').each -> this.slick.setPosition()
    stepFinishedAndProceed: ->
      if @currentStepIndex < @option.steps.length - 1
        @currentStepIndex += 1
      else
        @finished()
    finished: ->
      return unless @complete
      @confirm()
      @editing = false
      Vue.setDefault(@option, 'failWithoutOptions', {})
    setStepRef: (el) ->
      @stepRefs.push(el) if el?
  template: '
    <div>
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-3" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container start-items">
                <div class="expand steps">
                  <h5 v-if="editing" class="no-margin-top">Solution steps</h5>
                  <label v-else class="text-light">Solution steps</label>
                  <div v-for="step, index in option.steps" class="step" :class="{radio: editing}">
                    <label v-if="editing" class="text-light flex-container center-items">
                      <input type="radio" v-model="currentStepIndex" :value="index" :disabled="somePristine ? true : null">
                      Step {{ index + 1 }}
                      <div v-if="option.steps.length > 1" @click="removeStep(index)" class="btn btn-remove">
                        <span class="fa fa-times-circle"></span>
                      </div>
                    </label>
                    <div v-if="!editing" class="panel-label">Step {{ index + 1 }}</div>
                  </div>
                  <plus-button v-if="editing" label="add step" :method="addStep"/>
                </div>
                <div v-if="!editing" @click="edit" class="btn btn-icon">
                  <span class="fa fa-pen"></span>
                </div>
              </div>
              <support-confirm-button v-if="pristine"/>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-option-step
              :ref="setStepRef"
              v-for="step, index in option.steps"
              v-show="index == currentStepIndex"
              :step="step"
              :option="option"
              :index="currentStepIndex"
              :key="step.$key"
              @step-finished="stepFinishedAndProceed"
              @finished="finished"
              />
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>

      <support-option-fail v-if="option.failWithoutOptions" :option="option"/>
    </div>
  '
