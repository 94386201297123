
  import Badge from './badge.vue'
  import Label from './label.vue'

  export default
    props:
      modelValue: Number
      label: String
      min:
        type: Number
        default: 0
      max:
        type: Number
        default: 100
      unit:
        type: String
        default: ''

    data: ->
      value: @modelValue

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      emit: ->
        @$emit('update:modelValue', parseInt(@value))

    components:
      Badge: Badge
      Label: Label
