Vue.component 'teaser-url-condition',
  props: ['condition', 'index', 'count', 'parameters', 'allowDeleteLast']

  computed:
    valueType: ->
      parameter = @parameters.find((param) => param.label == @condition.parameter)
      parameter?.type
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @condition.operator)
    valueValid: ->
      GameValidator.placeholder(@condition.value) ||
        TypeValidator.valueValid(
          @condition.value,
          @valueType,
          @condition.operator
        )

  created: ->
    Vue.setDefault(@condition, 'parameter', '')
    Vue.setDefault(@condition, 'operator', '=')
    Vue.setDefault(@condition, 'value', '')
    Vue.setDefault(@condition, '$key', GuidGenerator.newGuid())

  methods:
    remove: ->
      @$emit('remove', @index)

  template: '
    <div class="flex-container center-items sub-form-group">

      <div class="width-30">
        <select class="form-control" v-model="condition.parameter">
          <option disabled="" value="">parameter</option>
          <option v-for="param in parameters" :value="param.label">{{ param.humanizedLabel || param.label }}</option>
        </select>
      </div>&nbsp;

      <div class="width-10 flex justify-center">
        =
      </div>&nbsp;

      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input type="text"
          v-model="condition.value"
          :placeholder="valueLabel"
          class="form-control"
          >
      </div>&nbsp;

      <div v-if="count > 1 || allowDeleteLast" @click="remove" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>

    </div>
  '
