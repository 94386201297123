
  import Conversation from 'models/conversation'

  import AnswerTemplateList from './answer_template_list.vue'
  import Message from './message.vue'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { ChatAltIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      conversation: Conversation
      small: Boolean

    methods:
      selectTemplate: (closePopup, template) ->
        @$emit('select', template)
        closePopup()

    components:
      AnswerTemplateList: AnswerTemplateList
      ChatAltIcon: ChatAltIcon
      Message: Message
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
      XIcon: XIcon
