
  import DialogTest from 'models/dialog_test.coffee'
  import DialogTestElement from 'models/dialog_test_element.coffee'

  import DraggableItem from 'components/shared/draggable_item.vue'
  import DropArea from 'components/shared/drop_area.vue'
  import ElementDisplay from './element_display.vue'
  import { ExternalLinkIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: DialogTest

    emits: ['update:modelValue', 'edit']

    data: ->
      test: @modelValue
      draggingIndex: null
      draggedOverDropAreaIndex: null

    computed:
      failedElementId: ->
        return if !@test.error?
        @test.error.elementId

    watch:
      modelValue: ->
        @test = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @test)
      remove: (index) ->
        @test.elements.splice(index, 1)
        @update()
      startDragging: (index) ->
        setTimeout => # avoid y-offset on dragged element
          @draggingIndex = index
        , 0
      stopDragging: ->
        @draggingIndex = null
      dropElement: (event, index) ->
        return if !@draggingIndex?
        element = @test.elements[@draggingIndex]
        @test.elements.splice(@draggingIndex, 1)
        @test.elements.splice(index, 0, element)
        @draggingIndex = null
        @update()
      scrollToBottom: ->
        @$refs.list.scrollTop = @$refs.list.scrollHeight

    components:
      DraggableItem: DraggableItem
      DropArea: DropArea
      ElementDisplay: ElementDisplay
      ExternalLinkIcon: ExternalLinkIcon
