Vue.component 'bubbles-editor',
  props: ['bubbles', 'multiple', 'titles', 'messageState', 'slider', 'message']
  data: ->
    sliderOptions:
      infinite: false
      accessibility: false # disables arrow key navigation (we need the arrow keys in the input fields)
      swipe: false
      prevArrow: '<button class="slick-prev"><span class="fa fa-chevron-left"></span></button>'
      nextArrow: '<button class="slick-next"><span class="fa fa-chevron-right"></span></button>'
    currentSlide: null
    noWatcher: false
    renderSlider: true
  created: ->
    for bubble in @bubbles
      Vue.setDefault(bubble, '$key', GuidGenerator.newGuid())
  methods:
    addBubble: (index) ->
      @$emit('add-bubble', index)
      @refreshSlider(@currentSlide + 1)
    removeBubble: (index, $key) ->
      @$emit('remove-bubble', $key)
      Vue.nextTick =>
        @refreshSlider(Math.min(index, @bubbles.length - 1))
    refreshSlider: (initialSlide) ->
      @renderSlider = false
      @currentSlide = initialSlide
      @sliderOptions.initialSlide = @currentSlide
      Vue.nextTick =>
        @renderSlider = true
    sliderChange: (event, slick) ->
      try
        @currentSlide = @$refs.slider.currentSlide()
  template: '
    <slick
      v-if="slider && renderSlider"
      :options="sliderOptions"
      ref="slider"
      @after-change="sliderChange"
      @set-position="sliderChange"
      class="gallery-slider"
      :class="{multiple: multiple}"
      >
      <bubble-editor
        v-for="(bubble, index) in bubbles"
        :key="bubble.$key"
        :bubble="bubble"
        :index="index"
        :count="bubbles.length"
        :multiple="multiple"
        :title="titles ? titles[index] : null"
        :message-state="messageState"
        :message="message"
        @add-bubble="addBubble"
        @remove-bubble="removeBubble"
        />
    </slick>
    <div v-else>
      <bubble-editor
        v-for="(bubble, index) in bubbles"
        :key="bubble.$key"
        :bubble="bubble"
        :index="index"
        :count="bubbles.length"
        :multiple="multiple"
        :title="titles ? titles[index] : null"
        :message-state="messageState"
        :message="message"
        @add-bubble="addBubble"
        @remove-bubble="removeBubble"
        />
    </div>
  '
