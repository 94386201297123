Vue.component 'payload-editor',
  props:
    payload: Object
    fixedGame: String
    side:
      type: String
      default: 'user'
  data: ->
    state: state
    operatorRegex: /\+|\-|\=|\<|\>|\~|\!/
  computed:
    gameLabels: ->
      return [] if !@state.intentSignatures?
      Array.unique @state.intentSignatures[@side].map((signature) -> signature.gameLabel)
    intentLabels: ->
      return [] if !@state.intentSignatures? || !@payload.game
      @state.intentSignatures[@side].filter((signature) => signature.gameLabel == @payload.game)
        .map((signature) -> signature.intentLabel)
    slotLabels: ->
      Object.keys(@currentSignature.slotTypes).sort()
    currentSignature: ->
      return {slotTypes: {}} if !@state.intentSignatures?
      currentSignature = @state.intentSignatures[@side].find (signature) =>
        @payload.game == signature.gameLabel && @payload.intent == signature.intentLabel
      if currentSignature? then currentSignature else {slotTypes: {}}
    showAddSlotsButton: ->
      @currentSignature && @slotLabels.length > 0
    showReloadButton: ->
      @state.reloadIntentSignatures?
  watch:
    'state.intentSignatures':
      handler: -> @validate()
      deep: true
    'payload.game': ->
      @payload.intent = ''
      @payload.slots = []
    'payload.intent': ->
      return if @state.intentSignaturesLoading
      @payload.slots = @payload.slots.filter (slot) => slot.label of @currentSignature.slotTypes
    payload:
      handler: -> @validate()
      deep: true
  created: ->
    Vue.setDefault(@payload, 'slots', [])
    Vue.setDefault(@payload, 'game', '')
    Vue.setDefault(@payload, 'intent', '')
    Vue.set(@payload, 'game', @fixedGame) if @fixedGame?
    @validate()
  methods:
    validate: ->
      Vue.set @payload, '$valid',
        @payload.game in @gameLabels &&
        @payload.intent in @intentLabels &&
        @payload.slots.every((slot) => slot.label of @currentSignature.slotTypes) &&
        @payload.slots.every((slot) => @valueValid(slot)) &&
        @slotsUnique()
    addSlot: ->
      @payload.slots.push(
        label: ''
        value: ''
      )
    removeSlot: (index) ->
      @payload.slots.splice(index, 1)
    valueLabel: (slotLabel) ->
      TypeValidator.valueLabel(@currentSignature.slotTypes[slotLabel], @slotLabelOperator(slotLabel))
    valueValid: (slot) ->
      TypeValidator.valueValid(slot.value, @currentSignature.slotTypes[slot.label], @slotLabelOperator(slot.label)) ||
        GameValidator.placeholder(slot.value)
    bareSlotLabel: (slotLabel) ->
      operatorIndex = slotLabel.search(@operatorRegex)
      slotLabel.slice(0, operatorIndex)
    slotLabelOperator: (slotLabel) ->
      operatorIndex = slotLabel.search(@operatorRegex)
      slotLabel.slice(operatorIndex)
    slotsUnique: ->
      bareSlotLabels = @payload.slots.map (slot) => @bareSlotLabel(slot.label)
      bareSlotLabels.length == Array.unique(bareSlotLabels).length
  template: '
    <div class="payload-editor">
      <div class="flex-container wrap sub-form-group intent-selector">
        <input v-if="fixedGame" v-model="payload.game" type="text" disabled="" class="styled-form-field expand">
        <select v-else v-model="payload.game" class="styled-form-field expand" :disabled="state.intentSignaturesLoading ? true : null">
          <option v-for="gameLabel in gameLabels" :value="gameLabel">
            {{ gameLabel }}
          </option>
        </select>
        <select v-model="payload.intent" class="styled-form-field expand" :disabled="state.intentSignaturesLoading ? true : null">
          <option v-for="intentLabel in intentLabels" :value="intentLabel">
            {{ intentLabel }}
          </option>
        </select>
      </div>
      <div v-for="(slot, index) in payload.slots" class="slot flex-container center-items sub-form-group">
        <div class="expand flex-container wrap">
          <select v-model="slot.label" class="slot-label styled-form-field" :disabled="state.intentSignaturesLoading ? true : null">
            <option v-for="slotLabel in slotLabels" :value="slotLabel">
              {{ slotLabel }}
            </option>
          </select>
          <div :class="{\'has-error\': !valueValid(slot)}" class="expand">
            <input
              v-model="slot.value"
              :placeholder="valueLabel(slot.label)"
              type="text"
              class="form-control"
              :disabled="state.intentSignaturesLoading ? true : null"
              />
          </div>
        </div>
        <div @click="removeSlot(index)" class="btn btn-remove">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>
      <div v-if="showAddSlotsButton" class="sub-form-group flex">
        <plus-button label="add slot" :method="addSlot"/>
        <info-button info-key="intent_slots"/>
      </div>
      <div v-if="showReloadButton && state.intentSignaturesLoading" class="btn btn-default intent-signatures-button" disabled="">
        <span class="fa fa-sync fa-spin"></span>
        loading intent signatures
      </div>
      <div v-else-if="showReloadButton && state.intentSignatures" class="btn btn-default intent-signatures-button" @click="state.reloadIntentSignatures()">
        <span class="fa fa-sync"></span>
        reload intent signatures
      </div>
      <div v-else-if="showReloadButton && state.intentSignaturesError" class="flex-container columns start-items has-error">
        <label class="control-label">Loading intent signatures failed</label>
        <div @click="state.reloadIntentSignatures()" class="btn btn-default intent-signatures-button">
          <span class="fa fa-sync"></span>
          retry
        </div>
      </div>
    </div>
  '
