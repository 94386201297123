Vue.component 'language-states-and-selection',
  props:
    languageStates:
      type: Object
      default: -> {}
    tableTitle:
      type: String
      default: 'Text fields and messages filled:'
  data: ->
    state: state
    channelTypeLogos: Globals.channelTypeLogos
  methods:
    languageStateLabelClass: (state) ->
      {
        none: 'label-info'
        all: 'label-success'
        some: 'label-warning'
      }[state]
  template: '
    <div class="language-select">
      <dropdown :chosen="state.languages[state.currentLanguage]" align="right">
        <li class="title">
          <div class="language-column"></div>
          <div v-for="medium in state.media" class="label-column">
            <img class="logo" v-if="channelTypeLogos[medium.toUpperCase()]" :src="channelTypeLogos[medium.toUpperCase()]"/>
          </div>
        </li>
        <li v-for="languageState, languageCode in languageStates">
          <a @click="state.currentLanguage = languageCode">
            <div class="language-column">{{ state.languages[languageCode] }}</div>
            <div v-for="medium in state.media" class="label-column">
              <div class="label" :class="languageStateLabelClass(languageState[medium])">
                {{ languageState[medium] }}
              </div>
            </div>
          </a>
        </li>
      </dropdown>
    </div>
  '
