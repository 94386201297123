
  import WidgetTeaserCondition from 'models/widget_teaser_condition.coffee'

  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import { Input, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: WidgetTeaserCondition

    emits: ['update:modelValue', 'remove']

    data: ->
      condition: @modelValue
      conditionTypesForSelect: WidgetTeaserCondition.OPERATOR_TYPES.map (operator) -> key: operator, label: operator

    computed:
      valueType: ->
        return 'string' unless @variable?
        @variable.valueType
      collectionType: ->
        return 'string' unless @variable?
        @variable.collectionType

    watch:
      modelValue: ->
        @condition = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @condition)

    components:
      ContextParameterSelector: ContextParameterSelector
      Input: Input
      KeyListbox: KeyListbox
      XIcon: XIcon
