import { confirm } from 'helpers'

Vue.component 'support-topic',
  props: ['topic', 'index']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    collapsed: true
    entity: @topic
    role: 'head'
    name: "topic/problem #{@index + 1}"
    attributes:
      name:
        type: 'String'
        default: ''
      autostart:
        type: 'Boolean'
        default: false
    nameCopy: ''
    autostartCopy: false
  computed:
    showCollapseButton: ->
      @collapsed && !@pristine
    showRightColumn: ->
      return false if @pristine
      return true if @editing
      return false if @topic.options?
      true
    showAddOptionButton: ->
      return false if !@topic.options?.length
      !@topic.options[-1..][0].fresh
  watch:
    pristine: ->
      if @topic.fresh && !@pristine
        @collapsed = false
        Vue.nextTick =>
          $(@$el).find('.collapsing-part').addClass('in')
  mounted: -> # use mounted so we can immediately focus the input field
    if !@topic.name?
      @edit()
    else
      @pristine = false # sideeffect (through watcher): start expanded if topic has been started but is still fresh
    @setCopy()
  methods:
    remove: ->
      return unless await confirm("Do you really want do delete topic “#{@topic.name}”?")
      @$emit('remove', @index)
    expand: ->
      for topic in @$parent.topicRefs
        topic.collapse() if topic != @
      @collapsed = false
      Vue.nextTick => $(@$el).find('.collapsing-part').collapse('show')
    collapse: ->
      $(@$el).find('.collapsing-part').collapse('hide').one 'hidden.bs.collapse', =>
        @collapsed = true
    finished: ->
      return unless @complete
      @confirm()
      @editing = false
      Vue.setDefault(@topic, 'options', [{fresh: true}])
    addOption: ->
      @state.closeRightColumn()
        .then =>
          @topic.options.push({fresh: true})
    removeOption: (index) ->
      @topic.options.splice(index, 1)
    addNoOptionsMessage: ->
      @state.closeRightColumn()
        .then =>
          Vue.set(@topic, 'noOptionsMessage', {})
  template: '
    <div class="support-topic">
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-1" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container center-items">
                <div class="expand">
                  <h5 v-if="editing" class="no-margin-top">Topic/Problem {{ index + 1 }}</h5>
                  <label v-else class="text-light">Topic/Problem {{ index + 1 }}</label>
                  <input
                    v-if="editing"
                    type="text"
                    v-model="nameCopy"
                    @keyup.enter="confirm"
                    class="form-control wizard-style"
                    placeholder="e.g. access issues, account hacked, ..."
                    >
                  <div v-if="!editing" class="panel-label">{{ topic.name }}</div>
                  <label v-if="editing" class="sliding-switch align text-light">
                    Autostart
                    <input type="checkbox" v-model="autostartCopy">
                    <span class="slider"></span>
                  </label>
                </div>
                <template v-if="!showCollapseButton">
                  <div v-if="!editing" @click="edit" class="btn btn-icon">
                    <span class="fa fa-pen"></span>
                  </div>
                  <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete topic\', method: remove}]"/>
                </template>
                <div v-if="showCollapseButton" @click="expand" class="btn">
                  <div class="chevron"></div>
                </div>
              </div>
              <support-confirm-button v-if="editing"/>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-topic-utterances :topic="topic" @finished="finished" @discard="discard"/>
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>

      <div v-if="!collapsed" class="collapsing-part collapse">
        <support-option v-for="option, index in topic.options" :option="option" :index="index" :topic="topic" @remove="removeOption"/>

        <div v-if="showAddOptionButton" class="responsive-grid-container no-vertical-spacing no-gutter">
          <div class="responsive-grid-item item-300">
            <div class="panel panel-transparent no-margin">
              <div class="panel-body text-center">
                <div @click="addOption" class="btn btn-default">Add additional solution</div>
                <template v-if="!topic.noOptionsMessage">
                  <b>&ensp;or&ensp;</b>
                  <div @click="addNoOptionsMessage" class="btn btn-default">Add fallback message</div>
                </template>
              </div>
            </div>
          </div>
          <div class="responsive-grid-spacer item-300"></div>
        </div>

        <support-no-options v-if="topic.noOptionsMessage" :topic="topic"/>
      </div>
    </div>
  '
