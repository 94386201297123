
  import DropArea from 'components/shared/drop_area.vue'
  import { BellIcon, CogIcon } from '@heroicons/vue/outline'

  export default
    props:
      show:
        type: Boolean
        default: true
      size:
        type: String
        default: 'sm'

    emits: ['drop']

    data: ->
      draggedOver: false

    components:
      BellIcon: BellIcon
      CogIcon: CogIcon
      DropArea: DropArea
