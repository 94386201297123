Vue.component 'action-menu',
  props: ['items']
  template: '
    <div class="action-menu dropdown">
      <div class="btn btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true">
        <span class="fa fa-ellipsis-h"/>
      </div>
      <ul class="dropdown-menu dropdown-menu-right">
        <li class="title">Actions</li>
        <li v-for="item in items" :class="{hr: item.hr}">
          <a v-if="item.method" @click.prevent="item.method" href="">
            <span class="fa-fw" :class="item.icon"/>
            {{ item.label }}
          </a>
          <a v-if="item.href" :href="item.href">
            <span class="fa-fw" :class="item.icon"/>
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  '
