
  export default
    props:
      label: String
      type: String
      color:
        type: String
        default: 'blue'

    computed:
      classes: ->
        switch @type
          when 'badge'
            # we need this for TailwindCss to generate the classes
            availableColorClasses = 'bg-MercuryGrey-100 bg-MercuryBlue-100 bg-MercuryGreen-100 bg-MercuryYellow-100 bg-MercuryRed-100 text-MercuryGrey-800 text-MercuryBlue-800 text-MercuryGreen-800 text-MercuryYellow-800 text-MercuryRed-800'
            "bg-Mercury#{@color.capitalize()}-100 text-Mercury#{@color.capitalize()}-800 px-3 py-1 rounded-full"
          else
            'text-gray-700'
