
  import PlatformApi from 'models/platform_api.coffee'
  import TemplateDisplay from './template_display.vue'
  import TemplateParameters from './template_parameters.vue'
  import { Button, Listbox } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      job: Object
      audience: Object
      selectedTemplate: Object

    emits: ['back', 'cancel', 'change', 'next']

    data: ->
      template: @selectedTemplate

    computed:
      availableTemplates: ->
        @$root.templates.filter (template) =>
          template.channelId == @$root.selectedChannel.uuid

    watch:
      selectedTemplate: ->
        @template = @selectedTemplate

    methods:
      templateLabel: (template) ->
        return '&nbsp;' unless template?
        "#{template.name.replaceAll('_', ' ').capitalize()} (#{template.language})"
      nextStep: ->
        parameters = @$refs.templateParameters.allParameters.map (parameter) ->
          Object.assign({}, parameter, contextParameter: parameter.contextParameter.label)
        @$emit('next', parameters)

    components:
      Btn: Button
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      Listbox: Listbox
      TemplateDisplay: TemplateDisplay
      TemplateParameters: TemplateParameters
