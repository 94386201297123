Vue.component 'type-select',
  props: ['modelType']
  data: ->
    availableTypes: TypeValidator.availableTypes.concat(state.references)
  created: ->
    Vue.setDefault(@modelType, 'type', '')
    Vue.setDefault(@modelType, 'list', false)
  template: '
    <div class="flex-container center-items">
      <select v-model="modelType.type" class="form-control">
        <option v-for="type in availableTypes" :value="type">{{ type }}</option>
      </select>
      &nbsp;
      <label class="flex-container non-bold">
        <input type="checkbox" v-model="modelType.list" value="true">
        &nbsp;
        <span>List</span>
      </label>
    </div>
  '
