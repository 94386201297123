import { platformApiBaseUrl } from 'rails_data'
import AnswerTemplate from 'models/answer_template'

export default class InboxApi
  constructor: (@session, @project, @stageLevel, @notifyOfReconnection) ->
    # set up websocket and event handlers
    @handlers =
      message: {}
    @connectWebsocket()

  connectWebsocket: =>
    try
      @websocket = new WebSocket(@sessionBaseUrl.replace(/^https:/, 'wss:') + 'stream')
      @websocket.addEventListener('message', @handleWebsocketMessage)
      @setPingTimeout()
    catch
      setTimeout(@reconnect, 10000)

  handleWebsocketMessage: (e) =>
    data = JSON.parse(e.data)
    if data.type == 'pong'
      @notifyOfReconnection(false)
      clearTimeout(@pongTimeout)
      @setPingTimeout()
    else if typeof @handlers[data.type] == 'function'
      @handlers[data.type](data)
    else if typeof @handlers[data.type]?[data.subType] == 'function'
      @handlers[data.type][data.subType](data)

  setPingTimeout: =>
    clearTimeout(@pingTimeout)
    @pingTimeout = setTimeout(@ping, 5000)

  ping: =>
    try
      @websocket.send(JSON.stringify(
        type: 'ping', payload: {timestamp: new Date().toISOString()}
      ))
      @pongTimeout = setTimeout(@reconnect, 4000)
    catch
      @reconnect()

  reconnect: =>
    @notifyOfReconnection(true)
    @halt()
    @connectWebsocket()

  halt: =>
    clearTimeout(@pingTimeout)
    clearTimeout(@pongTimeout)
    @websocket?.close()

  sendEvent: (data) =>
    return if !@websocket || @websocket.readyState != WebSocket.OPEN
    @websocket.send(JSON.stringify(data))

  # for new endpoints that are called directly on the platform API
  call: (method, path, body, {multipart, rawBody} = {multipart: false})->
    fetch(
      @sessionBaseUrl + path,
      method: method.toUpperCase(),
      body: if rawBody then rawBody else JSON.stringify(body),
      headers: if multipart
        'X-Requested-With': 'XMLHttpRequest'
        'Accept': 'application/json'
        'Authorization': "Bearer #{@session.sessionToken}"
      else
        'X-Requested-With': 'XMLHttpRequest'
        'Content-Type': 'application/json'
        'Accept': 'application/json'
        'Authorization': "Bearer #{@session.sessionToken}"
    )
      .then (response) =>
        if response.ok
          response.json()
        else
          throw new Error("API call failed: #{method.toUpperCase()} #{@sessionBaseUrl + path}")
      .then((response) -> response.data)

  # for old endpoints that still go over the rails app
  callWebapp: (method, path, body)->
    fetch(
      path,
      method: method.toUpperCase(),
      body: JSON.stringify(body),
      headers:
        'X-Requested-With': 'XMLHttpRequest'
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        'Content-Type': 'application/json'
        'Accept': 'application/json'
    )

  initFilter: (filters) =>
    filterObject =
      stageIds: filters.stageIds
      channelIds: if filters.channel?.uuid then filters.channel.uuid else []
      assignedTo: filters.assignedTo
      activeHandover: filters.activeHandover
      missingAnnotations: filters.missingAnnotations
      textSearch: filters.textSearch
      contextFilter: filters.contextParameters
    for fixParameter in (filters.fixParameters || [])
      switch fixParameter.field
        when 'hasMessagesBefore'
          filterObject[fixParameter.field] = moment(fixParameter.value).toISOString()
        when 'hasMessagesAfter'
          filterObject[fixParameter.field] = moment(fixParameter.value).add(1, 'day').toISOString()
        else
          filterObject[fixParameter.field] = fixParameter.value
    @call('post', 'subscribe', filterObject)

  loadConversations: (params) =>
    @call('get', 'conversations?' + $.param(params))

  loadConversation: (conversation) =>
    @call('get', "conversations/#{conversation.id}")

  loadMessages: (conversation, params) =>
    @call('get', "conversations/#{conversation.id}/messages?" + $.param(params))

  loadAnnotatableMessages: (conversation) ->
    @call('get', "conversations/#{conversation.id}/messages?annotationSuggested&pageSize=100")

  updateConversationContext: (conversation, partialContext) ->
    @call('post', "conversations/#{conversation.id}/context", context: partialContext)
  saveConversationNotes: (conversation, notes) =>
    @call('post', "conversations/#{conversation.id}/notes", text: notes)

  initHandover: (conversation) =>
    @call('post', "conversations/#{conversation.id}/actions/handover_initiate")
  stopHandover: (conversation) =>
    @call('post', "conversations/#{conversation.id}/actions/handover_suspend")
  sendMessage: (conversation, message, answerTemplateId) =>
    messageObject =
      type: 'Bubble'
      attachments: [
        type: 'Text'
        format: 'MARKDOWN'
        role: 'NONE'
        text: message
      ]
    @call(
      'post',
      "conversations/#{conversation.id}/actions/messaging_send",
      message: messageObject,
      templateId: answerTemplateId
    )
  sendFile: (conversation, file) =>
    formData = new FormData()
    formData.append('file', file)
    @call('post', "conversations/#{conversation.id}/upload", null, multipart: true, rawBody: formData)

  setMessageRead: (message) ->
    @sendEvent(
      type: 'message_read'
      messageId: message.id
    )
  markMessageforAnnotation: (conversation, message) ->
    @call('post', "conversations/#{conversation.id}/messages/#{message.id}/mark_annotation_suggested")

  sendAnnotation: (conversation, annotation) ->
    annotationToBeSent = ObjectProcessor.cloneWithout(annotation, ['message'])
    @call(
      'post',
      "conversations/#{conversation.id}/messages/#{annotation.message.id}/annotations",
      annotationToBeSent
    )
  ignoreAnnotatable: (conversation, messageId) ->
    @call(
      'post',
      "conversations/#{conversation.id}/messages/#{messageId}/annotations",
      ignore: true
    )
  deleteAnnotation: (conversation, annotation) ->
    annotationToBeSent = ObjectProcessor.cloneWithout(annotation, ['message'])
    @call(
      'delete',
      "conversations/#{conversation.id}/messages/#{annotation.message.id}/annotations",
      annotationToBeSent
    )

  reassignConversation: (conversation, teamId, agentId) ->
    @call('post', "handover/#{conversation.handoverSession.id}/reassign", {teamId: teamId, agentId: agentId})

  sendIntent: (botId, gameId, params) =>
    path = Routes.add_intent_project_bot_game_path(@project.id, @stageLevel, botId, gameId)
    @callWebapp('patch', path, params)

  loadContextParameters: (stageId) =>
    path = Routes.context_parameters_project_stage_path(@project.id, @stageLevel, stageId)
    @callWebapp('get', path)
      .then((response) -> response.json())
      .then((response) -> response.data)

  loadHandoverAgents: (teamId) =>
    if (teamId)
      @call('get', "handover/agents?teamId=#{teamId}")
    else
      @call('get', "handover/agents")

  # Answer templates

  loadAnswerTemplates: (conversationId) =>
    @call('get', "conversations/#{conversationId}/templates")

  loadAnswerTemplateTags: (conversationId) =>
    @call('get', "conversations/#{conversationId}/templates/tags")

  createAnswerTemplate: (conversationId, message) =>
    template =
      message: message
      exampleQuestion: ''
      teams: []
      tags: []
      confidence: 1.0
    @call('post', "conversations/#{conversationId}/templates", template)
      .then (data) -> new AnswerTemplate(data)

  updateAnswerTemplate: (conversationId, template) =>
    @call('patch', "conversations/#{conversationId}/templates/#{template.id}", template.gist())

  deleteAnswerTemplate: (conversationId, template) =>
    @call('delete', "conversations/#{conversationId}/templates/#{template.id}")

  # Report urls
  Object.defineProperties @prototype,
    sessionBaseUrl:
      get: ->
        platformApiBaseUrl + "projects/#{@project.uuid}/inbox/sessions/#{@session.uuid}/"
    analyticsUrl:
      get: ->
        platformApiBaseUrl + "projects/#{@project.uuid}/" + "inbox/reports/authorize?report=analytics&auth=#{@session.sessionToken}"
    agentStatusUrl:
      get: ->
        platformApiBaseUrl + "projects/#{@project.uuid}/" + "inbox/reports/authorize?report=team-stats&auth=#{@session.sessionToken}"
