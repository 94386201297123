import BotbuilderApi from 'models/botbuilder_api.coffee'
import ContextParameter from 'models/context_parameter.coffee'
import InternalParameter from 'models/internal_parameter.coffee'
import NLG from 'models/nlg.coffee'
import Priming from 'models/priming.coffee'

export default class Verbalization
  @TYPES =
    QueryVerbalization:
      label: 'Query'
      description: 'If there is no value stored in this context or Confirm existing is objected by the user, this message will ask the user for a value.'
    ConfirmExistingVerbalization:
      label: 'Confirm existing'
      description: 'If there is already a value stored in this context when the initiative is triggered, you can use this message to let the user confirm the existing value. When the user denies, Query will be played.'
    ConfirmUnderstoodVerbalization:
      label: 'Confirm understood'
      description: 'If you have used Query or Retry to ask the user for a value, this message lets you ask for a confirmation of the understood value. When the user denies, Retry will be played.'
    RetryVerbalization:
      label: 'Retry'
      description: 'If Confirm understood is objected by the user or a provided value does not validate, this message lets you rephrase your query.'
    SuccessVerbalization:
      label: 'Success'
      description: 'If the initiative ends with a valid value, this lets you send a success message.'
    FailVerbalization:
      label: 'Fail'
      description: 'If the initiative ends without a valid value, this lets you send a fail message.'

  @IMPLICATIONS = {}
  # implications may have this form:
  # @IMPLICATIONS =
  #   RetryVerbalization: ['ConfirmUnderstoodVerbalization']

  constructor: (@contextInterface, data = {}) ->
    @type = data.type
    @isActive = if data.isActive? then data.isActive else false
    @nlg = new NLG(@, data.nlg)
    @primings = (data.primings || []).map (primingData) => new Priming(@, primingData)
    switch @type
      when 'RetryVerbalization'
        @numberOfRetries = if data.numberOfRetries? then data.numberOfRetries else 2
      when 'ConfirmUnderstoodVerbalization'
        @onlyIfNotAllValidate = if data.onlyIfNotAllValidate? then data.onlyIfNotAllValidate else false

  searchVariables: (query, {types, limit} = {}) =>
    BotbuilderApi.search(@botId, query, types: types, context: @type, limit: limit)
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) =>
            switch matchData.resourceType
              when 'ContextParameter'
                new ContextParameter(matchData.entity)
              when 'InternalParameter'
                new InternalParameter(matchData.entity)

  loadVariables: ->
    @_variablesLoading = true
    @searchVariables('', types: ['InternalParameter'], context: @type, limit: 999)
      .then (@_variables) => @_variables
      .finally => @_variablesLoading = false

  getVariables: ->
    if !@_variables? && !@_variablesLoading
      @_loadingVariablesPromise = @loadVariables()
    else if @_variablesLoading
      @_loadingVariablesPromise
    else
      Promise.resolve(@_variables)

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @primings = (data.primings || []).map (primingData) => new Priming(@, primingData)

  Object.defineProperties @prototype,
    botId:
      get: ->
        @contextInterface.botId
    parameterKey:
      get: ->
        @contextInterface.parameterKey
    export:
      get: ->
        obj =
          type: @type
          isActive: @isActive
          nlg: @nlg.export
          primings: @primings.map (priming) => priming.export
        switch @type
          when 'RetryVerbalization'
            obj.numberOfRetries = @numberOfRetries
          when 'ConfirmUnderstoodVerbalization'
            obj.onlyIfNotAllValidate = @onlyIfNotAllValidate
        obj
