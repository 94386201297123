
  import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'
  import { confirm } from 'helpers'

  import { ActionMenu, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'
  import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      attribute: EntitySchemaAttribute

    emits: ['remove']

    data: ->
      actionMenuItems: [
        {icon: PencilIcon, label: 'Edit', method: => @$router.push(@routerTarget)}
        {icon: XIcon, label: 'Delete', method: @remove}
      ]

    computed:
      routerTarget: ->
        name: 'entitySchemaAttribute'
        params: {schemaKey: @attribute.schema.key, attributeKey: @attribute.key}


    methods:
      remove: ->
        return unless await confirm("Do you really want to delete attribute “#{@attribute.label}”? It will be deleted from all content of this type!")
        @$emit('remove')

    components:
      ActionMenu: ActionMenu
      CheckCircleIcon: CheckCircleIcon
      TableCell: TableCell
      TableRow: TableRow
      XCircleIcon: XCircleIcon
