import { confirm } from 'helpers'

Vue.component 'form-filling-field',
  props: ['field', 'index', 'fields']
  data: ->
    state: state
    fieldValidation: false
  computed:
    availableFields: ->
      fields = {}
      for field in @fields
        type = if field.parameterMode == 'custom'
          field.customType
        else if field.parameterMode == 'context'
          @availableContextParameters.find((cp) -> cp.label == field.contextParameter)?.type
        fields[field.label] = Object.assign({type: type}, field)
      delete fields[@field.label]
      fields
    validationType: ->
      switch @field.parameterMode
        when 'custom'
          TypeValidator.typeProperties[@field.customType.type]?.validationType
        when 'context'
          parameter = @availableContextParameters.find((cp) => cp.label == @field.contextParameter)
          TypeValidator.typeProperties[parameter?.type?.type]?.validationType
    availableContextParameters: ->
      @state.globalContextParameters.map((cp) ->
        Object.assign({}, cp, label: "global.#{cp.label}")
      ).concat(@state.computedContextParameters.write)
    customTypeValid: ->
      return true if @field.parameterMode != 'custom'
      GameValidator.type(@field.customType)
    contextParameterValid: ->
      return true if @field.parameterMode != 'context'
      @availableContextParameters.find((cp) => cp.label == @field.contextParameter)
  created: ->
    # make sure field has all required properties
    Vue.setDefault(@field, 'label', '')
    Vue.setDefault(@field, 'parameterMode', 'custom')
    Vue.setDefault(@field, 'contextParameter', '')
    Vue.setDefault(@field, 'contextWhenFilled', 'useValue')
    Vue.setDefault(@field, 'customType', {})
    # amend old format
    if @field.type
      Vue.set(@field, 'customType', @field.type)
      Vue.delete(@field, 'type')
    # continue
    Vue.setDefault(@field, 'validation', {message: {}})
    Vue.setDefault(@field, 'dependencies', [])
    Vue.setDefault(@field, 'mandatory', true)
    Vue.setDefault(@field, 'escapable', true)
    Vue.setDefault(@field, 'utterances', {})
    Vue.setDefault(@field, 'nlg', {})
    Vue.setDefault(@field, '$key', GuidGenerator.newGuid())
    @setValidation()
    @setFieldValidation()
    @validate()
  watch:
    field:
      handler: -> @validate()
      deep: true
    'field.parameterMode': -> @setValidation()
    'field.customType.type': -> @setValidation()
    'field.contextParameter': -> @setValidation()
    fieldValidation: ->
      Vue.set(@field, 'validation', {message: {}}) if !@fieldValidation
  methods:
    validate: ->
      Vue.set @field, '$valid',
        GameValidator.string(@field.label) &&
        @customTypeValid &&
        @contextParameterValid &&
        GameValidator.allValid(@field.dependencies) &&
        @field.nlg.$valid
    clone: ->
      @$emit('clone-form-field', @index)
    remove: ->
      return unless await confirm("Do you really want to remove form field “#{@field.label}”?")
      @$emit('remove-form-field', @index)
    validationValid: (attribute) ->
      if attribute == 'regex'
        try
          new RegExp(@field.validation.regex)
        catch
          return false
        true
      else if attribute in ['minimum', 'maximum']
        return true unless @field.validation[attribute]
        TypeValidator.valueValid(@field.validation[attribute], @field.customType.type, '=', acceptLogicalExpressions: false)
      else
        true
    setValidation: ->
      if @validationType == 'regex'
        Vue.setDefault(@field.validation, 'regex', '')
      else
        Vue.delete(@field.validation, 'regex')
      if @validationType == 'minmax'
        Vue.setDefault(@field.validation, 'minimum', '')
        Vue.setDefault(@field.validation, 'maximum', '')
      else
        Vue.delete(@field.validation, 'minimum')
        Vue.delete(@field.validation, 'maximum')
    setFieldValidation: ->
      if @validationType == 'regex'
        @fieldValidation = @field.validation.regex.length > 0 || @field.validation.message.length > 0
      else if @validationType == 'minmax'
        @fieldValidation = @field.validation.minimum.length > 0 || @field.validation.maximum.length > 0 || @field.validation.message.length > 0
  template: '
    <panel-togglable
      class="wizard-like-panel"
      :class="{incomplete: !field.$valid}"
      @show="$emit(\'show\')"
      >
      <template v-slot:header>
        <div class="handle" v-if="fields.length > 1">
          <span class="fa fa-arrows-alt"></span>&ensp;
        </div>
        <div class="width-50" style="width: 400px">
          <inline-edit
            v-model:string="field.label"
            placeholder="form field label"
            warning="When changing the field’s label you might have to update the placeholders in your API call definition. Continue?"
            focus="true"
            bold="true"
            />
        </div>
        <div class="expand"></div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!field.$valid"></span>
        </div>
      </template>

      <template v-slot:body>
        <div class="responsive-grid-container margin-bottom">
          <div class="responsive-grid-item item-400">
            <h5 class="no-margin-top">How will the bot ask for this field?</h5>
            <nlg-editor :nlg="field.nlg"/>
          </div>
          <div class="responsive-grid-item item-400">
            <div class="form-group">
              <b>Save input to&ensp;</b>
              <label class="non-bold no-margin-bottom inline-radio">
                <input type="radio" value="custom" v-model="field.parameterMode">
                Custom parameter
              </label>
              <label class="non-bold no-margin-bottom inline-radio">
                <input type="radio" value="context" v-model="field.parameterMode">
                Context
              </label>
            </div>
            <div v-if="field.parameterMode == \'custom\'" class="form-group" :class="{\'has-error\': !customTypeValid}">
              <label class="non-bold">This field is filled with an entity of the type</label>
              <type-select :model-type="field.customType"/>
            </div>
            <div v-if="field.parameterMode == \'context\'" class="form-group" :class="{\'has-error\': !contextParameterValid}">
              <label class="non-bold">This field uses the context parameter</label>
              <select v-model="field.contextParameter" class="form-control">
                <option v-for="parameter in availableContextParameters" :value="parameter.label">{{ parameter.humanizedLabel || parameter.label }}</option>
              </select>
            </div>
            <div v-if="field.parameterMode == \'context\'" class="form-group">
              <div class="text-normal">If the context parameter already has a value</div>
              <div class="radio">
                <label class="text-light">
                  <input type="radio" value="useValue" v-model="field.contextWhenFilled">
                  use that value and skip the question
                </label>
              </div>
              <div class="radio">
                <label class="text-light">
                  <input type="radio" value="askAgain" v-model="field.contextWhenFilled">
                  ask the question and overwrite the existing value
                </label>
              </div>
            </div>
            <div class="spacer"></div>

            <div class="form-group">
              <label class="non-bold">This field is relevant
                <info-button info-key="form_field_relevance"/>
              </label>
              <form-field-dependencies :dependencies="field.dependencies" :fields="availableFields"/>
            </div>
            <div class="form-group">
              <label class="sliding-switch non-bold">
                <input type="checkbox" v-model="field.mandatory">
                <span class="slider"></span>
                <span class="switch-label-right">This field is mandatory</span>
              </label>
            </div>
            <div class="spacer"></div>

            <div class="form-group">
              <label class="non-bold">
                Enter one or more example utterances that provide data for this field.
                Please enclose entities in curly brackets (e.g., <i>My name is {Freddy Mercury}.</i>)
              </label>
              <multilang-variation-field :dictionary="field.utterances"/>
            </div>
            <div class="spacer"></div>

            <div class="form-group">
              <label class="sliding-switch">
                <input type="checkbox" v-model="field.escapable">
                <span class="slider"></span>
                <span class="switch-label-right">Allows for competing interpretations</span>
              </label>
            </div>

            <div class="form-group" v-if="validationType">
              <label class="sliding-switch">
                <input type="checkbox" v-model="fieldValidation">
                <span class="slider"></span>
                <span class="switch-label-right">Validate</span>
              </label>
            </div>
            <div v-if="validationType && fieldValidation">
              <div v-if="validationType == \'regex\'" class="sub-form-group" :class="{\'has-error\': !validationValid(\'regex\')}">
                <label class="non-bold">Regular expression</label>
                <input type="text" class="form-control" v-model="field.validation.regex">
              </div>
              <div v-if="validationType == \'minmax\'" class="flex-container sub-form-group">
                <div class="expand" :class="{\'has-error\': !validationValid(\'minimum\')}">
                  <label class="non-bold">Minimum</label>
                  <input type="text" class="form-control" v-model="field.validation.minimum">
                </div>&nbsp;
                <div class="expand" :class="{\'has-error\': !validationValid(\'maximum\')}">
                  <label class="non-bold">Maximum</label>
                  <input type="text" class="form-control" v-model="field.validation.maximum">
                </div>
              </div>
              <label class="non-bold">Message to be sent when validation fails</label>
              <multilang-field :dictionary="field.validation.message" class="form-control"/>
            </div>
          </div>
        </div>
        <div class="button-container">
          <div class="btn btn-default" v-on:click="clone()">Clone form field</div>
          &nbsp;
          <div class="btn btn-caution" v-on:click="remove()">Remove form field</div>
        </div>
      </template>
    </panel-togglable>
  '
