import { createApp } from 'vue_shims'

import Project from 'models/project.coffee'
import User from 'models/user.coffee'

import ProjectSettingsEditor from 'components/project_settings/editor.vue'

initProjectSettingsApp = (element) ->
  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))
      project: new Project(elementData.project)
      users: elementData.users
        .map (userData) -> new User(userData)
        .sortByString('label')

    components:
      ProjectSettingsEditor: ProjectSettingsEditor

    template: '
      <ProjectSettingsEditor v-model="project"/>
    '
  )
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('project-settings-app')
      initProjectSettingsApp(element)
