
  import Summary from './summary.vue'
  import Chart from './chart.vue'
  import DistributionTable from './distribution_table.vue'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    data: ->
      summaryConfig:
        totalUsers: {label: 'Total users', type: 'absolute'}
        newUsers: {label: 'New users', type: 'absolute'}
        returningUsers: {label: 'Returning users', type: 'absolute'}

    computed:
      summary: ->
        ready = @data.users.summaryCurrent.status == 'ready'
        current = @data.users.summaryCurrent.data
        previous = @data.users.summaryPrevious.data
        Object.keys(@summaryConfig).map (key) =>
          key: key
          label: @summaryConfig[key].label
          type: @summaryConfig[key].type
          current: if ready then current[key] else null
          previous: if ready then previous?[key] else null
      series: ->
        ready = @data.users.series.status == 'ready'
        data = @data.users.series.data
        totalUsers: {label: 'Total users', data: if ready then data.totalUsers else []}
        newUsers: {label: 'New users', data: if ready then data.newUsers else []}
        returningUsers: {label: 'Returning users ', data: if ready then data.returningUsers else []}
      usersPerTimeframe: ->
        if @data.users.perTimeframe.status == 'ready'
          @data.users.perTimeframe.data
        else
          {}
      usersPerChannel: ->
        data = if @data.users.perChannel.status == 'ready' then @data.users.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)

    components:
      Chart: Chart
      DistributionTable: DistributionTable
      Summary: Summary
