
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import ParameterFilter from 'models/parameter_filter.coffee'
  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import { Input, Listbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: ParameterFilter
      deletable:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      parameterFilter: @modelValue
      operator: null
      operatorsForSelect: Object.entries(ParameterFilter.OPERATORS).map ([operator, label]) ->
        id: operator, name: label

    watch:
      modelValue: ->
        @parameterFilter = @modelValue

    created: ->
      @operator = @operatorsForSelect.find (op) => op.id == @parameterFilter.operator
      @operator ||= @operatorsForSelect[0]

    methods:
      update: ->
        @$emit('update:modelValue', @parameterFilter)
      updateOperator: ->
        return unless @operator?.id?
        @parameterFilter.operator = @operator.id
        @update()

    components:
      ContextParameterSelector: ContextParameterSelector
      Input: Input
      Listbox: Listbox
      XIcon: XIcon
