
  import WhatsappChannel from 'models/whatsapp_channel.coffee'
  import { dialog360PartnerId } from 'rails_data'

  import InfoEditor from './info_editor.vue'
  import Templates from './templates.vue'
  import { Button, Listbox, Modal, Tabs } from 'components/generic'

  export default
    props:
      modelValue: WhatsappChannel

    data: ->
      channel: @modelValue
      currentTab: 'info'
      debounceTimeout: null
      dialog360Channels: []
      selectedDialog360Channel: null
      tabs: [
        { id: 'info', name: 'Business information' }
        { id: 'templates', name: 'Template messages' }
      ]

    created: ->
      if !@channel.connected && @$root.connectChannelOnVisit
        @$root.connectChannelOnVisit = false
        @start360DialogFlow()

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      start360DialogFlow: ->
        params = {
          email: Globals.currentUser.email
          name: Globals.currentUser.organizationName
          partner: Globals.currentUser.organizationChargebeeId
          redirect_url: window.location.href
        }
        paramsString = Object.entries(params)
          .map ([key, value]) -> key + '=' + encodeURIComponent(value)
          .join('&')
        window.open(
          "https://hub.360dialog.com/dashboard/app/#{dialog360PartnerId}/permissions?#{paramsString}",
          'integratedOnboardingWindow',
          'toolbar=no, menubar=no, width=600, height=900, top=100, left=100',
        )
        window.addEventListener('message', @handleMessage)
      handleMessage: (event) ->
        return if typeof event.data != 'string'
        window.removeEventListener('message', @handleMessage)
        queryString = "#{event.data}"
        params = new URLSearchParams(queryString)
        channelsString = params.get('channels')
        # e.g. ?client=oaY9LLfUCL&channels=[y9MiLoCH,LiOX7bCH]
        channelIdsString = channelsString[1..-2]
        @channel.fetchChannels(channelIdsString)
          .then (data) =>
            @dialog360Channels = [phone_number: 'Select'].concat(data)
            @selectedDialog360Channel = @dialog360Channels[0]
            @$refs.dialog360ChannelsModal.open()
      connect: ->
        return if !@selectedDialog360Channel?.id?
        @$refs.dialog360ChannelsModal.close()
        @channel.fetchCredentialsAndConnect(@selectedDialog360Channel.id, @selectedDialog360Channel.phone_number)

    components:
      Button: Button
      InfoEditor: InfoEditor
      Listbox: Listbox
      Modal: Modal
      Tabs: Tabs
      Templates: Templates
