
  import Label from './label.vue'

  export default
    props:
      modelValue: String
      label: String
      placeholder: String

    data: ->
      value: @modelValue

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      emit: (e) ->
        @$emit('update:modelValue', e)
      focus: ->
        @$refs.datepicker.focus()

    components:
      Label: Label
