
  import ContextInitiative from 'models/context_initiative.coffee'
  import OperationalHook from 'models/operational_hook.coffee'

  import OperationalHookEditor from './operational_hook_editor.vue'
  import { Button, Label } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Array
      contextInitiative: ContextInitiative

    data: ->
      operationalHooks: @modelValue
      editorRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @operationalHooks = @modelValue

    beforeUpdate: ->
      @editorRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @operationalHooks)
      remove: (index) ->
        @operationalHooks.splice(index, 1)
        @update()
      addHook: ->
        @operationalHooks.push(
          new OperationalHook(@contextInitiative, operations: [type: 'ContextUpdate'])
        )
        @$nextTick => @editorRefs.last.focus()
      setEditorRef: (el) ->
        @editorRefs.push(el) if el?

    components:
      Button: Button
      Label: Label
      OperationalHookEditor: OperationalHookEditor
