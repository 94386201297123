Vue.component 'active-learning-game',
  props: ['config']
  data: ->
    state: state
    gameValidator: GameValidator
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'lowConfidenceMessage',
            'disambiguationMessage',
          ]
        )
      deep: true
  created: ->
    Vue.setDefault(@config, 'useForLowConfidenceInterpretations', false)
    Vue.setDefault(@config, 'useForDisambiguation', false)
    Vue.setDefault(@config, 'lowConfidenceMessage', {})
    Vue.setDefault(@config, 'disambiguationMessage', {})
  template: '
    <div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="flex-container center-items">
            <label class="sliding-switch align" style="margin: 5px 0 10px">
              <h5>Low confidence interpretation</h5>
              <input type="checkbox" v-model="config.useForLowConfidenceInterpretations">
              <span class="slider"></span>
            </label>
            <a href="#low-confidence-interpretations" data-toggle="collapse" class="chevron auto-margin-left"></a>
          </div>
        </div>
        <div id="low-confidence-interpretations" class="collapse">
          <div class="panel-body">
            <h5 class="no-margin-top">How does the bot present low confidence interpretations?</h5>
            <nlg-editor :nlg="config.lowConfidenceMessage" :autogenerated-quickreplies-enabled="true"/>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="flex-container center-items">
            <label class="sliding-switch align" style="margin: 5px 0 10px">
              <h5>Disambiguation</h5>
              <input type="checkbox" v-model="config.useForDisambiguation">
              <span class="slider"></span>
            </label>
            <a href="#disambiguation" data-toggle="collapse" class="chevron auto-margin-left"></a>
          </div>
        </div>
        <div id="disambiguation" class="collapse">
          <div class="panel-body">
            <h5 class="no-margin-top">How does the bot present competing interpretations?</h5>
            <nlg-editor :nlg="config.disambiguationMessage" :autogenerated-quickreplies-enabled="true"/>
          </div>
        </div>
      </div>
    </div>
  '
