Vue.component 'teaser-payload-editor',
  props:
    payload: Object
    fixedGame: String
    side:
      type: String
      default: 'user'
  data: ->
    state: state
    operatorRegex: /\+|\-|\=|\<|\>|\~|\!/
  computed:
    gameLabels: ->
      return [] if !@state.intentSignatures?
      Array.unique @state.intentSignatures[@side].map((signature) -> signature.gameLabel)
    intentLabels: ->
      return [] if !@state.intentSignatures? || !@payload.payload.game
      @state.intentSignatures[@side].filter((signature) => signature.gameLabel == @payload.payload.game)
        .map((signature) -> signature.intentLabel)
  watch:
    'state.intentSignatures':
      handler: -> @validate()
      deep: true
    'payload.payload.game': ->
      @payload.payload.intent = if @payload.payload.intent then @payload.payload.intent else ''
      @payload.payload.slots = []
    'payload.payload.intent': ->
      return if @state.intentSignaturesLoading
    payload:
      handler: -> @validate()
      deep: true
  created: ->
    Vue.setDefault(@payload.payload, 'game', '')
    Vue.setDefault(@payload.payload, 'intent', '')
    Vue.setDefault(@payload.payload, 'slots', [])
    Vue.set(@payload.payload, 'game', @fixedGame) if @fixedGame?
    @validate()
  methods:
    validate: ->
      Vue.set @payload.payload, '$valid',
        @payload.payload.game in @gameLabels &&
        @payload.payload.intent in @intentLabels
  template: '
    <div class="payload-editor">
      <div class="flex flex-col sub-form-group">
        <input v-model="payload.text" type="text" class="styled-form-field flex-grow mt-2.5" placeholder="Text to be sent on click">
        <select v-model="payload.payload.game" class="styled-form-field flex-grow mt-2.5" :disabled="state.intentSignaturesLoading ? true : null">
          <option value="" disabled>Game to be sent on click</option>
          <option v-for="gameLabel in gameLabels" :value="gameLabel">
            {{ gameLabel }}
          </option>
        </select>
        <select v-model="payload.payload.intent" class="styled-form-field flex-grow mt-2.5" :disabled="state.intentSignaturesLoading ? true : null">
          <option value="" disabled>Intent to be sent on click</option>
          <option v-for="intentLabel in intentLabels" :value="intentLabel">
            {{ intentLabel }}
          </option>
        </select>
      </div>
    </div>
  '
