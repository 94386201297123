
  import Condition from 'models/condition.coffee'
  import ConditionType from 'models/condition_type.coffee'
  import ContextInterface from 'models/context_interface.coffee'
  import ContextParameter from 'models/context_parameter'
  import DialogNode from 'models/dialog_node.coffee'
  import KnowledgeSource from 'models/knowledge_source'

  import VariableSelector from 'components/botbuilder/inputs/variable_selector.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Badge, Button, Combobox, Input, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    name: 'ConditionEditor'

    props:
      modelValue: Condition
      host: [DialogNode, ContextInterface, KnowledgeSource]
      deletable:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      condition: @modelValue
      variable: null
      component: null
      conditionTypesForSelect: ConditionType.TYPES.map (operator) -> key: operator, label: operator
      PlusIcon: PlusIcon

    computed:
      valueType: ->
        return 'string' unless @variable?
        (@component || @variable).valueType
      collectionType: ->
        return 'string' unless @variable?
        (@component || @variable).collectionType
      entitySchemaKey: ->
        return null unless @variable?
        (@component || @variable).entitySchemaKey
      contextParameter: ->
        return @variable if @variable instanceof ContextParameter
        null

    watch:
      modelValue: ->
        @condition = @modelValue

    methods:
      addCondition: (type) ->
        @condition = new Condition(
          type: type,
          first: @condition
          second: new Condition()
        )
      removeCondition: (firstOrSecond) ->
        @condition = switch firstOrSecond
          when 'first'
            @condition.second
          when 'second'
            @condition.first
        @update()
      swapLogicalOperator: ->
        @condition.swapLogicalOperator()
        @update()
      update: ->
        @$emit('update:modelValue', @condition)
      updateOperator: ->
        @condition.value2 = 'true' if @condition.isUnary && !@condition.value2
        @update()

    components:
      Badge: Badge
      Button: Button
      Combobox: Combobox
      VariableSelector: VariableSelector
      Input: Input
      KeyListbox: KeyListbox
      UniversalInput: UniversalInput
      XIcon: XIcon
