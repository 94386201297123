
  import Channel from 'models/channel.coffee'
  import { availableLanguages } from 'rails_data'
  import { alert, confirm } from 'helpers'

  import ChannelLogo from './channel_logo.vue'
  import InitialContextEditor from './initial_context_editor.vue'
  import StatusBadge from './status_badge.vue'
  import { ActionMenu, Button, EnvironmentBadge, H1, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'
  import { ClipboardIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Channel

    emits: ['update:modelValue', 'remove']

    data: ->
      channel: @modelValue
      contextUpdates: []
      languagesForSelector: @$root.languages.map (code) -> {key: code, label: availableLanguages[code]}
      actionMenuItems: [
        {icon: XIcon, label: 'Delete channel', method: @remove}
      ]

    watch:
      modelValue: ->
        @channel = @modelValue
        @setContextUpdates()

    created: ->
      @setContextUpdates()

    methods:
      setContextUpdates: ->
        @contextUpdates = Object.entries(@channel.contextUpdates).map ([key, value]) ->
          parameterKey: key
          value: value
      copyChannelId: (e) ->
        navigator.clipboard.writeText(@channel.id)
          .then =>
            e.target.blur()
            await alert('Copied channel ID to clipboard')
      remove: ->
        return unless await confirm('Do you really want to delete this channel?')
        @$emit('remove')
      update: ->
        @channel.contextUpdates = Object.fromEntries(
          @contextUpdates.map (update) -> [update.parameterKey, update.value]
        )
        @$emit('update:modelValue', @channel)
      addContextUpdate: ->
        @contextUpdates.push(parameterKey: '', value: '')
      removeContextUpdate: (index) ->
        @contextUpdates.splice(index, 1)
        @update()

    components:
      ActionMenu: ActionMenu
      Button: Button
      ChannelLogo: ChannelLogo
      ClipboardIcon: ClipboardIcon
      EnvironmentBadge: EnvironmentBadge
      H1: H1
      InitialContextEditor: InitialContextEditor
      Input: Input
      Label: Label
      KeyListbox: KeyListbox
      StatusBadge: StatusBadge
      Switch: Switch
      Textarea: Textarea
