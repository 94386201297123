export default class IntegrationInspection
  constructor: (@integration, data = {}) ->
    data ||= {}
    @capturedInput = data.capturedInput if data.capturedInput?

  Object.defineProperties @prototype,
    timeCapturedInputFormatted:
      get: ->
        return '' unless @capturedInput?.timestamp
        moment(@capturedInput.timestamp).format(t('formats.dateTime'))
    capturedHeaders:
      get: ->
        @capturedInput?.input?.headers
    capturedInputAsString:
      get: ->
        return '' unless @capturedInput?.input?.body
        JSON.stringify(@capturedInput.input.body, null, 2)
