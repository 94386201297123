
  import IntentList from './intent_list.vue'
  import UtteranceList from './utterance_list.vue'
  import { DocumentationLink, PageHeader, Tabs } from 'components/generic'

  export default
    data: ->
      tabs = [
        {id: 'intents', name: 'Intents'}
        {id: 'utterances', name: 'Utterances'}
      ]

      currentTab: if tabs.map((t) -> t.id).includes(@$route.params.tab) then @$route.params.tab else 'intents'
      tabs: tabs

    watch:
      '$route.params.tab': ->
        @currentTab = @$route.params.tab

    methods:
      pushRoute: ->
        @$router.push(name: @$route.name, params: {tab: @currentTab})

    components:
      DocumentationLink: DocumentationLink
      IntentList: IntentList
      UtteranceList: UtteranceList
      PageHeader: PageHeader
      Tabs: Tabs
