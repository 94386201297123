
  import { hexToRgba } from 'helpers'

  import { ColorPicker } from 'vue-color-kit'
  import { Label, Popover, Slider } from 'components/generic'

  export default
    props:
      modelValue:
        type: String
        default: '#000000'
      name:
        type: String
        default: 'Color'
      label:
        type: String
        default: 'Color'

    data: ->
      colors: [
        '#000000',
        '#808080',
        '#c0c0c0',
        '#ffffff',
        '#ff0000',
        '#800000',
        '#ffff00',
        '#808000',
        '#00ff00',
        '#008000',
        '#00ffff',
        '#008080',
        '#0000ff',
        '#000080',
        '#ff00ff',
        '#800080',
      ]
      rgba: @hexToRgba(@modelValue)

    methods:
      setWidth: ->
        el = @$el.getElementsByClassName('hu-color-picker')[0]
        el.style.width = '218px' if el
      setWidthWithTimeout: ->
        setTimeout(@setWidth, 0)
      update: (colorObject) ->
        hexColor = colorObject.hex + Math.floor(colorObject.rgba.a * 255).toString(16)
        @rgba = @hexToRgba(hexColor)
        @$emit('update:modelValue', hexColor)
        @setWidth()
        @$nextTick(@setWidth)
      hexToRgba: (hexColor) ->
        return {r: 0, g: 0, b: 0, a: 1} unless typeof hexColor == 'string'
        c = hexColor.substring(1).split('')
        if hexColor.match(/^#[0-9A-Fa-f]{4}$/)
          hexToRgba(hexColor[0..3], ("0x#{hexColor[4]}#{hexColor[4]}"&255) / 255)
        else if hexColor.match(/^#[0-9A-Fa-f]{8}$/)
          hexToRgba(hexColor[0..6], ("0x#{hexColor[7..8]}"&255) / 255)
        else
          hexToRgba(hexColor)

    components:
      ColorPicker: ColorPicker
      Label: Label
      Popover: Popover
      Slider: Slider
