
  import Bot from 'models/bot.coffee'
  import { ActionMenu, Button, CategoryTableRow, DocumentationLink, PageHeader, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { RefreshIcon, XIcon } from '@heroicons/vue/outline'
  import { PencilIcon, PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot

    data: ->
      undeletableRowRefs: []
      deletableRowRefs: []

    computed:
      undeletableHooks: ->
        @bot.config.hooks.filter (hook) -> !hook.canBeDeleted
      deletableHooks: ->
        @bot.config.hooks
          .filter (hook) -> hook.canBeDeleted
          .sortByString('label')

    beforeUpdate: ->
      @undeletableRowRefs = []
      @deletableRowRefs = []

    methods:
      createHook: ->
        @$router.push(name: 'newHook')
      routerTarget: (hook) ->
        name: 'hook'
        params: {hookKey: hook.key}
      actionMenuItems: (hook) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(@routerTarget(hook))},
          if hook.canBeDeleted
            {icon: XIcon, label: 'Delete', method: => hook.delete()}
          else
            {icon: RefreshIcon, label: 'Reset', method: => hook.reset()}
        ].filter (item) -> item?
      setUndeletableRowRef: (el) ->
        @undeletableRowRefs.push(el) if el?
      setDeletableRowRef: (el) ->
        @deletableRowRefs.push(el) if el?

    components:
      ActionMenu: ActionMenu
      Button: Button
      CategoryTableRow: CategoryTableRow
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
