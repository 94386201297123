export default class SlotDefinition

  constructor: (data = {}) ->
    data ||= {}
    @type = 'TypedSlotDefinition'
    @slotRole = ''
    switch @type
      when 'TypedSlotDefinition'
        @valueType = 'string'
      when 'AttributeSlotDefinition'
        @parameterKey = ''
    @update(data)

  update: (data = {}) ->
    @type = data.type if data.type?
    @slotRole = data.slotRole if data.slotRole?
    @_label = data.label if data.label?
    switch @type
      when 'TypedSlotDefinition'
        @valueType = data.valueType if data.valueType?
      when 'AttributeSlotDefinition'
        @parameterKey = data.parameterKey if data.parameterKey?

  clone: ->
    new SlotDefinition(@export)

  Object.defineProperties @prototype,
    label:
      get: ->
        return @_label if @_label?
        @slotRole
    export:
      get: ->
        obj =
          type: @type
          slotRole: @slotRole
        switch @type
          when 'TypedSlotDefinition'
            obj.valueType = @valueType
          when 'AttributeSlotDefinition'
            obj.parameterKey = @parameterKey
        obj

