Vue.component 'general-configuration',
  props: ['languages', 'webLogo', 'channelName', 'stageUuid', 'stages', 'toggleOptIn',
    'defaultLanguage', 'setDefaultLanguage', 'optIn'
  ]

  data: ->
    config: state.config
    name: @channelName
    defaultStageVersion: ''
    defaultStageUuid: @stageUuid
    stageIsSet: @stageUuid && @stageUuid != 'none'
    modes:
      open: 'Open'
      closed: 'Close'
      minified: 'Minified'
    defaultMode: 'closed'
    enabled: true
    stageLevel: Globals.stageLevel

  mounted: ->
    if @stageIsSet
      defaultStage = @stages.find (stage) => stage.uuid == @stageUuid
      @defaultStageVersion = defaultStage.version
    configData = @config
    if configData
      @enabled = configData.enabled
      @defaultMode = configData.defaultMode

  methods:
    setDefaultStageVersion: (value) ->
      @defaultStageVersion = value.version
      @defaultStageUuid = value.uuid
      @$emit('set-stage-uuid', @defaultStageUuid)
    allStages: (level) ->
      @stages.filter (stage) -> stage.stage_level == level
    setDefaultMode: (value) ->
      @defaultMode = value

  template: '
    <div class="panel-body widget-general-configuration">
      <div class="widget-general-configuration-item">
        <img :src="webLogo" class="web-icon" />
        <input type="text" name="umh_channel[name]" id="umh_channel_name" v-model="name">
      </div>
      <div class="widget-general-configuration-item">
        <label class="heading-checkbox sliding-switch align">
          Enabled
          <input type="hidden" name="umh_channel[config][enabled]" v-model="enabled">
          <input type="checkbox" name="umh_channel[config][enabled]" checked="true" v-model="enabled">
          <span class="slider"></span>
        </label>
      </div>
      <div class="widget-general-configuration-item">
        <label class="heading-checkbox sliding-switch align">
          Opt-in
          <input type="hidden" name="umh_channel[config][optIn]" v-model="optIn">
          <input type="checkbox" name="umh_channel[config][optIn]" @change="$emit(\'toggleOptIn\')" v-model="optIn">
          <span class="slider"></span>
        </label>
      </div>
      <div class="widget-general-configuration-item">
        <label>Default mode</label>
        <input type="hidden" name="umh_channel[config][defaultMode]" :value="defaultMode">
        <dropdown
          :chosen="modes[defaultMode]"
          align="left"
          class="filter-element"
          >
          <li v-for="(label, value) in modes">
            <a @click="setDefaultMode(value)">{{ label }}</a>
          </li>
        </dropdown>
      </div>
      <div class="widget-general-configuration-item">
        <label>Language</label>
        <input type="hidden" name="umh_channel[config][defaultLanguage]" :value="defaultLanguage">
        <dropdown
          :chosen="languages[defaultLanguage]"
          align="left"
          class="filter-element"
          >
          <li v-for="(label, value) in languages">
            <a @click="$emit(\'setDefaultLanguage\', value)">{{ label }}</a>
          </li>
        </dropdown>
      </div>

      <div class="widget-general-configuration-item">
        <label>Connected stage</label>
        <span class="mr-1">{{ stageLevel.capitalize() }} &gt;</span>
        <dropdown
          :chosen="defaultStageVersion"
          :disabled="stageIsSet"
          align="left"
          class="filter-element"
        >
          <li v-for="stage in allStages(stageLevel)">
            <a @click="setDefaultStageVersion(stage)">{{ stage.version }}</a>
          </li>
        </dropdown>
        <input type="hidden" name="umh_channel[stage_uuid]" v-model="defaultStageUuid">
      </div>
    </div>
  '
