
  import Filtering from './filtering.vue'
  import { Button, EnvironmentSelector, H3, Input, Listbox, Popover as CustomPopover } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { FilterIcon, SearchIcon } from '@heroicons/vue/solid'
  import { ChevronDownIcon, InformationCircleIcon, PresentationChartBarIcon, PresentationChartLineIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      assignedTo: null
      livechatSessionsAnalyticsUrl: Routes.analytics_project_path(Globals.projectId, Globals.stageLevel) + '/livechat_sessions'

    computed:
      filterPanelOpen: ->
        !!(@$root.filters.textSearch || @$root.filters.assignedTo || @$root.parameterFilters.length)
      assignmentOptions: ->
        [
          {id: 'mine', name: t("inbox.assignment.#{@$root.currentTab.key}.mine"), value: [@$root.agent?.id]}
          {id: 'myTeams', name: t("inbox.assignment.#{@$root.currentTab.key}.myTeams"), value: @$root.agent?.teamIds}
        ].concat(@$root.agentTeams.map (team) ->
          {id: team.name, name: team.name, value: [team.id]}
        ).concat([
          {id: 'all', name: t("inbox.assignment.#{@$root.currentTab.key}.all")}
        ])

    watch:
      assignmentOptions: ->
        @assignedTo = @assignmentOptions.find (option) =>
          !option.value? && !@$root.filters.assignedTo? ||
            JSON.stringify(option.value?.sort()) == JSON.stringify(@$root.filters.assignedTo?.sort())
        if !@assignedTo?
          @assignedTo ||= @assignmentOptions.last
          @setAssignment()

    methods:
      setAssignment: ->
        @$root.filters.assignedTo = @assignedTo.value
        @$emit('filter')

    components:
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      CustomPopover: CustomPopover
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      EnvironmentSelector: EnvironmentSelector
      FilterIcon: FilterIcon
      Filtering: Filtering
      H3: H3
      InformationCircleIcon: InformationCircleIcon
      Input: Input
      Listbox: Listbox
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
      PresentationChartBarIcon: PresentationChartBarIcon
      PresentationChartLineIcon: PresentationChartLineIcon
      SearchIcon: SearchIcon
