export default class ProgressItem
  @ctaUrl: (cta) ->
    {
      CHANNELS: Routes.channels_project_path(Globals.projectId, Globals.stageLevel)
      CORE_BEHAVIORS: Routes.automation_project_path(Globals.projectId, Globals.stageLevel) + '/active_bot/core_behaviours'
      KNOWLEDGE: Routes.automation_project_path(Globals.projectId, Globals.stageLevel) + '/active_bot/knowledge'
      SETTINGS: Routes.settings_project_path(Globals.projectId, Globals.stageLevel)
      USERS: Routes.project_users_path(Globals.projectId, Globals.stageLevel)
    }[cta]

  constructor: (@collection, data = {}) ->
    @key = data.key
    @label = data.label
    @cta = data.cta
    @completed = data.completed
    @documentationUrl = data.documentationUrl

  labelWithLink: (attrs = {}) ->
    attrString = Object.entries(attrs)
      .map ([key, value]) -> "#{key}=\"#{value}\""
      .join(' ')
    @label.replace('<a>', "<a href=\"#{@ctaLink}\" #{attrString}>")

  Object.defineProperties @prototype,
    ctaLink:
      get: ->
        ProgressItem.ctaUrl(@cta)
