
  import { passwordExpirationInDays } from 'rails_data'
  import PlatformApi from 'models/platform_api.coffee'
  import { H3, Input, Panel } from 'components/generic'

  export default
    props:
      user: Object

    data: ->
      passwordExpirationInDays: passwordExpirationInDays
      passwordData: {}
      editing: false
      sending: false
      errors: {}

    methods:
      cancel: ->
        @editing = false
        @errors = {}
        @passwordData = {}
      save: ->
        @sending = true
        @errors = {}
        PlatformApi.Webapp.savePassword(@passwordData)
          .then (data) =>
            @editing = false
          .catch (response) =>
            Object.keys(response.errors).forEach (attribute) =>
              @errors[attribute] = response.errors[attribute].join(', ')
          .finally =>
            @sending = false

    components:
      H3: H3
      Input: Input
      Panel: Panel
