import BotbuilderApi from 'models/botbuilder_api.coffee'
import Integration from 'models/integration.coffee'
import WorkflowParameterMapping from 'models/workflow_parameter_mapping'
import WorkflowStep from 'models/workflow_step.coffee'
import WorkflowTrigger from 'models/workflow_trigger.coffee'

export default class Workflow
  constructor: (data = {}) ->
    @id = data.id
    @trigger = new WorkflowTrigger(@, data.trigger || {})
    @steps = (data.steps || []).map (stepData) => new WorkflowStep(@, stepData)
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @id ||= data.id
    @label = data.label if data.label?
    @description = data.description if data.description?
    @enabled = data.enabled if data.enabled?
    @trigger = new WorkflowTrigger(@, data.trigger) if data.trigger?
    @steps = data.steps.map((stepData) => new WorkflowStep(@, stepData)) if data.steps?
    # metadata
    @status = data.status if data.status? # "<OK | INFO | WARN | ERROR | UNKNOWN>",
    @triggerType = data.triggerType if data.triggerType?
    @timeCreated = data.timeCreated if data.timeCreated?
    @timeUpdated = data.timeUpdated if data.timeUpdated?
    @timeLastExecution = data.timeLastExecution if data.timeLastExecution?
    @warningMessage = data.warningMessage if data.warningMessage?

  clone: ->
    new Workflow(@export)

  save: ->
    if @id
      BotbuilderApi.updateWorkflow(@).then (workflowData) =>
        (workflowData.steps || []).forEach (stepData, index) =>
          @steps[index].key = stepData.key
    else
      BotbuilderApi.createWorkflow(@).then (workflowData) => @update(workflowData)

  delete: ->
    BotbuilderApi.deleteWorkflow(@)

  setTrigger: (integration) ->
    return if @trigger.type != 'InboundApi'
    @trigger.integrationId = integration.id
    @trigger.outputMapping = integration.responseParameters.map (parameter) =>
      new WorkflowParameterMapping(key: parameter.key)

  addStep: (typeOrIntegration, index) ->
    if typeof typeOrIntegration == 'string'
      @steps.splice(index, 0, new WorkflowStep(@, type: typeOrIntegration))
    else if typeOrIntegration instanceof Integration
      @steps.splice(index, 0, new WorkflowStep(@, type: 'InvokeIntegration', integrationId: typeOrIntegration.id))

  Object.defineProperties @prototype,
    pristine:
      get: -> !@id?
    triggerType:
      get: -> @trigger.type
      set: (type) -> @trigger.setType(type)
    triggerTypeLabel:
      get: -> @trigger?.label
    timeCreatedFormatted:
      get: ->
        return '' if !@timeCreated?
        moment(@timeCreated).format(t('formats.dateTime'))
    timeUpdatedFormatted:
      get: ->
        return '' if !@timeUpdated?
        moment(@timeUpdated).format(t('formats.dateTime'))
    timeLastExecutionFormatted:
      get: ->
        return '' if !@timeLastExecution?
        moment(@timeLastExecution).format(t('formats.dateTime'))
    export:
      get: ->
        id: @id
        label: @label
        description: @description
        enabled: @enabled
        trigger: @trigger.export
        steps: @steps.map (step) -> step.export
