import { createApp } from 'vue_shims'
import VMdEditor from 'v_md_editor'

initWhatsappChannelSettingsApp = (element) ->
  window.state =
    projectId: $(element).data('project-id')
    type: $(element).data('type')
    channelKey: $(element).data('channel-key')
    channelTypes: $(element).data('channel-types')
    channelName: $(element).data('channel-name')
    languages: $(element).data('languages')
    currentLanguage: Object.keys($(element).data('languages'))[0]
    stages: $(element).data('stages')
    disabled: $(element).data('disabled')
    whatsappLogo: $(element).data('whatsapp-logo')

  createApp(
    data: ->
      stageUuid = $(element).data('stage-uuid')

      state: state
      whitelistedDomains: $(element).data('whitelisted-domains')
      languageStates: {}
      stageUuid: stageUuid
      defaultLanguage: 'de'
      defaultStageVersion: 'A'
      description: $(element).data('business-description') || ''
      email: $(element).data('business-email') || ''
      websites: $(element).data('business-websites') || ''
      address: $(element).data('business-address') || ''
      about: $(element).data('profile-about') || ''
      photo: $(element).data('profile-photo') || ''
      enabled: $(element).data('channel-enabled')
      optinRequired: $(element).data('optin-required')
      phoneNumber: $(element).data('phone-number') || ''
      deletePhoto: false
      stageLevel: Globals.stageLevel
      stageIsSet: stageUuid && stageUuid != 'none'

    methods:
      toggleOptIn: ->
        @optIn = !@optIn
      setDefaultLanguage: (value) ->
        @defaultLanguage = value
      setDefaultStageVersion: (value) ->
        @defaultStageVersion = value.version
        @stageUuid = value.uuid
      allStages: (level) ->
        @state.stages.filter (stage) -> stage.stage_level == level
      removePhoto: ->
        @deletePhoto = true
        @photo = ''

    mounted: ->
      if @stageIsSet
        defaultStage = @state.stages.find (stage) => stage.uuid == @stageUuid
        @defaultStageVersion = defaultStage.version

    components:
      'markdown-editor': VMdEditor

    template: '
      <div>
        <div class="panel-body widget-general-configuration">
          <div class="widget-general-configuration-item">
            <img :src="state.whatsappLogo" class="whatsapp-icon" />
          </div>
          <div class="widget-general-configuration-item">
            <label class="heading-checkbox sliding-switch align">
              Enabled
              <input type="hidden" name="umh_channel[enabled]" v-model="enabled">
              <input type="checkbox" name="umh_channel[enabled]" v-model="enabled">
              <span class="slider"></span>
            </label>
          </div>
          <div class="widget-general-configuration-item">
            <label class="heading-checkbox sliding-switch align">
              Channel requires optin
              <input type="hidden" name="umh_channel[optin_required]" v-model="optinRequired">
              <input type="checkbox" name="umh_channel[optin_required]" v-model="optinRequired">
              <span class="slider"></span>
            </label>
          </div>
          <div class="widget-general-configuration-item">
            <label class="mb-0 mr-2">Connected stage</label>
            <span class="mr-1">{{ stageLevel.capitalize() }} &gt;</span>
            <dropdown
              :chosen="defaultStageVersion"
              :disabled="stageIsSet"
              align="left"
              class="filter-element"
            >
              <li v-for="stage in allStages(stageLevel)">
                <a @click="setDefaultStageVersion(stage)">{{ stage.version }}</a>
              </li>
            </dropdown>
            <input type="hidden" name="umh_channel[stage_uuid]" v-model="stageUuid">
          </div>
        </div>

        <div class="panel-body">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="col-sm-12 padding-bottom-20">
                <h5>Profile</h5>
              </div>
              <div class="col-sm-6">
                <label for="number" class="col-sm-2 col-form-label">Number</label>
                <div class="col-sm-10 padding-bottom-20" >
                  <input
                    type="text"
                    name="umh_channel[phone_number]"
                    id="umh_channel_phone_number"
                    :value="phoneNumber"
                    @keyup.esc="$event.target.blur()"
                  >
                </div>

                <label for="photo" class="col-sm-2 col-form-label">Photo</label>
                <div class="col-sm-10 flex items-center">
                  <img v-if="photo" :src="photo" class="icon-images">
                  <file-upload name="umh_channel[profile_photo]" :url="photo" @reset="photo = \'\'"/>
                  <button type="button" class="btn btn-caution ml-2" :class="{disabled: !photo}" @click="removePhoto">
                    remove
                  </button>
                  <input type="hidden" name="umh_channel[delete_profile_photo]" :value="deletePhoto">
                </div>
              </div>

              <div class="col-sm-6">
                <label for="about" class="col-sm-2 col-form-label">About</label>
                <div class="col-sm-10">
                  <input type="hidden" name="umh_channel[profile_about][text]" :value="about">
                  <textarea v-model="about" rows="3" class="form-control"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="col-sm-12 padding-bottom-20">
                <h5>Business Profile</h5>
              </div>
              <br />
              <div class="col-sm-6">
                <label for="address" class="col-sm-2 col-form-label">Address</label>
                <div class="col-sm-10 padding-bottom-20">
                  <input type="hidden" name="umh_channel[business_profile][address]" :value="address">
                  <textarea v-model="address" rows="3" class="form-control"/>
                </div>
                <label for="email" class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-10">
                  <input type="hidden" name="umh_channel[business_profile][email]" :value="email">
                  <textarea v-model="email" rows="3" class="form-control"/>
                </div>
              </div>

              <div class="col-sm-6">
                <label for="description" class="col-sm-2 col-form-label">Description</label>
                <div class="col-sm-10 padding-bottom-20">
                  <input type="hidden" name="umh_channel[business_profile][description]" :value="description">
                  <textarea v-model="description" rows="3" class="form-control"/>
                </div>
                <label for="website" class="col-sm-2 col-form-label">Website</label>
                <div class="col-sm-10">
                  <input type="hidden" name="umh_channel[business_profile][websites]" :value="websites">
                  <textarea v-model="websites" rows="3" class="form-control"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('whatsapp-channel-configuration-app')
  initWhatsappChannelSettingsApp(element) if element?
