
  export default
    props:
      modelValue: String
      disabled:
        type: Boolean
        default: false

    data: ->
      content: @modelValue
      anchorOffset: 0

    emits: ['focus', 'update:modelValue', 'exit', 'blur']

    watch:
      modelValue: ->
        @anchorOffset = document.getSelection().anchorOffset
        @$el.innerText = (@content = @modelValue)
        @$nextTick => @setCaret()
      content: -> @update()

    mounted: ->
      @$el.innerText = @content

    methods:
      focus: ->
        @$el.focus()
      clear: ->
        @$el.innerText = ''
      reset: ->
        Vue.nextTick =>
          @$el.innerText = @content
      update: ->
        @$el.innerText = '' if @$el.innerText == "\n"
        @$emit('update:modelValue', @$el.innerText.trim())
      emitFocus: ->
        if @disabled
          @$el.blur()
          return
        @$emit('focus')
      exit: (event) ->
        @$emit('exit', event)
        event.target.blur()
      handleEnterKey: (event) ->
        return if event.shiftKey
        @exit(event)
      paste: (event) ->
        clipboardData = event.clipboardData || window.clipboardData
        input = clipboardData.getData('Text')
        document.execCommand('insertText', false, input)
      setCaret: ->
        return if @$el.childNodes.length == 0
        range = document.createRange()
        range.setStart(@$el.childNodes[0], @anchorOffset)
        range.collapse(true)
        selection = document.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
