import { createApp } from 'vue_shims'
import { Button, Modal } from 'components/generic'

initAlertApp = (element, message, resolve) ->
  createApp(
    data: ->
      message: message

    mounted: ->
      @$refs.modal.open()
      document.addEventListener 'keyup', @keyHandler

    methods:
      remove: ->
        resolve()
        element.remove()
        document.removeEventListener 'keyup', @keyHandler
      keyHandler: (e) ->
        return if e.key != 'Enter'
        @$refs.modal.close()

    components:
      Button: Button
      Modal: Modal

    template: '
      <Modal ref="modal" @closed="remove">
        <template #body>
          <div class="whitespace-pre-wrap">
            {{ message }}
          </div>
        </template>
        <template #buttons="{ close }">
          <Button @click="close" type="primary">Ok</Button>
        </template>
      </Modal>
    '
  )
    .mount(element)

export default (message) =>
  new Promise (resolve, reject) =>
    element = document.createElement('div')
    document.body.appendChild(element)
    initAlertApp(element, message, resolve)
