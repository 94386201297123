
  import PlatformApi from 'models/platform_api.coffee'
  import Role from 'models/role.coffee'
  import { alert } from 'helpers'
  import { Button, Checkbox, Input, Label, ScreenOverlay, Table, TableCell, TableHeadCell, TableHeadRow, TableRow } from 'components/generic'

  export default
    props:
      modelValue: Role

    emits: ['close', 'update:modelValue']

    data: ->
      role: if @modelValue? then @modelValue.clone() else null

    computed:
      title: ->
        return if !@role?
        if @editable
          "Editing #{@role.scope.toLowerCase()} role"
        else
          "Standard #{@role.scope.toLowerCase()} role “#{@role.name}”"
      editable: ->
        @role?.organizationId?

    watch:
      modelValue: ->
        if @modelValue?
          @role = @modelValue.clone()
          @$refs.overlay.open()
        else
          @role = null

    methods:
      save: ->
        @role.save()
          .then (data) =>
            @$emit('update:modelValue', new Role(data))
            @$refs.overlay.close()
          .catch (response) =>
            await alert(response.message)

    components:
      Button: Button
      Checkbox: Checkbox
      Input: Input
      Label: Label
      ScreenOverlay: ScreenOverlay
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
