window.Globals = {}
import User from 'models/user.coffee'
// please keep in sync with channel_type_logos in application_helper.rb
import alexaLogo from 'images/logo_alexa.png'
import webhookLogo from 'images/logo_webhook.svg'
import instagramLogo from 'images/logo_instagram.png'
import messengerLogo from 'images/logo_messenger.png'
import webchatLogo from 'images/logo_webchat.png'
import whatsappLogo from 'images/logo_whatsapp.png'
import phoneLogo from 'images/logo_phone.png'
window.Globals.channelTypeLogos = {
  ALEXA: alexaLogo,
  APP: webhookLogo,
  FACEBOOK: messengerLogo,
  INSTAGRAM: instagramLogo,
  WEB: webchatLogo,
  WHATSAPP: whatsappLogo,
  PHONE: phoneLogo,
}

const convertedRestrictions = (rawRestrictions) => {
  if (rawRestrictions) {
    return Object.fromEntries(
      Object.entries(rawRestrictions).map(([key, value]) =>
        [key, value == '.inf' ? Infinity : value]
      )
    )
  } else {
    return {}
  }
}

const setGlobals = () => {
  const $dataElement = $('#globals')
  if (!$dataElement.length) return
  window.Globals.currentUser = new User($dataElement.data('current-user'))
  if ($dataElement.data('project')) {
    window.Globals.project = $dataElement.data('project')
    window.Globals.projectId = $dataElement.data('project').id
    window.Globals.projectUuid = $dataElement.data('project').uuid
    window.Globals.projectName = $dataElement.data('project').name
    window.Globals.projectIsBotcore2 = $dataElement.data('project').isBotcore2
    window.Globals.projectRole = $dataElement.data('project').role
    window.Globals.projectToken = $dataElement.data('project').token
    window.Globals.stageLevel = $dataElement.data('project').stage_level
  } else {
    window.Globals.project = null
    window.Globals.projectId = null
    window.Globals.projectUuid = null
    window.Globals.projectName = null
    window.Globals.projectIsBotcore2 = null
    window.Globals.projectToken = null
    window.Globals.projectRole = null
    window.Globals.stageLevel = null
  }
  window.Globals.mayEditSubscriptionForOrganization = Globals.currentUser.may('edit_organization', {id: Globals.currentUser.organizationId})
  window.Globals.mayEditSubscriptionForProject = Globals.project ? Globals.currentUser.may('edit_organization', {id: Globals.project.owner_organization_id}) : false
  window.Globals.restrictions = convertedRestrictions($dataElement.data('restrictions'))
  window.Globals.organizationRestrictions = convertedRestrictions($dataElement.data('organization-restrictions'))
  $('#globals').remove()
}

export default setGlobals
