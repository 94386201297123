
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { DotsHorizontalIcon } from '@heroicons/vue/solid'

  export default
    props:
      items:
        type: Array
        default: []
      align:
        type: String
        default: 'right'
      direction:
        type: String
        default: 'down'

    computed:
      alignmentClasses: ->
        switch @align + ' ' + @direction
          when 'right down' then 'mt-2 right-0 origin-top-right'
          when 'left down' then 'mt-2 left-0 origin-top-left'
          when 'right up' then 'bottom-10 right-0 origin-bottom-right'
          when 'left up' then 'bottom-10 left-0 origin-bottom-left'

    components:
      Menu: Menu
      MenuButton: MenuButton
      MenuItems: MenuItems
      MenuItem: MenuItem
      DotsHorizontalIcon: DotsHorizontalIcon
