Vue.component 'attachments-editor',
  props: ['attachments', 'messageState', 'smallImage']
  computed:
    titleAndImage: ->
      return [] unless @smallImage
      @attachments[0..1]
  methods:
    remove: (index) ->
      @$emit('remove-attachment', @attachments[index].$key)
  template: '
    <div class="attachment-list">
      <title-with-image-editor
        v-if="smallImage"
        :attachments="titleAndImage"
        :message-state="messageState"
        />
      <template v-for="(attachment, index) in attachments" :key="attachment.$key">
        <attachment-editor
          v-if="!smallImage || index > 1"
          :attachment="attachment"
          :index="index"
          :message-state="messageState"
          @remove-attachment="remove"
          />
      </template>
    </div>
  '
