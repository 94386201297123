
  import WhatsappTemplate from 'models/whatsapp_template'
  import WhatsappTemplateParameter from 'models/whatsapp_template_parameter'
  import WorkflowStep from 'models/workflow_step'

  import WhatsappTemplateParameterEditor from './whatsapp_template_parameter_editor.vue'
  import { H3 } from 'components/generic'

  export default
    props:
      modelValue: WorkflowStep
      template: WhatsappTemplate

    data: ->
      step: @modelValue

    computed:
      noParameters: ->
        @step.headerParameters.length + @step.bodyParameters.length + @step.footerParameters.length + @step.buttonParameters.length == 0

    watch:
      template: ->
        @reSetParameters()

    created: ->
      @setParameters() if @template?

    methods:
      setParameters: ->
        @template.headerParameters.forEach (templateParam, index) =>
          @step.headerParameters[index] ||= templateParam
          @step.headerParameters[index].type = templateParam.type
          @step.headerParameters[index].placeholder = templateParam.placeholder
        @template.bodyParameters.forEach (templateParam, index) =>
          @step.bodyParameters[index] ||= templateParam
          @step.bodyParameters[index].placeholder = templateParam.placeholder
        @template.footerParameters.forEach (templateParam, index) =>
          @step.footerParameters[index] ||= templateParam
          @step.footerParameters[index].placeholder = templateParam.placeholder
        @template.buttonParameters.forEach (templateParam, index) =>
          @step.buttonParameters[index] ||= templateParam
          @step.buttonParameters[index].placeholder = templateParam.placeholder
      reSetParameters: ->
        @step.headerParameters = @template.headerParameters
        @step.bodyParameters = @template.bodyParameters
        @step.footerParameters = @template.footerParameters
        @step.buttonParameters = @template.buttonParameters
      update: ->
        @$emit('update:modelValue', @step)

    components:
      H3: H3
      WhatsappTemplateParameterEditor: WhatsappTemplateParameterEditor
