
  import DialogTest from 'models/dialog_test.coffee'

  import DebugMessage from 'components/inbox3/debug_message.vue'
  import ElementDisplay from './element_display.vue'
  import StatusIcon from './status_icon.vue'
  import { H3 } from 'components/generic'

  export default
    props:
      test: DialogTest

    data: ->
      containerStyle: {}

    computed:
      statusText: ->
        {
          OK: 'The test was successful!'
          FAILED: 'The test failed!'
          UNKNOWN: 'The test has not yet run.'
        }[@test.status]

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setHeight: ->
        top = @$refs.container.getBoundingClientRect().top
        @containerStyle.height = window.innerHeight - top - 16 + 'px'

    components:
      DebugMessage: DebugMessage
      ElementDisplay: ElementDisplay
      H3: H3
      StatusIcon: StatusIcon
