import PlatformApi from 'models/platform_api.coffee'

export default class AnalyticsData

  constructor: ->
    @messages =
      series: {}
      perTimeframe: {}
      perChannel: {}
      summaryCurrent: {}
      summaryPrevious: {}
    @users =
      series: {}
      perTimeframe: {}
      perChannel: {}
      summaryCurrent: {}
      summaryPrevious: {}
    @handovers =
      series: {}
      perTimeframe: {}
      perChannel: {}
      summaryCurrent: {}
      summaryPrevious: {}
    @livechatSessions =
      current: {}
      previous: {}
    @channels =
      data: {}
      summary: {}
    @intents =
      series: {}
      counts: {}
    @retention = {}

  setParams: (settings, settingsPreviousRange, intentLists) ->
    @settings = settings
    @settingsPreviousRange = settingsPreviousRange
    @intentLists = intentLists

  load: ({reload = false, route = null}) ->
    return if !route?
    if reload
      @startedLoadingAt = Date.now()
    if @["load#{route.capitalize()}Data"]?
      @["load#{route.capitalize()}Data"](reload)

  loadMessagesData: (reload) ->
    timestamp = @startedLoadingAt
    config =
      series: 'getMessagesSeries'
      perTimeframe: 'getMessagesPerTimeframe'
      perChannel: 'getMessagesPerChannel'
      summaryCurrent: 'getMessagesSummary'
      summaryPrevious: 'getMessagesSummary'
    Object.keys(config).forEach (attr) =>
      if reload || @messages[attr].timestamp != timestamp || @messages[attr].status != 'ready'
        endpoint = config[attr]
        params = if attr == 'summaryPrevious' then @settingsPreviousRange else @settings
        @messages[attr].status = 'loading'
        PlatformApi.Analytics[endpoint](params)
          .then (data) => @setData(data, @messages[attr], timestamp)
          .catch (response) => @setError(response, @messages[attr], timestamp)

  loadUsersData: (reload) ->
    timestamp = @startedLoadingAt
    config =
      series: 'getUsersSeries'
      perTimeframe: 'getUsersPerTimeframe'
      perChannel: 'getUsersPerChannel'
      summaryCurrent: 'getUsersSummary'
      summaryPrevious: 'getUsersSummary'
    Object.keys(config).forEach (attr) =>
      if reload || @users[attr].timestamp != timestamp || @users[attr].status != 'ready'
        endpoint = config[attr]
        params = if attr == 'summaryPrevious' then @settingsPreviousRange else @settings
        @users[attr].status = 'loading'
        PlatformApi.Analytics[endpoint](params)
          .then (data) => @setData(data, @users[attr], timestamp)
          .catch (response) => @setError(response, @users[attr], timestamp)

  loadHandoversData: (reload) ->
    timestamp = @startedLoadingAt
    config =
      series: 'getHandoversSeries'
      perTimeframe: 'getHandoversPerTimeframe'
      perChannel: 'getHandoversPerChannel'
      summaryCurrent: 'getHandoversSummary'
      summaryPrevious: 'getHandoversSummary'
    Object.keys(config).forEach (attr) =>
      if reload || @handovers[attr].timestamp != timestamp || @handovers[attr].status != 'ready'
        endpoint = config[attr]
        params = if attr == 'summaryPrevious' then @settingsPreviousRange else @settings
        @handovers[attr].status = 'loading'
        PlatformApi.Analytics[endpoint](params)
          .then (data) => @setData(data, @handovers[attr], timestamp)
          .catch (response) => @setError(response, @handovers[attr], timestamp)

  loadLivechatSessionsData: (reload) ->
    timestamp = @startedLoadingAt
    @livechatSessions.current.status = 'loading'
    PlatformApi.Analytics.getLivechatSessions(@settings)
      .then (data) => @setData(data, @livechatSessions.current, timestamp)
      .catch (response) => @setError(response, @livechatSessions.current, timestamp)
    PlatformApi.Analytics.getLivechatSessions(@settingsPreviousRange)
      .then (data) => @setData(data, @livechatSessions.previous, timestamp)
      .catch (response) => @setError(response, @livechatSessions.previous, timestamp)

  loadIntentsData: (reload) ->
    timestamp = @startedLoadingAt
    if reload || @intents.series.timestamp != timestamp || @intents.series.status != 'ready'
      @intents.series.status = 'loading'
      PlatformApi.Analytics.getIntents(@settings, @intentLists)
        .then (data) => @setData(data, @intents.series, timestamp)
        .catch (response) => @setError(response, @intents.series, timestamp)
    if reload || @intents.counts.timestamp != timestamp || @intents.counts.status != 'ready'
      @intents.counts.status = 'loading'
      PlatformApi.Analytics.getIntentCounts(@settings)
        .then (data) => @setData(data, @intents.counts, timestamp)
        .catch (response) => @setError(response, @intents.counts, timestamp)

  loadRetentionData: (reload) ->
    timestamp = @startedLoadingAt
    if reload || @retention.timestamp != timestamp || @retention.status != 'ready'
      @retention.status = 'loading'
      PlatformApi.Analytics.getRetention(@settings)
        .then (data) => @setData(data, @retention, timestamp)
        .catch (response) => @setError(response, @retention, timestamp)

  loadChannelsData: (reload) ->
    timestamp = @startedLoadingAt
    config =
      messages: 'getMessagesPerChannel'
      users: 'getUsersPerChannel'
      handovers: 'getHandoversPerChannel'
    Object.keys(config).forEach (attr) =>
      if reload || @[attr].perChannel.timestamp != timestamp || @[attr].perChannel.status != 'ready'
        endpoint = config[attr]
        @[attr].perChannel.status = 'loading'
        PlatformApi.Analytics[endpoint](@settings)
          .then (data) => @setData(data, @[attr].perChannel, timestamp)
          .catch (response) => @setError(response, @[attr].perChannel, timestamp)

  setData: (data, dataObject, timestamp) ->
    return if timestamp != @startedLoadingAt
    dataObject.timestamp = timestamp
    dataObject.data = data
    dataObject.status = 'ready'

  setError: (response, dataObject, timestamp) ->
    return if timestamp != @startedLoadingAt
    dataObject.timestamp = timestamp
    dataObject.data = null
    dataObject.status = 'error'
    dataObject.message = response.message
