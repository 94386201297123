
  import Message from 'models/message.coffee'
  import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Number

    data: ->
      delayIcon: Message.TEMPLATES['delay'].icon
      delay: @modelValue

    computed:
      delayInSeconds: ->
        @delay / 1000

    watch:
      modelValue: -> @delay = @modelValue

    methods:
      decrement: ->
        @delay -= 100
        @update()
      increment: ->
        @delay += 100
        @update()
      update: ->
        @$emit('update:modelValue', @delay)

    components:
      ChevronDownIcon: ChevronDownIcon
      ChevronUpIcon: ChevronUpIcon
