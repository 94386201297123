Vue.component 'dropdown',
  props: ['chosen', 'align', 'buttonClass', 'disabled']

  computed:
    dropdownClass: ->
      "dropdown-menu dropdown-menu-#{@align}"
    label: ->
      @chosen + (if @disabled then '' else ' <span class="fa fa-chevron-down"></span>')

  mounted: ->
    $(@$el).find('.dropdown-toggle').dropdown()

  template: '
    <div class="custom-select dropdown">
      <div
        :class="[buttonClass, disabled ? \'btn btn-dropdown\' : \'btn btn-dropdown dropdown-toggle\']"
        data-toggle="dropdown"
        v-html="label">
      </div>
      <ul v-if="!disabled" :class="dropdownClass">
        <slot></slot>
      </ul>
    </div>
  '
