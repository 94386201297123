
  import Bot from 'models/bot.coffee'

  import BotCreator from './bot_creator.vue'
  import { H3 } from 'components/generic'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/outline'

  export default
    props:
      currentBot: Bot
      bots: Array

    components:
      BotCreator: BotCreator
      ChevronDownIcon: ChevronDownIcon
      H3: H3
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
