Vue.component 'form-field-dependency',
  props: ['dependency', 'index', 'count', 'fields']
  computed:
    requiresCondition: ->
      @dependency.logicalOperator in ['if', 'and', 'or']
    valueType: ->
      @fields[@dependency.field]?.type
    availableOperators: ->
      TypeValidator.availableOperators(@valueType)
    valueValid: ->
      TypeValidator.valueValid(
        @dependency.value,
        @valueType,
        @dependency.comparisonOperator
      )
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @dependency.comparisonOperator)
  created: ->
    Vue.setDefault(@dependency, 'logicalOperator', 'always')
    Vue.setDefault(@dependency, 'field', '')
    Vue.setDefault(@dependency, 'comparisonOperator', '')
    Vue.setDefault(@dependency, 'value', '')
    Vue.setDefault(@dependency, '$key', GuidGenerator.newGuid())
    @validate()
  watch:
    dependency:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      Vue.set @dependency, '$valid',
        @dependency.logicalOperator in ['always', 'never'] ||
        @dependency.field of @fields &&
        @dependency.comparisonOperator of @availableOperators &&
        @valueValid
    remove: ->
      @$emit('remove-dependency', @index)
  template: '
    <div class="flex-container center-items sub-form-group">
      <div class="width-10">
        <select v-model="dependency.logicalOperator" class="form-control">
          <option v-if="index == 0" value="if">if</option>
          <option v-if="index == 0 && count == 1" value="always">always</option>
          <option v-if="index == 0 && count == 1" value="never">never</option>
          <option v-if="index > 0 && count > 1" value="and">and</option>
          <option v-if="index > 0 && count > 1" value="or">or</option>
        </select>
      </div>&nbsp;
      <div v-if="requiresCondition" :class="{\'has-error\': !dependency.field}" class="width-20">
        <select v-model="dependency.field" class="form-control">
          <option disabled="" value="">select form field</option>
          <option v-for="(field, fieldLabel) in fields" :value="fieldLabel">{{ fieldLabel }}</option>
        </select>
      </div>&nbsp;
      <div v-if="requiresCondition" :class="{\'has-error\': !dependency.comparisonOperator}" class="width-20">
        <select v-model="dependency.comparisonOperator" class="form-control">
          <option disabled="" value="">select operator</option>
          <option :value="operator" v-for="(text, operator) in availableOperators">
            {{ text }} ({{ operator }})
          </option>
        </select>
      </div>&nbsp;
      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input
          v-if="requiresCondition"
          v-model="dependency.value"
          type="text"
          :placeholder="valueLabel"
          class="form-control"
          >
      </div>
      <div v-if="count > 1" v-on:click="remove()" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>
    </div>
  '
