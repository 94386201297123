
  import Summary from './summary.vue'
  import Chart from './chart.vue'
  import DistributionTable from './distribution_table.vue'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    data: ->
      summaryConfig:
        totalMessages: {label: 'Messages received', type: 'absolute'}
        messagesPerUser: {label: 'Messages per user', type: 'absolute'}
        freeTextRate: {label: 'Free text input', type: 'rate'}
        parseRate: {label: 'Parse rate', type: 'rate'}

    computed:
      summary: ->
        ready = @data.messages.summaryCurrent.status == 'ready'
        current = @data.messages.summaryCurrent.data
        previous = @data.messages.summaryPrevious.data
        Object.keys(@summaryConfig).map (key) =>
          key: key
          label: @summaryConfig[key].label
          type: @summaryConfig[key].type
          current: if ready then current[key] else null
          previous: if ready then previous?[key] else null
      series: ->
        ready = @data.messages.series.status == 'ready'
        data = @data.messages.series.data
        freeTextMessages: {label: 'Free text messages', data: if ready then data.freeTextMessages else []}
        buttonMessages: {label: 'Button messages', data: if ready then data.buttonMessages else []}
        mediaMessages: {label: 'Media messages', data: if ready then data.mediaMessages else []}
        messagesInBotConversations:
          label: 'Messages sent in bot conversations'
          data: if ready then data.messagesInBotConversations else []
        messagesInHandoverConversations:
          label: 'Messages sent in live chat conversations'
          data: if ready then data.messagesInHandoverConversations else []
        parseRate:
          label: 'Parse rate'
          data: if ready then @rateToPercent(data.parseRate) else []
        freeTextRate:
          label: 'Percentage of free text input'
          data: if ready then @rateToPercent(data.freeTextRate) else []
      messagesPerTimeframe: ->
        if @data.messages.perTimeframe.status == 'ready'
          @data.messages.perTimeframe.data
        else
          {}
      messagesPerChannel: ->
        data = if @data.messages.perChannel.status == 'ready' then @data.messages.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)

    methods:
      rateToPercent: (dataseries) ->
        dataseries.map (datapoint) ->
          (datapoint * 100).toFixed(2)

    components:
      DistributionTable: DistributionTable
      Chart: Chart
      Summary: Summary
