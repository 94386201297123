
  export default
    props:
      message: String
      prompt: String
      resourceType:
        type: String
        default: 'Project'

    data: ->
      mayEditSubscription: if @resourceType == 'Project'
        Globals.mayEditSubscriptionForProject
      else
        Globals.mayEditSubscriptionForOrganization
      subscriptionUrl: Routes.organization_path(Globals.currentUser.organizationId) + '/subscription'
