
  import Bot from 'models/bot'
  import ContextParameter from 'models/context_parameter'

  import ContextParameterEditor from './context_parameter_editor.vue'
  import { BackLink, DocumentationLink, PageHeader, Panel } from 'components/generic'

  export default
    props:
      bot: Bot

    data: ->
      contextParameter: new ContextParameter(category: @$route.query.category)

    methods:
      cancel: ->
        @$router.push(name: 'contextParameterList')
      save: ->
        @contextParameter.create()
          .then =>
            @$root.contextParameters.push(@contextParameter)
            @$router.push(name: 'contextParameter', params: {parameterKey: @contextParameter.key})

    components:
      BackLink: BackLink
      ContextParameterEditor: ContextParameterEditor
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Panel: Panel
