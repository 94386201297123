
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Bot from 'models/bot.coffee'
  import InboxMessage from 'models/inbox_message.coffee'

  import Sidebar from 'components/botbuilder/sidebar.vue'
  import MessageComponent from 'components/inbox3/message.vue'
  import { Button, H3, Input, Label, Spinner, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      bot: Bot
      show: Boolean

    emits: ['close', 'set-node']

    data: ->
      message: ''
      waitingForResponse: false

    computed:
      messagesToDisplay: ->
        if @$root.showDebugInfo
          @$root.messages
        else
          @$root.messages.filter (message) -> message.origin != 'DEBUG'

    watch:
      show: ->
        return if !@show
        @$nextTick =>
          @scrollToBottom()
          @$refs.messageInput.focus()
      '$root.showDebugInfo': ->
        if @$root.showDebugInfo
          @$nextTick => @scrollToBottom()

    methods:
      sendPayload: (button) ->
        return if !button.payload
        @send(text: button.text?.text, payload: button.payload)
      sendMessage: ->
        return if @message.length == 0
        @send(text: @message)
      scrollToBottom: ->
        @$refs.scrollElement.scrollTop = @$refs.scrollElement.scrollHeight
      send: ({text, payload} = {}) ->
        attachments = [
          if text
            type: 'Text'
            format: 'PLAIN'
            role: 'NONE'
            text: text
        ,
          if payload
            type: 'Payload'
            content: payload
        ].filter (attachment) -> attachment?
        message = new InboxMessage(
          origin: 'USER'
          message:
            type: 'Bubble'
            attachments: attachments
          timestamp: (new Date).toISOString()
        )

        @$root.messages.push(message)
        @$nextTick => @scrollToBottom()

        @waitingForResponse = true
        BotbuilderApi.sendMessage(@bot.id, @$root.sessionId, message.message, @$root.currentLanguage)
          .then (response) =>
            message.interpretation = response.interpretation
            if response.debuggingLog
              @$root.messages.push(Object.assign(response.debuggingLog, origin: 'DEBUG'))
            response.botMessages.forEach (responseMessage) => @$root.messages.push(responseMessage)
            @$nextTick => @scrollToBottom()
          .finally =>
            @waitingForResponse = false
        @message = ''

    components:
      Button: Button
      H3: H3
      Input: Input
      Label: Label
      MessageComponent: MessageComponent
      Sidebar: Sidebar
      Spinner: Spinner
      Switch: Switch
      XIcon: XIcon
