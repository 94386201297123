
  import BotbuilderApi from 'models/botbuilder_api'
  import ContentEntity from 'models/content_entity'
  import { confirm, download } from 'helpers'

  import EntityTableRow from './entity_table_row.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import { Api2PaginationWithNumbers, BackLink, Button, Checkbox, H3, Input, Modal, Spinner, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { UploadIcon, XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      schemaKey: String

    data: ->
      filters:
        query: @$route.query.query || ''
      newEntity: new ContentEntity(@$root.currentLanguage, schemaKey: @schemaKey)
      creating: false
      uploading: false
      fileName: null
      deleteExisting: false
      download: download
      BotbuilderApi: BotbuilderApi
      PlusIcon: PlusIcon
      UploadIcon: UploadIcon
      XIcon: XIcon

    computed:
      filterParams: ->
        params =
          entitySchemaKey: @schemaKey
          query: @filters.query
        Object.entries(params).forEach ([key, value]) -> delete params[key] if value.length == 0
        params
      currentSchema: ->
        @$root.entitySchemas.find (schema) => schema.key == @schemaKey
      createDisabled: ->
        @creating || !@newEntity.label
      uploadDisabled: ->
        @uploading || !@fileName

    watch:
      '$root.currentLanguage': ->
        @reload()

    created: ->
      window.breadcrumbs.strip()
        .append(text: 'Content', callback: => @$router.push(name: 'content'))
        .append(text: @currentSchema?.label)

    mounted: ->
      @reload()

    methods:
      loadElements: ({page, changeRoute} = {}) ->
        changeRoute = if changeRoute? then changeRoute else true
        @$router.push(query: @filters) if changeRoute
        @$nextTick =>
          @$refs.pagination.load(page: page)
      reload: ->
        @loadElements(page: 0, changeRoute: false)
      setTableHeaderPosition: ->
        @$nextTick =>
          headerTop = parseFloat(window.getComputedStyle(@$refs.header).top)
          headerHeight = @$refs.header.offsetHeight
          @$refs.tableHeader.style.top = headerTop + headerHeight + 'px'
      resetNewEntity: ->
        @newEntity = new ContentEntity(@$root.currentLanguage, schemaKey: @schemaKey)
      create: ->
        @creating = true
        @newEntity.create()
          .then =>
            @$router.push(name: 'contentEntity', params: {entityKey: @newEntity.key})
      remove: (index) ->
        @$refs.pagination.remove(index)
      setFileName: ->
        @fileName = @$refs.fileInput.files[0]?.name
      resetFileInput: ->
        @$refs.fileInput.value = null
        @setFileName()
        @uploading = false
      deleteContent: ->
        return unless await confirm("You are going to delete all content of content schema “#{@currentSchema?.label}” and language #{@$root.languages[@$root.currentLanguage]}.\nAre sure?", alert: 'red')
        @currentSchema.deleteContent(@$root.currentLanguage)
          .then @reload
      upload: ->
        @uploading = true
        fileReader = new FileReader()
        fileReader.addEventListener('load', =>
          BotbuilderApi.createContentViaJson(
            @$root.currentLanguage
            @schemaKey
            fileReader.result
            deleteExisting: @deleteExisting
          )
            .then =>
              @reload()
              @$refs.uploadModal.close()
            .catch => @uploading = false
        )
        fileReader.readAsText(@$refs.fileInput.files[0])

    components:
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      BackLink: BackLink
      Button: Button
      Checkbox: Checkbox
      EntityTableRow: EntityTableRow
      H3: H3
      Input: Input
      LanguageSelector: LanguageSelector
      Modal: Modal
      Spinner: Spinner
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
