export default class Environment
  constructor: (data = {}) ->
    data ||= {}
    @environment = data.environment
    @botLabel = data.botLabel
    @botDescription = data.botDescription
    @botId = data.botId
    @botVersion = data.botVersion
    @timeLastConfigChange = data.timeLastConfigChange
    @timeLastModelChange = data.timeLastModelChange
    @timePublished = data.timePublished
    @publishedByUserId = data.publishedByUserId
    @testingStatus = data.testingStatus
    @trainingStatus = data.trainingStatus
    @approvalRequired = data.approvalRequired
    @deploymentId = data.deploymentId

  Object.defineProperties @prototype,
    timeLastConfigChangeFormatted:
      get: ->
        return '' if !@timeLastConfigChange?
        moment(@timeLastConfigChange).format(t('formats.dateTime'))
    dateLastConfigChangeFormatted:
      get: ->
        return '' if !@timeLastConfigChange?
        moment(@timeLastConfigChange).format(t('formats.date'))
    timeLastModelChangeFormatted:
      get: ->
        return '' if !@timeLastModelChange?
        moment(@timeLastModelChange).format(t('formats.dateTime'))
    timePublishedFormatted:
      get: ->
        return '' if !@timePublished?
        moment(@timePublished).format(t('formats.dateTime'))
    datePublishedFormatted:
      get: ->
        return '' if !@timePublished?
        moment(@timePublished).format(t('formats.date'))
