
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Channel from 'models/channel.coffee'
  import ModuleGeneratorTextarea from './module_generator_textarea.vue'
  import { Button, Input, Listbox, Modal } from 'components/generic'
  import { SparklesIcon } from '@heroicons/vue/solid'

  export default
    emits: ['add']

    data: ->
      languagesForSelector = Object.entries(@$root.languages).map ([code, lang]) -> {id: code, name: lang}

      moduleLabel: ''
      language: languagesForSelector[0]
      channelType: Channel.TYPES[0]
      prompt: ''
      sent: false
      availableChannels: Channel.TYPES
      languagesForSelector: languagesForSelector
      SparklesIcon: SparklesIcon

    methods:
      start: ->
        @$refs.modal.open()
        @$nextTick =>
          @$refs.labelInput.focus()
      generate: ->
        BotbuilderApi.autogenerateModule(
          @$root.currentBot
          moduleLabel: @moduleLabel
          prompt: @prompt
          language: @$root.currentLanguage
          channelType: @channelType.key
        ).then (data) =>
          @sent = true
          @$emit('add', data)
        .catch => null

    components:
      Button: Button
      Input: Input
      Listbox: Listbox
      Modal: Modal
      SparklesIcon: SparklesIcon
      ModuleGeneratorTextarea: ModuleGeneratorTextarea
