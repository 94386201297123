import ObjectProcessor from 'models/object_processor.coffee'
import DialogNode from 'models/dialog_node.coffee'
import Target from 'models/dialog_target.coffee'

export default class TriggerIntent extends DialogNode
  constructor: (@dialogModule, data = {}) ->
    super()
    @type = 'TriggerIntent'
    @triggerType = data.triggerType
    @key = data.key
    @label = data.label || ''
    @description = data.description || ''
    @target = new Target(@, data.target)

  update: (data = {}) ->
    @label = data.label if data.label?
    @description = data.description if data.description?
    @target = new Target(@, data.target) if data.target?
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    this

  removeTarget: (node) ->
    return if @target.nodeKey != node.key
    @target.reset()

  Object.defineProperties @prototype,
    triggerSettings:
      get: ->
        @dialogModule.triggerSettings
    color:
      get: -> 'violet'
    export:
      get: ->
        type: @type
        triggerType: @triggerType
        key: @key
        label: @label
        description: @description
        target: @target.export
