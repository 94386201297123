
  import { EnvironmentBadge, H3, Switch } from 'components/generic'
  import { ChevronDoubleDownIcon, ChevronDoubleLeftIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelPanelDevAnimated: Boolean
      modelPanelTestAnimated: Boolean
      modelPanelLiveAnimated: Boolean

    components:
      ChevronDoubleDownIcon: ChevronDoubleDownIcon
      ChevronDoubleLeftIcon: ChevronDoubleLeftIcon
      EnvironmentBadge: EnvironmentBadge
      H3: H3
      Switch: Switch
