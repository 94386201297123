export default class MicroChange
  constructor: (@makroChange, data = {}) ->
    @update(data)

  update: (data) ->
    data ||= {}
    @timestamp = data.timestamp
    @path = data.path
    @description = data.description

  Object.defineProperties @prototype,
    timestampFormatted:
      get: ->
        return '' if !@timestamp?
        moment(@timestamp).format(t('formats.dateTime'))
