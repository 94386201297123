
  import WhatsappChannel from 'models/whatsapp_channel.coffee'
  import { alert } from 'helpers'

  import ImageUpload from '../image_upload.vue'
  import SettingPanel from '../setting_panel.vue'
  import { Input, Textarea } from 'components/generic'
  import { UserCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: WhatsappChannel

    data: ->
      channel: @modelValue
      photo: null
      UserCircleIcon: UserCircleIcon

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      updatePhoto: ->
        oldPhotoUrl = @channel.photoUrl
        @channel.uploadPhoto(@photo)
          .then =>
            return if @channel.photoUrl != oldPhotoUrl
            @photo = null
            await alert("Photo couldn’t be saved.\nPlease note that WhatsApp requires a minimum size of 192x192 px.")

    components:
      ImageUpload: ImageUpload
      Input: Input
      SettingPanel: SettingPanel
      Textarea: Textarea
