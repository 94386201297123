import ConditionType from 'models/condition_type.coffee'

export default class Condition
  @TYPES =
    GenericCondition:
      label: ''
      attributes:
        value: '' # string
        value2: '' # string
        operator:  'equals' # ConditionType
    AndCondition:
      label: ''
      attributes:
        first: null
        second: null
    OrCondition:
      label: ''
      attributes:
        first: null
        second: null
    KnowledgeDisambiguationCondition:
      label: ''
      attributes:
        cardinality: 'NONE'
    AlwaysTrue:
      label: ''
      attributes: {}
    AlwaysFalse:
      label: ''
      attributes: {}

  constructor: (data = {}) ->
    data ||= {}
    @type = data.type || 'GenericCondition'
    Object.entries(@attributes).forEach ([attribute, defaultValue]) =>
      if data[attribute]?
        @[attribute] = switch attribute
          when 'value'
            "#{data[attribute]}"
          when 'first', 'second'
            new Condition(data[attribute])
          else
            data[attribute]
      else
        @[attribute] = defaultValue

  swapLogicalOperator: ->
    return if !['AndCondition', 'OrCondition'].includes(@type)
    @type = switch @type
      when 'AndCondition' then 'OrCondition'
      when 'OrCondition' then 'AndCondition'

  Object.defineProperties @prototype,
    label:
      get: ->
        Condition.TYPES[@type]?.label || @type
    attributes:
      get: ->
        Condition.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    isUnary:
      get: ->
        ConditionType.isUnary(@operator)
    export:
      get: ->
        Object.fromEntries(
          [['type', @type]].concat(
            @attributeKeys.map (attribute) =>
              if ['first', 'second'].includes(attribute)
                [attribute, @[attribute].export]
              else
                [attribute, @[attribute]]
          )
        )
