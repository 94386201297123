
  import Integration from 'models/integration.coffee'

  import { Card, H3 } from 'components/generic'

  export default
    props:
      integration: Integration

    components:
      Card: Card
      H3: H3
