
export default
  props:
    src: String
    size:
      type: String
      default: '12'

  computed:
    wrapperClasses: ->
      "h-#{@size} w-#{@size} rounded-full overflow-hidden bg-gray-100"

