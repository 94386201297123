import PlatformApi from 'models/platform_api.coffee'
import { availableLanguages } from 'rails_data'

export default class Project
  @ENVIRONMENTS = [
    {key: 'DEV', label: 'DEV'}
    {key: 'TEST', label: 'TEST'}
    {key: 'LIVE', label: 'LIVE'}
  ]

  @AVAILABLE_LANGUAGES = Object.entries(availableLanguages).map ([code, label]) ->
    {key: code, label: label}

  constructor: (data) ->
    @id = data.id
    @uuid = data.uuid
    @languages = data.languages || []
    @update(data)

  update: (data) ->
    @name = data.name if data.name?
    @description = data.description if data.description?
    @ownerId = data.owner_id if data.owner_id?
    @languages = data.bc2_languages if data.bc2_languages?
    if data.max_languages?
      @maxLanguages = if data.max_languages == '.inf' then Infinity else data.max_languages
    @messageRetentionTime = data.message_retention_time if data.message_retention_time?

  save: ->
    PlatformApi.Webapp.updateProject(@)

  delete: ->
    PlatformApi.Webapp.deleteProject(@)

  Object.defineProperties @prototype,
    export:
      get: ->
        name: @name
        description: @description
        owner_id: @ownerId
        bc2_languages: @languages
        message_retention_time: @messageRetentionTime
