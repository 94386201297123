Vue.component 'support-option-fail-with-options',
  props: ['option']
  mixins: [WizardComponent]
  data: ->
    entity: @option.failWithOptions
    role: 'tail'
    attributes:
      text:
        type: 'MultilangString'
        default:
          en: 'Do we want to find another solution together?'
          de: 'Wollen wir gemeinsam eine andere Lösung suchen?'
      yes:
        type: 'MultilangString'
        default:
          en: 'Yes'
          de: 'Ja'
      no:
        type: 'MultilangString'
        default:
          en: 'No'
          de: 'Nein'
      startOver:
        type: 'MultilangString'
        default:
          en: 'Start over'
          de: 'Nochmal von vorn'
    textCopy: {}
    yesCopy: {}
    noCopy: {}
    startOverCopy: {}
  created: ->
    if Object.keys(@option.failWithOptions).length > 0
      @pristine = false
    @setCopy()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">If things don’t work out and there are other solutions available, the bot will say ...</h5>
          <inline-edit
            v-model:string="textCopy[state.currentLanguage]"
            placeholder="Do we want to find another solution together?"
            class="center-content"
            />
          <div class="flex-container center-content">
            <div class="btn btn-default">
              <inline-edit v-model:string="yesCopy[state.currentLanguage]" placeholder="Yes"/>
            </div>&nbsp;
            <div class="btn btn-default">
              <inline-edit v-model:string="noCopy[state.currentLanguage]" placeholder="No"/>
            </div>&nbsp;
            <div class="btn btn-default">
              <inline-edit v-model:string="startOverCopy[state.currentLanguage]" placeholder="Start over"/>
            </div>
          </div>
          <support-confirm-button/>
        </div>
      </div>

      <div class="button-container">
        <button v-if="!pristine" :disabled="allComplete ? null : true" @click="finished" class="btn btn-primary">confirm and close</button>
      </div>
    </div>
  '
