
  import PlatformApi2 from 'models/platform_api2.coffee'

  import IntentTableRow from './intent_table_row.vue'
  import { Api2Pagination, Input, MultiselectDropdown, Spinner, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'

  export default
    data: ->
      dialogModulesForSelect = @$root.activeBot.config.allModules.sortByString('label').concat([
        {key: 'HOOK'}, {key: 'CONTEXT_INITIATIVE'}
      ])

      filters:
        modules: if @$route.query.modules
            moduleKeys = @$route.query.modules.split(',')
            dialogModulesForSelect.filter (module) => moduleKeys.includes(module.key)
          else
            []
        query: @$route.query.query || ''
      dialogModulesForSelect: dialogModulesForSelect
      PlatformApi2: PlatformApi2

    computed:
      filterParams: ->
        params =
          modules: @filters.modules.map((m) -> m.key).join(',')
          query: @filters.query
        Object.entries(params).forEach ([key, value]) -> delete params[key] if value.length == 0
        params

    mounted: ->
      @loadIntents(page: 0, changeRoute: false)

    methods:
      loadIntents: ({page, changeRoute} = {}) ->
        changeRoute = if changeRoute? then changeRoute else true
        @$router.push(query: @filterParams) if changeRoute
        @$nextTick =>
          @$refs.pagination.load(page: page)
      setTableHeaderPosition: ->
        @$nextTick =>
          filterContainerTop = parseFloat(window.getComputedStyle(@$refs.filterContainer).top)
          filterContainerHeight = @$refs.filterContainer.offsetHeight
          @$refs.tableHeader.style.top = filterContainerTop + filterContainerHeight + 'px'

    components:
      Api2Pagination: Api2Pagination
      Input: Input
      IntentTableRow: IntentTableRow
      MultiselectDropdown: MultiselectDropdown
      Spinner: Spinner
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
