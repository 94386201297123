import Conversation from 'models/conversation'
import DesktopNotifier from 'models/desktop_notifier'

Vue.component 'inbox2-conversation-list',
  props:
    filters: Object

  data: ->
    newMessage: ''
    startWithOldConversations: false
    scrolledToTop: true
    lastScrollDirection: 'up'
    dontEmitScroll: false
    fixed: false
    newConversationCount: 0
    conversationRefs: []

    # for infinite scroll
    collection: []
    params: {pageSize: 15}
    loading: true # current loading state
    threshold: 400 # in pixels (if end of inner container is nearer than threshold, new item will be loaded)
    browserNotificationCount: 0

  computed:
    conversationLists: ->
      [
        label: t('inbox.date.today')
        id: 'today'
        list: @collection.filter (conversation) -> conversation.isOfToday
      ,
        label: t('inbox.date.yesterday')
        id: 'yesterday'
        list: @collection.filter (conversation) -> conversation.isOfYesterday
      ,
        label: t('inbox.date.thisWeek')
        id: 'this-week'
        list: @collection.filter (conversation) -> conversation.isOfThisWeek
      ,
        label: t('inbox.date.before')
        id: 'before'
        list: @collection.filter (conversation) -> conversation.isBeforeThisWeek
      ]
    openConversation: ->
      @collection.filter (conversation) -> conversation.open == true
    unreadConversation: ->
      @collection.filter (conversation) -> conversation.lastMessageReadByAnyAgent == false

  created: ->
    @$root.api.handlers.message.queued = @handleMessageEvent
    @$root.api.handlers.message.sent = @handleMessageEvent
    @$root.api.handlers.message.received = @handleMessageEvent
    @$root.api.handlers.user_info = @handleUserEvent
    @$root.api.handlers.context = @handleContextEvent
    @$root.api.handlers.conversation_notes = @handleNotesEvent
    @$root.api.handlers.conversation_handover = @handleHandoverEvent
    @$root.api.handlers.conversation_annotation = @handleAnnotationEvent

  mounted: ->
    # initialize infinite scroll
    @scrollElement = $(@$el).find('.infinite-scroll')
    @oldScrollTop = @scrollElement[0].scrollTop
    @scrollElement.on 'scroll', @check
    @scrollElement.on 'scroll', @emitScroll
    @scrollElement.on 'scroll', @setNewConversationCount
    @scrollElement.on 'scroll', @setScrolledToTop

  beforeUpdate: ->
    @conversationRefs = []

  methods:
    currentConversation: -> # cannot use computed property as change in the ref doesn't trigger new calculation
      @conversationRefs.find((conversationComponent) -> conversationComponent.open)
    # reset conversation list and load anew
    loadConversations: (options = {}) ->
      new Promise (resolve, reject) =>
        if !options.conversation
          @startWithOldConversations = false
          @filters.timestamp = null
          @start().then(resolve)
        else
          @startWithOldConversations = true
          startDate = new Date(options.conversation.user.timeLastSeen)
          # strangely, the timestamp filter doesn’t exist (anymore?)
          @filters.timestamp = moment(startDate).add(60, 's').toDate().toISOString() # sometimes there are inconsistencies, so I add a minute to be sure.
          @start()
            .then(=> @findConversation(0, options.conversation.user.id))
            .then(=>
              @scrollToItem(options.conversation.user.id)
              resolve()
            ).catch()
    findConversation: (depth = 0, conversationUuid) ->
      conversation = @collection.find (conversation) -> conversation.id == conversationUuid
      new Promise (resolve, reject) =>
        if conversation?
          resolve(conversation)
        else if depth < 4
          @load()
            .then(=> @findConversation(depth + 1, conversationUuid))
            .then(resolve)
            .catch(reject)
        else
          reject('Couldn’t find conversation.')

    findAnnotatable: (direction) ->
      currentIndex = @conversationRefs.findIndex (conversationComponent) =>
        conversationComponent && conversationComponent.open
      if currentIndex
        if direction == 'down' # finding newer conversations
          nextConversation = @conversationRefs.slice(0, currentIndex).reverse()
            .find (conv) -> conv.conversation.annotationsSuggested
        else if direction == 'up' # finding older conversations
          nextConversation = @conversationRefs.slice(currentIndex + 1)
            .find (conv) -> conv.conversation.annotationsSuggested
          if !nextConversation? && @conversationRefs[-1..][0].conversation.lastMessageTime >= @$root.earliestAnnotation
            @load()
              .then => @findAnnotatable(direction)
            return
        if !nextConversation? || nextConversation.open
          @currentConversation()?.annotationInterface.showAnnotatableNotFound = true
          return
        nextConversation.expand()
          .then =>
            nextConversation.annotationInterface.findAnnotatable(direction)

    handleMessageEvent: (data) ->
      # find or create conversation
      conversation = @collection.find (conv) -> conv.id == data.user.id
      if !conversation then conversation = @addNewConversation(data)

      oldLastMessageReadByAnyAgent = conversation.lastMessageReadByAnyAgent
      newMessage = conversation.addMessage(data)

      # care for 'read' status and notifications
      if conversation.id == @currentConversation()?.id
        conversation.lastMessageReadByAnyAgent = true
        @$root.api.setMessageRead(newMessage)

      # We are doing this for a hotfix. But we need to change this logic.
      if newMessage.origin && newMessage.origin == 'USER' && conversation.isInHandoverMode && conversation.handoverSession.assignedAgentId == @$root.agent.id && !conversation.open
        if oldLastMessageReadByAnyAgent
          @browserNotificationCount += 1
          @changeTitle()
        DesktopNotifier.notify('There’s a bot user waiting for an answer!')

      if !conversation.lastMessageReadByAnyAgent && oldLastMessageReadByAnyAgent && !@scrolledToTop
        @newConversationCount += 1

      # push conversation to top
      if conversation.lastMessageTime > @collection[0].lastMessageTime
        @prependConversation(conversation)

    addNewConversation: (data) ->
      conversation = new Conversation(data)
      @$root.api.loadConversation(conversation).then (data) => conversation.update(data)
      @prependConversation(conversation)
      conversation

    prependConversation: (conversation) ->
      @dontEmitScroll = true
      oldScrollTop = @scrollElement.scrollTop()
      index = @collection.findIndex (conv) -> conv.id == conversation.id
      if index == -1
        @collection = [conversation].concat(@collection)
      else
        @collection = [conversation]
          .concat @collection.slice(0, index)
          .concat @collection.slice(index + 1)
      # if scroll position does not change
      conversationComponent = @conversationRefs.find (conv) -> conv.id == conversation.id
      if conversationComponent? && conversationComponent.offsetTop() < oldScrollTop
        @dontEmitScroll = false
        return
      # otherwise keep vertical position of list:
      Vue.nextTick =>
        conversationComponent = @conversationRefs.find (conv) -> conv.id == conversation.id
        if conversationComponent
          if conversationComponent.open
            @scrollElement.scrollTop(conversationComponent.offsetTop())
          else if conversationComponent.offsetTop() < @scrollElement.scrollTop()
            @scrollElement.scrollTop(oldScrollTop + conversationComponent.height())
          @dontEmitScroll = false

    handleUserEvent: (data) ->
      conversation = @collection.find (conv) -> conv.id == data.user.id
      return if !conversation
      conversation.update(user: data.user)

    handleContextEvent: (data) ->
      conversation = @collection.find (conv) -> conv.id == data.user.id
      return if !conversation
      conversation.update(context: data.updatedContext)

    handleNotesEvent: (data) ->
      conversation = @collection.find (conv) -> conv.id == data.user.id
      return if !conversation
      conversation.update(notes: data.notes)

    handleHandoverEvent: (data) ->
      conversation = @collection.find (conv) -> conv.id == data.user.id
      if !conversation
        conversation = @addNewConversation(data)
        @$root.api.loadMessages(conversation, pageSize: 1)
          .then (data) => conversation.addMessage(data[0])
      else
        conversation.update(handoverSession: data.handoverSession)
      # notify
      if conversation.isInHandoverMode && conversation.handoverSession.assignedAgentId == @$root.agent.id && !conversation.open
        @browserNotificationCount += 1
        @changeTitle()
        DesktopNotifier.notify('There’s a bot user waiting for an answer!')

    handleAnnotationEvent: (data) ->
      conversation = @collection.find (conv) -> conv.id == data.user.id
      return if !conversation
      conversation.update(annotationsSuggested: data.annotationsSuggested)

    onExpand: (conversationComponent) ->
      @fixed = true
      # trigger header collapsing
      @$emit('expand-conversation')
      # scroll into view
      offsetTop = conversationComponent.offsetTop()
      # modifiy offsetTop if there is an open conversation above the one to be opened
      if @currentConversation()? && @conversationRefs.indexOf(@currentConversation()) < @conversationRefs.indexOf(conversationComponent)
        collapsedConversationHeight = $(conversationComponent.$el).outerHeight(true)
        offsetTop = offsetTop - $(@currentConversation().$el).outerHeight(true) + collapsedConversationHeight
      # do the scrolling
      @dontEmitScroll = true
      @scrollElement.animate(
        {scrollTop: offsetTop},
        600,
        =>
          @dontEmitScroll = false
      )
      # collapse open conversation
      @currentConversation()?.collapse()

    onCollapsed: (conversationComponent) ->
      if @$root.activeTab == 'handover' && !conversationComponent.conversation.isInHandoverMode
        @collection = @collection.filter (conv) -> conv.id != conversationComponent.id
      return if @currentConversation()
      @fixed = false
      Vue.nextTick =>
        @$emit('scroll-up') if @setScrolledToTop()

    # methods for infinite scroll:
    # start infinite scroll
    start: ->
      @collection = []
      # reset status
      @loading = true
      @finished = false
      # load
      new Promise (resolve, reject) =>
        @$root.api.initFilter(@filters)
          .then(@append)
          .then(resolve)
          .catch =>
            setTimeout(@start, 10000)
    # check whether new items should be loaded
    check: ->
      new Promise (resolve, reject) =>
        if @loading || @finished
          resolve()
          return
        if @scrollElement[0].scrollHeight - (@scrollElement.scrollTop() + @scrollElement.height()) < @threshold
          @load().then(resolve)
        else
          resolve()
    # load new items
    load: ->
      new Promise (resolve, reject) =>
        if @finished
          resolve()
          return
        @loading = true
        @$root.api.loadConversations(@params)
          .then(@append)
          .then(resolve)
          .catch =>
            setTimeout(@load, 10000)
    append: (data) ->
      newConversations = data.map (item) -> new Conversation(item)
      @collection.push.apply(@collection, newConversations)
      if @$root.activeTab == 'handover'
        @browserNotificationCount = @unreadConversation.length
        @changeTitle()
      new Promise (resolve, reject) =>
        Vue.nextTick =>
          # update status
          @params.beforeId = @collection[-1..][0]?.id
          @finished = if data.finished? then data.finished else data.length < @params.pageSize
          @loading = false
          @check().then(resolve)
    scrollToItem: (id) ->
      item = @scrollElement.find("[data-id=\"#{id}\"]")
      top = @scrollElement.scrollTop() + item.position().top
      # offset = (@scrollElement.height() - item.height()) / 2
      @scrollElement.animate(scrollTop: top, 200, =>
        conversationComponent = @conversationRefs.find (conv) -> conv.id == id
        conversationComponent?.expand()
      )

    toggle: (e, id) ->
      $button = $(e.target).closest('.date-toggler')
      if $button.attr('aria-expanded') == 'true'
        $button.attr('aria-expanded', 'false')
        $(@$el).find('#' + id)
          .one('hidden.bs.collapse', @check)
          .collapse('hide')
      else
        $button.attr('aria-expanded', 'true')
        $(@$el).find('#' + id).collapse('show')
    scrollToTop: ->
      @scrollElement.animate({scrollTop: 0}, 500)
    setScrolledToTop: ->
      @scrolledToTop = @scrollElement[0].scrollTop == 0
    emitScroll: ->
      @lastScrollDirection = if @oldScrollTop > @scrollElement[0].scrollTop then 'up' else 'down'
      @$emit("scroll-#{@lastScrollDirection}") if !(@dontEmitScroll || @fixed)
      @oldScrollTop = @scrollElement[0].scrollTop
    setNewConversationCount: ->
      return if @lastScrollDirection == 'down'
      return if @newConversationCount == 0
      for conversationComponent, index in @conversationRefs
        if conversationComponent.offsetTop() > @scrollElement[0].scrollTop
          @newConversationCount = Math.min(@newConversationCount, index)
          break
    contentHeight: ->
      $(@$el).find('.infinite-scroll')[0].scrollHeight
    changeTitle: ->
      if @browserNotificationCount > 0
        document.title = "(#{@browserNotificationCount}) Mercury.ai"
      else
        document.title = "Mercury.ai"
    decreaseBrowserNotification: ->
      @browserNotificationCount = @browserNotificationCount - 1
      @changeTitle()
    setConversationRef: (el) ->
      @conversationRefs.push(el) if el?

  template: '
    <div class="conversation-list">

      <div v-if="!loading && collection.length == 0" class="conversation-placeholder"></div>

      <div v-show="!scrolledToTop && !fixed" @click="scrollToTop" class="btn btn-plain scroll-to-top">
        <div v-show="newConversationCount" class="label label-default">{{ newConversationCount }}</div>
        <span class="fa fa-chevron-up"/>
      </div>

      <div class="infinite-scroll" :class="{loading: loading && collection.length == 0, fixed: fixed}">
        <template v-for="list in conversationLists" :key="list.label">
          <div v-if="list.list.length" class="date-list">
            <div @click="toggle($event, list.id)" class="date-toggler pointer" aria-expanded="true">
              {{ list.label }}
              <span class="chevron"></span>
            </div>
            <div :id="list.id" class="collapse in">
              <inbox2-conversation
                v-for="conversation in list.list"
                :key="conversation.id"
                :ref="setConversationRef"
                :conversation="conversation"
                @expand="onExpand"
                @collapsed="onCollapsed"
                @decreaseBrowserNotification="decreaseBrowserNotification"
                />
            </div>
          </div>
        </template>
      </div>
    </div>
  '
