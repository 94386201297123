
  import Integration from 'models/integration.coffee'

  import { BackLink, DocumentationLink, Input, KeyListbox, PageHeader, Panel } from 'components/generic'

  export default
    data: ->
      integration: new Integration()
      integrationTypes: Object.entries(Integration.TYPES).map ([key, config]) -> {key: key, label: config.label}

    methods:
      save: ->
        @integration.save()
          .then =>
            @$router.push(name: 'integration', params: {integrationId: @integration.id})
      cancel: ->
        @$router.push(name: 'integrationList')

    components:
      BackLink: BackLink
      DocumentationLink: DocumentationLink
      Input: Input
      KeyListbox: KeyListbox
      PageHeader: PageHeader
      Panel: Panel
