
  import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'
  import ValueType from 'models/value_type.coffee'

  import DataTypeDependentInput from './data_type_dependent_input.vue'
  import { Button, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: [String, Number, Boolean, Array]
      attribute: EntitySchemaAttribute

    data: ->
      value: @modelValue
      inputRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @value = @modelValue

    beforeUpdate: ->
      @inputRefs = []

    methods:
      update: ->
        @$emit('update:modelValue', @value)
      add: ->
        @value ||= []
        @value.push(
          if ValueType.isNumber(@attribute.valueType)
            0
          else if @attribute.valueType == 'bool'
            true
          else
            ''
        )
        @$nextTick => @inputRefs.last.focus()
      remove: (index) ->
        @value.splice(index, 1)
        @update()
      setInputRef: (index, el) ->
        @inputRefs[index] = el if el?

    components:
      Button: Button
      DataTypeDependentInput: DataTypeDependentInput
      Label: Label
      XIcon: XIcon
