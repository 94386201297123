
import ParameterFilter from 'models/parameter_filter.coffee'
import Button from './button.vue'
import H3 from './h3.vue'
import ParameterFilterComponent from './parameter_filter.vue'
import { FilterIcon } from '@heroicons/vue/outline'

export default
  props:
    modelValue: Array
    parameters: Array
    context: String # e.g. 'inbox' or 'wa-promotion'
    title: String
    disabled:
      type: Boolean
      default: false

  data: ->
    filters: @modelValue
    filterRefs: []

  watch:
    modelValue: -> @filters = @modelValue

  beforeUpdate: ->
    @filterRefs = []

  methods:
    addFilter: ->
      @filters.push(new ParameterFilter())
      Vue.nextTick =>
        setTimeout =>
          @filterRefs[-1..][0].open()
        , 0
    parameterLabel: (parameter) ->
      parameter.humanizedLabel || parameter.label
    applyFilters: (filter) ->
      if filter? && @$root.track?
        @$root.track("#{@context.capitalize()} filter added", filter: filter)
      @filters = @filters.filter (filter) -> filter.valid
      @$emit('update:modelValue', @filters)
    removeAppliedFilter: (index) ->
      removedFilter = @filters.splice(index, 1)
      if @$root.track?
        @$root.track("#{@context.capitalize()} filter removed", filter: removedFilter[0])
      @applyFilters()
    removeDraftFilter: (index) ->
      @filters.splice(index, 1)
    resetFilter: (index, filter) ->
      @filters[index] = filter.clone()
    setFilterRef: (el) ->
      @filterRefs.push(el) if el?

  components:
    Button: Button
    FilterIcon: FilterIcon
    H3: H3
    ParameterFilter: ParameterFilterComponent
