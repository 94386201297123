
  import TemplateInList from './template_in_list.vue'
  import { H3, Table, TableHeadCell, TableHeadRow } from 'components/generic'

  export default
    methods:
      removeTemplate: (index) ->
        @$root.templates.splice(index, 1)

    components:
      H3: H3
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TemplateInList: TemplateInList
