import Analytics from 'analytics.coffee'

export default (error, vueInstance, info) ->
  console.log(error)
  try
    Analytics.track(
      'Vue error occurred',
      is_vue_error: true
      error: "#{error.name}: #{error.message}",
      error_json: JSON.stringify(error, Object.getOwnPropertyNames(error))
      browser: window.navigator.userAgent,
      stack: error.stack.split("\n").map (line) -> line.split('@')[0]
      vue_info: info,
      url: window.location.href,
      is_botcore_2: Globals.projectIsBotcore2,
      project_role: Globals.projectRole
    )
