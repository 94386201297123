Vue.component 'handover-game',
  props: ['config']

  data: ->
    state: state
    gameValidator: GameValidator

  computed:
    availableContextParameters: ->
      @state.globalContextParameters.map((cp) -> Object.assign({}, cp, {label: "global.#{cp.label}"}))

  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'invocationUtterance',
            'confirmationQuestion',
            'moderationMessage',
            'noHandoverMessage',
          ]
        )
      deep: true

  created: ->
    Vue.setDefault(@config, 'triggerHandoverOnNoParse', true)
    Vue.setDefault(@config, 'triggerThreshold', 2)
    Vue.setDefault(@config, 'confirmation', true)
    Vue.setDefault(@config, 'checkBeforeTriggering', false)
    Vue.setDefault(@config, 'cancelHandoverViaMenu', true)
    Vue.setDefault(@config, 'invocationUtterance', {})
    Vue.setDefault(@config, 'reactions', [{}])
    Vue.setDefault(@config, 'confirmationQuestion', {})
    Vue.setDefault(@config, 'moderationMessage', {})
    Vue.setDefault(@config, 'handoverNotPossibleMessage', {})
    Vue.setDefault(@config, 'noHandoverMessage', {})
    # amend old format
    if typeof Object.values(@config.confirmationQuestion)[0] == 'string'
      for lang, message of @config.confirmationQuestion
        defaultMessage = @state.defaultConfig.confirmationQuestion[lang] || @state.defaultConfig.confirmationQuestion.en
        @config.confirmationQuestion[lang] = ObjectProcessor.clone(defaultMessage)
        @config.confirmationQuestion[lang].web[0].messages[0].attachments[0].text = message
    if typeof Object.values(@config.moderationMessage)[0] == 'string'
      for lang, message of @config.moderationMessage
        defaultMessage = @state.defaultConfig.moderationMessage[lang] || @state.defaultConfig.moderationMessage.en
        @config.moderationMessage[lang] = ObjectProcessor.clone(defaultMessage)
        @config.moderationMessage[lang].web[0].messages[0].attachments[0].text = message

  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Trigger settings</h3>
      </div>
      <div class="panel-body non-first">
        <p>
          Should handover be triggered when the bot doesn’t understand a user input?
          <select class="styled-form-field" v-model="config.triggerHandoverOnNoParse">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </p>
        <p v-if="config.triggerHandoverOnNoParse">
          What should be the trigger threshold?
          After how many “did not understand” events should the handover be triggered?
          <select class="styled-form-field" v-model="config.triggerThreshold">
            <option :value="v" v-for="v in [1,2,3,4,5]">{{ v }}</option>
          </select>
        </p>
        <p>
          In case of a trigger event, should the bot ask whether to do a handover?
          <select class="styled-form-field" v-model="config.confirmation">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </p>
        <p>
          Should the bot check whether a handover agent is available before entering handover mode?
          (If yes, you may enter a message that is displayed when no agent is available.)
          <select class="styled-form-field" v-model="config.checkBeforeTriggering">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </p>
        <p>
          Should users be able to end handover mode and reactivate the bot?
          This will add an option to the persistent menu.
          <select class="styled-form-field" v-model="config.cancelHandoverViaMenu">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </p>
      </div>

      <div class="panel-heading">
        <h3>Intent structure</h3>
      </div>
      <div class="panel-body">
        <div class="responsive-grid-container">
          <div class="responsive-grid-item item-300 no-margin-top">
            <h4>Invocation utterances for handover</h4>
            <multilang-variation-field :dictionary="config.invocationUtterance"/>
            <div class="spacer"></div>

            <h5>Bot reaction</h5>
            <bot-reactions
              :host="config"
              :context-parameters="availableContextParameters"
              :allow-last-active-game="true"
              :agent-teams="state.agentTeams"
              />
          </div>

          <div class="responsive-grid-item item-500 no-margin-top">
            <h4>Bot messages</h4>
            <div class="panel-group">
              <panel-togglable v-if="config.confirmation">
                <template v-slot:togglingheader>
                  <div class="expand">Handover confirmation</div>
                  <div class="check">
                    <span class="fa fa-exclamation-circle text-danger" v-if="!config.confirmationQuestion.$valid"></span>
                  </div>
                </template>
                <template v-slot:body>
                  <nlg-editor :nlg="config.confirmationQuestion"/>
                </template>
              </panel-togglable>
              <panel-togglable>
                <template v-slot:togglingheader>
                  <div class="expand">Handover moderation</div>
                  <div class="check">
                    <span class="fa fa-exclamation-circle text-danger" v-if="!config.moderationMessage.$valid"></span>
                  </div>
                </template>
                <template v-slot:body>
                  <nlg-editor :nlg="config.moderationMessage"/>
                </template>
              </panel-togglable>
              <panel-togglable v-if="config.checkBeforeTriggering">
                <template v-slot:togglingheader>
                  <div class="expand">Message that is displayed when no handover agent is available</div>
                  <div class="check">
                    <span class="fa fa-exclamation-circle text-danger" v-if="!config.handoverNotPossibleMessage.$valid"></span>
                  </div>
                </template>
                <template v-slot:body>
                  <nlg-editor :nlg="config.handoverNotPossibleMessage"/>
                </template>
              </panel-togglable>
              <panel-togglable>
                <template v-slot:togglingheader>
                  <div class="expand">Message for users who want no handover</div>
                  <div class="check">
                    <span class="fa fa-exclamation-circle text-danger" v-if="!config.noHandoverMessage.$valid"></span>
                  </div>
                </template>
                <template v-slot:body>
                  <nlg-editor :nlg="config.noHandoverMessage"/>
                </template>
              </panel-togglable>
            </div>
          </div>
        </div>
      </div>
    </div>
  '
