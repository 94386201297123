Vue.component 'general-appearance',
  props: ['cornerRadius'],

  data: ->
    defaultStyle =
      headerColor: '#3A3A3A'
      bodyColor: '#F9F8F8'
      bodyShadowColor: '#000000'
      bodyShadowSize: '10'
      font: ''
      bodyBorderRadius: '8'

    fontFamilies: [
      'Arial',
      'Verdana',
      'Helvetica',
      'Tahoma',
      'Trebuchet MS',
      'Times New Roman',
      'Georgia',
      'Garamond',
    ]
    config: state.config
    removeFooter: false
    logoUrl: ''
    avatarUrl: ''
    toggleOpen: ''
    toggleClose: ''
    defaultStyle: defaultStyle
    style: ObjectProcessor.clone(defaultStyle)

  computed:
    colorsChanged: ->
      ['headerColor', 'bodyColor', 'bodyShadowColor', 'bodyShadowSize'].some (attr) =>
        @style[attr] != @defaultStyle[attr]

  mounted: ->
    configData = @config
    if configData
      @removeFooter = configData.removeFooter
      @logoUrl = configData.logoUrl
      @avatarUrl = configData.avatarUrl
      @toggleOpen = configData.toggleButton?.open
      @toggleClose = configData.toggleButton?.close
      if configData.style
        @style =
          headerColor: configData.style.header_color || @style.headerColor
          bodyColor: configData.style.body_color || @style.bodyColor
          bodyShadowColor: configData.style.body_shadow_color || @style.bodyShadowColor
          bodyShadowSize: configData.style.body_shadow_size || @style.bodyShadowSize
          font: configData.style.body_font || @style.font
          bodyBorderRadius: configData.style.body_border_radius || @style.bodyBorderRadius

  methods:
    setFamily: (value) ->
      @style.font = value
    removeFont: ->
      @style.font = ''
    resetColors: ->
      ['headerColor', 'bodyColor', 'bodyShadowColor', 'bodyShadowSize'].forEach (attr) =>
        @style[attr] = @defaultStyle[attr]

  template: '
    <div class="general-apperance">
      <div class="form-group row">
        <label for="logo" class="col-sm-2 col-form-label">
          Add logo
        </label>
        <div class="col-sm-10 flex items-center">
          <img v-if="logoUrl" :src="logoUrl" class="icon-images">
          <input type="hidden" name="umh_channel[config][logoUrl]" :value="logoUrl">
          <file-upload name="umh_channel[file][logo]" :url="logoUrl" @reset="logoUrl = \'\'"/>
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Add avatar
        </label>
        <div class="col-sm-10 flex items-center">
          <img v-if="avatarUrl" :src="avatarUrl" class="icon-images">
          <input type="hidden" name="umh_channel[config][avatarUrl]" :value="avatarUrl">
          <file-upload name="umh_channel[file][avatar]" :url="avatarUrl" @reset="avatarUrl = \'\'"/>
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Colors
        </label>
        <div class="col-sm-10 flex items-center">
          <color-picker v-model="style.headerColor" colorpicker="header" property="Header" name="umh_channel[config][style][header_color]" />
          <color-picker v-model="style.bodyColor" colorpicker="body" property="Body" name="umh_channel[config][style][body_color]" />
          <color-picker v-model="style.bodyShadowColor" colorpicker="shadow" property="Shadow" name="umh_channel[config][style][body_shadow_color]" />
          <p class="property">Blur</p>
          <number-input v-model="style.bodyShadowSize" name="umh_channel[config][style][body_shadow_size]" />
          <refresh-button :clickable="colorsChanged" @click="resetColors"/>
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Font
        </label>
        <input type="hidden" name="umh_channel[config][style][body_font]" :value="fontFamilies[fontFamilies.indexOf(style.font)]">
        <div class="col-sm-10 flex items-center font">
          <dropdown
            :chosen="fontFamilies[fontFamilies.indexOf(style.font)] || \'Choose font\'"
            align="left"
            class="filter-element"
            >
            <li v-for="font in fontFamilies">
              <a @click="setFamily(font)">{{ font }}</a>
            </li>
          </dropdown>
          <refresh-button :clickable="style.font ? true : false" @click="removeFont" />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="corner" class="col-sm-2 col-form-label">
          Corner
        </label>
        <div class="col-sm-10 flex items-center">
          <div class="icon-image"><img :src="cornerRadius" /></div>
          <number-input v-model="style.bodyBorderRadius" name="umh_channel[config][style][body_border_radius]" />
          <refresh-button
            :clickable="style.bodyBorderRadius != defaultStyle.bodyBorderRadius"
            @click="style.bodyBorderRadius = defaultStyle.bodyBorderRadius"
          />
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="avatar" class="col-sm-2 col-form-label">
          Footer
        </label>
        <div class="col-sm-10">
          <label for="removeFooter" class="sliding-switch non-bold">
            <input type="checkbox" name="umh_channel[config][removeFooter]" id="removeFooter" v-model="removeFooter">
            <span class="slider"></span>
            <span class="switch-label-right">Disable</span>
          </label>
        </div>
      </div>
      <div class="form-group row"><div class="dotted-row"></div></div>
      <div class="form-group row">
        <label for="toggle" class="col-sm-2 col-form-label">
          Add toggle button
        </label>
        <div class="col-sm-10">
          <div class="flex items-center">
            <img v-if="toggleOpen" :src="toggleOpen" class="icon-images">
            <input type="hidden" name="umh_channel[config][toggleButton_open]" :value="toggleOpen">
            <file-upload name="umh_channel[file][open_toggle]" :url="toggleOpen" @reset="toggleOpen = \'\'"/>
          </div>
          <br />
          <div class="flex items-center">
            <img v-if="toggleClose" :src="toggleClose" class="icon-images">
            <input type="hidden" name="umh_channel[config][toggleButton_close]" :value="toggleClose">
            <file-upload name="umh_channel[file][close_toggle]" :url="toggleClose" @reset="toggleClose = \'\'"/>
          </div>
        </div>
      </div>
    </div>
  '
