
  import { Overlay } from 'components/generic'
  import { ExclamationIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      statusObject:
        type: Object
        default: {}
      position:
        type: String
        default: 'center'

    components:
      ExclamationIcon: ExclamationIcon
      Overlay: Overlay
      RefreshIcon: RefreshIcon
