Vue.component 'plus-button',
  props:
    label: String
    method: Function
    iconClass:
      default: 'fa fa-plus-circle'
      type: String
    colorClass:
      default: 'text-primary'
      type: String
  template: '
    <button class="btn btn-plus no-padding flex-container center-items" @click.prevent="method" tabindex="0">
      <div class="text-bigger" :class="colorClass">
        <span :class="iconClass"></span>
      </div>
      &nbsp;
      {{ label }}
    </button>
  '
