
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappTemplate from 'models/whatsapp_template.coffee'
  import TemplateButton from 'components/whatsapp_templates/template_button.vue'
  import { Button, H3, Input, Label, Listbox, Textarea } from 'components/generic'
  import { ChevronRightIcon, DocumentIcon, FilmIcon, PhotographIcon } from '@heroicons/vue/outline'

  export default
    props:
      template: WhatsappTemplate

    data: ->
      languages = Object.keys(@$root.languages).map (code) => {id: code, name: @$root.languages[code]}
      media = WhatsappTemplate.MEDIA.map (medium) -> {id: medium, name: medium.toLowerCase().capitalize()}
      buttonTypes = WhatsappTemplate.BUTTON_TYPES.map (buttonType) -> {id: buttonType, name: buttonType.replace(/_/g, ' ').toLowerCase().capitalize()}

      categories: WhatsappTemplate.CATEGORIES
      languages: languages
      media: media
      buttonTypes: buttonTypes
      category: null
      language: languages.find((lang) => lang.id == @template.language) || {name: 'select'}
      medium: if @template.header?.format then media.find((medium) => medium.id == @template.header.format) else media[0]
      buttonType: buttonTypes[0]

    computed:
      nameErrorMessage: ->
        return '&nbsp;' if !@template.name? || @template.name.match(/^[a-z_]*$/)
        'Only lowercase letters and underscores'
      headerVars: ->
        return [] if !@template.header?.text?
        @parse(@template.header.text)
      availableButtonTypes: ->
        switch @template.buttonType
          when 'QR'
            @buttonTypes.map (buttonType) ->
              if buttonType.id == 'QUICK_REPLY' then buttonType else {name: buttonType.name}
          when 'CTA'
            if @template.buttons.buttons.some (button) -> button.type == 'PHONE_NUMBER'
              @buttonTypes.map (buttonType) ->
                if buttonType.id == 'URL' then buttonType else {name: buttonType.name}
            else if @template.buttons.buttons.some (button) -> button.type == 'URL'
              @buttonTypes.map (buttonType) ->
                if buttonType.id == 'PHONE_NUMBER' then buttonType else {name: buttonType.name}
          else
            @buttonTypes
      mayAddButton: ->
        return false if @template.buttonType == 'QR' && @template.buttons.buttons.length == 3
        return false if @template.buttonType == 'CTA' && @template.buttons.buttons.length == 2
        true

    watch:
      category: ->
        @template.category = @category.id
      language: ->
        @template.language = @language.id
      medium: ->
        return unless @template.header?
        @template.header.format = @medium.id
      availableButtonTypes: ->
        @buttonType = @availableButtonTypes.find (buttonType) -> buttonType.id?
      'template.valid': ->
        @$parent.steps[0].completed = @template.valid

    created: ->
      # set category here, so the watcher is trigger and may reset the category if it is not in the category list
      @category = WhatsappTemplate.CATEGORIES.find((cat) => cat.id == @template.category) || {name: 'select'}

    methods:
      toggleHeader: (e) ->
        if @template.header then @template.removeHeader() else @template.addHeader()
        e.target.blur()
      toggleFooter: (e) ->
        if @template.footer then @template.removeFooter() else @template.addFooter()
        e.target.blur()
      toggleButtons: (e) ->
        if @template.buttons then @template.removeButtons() else @template.addButtons()
        e.target.blur()
      addHeaderVariable: ->
        return unless @template.header?.format == 'TEXT'
        return if @template.header.text.length > 55
        @template.header.text += '{{1}}'
        @$refs.headerInput.focus()
      addBodyVariable: ->
        vars = @parse(@template.body.text).map (num) -> parseInt(num)
        lastVar = Math.max(...vars.concat([0]))
        @template.body.text += "{{#{lastVar + 1}}}"
        @$refs.bodyTextarea.focus()
      addButton: (e) ->
        return unless @mayAddButton
        @template.buttons.buttons.push(type: @buttonType.id)
        e.target.blur()
      removeButton: (index) ->
        @template.buttons.buttons.splice(index, 1)
      nextStep: ->
        @$emit('next-step')
      parse: (originalString) ->
        string = originalString.slice()
        res = []
        while match = string.match(/\{\{(\d+)\}\}/)
          res.push(match[1])
          string = string.slice(match.index + 5)
        res

    components:
      Btn: Button
      ChevronRightIcon: ChevronRightIcon
      DocumentIcon: DocumentIcon
      FilmIcon: FilmIcon
      H3: H3
      Input: Input
      Label: Label
      Listbox: Listbox
      PhotographIcon: PhotographIcon
      TemplateButton: TemplateButton
      Textarea: Textarea
