Vue.component 'variation-field',
  props: ['list']
  data: ->
    items: []
  watch:
    items:
      handler: ->
        @list.length = 0
        Vue.set(@list, index, item.s) for item, index in @items
      deep: true
  created: ->
    @setItems()
  methods:
    newItem: (string = '') ->
      s: string
    setItems: ->
      # need to wrap items from dictionary in objects in order to have v-for and v-model combined
      @items = if @list.length
        for item in @list
          s: item
      else
        [@newItem()]
    add: ->
      @items.push(@newItem())
      Vue.nextTick =>
        $(@$el).find('.variation:last input').first().focus()
    insertItems: (index, items) ->
      return if items.length == 0
      newItems = items.map (item) => @newItem(item)
      for newItem, i in newItems
        @items.splice(index + 1, 0, newItem)
      Vue.nextTick =>
        $(@$el).find(".variation:nth-child(#{index + items.length}) input").first().focus()
    remove: (index) ->
      @items.splice(index, 1)
    paste: (index, event) ->
      clipboardData = event.clipboardData || window.clipboardData
      items = clipboardData.getData('Text').split('\n')
      # insert first item
      firstItem = items.shift()
      firstRemainder = @items[index].s.slice(0, event.target.selectionStart)
      lastRemainder = @items[index].s[event.target.selectionEnd..]
      @items[index].s = firstRemainder + firstItem + lastRemainder
      # insert remaining items
      @insertItems(index + 1, items)
  template: '
    <div>
      <div v-for="item, index in items" class="flex-container center-items sub-form-group variation">
        <input
          v-model="item.s"
          type="text"
          class="form-control"
          @keyup.enter="add"
          @paste.prevent="paste(index, $event)"
          >
        <div v-if="items.length > 1" @click="remove(index)" class="btn btn-remove">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>
      <plus-button label="add" :method="add"/>
    </div>
  '
