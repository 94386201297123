import rangy from 'patches/rangy'
import Message from 'models/inbox_message'

Vue.component 'inbox2-annotation-interface',
  props: ['conversation']

  data: ->
    annotatableMessages: []
    currentApplier: null
    marked: false
    utteranceText: null
    entitySpans: null
    selectedMessage: null
    rangySelections: []
    annotation: null
    annotatable: null
    newIntent: null
    intentLabel: ''
    botMessage: ''
    gameFilterString: ''
    intentFilterString: ''
    sending: false
    showAnnotatedMessage: false
    showAnnotationFailed: false
    showAnnotatableNotFound: false
    feedbackDuration: 3000
    modes: [
      'sending'
      'annotatable-search'
      'utterance-marking'
      'utterance-confirmation'
      'game-selection'
      'intent-selection'
      'bot-message'
      'entity-marking'
      'entity-confirmation'
      'slot-selection'
    ]

  computed:
    mode: ->
      return 'sending' if @sending
      if @annotation?.game && @annotation?.intent
        if @entitySpans?
          return 'slot-selection'
        else if @marked
          return 'entity-confirmation'
        else
          return 'entity-marking'
      else if @annotation?.game && !@annotation?.intent
        if @newIntent?
          return 'bot-message'
        else
          return 'intent-selection'
      else if @utteranceText?
        return 'game-selection'
      else if @marked
        return 'utterance-confirmation'
      else if @annotatable && !@annotatable.annotated
        return 'utterance-marking'
      else
        return 'annotatable-search'
    conversationHasAnnotatables: ->
      @conversation.annotationsSuggested
    currentBot: ->
      @$root.stageBots[@conversation.stageId]
    currentGames: ->
      @$root.games[@conversation.stageId]
    currentGamesArray: ->
      Object.keys(@currentGames).sort()
    currentIntents: ->
      return [] if !@annotation?.game?
      @currentGames[@annotation.game].sort((a, b) ->
        return -1 if a.intentLabel < b.intentLabel
        return 1 if a.intentLabel > b.intentLabel
        0
      )
    currentIntent: ->
      return if !@annotation?.game? || !@annotation?.intent?
      @currentIntents.find (intent) => intent.intentLabel == @annotation.intent
    currentSlots: ->
      Object.keys(@currentIntent.slotTypes).sort()
    mayAddIntent: ->
      return false unless @currentBot?
      return false unless @annotation?
      return false unless @currentBot.games[@annotation.game]?.type == 'Generic'
      true
    showNavButtons: ->
      @modeIs(
        'slot-selection'
        'entity-confirmation'
        'entity-marking'
        'bot-message'
        'intent-selection'
        'game-selection'
        'utterance-confirmation'
      )
    messageList: -> @$parent.$refs.messageList
    conversationList: -> @$parent.$parent

  watch:
    mode: ->
      if @mode != 'game-selection'
        @gameFilterString = ''
      if @mode != 'intent-selection'
        @intentFilterString = ''
    showAnnotatedMessage: ->
      return unless @showAnnotatedMessage
      setTimeout((=> @showAnnotatedMessage = false), @feedbackDuration)
    showAnnotationFailed: ->
      return unless @showAnnotationFailed
      setTimeout((=> @showAnnotationFailed = false), @feedbackDuration)
    showAnnotatableNotFound: ->
      return unless @showAnnotatableNotFound
      setTimeout((=> @showAnnotatableNotFound = false), @feedbackDuration)

  created: ->
    @$root.api.loadAnnotatableMessages(@conversation)
      .then (data) =>
        @conversation.annotatableMessages = data.reverse().map((data) -> new Message(data))
      .catch =>
        @conversation.annotatableMessages = []
    # init handler for enter key
    $(document).on 'keyup', (e) =>
      return if e.keyCode != 13
      return if !@marked
      @confirmSelection()

  unmounted: ->
    @resetAnnotation()

  methods:
    modeIs: (modes...) ->
      modes.some (mode) => mode == @mode

    stepState: (modes...) ->
      return 'active' if @modeIs(modes...)
      return 'done' if modes.some((mode) => @modes.indexOf(mode) < @modes.indexOf(@mode))
      'coming'

    findAnnotatable: (direction) ->
      @messageList.goToAnnotatable(direction)
        .then (annotatable) =>
          @annotatable = annotatable
        .catch =>
          @conversationList.findAnnotatable(direction)

    ignoreAnnotatable: ->
      @$root.track('Inbox message annotation ignored', conversationId: @conversation.id, messageId: @annotatable.id)
      @sending = true
      @$root.api.ignoreAnnotatable(@conversation, @annotatable.id)
        .then =>
          delete @annotatable.annotatable
          @annotatable.annotated = true
        .finally =>
          @sending = false

    textSelected: (message) ->
      return unless @modeIs('annotatable-search', 'utterance-marking', 'utterance-confirmation', 'entity-marking', 'entity-confirmation')
      selectionInBody = $(rangy.getSelection().anchorNode.parentNode).hasClass('text')
      selectionInIntent = $(rangy.getSelection().anchorNode.parentNode).hasClass('selection-intent')
      return if !(selectionInBody || selectionInIntent)
      if @modeIs('entity-marking', 'entity-confirmation')
        @marked = false
        return if !rangy.getSelection().anchorNode.parentNode.classList.contains('selection-intent')
        return if !rangy.getSelection().focusNode.parentNode.classList.contains('selection-intent')
      rangy.getSelection().expand('word')
      @marked = true
      @selectedMessage = message

    confirmSelection: ->
      if @modeIs('entity-marking', 'entity-confirmation')
        @entitySpans = rangy.getSelection().getAllRanges().map (range) ->
          textContainer = $(range.commonAncestorContainer).closest('.text')[0]
          start: range.startOffsetRelativeTo(textContainer)
          end: range.endOffsetRelativeTo(textContainer)
        rangy.createClassApplier('selection-entity').applyToSelection()
      else
        # workaround for Webkit browsers (they select more than the message text when the full line is marked):
        maxText = rangy.getSelection().anchorNode.parentNode.textContent
        selectedText = rangy.getSelection().toString()
        while maxText.indexOf(selectedText) == -1
          selectedText = selectedText[0..-2]
        # continue
        @utteranceText = selectedText
        rangy.createClassApplier('selection-intent').applyToSelection()
      @rangySelections.push(rangy.saveSelection())
      rangy.getSelection().removeAllRanges()
      @marked = false

    selectGame: (game) ->
      @annotation =
        game: game
        utterance: @utteranceText
        message: @selectedMessage
        language: @selectedMessage.interpretation?.language || @currentBot.default_language

    selectIntent: (intent) ->
      return unless @annotation?
      Vue.set(@annotation, 'intent', intent)
      if Object.keys(@currentIntent.slotTypes).length == 0
        @sendAnnotation()

    selectSlot: (slot) ->
      Vue.setDefault(@annotation, 'entities', [])
      # the browser allows to select multiple ranges (using the ctrl key),
      # but we will use only the first, as the annotation API doesn't accept more:
      @annotation.entities.push(Object.assign({slot: slot, type: @currentIntent.slotTypes[slot]}, @entitySpans[0]))
      @entitySpans = null

    sendAnnotation: ->
      @$root.track('Inbox message annotated', conversationId: @conversation.id, messageId: @annotation.message.id)
      @sending = true
      @$root.api.sendAnnotation(@conversation, @annotation)
        .then =>
          @lastAnnotation = ObjectProcessor.cloneWithout(@annotation, ['message'])
          @lastAnnotation.message = @annotation.message
          delete @annotation.message.annotatable
          @annotation.message.annotated = true
          @showAnnotatedMessage = true
        .catch =>
          @showAnnotationFailed = true
        .finally =>
          @resetAnnotation()
          @sending = false

    undoLastAnnotation: ->
      @$root.track('Inbox annotation undone', conversationId: @conversation.id, messageId: @lastAnnotation.message.id)
      @$root.api.deleteAnnotation(@conversation, @lastAnnotation)
      Vue.delete(@lastAnnotation.message, 'annotated')
      @showAnnotatedMessage = false

    addIntent: ->
      return unless @modeIs('intent-selection') && @currentBot
      @newIntent =
        language: @selectedMessage.interpretation?.language
        botId: @currentBot.id
        game: @annotation.game
        utterance: @annotation.utterance
        label: @intentLabel
      @resetNewIntentInputs()
      Vue.nextTick =>
        $(@$el).find('.bot-message input').focus()

    addBotMessage: ->
      Vue.set(@newIntent, 'botMessage', @botMessage)
      @resetNewIntentInputs()
      Vue.set(@annotation, 'intent', @newIntent.label)
      @$root.track 'Inbox intent created',
        conversationId: @conversation.id, messageId: @annotation.message.id, intent: @newIntent
      @sending = true
      @$root.api.sendIntent(@newIntent.botId, @currentBot.games[@newIntent.game].id, intent: JSON.stringify(@newIntent))
        .then =>
          @currentIntents.push(
            gameLabel: @newIntent.game
            intentLabel: @newIntent.label
            slotTypes: {}
          )
          @lastAnnotation = ObjectProcessor.cloneWithout(@annotation, ['message'])
          @lastAnnotation.message = @annotation.message
          Vue.delete(@annotation.message, 'annotatable')
          Vue.set(@annotation.message, 'annotated')
          @showAnnotatedMessage = true
        .catch =>
          @showAnnotationFailed = true
        .finally =>
          @resetAnnotation()
          @sending = false

    back: ->
      switch @mode
        when 'slot-selection'
          @entitySpans = null
          rangySelection = @rangySelections.pop()
          rangy.restoreSelection(rangySelection)
          rangy.createClassApplier('selection-entity').undoToSelection()
          rangy.getSelection().removeAllRanges()
        when 'entity-confirmation'
          @marked = false
          rangy.getSelection().removeAllRanges()
        when 'entity-marking'
          Vue.delete(@annotation, 'intent')
          # remove all selected entities
          for rangySelection in @rangySelections[1..-1].reverse()
            rangy.restoreSelection(rangySelection)
            rangy.createClassApplier('selection-entity').undoToSelection()
            @rangySelections.pop()
          rangy.getSelection().removeAllRanges()
        when 'bot-message'
          @newIntent = null
          @resetNewIntentInputs()
        when 'intent-selection'
          Vue.delete(@annotation, 'game')
        when 'game-selection', 'utterance-confirmation'
          @resetAnnotation()

    goToStep: (mode) ->
      @back() until @mode == mode

    resetNewIntentInputs: ->
      @intentLabel = ''
      @botMessage = ''

    resetAnnotation: ->
      @marked = false
      @annotation = null
      @newIntent = null
      @utteranceText = null
      @entitySpans = null
      @selectedMessage = null
      for rangySelection in @rangySelections
        rangy.restoreSelection(rangySelection)
        rangy.createClassApplier('selection-intent').undoToSelection()
        rangy.createClassApplier('selection-entity').undoToSelection()
      rangy.getSelection().removeAllRanges()
      @rangySelections = []
      @newIntent = null
      @resetNewIntentInputs()

  template: '
    <div class="annotation-interface">

      <div class="step" :class="stepState(\'annotatable-search\')">
        <div class="step-row">
          <span class="step-number">1</span>
          <span v-show="conversationHasAnnotatables">
            Find the next message to be annotated by clicking
            <a @click.prevent="findAnnotatable(\'down\')" href="">next annotation</a>
            or
            <a @click.prevent="findAnnotatable(\'up\')" href="">previous annotation</a>
          </span>
          <span v-show="!conversationHasAnnotatables">
            There are no messages that need annotation left. You may still annotate (just mark an utterance in order to start).
          </span>
        </div>
      </div>

      <div class="step" :class="stepState(\'utterance-marking\', \'utterance-confirmation\')">
        <div class="step-row">
          <span class="step-number">2</span>
          <span>Mark an utterance and hit enter</span>
          <button @click="goToStep(\'utterance-marking\')" class="btn btn-icon edit-button"><span class="fa fa-pen"></span></button>
        </div>
      </div>

      <div class="step" :class="stepState(\'game-selection\')">
        <div class="step-row">
          <span class="step-number">3</span>
          <span>Select the game the utterance belongs to</span>
          <div v-if="annotation && annotation.game" class="btn btn-default game-button btn-pseudo">
            {{ annotation.game }}
          </div>
          <div v-if="stepState(\'game-selection\') == \'active\'" class="filter-input">
            <input v-model="gameFilterString" type="text">
            <div class="icon-magnifier"></div>
          </div>
          <button @click="goToStep(\'game-selection\')" class="btn btn-icon edit-button"><span class="fa fa-pen"></span></button>
        </div>
        <div v-if="modeIs(\'game-selection\')" class="step-panel">
          <div class="flex-container wrap button-list">
            <template v-for="game in currentGamesArray">
              <button
                v-if="game.toLowerCase().indexOf(gameFilterString.toLowerCase()) == 0"
                @click="selectGame(game)"
                class="btn btn-default game-button"
                >
                {{ game }}
              </button>
            </template>
          </div>
        </div>
      </div>

      <div class="step" :class="stepState(\'intent-selection\', \'bot-message\')">
        <div class="step-row">
          <span class="step-number">4</span>
          <span>Select the intent that is expressed</span>
          <span v-if="mayAddIntent"> or add a new intent</span>
          <div v-if="stepState(\'intent-selection\') == \'active\'" class="filter-input">
            <input v-model="intentFilterString" type="text">
            <div class="icon-magnifier"></div>
          </div>
          <button @click="goToStep(\'intent-selection\')" class="btn btn-icon edit-button"><span class="fa fa-pen"></span></button>
        </div>
        <div v-if="modeIs(\'intent-selection\')" class="step-panel">
          <div class="flex-container wrap button-list">
            <template v-for="intent in currentIntents">
              <div
                v-if="intent.intentLabel.toLowerCase().indexOf(intentFilterString.toLowerCase()) == 0"
                @click="selectIntent(intent.intentLabel)"
                :title="intent.canonical ? intent.canonical[annotation.language || \'en\'] || intent.canonical.de : \'\'"
                class="btn btn-default intent-button"
                >
                {{ intent.intentLabel }}
              </div>
            </template>
          </div>
          <div class="intent-label" v-if="mayAddIntent">
            <input type="text" v-model="intentLabel" @keyup.enter="addIntent" placeholder="Type intent label ...">
            <button @click.prevent="addIntent" class="btn btn-default">Add intent</button>
          </div>
        </div>
        <div v-if="modeIs(\'bot-message\')" class="step-panel">
          <div class="bot-message" v-if="mayAddIntent">
            <input type="text" v-model="botMessage" @keyup.enter="addBotMessage" placeholder="Type bot message ...">
            <button @click.prevent="addBotMessage" class="btn btn-default">Save intent</button>
          </div>
        </div>
      </div>

      <template v-if="currentIntent && currentSlots.length">
        <div class="step" :class="stepState(\'entity-marking\', \'entity-confirmation\')">
          <div class="step-row">
            <span class="step-number">5</span>
            <span>{{ annotation.game }}/{{ annotation.intent }}() – mark an entity associated with the intent and hit enter</span>
            <button @click="goToStep(\'entity-marking\')" class="btn btn-icon edit-button"><span class="fa fa-pen"></span></button>
          </div>
          <div v-if="modeIs(\'entity-marking\')" class="step-panel">
            <div @click="sendAnnotation" class="btn btn-default">
              there are no (more) entites in this intent
            </div>
          </div>
        </div>
        <div class="step" :class="stepState(\'slot-selection\')">
          <div class="step-row">
            <span class="step-number">6</span>
            <span>{{ annotation.game }}/{{ annotation.intent }}() – select the slot</span>
          </div>
          <div v-if="modeIs(\'slot-selection\')" class="step-panel">
            <div class="flex-container wrap button-list">
              <div v-for="slot in currentSlots" @click="selectSlot(slot)" class="btn btn-default intent-button">
                {{ slot }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <fade-out :toggle="showAnnotatedMessage" class="flex-container center-content">
        <div class="feedback-panel success">
          <span class="far fa-check-circle"></span>
          Annotation successful
          <button @click="undoLastAnnotation" class="btn btn-default">
            Undo
            &ensp;
            <span class="fa fa-times"></span>
          </button>
          <div class="progress-bar" :style="{\'animation-duration\': feedbackDuration + \'ms\'}"></div>
        </div>
      </fade-out>

      <fade-out :toggle="showAnnotatableNotFound" class="flex-container center-content">
        <div class="feedback-panel danger">
          Can’t find another message that needs annotation.
          <div class="progress-bar" :style="{\'animation-duration\': feedbackDuration + \'ms\'}"></div>
        </div>
      </fade-out>

      <fade-out :toggle="showAnnotationFailed" class="flex-container center-content">
        <div class="feedback-panel danger">
          Annotation failed
          <div class="progress-bar" :style="{\'animation-duration\': feedbackDuration + \'ms\'}"></div>
        </div>
      </fade-out>

      <div v-if="showNavButtons" class="button-container flex-container start-content">
        <div @click="back" class="btn btn-default">← Back</div>
        &ensp;
        <div @click="resetAnnotation" class="btn btn-default">Cancel ×</div>
      </div>

      <div v-if="modeIs(\'utterance-marking\')" class="button-container flex-container start-content">
        <div @click="ignoreAnnotatable" class="btn btn-default">Ignore !</div>
      </div>
    </div>
  '
