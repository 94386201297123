
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Environment from 'models/environment.coffee'
  import PlatformApi2 from 'models/platform_api2.coffee'

  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid'

  export default
    props:
      devEnvironment: Environment

    data: ->
      bots: []
      selectedBot: null
      unwatch: null

    computed:
      maySelectActiveBot: ->
        Globals.currentUser.may('edit_project_settings_group2', Globals.project)

    created: ->
      @bots = await BotbuilderApi.getBots()
      if @devEnvironment?
        @setSelectedBot()
      else
        @unwatch = @$watch('devEnvironment', =>
          @setSelectedBot()
          @unwatch()
        )

    methods:
      setSelectedBot: ->
        @selectedBot = @bots.find (bot) => bot.id == @devEnvironment.botId
      setActiveBot: ->
        PlatformApi2.setActiveBot(@selectedBot.id)
          .catch => @setSelectedBot()

    components:
      CheckIcon: CheckIcon
      ChevronDownIcon: ChevronDownIcon
      Listbox: Listbox
      ListboxButton: ListboxButton
      ListboxLabel: ListboxLabel
      ListboxOption: ListboxOption
      ListboxOptions: ListboxOptions
