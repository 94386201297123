import { createApp } from 'vue_shims'

initUnitTestingApp = (element) ->
  createApp(
    data: ->
      stages: $(element).data('stages')
      tests: $(element).data('tests')
      currentId: null
      currentName: ''
      currentDefinition: ''
      currentStage: null
      currentDefinitionDirty: false
      sending: false
      status: null
      report: null
      lastRunAt: null
      lastRunAtDisplay: null
      errorMessage: null
      nameError: null
      stageError: null
      dirty: false
      testRowRefs: []

    computed:
      activeStages: ->
        return [] unless @stages
        @stages.filter (stage) -> stage.instanceUuid
      sortedTests: ->
        @tests.slice().sort (a, b) ->
          return -1 if a.name.toLowerCase() < b.name.toLowerCase()
          return 1 if a.name.toLowerCase() > b.name.toLowerCase()
          0

    watch:
      currentName: ->
        @nameError = null
        @dirty = true
      currentStage: ->
        @stageError = null
        @dirty = true
      currentDefinition: ->
        @currentDefinitionDirty = true
        @dirty = true
      lastRunAt: ->
        @lastRunAtDisplay = null if !@lastRunAt?
        @dirty = true

    beforeUpdate: ->
      @testRowRefs = []

    methods:
      clean: ->
        @nameError = null
        @stageError = null
        @dirty = false
      clear: ->
        @currentId = null
        @currentName = ''
        @currentDefinition = ''
        @currentStage = null
        @status = null
        @report = null
        @lastRunAt = null
        @currentDefinitionDirty = false
        @errorMessage = null
        Vue.nextTick => @clean()
      edit: (id) ->
        test = @tests.find (test) -> test.id == id
        @currentId = test.id
        @currentName = test.name
        @currentDefinition = test.definition
        @currentStage = @stages.find (stage) -> stage.uuid == test.stage.uuid
        @status = test.status
        @report = test.report
        @lastRunAt = test.last_run_at
        @lastRunAtDisplay = test.last_run_at_display
        @currentDefinitionDirty = false
        @errorMessage = null
        Vue.nextTick => @clean()
      save: (id) ->
        unitTestData =
          name: @currentName
          definition: @currentDefinition
          stage_uuid: @currentStage?.uuid
          last_run_at: if !@currentDefinitionDirty then @lastRunAt else null
          status: if !@currentDefinitionDirty then @status else null
          report: if !@currentDefinitionDirty then @report else null
        if id?
          $.post(
            Routes.project_unit_test_path(Globals.projectId, Globals.stageLevel, id),
            _method: 'patch'
            unit_test: unitTestData
          ).done((data) =>
            index = @tests.findIndex (test) -> test.id == id
            Vue.set(@tests, index, data)
            @clean()
          ).fail(@saveFailed)
        else
          $.post(
            Routes.project_unit_tests_path(Globals.projectId, Globals.stageLevel),
            unit_test: unitTestData
          ).done((data) =>
            @tests.push(data)
            @currentId = data.id
            @clean()
          ).fail(@saveFailed)
        return
      saveFailed: (xhr) ->
        if xhr.responseJSON?.name?
          @nameError = 'Name ' + xhr.responseJSON.name.join(', ')
        if xhr.responseJSON?.stage_uuid?
          @stageError = 'Stage ' + xhr.responseJSON.stage_uuid.join(', ')
      deleteTest: (id) ->
        index = @tests.findIndex (test) -> test.id == id
        @tests.splice(index, 1)
      run: ->
        return unless @currentDefinition
        return unless @currentStage
        @status = null
        @report = null
        @errorMessage = null
        @sending = true
        $.postJSON(
          Routes.run_test_project_unit_tests_path(Globals.projectId, Globals.stageLevel),
          definition: @currentDefinition, instance_uuid: @currentStage.instanceUuid
        ).done((response) =>
          if response.success
            @status = response.status
            @report = response.report
          else
            @errorMessage = response.message
          @lastRunAt = response.run_at
          @lastRunAtDisplay = response.run_at_display
        ).fail(=>
          @errorMessage = 'A server error occurred.'
          @lastRunAt = null
        ).always(=>
          @sending = false
          @currentDefinitionDirty = false
        )
      runFromList: (mode) ->
        switch mode
          when 'all'
            for test in @testRowRefs
              test.run()
          when 'selected'
            for test in @testRowRefs
              continue unless test.checked
              test.run()
      setTestRow: (el) ->
        @testRowRefs.push(el) if el?

    template: '
      <div class="responsive-grid-container no-vertical-spacing">
        <div class="responsive-grid-item item-400">
          <div class="panel panel-default">
            <div class="panel-body">
              <h3 class="no-margin-top">Test definition</h3>
              <div :class="{\'has-error\': nameError}" class="form-group">
                <input type="text" v-model="currentName" placeholder="Test name" class="form-control">
                <span class="help-block" v-if="nameError">{{ nameError }}</span>
              </div>
              <div class="form-group">
                <textarea v-model="currentDefinition" rows="6" style="resize: vertical" class="form-control"></textarea>
              </div>
              <div :class="{\'has-error\': stageError}" class="form-group">
                <button :disabled="sending ? true : null" @click="run" class="btn btn-primary">run</button>
                <select class="styled-form-field" v-model="currentStage">
                  <option v-for="stage in activeStages" :value="stage" v-text="stage.label"></option>
                </select>
                &nbsp;&nbsp;&nbsp;
                <div @click="save(currentId)" :disabled="dirty ? null : true" class="btn btn-default">save</div>
                <div @click="save()" :disabled="dirty ? null : true" class="btn btn-default">save as</div>
                &nbsp;&nbsp;&nbsp;
                <div @click="clear" class="btn btn-default">clear</div>
                <span class="help-block" v-if="stageError">{{ stageError }}</span>
              </div>
            </div>

            <div class="panel-body" v-cloak>
              <h3 class="no-margin-top">Test results</h3>
              <p v-show="lastRunAtDisplay">Run at: {{ lastRunAtDisplay }}</p>
              <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
              <pre v-if="report">{{ report }}</pre>
              <div v-if="sending" class="loading"></div>
            </div>
          </div>
        </div>

        <div class="responsive-grid-item item-500">
          <div class="panel panel-default">
            <div class="panel-body">
              <h3 class="no-margin-top">Saved tests</h3>
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Stage</th>
                    <th>Last run</th>
                    <th>Result</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <unit-test
                    v-for="test in sortedTests"
                    :test="test"
                    @edit-test="edit"
                    @delete-test="deleteTest"
                    :ref="setTestRow"
                    />
                </tbody>
              </table>
              <div class="form-group">
                <div @click="runFromList(\'all\')" class="btn btn-default">run all</div>
                <div @click="runFromList(\'selected\')" class="btn btn-default">run selected</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('unit-testing-app')
  initUnitTestingApp(element) if element?
