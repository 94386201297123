
  import ContextInitiative from 'models/context_initiative.coffee'
  import ContextUpdate from 'models/context_update.coffee'
  import Hook from 'models/hook.coffee'
  import OperationalHook from 'models/operational_hook.coffee'
  import Operation from 'models/operation.coffee'

  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { CollapsibleCard, Combobox, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: OperationalHook
      contextInitiative: ContextInitiative

    emits: ['update:modelValue', 'remove']

    data: ->
      operationalHook: @modelValue
      hook: @$root.currentBot.config.hooks.find (hook) => hook.key == @modelValue.hookKey
      operatorsForSelect: ContextUpdate.OPERATOR_TYPES.map (operator) -> key: operator

    computed:
      componentOrContextParameter: ->
        @$root.componentOrContextParameter(@operationalHook.operations[0].parameterKey) || {}
      componentsForSelect: ->
        if @operationalHook.contextInitiative.components
          @operationalHook.contextInitiative.components.map (component) ->
            Object.assign(component.clone(), key: component.fullKey)
        else
          [@operationalHook.contextInitiative.contextInterface.contextParameter]

    watch:
      modelValue: ->
        @operationalHook = @modelValue

    created: ->
      @operationalHook.operation.parameterKey ||= @componentsForSelect[0].key
      @operationalHook.operation.operator ||= @operatorsForSelect[0]

    methods:
      update: ->
        @$emit('update:modelValue', @operationalHook)
      updateHook: ->
        @operationalHook.hookKey = @hook.key if @hook?.key?
        @update()
      focus: ->
        @$refs.card.open()
        @$nextTick =>
          @$refs.hookSelection.focus()
      hookLabel: (hook) ->
        hook?.label
      searchHooks: (query) ->
        @$root.currentBot.config.searchHooks(query)

    components:
      CollapsibleCard: CollapsibleCard
      Combobox: Combobox
      KeyListbox: KeyListbox
      UniversalInput: UniversalInput
      XIcon: XIcon
