import ObjectProcessor from 'models/object_processor.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import DialogNode from 'models/dialog_node.coffee'
import Target from 'models/dialog_target.coffee'
import UtteranceCollection from 'models/utterance_collection.coffee'
import Utterance from 'models/utterance.coffee'

export default class UserIntent extends DialogNode
  @NLP_ACCESS_MODES = [
    'global'
    'priming'
    'module'
    'none'
  ]

  constructor: (@dialogModule, data = {}) ->
    super()
    @type = 'UserIntent'
    @key = data.key
    @label = data.label || ''
    @description = data.description || ''
    @target = new Target(@, data.target)
    @utterances = new UtteranceCollection(data.utterances)
    @canonicals = data.canonicals || {}
    @nlpAccessMode = data.nlpAccessMode || 'global'

  update: (data = {}) ->
    @key = data.key if !@key && data.key?
    @label = data.label if data.label?
    @description = data.description  if data.description ?
    @target = new Target(@, data.target) if data.target?
    @utterances = new UtteranceCollection(data.utterances) if data.utterances?
    @canonicals = data.canonicals if data.canonicals?
    @nlpAccessMode = data.nlpAccessMode if data.nlpAccessMode?
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    this

  clone: ->
    new UserIntent(@dialogModule, @export)

  merge: (otherNode) ->
    BotbuilderApi.mergeNode(@, otherNode)
      .then (response) => @dialogModule.updateNodes(response.affectedResources)

  removeTarget: (node) ->
    return if @target.nodeKey != node.key
    @target.reset()

  Object.defineProperties @prototype,
    color:
      get: -> 'blue'
    pinned:
      get: ->
        @dialogModule?.nodeMeta[@key]?.pinned
      set: (value) ->
        return unless @dialogModule?
        @dialogModule.nodeMeta[@key] ||= {}
        @dialogModule.nodeMeta[@key].pinned = value
    export:
      get: ->
        type: @type
        key: @key
        label: @label
        description: @description
        target: @target.export
        utterances: @utterances.export
        canonicals: @canonicals
        nlpAccessMode: @nlpAccessMode
    slotDefinitions:
      get: ->
        Array.unique(
          @utterances.utterances.map((utterance) -> utterance.slots.map((slot) -> slot.definition)).flat(2)
        , (slotDefinition) -> slotDefinition.slotRole
        ).filter (sd) -> sd.slotRole

