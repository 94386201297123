
  export default
    props:
      environment: String

    data: ->
      formattedEnvironment: (@environment || '').toUpperCase()
      envColorClasses:
        DEV: 'border-MercuryBlue-400 text-MercuryBlue-400'
        TEST: 'border-MercuryYellow-400 text-MercuryYellow-400'
        LIVE: 'border-MercuryGreen-400 text-MercuryGreen-400'
