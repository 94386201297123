
  import { UploadIcon } from '@heroicons/vue/outline'

  export default
    props:
      small: Boolean

    emits: ['set-file']

    methods:
      setFile: (e) ->
        @$emit('set-file', e.target.files[0])

    components: 
      UploadIcon: UploadIcon
