
  import placeholderImage from 'images/placeholder.png'
  import { Button } from 'components/generic'

  export default
    props:
      template: Object

    computed:
      imageUrl: ->
        return @template.header.url if @template.header?.url?.length
        placeholderImage

    components:
      Btn: Button
