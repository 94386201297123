import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import BotbuilderApi from 'models/botbuilder_api.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import { trackRouting } from 'helpers'

import ChannelList from 'components/channels/channel_list.vue'
import ChannelEditor from 'components/channels/channel_editor.vue'

initChannelsApp = (element) ->
  routes = [
    name: 'index',
    path: '/',
    component: ChannelList,
  ,
    name: 'channel',
    path: '/:channelId',
    component: ChannelEditor,
  ].map (item) -> Object.assign(item, path: '/projects/:projectId/:stageLevel/channels2' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))
      languages: elementData.languages
      currentBot: null
      connectChannelOnVisit: false
      contextParameters: []

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        @$router.options.routes.find (route) => route.name == @$route.name

    watch:
      '$route.name': -> @setBreadcrumbs()
      '$route.params.channelId': -> @setBreadcrumbs()

    created: ->
      @loadBot()
      @loadContextParameters()

    methods:
      loadBot: ->
        Promise.all([
          BotbuilderApi.getBots()
          PlatformApi2.getActiveBotId()
        ]).then ([bots, activeBotId]) =>
          @currentBot = bots.find (bot) -> bot.id == activeBotId
          @currentBot.loadConfig()
      loadContextParameters: ->
        BotbuilderApi.getContextParameters().then (cps) => @contextParameters = cps
      setBreadcrumbs: ->
        if @currentRoute.breadcrumbParent?
          parentRoute = @$router.options.routes.find (route) => route.name == @currentRoute.breadcrumbParent
          window.breadcrumbs.strip().append(
            text: parentRoute.label
            callback: => @$router.push(name: parentRoute.name)
          )
        else
          window.breadcrumbs.strip()
          window.breadcrumbs.append(text: @currentRoute.label) if @currentRoute.label?
      contextParameterByKey: (parameterKeyWithComponent) ->
        contextParameterKey = parameterKeyWithComponent.split('#')[0]
        @contextParameters.find (cp) -> cp.key == contextParameterKey
      componentByKey: (parameterKeyWithComponent) ->
        contextParameter = @contextParameterByKey(parameterKeyWithComponent)
        return unless contextParameter?.components?
        componentKey = parameterKeyWithComponent.split('#')[1]
        contextParameter.components.find (cmp) -> cmp.key == componentKey

    template: '
      <router-view/>
    '
  )
    .use(router)
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('channels-app')
      initChannelsApp(element)
