export default class RolePermission
  constructor: (@role, data) ->
    @type = data.permission_type
    @label = data.label
    @enabled = data.enabled
    @_dev = data.dev
    @_test = data.test
    @_live = data.live

  Object.defineProperties @prototype,
    all:
      get: ->
        @enabled && !@dev? && !@test? && !@live?
      set: (value) ->
        if value
          @enabled = true
        else
          @enabled = false
        @dev = null
        @test = null
        @live = null
        @role.heedImplications(@, 'all', value)
    dev:
      get: -> @_dev
      set: (value) ->
        @_dev = value
        @enabled = true if value
        @role.heedImplications(@, 'dev', value)
    test:
      get: -> @_test
      set: (value) ->
        @_test = value
        @enabled = true if value
        @role.heedImplications(@, 'test', value)
    live:
      get: -> @_live
      set: (value) ->
        @_live = value
        @enabled = true if value
        @role.heedImplications(@, 'live', value)
    export:
      get: ->
        permission_type: @type
        label: @label
        enabled: @enabled
        dev: @dev
        test: @test
        live: @live
