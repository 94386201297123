import Rails from '@rails/ujs'

window.modal_holder_selector = '#modal-holder'
window.modal_selector = '.modal'
window.showModal = (html) ->
  $(modal_holder_selector).html(html)
    .find(modal_selector).modal()

$ ->

  $(document).on 'click', 'a[data-modal]', ->
    location = $(this).attr('href')
    #Load modal dialog from server
    $.get location, (data)-> showModal(data)
    false

  $(document).on 'submit', 'form[data-modal]', (event) ->
    event.stopPropagation()
    try
      Rails.handleRemote.call(event.target, 'submit')
    false

  $(document).on 'ajax:success', 'form[data-modal]', (event)->
    [ data, status, xhr ] = event.detail

    redirectURL = if data.body?.firstChild?.id == 'main-modal'
      null
    else
      xhr.responseURL

    if redirectURL
      # Redirect to url
      window.location = redirectURL
    else
      # Remove old modal backdrop
      $('.modal-backdrop').remove()
      # Replace old modal with new one
      $(modal_holder_selector).html(xhr.responseText)
        .find(modal_selector).modal()

    false
