Vue.component 'modal',
  props: ['title']
  methods:
    show: ->
      $(@$el).modal('show')
    close: ->
      $(@$el).modal('hide')
  template: '
    <div class="modal" id="main-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div v-on:click="close" class="close">
              <span>×</span>
              <span class="sr-only">Close</span>
            </div>
            <h3 class="modal-title" id="modal-label">
              {{ title }}
            </h3>
          </div>
          <div class="modal-body">
            <slot :close="close"/>
          </div>
        </div>
      </div>
    </div>
  '
