
  import TemplateDisplay from './template_display.vue'
  import TemplateParameters from './template_parameters.vue'
  import { Button, H3, Input } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      template: Object
      errorMessage: String

    emits: ['back', 'home']

    data: ->
      mounted: false

    computed:
      ready: ->
        return false if !@mounted
        @$refs.templateParameters.allParameters.every (param) -> param.defaultValue
      examplePayload: ->
        string =
          """
            {
              "to": "<recipient_whatsapp_id>",
              "type": "template",
              "template": {
                "name": "#{@template.name}",
                "namespace": "#{@template.namespace}",
                "language": {
                  "code": "#{@template.language}",
                  "policy": "deterministic"
                },
                "components": [\n
          """
        if @template.headerParameter?
          string +=
            """
            \ \ \ \ \ \ {
                    "type": "header",
                    "parameters": [
                      {
                        "type": "text",
                        "text": "<your_text_string>"
                      }
                    ]
                  },\n
            """
        if @template.setBodyParameters.length
          string +=
            """
            \ \ \ \ \ \ {
                    "type": "body",
                    "parameters": [\n
            """
          @template.setBodyParameters.forEach (parameter, index) =>
            string +=
              """
              \ \ \ \ \ \ \ \ \ \ {
                          "type": "text",
                          "text": "<your_text_string>"
                        }#{if index < @template.setBodyParameters.length - 1 then ',' else ''}\n
              """
          string +=
            """
            \ \ \ \ \ \ \ \ ]
                  }#{if @template.buttonParameter? then ',' else ''}\n
            """
        if @template.buttonParameter?
          string +=
            """
            \ \ \ \ \ \ {
                    "type": "buttons",
                    "parameters": [
                      {
                        "type": "text",
                        "text": "<your_text_string>"
                      }
                    ]
                  }\n
            """
        string +=
          """
          \ \ \ \ ]
            }
          }
          """
        string

    mounted: ->
      @mounted = true

    components:
      Button: Button
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      H3: H3
      Input: Input
      TemplateDisplay: TemplateDisplay
      TemplateParameters: TemplateParameters
