
  import Bubble from 'models/bubble'

  import AttachmentEditor from './attachment_editor.vue'
  import ButtonEditor from './button_editor.vue'
  import ButtonMover from './button_mover.vue'
  import { Button } from 'components/generic'

  export default
    props:
      modelValue: Bubble
      index: Number
      count: Number

    emits: ['remove', 'update:modelValue']

    data: ->
      bubble: @modelValue
      attachmentEditorRefs: []

    watch:
      modelValue: ->
        @bubble = @modelValue

    beforeUpdate: ->
      @attachmentEditorRefs = []

    methods:
      remove: ->
        return unless confirm("Do you really want to delete this slide?")
        @$emit('remove', @index, @bubble.id)
      addButton: ->
        @bubble.addButton()
        @update()
      removeAttachment: (attachment) ->
        @bubble.removeAttachment(attachment.id)
        @update()
      update: ->
        @$emit('update:modelValue', @bubble)
      focus: ->
        @attachmentEditorRefs[0].focus()
      setAttachmentEditorRef: (el) ->
        @attachmentEditorRefs.push(el) if el?

    components:
      AttachmentEditor: AttachmentEditor
      Button: Button
      ButtonEditor: ButtonEditor
      ButtonMover: ButtonMover
