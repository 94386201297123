
  import DesktopNotifier from 'models/desktop_notifier'

  import { Button, Modal } from 'components/generic'

  export default
    mounted: ->
      if DesktopNotifier.needToReqestPermission()
        @$refs.modal.open()

    methods:
      requestNotificationPermission: ->
        DesktopNotifier.requestPermission()
        @$refs.modal.close()

    components:
      Button: Button
      Modal: Modal
