
import Addon from 'models/addon.coffee'
import PlatformApi from 'models/platform_api.coffee'
import { Badge, Button, H3, Modal, Panel } from 'components/generic'

export default
  data: ->
    sending: false
    selectedAddon: null

  computed:
    subscription: ->
      @$root.organization.subscription || {}
    addons: ->
      (@subscription.addons || []).map (data) -> new Addon(data)

  methods:
    openAddonModal: (addon) ->
      @selectedAddon = addon
      if addon.cancelled
        @$refs.addonReactivationModal.open()
      else
        @$refs.addonCancellationModal.open()
    cancelAddon: ->
      @sending = true
      PlatformApi.Webapp.cancelAddon(@$root.organization.id, @selectedAddon.id)
        .then (data) =>
          @subscription.update(data)
          @$refs.addonCancellationModal.close()
        .finally =>
          @sending = false
    reactivateAddon: ->
      @sending = true
      PlatformApi.Webapp.reactivateAddon(@$root.organization.id, @selectedAddon.id)
        .then (data) =>
          @subscription.update(data)
          @$refs.addonReactivationModal.close()
        .finally =>
          @sending = false

  components:
    Badge: Badge
    Button: Button
    H3: H3
    Modal: Modal
    Panel: Panel
