import { createApp, ref } from 'vue'
import Project from 'models/project.coffee'

import { EnvironmentSelector } from 'components/generic'
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/solid'

initBreadcrumbsApp = (element) ->
  createApp(
    data: ->
      turbolinksPermanentSelector: 'breadcrumbs-app'
      breadcrumbs: []
      environmentSelector: false
      environmentSelectorAsLast: false
      environment: Globals.stageLevel
      environments: Project.ENVIRONMENTS

    computed:
      firstBreadcrumb: -> @breadcrumbs[0]
      railsBreadcrumbs: -> @breadcrumbs[1..-1].filter (bc) -> bc.rails
      nonRailsBreadcrumbs: -> @breadcrumbs[1..-1].filter (bc) -> !bc.rails
      chevronRightClass: -> 'ml-2 flex-shrink-0 h-5 w-5 text-gray-500'

    created: ->
      @set()
      $(window).on 'turbolinks:load', @set
      window.breadcrumbs =
        list: @breadcrumbs
        strip: =>
          @strip()
          window.breadcrumbs
        append: (item) =>
          @append(item)
          window.breadcrumbs
        enableEnvironmentSelector: ({last} = {last: false}) =>
          @enableEnvironmentSelector(last: last)
        disableEnvironmentSelector: =>
          @environmentSelector = false
          @environmentSelectorAsLast = false

    methods:
      set: ->
        @breadcrumbs = $('#breadcrumbs-data').data('breadcrumbs').map (item) -> Object.assign(item, rails: true)
      strip: ->
        @breadcrumbs = @breadcrumbs.filter (item) -> item.rails
      append: (item) ->
        @breadcrumbs.push(item)
      enableEnvironmentSelector: ({last} = {last: false}) ->
        if last
          @environmentSelectorAsLast = true
        else
          @environmentSelector = true
      isLast: (breadcrumb) ->
        @breadcrumbs.indexOf(breadcrumb) == @breadcrumbs.length - 1

    components:
      ChevronRightIcon: ChevronRightIcon
      EnvironmentSelector: EnvironmentSelector
      HomeIcon: HomeIcon

    template: '
      <template v-if="breadcrumbs && breadcrumbs.length">
        <nav class="flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-2 m-0">

            <li class="flex items-center">
              <a :href="firstBreadcrumb.url" class="text-gray-500 hover:text-gray-600">
                <HomeIcon class="flex-shrink-0 h-5 w-5"/>
                <span class="sr-only">Home</span>
              </a>
              <ChevronRightIcon :class="chevronRightClass"/>
            </li>

            <li v-for="breadcrumb in railsBreadcrumbs" class="flex items-center">
              <div v-if="isLast(breadcrumb)" class="text-sm font-medium text-gray-600">
                {{ breadcrumb.text }}
              </div>
              <a v-else-if="breadcrumb.url" :href="breadcrumb.url" class="text-sm font-medium text-gray-600 hover:text-gray-800 hover:no-underline">
                {{ breadcrumb.text }}
              </a>
              <a v-else-if="breadcrumb.callback" @click="breadcrumb.callback" class="text-sm font-medium text-gray-600 hover:text-gray-800 hover:no-underline cursor-pointer">
                {{ breadcrumb.text }}
              </a>
              <ChevronRightIcon v-if="!isLast(breadcrumb) || environmentSelector" :class="chevronRightClass"/>
            </li>

            <li v-if="environmentSelector" class="flex items-center" aria-current="page">
              <EnvironmentSelector class="relative"/>
              <ChevronRightIcon v-if="nonRailsBreadcrumbs.length" :class="chevronRightClass"/>
            </li>

            <li v-for="breadcrumb in nonRailsBreadcrumbs" class="flex items-center">
              <div v-if="isLast(breadcrumb)" class="text-sm font-medium text-gray-600">
                {{ breadcrumb.text }}
              </div>
              <a v-else-if="breadcrumb.callback" @click="breadcrumb.callback" class="text-sm font-medium text-gray-600 hover:text-gray-800 hover:no-underline cursor-pointer">
                {{ breadcrumb.text }}
              </a>
              <ChevronRightIcon v-if="!isLast(breadcrumb) || environmentSelectorAsLast" :class="chevronRightClass"/>
            </li>

            <li v-if="environmentSelectorAsLast" class="flex items-center" aria-current="page">
              <EnvironmentSelector class="relative"/>
            </li>

          </ol>
        </nav>
      </template>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('breadcrumbs-app')
      initBreadcrumbsApp(element)
