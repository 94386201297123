Vue.component 'support-option-done',
  props: ['option']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    entity: @option.done
    role: 'head'
    name: '“Wrap up”'
    attributes:
      name:
        type: 'String'
        default: ''
    nameCopy: ''
  computed:
    showRightColumn: ->
      return false if @pristine
      return true if @editing
      false
  mounted: ->
    if @option.done.name?
      @pristine = false
    else
      @edit()
    Vue.setDefault(@option, 'failWithOptions', {})
    @setCopy()
  methods:
    finished: ->
      return unless @complete
      @confirm()
      @editing = false
      Vue.delete(@option, 'fresh')
  template: '
    <div>
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-3" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container center-items">
                <div class="expand">
                  <h5 v-if="editing" class="no-margin-top">Wrap up</h5>
                  <label v-else class="text-light">Wrap up</label>
                  <input
                    v-if="editing"
                    type="text"
                    v-model="nameCopy"
                    @keyup.enter="confirm"
                    class="form-control wizard-style"
                    placeholder="e.g. send status, ask for help, ..."
                    >
                  <div v-if="!editing" class="panel-label">{{ option.done.name }}</div>
                </div>
                <div v-if="!editing" @click="edit" class="btn btn-icon">
                  <span class="fa fa-pen"></span>
                </div>
              </div>
              <support-confirm-button v-if="editing"/>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-option-done-message :option="option" @finished="finished" @discard="discard"/>
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>
    </div>
  '
