
  import BotCreator from 'components/botbuilder/bot_creator.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Button, PageHeader, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/outline'

  export default
    methods:
      projectSettingsUrl: (project) ->
        Routes.edit_project_path(project.id, Globals.stageLevel)

    data: ->
      PlusIcon: PlusIcon

    computed:
      mayAddProject: ->
        Globals.organizationRestrictions.projects > @$root.projects.length

    components:
      BotCreator: BotCreator
      Button: Button
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      UpgradePrompt: UpgradePrompt
