import { createApp } from 'vue_shims'
import GeneralConfiguration from 'components/channels/general.vue'
import InstagramConfiguration from 'components/channels/instagram.vue'
import StatusDisplay from 'components/channels/status_display.vue'

initChannelConfigurationApp = (element) ->
  createApp(
    data: ->
      channel: $(element).data('channel')
      languages: $(element).data('languages')
      stages: $(element).data('stages')
      facebookPages: $(element).data('facebook-pages')
      facebookLoginUrlForInstagram: $(element).data('facebook-login-url-for-instagram')

    computed:
      channelJson: ->
        JSON.stringify(@channel)

    components:
      GeneralConfiguration: GeneralConfiguration
      InstagramConfiguration: InstagramConfiguration
      StatusDisplay: StatusDisplay

    template: '
      <div>
        <GeneralConfiguration v-model="channel"/>
        <InstagramConfiguration v-if="channel.type == \'INSTAGRAM\'" v-model="channel"/>
        <StatusDisplay :channel="channel"/>

        <input type="hidden" name="channel" :value="channelJson">
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('channel-configuration-app')
      initChannelConfigurationApp(element)
