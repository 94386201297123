
import { Input, Listbox } from 'components/generic'
import { PlusCircleIcon, XCircleIcon } from '@heroicons/vue/outline'

export default
  props:
    condition: Object
    index: Number
    count: Number
    lastGameConditionPresent:
      type: Boolean
      default: false
    editing:
      type: Boolean
      default: false

  data: ->
    lastActiveGameLabel: '!--lastActiveGame--!'

  computed:
    availableContextParameters: ->
      if @lastGameConditionPresent && !@isLastGameCondition
        [label: '', humanizedLabel: 'choose parameter'].concat(@$root.contextParameters)
      else
        [label: @lastActiveGameLabel, humanizedLabel: 'last Game'].concat(@$root.contextParameters)
    selectedParameter: ->
      return @availableContextParameters[0] if !@condition.parameter
      parameter = @availableContextParameters.find (parameter) => parameter?.label == @condition.parameter
      parameter || {label: @condition.parameter, humanizedLabel: @condition.parameter}
    isLastGameCondition: ->
      @condition.parameter == @lastActiveGameLabel
    selectedGame: ->
      if !@condition.lastActiveGame
        @condition.lastActiveGame = @$root.moduleNames[0]?.key
        return @$root.moduleNames[0]
      game = @$root.moduleNames.find (module) => @condition.lastActiveGame == module.key
      game || {key: @condition.lastActiveGame, label: @condition.lastActiveGame}
    type: ->
      return undefined if @isLastGameCondition
      @selectedParameter?.type || 'Boolean'
    availableOperators: ->
      operatorMap = TypeValidator.availableOperators(@type)
      Object.keys(operatorMap).map (operator) ->
        {id: operator, name: "#{operatorMap[operator]} (#{operator})"}
    selectedOperator: ->
      operator = @availableOperators.find (operator) => @condition.operator == operator.id
      operator || {id: @condition.operator, name: @condition.operator}
    cellClasses: ->
      if @editing then 'font-medium text-gray-900' else 'font-base text-gray-500'

  watch:
    'condition.parameter': (newParameter, oldParameter) ->
      if newParameter == @lastActiveGameLabel
        @condition.lastActiveGame = ''
        delete @condition.operator
        delete @condition.value
      else if oldParameter == @lastActiveGameLabel
        @condition.operator = @availableOperators[0]?.id
        @condition.value = ''
        delete @condition.lastActiveGame
      else
        operator = @availableOperators.find (operator) => @condition.operator == operator.id
        @condition.operator = operator?.id || @availableOperators[0]?.id

  created: ->
    Vue.setDefault(@condition, '$key', GuidGenerator.newGuid())
    if @condition.lastActiveGame?
      @condition.parameter = @lastActiveGameLabel
    else
      Vue.setDefault(@condition, 'parameter', '')
      Vue.setDefault(@condition, 'operator', @availableOperators[0]?.id)
      Vue.setDefault(@condition, 'value', '')

  methods:
    setParameter: (parameter) ->
      @condition.parameter = parameter.label
    setGame: (game) ->
      @condition.lastActiveGame = game.key
    setOperator: (operator) ->
      @condition.operator = operator.id
    addCondition: ->
      @$emit('add-condition')
    removeCondition: ->
      @$emit('remove-condition', @index)

  components:
    Listbox: Listbox
    Input: Input
    PlusCircleIcon: PlusCircleIcon
    XCircleIcon: XCircleIcon
