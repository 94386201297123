
  import Synonym from 'models/synonym.coffee'

  import { ActionMenu, Input } from 'components/generic'
  import { VariableIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Synonym

    data: ->
      synonym: @modelValue

    computed:
      actionMenuItems: ->
        [
          if @synonym.isRegEx
            {icon: VariableIcon, label: 'Don’t parse as RegEx', method: => @setRegEx(false)}
          else
            {icon: VariableIcon, label: 'Parse as RegEx', method: => @setRegEx(true)}
          {icon: XIcon, label: 'Delete', method: => @$emit('remove')}
        ]

    methods:
      setRegEx: (val) ->
        @synonym.isRegEx = val
        @update()
      update: ->
        @$emit('update:modelValue', @synonym)
      focus: ->
        @$refs.input.focus()

    components:
      ActionMenu: ActionMenu
      Input: Input
