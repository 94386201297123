
  import ProgressCollection from 'models/progress_collection'

  import { H3 } from 'components/generic'
  import { CheckIcon, ExclamationCircleIcon, ExternalLinkIcon } from "@heroicons/vue/outline";

  export default
    data: ->
      collection: null

    computed:
      display: ->
        @collection?.steps.some (step) -> !step.completed

    created: ->
      @collection = await ProgressCollection.load()

    components:
      CheckIcon: CheckIcon
      ExclamationCircleIcon: ExclamationCircleIcon
      ExternalLinkIcon: ExternalLinkIcon
      H3: H3
