import BotbuilderApi from 'models/botbuilder_api.coffee'
import Channel from 'models/channel.coffee'
import DialogTestElement from 'models/dialog_test_element.coffee'

export default class DialogTest
  constructor: (data) ->
    data ||= {}
    # set default values
    @label = ''
    @description = ''
    @language = ''
    @channelType = 'WEB'
    @skipOptIn = false
    @draft = false
    @elements = []
    # read-only
    @status = 'OK' # or 'FAILED' or 'UNKNOWN'; or, only assigned by webapp: 'RUNNING'
    @valid = true
    @debuggingLogs = []
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @label = data.label if data.label?
    @description = data.description if data.description?
    @language = data.language if data.language?
    @channelType = data.channelType if data.channelType?
    @skipOptIn = data.skipOptIn if data.skipOptIn?
    @draft = data.draft if data.draft?
    @elements = data.elements.map((elementData) => new DialogTestElement(@, elementData)) if data.elements?
    # read-only
    @id = data.id if data.id?
    @status = data.status if data.status?
    @valid = data.valid if data.valid?
    @timeCreated = data.timeCreated if data.timeCreated?
    @timeUpdated = data.timeUpdated if data.timeUpdated?
    @timeLastExecution = data.timeLastExecution if data.timeLastExecution?
    @debuggingLogs = data.debuggingLogs if data.debuggingLogs?
    @error = data.error if data.error?

  save: ({noAlert} = {}) ->
    if @id?
      BotbuilderApi.updateDialogTest(@, noAlert: noAlert).then (data) =>
        (data.elements || []).forEach (elementData, index) =>
          return if !@elements[index]?
          return if @elements[index].id
          @elements[index].id = elementData.id
    else
      BotbuilderApi.createDialogTest(@)
        .then (data) =>
          @update(data)

  delete: ->
    BotbuilderApi.deleteDialogTest(@)

  run: ->
    @status = 'RUNNING'
    BotbuilderApi.executeDialogTest(@)
      .then (data) =>
        @update(data)
        @error = null if !data.error?
      .catch => @status = 'FAILED'

  Object.defineProperties @prototype,
    timeLastExecutionFormatted:
      get: ->
        return '' if !@timeLastExecution
        moment(@timeLastExecution).format(t('formats.dateTime'))
    channelTypeLabel:
      get: ->
        Channel.TYPES.find((ch) => ch.key == @channelType)?.label
    export:
      get: ->
        id: @id,
        label: @label
        description: @description
        language: @language
        channelType: @channelType
        skipOptIn: @skipOptIn
        draft: @draft
        elements: @elements.map (element) -> element.export
