
  import Message from 'models/message.coffee'

  import ButtonEditor from './button_editor.vue'
  import ButtonMover from './button_mover.vue'
  import { Button } from 'components/generic'
  import { ExclamationCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Message

    data: ->
      message: @modelValue

    computed:
      mayAddQuickreplies: ->
        @message.isLast &&
          @message.canHaveQuickreplies &&
          (
            !@message.valueFor('maxQuickreplies')? ||
              @message.quickreplyButtons.length < @message.valueFor('maxQuickreplies')
          )

    watch:
      modelValue: ->
        @message = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @message)

    components:
      Button: Button
      ButtonEditor: ButtonEditor
      ButtonMover: ButtonMover
      ExclamationCircleIcon: ExclamationCircleIcon
