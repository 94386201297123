
  import Channel from 'models/channel.coffee'
  import Project from 'models/project.coffee'
  import { availableLanguages } from 'rails_data'

  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Button, Input, KeyListbox, Modal } from 'components/generic'

  export default
    props:
      channels: Array

    data: ->
      channelTypeLabels: Object.fromEntries(Channel.TYPES.map (type) -> [type.key, type.label])
      creating: false
      newChannelData: {}
      newChannel: null
      languagesForSelector: @$root.languages.map (code) -> {key: code, label: availableLanguages[code]}

    computed:
      mayAddLiveChannels: ->
        Globals.restrictions.channels > @channels.filter((ch) -> ch.environment == 'LIVE').length
      environments: ->
        if @mayAddLiveChannels
          Project.ENVIRONMENTS
        else
          Project.ENVIRONMENTS.filter (env) -> env.key != 'LIVE'

    methods:
      start: (type) ->
        @newChannelData =
          type: type
          label: ''
          environment: @environments[0].key
          defaultLanguage: @$root.languages[0]
        @$refs.modal.open()
        @$nextTick => @$refs.nameInput.focus()
      create: ->
        @creating = true
        @newChannel = Channel.typed(@newChannelData)
        @newChannel.save()
          .then =>
            switch @newChannel.type
              when 'WEB'
                @newChannel.connect()
                  .then => @newChannel.saveDefaultConfig()
                  .finally => @goToNewChannel()
              else
                @$root.connectChannelOnVisit = true
                @goToNewChannel()
          .finally =>
            @creating = false
      goToNewChannel: ->
        @$router.push(name: 'channel', params: {channelId: @newChannel.id})

    components:
      Button: Button
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
      UpgradePrompt: UpgradePrompt
