Date.differenceInDays = (date1, date2) ->
  utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
  Math.floor((utc2 - utc1) / (24 * 60 * 60 * 1000))

Date.differenceInFullMonths = (date1, date2) ->
  yearDiff = date2.getFullYear() - date1.getFullYear()
  monthDiff = date2.getMonth() - date1.getMonth()
  12 * yearDiff + monthDiff

Date.prototype.toISODate = ->
  "#{@getFullYear()}-#{('00' + (@getMonth() + 1)).slice(-2)}-#{('00' + @getDate()).slice(-2)}"

Date.prototype.toISOTime = ->
  "#{('00' + @getHours()).slice(-2)}:#{('00' + @getMinutes()).slice(-2)}:#{('00' + @getSeconds()).slice(-2)}"

Date.prototype.startOfWeek = ->
  prevMonday = new Date(this)
  prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7)
  prevMonday

Date.prototype.startOfMonth = ->
  new Date(@getFullYear(), @getMonth(), 1)

Date.prototype.startOfYear = ->
  new Date(@getFullYear(), 0, 1)

# taken from https://stackoverflow.com/a/6117889
Date.prototype.getWeekNumber = ->
  d = new Date(Date.UTC(@getFullYear(), @getMonth(), @getDate()))
  dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  Math.ceil((((d - yearStart) / 86400000) + 1) / 7)

Date.prototype.getMonthAbbreviation = ->
  ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sept', 'oct', 'nov', 'dec'][@getMonth()]

export default Date
