<template>
  <li class="bg-white rounded-lg shadow">
    <div class="grid grid-cols-12 p-6 pr-4 pt-8">

      <div class="col-span-2">
        <input
          v-model="tagCopy.name"
          :disabled="!editing"
          :style="tagCopy.labelStyles"
          placeholder="Tag name"
          class="w-full inline-flex text-center px-3 py-2 rounded-full text-sm font-medium placeholder-gray-400 outline-none"
          >
        <p class="text-xs text-gray-400 pt-2">
          LAST USED: {{ tagCopy.lastUsedFormatted }}
        </p>

        <Listbox
          v-model="selectedTeam"
          :collection="teamsWithPrompt"
          @selected="addTeam"
          :disabled="!editing"
        />

        <!-- Teams with this Tag -->
        <div class="mt-3">
          <Badge
            v-for="team, index in tagCopy.agentTeams(teams)"
            :key="team.id"
            color="gray"
            size="sm"
          >
            {{ team.name }}
            <button
              v-if="editing"
              type="button"
              class="flex-shrink-0 ml-1 -mr-2 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-800 hover:bg-gray-200 hover:text-MercuryRed-800 focus:outline-none focus:bg-gray-500 focus:text-white"
              @click="removeTeam(index)"
            >
              <span class="sr-only">Remove Team</span>
              <XIcon class="h-3 w-3"/>
            </button>
          </Badge>
        </div>

      </div>

      <!-- when -->
      <div class="col-span-1 text-center pt-2 text-gray-500">WHEN</div>

      <!-- Filter Definition"  -->
      <div class="col-span-8 flex flex-col">
        <div class="align-middle inline-block min-w-full">
          <div class="sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 -mt-10">
              <tbody>
                <TagCondition2
                  v-for="condition, index in tagCopy.conditions"
                  :key="condition.$key"
                  :condition="condition"
                  :index="index"
                  :count="tagCopy.conditions.length"
                  :last-game-condition-present="lastGameConditionPresent"
                  :editing="editing"
                  @add-condition="addCondition"
                  @remove-condition="removeCondition"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-span-1 flex items-start justify-end">
        <ActionMenu :items="actionMenuItems" class="-mt-5"/>
      </div>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform scale-y-0"
      enter-to-class="transform scale-y-100"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="transform scale-y-100"
      leave-to-class="transform scale-y-0"
    >
      <div v-if="editing" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse origin-top rounded-b-lg">
        <Button type="primary" @click="save" class="ml-3" :disabled="sending">
          Save
        </Button>
        <Button @click="cancel" :disabled="sending">
          Cancel
        </Button>
        <div v-if="errors.length" class="mr-auto text-MercuryRed-600 text-sm font-medium">
          Please fix the following error(s):
          {{ errors.join(', ') }}
        </div>
      </div>
    </transition>
  </li>
</template>

<script lang="coffee">
import HandoverTag from 'models/handover_tag'
import PlatformApi from 'models/platform_api.coffee'
import { alert, confirm } from 'helpers'

import TagCondition2 from './tag_condition2.vue'
import { ActionMenu, Badge, Button, Listbox } from 'components/generic'
import { XIcon, XCircleIcon, DuplicateIcon, PencilIcon } from '@heroicons/vue/outline'

export default
  props:
    tag: HandoverTag
    index: Number
    teams: Array

  data: ->
    selectedTeam: @teams[0]
    editing: false
    sending: false
    tagCopy: @tag.clone()
    actionMenuItems: [
      {icon: PencilIcon, label: 'Edit', method: @edit},
      {icon: DuplicateIcon, label: 'Clone', method: @clone},
      {icon: XCircleIcon, label: 'Delete', method: @deleteTag, hr: true},
    ]
    errors: {}

  computed:
    teamsWithPrompt: ->
      @selectedTeam = {name: 'Add teams'}
      [@selectedTeam].concat(@teams)
    lastGameConditionPresent: ->
      @tagCopy.conditions.some (condition) ->
        condition.lastActiveGame?

  mounted: ->
    @selectedTeam = @teamsWithPrompt[0]

  methods:
    addTeam: (team) ->
      return if @tagCopy.agentTeamIds.includes(team.id)
      @tagCopy.agentTeamIds.push(team.id)
    removeTeam: (index) ->
      @tagCopy.agentTeamIds.splice(index, 1)
    addCondition: ->
      if @lastGameConditionPresent
        @tagCopy.conditions.push({})
      else
        @tagCopy.conditions.push({lastActiveGame: ''})
    removeCondition: (index) ->
      @tagCopy.conditions.splice(index, 1)
    edit: ->
      @editing = true
      Vue.nextTick =>
        $(@$el).find('input').focus()
    cancel: ->
      @editing = false
      if !@tagCopy.id?
        @$emit('delete-tag', @index)
        return
      @tagCopy = @tag.clone()
    save: ->
      return if !@validate()
      @sending = true
      PlatformApi.Handover.saveTag(@tagCopy)
        .then (data) =>
          @editing = false
          for attr, value of data
            @tag[attr] = value
        .catch (error) -> await alert("Sorry, tag could not be saved.\nError message:\n" + error?.message)
        .finally =>
          @sending = false
    clone: ->
      @$emit('clone-tag', @tag)
    deleteTag: ->
      return unless await confirm("Do you really want do delete handover tag “#{@tagCopy.name}”?")
      @$emit('delete-tag', @index)
    validate: ->
      @errors = []
      @errors.push('tag needs a name') if @tagCopy.name.length == 0
      @errors.push('name is taken') if @$root.tags.some (tag, index) => tag.name == @tagCopy.name && index  != @index
      @errors.push('tag must be assigned at least one team') if @tagCopy.agentTeamIds.length == 0
      return false if @errors.length > 0
      true

  components:
    ActionMenu: ActionMenu
    Badge: Badge
    Button: Button
    Listbox: Listbox
    TagCondition2: TagCondition2
    XIcon: XIcon
</script>
