# this component is only used in inbox2

removedFilter = Vue.component 'parameter-filtering',
  props:
    filters: Object
    # the `filters` prop is expected in the following format:
    #   stageIds: [...]
    #   fixParameters: [...]
    #   contextParameters: [...]

  data: ->
    fixParameters: if @$root.isBotcore2
      [
        key: 'minNumberOfMessages'
        label: 'Min. number of user messages'
        valueType: 'number'
        collectionType: 'none'
        operator: '='
      ,
        key: 'hasMessagesBefore'
        label: 'Has messages before'
        valueType: 'date'
        collectionType: 'none'
        operator: '='
      ,
        key: 'hasMessagesAfter'
        label: 'Has messages after'
        valueType: 'date'
        collectionType: 'none'
        operator: '='
      ,
        key: 'wasInHandover'
        label: 'Had handover'
        valueType: 'bool'
        collectionType: 'none'
        operator: '='
        presentOrNot: true
      ]
    else
      [
        label: 'minNumberOfMessages'
        humanizedLabel: 'Min. number of user messages'
        type: 'Number'
        operator: '='
      ,
        label: 'hasMessagesBefore'
        humanizedLabel: 'Has messages before'
        type: 'Date'
        operator: '='
      ,
        label: 'hasMessagesAfter'
        humanizedLabel: 'Has messages after'
        type: 'Date'
        operator: '='
      ,
        label: 'wasInHandover'
        humanizedLabel: 'Had handover'
        type: 'Boolean'
        operator: '='
        presentOrNot: true
      ]
    filterRefs: []
    fixFilterRefs: []

  computed:
    availableContextParameters: ->
      return [] unless @filters.stageIds
      (
        if @$root.isBotcore2
          @$root.contextParameters
        else
          Array.unique(
            [].concat.apply([],
              @filters.stageIds.map (stageId) =>
                @$root.contextParameters[stageId]?.read
            )
              .filter (parameter) -> parameter?
          , (parameter) -> parameter.label
          )
      ).sort (a, b) ->
        labelA = a.humanizedLabel || a.label
        labelB = b.humanizedLabel || b.label
        return -1 if labelA < labelB
        return 1 if labelA > labelB
        0

  beforeUpdate: ->
    @filterRefs = []
    @fixFilterRefs = []

  methods:
    addFilter: (parameter) ->
      @filters.contextParameters.push(
        mode: 'include'
        field: if @$root.isBotcore2 then parameter.key else parameter.label
        operator: ''
        value: ''
      )
      Vue.nextTick =>
        setTimeout =>
          @filterRefs[-1..][0].open()
        , 0
    addFixFilter: (parameter) ->
      @filters.fixParameters.push(
        mode: 'include'
        field: if @$root.isBotcore2 then parameter.key else parameter.label
        operator: parameter.operator
        value: ''
      )
      Vue.nextTick =>
        setTimeout =>
          @fixFilterRefs[-1..][0].open()
        , 0
    parameterByLabel: (keyOrLabel) ->
      if @$root.isBotcore2
        @availableContextParameters.find (parameter) -> parameter.key == keyOrLabel
      else
        @availableContextParameters.find (parameter) -> parameter.label == keyOrLabel
    fixParameterByLabel: (keyOrLabel) ->
      if @$root.isBotcore2
        @fixParameters.find (parameter) -> parameter.key == keyOrLabel
      else
        @fixParameters.find (parameter) -> parameter.label == keyOrLabel
    applyFilter: (filter) ->
      if filter? && @$root.track?
        @$root.track('Inbox filter added', filter: filter)
      @$emit('filters-changed')
    removeFilter: (index) ->
      removedFilter = @filters.contextParameters.splice(index, 1)
      if @$root.track?
        @$root.track('Inbox filter removed', filter: removedFilter[0])
      @applyFilter()
    removeFixFilter: (index) ->
      removedFilter = @filters.fixParameters.splice(index, 1)
      if @$root.track?
        @$root.track('Inbox filter removed', filter: removedFilter[0])
      @applyFilter()
    setFilterRef: (el) ->
      @filterRefs.push(el) if el?
    setFixFilterRef: (el) ->
      @fixFilterRefs.push(el) if el?

  template: '
    <div>
      {{ t("inbox.filter.addFilter") }}&ensp;
      <dropdown align="left" chosen="None">
        <li v-for="parameter in fixParameters">
          <a @click="addFixFilter(parameter)">{{ parameter.humanizedLabel || parameter.label }}</a>
        </li>
        <li v-for="parameter in availableContextParameters">
          <a @click="addFilter(parameter)">{{ parameter.humanizedLabel || parameter.label }}</a>
        </li>
      </dropdown>
      <div class="parameter-filters">
        <parameter-filter
          :ref="setFixFilterRef"
          v-for="filter, index in filters.fixParameters"
          :filter="filter"
          :parameter="fixParameterByLabel(filter.field)"
          :index="index"
          @apply-filter="applyFilter(filter)"
          @remove-filter="removeFixFilter"
          />
        <parameter-filter
          :ref="setFilterRef"
          v-for="filter, index in filters.contextParameters"
          :filter="filter"
          :parameter="parameterByLabel(filter.field)"
          :index="index"
          @apply-filter="applyFilter(filter)"
          @remove-filter="removeFilter"
          />
      </div>
    </div>
  '
