
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import { DocumentAddIcon } from '@heroicons/vue/outline'

  export default
    props:
      small:
        type: Boolean
        default: false

    data: ->
      draggedOver: false

    emits: ['add']

    methods:
      addFiles: (e) ->
        @$emit('add', Array.from(e.target.files))
      drop: (e) ->
        @draggedOver = false
        @$emit('add', Array.from(e.dataTransfer.files))

    components:
      DocumentAddIcon: DocumentAddIcon
