
  import { BookOpenIcon } from '@heroicons/vue/outline'

  export default
    props:
      url: String
      label:
        type: String
        default: 'Documentation'
      iconOnly:
        type: Boolean
        default: false

    components:
      BookOpenIcon: BookOpenIcon
