import { confirm } from 'helpers'

Vue.component 'user-intent',
  props: ['intent', 'index', 'intentList', 'botIntents', 'contextParameters', 'isRecoveryIntent', 'hasErrorCount']
  data: ->
    state: state
    render: false
    labelUnique: true
  created: ->
    # make sure intent has all required properties
    Vue.setDefault(@intent, 'label', '')
    Vue.setDefault(@intent, 'utterances', {}) unless @isRecoveryIntent
    Vue.setDefault(@intent, 'slots', []) unless @isRecoveryIntent
    Vue.setDefault(@intent, 'errorCount', 3) if @hasErrorCount
    Vue.setDefault(@intent, 'reactions', [{}])
    Vue.setDefault(@intent, '$key', GuidGenerator.newGuid())
    # amend old format
    if @intent.contextConditionals?
      Vue.set(@intent, 'reactions', @intent.contextConditionals)
      Vue.delete(@intent, 'contextConditionals')
    for reaction in @intent.reactions
      Vue.setDefault(reaction, '$key', GuidGenerator.newGuid())
      Vue.setDefault(reaction, 'conditions', [])
      # amend old format of reactions
      if reaction.parameter? # check if old format (i.e. only one condition)
        if reaction.parameter && reaction.operator # check if condition is set
          reaction.conditions.push(
            parameter: reaction.parameter
            operator: reaction.operator
            value: reaction.value
          )
        Vue.delete(reaction, 'parameter')
        Vue.delete(reaction, 'operator')
        Vue.delete(reaction, 'value')
      # for up-to-date games again
      else if !reaction.ifLastActiveGame? && reaction.conditions.length == 0
        Vue.delete(reaction, 'conditions')
    @validate()
    # defer rendering of panel content so page can be built up fastser
    setTimeout (=> @render = true), 0
  watch:
    intent:
      handler: -> @validate()
      deep: true
  computed:
    labelValid: ->
      @labelUnique && GameValidator.string(@intent.label)
    utterancesValid: ->
      GameValidator.variationField(@intent.utterances)
  methods:
    validate: ->
      @labelUnique = GameValidator.unique(@intent.label, @intentList.map (intent) -> intent.label)
      Vue.set @intent, '$valid',
        @labelValid &&
        (@isRecoveryIntent || @utterancesValid) &&
        (@isRecoveryIntent || GameValidator.allValid(@intent.slots)) &&
        (!@hasErrorCount || @intent.errorCount > 0) &&
        GameValidator.allValid(@intent.reactions)
    remove: (index) ->
      return unless await confirm("Do you really want to delete intent “#{@intent.label}”?")
      $(@$el).find('.collapse').collapse('hide')
      @$emit('remove-intent', index)
    addSlot: ->
      @intent.slots.push({})
    removeSlot: (index) ->
      @intent.slots.splice(index, 1)
    addReaction: ->
      @intent.reactions.push({conditions: []})
    removeReaction: (index) ->
      @intent.reactions.splice(index, 1)
    scrollToAndOpen: ->
      @$el.scrollIntoView(behavior: 'smooth')
      @$refs.togglable.expand()
  template: '
    <panel-togglable ref="togglable" :data-key="intent.$key">
      <template v-slot:header>
        <div class="width-50 expand" :class="{\'has-error\': !labelValid}">
          <inline-edit
            v-model:string="intent.label"
            placeholder="intent label"
            warning="Changing the intent’s label will break existing references to this intent. Continue?"
            focus="true"
            bold="true"
            />
        </div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!intent.$valid"></span>
        </div>
      </template>

      <template v-if="render" v-slot:body>

        <div v-if="hasErrorCount" :class="{\'has-error\': intent.errorCount < 1}" class="form-group">
          triggered after
          <input type="number" min="1" style="width: 5em" v-model="intent.errorCount" class="styled-form-field">
          consecutive errors
        </div>

        <div class="form-group" :class="{\'has-error\': !utterancesValid}" v-if="!isRecoveryIntent">
          <label class="non-bold">
            Utterance
            <info-button info-key="utterances"/>
          </label>
          <multilang-variation-field :dictionary="intent.utterances"/>
        </div>

        <div v-if="!isRecoveryIntent" class="form-group">
          <h5>Slots</h5>
          <div
            v-for="(slot, slotIndex) in intent.slots"
            :key="slot.$key"
            class="panel-bordered form-group"
            >
            <user-intent-slot
              :intent-slot="slot"
              :index="slotIndex"
              @remove-slot="removeSlot"
              class="panel-body"
              />
          </div>
          <plus-button label="add slot" :method="addSlot"/>
        </div>

        <h5>Bot reaction</h5>
        <bot-reactions
          :host="intent"
          :context-parameters="contextParameters"
          :bot-intents="botIntents"
          :allow-last-active-game="isRecoveryIntent"
          />

        <div v-if="!isRecoveryIntent" class="btn btn-caution pull-right" @click="remove(index)">Remove intent</div>
      </template>

      <template v-if="!render" v-slot:body>
        <span class="fa fa-sync fa-spin"></span>&nbsp;
        loading...
      </template>
    </panel-togglable>
  '
