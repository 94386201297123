import Conversation from 'models/conversation'
import { railsEnv } from 'rails_data'
import { alert } from 'helpers'

Vue.component 'inbox2-conversation-details',
  props:
    conversation: Conversation

  data: ->
    notesCopy: null
    isOpen: false
    contextParameterRefs: []
    projectId: Globals.projectId
    railsEnv: railsEnv
    ravensburgerData:
      name: ''
      content: ''
    ravensburgerSending: false

  computed:
    primaryUserParameters: ->
      params = {}
      for key in ['user.firstName', 'user.lastName', 'user.email', 'user.phone']
        params[key] = @conversation.context[key]
      params
    otherUserParameters: ->
      keys = if @$root.isBotcore2
        (
          if @$root.contextParameters.length > 0
            @$root.contextParameters.map (param) -> param.key
          else
            Object.keys(@conversation.context)
        ).filter (key) ->
          key[0..3] == 'user' && !(key in ['user.firstName', 'user.lastName', 'user.email', 'user.phone'])
      else
        (
          if @$root.contextParameters[@conversation.stageId]?.read
            @$root.contextParameters[@conversation.stageId].read.map (param) -> param.label
          else
            Object.keys(@conversation.context)
        ).filter (key) ->
          key[0..3] == 'user' && !(key in ['user.firstName', 'user.lastName', 'user.email', 'user.phone'])
      keys.sort (a, b) =>
        if @conversation.context[a] && @conversation.context[a]
          0
        else if @conversation.context[a]
          -1
        else if @conversation.context[b]
          1
        else
          0
      params = {}
      for key in keys
        params[key] = @conversation.context[key]
      params
    nonUserParameters: ->
      params = {}
      if @$root.isBotcore2
        return params if @$root.contextParameters.length == 0
        for param in @$root.contextParameters
          continue if param.key[0..3] == 'user'
          continue if !param.settings.displayInInbox
          params[param.key] = @conversation.context[param.key]
      else
        return params if !@$root.contextParameters[@conversation.stageId]?.read
        for param in @$root.contextParameters[@conversation.stageId].read
          continue if param.label[0..3] == 'user'
          continue if !param.displayInInbox
          params[param.label] = @conversation.context[param.label]
      params
    messagesInbound: ->
      @conversation.statistics?.messagesInbound
    notesEmpty: ->
      return true if !@conversation.notes?.text?
      @conversation.notes.text.length == 0
    conversationFirstSeen: -> l(@conversation.timeFirstSeen, 'dateTime')
    conversationLastSeen: -> l(@conversation.timeLastSeen, 'dateTime')

  beforeUpdate: ->
    @contextParameterRefs = []

  methods:
    editContextParameter: (key) ->
      @closeNotes()
      for cpComponent in @contextParameterRefs
        continue if cpComponent.paramKey == key
        cpComponent.cancel()
    editNotes: ->
      @editContextParameter(key: null) # cancel editing of context parameters
      @notesCopy = @conversation.notes?.text || ''
      Vue.nextTick =>
        $(@$el).find(".conversation-notes .editable").focus()
    saveNotes: ->
      @$root.track('Inbox conversation notes saved', conversationId: @conversation.id, notes: @notesCopy)
      notes = @notesCopy
      @$root.api.saveConversationNotes(@conversation, notes)
        .then (data) =>
          @conversation.update(notes: data)
          @closeNotes()
        .catch =>
          await alert('Sorry, your changes couldn’t be saved.')
    closeNotes: ->
      @notesCopy = null
    notesEditableExit: (e) ->
      if e.keyCode == 13
        @saveNotes()
      else
        @closeNotes()
    expand: ->
      @isOpen = true
      $('#hidden-parameters').collapse('show')
    collapse: ->
      $('#hidden-parameters').collapse('hide').one('hidden.bs.collapse', => @isOpen = false)
    setContextParameterRef: (el) ->
      @contextParameterRefs.push(el) if el?
    createRavensburgerTicket: ->
      @ravensburgerSending = true
      fetch(
        "https://customers.mercury.ai/ravensburger/sapintegration/users/#{@conversation.id}/tickets/create"
        method: 'POST',
        body: JSON.stringify(@ravensburgerData),
        headers:
          'X-Requested-With': 'XMLHttpRequest'
          'Content-Type': 'application/json'
          'Accept': 'application/json'
          'Authorization': "Bearer #{Globals.projectToken}"
      )
        .then (response) =>
          if response.ok
            @ravensburgerData = {name: '', content: ''}
            await alert('Ticket has been created.')
          else
            response.json()
              .then (response) -> await alert(response.message)
        .catch -> await alert('Sorry, ticket couldn’t be created.')
        .finally => @ravensburgerSending = false

  template: '
    <div class="conversation-details flex-container columns">
      <div class="conversation-attributes expand">

        <inbox2-conversation-details-context-parameter
          v-for="value, key in primaryUserParameters"
          :ref="setContextParameterRef"
          :key="key"
          :paramKey="key"
          :context="conversation.context"
          :conversation="conversation"
          @edit="editContextParameter"
          />

        <div class="spacer"></div>

        <inbox2-conversation-details-context-parameter
          v-for="value, key in nonUserParameters"
          :ref="setContextParameterRef"
          :key="key"
          :paramKey="key"
          :context="conversation.context"
          :conversation="conversation"
          @edit="editContextParameter"
          />

        <div class="spacer"></div>

        <button v-show="!isOpen" @click="expand" class="btn btn-plain">{{ t("inbox.conversations.showAll") }}</button>

        <div class="collapse" id="hidden-parameters">
          <inbox2-conversation-details-context-parameter
            v-for="value, key in otherUserParameters"
            :ref="setContextParameterRef"
            :key="key"
            :paramKey="key"
            :context="conversation.context"
            :conversation="conversation"
            @edit="editContextParameter"
            />

          <div class="spacer"></div>

          <div class="user-data">
            <div class="user-data-label">{{ conversation.channelType.toLowerCase().capitalize() }} ID</div>
            <div class="user-data-value ellipsis">{{ conversation.nativeUserId }}</div>
          </div>

          <div class="user-data">
            <div class="user-data-label">{{ t("inbox.conversations.firstSeen") }}</div>
            <div class="user-data-value ellipsis">{{ conversationFirstSeen }}</div>
          </div>

          <div class="user-data">
            <div class="user-data-label">{{ t("inbox.conversations.lastSeen") }}</div>
            <div class="user-data-value ellipsis">{{ conversationLastSeen }}</div>
          </div>

          <div class="user-data">
            <div class="user-data-label">{{ t("inbox.conversations.totalMessages") }}</div>
            <div class="user-data-value ellipsis">
              <span class="label label-equal label-default">{{ messagesInbound }}</span>
            </div>
          </div>

          <div class="spacer"></div>
          <button @click="collapse" class="btn btn-plain">{{ t("inbox.conversations.hide") }}</button>
          <div class="spacer"></div>
        </div>

      </div>

      <div class="conversation-notes">
        <!-- ticket creating function for Ravensburger -->
        <template v-if="railsEnv == \'production\' && projectId == 333">
          <div class="notes-label">
            <div class="user-data-label">Create ticket</div>
            <div class="user-data-value"></div>
          </div>
          <div class="notes flex flex-col items-end mb-2">
            <input v-model="ravensburgerData.name" type="text" placeholder="Title" class="form-control mb-2">
            <textarea v-model="ravensburgerData.content" placeholder="Text" class="form-control flex-grow mb-2"></textarea>
            <button @click="createRavensburgerTicket" :disabled="ravensburgerSending" type="button" class="btn btn-primary">Create</button>
          </div>
        </template>

        <template v-else>
          <div class="notes-label">
            <div class="user-data-label">{{ t("inbox.conversations.notes") }}</div>
            <div class="user-data-value"></div>
            <div v-if="notesCopy == null && !notesEmpty" @click="editNotes" class="user-data-edit">
              <span class="fa fa-pen"></span>
            </div>
          </div>
          <div class="notes">
            <div v-if="notesCopy == null && notesEmpty" class="text-center">
              <div class="spacer"></div>
              <button @click="editNotes" class="btn btn-default">+ {{ t("inbox.conversations.addNote") }}</button>
            </div>
            <div
              v-if="notesCopy == null && !notesEmpty"
              class="notes-content"
              >{{ conversation.notes.text }}</div>
            <editable
              v-if="notesCopy != null"
              :content="notesCopy"
              @update="notesCopy = $event"
              @exit="notesEditableExit"
              @blur="notesEditableExit"
              class="form-control"
              />
          </div>
        </template>
      </div>
    </div>
  '
