
  import MacroChange from 'models/macro_change'
  import User from 'models/user'
  import { confirm } from 'helpers'

  import { ActionMenu, Api2PaginationWithNumbers, EnvironmentBadge, Spinner, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { ReplyIcon } from '@heroicons/vue/outline'

  export default
    props:
      page:
        type: Number
        default: 0
      embedded:
        type: Boolean
        default: false

    data: ->
      mounted: false
      users: {}
      userLoadingStarted: {}
      actionMenuItems: {}
      MacroChange: MacroChange

    mounted: ->
      @mounted = true
      @$refs.pagination.load(page: @page)

    methods:
      actionMenuItem: (change) ->
        [
          {label: 'Restore version before', icon: ReplyIcon, method: => @restore(change)}
        ]
      loadUsers: (macroChanges) ->
        macroChanges.forEach (change) =>
          return if @userLoadingStarted[change.platformUserId]
          if !change.platformUserId
            change.platformUserId = 'System'
            return
          @userLoadingStarted[change.platformUserId] = true
          @users[change.platformUserId] = await User.load(change.platformUserId.split('-').last)
      restore: (change) ->
        return unless await confirm("Restore your Chatbot the the last version that does not include the listed changes. All changes included in this version and later ones will be undone, and a new version will be created.")
        change.restore()
          .then =>
            # reload whole botbuilder app
            Turbolinks.visit(window.location)
      goTo: (change) ->
        => @$emit('go-to', change.id)

    components:
      ActionMenu: ActionMenu
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      EnvironmentBadge: EnvironmentBadge
      Spinner: Spinner
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
