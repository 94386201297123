
  import ContentList from './content_list.vue'
  import SchemaTable from './schema_table.vue'
  import Wrapper from './wrapper.vue'

  export default
    components:
      ContentList: ContentList
      SchemaTable: SchemaTable
      Wrapper: Wrapper
