
  import TemplateParameter from './template_parameter.vue'
  import { H3 } from 'components/generic'
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      template: Object
      audience: Object
      context: String
      disabled:
        type: Boolean
        default: false

    data: ->
      customParameter: {label: 'custom', humanizedLabel: 'Custom'}

    computed:
      allParameters: ->
        [@template.headerParameter].concat(@template.setBodyParameters).concat([@template.buttonParameter]).filter (param) -> param?
      demoUser: ->
        @audience.users[0]

    watch:
      template: ->
        @parseParameters()
      'template.headerParameter':
        handler: -> @interpolateTemplateHeader()
        deep: true
      'template.setBodyParameters':
        handler: -> @interpolateTemplateBody()
        deep: true

    created: ->
      @parseParameters() if @template?

    methods:
      parseParameters: ->
        @template.headerParameter = null
        @template.setBodyParameters = []
        @template.buttonParameter = null
        if @template.header?.format == 'TEXT'
          @template.headerParameter = @parse(@template.header.text)[0]
        else if @template.header?
          @template.headerParameter =
            placeholder: 'URL'
            contextParameter: @customParameter
            defaultValue: ''
        @template.setBodyParameters = @parse(@template.body.text)
        if button = @template.buttons?.buttons.find (button) -> button.type == 'URL'
          @template.buttonParameter = @parse(button.url)[0]
      parse: (originalString) ->
        string = originalString.slice()
        res = []
        while match = string.match(/\{\{\d+\}\}/)
          res.push(
            placeholder: match[0]
            contextParameter: @customParameter
            defaultValue: ''
          )
          string = string.slice(match.index + 5)
        res
      interpolateTemplateHeader: ->
        return unless @template.headerParameter?
        if @template.header.format == 'TEXT'
          @template.header.interpolatedText =
            @template.header.text.replace(@template.headerParameter.placeholder, @interpolationValue(@template.headerParameter))
        else
          @template.header.url = @urlValue(@template.headerParameter)
      interpolateTemplateBody: ->
        string = @template.body.text
        for parameter in @template.setBodyParameters
          string = string.replace(parameter.placeholder, @interpolationValue(parameter))
        @template.body.interpolatedText = string
      interpolationValue: (parameter) ->
        if parameter.contextParameter?.label == 'custom'
          return parameter.defaultValue || parameter.placeholder
        value = @demoUser?.properties?[parameter.contextParameter?.label]
        return value if value?
        return parameter.defaultValue if parameter.defaultValue.length
        parameter.placeholder
      urlValue: (parameter) ->
        if parameter.contextParameter?.label == 'custom'
          return parameter.defaultValue
        value = @demoUser?.properties?[parameter.contextParameter?.label]
        return value if value?
        parameter.defaultValue

    components:
      ChevronRightIcon: ChevronRightIcon
      H3: H3
      TemplateParameter: TemplateParameter
