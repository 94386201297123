
import PlatformApi from 'models/platform_api.coffee'
import Subscription from 'models/subscription'
import Addons from './addons.vue'
import AvailableAddons from './available_addons.vue'
import Upgrade from './upgrade.vue'
import { Badge, Button, H1, H3, Input, Modal, Panel } from 'components/generic'

export default
  data: ->
    sending: false
    editing: false
    poNumberCopy: @$root.organization.po_number

  computed:
    subscription: ->
      @$root.organization.subscription || new Subscription({})

  created: ->
    PlatformApi.Webapp.getSubscription(@$root.organization.id)
      .then (data) =>
        @$root.organization.subscription = new Subscription(data)

  mounted: ->
    @setHeight()
    window.addEventListener('resize', @setHeight)

  unmounted: ->
    window.removeEventListener('resize', @setHeight)

  methods:
    setHeight: ->
      @$refs.aside.style.height = document.documentElement.clientHeight -
        @$refs.aside.getBoundingClientRect().top + 'px'
    openCancellationModal: ->
      @$refs.cancellationModal.open()
    cancelSubscription: ->
      @sending = true
      PlatformApi.Webapp.cancelSubscription(@$root.organization.id)
        .then (data) =>
          @subscription.update(data)
          @$refs.cancellationModal.close()
        .finally =>
          @sending = false
    openReactivationModal: ->
      @$refs.reactivationModal.open()
    reactivateSubscription: ->
      @sending = true
      PlatformApi.Webapp.reactivateSubscription(@$root.organization.id)
        .then (data) =>
          @subscription.update(data)
          @$refs.reactivationModal.close()
        .finally =>
          @sending = false
    cancelPoNumber: ->
      @editing = false
      @poNumberCopy = @$root.organization.po_number
    savePoNumber: ->
      @sending = true
      PlatformApi.Webapp.saveOrganization(id: @$root.organization.id, po_number: @poNumberCopy)
        .then (data) =>
          Object.assign(@$root.organization, data)
          @poNumberCopy = @$root.organization.po_number
          @editing = false
        .finally =>
          @sending = false

  components:
    Addons: Addons
    AvailableAddons: AvailableAddons
    Badge: Badge
    Button: Button
    H1: H1
    H3: H3
    Input: Input
    Modal: Modal
    Panel: Panel
    Upgrade: Upgrade
