
  import Integration from 'models/integration.coffee'
  import Secret from 'models/secret'

  import { Button, H3, Input, KeyListbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Integration
      displayKeys: Boolean
      max:
        type: Number
        default: 99
      description: String

    data: ->
      integration: @modelValue
      availableSecrets: []
      secretsLoaded: false
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @integration = @modelValue

    created: ->
      @loadSecrets()

    methods:
      loadSecrets: ->
        Secret.list().then (secrets) =>
          @availableSecrets = [{label: 'Select'}].concat(
            secrets.map (secret) ->
              {key: secret.id, label: "#{secret.label} (#{secret.typeLabel})"}
            )
          @secretsLoaded = true
      addSecret: ->
        @integration.secretAttachments.push(secretId: @availableSecrets[0]?.id, key: '')
      removeSecret: (index) ->
        @integration.secretAttachments.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @integration)

    components:
      Button: Button
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      XIcon: XIcon
