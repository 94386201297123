
  import { CheckIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Boolean
      name: String
      disabled:
        type: Boolean
        default: false

    emits: ['change', 'update:modelValue']

    data: ->
      checkboxValue: @modelValue

    watch:
      modelValue: ->
        @checkboxValue = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @checkboxValue)
        @$emit('change')

    components:
      CheckIcon: CheckIcon
