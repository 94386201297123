
  import Label from './label.vue'
  import VueBootstrapDatetimePicker from 'components/vue-bootstrap-datetimepicker.coffee'

  export default
    props:
      modelValue: String
      label: String
      config: Object
      placeholder: String
      errorText: String
      disabled:
        type: Boolean
        default: false

    data: ->
      value: @modelValue

    watch:
      value: ->
        @emit()
      modelValue: ->
        @value = @modelValue

    methods:
      emit: ->
        @$emit('update:modelValue', @value)
      focus: ->
        @$refs.input.$el.focus()

    components:
      Label: Label
      VueBootstrapDatetimePicker: VueBootstrapDatetimePicker
