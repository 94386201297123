export default class WidgetTeaserCondition
  @OPERATOR_TYPES = [
    'equals'
    'notEquals'
    'exists'
    'notExists'
  ]

  constructor: (data = {}) ->
    @parameter = data.parameter || ''
    @operator = data.operator || 'equals'
    @value = data.value || ''

  Object.defineProperties @prototype,
    isUnary:
      get: ->
        ['exists', 'notExists'].includes(@operator)
    export:
      get: ->
        parameter: @parameter
        operator: @operator
        value: @value
