import { confirm } from 'helpers'

Vue.component 'search-game-attribute',
  props: ['attribute', 'index', 'contentObjectType', 'autoSelectQuestion']
  data: ->
    state: state
  computed:
    valid: ->
      return true unless @validator?
      @validator.isValid(@attribute, type: @contentObjectType.signature[@attribute.name])
  created: ->
    # make sure attribute has all required properties
    Vue.setDefault(@attribute, 'name', '')
    Vue.setDefault(@attribute, 'weight', 1)
    Vue.setDefault(@attribute, 'questions', {})
    Vue.setDefault(@attribute, '$key', GuidGenerator.newGuid())
    # amend old format
    if Object.values(@attribute.questions)[0] instanceof Array # if `questions` is a MultilangVariationString
      for lang, list of @attribute.questions
        quickreplies = []
        if @attribute.showQuickreplies
          if @attribute.autoQuickreplies
            quickreplies.push(
              type: 'Button'
              buttonType: 'QuickReplyAutogenerate'
              maxCount: @attribute.maxQuickreplies
              text:
                type: 'Text'
                text: '<generated>'
                role: 'NONE'
                format: 'PLAIN'
            )
          else
            for quickreply in @attribute.quickreplies
              quickreplies.push(
                type: 'Button'
                buttonType: 'QuickReply'
                text:
                  type: 'Text'
                  text: quickreply.label[lang]
                  role: 'NONE'
                  format: 'PLAIN'
                payload:
                  game: @state.gameName
                  intent: 'Find'
                  slots: [
                    label: "#{@attribute.name}#{quickreply.operator}"
                    value: quickreply.value
                  ]
              )
        if @attribute.addWhateverQuickreply
          quickreplies.push(
            type: 'Button'
            buttonType: 'QuickReply'
            payload:
              game: @state.gameName
              intent: 'Find'
              slots: []
            text:
              type: 'Text'
              text: if lang == 'de' then 'egal' else if lang == 'en' then 'whatever'
              role: 'NONE'
              format: 'PLAIN'
          )
        @attribute.questions[lang] =
          web: list.map (question) =>
            messages: [
              template: 'text'
              type: 'Bubble'
              attachments: [
                type: 'Text'
                text: question
                format: 'PLAIN'
                role: 'NONE'
              ].concat(quickreplies)
            ]
            callbacks: if @attribute.callbacks instanceof Array then @attribute.callbacks else []
    @validate()
  watch:
    attribute:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      @attribute.$valid = @attribute.questions?.$valid
    remove: (index) ->
      return unless await confirm("Do you really want to remove attribute “#{@attribute.name}”?")
      @$emit('remove-attribute', index)
    isReferenceAttribute: (attribute) ->
      TypeValidator.valueType(@contentObjectType.signature[attribute.name]) in @state.references
  template: '
    <panel-togglable parent-selector="#attributes-accordion">
      <template v-slot:header>
        <div class="width-50 text-bold">
          {{ attribute.name }}
        </div>
        <div :class="{invisible: autoSelectQuestion != \'weight\'}">
          <select class="styled-form-field input-sm" v-model="attribute.weight">
            <option :value="v" v-for="v in [1,2,3,4,5,6,7,8,9,10]">{{ v }}</option>
          </select>
        </div>
        <div class="check expand">
          <span class="fa fa-exclamation-circle text-danger" v-if="!attribute.$valid"></span>
        </div>
      </template>

      <template v-slot:body>
        <div class="form-group">
          <p class="text-bold">How should the bot ask for options regarding this attribute?</p>
          <nlg-editor :nlg="attribute.questions" :autogenerated-quickreplies-enabled="true" :in-search-attribute="true"/>
        </div>
        <div class="btn btn-caution pull-right" v-on:click="remove(index)">Remove attribute from game</div>
      </template>
    </panel-togglable>
  '
