import ContextParameter from 'models/context_parameter.coffee'
import ValueType from 'models/value_type.coffee'

export default class EntitySchemaAttribute
  constructor: (@schema, data) ->
    @type = 'valueTyped'
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key if data.key?
    @type = data.type if data.type?
    @label = data.label || ''
    @description = data.description || ''
    @collectionType = data.collectionType || 'none'
    @activeComponents = data.activeComponents # will be set by BE if undefined
    @searchFilter = if data.searchFilter? then data.searchFilter else false
    switch @type
      when 'valueTyped'
        @valueType = data.valueType || 'string'
      when 'entityReference'
        @entitySchemaKey = data.entitySchemaKey
    this

  Object.defineProperties @prototype,
    valueTypeOrSchemaKey:
      get: ->
        switch @type
          when 'valueTyped' then @valueType
          when 'entityReference' then @entitySchemaKey
      set: (valueTypeOrSchemaKey) ->
        switch @type
          when 'valueTyped' then @valueType = valueTypeOrSchemaKey
          when 'entityReference' then @entitySchemaKey = valueTypeOrSchemaKey
    dataTypeLabel:
      get: ->
        switch @type
          when 'valueTyped'
            "#{ContextParameter.VALUE_TYPES[@valueType]} #{if @collectionType != 'none' then @collectionType else ''}"
          when 'entityReference'
            "#{@entitySchemaKey} #{if @collectionType != 'none' then @collectionType else ''}"
    templateString:
      get: ->
        valueString = switch @type
          when 'valueTyped'
            if ValueType.isNumber(@valueType)
              '0'
            else
              switch @valueType
                when 'bool' then 'false'
                when 'date' then moment().format('YYYY-MM-DDTHH:mm:ss')
                else '""'
          when 'entityReference'
            '""'
        valueString = '[' + valueString + ']' if @collectionType != 'none'
        "\"#{@key}\": #{valueString}"
    export:
      get: ->
        obj =
          type: @type
          key: @key
          label: @label
          description: @description
          collectionType: @collectionType
          activeComponents: @activeComponents
          searchFilter: @searchFilter
        switch @type
          when 'valueTyped'
            obj.valueType = @valueType
          when 'entityReference'
            obj.entitySchemaKey = @entitySchemaKey
        obj
