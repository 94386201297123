
  import PlatformApi from 'models/platform_api.coffee'
  import TemplateDisplay from './template_display.vue'
  import { Alert, Button, H3, Input } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      job: Object
      selectedTemplate: Object

    data: ->
      phoneNumberForPreview: ''
      previewErrorMessage: null

    methods:
      sendPreview: ->
        return if @phoneNumberForPreview.length == 0
        @previewErrorMessage = null
        PlatformApi.WaTemplates.sendPreview(@job.id, @phoneNumberForPreview)
          .catch (data) =>
            @previewErrorMessage = data.message if data.message?

    components:
      Alert: Alert
      Button: Button
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      H3: H3
      Input: Input
      TemplateDisplay: TemplateDisplay
