Vue.component 'condition',
  props: ['condition', 'signature', 'status', 'lastCondition', 'primitiveAttributes']
  computed:
    type: ->
      return undefined unless @condition.attribute?
      Object.assign({name: 'String'}, @signature)[@condition.attribute]
    valueLabel: ->
      TypeValidator.valueLabel(@type, @condition.operator)
    availablePrimitiveAttributes: ->
      [attribute: label: 'name'].concat(@primitiveAttributes)
    availableOperators: ->
      TypeValidator.availableOperators(@type)
    valueValid: ->
      @status.valid = TypeValidator.valueValid(@condition.value, @type, @condition.operator)
  methods:
    remove: ->
      @$emit('remove-condition', @condition)
  watch:
    'condition.attribute': ->
      @condition.operator = '' unless (@condition.operator of @availableOperators)
  template: '
    <div class="flex-container center-items sub-form-group">
      <select class="styled-form-field" v-model="condition[\'attribute\']">
        <option disabled="" value="">select attribute</option>
        <option :value="attr.attribute.label" v-for="attr in availablePrimitiveAttributes">{{ attr.attribute.label }}</option>
      </select>&nbsp;
      <select class="styled-form-field" v-model="condition[\'operator\']">
        <option disabled="" value="">select operator</option>
        <option :value="operator" v-for="(text, operator) in availableOperators">{{ text }} ({{ operator }})</option>
      </select>&nbsp;
      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input :placeholder="valueLabel" class="form-control" type="text" v-model="condition[\'value\']">
      </div>&nbsp;
      <div v-if="!lastCondition" v-on:click="remove()" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>
    </div>
  '
