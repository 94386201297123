import DialogNode from 'models/dialog_node.coffee'
import GuidGenerator from 'models/guid_generator.coffee'
import Payload from 'models/payload.coffee'
import Verbalization from 'models/verbalization.coffee'

export default class Attachment
  @TYPES = [
    'Link'
    'Video'
    'Audio'
    'Image'
    'Document'
    'Text'
    'PostbackButton'
    'LinkButton'
    'QuickReplyButton'
    'AutogeneratedQuickReplyButtons'
    'FillSlotButton'
    'ProvideValueButton'
  ]

  @WEBVIEW_SIZES = [
    "default"
    "compact"
    "tall"
    "full"
  ]

  constructor: (@bubble, data = {}) ->
    @id = GuidGenerator.newGuid()
    @type = data.type
    @update(data)

  update: (data) ->
    @pristine = data.pristine
    switch @type
      when 'Link'
        @url = data.url || ''
        @text = data.text || ''
      when 'Video', 'Audio', 'Document'
        @path = data.path || ''
      when 'Image'
        @path = data.path || ''
        @ratio = data.ratio || 'UNKNOWN' # or 'VERTICAL' or 'HORIZONTAL' or 'QUADRATIC'
      when 'Text'
        @text = data.text || ''
        @textFormat = data.textFormat || 'PLAIN' # or 'MARKDOWN'
        @textRole = data.textRole || 'NONE' # or 'TITLE' or 'SUBTITLE'
      when 'PostbackButton', 'QuickReplyButton'
        @label = new Attachment(@bubble, data.label || {type: 'Text'})
        @payload = new Payload(@, data.payload || {})
      when 'FillSlotButton' # deprecated
        @label = new Attachment(@bubble, data.label || {type: 'Text'})
        @values = data.values || ['']
        @values.push('') if @values.length == 0
        @parameterKey = data.parameterKey
      when 'ProvideValueButton'
        @label = new Attachment(@bubble, data.label || {type: 'Text'})
        @updates = data.updates || [{values: ['']}]
      when 'LinkButton'
        @label = new Attachment(@bubble, data.label || {type: 'Text'})
        @url = data.url || ''
        @webviewSize = data.webviewSize || 'default' # "compact" | "default" | "tall" | "full"
        @webview = if data.webview? then data.webview else true
        @openedInNewTab = if data.openedInNewTab? then data.openedInNewTab else true
      when 'AutogeneratedQuickReplyButtons'
        @parameterKey = data.parameterKey
        @setMaxCount(data.maxCount)

  setButtonType: (type) ->
    return if !@isButton
    switch @type = type
      when 'PostbackButton', 'QuickReplyButton'
        @label ||= new Attachment(@bubble, {type: 'Text'})
        @payload ||= new Payload(@)
        delete @url
        delete @webviewSize
        delete @webview
        delete @openedInNewTab
        delete @values
        delete @maxCount
        delete @parameterKey
        delete @updates
      when 'LinkButton'
        @label ||= new Attachment(@bubble, {type: 'Text'})
        @url ||= ''
        @webviewSize ||= 'default'
        @webview = true if !@webview?
        @openedInNewTab = true if !@openedInNewTab?
        delete @payload
        delete @values
        delete @maxCount
        delete @parameterKey
        delete @updates
      when 'FillSlotButton' # deprecated
        @label ||= new Attachment(@bubble, {type: 'Text'})
        @values ||= ['']
        @values.push('') if @values.length == 0
        @parameterKey = ''
        delete @payload
        delete @url
        delete @webviewSize
        delete @webview
        delete @openedInNewTab
        delete @maxCount
        delete @updates
      when 'ProvideValueButton'
        @label ||= new Attachment(@bubble, {type: 'Text'})
        @updates ||= [{values: ['']}]
        delete @payload
        delete @url
        delete @webviewSize
        delete @webview
        delete @openedInNewTab
        delete @maxCount
        delete @parameterKey
      when 'AutogeneratedQuickReplyButtons'
        @setMaxCount()
        @parameterKey = if @host instanceof DialogNode && @host.moduleKey == 'ActiveLearning'
          'internal.dialog.disambiguationIntents'
        else if @host instanceof Verbalization
          @host.parameterKey + '#' + @host.contextInterface.contextParameter?.components[0].key
        else
          ''
        delete @label
        delete @url
        delete @webviewSize
        delete @webview
        delete @openedInNewTab
        delete @values
        delete @payload
        delete @updates

  setMaxCount: (maxCount) ->
    @maxCount = if maxCount?
      maxCount
    else if @message?.countOfAutogeneratedQuickrepliesCustomizable
      3
    else
      10

  addUpdate: ->
    return if @type != 'ProvideValueButton'
    @updates.push(values: [''])

  buttonPushUp: ->
    return unless @isButton
    index = @bubble.attachments.indexOf(@)
    attachmentsBefore = @bubble.attachments.slice(0, index)
    indexOfButtonBefore = index - 1 - attachmentsBefore.reverse().findIndex (a) -> a.isButton
    return if indexOfButtonBefore < 0
    [@bubble.attachments[index], @bubble.attachments[indexOfButtonBefore]] = [@bubble.attachments[indexOfButtonBefore], @bubble.attachments[index]]
  buttonPushDown: ->
    return unless @isButton
    index = @bubble.attachments.indexOf(@)
    attachmentsAfter = @bubble.attachments.slice(index + 1)
    indexOfButtonAfter = index + 1 + attachmentsAfter.findIndex (a) -> a.isButton
    return if indexOfButtonAfter == index
    [@bubble.attachments[index], @bubble.attachments[indexOfButtonAfter]] = [@bubble.attachments[indexOfButtonAfter], @bubble.attachments[index]]

  Object.defineProperties @prototype,
    message:
      get: ->
        @bubble?.message
    isButton:
      get: ->
        ['PostbackButton', 'QuickReplyButton', 'AutogeneratedQuickReplyButtons', 'LinkButton', 'FillSlotButton', 'ProvideValueButton'].includes(@type)
    pathIsUrl:
      get: ->
        @path? && @path.match("^#{String.urlRegexpString}$")?
    pathIsPlaceholder:
      get: ->
        @path? && /^\$\{.+\}$/.test(@path)
    isYoutubeVideo:
      get: ->
        return false unless @path
        !!@path.match(/^(https?\:\/\/)?(www\.)?(youtube(-nocookie)?\.com|youtu\.be)\/.+/)
    isVimeoVideo:
      get: ->
        return false unless @path
        !!@path.match(/^https?:\/\/(www\.)?(vimeo\.com|player\.vimeo\.com)\/.+/)
    isEmbeddedVideoPath:
      get: ->
        return unless @path
        @isYoutubeVideo || @isVimeoVideo
    videoEmbeddingPath:
      get: ->
        return unless @isEmbeddedVideoPath
        if @isYoutubeVideo
          parts = @path.match(new RegExp('(watch[?&]v=|embed/|youtu.be/)([a-z0-9_-]+)','i'))
          return "https://www.youtube-nocookie.com/embed/#{parts[2]}" if @path.includes('youtube-nocookie')
          "https://www.youtube.com/embed/#{parts[2]}"
        else if @isVimeoVideo
          parts = @path.match(new RegExp('/([0-9]{5,})','i'))
          "https://player.vimeo.com/video/#{parts[1]}"
    pathValid:
      get: ->
        @pathIsUrl || @pathIsPlaceholder
    urlValid:
      get: ->
        @url? && (
          @url? && /^\$\{.+\}$/.test(@url) || # placeholder
          @url.match("^#{String.urlRegexpString}$")? || # url
          /^mailto:[^\s@]+@[^\s@]+\.[^\s@]+$/.test(@url) || # mailto link
          @url.match(/^tel:\+?[\d\.\-\(\)]+$/)? # tel link
        )
    export:
      get: ->
        obj = {type: @type}
        switch @type
          when 'Link'
            obj.url = @url
            obj.text = @text
          when 'Video', 'Audio', 'Document'
            obj.path = @path
          when 'Image'
            obj.path = @path
            obj.ratio = @ratio
          when 'Text'
            obj.text = @text
            obj.textFormat = @textFormat
            obj.textRole = @textRole
          when 'PostbackButton', 'QuickReplyButton'
            obj.label = @label.export
            obj.payload = @payload.export
          when 'FillSlotButton' # deprecated
            obj.label = @label.export
            obj.values = @values
            obj.parameterKey = @parameterKey
          when 'ProvideValueButton'
            obj.label = @label.export
            obj.updates = @updates
          when 'LinkButton'
            obj.label = @label.export
            obj.url = @url
            obj.webviewSize = @webviewSize
            obj.webview = @webview
            obj.openedInNewTab = @openedInNewTab
          when 'AutogeneratedQuickReplyButtons'
            obj.maxCount = @maxCount
            obj.parameterKey = @parameterKey
        obj
    valid:
      get: ->
        result = switch @type
          when 'Link'
            @urlValid
          when 'Video', 'Audio', 'Document'
            @path?.length > 0
          when 'Image'
            @path?.length > 0 && ['UNKNOWN', 'VERTICAL', 'HORIZONTAL', 'QUADRATIC'].includes(@ratio)
          when 'Text'
            @text?.length > 0 &&
              ['PLAIN', 'MARKDOWN'].includes(@textFormat) &&
              ['NONE', 'TITLE', 'SUBTITLE', 'FOOTER', 'CTA'].includes(@textRole)
          when 'PostbackButton', 'QuickReplyButton'
            @label?.valid && @payload?.valid
          when 'FillSlotButton' # deprecated
            @label?.valid && @values?.length > 0
          when 'ProvideValueButton'
            @label?.valid && @updates?.length > 0
          when 'LinkButton'
            @label?.valid && @url?.length > 0
          when 'AutogeneratedQuickReplyButtons'
            @maxCount > 0 && @parameterKey?.length > 0
        !!result
    dialogModule:
      get: -> @bubble.dialogModule
    dialogNode:
      get: -> @bubble.dialogNode
    host:
      get: -> @bubble.host
