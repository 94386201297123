
  import BotIntent from 'models/bot_intent.coffee'

  import HintList from './hint_list.vue'
  import NLGEditor from 'components/botbuilder/nlg/nlg_editor.vue'
  import PrimingsEditor from 'components/botbuilder/primings_editor.vue'
  import { H3, Label, Listbox, Switch, Tabs } from 'components/generic'

  export default
    props:
      intent: BotIntent
      hints: Array

    emits: ['change']

    data: ->
      currentTab: 'message'
      debounceTimeout: null

    computed:
      tabs: ->
        [
          { id: 'message', name: 'Message' }
          { id: 'primings', name: 'Primings' }
          { id: 'hints', name: 'Hints & errors', badge: @hints.length }
        ]

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(
          => @intent.save().then => @$emit('change')
        , 1000
        )

    components:
      H3: H3
      HintList: HintList
      Label: Label
      Listbox: Listbox
      NLGEditor: NLGEditor
      PrimingsEditor: PrimingsEditor
      Switch: Switch
      Tabs: Tabs
