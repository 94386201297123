export default class IntegrationParameter
  constructor: (data = {}) ->
    data ||= {}
    @key = data.key || ''
    @label = data.label || ''
    @description = data.description || ''
    @defaultValue = data.defaultValue || ''
    @exampleValue = data.exampleValue || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        key: @key
        label: @label
        description: @description
        defaultValue: @defaultValue
        exampleValue: @exampleValue
