
  import Analytics from 'analytics.coffee'

  export default
    props:
      color:
        type: String
        default: 'default'
      active:
        type: Boolean
        default: false

    computed:
      colorClasses: ->
        switch @color
          when 'green'
            if @active
              'text-MercuryGreen-900 bg-MercuryGreen-200 hover:bg-MercuryGreen-200 focus:ring-MercuryGreen-300'
            else
              'text-MercuryGreen-900 bg-MercuryGreen-100 hover:bg-MercuryGreen-200 focus:ring-MercuryGreen-300'
          when 'gray'
            if @active
              'text-gray-900 bg-gray-200 hover:bg-gray-200 focus:ring-gray-300'
            else
              'text-gray-900 bg-white hover:bg-gray-200 focus:ring-gray-300'
          when 'yellow'
            if @active
              'text-gray-700 bg-MercuryYellow-200 hover:bg-MercuryYellow-200 focus:ring-MercuryYellow-300'
            else
              'text-gray-700 bg-MercuryYellow-100 hover:bg-MercuryYellow-200 focus:ring-MercuryYellow-300'
      classes: ->
        "inline-flex items-center justify-center font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-inset #{@colorClasses} text-sm px-4 py-2"

    methods:
      blur: ->
        @$refs.button.blur()
      track: ->
        try
          label = @$refs.button.innerText
          Analytics.track(
            'Button clicked',
            button_label: label,
            url: window.location.href,
            is_botcore_2: Globals.projectIsBotcore2,
            project_role: Globals.projectRole
          )
