
  import Message from 'models/inbox_message'
  import Attachment from './attachment.vue'

  export default
    props:
      bubble: Object
      message: Message
      isLastMessage:
        type: Boolean
        default: false

    emits: ['click']

    computed:
      attachments: ->
        attachmentTypes = @bubble.attachments.map((attachment) -> attachment.type?.toLowerCase()).sort()
        if Array.equal(attachmentTypes, ['payload', 'text'])
          return [
            type: 'postbackbutton'
            text: @bubble.attachments.find((attachment) -> attachment.type.toLowerCase() == 'text')
          ]
        @bubble.attachments

    components:
      Attachment: Attachment
