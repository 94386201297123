
  import ContextUpdate from 'models/context_update.coffee'
  import ConditionType from 'models/condition_type.coffee'
  import DialogTestElement from 'models/dialog_test_element.coffee'
  import Utterance from 'models/utterance.coffee'

  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import PayloadEditor from 'components/botbuilder/nlg/payload_editor.vue'
  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import UtteranceEditor from 'components/botbuilder/utterances/utterance_editor.vue'
  import { Button, Input, KeyListbox } from 'components/generic'

  export default
    props:
      modelValue: DialogTestElement

    data: ->
      element: @modelValue
      comparisonModeForSelect: [
        {key: 'INJECTIVE', label: 'at least the following messages must be present'}
        {key: 'BIJECTIVE', label: 'exactly the following messages must be present'}
      ]
      conditionTypesForSelect: [{label: 'Operator'}].concat(
        ConditionType.TYPES.map (type) -> {key: type, label: type}
      )
      operatorsForSelect: [{label: 'Operator'}].concat(
        ContextUpdate.OPERATOR_TYPES.map (operator) -> {key: operator, label: operator}
      )

    computed:
      componentOrContextParameter: ->
        @$root.componentOrContextParameter(@element.parameterKey)

    watch:
      modelValue: ->
        @element = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @element)
      addResponse: ->
        @element.responses.push(new Utterance())
      removeResponse: (index) ->
        @element.responses.splice(index, 1)
        @update()
      updateConditionType: ->
        @element.value = 'true' if @element.isUnary && !@element.value
        @update()

    components:
      Button: Button
      ContextParameterSelector: ContextParameterSelector
      Input: Input
      KeyListbox: KeyListbox
      PayloadEditor: PayloadEditor
      TargetEditor: TargetEditor
      UniversalInput: UniversalInput
      UtteranceEditor: UtteranceEditor
