
  import ParameterFilter from 'models/parameter_filter.coffee'
  import { H3, ParameterFiltering } from 'components/generic'

  export default
    props:
      modelValue: Object
      channels: Array
      contextParameters: Array

    data: ->
      filters: @modelValue

    computed:
      fixParameters: ->
        if @$root.isBotcore2
          [
            key: 'channel'
            label: 'Channel'
            type: 'Selection'
            valueType: 'Selection'
            collection: @channels.sort (a, b) -> a.name > b.name
            operator: '='
            fix: true
          ,
            key: 'wasInHandover'
            label: 'Had handover'
            valueType: 'bool'
            collectionType: 'none'
            operator: '='
            fix: true
          ,
            key: 'minNumberOfMessagesSent'
            label: 'Min. number of user messages'
            valueType: 'number'
            collectionType: 'none'
            operator: '='
            fix: true
          ]
        else
          [
            label: 'channel'
            humanizedLabel: 'Channel'
            type: 'Selection'
            collection: @channels.sort (a, b) -> a.name > b.name
            operator: '='
            fix: true
          ,
            label: 'wasInHandover'
            humanizedLabel: 'Had handover'
            type: 'Boolean'
            operator: '='
            fix: true
          ,
            label: 'minNumberOfMessagesSent'
            humanizedLabel: 'Min. number of user messages'
            type: 'Number'
            operator: '='
            fix: true
          ]
      availableParameters: ->
        @fixParameters.concat(
          @contextParameters
            .sort (a, b) ->
              labelA = a.humanizedLabel || a.label
              labelB = b.humanizedLabel || b.label
              return -1 if labelA < labelB
              return 1 if labelA > labelB
              0
        )

    watch:
      filters: ->
        @$emit('update:modelValue', @filters)
      modelValue: ->
        @filters = @modelValue

    components:
      H3: H3
      ParameterFiltering: ParameterFiltering
