import GuidGenerator from 'models/guid_generator.coffee'

export default class WorkflowOperationInputBinding
  constructor: (data = {}) ->
    @id = GuidGenerator.newGuid()
    @workflowVariableKey = data.workflowVariableKey || ''
    @value = data.value || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        workflowVariableKey: @workflowVariableKey
        value: @value
