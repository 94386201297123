
import BotCreator from 'components/botbuilder/bot_creator.vue'
import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
import { Button } from 'components/generic'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

stages = (projectId) ->
  return [] unless projectId?
  [
    label: 'dev',
    description: 'Develop your bot without risking disruptions of customer experience.',
    href: Routes.project_path(projectId, 'dev'),
    inactiveClass: "border-MercuryBlue-200 text-MercuryBlue-200 hover:bg-MercuryBlue-200 hover:text-MercuryBlue-800",
    activeClass: "border-MercuryBlue-200 bg-MercuryBlue-200 text-MercuryBlue-600 hover:text-MercuryBlue-800",
    menuClasses: "border-MercuryBlue-200 text-MercuryBlue-200",
  ,
    label: 'test',
    description: 'Test your bot in this QA environment and promote it when ready.',
    href: Routes.project_path(projectId, 'test'),
    inactiveClass: "border-MercuryYellow-200 text-MercuryYellow-200 hover:bg-MercuryYellow-200 hover:text-MercuryYellow-800",
    activeClass: "border-MercuryYellow-200 bg-MercuryYellow-200 text-MercuryYellow-600 hover:text-MercuryYellow-800",
    menuClasses: "border-MercuryYellow-200 text-MercuryYellow-200",
  ,
    label: 'live',
    description: 'Your production environment where bot and live-agents work hand in hand.',
    href: Routes.project_path(projectId, 'live'),
    inactiveClass: "border-MercuryGreen-200 text-MercuryGreen-200 hover:bg-MercuryGreen-200 hover:text-MercuryGreen-800",
    activeClass: "border-MercuryGreen-200 bg-MercuryGreen-200 text-MercuryGreen-600 hover:text-MercuryGreen-800",
    menuClasses: "border-MercuryGreen-200 text-MercuryGreen-200",
  ]

export default
  props: ['projects']

  data: ->
    stages: stages(Globals.projectId)
    currentProjectId: Globals.projectId
    currentProjectName: Globals.projectName
    currentStageLevel: Globals.stageLevel

  computed:
    projectHomeUrl: ->
      return Routes.root_path() unless @currentProjectId?
      Routes.project_path(@currentProjectId, @currentStageLevel || 'live')
    currentStage: ->
      @stages.find((stage) => stage.label == @currentStageLevel) || {}
    mayAddProject: ->
      Globals.organizationRestrictions.projects > @projects.length

  created: ->
    @set()
    $(window).on 'turbolinks:load', => @set()

  methods:
    set: ->
      @currentStageLevel = Globals.stageLevel
      @currentProjectId = Globals.projectId
      @currentProjectName = Globals.projectName || 'no project'
      @stages = stages(@currentProjectId)
    goTo: (closeFunction, options = {}) ->
      @currentStageLevel = options.stageLevel if options.stageLevel?
      if options.project?
        @currentProjectName = options.project.name
        @currentStageLevel = options.project.stage_level
      closeFunction()

  components:
    BotCreator: BotCreator
    Button: Button
    ChevronDownIcon: ChevronDownIcon
    Popover: Popover
    PopoverButton: PopoverButton
    PopoverPanel: PopoverPanel
    UpgradePrompt: UpgradePrompt
