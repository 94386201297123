class window.TypeValidator

  @numberRegexpString: '\\-?\\d+(\\.\\d+)?'
  @stringRegexpString: '.*'
  @dateRegexpString: '\\d{4}-(0[1-9]|1[0-2])-([0-2][1-9]|3[01])'
  @timeRegexpString: '([01]\\d|2[0-3]):([0-5]\\d)'

  @typeProperties:
    Number:
      validationType: 'minmax'
    NumberWithUnit:
      validationType: 'minmax'
    Date:
      validationType: 'minmax'
    Time:
      validationType: 'minmax'
    URI:
      validationType: 'regex'
    String:
      validationType: 'regex'
    Boolean:
      validationType: null
    GeoLocation:
      validationType: null

  @availableTypes: Object.keys(@typeProperties)

  @valueType: (type) ->
    return undefined unless type # no "?" appended, so type == '' will trigger this, too
    if type instanceof Object
      return undefined unless type.type
      type.type
    else
      type.match(/^(List_)?(Quantified_)?(.+)$/)[3]

  @isQuantifiedType: (type) ->
    return undefined unless type # no "?" appended, so type == '' will trigger this, too
    if type instanceof Object
      return undefined unless type.type
      type.type.match(/Quantified_/)
    else
      type.match(/Quantified_/)

  @valueTypeOrQuantified: (type) ->
    return 'Quantified' if @isQuantifiedType(type)
    @valueType(type)

  @isListType: (type) ->
    return undefined unless type # no "?" appended, so type == '' will trigger this, too
    if type instanceof Object
      return undefined unless type.list?
      type.list
    else
      type.match(/^List_/)

  @typeLabel: (type, operator) ->
    if operator == '<>'
      'Interval'
    else if @valueType(type) in @availableTypes
      @valueType(type)
    else
      null

  @valueLabel: (type, operator) ->
    return 'none' if operator == 'clear'
    typeLabel = @typeLabel(type, operator)
    return 'value' unless typeLabel?
    if @isQuantifiedType(type)
      typeLabel = "Amount of #{typeLabel}"
    if operator == 'set' && @isListType(type)
      typeLabel = "List of #{typeLabel}"
    "value (#{typeLabel})"

  @availableOperators: (type) ->
    return {} unless @valueType(type)?
    if @isListType(type)
      '+': 'contains'
      '-': 'contains not'
    else
      switch @valueTypeOrQuantified(type)
        when 'Number', 'NumberWithUnit', 'Quantified'
          '=': 'equals'
          '!=': 'does not equal'
          '<': 'less'
          '>': 'greater'
          '<=': 'less or equals'
          '>=': 'greater or equals'
          '<>': 'in interval'
        when 'Date', 'Time'
          '=': 'equals'
          '!=': 'does not equal'
          '<': 'before'
          '>': 'after'
          '<=': 'before or equals'
          '>=': 'after or equals'
          '<>': 'in interval'
        when 'GeoLocation', 'String'
          '=': 'equals'
          '!=': 'does not equal'
          '~': 'similar to'
          '!~': 'not similar to'
        else
          '=': 'equals'
          '!=': 'does not equal'

  @defaultOperators: ->
    '=': 'equals'
    '!=': 'does not equal'

  @isSetOperator: ->
    '?': 'is set'

  @availableUpdateOperators: (type) ->
    operators = ['set', 'clear']
    return operators unless @valueType(type)?
    return operators.concat('add', 'delete') if @isListType(type)
    return operators.concat('increase', 'decrease') if @valueType(type) == 'Number'
    operators

  @valueValid: (value, type, operator, acceptLogicalExpressions = true) ->
    return true unless type?
    return true if operator == 'clear'
    return !!value if type == 'Selection'
    literalRegexpString = switch @valueTypeOrQuantified(type)
      when 'Number' then @numberRegexpString
      when 'NumberWithUnit', 'Quantified' then "#{@numberRegexpString}.*"
      when 'Date' then @dateRegexpString
      when 'Time' then @timeRegexpString
      when 'URI' then "#{String.uriRegexpString}|\"#{String.uriRegexpString}\""
      when 'bareURL' then String.urlRegexpString
      when 'String', 'LocalizedString' then @stringRegexpString
      when 'Boolean' then '(true|false)'
      when 'GeoLocation' then "\\(#{@numberRegexpString},\\s*#{@numberRegexpString}\\)"
      else '.+'
    # accept only ranges for <>
    if operator == '<>'
      literalRegexpString = "#{literalRegexpString}\\.{2}\\.?#{literalRegexpString}"
    else if operator == 'set' && @isListType(type)
      literalRegexpString = "\\[#{literalRegexpString}(,\\s*#{literalRegexpString})*\\]"
    if acceptLogicalExpressions
      # accept simple logical expressions with & and |
      value.trim().match("^#{literalRegexpString}(\\s*(&|\\|)\\s*#{literalRegexpString})*$")?
    else
      value.trim().match("^#{literalRegexpString}$")?
