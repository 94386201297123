
  import Card from './card.vue'
  import { ChevronDownIcon } from '@heroicons/vue/outline'

  export default
    props:
      title: String
      collapsed:
        type: Boolean
        default: true
      color:
        type: String
        default: 'green'
      draggable:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      isOpen: !@collapsed

    methods:
      open: ->
        @isOpen = true
      close: ->
        @isOpen = false

    components:
      Card: Card
      ChevronDownIcon: ChevronDownIcon
