import PlatformApi2 from 'models/platform_api2'

export default class Secret
  @TYPES = [
    {key: 'BearerToken', label: 'Bearer token'}
    {key: 'BasicAuth', label: 'Basic authentication'}
  ]

  @list: ->
    PlatformApi2.getSecrets()
      .then (data) -> (data || []).map (secretData) -> new Secret(secretData)

  constructor: (data = {}) ->
    data ||= {}
    @id = data.id || ''
    @label = data.label || ''
    @description = data.description || ''
    @type = data.type || 'BearerToken'
    switch @type
      when 'BearerToken'
        @token = data.token || ''
      when 'BasicAuth'
        @username = data.username || ''
        @password = data.password || ''
    @timeCreated = data.timeCreated
    @timeLastUsed = data.timeLastUsed

  create: ->
    PlatformApi2.createSecret(@)
      .then (data) =>
        @id = data.id
        @token = data.token

  delete: ->
    PlatformApi2.deleteSecret(@)

  Object.defineProperties @prototype,
    typeLabel:
      get: ->
        (@constructor.TYPES
          .find (type) => type.key == @type
          ?.label
        ) || @type
    timeLastUsedFormatted:
      get: ->
        return '' if !@timeLastUsed
        moment(@timeLastUsed).format(t('formats.dateTime'))
    export:
      get: ->
        obj =
          label: @label
          description: @description
          type: @type
        switch @type
          when 'BearerToken'
            obj.token = @token
          when 'BasicAuth'
            obj.username = @username
            obj.password = @password
        obj
