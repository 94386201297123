
  import Bot from 'models/bot.coffee'
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import DialogTest from 'models/dialog_test.coffee'
  import { prompt } from 'helpers'

  import TestTableRow from './test_table_row.vue'
  import { Button, Checkbox, DocumentationLink, PageHeader, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { PlayIcon, PlusIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      tests: []
      allSelected: false
      creatingTest: false
      PlayIcon: PlayIcon
      RefreshIcon: RefreshIcon

    computed:
      testsRunning: ->
        @tests.some (test) -> test.status == 'RUNNING'
      runnable: ->
        !@testsRunning && @tests.some((test) -> test.selected)

    created: ->
      BotbuilderApi.getDialogTests()
        .then (tests) =>
          @tests = tests.sortByString('label')

    methods:
      addTest: ->
        @creatingTest = true
        newTestLabel = await prompt('Please enter a label for the new dialog test:', '')
        if newTestLabel == ''
          @creatingTest = false
          return
        newTest = new DialogTest(
          label: newTestLabel
          language: @$root.currentLanguage
        )
        newTest
          .save()
          .then => @$router.push(name: 'test', params: {testId: newTest.id})
      updateListWith: (test) ->
        return if @tests.find (t) -> t.id == test.id
        @tests.push(test)
        @tests = @tests.sortByString('label')
      removeTest: (test) ->
        test.delete().then =>
          @tests = @tests.filter (t) => t.id != test.id
      selected: (index, value) ->
        @allSelected = false if !value
      selectAll: ->
        # wait for @allSelected to be changed
        setTimeout =>
          @tests.forEach (test) =>
            test.selected = @allSelected
        , 0
      runSelected: ->
        @tests.forEach (test) =>
          test.run() if test.selected

    components:
      Button: Button
      Checkbox: Checkbox
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      TestTableRow: TestTableRow
