
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Bot from 'models/bot.coffee'
  import Workflow from 'models/workflow.coffee'
  import WorkflowTrigger from 'models/workflow_trigger.coffee'
  import { confirm } from 'helpers'

  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { ActionMenu, Button, DocumentationLink, PageHeader, Switch, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      bot: Bot

    data: ->
      workflows: []
      PlusIcon: PlusIcon

    computed:
      mayAddWorkflow: ->
        Globals.restrictions.workflows > @workflows.length

    created: ->
      BotbuilderApi.getWorkflows()
        .then (workflows) =>
          @workflows = workflows.sortByString('timeCreated')

    methods:
      createWorkflow: ->
        @$router.push(name: 'newWorkflow')
      actionMenuItems: (workflow) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(name: 'workflow', params: {workflowId: workflow.id})}
          {icon: XIcon, label: 'Delete', method: => @remove(workflow)}
        ]
      remove: (workflow) ->
        return unless await confirm("Do you really want to delete workflow “#{workflow.label}”?")
        workflow.delete().then =>
          @workflows = @workflows.filter (wf) -> wf.id != workflow.id

    components:
      ActionMenu: ActionMenu
      Button: Button
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Switch: Switch
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      UpgradePrompt: UpgradePrompt
