import MessageVariation from 'models/message_variation.coffee'

export default class LanguageNLG
  constructor: (@nlg, data = {}) ->
    data ||= {}
    @nlgPerMedium = {}
    Object.entries(data).forEach ([medium, variations]) =>
      @nlgPerMedium[medium] = variations.map (variationData) =>
        new MessageVariation(@, Object.assign({medium: medium}, variationData))

  forMedium: (medium) ->
    @nlgPerMedium[medium] ||= [new MessageVariation(@, medium: medium)]
    @nlgPerMedium[medium]

  Object.defineProperties @prototype,
    export:
      get: ->
        obj = {}
        Object.entries(@nlgPerMedium).forEach ([medium, variations]) ->
          obj[medium] = variations.map (variation) -> variation.export
        obj
    messages:
      get: ->
        Object.values(@nlgPerMedium).map (variations) ->
          variations.map (variation) -> variation.messages
        .flat(3)
    buttons:
      get: ->
        @messages.map((message) -> message.buttons).flat()
