import Agent from 'models/agent'
import AgentTeam from 'models/agent_team'
import Conversation from 'models/conversation'

Vue.component 'inbox2-agent-team-member-selection',
  props:
    conversation: Conversation
    team: AgentTeam

  data: ->
    isOpen: false
    selectedAgentId: ''
    handoverAgents: []

  computed:
    agents: ->
      [
        new Agent(
          id: null
          name: t('inbox.conversations.defaultAgentName')
          firstName: t('inbox.conversations.defaultAgentFirstname')
          lastName: t('inbox.conversations.defaultAgentLastname')
        )
      ].concat @handoverAgents

  created: ->
    @$root.api.loadHandoverAgents(@team.id)
      .then (agentsData) => @handoverAgents = agentsData.map (data) -> new Agent(data)
      .catch => @handoverAgents = []

  watch:
    selectedAgentId: ->
      return if @selectedAgentId == ''
      @$emit('selected-agent', @team.id, @selectedAgentId)

  methods:
    toggle: ->
      @isOpen = !@isOpen
      $(@$el).find('.collapse').collapse('toggle')
    collapse: ->
      @isOpen = false
      $(@$el).find('.collapse').collapse('hide')

  template: '
    <li>
      <a @click.stop="toggle" class="flex-container center-items" :aria-expanded="isOpen ? \'true\' : \'false\'">
        {{ team.name }}
        <span class="select-agent auto-margin-left">{{ t(\'inbox.conversations.selectAgent\') }}</span>
        <span class="chevron"></span>
      </a>
      <div class="collapse" @click.stop="">
        <label v-for="agent in agents" class="agent" :class="{online: agent.online}">
          <radiobutton type="radio" v-model="selectedAgentId" :value-value="agent.id"/>
          <div class="avatar-image">{{ agent.initialsFromList }}</div>
          <span class="ellipsis">{{ agent.name }}</span>
        </label>
      </div>
    </li>
  '
