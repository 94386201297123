import WidgetTeaserButtonPayload from 'models/widget_teaser_button_payload.coffee'

export default class WidgetTeaserButton
  constructor: (data = {}) ->
    @text = data.text || ''
    @payload = data.payload || {}

  Object.defineProperties @prototype,
    export:
      get: ->
        text: @text
        payload: @payload
