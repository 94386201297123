export default class IntegrationParameterMapping
  constructor: (data = {}) ->
    data ||= {}
    @key = data.key || ''
    @jsonPath = data.jsonPath || ''
    @defaultValue = data.defaultValue || ''

  Object.defineProperties @prototype,
    export:
      get: ->
        key: @key
        jsonPath: @jsonPath
        defaultValue: @defaultValue
