
  import Attachment from 'models/attachment'
  import Message from 'models/inbox_message'
  import marked from 'marked'
  import { Button } from 'components/generic'
  import { DocumentIcon } from '@heroicons/vue/outline'
  import { LocationMarkerIcon } from '@heroicons/vue/solid'

  export default
    name: 'Attachment'

    props:
      attachment: Object
      message: Message
      isLastMessage:
        type: Boolean
        default: false

    emits: ['click']

    computed:
      type: ->
        type = if @attachment.type?.toLowerCase() == 'button' # for message as defined in message editor
          "#{@attachment.buttonType}button"
        else if @attachment.type
          @attachment.type.toLowerCase()
        else
          'text'
      renderedMarkdown: ->
        marked(@attachment.text)
      isEmbeddedVideoPath: ->
        (@attachment.url || @attachment.path).match(/^(https?\:\/\/)?(www\.)?youtube\.com\/embed\/.+/) ||
          (@attachment.url || @attachment.path).match(/^(https?\:\/\/)?player\.vimeo\.com\/video\/.+/)
      isInstagramStoryMention: ->
        @attachment.url.startsWith('https://lookaside.fbsbx.com/ig_messaging_cdn') && @message && (moment() - moment(@message.timestamp)) / (60*60*1000) < 24
      attachmentAsAttachmentModel: ->
        new Attachment(null, @attachment)

    components:
      Button: Button
      DocumentIcon: DocumentIcon
      LocationMarkerIcon: LocationMarkerIcon
