import { confirm } from 'helpers'

Vue.component 'subscriptions-game',
  props: ['config']
  data: ->
    state: state
    currentSubscription: null
    subscriptionListToBeAdded: null
  watch:
    config:
      handler: -> @setLanguageStates()
      deep: true
  created: ->
    Vue.setDefault(@config, 'subscriptions', [])
    @setLanguageStates()
  methods:
    setLanguageStates: ->
      @$parent.setLanguageStates(
        [
          'subscriptions.subscribingUtterances',
          'subscriptions.unsubscribingUtterances',
          'subscriptions.invitation',
          'subscriptions.subscriptionConfirmation',
          'subscriptions.cancellationConfirmation',
        ]
      )
    edit: (index) ->
      @currentSubscription = @config.subscriptions[index]
    add: ->
      @config.subscriptions.push(
        subscriptionListId: @subscriptionListToBeAdded.uuid
        subscriptionListName: @subscriptionListToBeAdded.name
        $key: GuidGenerator.newGuid()
      )
      @subscriptionListToBeAdded = null
    remove: (index) ->
      return unless await confirm("Do you really want to remove the subscription for #{@config.subscriptions[index].subscriptionListName}?")
      @currentSubscription = null if @currentSubscription == @config.subscriptions[index]
      @config.subscriptions.splice(index, 1)
    removeMethod: (index) ->
      => @remove(index)
  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Available subscriptions</h3>
      </div>
      <div class="panel-body">
        <p>
          Select a publishing list the user can subscribe to:
        </p>
        <table class="table">
          <tbody>
            <tr v-for="subscription, index in config.subscriptions">
              <td>{{ subscription.subscriptionListName }}</td>
              <td class="text-right">
                <div class="btn-icon-cell-2">
                  <button @click="edit(index)" class="btn btn-icon">
                    <span class="fa fa-pen"></span>
                  </button>
                  <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete\', method: removeMethod(index)}]"/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-group flex-container">
          <select class="styled-form-field" v-model="subscriptionListToBeAdded">
            <option :value="null" selected="" disabled="">Select a subscription list</option>
            <option :value="subscriptionList" v-for="subscriptionList in state.broadcastPlans">{{ subscriptionList.name }}</option>
          </select>
          &nbsp;
          <div class="btn btn-primary" v-on:click="add()">add</div>
        </div>
      </div>

      <subscription-config v-if="currentSubscription" :subscription="currentSubscription" :key="currentSubscription.$key"/>
    </div>
  '
