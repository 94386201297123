
  import Message from 'models/message'

  import ButtonEditor from './button_editor.vue'
  import ButtonMover from './button_mover.vue'
  import MediaAttachmentEditor from './media_attachment_editor.vue'
  import Slick from 'components/shared/slick.vue'
  import TextAttachmentEditor from './text_attachment_editor.vue'
  import { Button } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Message
      medium: String

    emits: ['update:modelValue']

    data: ->
      message: @modelValue
      sliderOptions:
        infinite: false
        accessibility: false # disables arrow key navigation (we need the arrow keys in the input fields)
        swipe: false
      currentSlide: 0
      noWatcher: false
      renderSlider: false
      titleEditorRefs: []

    computed:
      mayAddSlide: ->
        return true if !@message.valueFor('maxSlides')?
        @message.bubbles.length < @message.valueFor('maxSlides')

    watch:
      modelValue: ->
        @message = @modelValue
      '$root.currentMedium': ->
        # make sure slider is rendered correctly when becoming visible
        @refreshSlider(@currentSlide) if @medium == @$root.currentMedium

    mounted: ->
      @sliderOptions.prevArrow = @$refs.prev
      @sliderOptions.nextArrow = @$refs.next
      @renderSlider = true

    beforeUpdate: ->
      @titleEditorRefs = []

    methods:
      addButton: (bubble) ->
        bubble.addButton()
      removeButton: (bubbleIndex, buttonIndex) ->
        @message.bubbles[bubbleIndex].attachments.splice(buttonIndex + 3, 1)
        @update()
      addBubble: ->
        @message.addSlide(@currentSlide)
        @update()
        @refreshSlider(@currentSlide + 1)
        @$nextTick =>
          @$nextTick =>
            @focus()
      removeBubble: (index) ->
        @message.bubbles.splice(index, 1)
        @update()
        @$nextTick =>
          @refreshSlider(Math.min(index, @message.bubbles.length - 1))
      update: ->
        @$emit('update:modelValue', @message)
      refreshSlider: (initialSlide) ->
        @renderSlider = false
        @currentSlide = initialSlide
        @sliderOptions.initialSlide = @currentSlide
        @$nextTick =>
          @renderSlider = true
      sliderChange: (event, slick) ->
        try
          @currentSlide = @$refs.slider.currentSlide()
      focus: ->
        return if !@titleEditorRefs[@currentSlide]?
        @titleEditorRefs[@currentSlide].focus()
      setTitleEditorRef: (el) ->
        @titleEditorRefs.push(el) if el?

    components:
      Button: Button
      ButtonEditor: ButtonEditor
      ButtonMover: ButtonMover
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      MediaAttachmentEditor: MediaAttachmentEditor
      Slick: Slick
      TextAttachmentEditor: TextAttachmentEditor
