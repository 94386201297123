
  import Bot from 'models/bot'

  import VersionTable from './version_table.vue'
  import { PageHeader } from 'components/generic'

  export default
    props:
      bot: Bot

    created: ->
      @$root.appData.versions ||= {}
      @$root.appData.versions.page ||= 0

    components:
      VersionTable: VersionTable
      PageHeader: PageHeader
