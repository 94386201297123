
  import WebChannel from 'models/web_channel.coffee'

  import MenuEditor from '../menu_editor.vue'
  import { H3, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'

  export default
    props:
      modelValue: WebChannel

    data: ->
      channel: @modelValue
      cookieType: 'noSession'

    created: ->
      @cookieType = if @channel.widgetConfig.cookieLifetime == 'session'
        'session'
      else
        'noSession'

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      setCookieLifetime: ->
        @channel.widgetConfig.cookieLifetime = if @cookieType == 'session'
          'session'
        else
          WebChannel.DEFAULT_COOKIE_LIFETIME
        @update()

    components:
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      MenuEditor: MenuEditor
      Switch: Switch
      Textarea: Textarea
