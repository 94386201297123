import GuidGenerator from 'models/guid_generator.coffee'
import Operation from 'models/operation.coffee'

export default class OperationalHook
  constructor: (@contextInitiative, data = {}) ->
    @id = GuidGenerator.newGuid()
    @hookKey = data.hookKey
    @operations = (data.operations || []).map (operationData) => new Operation(@, operationData)
    if @operations.length == 0
      @operations.push(new Operation(@, type: 'ContextUpdate', parameterKey: @parameterKey))

  Object.defineProperties @prototype,
    contextInterface:
      get: ->
        @contextInitiative?.contextInterface
    parameterKey:
      get: ->
        @contextInterface?.parameterKey
    operation:
      get: ->
        @operations[0]
    export:
      get: ->
        hookKey: @hookKey
        operations: @operations.map (operation) -> operation.export
