
  import DialogTestElement from 'models/dialog_test_element.coffee'

  import { Button } from 'components/generic'
  import { XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      element: DialogTestElement
      failed: Boolean

    emits: ['remove', 'click']

    data: ->
      contextParameter: null
      component: null

    computed:
      wrapperClasses: ->
        classes = switch @element.type
          when 'InvokeWithTextMessage', 'InvokeWithPayload', 'InvokeWithButtonClick'
            'self-end items-end'
          when 'QueryResponseMessage', 'QueryResponseIntent', 'QueryContext'
            'self-start items-start'
          when 'UpdateContext', 'Reset', 'Comment', 'QueryInterpretedIntent'
            'self-center items-center'
        classes + if @failed then ' border border-2 border-MercuryRed-500 p-2 rounded-2xl' else ''
      innerClasses: ->
        switch @element.type
          # UI category "user"
          when 'InvokeWithTextMessage', 'InvokeWithButtonClick'
            'text-left text-MercuryGreen-900 bg-MercuryGreen-100 rounded-2xl rounded-tr-none'
          when 'InvokeWithPayload'
            'text-left text-MercuryGreen-900 bg-white border-2 border-dashed border-MercuryGreen-300 rounded-2xl rounded-tr-none'
          # UI category "bot"
          when 'QueryResponseMessage'
            'text-left text-gray-900 bg-gray-100 rounded-2xl rounded-tl-none'
          when 'QueryResponseIntent'
            'text-left text-gray-900 bg-white border-2 border-dashed border-gray-300 rounded-2xl rounded-tl-none'
          when 'QueryContext'
            'text-left text-gray-700 bg-gray-100 rounded-2xl rounded-tl-none font-mono'
          # UI category "system"
          when 'QueryInterpretedIntent'
            'text-center text-gray-900 bg-white border-2 border-dashed border-MercuryYellow-400 rounded-md'
          when 'UpdateContext'
            'text-center text-gray-700 bg-MercuryYellow-100 rounded-md font-mono'
          when 'Reset'
            'text-center text-gray-700 bg-MercuryYellow-100 italic rounded-md'
          when 'Comment'
            'text-center text-gray-600 bg-MercuryYellow-50 italic rounded-md'

    created: ->
      if ['QueryContext', 'UpdateContext'].includes(@element?.type)
        @contextParameter = @$root.contextParameterByKey(@element.parameterKey)
        @component = @$root.componentByKey(@element.parameterKey)

    methods:
      remove: ->
        @$emit('remove')

    components:
      Button: Button
      XCircleIcon: XCircleIcon
