
  import Attachment from 'models/attachment.coffee'
  import TextAttachmentEditor from './text_attachment_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Input } from 'components/generic'
  import { FilmIcon, PencilAltIcon, PhotographIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Attachment

    emits: ['update:modelValue']

    data: ->
      attachment: @modelValue
      editing: false
      typeButtons: [
        {type: 'Text', icon: PencilAltIcon}
        {type: 'Image', icon: PhotographIcon}
        {type: 'Video', icon: FilmIcon}
      ]

    computed:
      isBlank: ->
        @attachment.type == 'Text' && @attachment.text?.length == 0 ||
          @attachment.path?.length == 0

    watch:
      modelValue: ->
        @attachment = @modelValue
      editing: ->
        @focus() if @editing

    created: ->
      @editing = if @isBlank then true else false

    methods:
      setType: (type) ->
        @attachment.type = type
        if type == 'Text'
          @attachment.text ?= ''
          @attachment.textRole = 'TITLE'
          delete @attachment.path
        else
          @attachment.path = ''
          delete @attachment.text
          delete @attachment.textRole
        @update()
        @focus()
      reset: ->
        @setType('Text')
        @attachment.text = ''
        @update()
        @editing = true
      endEditing: ->
        return if @isBlank
        @editing = false
        @attachment.pristine = false
        @attachment.bubble.message.pristine = false
        @update()
      update: ->
        @$emit('update:modelValue', @attachment)
      focus: ->
        @$nextTick => @$refs.input?.focus()

    components:
      Input: Input
      TextAttachmentEditor: TextAttachmentEditor
      UniversalInput: UniversalInput
      XCircleIcon: XCircleIcon
