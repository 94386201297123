
  import InstagramChannel from 'models/instagram_channel.coffee'

  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { H3, Label, Switch } from 'components/generic'

  export default
    props:
      modelValue: InstagramChannel

    data: ->
      channel: @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @channel)

    components:
      H3: H3
      Label: Label
      Switch: Switch
      TargetEditor: TargetEditor
