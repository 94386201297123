
  import ContextInterface from 'models/context_interface.coffee'
  import ContextParameter from 'models/context_parameter'
  import DialogNode from 'models/dialog_node.coffee'
  import KnowledgeSource from 'models/knowledge_source.coffee'
  import WorkflowStep from 'models/workflow_step.coffee'
  import WorkflowTrigger from 'models/workflow_trigger'
  import ValueType from 'models/value_type.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import DataTypeDependentInput from './data_type_dependent_input.vue'
  import VariableSelector from './variable_selector.vue'
  import { Button, Combobox, Input, Label } from 'components/generic'
  import { TerminalIcon } from '@heroicons/vue/outline'

  staticValueReference = 'Enter a static value using a data type-specific input field'
  expressionReference = 'Enter a free text value that may contain smart expressions'
  fixedItems = [
    {label: 'Enter value', reference: staticValueReference}
    {label: 'Enter smart expression', reference: expressionReference}
    {label: '-----'}
    {label: '# Select parameter #'}
  ]

  export default
    props:
      modelValue: String
      valueType: String
      collectionType: String
      entitySchemaKey: String
      contextParameter: ContextParameter
      label: String
      placeholder: String
      host: [DialogNode, KnowledgeSource, WorkflowStep, WorkflowTrigger, ContextInterface, Verbalization]
      resourceTypes:
        type: Array
        default: ['ContextParameter']

    emits: ['update:modelValue', 'blur']

    data: ->
      value: @modelValue
      comboboxString: null
      fixedItems: fixedItems
      mode: null
      isOpen: false

    watch:
      modelValue: ->
        @value = @modelValue
      valueType: ->
        @setInitialMode()

    created: ->
      @setInitialMode()

    methods:
      setInitialMode: ->
        if !@value
          @mode = 'static'
        else if @value.match(/^\$\{(.*?)\}$/)
          if @value.match(/^\$\{(\S*?)\}$/)
            @setModeCombobox()
          else
            @mode = 'free'
        else if @valueType == 'bool' && ['true', 'false'].includes(@value)
          @mode = 'static'
        else if ValueType.isNumber(@valueType) && !isNaN(@value) && !isNaN(parseFloat(@value))
          @mode = 'static'
        else if @valueType == 'date' && @value.match(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d/)
          @mode = 'static'
        else if @valueType == 'entity' && @value.match(/\S+\/\S+/)
          @mode = 'static'
        else if @valueType == 'knowledgeSource' && @value.isUuid
          @mode = 'static'
        else if @valueType == 'language' && Object.keys(@$root.languages || {}).includes(@value)
          @mode = 'static'
        else
          @mode = 'free'
      setModeCombobox: (focus = false) ->
        @comboboxString = if match = @value.match(/^\$\{(.*?)\}$/)
          match[1]
        else
          @value
        @mode = 'combobox'
        return unless focus
        @$nextTick =>
          @$refs.combobox.open()
      focus: ->
        if @$refs.combobox?
          @$refs.combobox.focus()
        else if @$refs.input?
          @$refs.input.focus()
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$el)
        @$emit('blur')
      focusout: (e) ->
        setTimeout =>
          return if @$el.contains(document.activeElement)
          @$emit('blur')
        , 0
      update: ->
        @$emit('update:modelValue', @value)
      comboboxUpdated: ->
        switch @comboboxString
          when staticValueReference
            @mode = 'static'
            @$nextTick =>
              @$refs.input.focus()
          when expressionReference
            @mode = 'free'
            @$nextTick =>
              @$refs.input.focus()
          else
            @value = "${#{@comboboxString}}"
            @update()

    components:
      Button: Button
      DataTypeDependentInput: DataTypeDependentInput
      Input: Input
      Label: Label
      TerminalIcon: TerminalIcon
      VariableSelector: VariableSelector
