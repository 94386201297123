
  import { H3 } from 'components/generic'

  # just listed here so the classes are generated
  colorClasses = [
    'text-gray-700', 'bg-gray-50',
    'text-MercuryGrey-700', 'bg-MercuryGrey-50',
    'text-MercuryBlue-700', 'bg-MercuryBlue-50',
    'text-MercuryGreen-700', 'bg-MercuryGreen-50',
    'text-MercuryYellow-700', 'bg-MercuryYellow-50',
    'text-MercuryRed-700', 'bg-MercuryRed-50',
    'text-Skobeloff-700', 'bg-Skobeloff-50',
    'text-Avocado-700', 'bg-Avocado-50',
    'text-CornellRed-700', 'bg-CornellRed-50',
    'text-MagentaViolet-700', 'bg-MagentaViolet-50',
  ]

  export default
    props:
      tile: Object
      index: Number

    created: ->
      return if !@tile.icon
      try
        @[@tile.icon] = require("@heroicons/vue/outline/#{@tile.icon}.js")

    computed:
      isOdd: ->
        @index % 2 == 0
      isNotLast: ->
        @index < @$root.infoTiles.length - 1
      isNotLastRow: ->
        Math.floor(@index / 2) + 1 < Math.ceil(@$root.infoTiles.length / 2)
      iconComponent: ->
        @[@tile.icon]
      iconForegroundClass: ->
        "text-#{@tile.color}-700"
      iconBackgroundClass: ->
        "bg-#{@tile.color}-50"

    components:
      H3: H3
