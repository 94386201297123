
  export default
    props:
      colSpan:
        type: Number
        default: 1

    computed:
      colSpanClass: ->
        switch @colSpan
          when 1 then 'col-span-1'
          when 2 then 'col-span-2'
          when 3 then 'col-span-3'
          when 4 then 'col-span-4'
