
  import BotIntent from 'models/bot_intent.coffee'
  import DialogAction from 'models/dialog_action.coffee'

  export default
    props:
      node: [BotIntent, DialogAction]

    emits: ['edit']

    methods:
      parameterLabel: (element) ->
        @$root.contextParameters.find((cp) => cp.key == element.parameterKey)?.label || element.parameterKey
      editContextParameter: (element) ->
        @$emit('edit', element.parameterKey)
