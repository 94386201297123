
  import WorkflowTrigger from 'models/workflow_trigger.coffee'
  import BotInputEditor from './bot_input_editor.vue'
  import BotOutputEditor from './bot_output_editor.vue'
  import { Button, Card, H3 } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: WorkflowTrigger
      direction: String

    emits: ['update:modelValue', 'remove']

    data: ->
      trigger: @modelValue
      PlusIcon: PlusIcon

    watch:
      modelValue: -> @trigger = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @trigger)
      add: ->
        switch @direction
          when 'input' then @trigger.addInput()
          when 'output' then @trigger.addOutput()
        @update()
        @$refs.addButton.blur()
      remove: (index) ->
        switch @direction
          when 'input' then @trigger.botInput.splice(index, 1)
          when 'output' then @trigger.botOutput.splice(index, 1)
        @update()

    components:
      BotInputEditor: BotInputEditor
      BotOutputEditor: BotOutputEditor
      Button: Button
      Card: Card
      H3: H3
