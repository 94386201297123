Vue.component 'generic-game',
  props: ['config']
  data: ->
    state: state
    userIntentPaginationData: {offset: 0}
    botIntentPaginationData: {offset: 0}
    botIntentRefs: []
    userIntentRefs: []
  computed:
    availableContextParameters: ->
      @config.contextParameters.concat(
        @state.globalContextParameters.map((cp) -> Object.assign({}, cp, {label: "global.#{cp.label}"}))
      )
  watch:
    config:
      handler: -> @setLanguageStates()
      deep: true
  created: ->
    # make sure game config has all required properties
    Vue.setDefault(@config, 'contextParameters', [])
    Vue.setDefault(@config, 'userIntents', [])
    Vue.setDefault(@config, 'botIntents', [])
    # set keys for list rendering
    for intent in @config.userIntents
      Vue.setDefault(intent, '$key', GuidGenerator.newGuid())
    for intent in @config.botIntents
      Vue.setDefault(intent, '$key', GuidGenerator.newGuid())
    # avoid empty language matrix
    @setLanguageStates()
    # amend old format
    for botIntent in @config.botIntents
      Vue.setDefault(botIntent, 'contextUpdates', [])
    for userIntent in @config.userIntents
      for reaction in userIntent.reactions
        botIntent = @config.botIntents.find (intent) => intent.label == reaction.botIntent
        if botIntent? && reaction.contextUpdates
          botIntent.contextUpdates = botIntent.contextUpdates.concat(reaction.contextUpdates)
        Vue.delete(reaction, 'contextUpdates')
  mounted: ->
    @followDeeplink()
  beforeUpdate: ->
    @botIntentRefs = []
    @userIntentRefs = []
  methods:
    setLanguageStates: ->
      @$parent.setLanguageStates(
        [
          'userIntents.utterances',
          'botIntents.nlg',
        ]
      )
    addUserIntent: ->
      @config.userIntents.push({})
      @$refs.userIntentPagination.setPage(@$refs.userIntentPagination.maxPageNumber)
      Vue.nextTick =>
        $(@$el).find('#user-intent-accordion .collapse:last').collapse('show')
    addBotIntent: ->
      @config.botIntents.push({})
      @$refs.botIntentPagination.setPage(@$refs.botIntentPagination.maxPageNumber)
      Vue.nextTick =>
        $(@$el).find('#bot-intent-accordion .collapse:last').collapse('show')
    removeUserIntent: (index) ->
      @config.userIntents.splice(index, 1)
    removeBotIntent: (index) ->
      @config.botIntents.splice(index, 1)
    collapsibleIntents: (side, action) ->
      $(@$el).find("##{side}-intent-accordion .collapse").collapse(action)
    followDeeplink: ->
      if intentLabel = (new URL(location)).searchParams.get('user_intent')
        @goToIntent('user', intentLabel)
      else if intentLabel = (new URL(location)).searchParams.get('bot_intent')
        @goToIntent('bot', intentLabel)
    goToIntent: (side, label) ->
      list = @config["#{side}Intents"]
      pagination = @$refs["#{side}IntentPagination"]
      componentList = @["#{side}IntentRefs"]
      intentIndex = list.findIndex((intent) -> intent.label.normalizeName() == label)
      return if intentIndex < 0
      pagination.setPage(Math.floor(intentIndex / pagination.perPage))
      Vue.nextTick =>
        componentList.find((component) => component.intent.$key == list[intentIndex].$key)?.scrollToAndOpen()
    setBotIntentRef: (el) ->
      @botIntentRefs.push(el) if el?
    setUserIntentRef: (el) ->
      @userIntentRefs.push(el) if el?

  template: '
    <div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <a class="flex-container center-items" href="#context-parameters" data-toggle="collapse">
            <h3 class="expand">Context parameters</h3>
            <div class="chevron"></div>
          </a>
        </div>
        <div id="context-parameters" class="collapse">
          <div class="panel-body">
            <h4>Global context parameters</h4>
            <global-context-parameters :parameters="state.globalContextParameters"/>
          </div>
          <div class="panel-body">
            <h4>Local context parameters</h4>
            <context-parameters-table :parameters="config.contextParameters"/>
          </div>
        </div>
      </div>

      <div class="panel panel-default no-margin">
        <div class="panel-heading">
          <h3>Intent structure</h3>
        </div>
        <div class="panel-body">
          <div class="responsive-grid-container">
            <div class="responsive-grid-item item-300">
              <h4 class="clearfix">
                User intents
                <div class="pull-right flex-container columns bottom-items">
                  <div @click="collapsibleIntents(\'user\', \'show\')" class="btn btn-icon-light no-padding text-muted">
                    expand all &nbsp; <span class="fa fa-chevron-down"></span>
                  </div>
                  <div @click="collapsibleIntents(\'user\', \'hide\')" class="btn btn-icon-light no-padding text-muted">
                    collapse all &nbsp; <span class="fa fa-chevron-up"></span>
                  </div>
                </div>
              </h4>
              <pagination
                :collection="config.userIntents"
                :pagination-data="userIntentPaginationData"
                ref="userIntentPagination"
                per-page="20"
                v-slot="paginatedIntents"
                >
                <div class="panel-group" id="user-intent-accordion">
                  <user-intent
                    v-for="(intent, index) in paginatedIntents"
                    :ref="setUserIntentRef"
                    :key="intent.$key"
                    :intent="intent"
                    :index="parseInt(index) + userIntentPaginationData.offset"
                    :intent-list="config.userIntents"
                    :bot-intents="config.botIntents"
                    :context-parameters="availableContextParameters"
                    @remove-intent="removeUserIntent"
                    />
                </div>
              </pagination>
              <button v-on:click="addUserIntent()" class="btn btn-primary">Add user intent</button>
            </div>
            <div class="responsive-grid-item item-500">
              <h4 class="clearfix">
                Bot intents
                <div class="pull-right flex-container columns bottom-items">
                  <div @click="collapsibleIntents(\'bot\', \'show\')" class="btn btn-icon-light no-padding text-muted">
                    expand all &nbsp; <span class="fa fa-chevron-down"></span>
                  </div>
                  <div @click="collapsibleIntents(\'bot\', \'hide\')" class="btn btn-icon-light no-padding text-muted">
                    collapse all &nbsp; <span class="fa fa-chevron-up"></span>
                  </div>
                </div>
              </h4>
              <pagination
                :collection="config.botIntents"
                :pagination-data="botIntentPaginationData"
                ref="botIntentPagination"
                per-page="20"
                v-slot="paginatedIntents"
                >
                <div class="panel-group" id="bot-intent-accordion">
                  <bot-intent
                    v-for="(intent, index) in paginatedIntents"
                    :ref="setBotIntentRef"
                    :key="intent.$key"
                    :intent="intent"
                    :index="parseInt(index) + botIntentPaginationData.offset"
                    :intent-list="config.botIntents"
                    :context-parameters="availableContextParameters"
                    @remove-intent="removeBotIntent"
                    />
                </div>
              </pagination>
              <button v-on:click="addBotIntent()" class="btn btn-primary">Add bot intent</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  '
