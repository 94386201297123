import { createApp } from 'vue_shims'

initPromoteBotApp = (element) ->
  createApp(
    data: ->
      promotableStages: $(element).data('promoted-stages')
      allTargetStages: $(element).data('all-target-stages')
      stageLevelTranslations: $(element).data('stage-level-translations')
      defaultPromotableStage: ''
      defaultTargetableStage: ''
      targetableStages: []
      arrowImage: $(element).data('arrow-image')

    methods:
      setPromotableStages: (stage) ->
        @defaultPromotableStage = stage
        index = Object.keys(@stageLevelTranslations).indexOf(stage.stage_mode)
        targetableStageMode = Object.keys(@stageLevelTranslations)[index + 1]
        @targetableStages = @allTargetStages.filter (targetStage) -> targetStage.stage_mode == targetableStageMode
      promotableStageLabel: (stage) ->
        if stage then "#{@stageLevelTranslations[stage.stage_mode].capitalize()} #{stage.version}" else 'Select stage'
      setTargetableStages: (stage) ->
        @defaultTargetableStage = stage
      targetableStageLabel: (stage) ->
        if stage then "#{@stageLevelTranslations[stage.stage_mode].capitalize()} #{stage.version}" else ''

    template: '
      <div class="promote-bot-stage">
        <dropdown
          :chosen="promotableStageLabel(defaultPromotableStage)"
          align="left"
          class="filter-element"
          >
          <li v-for="stage in promotableStages">
            <a @click="setPromotableStages(stage)">{{ promotableStageLabel(stage) }}</a>
          </li>
        </dropdown>
        <input type="hidden" name="promotable_stage_uuid" v-model="defaultPromotableStage.uuid">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img :src="arrowImage" alt="arrow progress">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <dropdown
          :chosen="targetableStageLabel(defaultTargetableStage)"
          align="left"
          class="filter-element"
          >
          <li v-for="stage in targetableStages">
            <a @click="setTargetableStages(stage)">{{ targetableStageLabel(stage) }}</a>
          </li>
        </dropdown>
        <input type="hidden" name="targetable_stage_uuid" v-model="defaultTargetableStage.uuid">
      </div>
    '
  ).mount(element)

$(window).on 'shown.bs.modal', ->
  element = document.getElementById('promote-bot-app')
  initPromoteBotApp(element) if element?
