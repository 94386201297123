export default class WorkflowVariable
  constructor: (data = {}) ->
    @key = data.key
    @stepKey = data.stepKey
    @reference = data.reference # for use in property expressions

  Object.defineProperties @prototype,
    label:
      get: ->
        @key
