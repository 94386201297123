
  import { PlusIcon, LightningBoltIcon } from '@heroicons/vue/outline'

  export default
    props:
      edge: Object
      isCurrent: Boolean

    computed:
      style: ->
        dasharray = if ['conditioned', 'priming', 'fail'].includes(@edge.kind) then '5' else 'none'
        strokeColor = if @isCurrent then 'F6511D' else '595959'
        backgroundSvg = """url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 -1 #{@edge.width} #{@edge.height + 2}'><path d='#{@edge.path}' stroke='%23#{strokeColor}' stroke-width='2' stroke-dasharray='#{dasharray}' fill='none'/></svg>")"""

        left: "#{@edge.left}px"
        top: "#{@edge.top}px"
        width: "#{@edge.width}px"
        height: "#{@edge.height + 2}px"
        background: backgroundSvg
        'background-repeat': 'no-repeat'
        'background-position': 'center center'
        'background-size': '100% 100%, auto'
        transition: 'top .5s, left .5s, width .5s, height .5s'
      plusButtonClasses: ->
        "relative w-6 h-6 bg-white flex items-center justify-center rounded-full shadow-sm cursor-pointer pointer-events-auto"
      plusButtonStyle: ->
        transform: 'translateX(-50%) translateY(-50%)'
        left: @edge.ownWidth / 2 + 'px'
        top: (@edge.startPoint.y - @edge.top) + @edge.ownHeight / 2 + 'px'

    components:
      PlusIcon: PlusIcon
      LightningBoltIcon: LightningBoltIcon
