
  import Badge from './badge.vue'

  export default
    props:
      modelValue: String
      tabs: Array # of objects with id and name
      color:
        type: String
        default: 'light'
      type:
        type: String
        default: 'small'

    data: ->
      currentTab: @modelValue

    computed:
      bgColor: ->
        if @color == 'dark'
          'bg-gray-50 hover:bg-gray-100'
        else
          'bg-white hover:bg-gray-50'

    watch:
      modelValue: -> @currentTab = @modelValue

    methods:
      setTab: (value) ->
        @currentTab = value
        @$emit('update:modelValue', @currentTab)
      isCurrent: (tab) ->
        @currentTab == tab.id

    components:
      Badge: Badge
