
  import TraceDisplay from './trace_display.vue'
  import { H3 } from 'components/generic'
  import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      traces: Array

    data: ->
      currentTrace: null

    methods:
      setTrace: (index) ->
        @currentTrace = @traces[index]

    components:
      CheckCircleIcon: CheckCircleIcon
      H3: H3
      TraceDisplay: TraceDisplay
      XCircleIcon: XCircleIcon
