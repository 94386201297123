
  import Verbalization from 'models/verbalization.coffee'

  import NLGEditor from 'components/botbuilder/nlg/nlg_editor.vue'
  import PrimingsEditor from 'components/botbuilder/primings_editor.vue'
  import Sidebar from 'components/botbuilder/sidebar.vue'
  import { H3, Tabs } from 'components/generic'

  export default
    props:
      modelValue: Verbalization

    emits: ['close', 'update:modelValue']

    data: ->
      verbalization: @modelValue
      currentTab: 'message'
      tabs: [
        { id: 'message', name: 'Message' }
        { id: 'primings', name: 'Primings' }
      ]

    watch:
      modelValue: ->
        @verbalization = @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @verbalization)

    components:
      H3: H3
      NLGEditor: NLGEditor
      PrimingsEditor: PrimingsEditor
      Sidebar: Sidebar
      Tabs: Tabs
