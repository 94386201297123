
  export default
    props:
      size:
        type: String
        default: 'md'
      color:
        type: String
        default: 'blue'

    computed:
      classes: ->
        "#{@sizeClasses} #{@colorClasses}"
      sizeClasses: ->
        switch @size
          when 'sm' then 'text-xs'
          when 'md' then 'text-sm'
          when 'lg' then 'text-base'
      colorClasses: ->
        switch @color
          when 'blue'
            'bg-MercuryBlue-50 text-MercuryBlue-800'
          when 'green'
            'bg-MercuryGreen-50 text-MercuryGreen-800'
          when 'yellow'
            'bg-MercuryYellow-50 text-MercuryYellow-800'
          when 'red'
            'bg-MercuryRed-50 text-MercuryRed-800'
          when 'gray'
            'bg-gray-100 text-gray-800'
          when 'light-gray'
            'bg-gray-100 text-gray-500'
          when 'violet'
            'bg-MagentaViolet-50 text-MagentaViolet-800'
          when 'white'
            'bg-white text-gray-500'
