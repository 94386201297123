
  import WhatsappChannel from 'models/whatsapp_channel.coffee'

  import TemplateInList from './template_in_list.vue'
  import { H3, Table, TableHeadCell, TableHeadRow } from 'components/generic'

  export default
    props:
      templates: Array
      channel: WhatsappChannel

    emits: ['edit']

    methods:
      editTemplate: (template) ->
        @$emit('edit', template)
      removeTemplate: (index) ->
        @templates.splice(index, 1)

    components:
      H3: H3
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TemplateInList: TemplateInList
