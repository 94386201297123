
  import DialogTarget from 'models/dialog_target.coffee'
  import FacebookChannel from 'models/facebook_channel.coffee'

  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { Button, Input, Label, Textarea } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: FacebookChannel

    data: ->
      channel: @modelValue
      getStartedTarget: null
      getStartedTargetIsSet: false
      PlusIcon: PlusIcon

    created: ->
      @setGetStartedTarget()

    methods:
      setGetStartedTarget: ->
        getStartedPayload = @modelValue.getStarted?.payload || ''
        [moduleKey, nodeKey] = if getStartedPayload
          getStartedPayload.replace(/\(.*\)$/, '').split('/')
        else
          []
        @getStartedTarget = new DialogTarget(
          {}
          moduleKey: moduleKey
          nodeKey: nodeKey
        )
        @getStartedTargetIsSet = true
      update: ->
        @$emit('update:modelValue', @channel)
      updateGetStartedTarget: ->
        if @getStartedTarget.moduleKey? && @getStartedTarget.nodeKey?
          @channel.getStarted.payload = "#{@getStartedTarget.moduleKey}/#{@getStartedTarget.nodeKey}"
        else
          @channel.getStarted.payload = ''
        @update()
      addIceBreakerItem: ->
        @channel.defaultIceBreaker.addItem()
      removeIceBreakerItem: (index) ->
        @channel.defaultIceBreaker.items.splice(index, 1)
        @update()

    components:
      Button: Button
      Input: Input
      Label: Label
      TargetEditor: TargetEditor
      Textarea: Textarea
      XIcon: XIcon
