Vue.component 'persistent-menu-item-list',
  props: ['itemList', 'level']
  data: ->
    state: state
  methods:
    removeItem: (index) ->
      @itemList.splice(index, 1)
      @$emit('cleared-items') if !@itemList.length
    addItem: ->
      @itemList.push(@defaultItem())
    defaultItem: ->
      title: {}
      type: 'postback'
      payload: ''
  template: '
    <div>
      <persistent-menu-item
        v-for="(item, index) in itemList"
        :item="item"
        :index="index"
        :item-list="itemList"
        :level="level"
        :key="item.$key"
        @remove-item="removeItem"
        />
      <div class="btn btn-default" v-if="!itemList.length && !state.disabled" @click="addItem">
        Add menu item
      </div>
    </div>'
