
  import PlatformApi from 'models/platform_api.coffee'
  import Subscription from 'models/subscription'
  import { Button, Modal } from 'components/generic'

  export default
    data: ->
      selectedPlan: null
      sending: false

    computed:
      subscription: ->
        @$root.organization.subscription || new Subscription({})
      show: ->
        @subscription.plan == 'business'
      plans: ->
        @subscription.available_plans || []

    methods:
      mail: ->
        window.location.href = 'mailto:sales@mercury.ai'
      selectPlan: (plan) ->
        @selectedPlan = plan
        @$refs.upgradeModal.open()
      upgrade: ->
        @sending = true
        PlatformApi.Webapp.upgradePlan(@$root.organization.id, @selectedPlan.id)
          .then (data) =>
            @subscription.update(data)
            @$refs.upgradeModal.close()
          .finally =>
            @sending = false

    components:
      Button: Button
      Modal: Modal
