import { confirm } from 'helpers'

Vue.component 'bot-reaction',
  props: ['reaction', 'index', 'count', 'agentTeams', 'botIntents', 'parameters', 'isElseReaction', 'allowLastActiveGame']

  data: ->
    state: state

  created: ->
    # account for old format
    if @reaction.contextParameter?
      @reaction.parameter = @reaction.contextParameter
      delete @reaction.contextParameter
    # make sure reaction has all required properties
    Vue.setDefault(@reaction, '$key', GuidGenerator.newGuid())
    Vue.setDefault(@reaction, 'agentTeamId', null)
    if @botIntents
      Vue.setDefault(@reaction, 'botIntent', '')
    if !@isElseReaction
      Vue.setDefault(@reaction, 'conditions', [])
      if @allowLastActiveGame
        Vue.setDefault(@reaction, 'ifLastActiveGame', '')
      else
        @addCondition() if @reaction.conditions.length == 0
    @validate()

  computed:
    label: ->
      if @isElseReaction
        'else' if @count > 1
      else if @allowLastActiveGame
        'if last active game was'
      else
        'if'
    availableParameters: ->
      @parameters.concat(@state.computedContextParameters.read)
    showRemoveButton: ->
      @count > 1 && !@isElseReaction
    agentTeamsWithAnyOption: ->
      [{id: null, name: 'Any team'}].concat(@agentTeams)

  watch:
    reaction:
      handler: -> @validate()
      deep: true

  methods:
    validate: ->
      Vue.set @reaction, '$valid',
        (@isElseReaction || GameValidator.allValid(@reaction.conditions)) &&
        (
          @botIntents && @reaction.botIntent in @botIntents.map((intent) -> intent.label) ||
          @agentTeams && @reaction.agentTeamId in @agentTeams.map((team) -> team.id)
        )
    remove: (index) ->
      return unless await confirm('Do you really want to delete this reaction?')
      @$emit('remove', index)
    removeCondition: (index) ->
      @reaction.conditions.splice(index, 1)
    addCondition: ->
      @reaction.conditions.push(parameter: '', operator: '', value: '')

  template: '
    <div>
      <label v-show="label">{{ label }}</label>
      <div v-if="!isElseReaction" class="form-group">
        <div v-if="allowLastActiveGame">
          <select v-model="reaction.ifLastActiveGame" class="form-control sub-form-group">
            <option value="">any</option>
            <option v-for="game in state.games" :value="game">{{ game }}</option>
          </select>
          <label v-show="reaction.conditions.length > 0">and</label>
        </div>
        <user-intent-reaction-condition
          v-for="condition, index in reaction.conditions"
          :key="condition.$key"
          :condition="condition"
          :index="index"
          :count="reaction.conditions.length"
          :parameters="availableParameters"
          :allow-delete-last="allowLastActiveGame"
          @remove="removeCondition"
          />
        <plus-button label="add condition" :method="addCondition"/>
      </div>

      <template v-if="botIntents">
        <label v-show="!isElseReaction">then</label>
        <div :class="{\'has-error\': !reaction.botIntent, \'form-group\': showRemoveButton}">
          <select v-model="reaction.botIntent" class="form-control">
            <option value="" selected disabled>Bot intent</option>
            <option v-for="intent in botIntents" :value="intent.label">{{ intent.label }}</option>
          </select>
        </div>
      </template>

      <template v-if="agentTeams">
        <label><span v-show="!isElseReaction">then </span>assign to</label>
        <div :class="{\'form-group\': showRemoveButton}">
          <select v-model="reaction.agentTeamId" class="form-control">
            <option v-for="team in agentTeamsWithAnyOption" :value="team.id">{{ team.name }}</option>
          </select>
        </div>
      </template>

      <div v-if="showRemoveButton" @click="remove(index)" class="btn btn-caution pull-right">
        remove reaction
      </div>
    </div>
  '
