
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import SourceCreator from './source_creator.vue'
  import { Button, Modal } from 'components/generic'

  export default
    props:
      redirect:
        type: Boolean
        default: true

    data: ->
      creator: null
      sending: false

    computed:
      creatingDisabled: ->
        @sending || !@creator?.valid

    methods:
      open: ->
        @$refs.modal.open()
      create: ->
        @sending = true
        @creator.create()
          .then (newSource) =>
            if @redirect
              @$router.push(name: 'knowledgeSource', params: {sourceId: newSource.id})
            else
              @$emit('created', newSource)
              @$refs.modal.close()
          .finally =>
            @sending = false
      cancel: ->
        @creator?.cancel()
      setCreatorRef: (el) ->
        @creator = el

    components:
      Button: Button
      Modal: Modal
      SourceCreator: SourceCreator
