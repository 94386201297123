
  import ProgressSidebar from './progress_sidebar.vue'
  import Tile from './tile.vue'
  import { H1 } from 'components/generic'

  export default
    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setHeight: ->
        @$el.style.height = document.documentElement.clientHeight - @$el.getBoundingClientRect().top + 'px'

    components:
      H1: H1
      ProgressSidebar: ProgressSidebar
      Tile: Tile
