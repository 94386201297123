import AnswerTemplate from 'models/answer_template'
import marked from 'marked'

Vue.component 'inbox2-answer-template-list',
  props:
    conversationId: String

  data: ->
    filterString: ''
    filterTag: null
    answerTemplates: []
    availableTags: []
    isOpen: true

  computed:
    filteredTemplates: ->
      @answerTemplates.filter (template) =>
        return false if @filterTag && !template.tags.includes(@filterTag)
        fullText = template.exampleQuestion + $(marked(template.messageText)).text() # strip off markdown
        return fullText.toLowerCase().indexOf(@filterString.toLowerCase()) >= 0

  created: ->
    @$root.api.loadAnswerTemplates(@conversationId)
      .then (templatesData) => @answerTemplates = templatesData.map (data) -> new AnswerTemplate(data)
      .catch => @answerTemplates = []
    @$root.api.loadAnswerTemplateTags(@conversationId)
      .then (tags) => @availableTags = tags
      .catch => @availableTags = []

  methods:
    setFilterTag: (tag) ->
      @$root.track('Inbox answer template tag selected', tag: tag)
      @filterTag = tag
    resetFilterTag: ->
      @$root.track('Inbox answer template tag filter reset')
      @filterTag = null
    chosenTag: ->
      @filterTag || t("inbox.filter.allTags")
    useTemplate: (template) ->
      @$emit('use-template', template)
    addTemplate: (message) ->
      @$root.api.createAnswerTemplate(@conversationId, message)
        .then (template) => @answerTemplates.push(template)
    deleteTemplate: (template) ->
      @$root.api.deleteAnswerTemplate(@conversationId, template)
        .then =>
          @answerTemplates = @answerTemplates.filter (innerTemplate) -> innerTemplate.id != template.id
    toggle: ->
      @isOpen = !@isOpen
      $(@$el).find('.collapse').collapse('toggle')

  template: '
    <div class="answer-templates">
      <a @click="toggle" class="answer-templates-header block flex-container center-items" :aria-expanded="isOpen ? true : null">
        <b>{{ t("inbox.conversations.answerTemplates") }}</b>
        <div class="chevron auto-margin-left"></div>
      </a>

      <div class="collapse in">
        <div class="filter-input">
          <input
            v-model="filterString"
            type="text"
            :placeholder="t(\'inbox.filter.searchAnswer\')"
            @focus="$root.track(\'Inbox answer template search focussed\')"
            >
          <div class="icon-magnifier"></div>
        </div>

        <div class="filter-input">
          <dropdown align="left" :chosen="chosenTag()">
            <li>
              <a @click="resetFilterTag">{{ t("inbox.filter.allTags") }}</a>
            </li>
            <li v-for="tag in availableTags">
              <a @click="setFilterTag(tag)">{{ tag }}</a>
            </li>
          </dropdown>
        </div>

        <div class="answer-template-list">
          <inbox2-answer-template
            v-for="template in filteredTemplates"
            :key="template.id"
            :template="template"
            :conversationId="conversationId"
            @use="useTemplate"
            @delete="deleteTemplate"
          />
        </div>
      </div>
    </div>
  '
