
  import PlatformApi from 'models/platform_api.coffee'
  import { H3, Input, Panel } from 'components/generic'

  export default
    props:
      user: Object

    data: ->
      editing: false
      sending: false
      slackId: @user.slack_id

    methods:
      cancel: ->
        @slackId = @user.slack_id
        @editing = false
      save: ->
        @sending = true
        PlatformApi.Webapp.saveProfile(slack_id: @slackId)
          .then (data) =>
            Object.assign(@user, data)
            @slackId = @user.slack_id
            @editing = false
          .catch =>
            @cancel()
          .finally =>
            @sending = false

    components:
      H3: H3
      Input: Input
      Panel: Panel
