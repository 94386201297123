
  import Bot from 'models/bot.coffee'
  import PlatformApi from 'models/platform_api.coffee'
  import Project from 'models/project.coffee'

  import { Button, H3, Input, KeyListbox, Modal } from 'components/generic'

  export default
    props:
      createProject:
        type: Boolean
        default: false

    data: ->
      step: 0
      label: ''
      projectLanguage: null
      languagesForSelector: [label: '🦜 Choose a language for your bot'].concat(Project.AVAILABLE_LANGUAGES)
      selectedTemplate: null
      newBot: null
      projectCreated: false
      templateOptions: [
        {
          key: 'EMPTY',
          title: 'Empty bot',
          body: 'Clean like a plate of stainless steel.',
          colorClass: 'bg-Avocado-800',
          selectedClass: 'ring-Avocado-800 ring-2 ring-inset',
          svg: {
            fill: 'none',
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round',
            'stroke-width': '2',
            d: 'M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z',
          },
        },
        {
          key: 'HELLO_WORLD',
          title: 'Starter template',
          body: 'Some dialogues to kickstart your bot.',
          colorClass: 'bg-Skobeloff-700',
          selectedClass: 'ring-Skobeloff-700 ring-2 ring-inset',
          svg: {
            fill: 'none',
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round',
            'stroke-width': '2',
            d: 'M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z',
          },
        },
        {
          key: 'ecommerce',
          title: 'Blueprint: Conversational Commerce',
          body: 'eCommerce customer service and loyalty ',
          colorClass: 'bg-MagentaViolet-600',
          selectedClass: 'ring-MagentaViolet-600 ring-2 ring-inset',
          svg: {
            fill: 'none',
            'stroke-linecap': 'round',
            'stroke-linejoin': 'round',
            'stroke-width': '2',
            d: 'M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z',
          },
        },
      ]

    watch:
      step: ->
        @$refs.labelModal.close() unless @step == 1
        @$refs.languageModal.close() unless @step == 2
        @$refs.templateModal.close() unless @step == 3
        switch @step
          when 1 then @$refs.labelModal.open()
          when 2 then @$refs.languageModal.open()
          when 3 then @$refs.templateModal.open()
          when 4 then @$refs.finalModal.open()

    methods:
      start: ->
        @step = 0
        @label = ''
        @projectLanguage = null
        @selectedTemplate = null
        @$nextTick =>
          @step = 1
      nextFromLabelModal: ->
        if @createProject
          @step += 1
        else
          @step += 2
      backFromTemplateModal: ->
        if @createProject
          @step -= 1
        else
          @step -= 2
      create: ->
        if !@createProject
          @createBot().then =>
            @$refs.templateModal.close()
            @$root.bots.push(@newBot)
            @$router.push(name: 'moduleList', params: {botId: @newBot.id})
        else
          @step += 1
          PlatformApi.Webapp.createBc2Project(label: @label, language: @projectLanguage)
            .then (projectData) =>
              @projectCreated = true
              # set needed Globals data for BotbuilderApi
              window.Globals.project = projectData
              # create initial bot
              @createBot()
      createBot: ->
        @newBot = new Bot(label: @label)
        @newBot.createFromTemplate(@selectedTemplate.key)
      redirect: ->
        Turbolinks.visit(Routes.project_path(Globals.project.id, 'dev'))

    components:
      Button: Button
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
  