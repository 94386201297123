jQuery.fn.registerShownBsCollapseWatcherForSlickSliders = ->
  $(this[0]).on 'shown.bs.collapse', ->
    $('.slick-slider').each -> this.slick.setPosition()
  return this

jQuery.htmlDecode = (input) ->
  doc = new DOMParser().parseFromString(input, 'text/html')
  doc.documentElement.textContent

jQuery.postJSON = (url, data) ->
  $.ajax(
    type: 'POST'
    url: url
    data: JSON.stringify(data)
    contentType: 'application/json'
  )

