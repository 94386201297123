import GuidGenerator from 'models/guid_generator.coffee'

export default class Validation
  @TYPES =
    RegExValidation:
      label: 'Regular expression'
      attributes:
        regularExpression: ''
    MinMaxValidation:
      label: 'Min/max'
      attributes:
        min: null
        max: null
    AndValidation:
      attributes:
        first: null
        second: null
    OrValidation:
      attributes:
        first: null
        second: null

  constructor: (data = {}) ->
    data ||= {}
    @id = GuidGenerator.newGuid()
    @type = data.type || 'RegExValidation'
    @valid = if data.valid? then data.valid else true
    Object.entries(@attributes).forEach ([attribute, defaultValue]) =>
      if data[attribute]?
        @[attribute] = switch attribute
          when 'first', 'second'
            new Validation(data[attribute])
          else
            data[attribute]
      else
        @[attribute] = defaultValue

  Object.defineProperties @prototype,
    attributes:
      get: ->
        Validation.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    export:
      get: ->
        Object.fromEntries(
          [['type', @type], ['valid', @valid]].concat(
            @attributeKeys.map (attribute) =>
              if ['first', 'second'].includes(attribute)
                [attribute, @[attribute].export]
              else
                [attribute, @[attribute]]
          )
        )
