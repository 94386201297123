Vue.component 'attachment-editor',
  props: ['attachment', 'index', 'messageState', 'smallImage']
  data: ->
    gameValidator: GameValidator
    buttonTypes:
      Postback: 'intent'
      Link: 'link'
    editing: false
    webviewSizes: ['compact', 'tall', 'full']
  computed:
    showError: ->
      !@messageState.pristine && !@attachment.$valid
    showPath: ->
      @editing || @attachment.path && !@pathValid
    placeholder: ->
      return unless @attachment.type == 'Text'
      switch @attachment.role
        when 'NONE' then 'Enter text here'
        when 'TITLE' then 'Enter headline here'
        when 'SUBTITLE' then 'Enter subline here'
    embeddedMessageState: ->
      ObjectProcessor.clone(@messageState)
    pathValid: ->
      (@messageState.template in ['card', 'gallery', 'result'] && !@attachment.path) || @pathIsUrl || @pathIsPlaceholder
    pathIsUrl: ->
      GameValidator.url(@attachment.path)
    pathIsPlaceholder: ->
      GameValidator.placeholder(@attachment.path)
    isEmbeddedVideoPath: ->
      @attachment.path.match(/^(https?\:\/\/)?(www\.)?youtube\.com\/embed\/.+/) ||
        @attachment.path.match(/^(https?\:\/\/)?player\.vimeo\.com\/video\/.+/)
    urlValid: ->
      GameValidator.url(@attachment.url) ||
        GameValidator.mailtoLink(@attachment.url) ||
        GameValidator.telLink(@attachment.url) ||
        GameValidator.placeholder(@attachment.url)
    isVirtualButton: ->
      @attachment.type == 'Button' && @attachment.text.text.match(/<.*>/)
  created: ->
    Vue.setDefault(@attachment, '$key', GuidGenerator.newGuid())
    # amend old payload format
    Vue.set(@attachment, 'payload', {}) if typeof @attachment.payload == 'string'
    Vue.set(@attachment, 'content', {}) if typeof @attachment.content == 'string'
    # add needed properties (not complete for all types)
    if @attachment.type == 'Text'
      Vue.setDefault(@attachment, 'text', '')
      Vue.setDefault(@attachment, 'role', 'NONE')
      Vue.setDefault(@attachment, 'format', 'PLAIN')
    else if @attachment.type == 'Button'
      Vue.setDefault(@attachment, 'text', {type: 'Text'})
      if @attachment.buttonType in ['QuickReply', 'Postback']
        Vue.setDefault(@attachment, 'payload', {})
      else if @attachment.buttonType == 'Link'
        Vue.setDefault(@attachment, 'url', '')
        Vue.setDefault(@attachment, 'newTab', false)
        Vue.setDefault(@attachment, 'webview', false)
        Vue.setDefault(@attachment, 'webviewSize', 'full')
    @validate()
  mounted: ->
    if @attachment.isNew
      @editing = true
      @messageState.editing = true
      @messageState.pristine = true
      Vue.delete(@attachment, 'isNew')
      $(@$el).find('.message-input').focus()
  unmounted: ->
    $(document).off "click.#{@attachment.$key}"
  watch:
    attachment:
      handler: -> @validate()
      deep: true
    'attachment.buttonType': ->
      return unless @attachment.type == 'Button'
      if @attachment.buttonType in ['QuickReply', 'Postback']
        Vue.setDefault(@attachment, 'payload', {})
      else
        Vue.delete(@attachment, 'payload')
      if @attachment.buttonType == 'Link'
        Vue.setDefault(@attachment, 'url', '')
      else
        Vue.delete(@attachment, 'url')
    editing: ->
      return unless @attachment.type in ['Button', 'Image', 'Video']
      if @editing
        # end editing mode when clicked outside
        setTimeout(=> # need this, otherwise the click that enabled editing mode triggers the handler
          $(document).on "click.#{@attachment.$key}", (event) =>
            if $(event.target).closest(".attachment.#{@attachment.type.toLowerCase()}").get(0) != @$el
              @endEditing()
            true
        , 0)
        if @attachment.type in ['Image', 'Video']
          Vue.nextTick =>
            $(@$el).find('input').focus()
      else
        $(document).off "click.#{@attachment.$key}"
  methods:
    validate: ->
      Vue.set @attachment, '$valid',
        switch @attachment.type
          when 'Text'
            @attachment.role == 'SUBTITLE' || @textValid(@attachment)
          when 'Payload'
            @attachment.content.$valid
          when 'Image', 'Video'
            @pathValid
          when 'Location'
            if !@attachment.fromContext
              GameValidator.geolocation(@attachment.geolocation)
            else
              true
          when 'Button'
            switch @attachment.buttonType
              when 'QuickReply', 'Postback'
                @textValid(@attachment.text) &&
                  @attachment.payload?.$valid
              when 'Link'
                @textValid(@attachment.text) && @urlValid
    textValid: (text) ->
      GameValidator.string(text.text) &&
        text.role in ['NONE', 'SUBTITLE', 'TITLE']
    startEditing: ->
      @editing = true
      @messageState.editing = true
      @$emit('start-editing')
    endEditing: ->
      @editing = false
      @messageState.editing = false
      @messageState.pristine = false
      @$emit('end-editing')
    remove: ->
      @$emit('remove-attachment', @index)
    setText: (newText) ->
      @attachment.text = newText
      @$refs.textEditable.reset()
  template: '
    <div
      class="attachment"
      :class="[attachment.type.toLowerCase(), {\'with-error\': showError, editing: editing, virtual: isVirtualButton}]"
      @click.stop=""
    >
      <editable
        v-if="attachment.type == \'Text\'"
        :content="attachment.text"
        @update="attachment.text = $event"
        @focus="startEditing"
        @exit="endEditing"
        :placeholder="placeholder"
        class="message-input"
        :class="{\'has-error\': showError, title: attachment.role == \'TITLE\', subtitle: attachment.role == \'SUBTITLE\'}"
        ref="textEditable"
        />
      <div v-else-if="attachment.type == \'Payload\'">
        <payload-editor :payload="attachment.content"/>
      </div>
      <div
        v-else-if="attachment.type == \'Image\' || attachment.type == \'Video\'"
        @click="startEditing"
        :class="attachment.type.toLowerCase() + \'-element\'"
        >
        <div v-if="!editing && attachment.type == \'Image\'" class="img-container" :class="{filled: pathIsPlaceholder}">
          <img v-if="pathIsUrl" :src="attachment.path">
          <div v-if="pathIsPlaceholder" class="image-placeholder filled">Image: {{ attachment.path }}</div>
          <div v-if="!attachment.path" class="image-placeholder">{{ smallImage ? "img" : "Add your image here" }}</div>
        </div>
        <div v-if="!editing && attachment.type == \'Video\'" class="iframe-container">
          <iframe v-if="attachment.path && isEmbeddedVideoPath" :src="attachment.path"/>
          <video v-else-if="attachment.path" :src="attachment.path"/>
          <div class="iframe-overlay"></div>
          <div v-if="!attachment.path" class="video-placeholder">Add your video here</div>
        </div>
        <div v-if="showPath" class="path-input-container flex-container center-items" :class="{\'has-error\': !pathValid}">
          <input
            v-model="attachment.path"
            type="text"
            class="form-control"
            placeholder="URL"
            @focusout="endEditing"
            @keyup.enter="endEditing"
            @keyup.esc="endEditing"
            >
        </div>
      </div>
      <div v-else-if="attachment.type == \'Location\'">
        <div class="row form-group">
          <div class="col-sm-2 text-right">
            <label class="control-label non-bold">Geolocation</label>
          </div>
          <div class="col-sm-10" :class="{\'has-error\': !gameValidator.geolocation(attachment.geolocation)}">
            <input v-model="attachment.geolocation" type="text" class="form-control" :disabled="attachment.fromContext ? true : null">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-10 col-sm-offset-2">
            <label class="sliding-switch non-bold">
              <input type="checkbox" v-model="attachment.fromContext">
              <span class="slider"></span>
              <span class="switch-label-right">take location from context</span>
            </label>
          </div>
        </div>
      </div>
      <div v-else-if="attachment.type == \'Button\'">
        <attachment-editor
          :attachment="attachment.text"
          :message-state="embeddedMessageState"
          @start-editing="startEditing"
          @end-editing="endEditing"
          />
        <select v-show="editing" v-model="attachment.buttonType" class="form-control button-type-select" required="">
          <option :value="undefined" disabled="" selected="" hidden="">select type</option>
          <option v-for="label, type in buttonTypes" :value="type">{{ label }}</option>
        </select>
        <payload-editor
          v-if="attachment.buttonType == \'Postback\'"
          v-show="editing"
          :payload="attachment.payload"
          />
        <div
          v-if="attachment.buttonType == \'Link\'"
          v-show="editing"
          :class="{\'has-error\': !urlValid}"
          >
          <input v-model="attachment.url" type="text" class="form-control spaced-input" placeholder="URL">
          <label class="sliding-switch text-light spaced-input">
            <input type="checkbox" v-model="attachment.newTab">
            <span class="slider"></span>
            <span class="switch-label-right">open in new tab/window</span>
          </label>
          <label class="sliding-switch text-light spaced-input">
            <input type="checkbox" v-model="attachment.webview">
            <span class="slider"></span>
            <span class="switch-label-right">enable webview</span>
          </label>
          <select v-show="attachment.webview" v-model="attachment.webviewSize" class="form-control spaced-input" required="">
            <option v-for="size in webviewSizes" :value="size">{{ size }}</option>
          </select>
        </div>
        <div v-show="editing" @click="remove" class="btn btn-caution remove-button">delete</div>
      </div>
    </div>
  '
