Vue.component 'support-topic-introduction',
  props: ['topic']
  mixins: [WizardComponent]
  data: ->
    entity: @topic
    role: 'tail'
    attributes:
      introductoryMessage:
        type: 'NLG'
        default: {}
    introductoryMessageCopy: {}
  created: ->
    if @topic.introductoryMessage?
      @pristine = false
    @setCopy()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">How does the bot reply to start the solution dialogue?</h5>
          <nlg-editor :nlg="introductoryMessageCopy"/>
          <support-confirm-button/>
        </div>
      </div>
      <div class="button-container">
        <button v-if="!pristine" :disabled="allComplete ? null : true" @click="finished" class="btn btn-primary">confirm and close</button>
      </div>
    </div>
  '
