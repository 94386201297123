
  import GuidGenerator from 'models/guid_generator.coffee'
  import WebChannel from 'models/web_channel.coffee'

  import ControlsEditor from './controls_editor.vue'
  import DesignEditor from './design_editor.vue'
  import TeaserEditor from './teaser_editor.vue'
  import WidgetTest from './widget_test.vue'
  import { Button, Tabs } from 'components/generic'

  export default
    props:
      modelValue: WebChannel

    data: ->
      channel: @modelValue
      currentTab: if @$route.hash then @$route.hash[1..] else 'design'
      debounceTimeout: null
      displayEditor: @modelValue.connected
      userIdForWidget: GuidGenerator.newGuid()
      tabs: [
        { id: 'design', name: 'Widget design' }
        { id: 'teasers', name: 'Teaser message' }
        { id: 'settings', name: 'Settings' }
        { id: 'test', name: 'Widget test' }
      ]

    watch:
      '$route.hash': ->
        @currentTab = if @$route.hash then @$route.hash[1..] else 'design'

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      updateDesign: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(
          => @channel.uploadStyle()
        , 1000
        )
        @update()
      connect: ->
        @channel.connect()
          .then =>
            @channel.saveDefaultConfig()
            @displayEditor = true
      setHash: ->
        @$router.push(hash: "##{@currentTab}")

    components:
      Button: Button
      ControlsEditor: ControlsEditor
      DesignEditor: DesignEditor
      Tabs: Tabs
      TeaserEditor: TeaserEditor
      WidgetTest: WidgetTest
