import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import Role from 'models/role.coffee'
import { trackRouting } from 'helpers'

import Menu from 'components/shared/sidebar_menu.vue'
import Account from 'components/organization_settings/account.vue'
import Billing from 'components/organization_settings/billing.vue'
import Dpa from 'components/organization_settings/dpa.vue'
import Projects from 'components/organization_settings/projects.vue'
import SecurityAndDataProtection from 'components/organization_settings/security_and_data_protection.vue'
import Subscription from 'components/organization_settings/subscription.vue'
import Terms from 'components/organization_settings/terms.vue'
import Users from 'components/organization_settings/users.vue'
import { ChipIcon, CreditCardIcon, OfficeBuildingIcon, ShieldCheckIcon, TicketIcon, UserCircleIcon } from '@heroicons/vue/outline'

initOrganizationSettingsApp = (element) ->
  organization = $(element).data('organization')

  routes = [
    name: 'account'
    label: 'Account'
    icon: OfficeBuildingIcon
    path: '/account'
    alias: '/organizations/:organizationId'
    component: Account
  ,
    if Globals.currentUser.may('view_users', organization)
      name: 'users'
      label: 'Users'
      icon: UserCircleIcon
      path: '/users/:userId?'
      component: Users
    else
      null
  ,
    name: 'projects'
    label: 'Projects'
    icon: ChipIcon
    path: '/projects'
    component: Projects
  ,
    if Globals.currentUser.may('edit_organization', organization)
      name: 'subscription'
      label: 'Subscription'
      icon: TicketIcon
      path: '/subscription'
      component: Subscription
    else
      null
  ,
    if Globals.currentUser.may('view_invoices', organization)
      name: 'billing'
      label: 'Billing'
      icon: CreditCardIcon
      path: '/billing'
      component: Billing
    else
      null
  ,
    if Globals.currentUser.may('edit_organization', organization)
      name: 'securityAndDataProtection'
      label: 'Roles, Security and Data protection'
      icon: ShieldCheckIcon
      path: '/security_and_data_protection'
      component: SecurityAndDataProtection
    else
      null
  ,
    name: 'dpa'
    path: '/dpa'
    component: Dpa
  ,
    name: 'terms'
    path: '/terms'
    component: Terms
  ]
    .filter (item) -> item?
    .map (item) -> Object.assign(item, path: '/organizations/:organizationId' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    data: ->
      organization: organization
      organizationRoles: $(element).data('organization-roles').map (roleData) -> new Role(roleData)
      projectRoles: $(element).data('project-roles').map (roleData) -> new Role(roleData)
      users: $(element).data('users')
      projects: $(element).data('projects')
      mayCreateProject: $(element).data('may-create-project')
      routes: routes.filter (route) -> route.label?

    computed:
      displayMenu: ->
        @$route.name != 'dpa' && @$route.name != 'terms'

    components:
      Menu: Menu

    template: '
      <template v-if="displayMenu">
        <div class="flex">
          <Menu
            title="Account settings"
            :items="routes"
            :params="{organizationId: organization.id}"
            class="tw-hidden md:flex md:w-64 md:flex-shrink-0 md:fixed md:h-screen"
          />

          <router-view class="md:ml-64"/>
        </div>
      </template>

      <template v-else>
        <router-view/>
      </template>
    '
  )
    .use(router)
    .mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('organization-settings-app')
      initOrganizationSettingsApp(element)
