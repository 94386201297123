Vue.component 'context-update',
  props: ['update', 'index', 'contextParameters']
  data: ->
    state: state
  created: ->
    # make sure update has all required properties
    Vue.setDefault(@update, 'parameter', '')
    Vue.setDefault(@update, 'operator', '')
    Vue.setDefault(@update, 'value', '')
    Vue.setDefault(@update, '$key', GuidGenerator.newGuid())
    @validate()
  computed:
    availableContextParameters: ->
      @contextParameters
        .concat(@state.computedContextParameters.write)
    valueType: ->
      contextParameter = @availableContextParameters.find((parameter) => parameter.label == @update.parameter)
      contextParameter?.type
    availableOperators: ->
      TypeValidator.availableUpdateOperators(@valueType)
    operatorValid: ->
      @update.operator in @availableOperators
    valueLabel: ->
      TypeValidator.valueLabel(@valueType, @update.operator)
    valueValid: ->
      GameValidator.placeholder(@update.value) ||
      TypeValidator.valueValid(
        @update.value,
        @valueType,
        @update.operator,
        false
      )
  watch:
    update:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      Vue.set @update, '$valid',
        @valueType? && # checks whether parameter is valid
        @operatorValid &&
        @valueValid
    remove: (index) ->
      @$emit('remove-update', index)
  template: '
    <div class="flex-container center-items">
      <div :class="{\'has-error\': !valueType}" class="width-30">
        <select v-model="update.parameter" class="form-control">
          <option v-for="parameter in availableContextParameters" :value="parameter.label">{{ parameter.label }}</option>
        </select>
      </div>&nbsp;
      <div :class="{\'has-error\': !operatorValid}" class="width-20">
        <select v-model="update.operator" class="form-control">
          <option v-for="operator in availableOperators" :value="operator">{{ operator }}</option>
        </select>
      </div>&nbsp;
      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input
          v-if="update.operator !=\'clear\'"
          type="text"
          v-model="update.value"
          class="form-control"
          :placeholder="valueLabel"
          >
      </div>
      <div @click="remove(index)" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>
    </div>
  '
