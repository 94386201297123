
  export default
    props:
      transferData: Object

    emits: ['drag-start', 'drag-end']

    methods:
      onDragStart: (event) ->
        event.dataTransfer.setData('value', JSON.stringify(@transferData))
        @$emit('drag-start')
