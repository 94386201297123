Vue.component 'support-utterances',
  props: ['config']
  mixins: [WizardComponent]
  data: ->
    entity: @config
    role: 'mid'
    attributes:
      invocationUtterance:
        type: 'Utterances'
        default: {}
    invocationUtteranceCopy: {}
  mounted: -> # use mounted so we can immediately focus the input field
    if !@config.invocationUtterance?
      Vue.nextTick =>
        $(@$el).find('input').first().focus()
    else
      @pristine = false
    @setCopy()
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">How do users start this conversation?</h5>
          <utterances :utterances="invocationUtteranceCopy"/>
          <support-confirm-button/>
        </div>
      </div>

      <support-introduction v-if="!pristine" :config="config" @finished="finished" @discard="discard"/>
    </div>
  '
