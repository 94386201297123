import Conversation from 'models/conversation'

Vue.component 'inbox2-agent-assignment',
  props:
    conversation: Conversation

  data: ->
    selectedTeamId: null
    selectedAgentId: null
    teamRefs: []

  computed:
    assignedAgent: ->
      @$root.agents.find (agent) => agent.id == @conversation.handoverSession.assignedAgentId
    teams: ->
      @$root.agentTeams
    assignedAgentLabel: ->
      return '' if !@assignedAgent
      "<div class='avatar-image'>#{@assignedAgent.initials}</div>" +
        @assignedAgent.fullName

  beforeUpdate: ->
    @teamRefs = []

  methods:
    selectedAgent: (teamId, agentId) ->
      @selectedTeamId = teamId
      @selectedAgentId = agentId
      for teamComponent in @teamRefs
        continue if teamComponent.team.id == teamId
        teamComponent.selectedAgentId = ''
    assign: ->
      @$root.track('Inbox handover session re-assigned', conversationId: @conversation.id, toAgentId: @selectedAgentId, toTeamId: @selectedTeamId)
      for teamComponent in @teamRefs
        teamComponent.collapse()
      @$root.api.reassignConversation(@conversation, @selectedTeamId, @selectedAgentId)
    setTeamRef: (el) ->
      @teamRefs.push(el) if el?

  template: '
    <dropdown :chosen="assignedAgentLabel" class="agent-assignment">
      <inbox2-agent-team-member-selection
        :ref="setTeamRef"
        v-for="team in teams"
        :key="team.id"
        :team="team"
        :conversation="conversation"
        @selected-agent="selectedAgent"
        />

      <div class="button-container">
        <button @click="assign" class="btn btn-success">
          {{ t(\'inbox.conversations.assign\') }}
        </button>
      </div>
    </dropdown>
  '
