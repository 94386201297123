
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

export default
  props:
    url: String
    parameters: Object
    limitParameter:
      type: String
      default: 'limit'
    offsetParameter:
      type: String
      default: 'offset'

  emits: ['offset-changed']

  data: ->
    items: []
    error: null
    finished: false
    loading: false
    offset: @parameters[@offsetParameter] || 0

  computed:
    limit: ->
      @parameters[@limitParameter] || 50
    paramString: ->
      params = Object.assign({}, @parameters, offset: @offset)
      Object.entries(params).map((kv) -> kv.map(encodeURIComponent).join('=')).join('&')

  methods:
    fetch: (reload = false) ->
      if reload
        @offset = 0
        @items = []
        @finished = false
      @loading = true
      $.getJSON("#{@url}?#{@paramString}")
        .done (response) =>
          if response.success
            @error = null
            @items = response.data?.items || []
            @finished = true if @items.length < @limit
          else
            @error = response.message
        .fail (response) =>
          @error = response.message
        .always =>
          @loading = false
    goToPrevious: ->
      return if @offset == 0
      @offset = Math.max(@offset - @limit, 0)
      @$emit('offset-changed', @offset)
      @fetch()
    goToNext: ->
      return if @finished
      @offset += @limit
      @$emit('offset-changed', @offset)
      @fetch()

  components:
    ChevronLeftIcon: ChevronLeftIcon
    ChevronRightIcon: ChevronRightIcon
