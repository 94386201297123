
import PlatformApi from 'models/platform_api.coffee'
import { confirm } from 'helpers'
import Invite from 'components/organization_settings/invite.vue'
import Profile from 'components/profile/profile.vue'
import { ActionMenu, Avatar, H1, Label, Popover, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
import { LockOpenIcon, PencilIcon, XCircleIcon } from '@heroicons/vue/outline'

export default
  data: ->
    currentUser: null

  computed:
    mayCreateUsers: ->
      Globals.currentUser.may('create_users', id: Globals.currentUser.organizationId)
    mayAddUsers: ->
      Globals.restrictions.users > @$root.users.length

  watch:
    '$route.params.userId': -> @setCurrentUser()

  created: ->
    @setCurrentUser()

  methods:
    navigateToUser: (userId) ->
      @$router.push(params: userId: userId)
    setCurrentUser: ->
      @currentUser = if @$route.params.userId?
        @$root.users.find (u) => u.id == parseInt(@$route.params.userId)
      else
        null
      return if !@currentUser?
      PlatformApi.Webapp.getUser(@currentUser.id).then (data) =>
        Object.assign(@currentUser, data)
    updateUser: (user) ->
      Object.assign(@currentUser, user)
    addUser: (user) ->
      @$root.users.push(user)
    actionMenuItems: (user) ->
      return [] if user.id == Globals.currentUser.id
      return [] if !Globals.currentUser.may('edit_users', @$root.organization)
      [
        {icon: PencilIcon, label: 'Edit', method: (=> @navigateToUser(user.id))},
        if user.access_locked then {icon: LockOpenIcon, label: 'Unlock PW', method: (=> @unlockPw(user))},
        if user.max_login_attempts then {icon: LockOpenIcon, label: 'Unlock TFA', method: (=> @unlockTfa(user))},
        if Globals.currentUser.may('delete_users', @$root.organization) then {icon: XCircleIcon, label: 'Delete', method: (=> @deleteUser(user))},
      ].filter (item) -> item?
    unlockPw: (user) ->
      PlatformApi.Webapp.unlockUserPw(user)
        .then (data) => Object.assign(user, data)
    unlockTfa: (user) ->
      PlatformApi.Webapp.unlockUserTfa(user)
        .then (data) => Object.assign(user, data)
    deleteUser: (user) ->
      return unless await confirm("Do you really want to delete user “#{user.name}”?")
      PlatformApi.Webapp.deleteUser(user)
        .then =>
          @$root.users = @$root.users.filter (u) -> u.id != user.id
    projectStats: (user) ->
      return [] if !user.session_stats.projects?
      user.session_stats.projects.map (projectStats) =>
        project = @$root.projects.find (project) -> project.uuid == projectStats.projectId
        name: project.name, count: projectStats.handoverSessionCount

  components:
    ActionMenu: ActionMenu
    Avatar: Avatar
    Invite: Invite
    H1: H1
    Label: Label
    Popover: Popover
    Table: Table
    TableCell: TableCell
    TableHeadCell: TableHeadCell
    TableHeadRow: TableHeadRow
    TableRow: TableRow
    TableWrapper: TableWrapper
    Profile: Profile
    XCircleIcon: XCircleIcon
