Vue.component 'search-game',
  props: ['config', 'contentObjectType']
  data: ->
    state: state
    attributeToBeAdded: undefined
  computed:
    primitveAttributes: ->
      @contentObjectType.attributes.filter (attribute) -> !attribute.attribute.conditions
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'invocationUtterance',
            'attributes.questions',
            {
              base: 'attributes',
              condition: ((attribute) -> attribute.showQuickreplies && !attribute.autoQuickreplies),
              suffix: 'quickreplies.label'
            }
            # result messages:
            'emptyResultPresentation',
            'resultPresentation',
          ]
        )
      deep: true
  created: ->
    # make sure game config has all required properties
    Vue.setDefault(@config, 'invocationUtterance', {})
    Vue.setDefault(@config, 'autoSelectQuestion', 'informationGain')
    Vue.setDefault(@config, 'attributes', [])
    Vue.setDefault(@config, 'behavior', {})
    Vue.setDefault(@config.behavior, 'questionCount', 2)
    Vue.setDefault(@config.behavior, 'resultIfLessThan', 10)
    Vue.setDefault(@config.behavior, 'dropConstraints', true)
    Vue.setDefault(@config, 'emptyResultPresentation', {})
    Vue.setDefault(@config, 'resultPresentation', {})
  methods:
    addAttribute: ->
      return if !@attributeToBeAdded?
      @config.attributes.push(
        name: @attributeToBeAdded.attribute.label
      )
      Vue.nextTick =>
        $(@$el).find('#attributes-accordion .collapse:last').collapse(parent: '#attributes-accordion', show: true)
    removeAttribute: (index) ->
      $(@$el).find('.collapse').collapse('hide')
      @config.attributes.splice(index, 1)
  template: '
    <div class="panel panel-default no-margin">
      <div class="panel-heading">
        <h3>This is how the user starts the search</h3>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <label class="non-bold">Invocation utterance</label>
          <multilang-variation-field :dictionary="config.invocationUtterance"/>
        </div>
      </div>

      <div class="panel-heading">
        <h3>Now the Bot will ask for attributes</h3>
      </div>
      <div class="panel-body">
        <h4>Actionable attributes</h4>
        <p>
          Auto-select the most relevant question
          &ensp;
          <label class="sliding-switch">
            <input type="checkbox" true-value="informationGain" false-value="weight" v-model="config.autoSelectQuestion">
            <span class="slider"></span>
          </label>
        </p>
        <div class="panel-group" id="attributes-accordion">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="flex-container">
                <h5 class="width-50">
                  Attribute
                  <info-button info-key="actionable_attribute"/>
                </h5>
                <h5 v-show="config.autoSelectQuestion == \'weight\'">
                  Weight
                  <info-button info-key="weight"/>
                </h5>
              </div>
            </div>
          </div>
          <search-game-attribute
            v-for="attribute, index in config.attributes"
            :key="attribute.$key"
            v-on:remove-attribute="removeAttribute"
            :attribute="attribute"
            :index="index"
            :content-object-type="contentObjectType"
            :auto-select-question="config.autoSelectQuestion"
            />
          </div>
        <div class="form-group flex-container" v-if="contentObjectType">
          <select class="styled-form-field" v-model="attributeToBeAdded">
            <option :value="undefined" selected="true" disabled="true">Select attribute</option>
            <option :value="attr" v-for="attr in contentObjectType.attributes">{{ attr.attribute.label }}</option>
          </select>
          &nbsp;
          <div class="btn btn-primary" @click="addAttribute">add</div>
        </div>
      </div>

      <div class="panel-heading" v-if="config.behavior">
        <h3>Search behavior settings</h3>
      </div>
      <div class="panel-body non-first" v-if="config.behavior">
        <p>
          How many questions should be asked before before showing a first selection of results?
          <select class="styled-form-field" v-model="config.behavior.questionCount">
            <option :value="v" v-for="v in [0,1,2,3,4,5]">{{ v }}</option>
          </select>
        </p>
        <p>
          Show results anyway if there are less than
          <input class="styled-form-field" type="number" min="1" style="width: 6em" v-model="config.behavior.resultIfLessThan">
          results.
        </p>
        <p>
          Auto-adjust the query by dropping contextual constraints to avoid empty result lists:
          <select class="styled-form-field" v-model="config.behavior.dropConstraints">
            <option :value="true">yes</option>
            <option :value="false">no</option>
          </select>
        </p>
      </div>

      <div class="panel-heading">
        <h3>This is how the Bot will present the results</h3>
      </div>
      <div class="panel-body">
        <div class="panel-group" id="messages-accordion">
          <result-nlg :nlg="config.resultPresentation" info-key="search_result_presentation">
            <p>
              Available labels:
            </p>
            <result-labels :attributes="[\'name\', \'id\'].concat(Object.keys(contentObjectType.signature))"/>
          </result-nlg>
          <empty-result-nlg :nlg="config.emptyResultPresentation"/>
        </div>
      </div>
    </div>
  '
