
  import TemplateDisplay from './template_display.vue'
  import TemplateParameters from './template_parameters.vue'
  import { Button, H3, Input } from 'components/generic'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      template: Object

    emits: ['back', 'cancel', 'submit']

    data: ->
      mounted: false

    computed:
      ready: ->
        return false if !@mounted
        @$refs.templateParameters.allParameters.every (param) -> param.defaultValue

    mounted: ->
      @mounted = true

    components:
      Button: Button
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      H3: H3
      Input: Input
      TemplateDisplay: TemplateDisplay
      TemplateParameters: TemplateParameters
