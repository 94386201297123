
  import { Input, Listbox } from 'components/generic'

  export default
    props:
      section: String
      parameter: Object
      template: Object
      context: String
      disabled:
        type: Boolean
        default: false

    computed:
      availableContextParameters: ->
        return [] unless @$root.stageIds
        [
          @$parent.customParameter
        ].concat @$root.availableContextParameters
      defaultFieldLabel: ->
        if @parameter.contextParameter?.label == 'custom'
          'Custom value - this will be the same for all recipients:'
        else
          'If no value is available for a user, this default will be used instead:'
      contextParameterIdAttribute: ->
        if @$root.isBotcore2 then 'key' else 'label'

    methods:
      contextParameterLabel: (parameter) ->
        parameter.humanizedLabel || parameter.label

    components:
      Input: Input
      Listbox: Listbox
