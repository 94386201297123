Vue.component 'dictionary',
  props: ['dictionary', 'language', 'fixedPos']
  data: ->
    variants: []
  created: ->
    @variants = @dictionary[@language] if @language && @dictionary[@language]
    @amendVariants()
    @variants.push(@defaultVariant()) if @variants.length == 0
  watch:
    language: ->
      @variants = if @language && @dictionary[@language]
        @dictionary[@language]
      else
        [@defaultVariant()]
    variants:
      handler: ->
        @amendVariants()
        Vue.set(@dictionary, @language, @variants) if @variants[0].pos?
      deep: true
  methods:
    addVariant: ->
      @variants.push(@defaultVariant())
    removeVariant: (index) ->
      @variants.splice(index, 1)
    defaultVariant: ->
      if @fixedPos then {pos: @fixedPos} else {}
    amendVariants: ->
      # amend old entries without variants
      unless @variants instanceof Array
        @variants = [@variants]
  template: '
    <div>
      <dictionary-entry
        v-for="(variant, index) in variants"
        :key="variant.$key"
        :entry="variant"
        :fixed-pos="fixedPos"
        :index="index"
        :count="variants.length"
        :current-language="language"
        @remove-entry="removeVariant"
        />
      <plus-button label="add a variation" :method="addVariant"/>
    </div>
  '
