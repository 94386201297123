
  import ValueType from 'models/value_type.coffee'
  import { Label, Listbox } from 'components/generic'

  export default
    props:
      modelValue: Object
      disabled:
        type: Boolean
        default: false

    data: ->
      valueTypes = Object.entries(ValueType.TYPES).map ([key, label]) -> {id: key, name: label}
      collectionTypes = Object.entries(ValueType.COLLECTION_TYPES).map ([key, label]) -> {id: key, name: label}

      host: @modelValue
      valueTypes: valueTypes
      collectionTypes: collectionTypes
      valueType: valueTypes.find (vt) => vt.id == @modelValue.valueType
      collectionType: collectionTypes.find (ct) => ct.id == @modelValue.collectionType

    watch:
      valueType: ->
        @host.valueType = @valueType.id
        @update()
      collectionType: ->
        @host.collectionType = @collectionType.id
        @update()

    methods:
      update: ->
        @$emit('update:modelValue', @host)

    components:
      Label: Label
      Listbox: Listbox
