
  import { ActionMenu, Badge, TableCell, TableRow } from 'components/generic'
  import { ArrowUpIcon } from '@heroicons/vue/outline'

  export default
    props:
      interpretation: Object

    data: ->
      node: null

    created: ->
      if @$root.currentBot.config.module(@interpretation.intent.moduleKey)?
        @node = await @$root.currentBot.config.getNode(@interpretation.intent.moduleKey, @interpretation.intent.nodeKey)

    computed:
      roundedConfidence: ->
        parseFloat(@interpretation.confidence.toFixed(2))
      badgeColor: ->
        switch @interpretation.interpretationClass
          when 'HIGH_CONFIDENCE'
            'green'
          when 'LOW_CONFIDENCE'
            'yellow'
          else
            'red'
      botbuilderRouterTarget: ->
        if @interpretation.intent.moduleKey == 'HOOK'
          name: 'hook'
          params: {hookKey: @interpretation.intent.nodeKey}
        else if @interpretation.intent.moduleKey == 'CONTEXT_INITIATIVE'
          name: 'contextParameter'
          params: {parameterKey: @interpretation.intent.nodeKey}
        else
          name: 'module'
          params: {moduleKey: @interpretation.intent.moduleKey}
          hash: '#' + @interpretation.intent.nodeKey
      trainingDataRouterTarget: ->
        name: 'trainingData'
        params: {tab: 'utterances'}
        query: {intents: @interpretation.intent.nodeKey}
      actionMenuItems: ->
        [
          label: 'Show in dialog editor'
          icon: ArrowUpIcon
          iconClass: 'rotate-45'
          method: => @$router.push(@botbuilderRouterTarget)
        ,
          label: 'Show utterances'
          icon: ArrowUpIcon
          iconClass: 'rotate-45'
          method: => @$router.push(@trainingDataRouterTarget)
        ]
      nodeLabel: ->
        @node?.label || @interpretation.intent.nodeKey
      moduleLabel: ->
        @$root.currentBot.config.module(@interpretation.intent.moduleKey)?.label || @interpretation.intent.moduleKey

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      TableCell: TableCell
      TableRow: TableRow
