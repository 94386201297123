import Channel from 'models/channel.coffee'
import IceBreaker from 'models/ice_breaker.coffee'
import PlatformApi from 'models/platform_api.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import FacebookMenu from 'models/facebook_menu.coffee'

export default class FacebookChannel extends Channel
  @SCOPE = 'pages_show_list,pages_messaging,pages_read_engagement,pages_manage_metadata,business_management'

  setConfig: (data = {}) ->
    @pageId = data.pageId
    @pageName = data.pageName
    @greeting = data.greeting || []
    @getStarted = data.get_started || {payload: ''}
    @iceBreakers = (data.ice_breakers || []).map (iceBreakerData) => new IceBreaker(@, iceBreakerData)
    @persistentMenu = (data.persistent_menu || []).map (menuData) => new FacebookMenu(@, menuData)
    @whitelistedDomains = data.whitelisted_domains || []
    # not used in webapp
    @accountLinkingUrl = data.account_linking_url || ''

  fetchPages: (code) ->
    PlatformApi.Webapp.getFacebookPages(code)

  fetchCredentialsAndConnect: (pageId, accessToken) ->
    PlatformApi.Webapp.getFacebookLongLivedToken(accessToken)
      .then (data) =>
        @connect(facebookPageId: pageId, pageAccessToken: data.long_lived_token)

  Object.defineProperties @prototype,
    config:
      get: ->
        greeting: @greeting.filter (g) -> g.text.length > 0
        get_started: @getStarted
        ice_breakers: @iceBreakers.filter((ib) -> ib.items.length > 0).map (iceBreaker) -> iceBreaker.export
        persistent_menu: @persistentMenu.filter((menu) -> menu.items.length > 0).map (menu) -> menu.export
        whitelisted_domains: @whitelistedDomains
    defaultGreeting:
      get: ->
        greeting = @greeting.find (g) => g.locale == 'default'
        return greeting if greeting?
        greeting = {locale: 'default', text: ''}
        @greeting.push(greeting)
        greeting
      set: (greeting) ->
        index = @greeting.findIndex (greeting) => greeting.locale == 'default'
        if index == -1
          index = @greeting.length
        @greeting[index] = greeting
    defaultIceBreaker:
      get: ->
        iceBreaker = @iceBreakers.find (iceBreaker) => iceBreaker.locale == 'default'
        return iceBreaker if iceBreaker?
        iceBreaker = new IceBreaker()
        @iceBreakers.push(iceBreaker)
        iceBreaker
      set: (iceBreaker) ->
        index = @iceBreakers.findIndex (iceBreaker) => iceBreaker.locale == 'default'
        if index == -1
          index = @iceBreakers.length
        @iceBreakers[index] = iceBreaker
    defaultMenu:
      get: ->
        menu = @persistentMenu.find (menu) => menu.locale == 'default'
        return menu if menu?
        menu = new FacebookMenu(@)
        @persistentMenu.push(menu)
        menu
      set: (menu) ->
        index = @persistentMenu.findIndex (menu) => menu.locale == 'default'
        if index == -1
          index = @persistentMenu.length
        @persistentMenu[index] = menu
