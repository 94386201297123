import BotbuilderApi from 'models/botbuilder_api.coffee'
import ContextInitiative from 'models/context_initiative.coffee'

export default class ContextInterface
  @VALUE_PROVIDERS = [
    {key: 'defaultValue', label: 'Default value', description: 'Set a default value that is used for new users'}
    {key: 'initiative', label: 'Initiative', description: 'Have your bot collect the data from the user in a dialog'}
  ]

  constructor: (@botConfig, data = {}) ->
    if data.contextParameter?
      @contextParameter = data.contextParameter
      @parameterKey = data.contextParameter.key
    else
      @parameterKey = data.parameterKey
    @update(data)

  update: (data) ->
    data ||= {}
    @valueProvider = data.valueProvider if data.valueProvider?
    @defaultValue = data.defaultValue if data.defaultValue?
    @initiative = new ContextInitiative(@, data.initiative)
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @initiative.partialUpdate(data.initiative)

  clone: ->
    new ContextInterface(@botConfig, @export)

  load: ->
    BotbuilderApi.getContextInterface(@botConfig.id, @key).then (data) =>
      @update(data)

  save: ->
    BotbuilderApi.updateContextInterface(@)
      .then (data) => @partialUpdate(data)

  Object.defineProperties @prototype,
    export:
      get: ->
        parameterKey: @parameterKey
        valueProvider: @valueProvider
        defaultValue: @defaultValue
        initiative: @initiative.export
    botId:
      get: -> @botConfig.id
    key:
      get: ->
        @parameterKey
    label:
      get: ->
        @contextParameter?.label
    isUserParameter:
      get: ->
        @parameterKey.startsWith('user.')
    understoodValueRef:
      get: ->
        'internal.initiative.understoodValue'
