import GuidGenerator from 'models/guid_generator.coffee'

export default class Synonym
  constructor: (data = {}) ->
    @id = GuidGenerator.newGuid()
    @text = '' if !data.text? && !data.pattern?
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @pattern = data.pattern if data.pattern?
    @text = data.text if data.text?
    this

  Object.defineProperties @prototype,
    isRegEx:
      get: ->
        @pattern? && !@text?
      set: (val) ->
        if val
          @pattern = @text
          delete @text
        else
          @text = @pattern
          delete @pattern
    string:
      get: ->
        if @isRegEx
          @pattern
        else
          @text
      set: (str) ->
        if @isRegEx
          @pattern = str
        else
          @text = str
    export:
      get: ->
        if @isRegEx
          pattern: @pattern
        else
          text: @text
