
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Bot from 'models/bot.coffee'
  import { ActionMenu, Button, DocumentationLink, PageHeader, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      bot: Bot

    data: ->
      integrations: []
      PlusIcon: PlusIcon

    created: ->
      @integrations = await BotbuilderApi.getIntegrations()

    methods:
      createIntegration: ->
        @$router.push(name: 'newIntegration')
      actionMenuItems: (integration) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(name: 'integration', params: {integrationId: integration.id})}
          {icon: XIcon, label: 'Delete', method: => @remove(integration)}
        ]
      remove: (integration) ->
        return unless await confirm("Do you really want to delete integration “#{integration.label}”?")
        integration.delete().then =>
          @integrations = @integrations.filter (itg) -> itg.id != integration.id

    components:
      ActionMenu: ActionMenu
      Button: Button
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
