import { alert } from 'helpers'

Vue.component 'training-datum',
  props: ['trainingDatum', 'index']

  data: ->
    editing: false
    copy: null

  computed:
    utteranceHtml: ->
      TrainingDataHelper.utteranceHtml(@trainingDatum)
    availableGames: ->
      return [] unless @trainingDatum.stage
      Array.unique(
        @trainingDatum.stage.user_intent_signatures.map (intent) -> intent.gameLabel
      ).sort()
    availableIntents: ->
      return [] unless @trainingDatum.stage && @copy.game
      @trainingDatum.stage.user_intent_signatures
        .filter((intent) => intent.gameLabel == @copy.game)
        .map((intent) -> intent.intentLabel)
        .sort()
    actionMenuItems: ->
      items = []
      if ['ANNOTATOR', 'MANUAL', 'AL'].includes(@trainingDatum.origin)
        items.push({icon: 'far fa-times-circle', label: 'Delete', method: @deleteIt})
      if @trainingDatum.origin == 'CREATOR' && @$root.stageBotMap
        botId = @trainingDatum.stageIds.map((stageId) => @$root.stageBotMap[stageId]).filter((id) -> id)[0]
        if botId && game = @$root.botGames[botId][@trainingDatum.game]
          if game.type == 'Generic' then options = {user_intent: @trainingDatum.intent} else {}
          items.unshift(
            icon: 'fa fa-robot'
            label: 'Open in creator'
            href: Routes.edit_project_bot_game_path(Globals.projectId, Globals.stageLevel, botId, game.id, options)
          )
      return items
    editable: ->
      @trainingDatum.origin != 'CREATOR'

  created: ->
    Vue.setDefault(@trainingDatum, '$key', GuidGenerator.newGuid())

  methods:
    edit: ->
      @$emit('edit-training-datum')
      @copy = ObjectProcessor.clone(@trainingDatum, removeTransientProperties: false)
      @editing = true
    cancel: ->
      @editing = false
    cancelMaybeRemove: ->
      @cancel()
      @remove() if !@trainingDatum.id?
    maybeCancel: ->
      @cancel() if @trainingDatum.id?
    save: ->
      url = if @trainingDatum.id?
        Routes.project_training_datum_path(Globals.projectId, Globals.stageLevel, @trainingDatum.id)
      else
        Routes.project_training_data_path(Globals.projectId, Globals.stageLevel)
      $.post(
        url,
        _method: if @trainingDatum.id? then 'patch' else 'post',
        stage_id: @trainingDatum.stage?.uuid
        training_datum:
          game: @copy.game
          intent: @copy.intent
          language: @copy.language
          text: @copy.text
        (response) =>
          if response.success
            Vue.set(@trainingDatum, k, v) for k, v of response.data
          else
            await alert(response.message)
            @remove() if !@trainingDatum.id?
      ).always =>
        @editing = false
    deleteIt: ->
      $.post(
        Routes.project_training_datum_path(Globals.projectId, Globals.stageLevel, @trainingDatum.id),
        _method: 'delete',
        (response) =>
          if response.success
            @remove()
          else
            await alert(response.message)
      )
    remove: ->
      @$emit('remove-training-datum', @index)

  template: '
    <tr>
      <template v-if="editing && trainingDatum.stage">
        <td>
          <select v-model="copy.game" class="form-control">
            <option v-for="game in availableGames" :value="game" v-text="game"/>
          </select>
        </td>
        <td>
          <select v-model="copy.intent" class="form-control">
            <option v-for="intent in availableIntents" :value="intent" v-text="intent"/>
          </select>
        </td>
      </template>
      <template v-else>
        <td class="ellipsis">{{ trainingDatum.game }}</td>
        <td class="ellipsis">{{ trainingDatum.intent }}</td>
      </template>
      <td class="ellipsis">{{ trainingDatum.origin.toLowerCase() }}</td>
      <template v-if="editing">
        <td>
          <select v-model="copy.language" class="form-control">
            <option v-for="language, code in $root.languages" :value="code">{{ language }}</option>
          </select>
        </td>
        <td>
          <input type="text" v-model="copy.text" class="form-control" @keyup.esc="maybeCancel"/>
        </td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button @click="save" class="btn btn-icon primary permanent">
              <span class="fa fa-check"></span>
            </button>
            <button @click="cancelMaybeRemove" class="btn btn-icon permanent">
              <span class="fa fa-times"></span>
            </button>
          </div>
        </td>
      </template>
      <template v-else>
        <td class="ellipsis">{{ $root.languages[trainingDatum.language] }}</td>
        <td class="ellipsis" v-html="utteranceHtml"></td>
        <td class="text-right">
          <div class="btn-icon-cell-2">
            <button v-if="editable" @click="edit" class="btn btn-icon">
              <span class="fa fa-pen"></span>
            </button>
            <action-menu v-if="actionMenuItems.length" :items="actionMenuItems" class="auto-margin-left"/>
            <div v-if="!editable && !actionMenuItems.length" class="btn invisible">&nbsp;</div>
          </div>
        </td>
      </template>
    </tr>
  '
