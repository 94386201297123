window.RightColumnCollapser =
  data: ->
    reallyShowRightColumn: false
  watch:
    showRightColumn: ->
      if @showRightColumn
        @state.closeRightColumn()
        @reallyShowRightColumn = true
        Vue.nextTick =>
          $(@$el).find('.responsive-grid-item:last-child > .collapse').collapse('show')
      else
        $(@$el).find('.responsive-grid-item:last-child > .collapse').collapse('hide')
        $(@$el).one('hidden.bs.collapse', => @reallyShowRightColumn = false)
  mounted: ->
    Vue.nextTick =>
      @setHeight()
      $(window).resize(@setHeight)
  unmounted: ->
    $(window).off('resize')
  methods:
    setHeight: ->
      rightColumnTop = $(@$el).find('.wizard-row-container > div').last().position().top
      if rightColumnTop > 0
        $(@$el).find('.wizard-row-container').addClass('one-column')
      else
        $(@$el).find('.wizard-row-container').removeClass('one-column')
