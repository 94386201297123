
  import Conversation from 'models/conversation'

  import { ChevronLeftIcon, InformationCircleIcon, UserCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      conversation: Conversation

    components:
      ChevronLeftIcon: ChevronLeftIcon
      InformationCircleIcon: InformationCircleIcon
      UserCircleIcon: UserCircleIcon
