
  import Version from 'components/botbuilder/versions/version.vue'
  import VersionTable from 'components/botbuilder/versions/version_table.vue'
  import { Button, PageHeader, ScreenOverlay } from 'components/generic'

  export default
    data: ->
      page: 0
      view: 'table'

    methods:
      openOverlay: ->
        @$refs.overlay.open()

    components:
      Button: Button
      PageHeader: PageHeader
      ScreenOverlay: ScreenOverlay
      Version: Version
      VersionTable: VersionTable
