export default class SlotRole
  constructor: (data = {}) ->
    @reference = data.reference # for use in property expressions
    @key = data.key # name
    @valueType = data.valueType # name

  Object.defineProperties @prototype,
    computedCategory:
      get: ->
        'slot'
    label:
      get: ->
        @key
