
  import PlatformApi from 'models/platform_api.coffee'
  import WhatsappTemplate from 'models/whatsapp_template.coffee'
  import ProgressBar from './progress_bar.vue'
  import TemplateEditing from './template_editing.vue'
  import TemplatePreviewSubmit from './template_preview_submit.vue'
  import TemplateSubmitted from './template_submitted.vue'

  export default
    props:
      template: Object

    data: ->
      steps: [
        { name: 'Define template', completed: false }
        { name: 'Preview and submit', completed: false }
      ]
      currentStep: 0
      errorMessage: null

    methods:
      previousStep: ->
        @currentStep -= 1
      nextStep: ->
        @currentStep += 1
      submit: ->
        PlatformApi.WaTemplates.createTemplate(@template.channelId, @template.dataForCreate)
          .then (data) =>
            @errorMessage = null
            @template.update(data)
            @$root.templates.push(@template)
            @currentStep += 1
          .catch (data) =>
            @errorMessage = if data.meta?.developer_message then "Error message: #{data.meta.developer_message}" else '(No error message given.)'
            @currentStep += 1

    components:
      ProgressBar: ProgressBar
      TemplateEditing: TemplateEditing
      TemplatePreviewSubmit: TemplatePreviewSubmit
      TemplateSubmitted: TemplateSubmitted
