
  import PlatformApi2 from 'models/platform_api2.coffee'

  import PublishingLayout from './layout.vue'
  import { EnvironmentBadge, H3, Switch } from 'components/generic'
  import { ChevronDoubleLeftIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      debounceTimeout: null
      allEnvironments: []
      settings: {}
      testsAvailable: false #Globals.restrictions.dialog_tests
      testAvailabilityInfo: 'This feature is not available in your subscription plan.' if !Globals.restrictions.dialog_tests

    computed:
      environments: ->
        DEV: @allEnvironments.find (env) -> env.environment == 'DEV'
        TEST: @allEnvironments.find (env) -> env.environment == 'TEST'
        LIVE: @allEnvironments.find (env) -> env.environment == 'LIVE'

    created: ->
      PlatformApi2.getDeploymentSettings().then (data) =>
        @settings = data
      PlatformApi2.getEnvironments().then (environments) =>
        @allEnvironments = environments

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          PlatformApi2.updateDeploymentSettings(@settings)
        , 1000)

    components:
      ChevronDoubleLeftIcon: ChevronDoubleLeftIcon
      EnvironmentBadge: EnvironmentBadge
      H3: H3
      PublishingLayout: PublishingLayout
      Switch: Switch
