
  import Message from 'models/inbox_message'

  import Bubble from './bubble.vue'
  import DebugMessage from './debug_message.vue'
  import { InformationCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      message: [Message, Object]
      mirrored: Boolean
      isLast:
        type: Boolean
        default: false
      hideTimestamp:
        type: Boolean
        default: false

    emits: ['click', 'set-node']

    data: ->
      expanded: false # for SYSTEM messages only

    computed:
      outerClass: ->
        switch @message.origin
          when 'BOT'
            if @mirrored
              'self-end bg-gray-200 text-gray-700 text-lg rounded-br-none'
            else
              'self-start bg-gray-200 text-gray-700 text-lg rounded-bl-none'
          when 'HANDOVER'
            if @mirrored
              'self-end bg-MercuryGrey-600 text-white text-lg rounded-br-none'
            else
              'self-start bg-MercuryGrey-600 text-white text-lg rounded-bl-none'
          when 'USER'
            if @mirrored
              'self-start bg-MercuryBlue-500 text-white text-lg rounded-bl-none'
            else
              'self-end bg-MercuryBlue-500 text-white text-lg rounded-br-none'
          when 'SYSTEM', 'DEBUG'
            if @expanded
              'self-center text-center bg-MercuryYellow-50 text-gray-600 text-base'
            else
              'w-60 shrink-0 self-center text-center bg-MercuryYellow-50 text-gray-600 text-sm p-1 px-3 sm:px-4 lg:px-5 rounded-2xl shadow-md flex justify-center'
      innerClass: ->
        switch @message.origin
          when 'BOT'
            if @mirrored then 'text-gray-500 text-right' else 'text-gray-500 flex-row-reverse space-x-reverse'
          when 'HANDOVER'
            if @mirrored then 'text-gray-100 text-right' else 'text-gray-100 flex-row-reverse space-x-reverse'
          when 'USER'
            if @mirrored then 'text-MercuryBlue-50 flex-row-reverse space-x-reverse' else 'text-MercuryBlue-50 text-right'
          when 'SYSTEM', 'DEBUG'
            'text-gray-500 text-right'
      displayIntentInfo: ->
        return true if !@$root.currentTab?
        @$root.currentTab.key == 'logs'
      intentIdentifier: ->
        if typeof @message.interpretation?.intent == 'string'
          @message.interpretation.intent
        else
          "#{@message.interpretation?.intent?.moduleKey}/#{@message.interpretation?.intent?.nodeKey}"
      handoverAgentName: ->
        return null if !@message.handoverAgentId
        return null if !(agent = @$root.agents.find (agent) => agent.id == @message.handoverAgentId)
        agent.fullName

    components:
      Bubble: Bubble
      DebugMessage: DebugMessage
      InformationCircleIcon: InformationCircleIcon
