import Conversation from 'models/conversation'
import { cobrowsingUrl } from 'rails_data'
import { alert, hexToRgba } from 'helpers'

Vue.component 'inbox2-conversation',
  props:
    conversation: Conversation

  data: ->
    channelTypeLogos: Globals.channelTypeLogos
    annotationInterface: null
    expanded: false
    handoverWaitTime: null

  computed:
    id: ->
      @conversation.id
    open: ->
      # the 'open' state has to be stored in the model because Vue re-renders the component
      # when the conversation is moved to another sub-list ('today', 'yesterday', ...) of the conversation list
      @conversation.open
    avatarStyle: ->
      return {} if !@conversation.profilePictureURL
      'background-image': "url(\"#{@conversation.profilePictureURL}\")"
    avatarInitials: ->
      return '' if @conversation.profilePictureURL
      @conversation.initials
    lastMessageDate: ->
      l(moment(@conversation.lastMessageTime), 'date') if @conversation.lastMessageTime
    lastMessageTime: ->
      l(moment(@conversation.lastMessageTime), 'time') if @conversation.lastMessageTime
    handoverAssigneeClass: ->
      return '' if !@conversation.isInHandoverMode
      return 'assigned-to-me' if @conversation.handoverSession?.assignedAgentId == @$root.agent.id
      return 'assigned-to-team' if @conversation.handoverSession?.assignedTeamId in @$root.agent.teamIds
      'assigned-to-none'
    formattedHandoverWaitTime: ->
      return if !@handoverWaitTime?
      l(@handoverWaitTime, 'duration')
    handoverWaitTimeLabelClass: ->
      return 'label-danger' if @handoverWaitTime >= @conversation.handoverSession?.responseTimeThresholds?.red
      return 'label-warning' if @handoverWaitTime >= @conversation.handoverSession?.responseTimeThresholds?.yellow
      'label-info'
    mayHandover: ->
      @$root.activeTab in ['inbox', 'handover'] &&
        @$root.session.permissions.stages?[@conversation.stageId]?.answer
    mayInitHandover: ->
      @mayHandover && !@conversation.isInHandoverMode
    isInHandoverMode: ->
      @mayHandover && @conversation.isInHandoverMode

  watch:
    'conversation.handoverSession':
      handler: -> @setHandoverWaitTime()
      deep: true

  created: ->
    @setHandoverWaitTime()

  mounted: ->
    if @open
      @annotationInterface = @$refs.annotationInterface
      $(@$el).children('.collapse').addClass('in')

  methods:
    expand: (e) ->
      if @isInHandoverMode && !@conversation.lastMessageReadByAnyAgent
        @$emit('decreaseBrowserNotification', @)
      new Promise (resolve, reject) =>
        if @open
          resolve()
          return
        @$emit('expand', @)
        Vue.set(@conversation, 'open', true)
        @loadConversation()
        @conversation.lastMessageReadByAnyAgent = true
        Vue.nextTick =>
          @annotationInterface = @$refs.annotationInterface
          if e? && @$root.activeTab == 'annotation' # i.e. if expanded by click
            @annotationInterface.findAnnotatable('up')
          $(@$el).children('.collapse')
            .one('shown.bs.collapse', => @expanded = true)
            .collapse('show')
          resolve()
    collapse: ->
      return unless @open
      @expanded = false
      $(@$el).children('.collapse')
        .one 'hidden.bs.collapse',
          =>
            Vue.set(@conversation, 'open', false)
            @$emit('collapsed', @)
        .collapse('hide')
    track: (action) ->
      @$root.track("Inbox conversation #{action}", conversationId: @conversation.id)
    loadConversation: ->
      new Promise (resolve, reject) =>
        reject() unless @conversation.stageId?
        @conversation.lastMessageReadByAnyAgent = true
        # load user details
        @$root.api.loadConversation(@conversation)
          .then (data) =>
            @conversation.update(data)
            resolve()
        # load games' intent signatures (not for BC2 because this is needed only for annotation)
        if !@$root.isBotcore2 && !@$root.games[@conversation.stageId]?
          $.getJSON(Routes.user_intent_signatures_project_stage_path(Globals.projectId, Globals.stageLevel, @conversation.stageId))
            .done (data) =>
              Vue.set(@$root.games, @conversation.stageId, data)
    initHandoverMode: ->
      @$root.track('Inbox conversation taken over', conversationId: @conversation.id)
      @$root.sending = true
      @$root.api.initHandover(@conversation)
        .then =>
          Vue.setDefault(@conversation, 'handoverSession', {})
          Vue.set(@conversation.handoverSession, 'timeOpened', new Date().toISOString())
        .catch => await alert('Sorry, handover mode could not be initiated!')
        .finally => @$root.sending = false
    useTemplate: (template) ->
      @$refs.handoverInterface.useTemplate(template)
    addToAnswerTemplates: (message) ->
      return unless @$refs.answerTemplateList?
      @$refs.answerTemplateList.addTemplate(message)
    messageTextSelected: (message) ->
      return unless @annotationInterface?
      @annotationInterface.textSelected(message)
    showDate: (i) ->
      index = Number(i)
      return true if index == 0
      !moment(@conversation.messages[index].timestamp)
        .isSame(moment(@conversation.messages[index - 1].timestamp), 'day')
    setHandoverWaitTime: ->
      if !@conversation.isInHandoverMode
        @handoverWaitTime = null
        return
      if @conversation.handoverSession.timeLastResponse?
        @handoverWaitTime = moment().diff(moment(@conversation.handoverSession.timeLastResponse))
      else
        @handoverWaitTime = moment().diff(moment(@conversation.handoverSession.timeOpened))
      setTimeout(@setHandoverWaitTime, 1000)
    height: ->
      $(@$el).height()
    offsetTop: ->
      $(@$el).offset().top - @$parent.scrollElement.offset().top + @$parent.scrollElement[0].scrollTop
    openCobrowsing: ->
      window.open("#{cobrowsingUrl}?room=#{@conversation.coBrowsingSessionId}",'pagename','resizable=0,height=600,width=1100')
      return false
    hexToRgba: hexToRgba

  template: '
    <div class="conversation" :class="{open: open}" :data-id="id">
      <div @click="expand(); track(\'opened\')" class="flex-container center-items conversation-row" :class="{open: open}">
        <template v-if="!open">
          <div class="avatar-image" :style="avatarStyle">
            {{ avatarInitials }}
            <img :src="channelTypeLogos[conversation.channelType]">
          </div>
          <div class="name" :class="{\'text-bold\': !conversation.lastMessageReadByAnyAgent}">
            {{ conversation.firstName }} {{ conversation.lastName }}
          </div>
          <div class="expand ellipsis" v-html="conversation.lastMessageTeaser"></div>
          <div class="need-annotation">
            <span class="fa fa-circle text-danger" v-if="conversation.annotationsSuggested"></span>
          </div>
          <div class="last-interaction-date">{{ lastMessageDate }}</div>
          <div class="last-interaction-time">{{ lastMessageTime }}</div>
          <div class="handover-indicator" :class="handoverAssigneeClass"></div>
          <div class="handover-wait-time">
            <span v-show="handoverWaitTime" :class="handoverWaitTimeLabelClass" class="label text-small">
              {{ formattedHandoverWaitTime }}
            </span>
          </div>
        </template>

        <div v-if="open" class="expand responsive-grid-container no-gutter flex-container center-items">
          <div class="conversation-name responsive-grid-item item-200 flex-container center-items">
            <div class="avatar-image" :style="avatarStyle">
              {{ avatarInitials }}
              <img :src="channelTypeLogos[conversation.channelType]">
            </div>
            <div class="name expand">{{ conversation.firstName }} {{ conversation.lastName }}</div>
            <div v-if="$root.cobrowsingSessionAvailable" @click="openCobrowsing" class="cobrowsing" />
            <div class="sm:hidden flex justify-end">
              <div @click.stop="collapse(); track(\'closed\')" class="btn btn-plain">
                {{ t(\'inbox.conversations.buttonClose\') }}&ensp;
                <span class="fa fa-times"></span>
              </div>
            </div>
          </div>

          <div class="responsive-grid-item item-400 flex flex-wrap items-center">
            <div v-if="isInHandoverMode">
              <span
                v-for="tag in conversation.handoverTags"
                class="text-center px-3 py-1 rounded-full text-sm font-medium mr-2"
                :style="{color: tag.color, backgroundColor: hexToRgba(tag.color, 0.2)}"
              >
                {{ tag.name }}
              </span>
            </div>
            <div v-if="isInHandoverMode" class="flex flex-wrap items-center ml-auto">
              {{ t(\'inbox.conversations.assignedTo\') }} &ensp;
              <inbox2-agent-assignment v-if="open" :conversation="conversation"/>
            </div>
          </div>

          <div class="tw-hidden sm:flex responsive-grid-item item-200 justify-end">
            <div @click.stop="collapse(); track(\'closed\')" class="btn btn-plain text-right">
              {{ t(\'inbox.conversations.buttonClose\') }}&ensp;
              <span class="fa fa-times"></span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="open" class="collapse">
        <div class="conversation-panel responsive-grid-container no-gutter">

          <inbox2-conversation-details
            v-if="$root.activeTab == \'inbox\' || $root.activeTab == \'handover\'"
            :conversation="conversation"
            class="responsive-grid-item item-200"
            />

          <div class="message-list responsive-grid-item item-400">
            <div class="scroll-container">
              <inbox2-message-list ref="messageList" v-slot="infiniteMessages" :conversation="conversation">
                <template v-for="message, index in infiniteMessages" :key="message.id">
                  <div v-if="showDate(index)" class="message-date flex-container center-content">
                    <div>{{ message.formattedDate }}</div>
                  </div>
                  <inbox2-message
                    :message="message"
                    :conversation="conversation"
                    @text-selected="messageTextSelected"
                    />
                </template>
              </inbox2-message-list>
            </div>

            <div class="message-list-controls flex-container center-items center-content" v-if="mayInitHandover">
              <div class="btn btn-success" @click.stop="initHandoverMode">
                {{ t(\'inbox.conversations.takeOver\') }}
              </div>
            </div>
            <inbox2-handover-interface
              ref="handoverInterface"
              v-if="isInHandoverMode"
              :conversation="conversation"
              @add-to-answer-templates="addToAnswerTemplates"
              class="message-list-controls"
              />
            <div v-if="annotationInterface" class="message-list-controls flex-container rows center-items spread-content">
              <template v-if="annotationInterface.modeIs(\'annotatable-search\', \'utterance-marking\')">
                <div @click="annotationInterface.findAnnotatable(\'up\')" class="btn btn-default">
                  ← Previous annotation
                </div>
                <div @click="annotationInterface.findAnnotatable(\'down\')" class="btn btn-default">
                  Next annotation →
                </div>
              </template>
            </div>
          </div>

          <inbox2-annotation-interface
            v-if="$root.activeTab == \'annotation\'"
            :conversation="conversation"
            ref="annotationInterface"
            class="responsive-grid-item item-500"
            />
          <div v-else class="responsive-grid-item item-200">
            <inbox2-answer-template-list
              ref="answerTemplateList"
              v-if="isInHandoverMode"
              :conversationId="conversation.id"
              @use-template="useTemplate"
              />
          </div>

        </div>
      </div>
    </div>
  '
