Vue.component 'persistent-menu',
  props: ['menuStructure']
  data: ->
    state: state
    languageStates: {}
  computed:
    languageStateTableTitle: ->
      'Labels and URLs filled for:<br><span class="text-muted">(menus will only be generated for fully filled languages, the others will be discarded)</span>'
  watch:
    menuStructure:
      handler: -> @setLanguageStates()
      deep: true
  created: ->
    # set default menu
    Vue.setDefault(@menuStructure, 'locale', 'default')
    Vue.setDefault(@menuStructure, 'composer_input_disabled', false)
    Vue.setDefault(@menuStructure, 'call_to_actions', [])
    # init language states
    @setLanguageStates()
  methods:
    setLanguageStates: ->
      states = {}
      selectors = [
        'call_to_actions.title',
        'call_to_actions.call_to_actions.title',
        'call_to_actions.call_to_actions.call_to_actions.title',
        'call_to_actions.url',
        'call_to_actions.call_to_actions.url',
        'call_to_actions.call_to_actions.call_to_actions.url',
      ]
      for languageCode of @state.languages
        for medium in @state.media
          Vue.setDefault(states, languageCode, {})
          states[languageCode][medium] = LanguageValidator.filledIn(
            @menuStructure,
            languageCode,
            medium,
            selectors
          )
      @languageStates = states
  template: '
    <div>
      <div class="form-group">
        <div class="col-sm-12">
          <label class="sliding-switch non-bold">
            <input type="checkbox" v-model="menuStructure.composer_input_disabled" :disabled="state.disabled ? true : null">
            <span class="slider"></span>
            <span class="switch-label-right">Disable keyboard input</span>
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <language-states-and-selection
            :language-states="languageStates"
            :table-title="languageStateTableTitle"
            />
        </div>
      </div>
      <persistent-menu-item-list
        v-if="menuStructure.call_to_actions"
        :item-list="menuStructure.call_to_actions"
        :level="0"
        />
    </div>
  '
