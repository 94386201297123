import ConditionType from 'models/condition_type.coffee'

availableOperators = {
  '~': 'similar'
  '!~': 'notSimilar'
  '=': 'equals'
  '!=': 'notEquals'
}

Vue.component 'parameterFilter',
  props: ['filter', 'parameter', 'index']

  data: ->
    fixedOperator: null

  computed:
    operators: ->
      if @$root.isBotcore2
        if @fixedOperator?
          return {"#{@fixedOperator}": availableOperators[@fixedOperator]}
        availableOperators
      else
        if @fixedOperator?
          return {"#{@fixedOperator}": availableOperators[@fixedOperator]}
        return TypeValidator.defaultOperators() unless @parameter.type
        availableOperators
    valueLabel: ->
      if @$root.isBotcore2
        @parameter.valueTypeLabel
      else
        TypeValidator.valueLabel(@parameter.type, @filter.operator)
    valid: ->
      if @$root.isBotcore2
        true
      else
        TypeValidator.valueValid(@filter.value, @parameter.type, @filter.operator, acceptLogicalExpressions: false)
    label: ->
      (@parameter.humanizedLabel || @parameter.label) +
        @filter.operator +
        @filter.value
    onlyEquals: ->
      Object.keys(@operators).length == 1 && Object.keys(@operators)[0] == '='
    normalizedValueType: ->
      if @$root.isBotcore2
        @parameter.valueType
      else
        if typeof @parameter.type == 'string' then @parameter.type.toLowerCase() else @parameter.type

  watch:
    'filter.operator': -> @focus()

  created: ->
    if @parameter.presentOrNot
      @filter.value = 'true'
      @$emit('apply-filter')
      return
    if @filter.operator
      @fixedOperator = @filter.operator

  mounted: ->
    $(@$el).find('.dropdown-toggle').dropdown()
    $(@$el).on 'hidden.bs.dropdown', =>
      @remove() if !@filter.operator
    $(@$el).one 'shown.bs.dropdown', =>
      @focus() if @fixedOperator?

  methods:
    apply: ->
      if !@filter.operator
        @remove()
      else
        @$emit('apply-filter')
        @close()
    remove: ->
      @$emit('remove-filter', @index)
    open: ->
      if $(@$el).find('.dropdown-menu').is(':hidden')
        $(@$el).find('.dropdown-toggle').dropdown('toggle')
    close: ->
      if $(@$el).find('.dropdown-menu').is(':not(:hidden)')
        $(@$el).find('.dropdown-toggle').dropdown('toggle')
    focus: ->
      Vue.nextTick =>
        $(@$el).find('input[type="text"]').focus()

  template: '
    <div class="dropdown custom-select parameter-filter">
      <div class="btn btn-dropdown dropdown-toggle" data-toggle="dropdown">
        <span>
          <span @click.stop="remove" class="far fa-times-circle text-muted"></span>
        </span>
        &ensp;
        <span class="filter-label ellipsis">{{ label }}</span>
      </div>
      <ul v-if="!parameter.presentOrNot" class="dropdown-menu dropdown-menu-left" style="max-height: min-content">
        <li v-for="label, operator in operators" @click.stop="">
          <div :class="{radio: !onlyEquals}">
            <label>
              <span :class="{hidden: onlyEquals}">
                <input type="radio" :value="operator" v-model="filter.operator" :class="{hidden: onlyEquals}">
                {{ operators[operator] }}
              </span>
              <div v-if="filter.operator != \'?\'" :class="{\'has-error\': !valid}">
                <datepicker
                  v-if="filter.operator == operator && normalizedValueType == \'date\'"
                  :value="filter.value"
                  class="form-control"
                  @set-date="filter.value = $event"
                  @keyup.enter="apply"
                />
                <input
                  v-else-if="filter.operator == operator"
                  type="text"
                  v-model="filter.value"
                  class="form-control"
                  :placeholder="valueLabel"
                  @keyup.enter="apply"
                />
              </div>
            </label>
          </div>
        </li>
        <li @click="apply" class="apply">
          Apply
        </li>
      </ul>
    </div>
  '
