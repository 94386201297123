
  import ConditionalWidgetTeaser from 'models/conditional_widget_teaser.coffee'
  import DialogModule from 'models/dialog_module.coffee'
  import Payload from 'models/payload.coffee'
  import WidgetTeaserCondition from 'models/widget_teaser_condition.coffee'

  import InitialBehaviorEditor from './initial_behavior_editor.vue'
  import PayloadEditor from 'components/botbuilder/nlg/payload_editor.vue'
  import SettingPanel from '../setting_panel.vue'
  import TeaserConditionEditor from "./teaser_condition_editor.vue";
  import VMdEditor from 'v_md_editor'
  import { ActionMenu, Button, Card, H3, Input, KeyListbox, Label } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: ConditionalWidgetTeaser

    emits: ['update:modelValue', 'remove']

    data: ->
      teaser: @modelValue
      buttonPayloadIsSet: false
      buttonPayload: null
      PlusIcon: PlusIcon
      ConditionalWidgetTeaser: ConditionalWidgetTeaser
      actionMenuItems: [
        {icon: XIcon, label: 'Remove', method: => @$emit('remove')}
      ]

    created: ->
      @setButtonPayload()

    methods:
      setButtonPayload: ->
        @buttonPayload = new Payload(
          {dialogModule: new DialogModule(@$root.currentBot.config)}
          target:
            moduleKey: @teaser.button.payload.game
            nodeKey: @teaser.button.payload.intent
          slots: []
        )
        @buttonPayloadIsSet = true
      updateButtonPayload: ->
        @teaser.button.payload.game = @buttonPayload.target.moduleKey
        @teaser.button.payload.intent = @buttonPayload.target.nodeKey
        @teaser.button.payload.slots = @buttonPayload.slots.map (slot) ->
          {label: slot.slotRole, value: slot.value}
        @update()
      addCondition: ->
        @teaser.contextConditions.push(new WidgetTeaserCondition())
      removeCondition: (index) ->
        @teaser.contextConditions.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @teaser)

    components:
      ActionMenu: ActionMenu
      Button: Button
      Card: Card
      H3: H3
      InitialBehaviorEditor: InitialBehaviorEditor
      Input: Input
      Label: Label
      KeyListbox: KeyListbox
      PayloadEditor: PayloadEditor
      SettingPanel: SettingPanel
      TeaserConditionEditor: TeaserConditionEditor
      VMdEditor: VMdEditor
