
  import Addon from 'models/addon.coffee'
  import PlatformApi from 'models/platform_api.coffee'
  import { Button, Card, H3, Panel } from 'components/generic'
  import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

  export default
    data: ->
      sliderOpen: false,
      selectedAddon: {}
      sending: false

    computed:
      addons: ->
        (@$root.organization.subscription?.available_addons || []).map (data) -> new Addon(data)

    methods:
      closeSlider: ->
        @sliderOpen = false
      openSlider: (addon) ->
        @selectedAddon = addon
        @sliderOpen = true
      addAddon: ->
        @sending = true
        PlatformApi.Webapp.addAddon(@$root.organization.id, @selectedAddon.id)
          .then (data) =>
            @$root.organization.subscription.update(data)
            @closeSlider()
          .finally =>
            @sending = false

    components:
      Button: Button
      Card: Card
      Dialog: Dialog
      DialogOverlay: DialogOverlay
      DialogTitle: DialogTitle
      H3: H3
      Panel: Panel
      TransitionChild: TransitionChild
      TransitionRoot: TransitionRoot
