
  import Summary from './summary.vue'
  import Chart from './chart.vue'
  import DistributionTable from './distribution_table.vue'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    data: ->
      summaryConfig:
        totalHandovers: {label: 'Total handovers', type: 'absolute'}
        handoverRequests: {label: 'Handover requests', type: 'absolute'}
        handoverRate: {label: 'Percentage of users in handover', type: 'rate'}

    computed:
      summary: ->
        ready = @data.handovers.summaryCurrent.status == 'ready'
        current = @data.handovers.summaryCurrent.data
        previous = @data.handovers.summaryPrevious.data
        Object.keys(@summaryConfig).map (key) =>
          key: key
          label: @summaryConfig[key].label
          type: @summaryConfig[key].type
          current: if ready then current[key] else null
          previous: if ready then previous?[key] else null
      series: ->
        ready = @data.handovers.series.status == 'ready'
        data = @data.handovers.series.data
        totalHandovers: {label: 'Handover sessions', data: if ready then data.totalHandovers else []}
        handoverRequests: {label: 'Handover requests', data: if ready then data.handoverRequests else []}
        handoverRate:
          label: 'Percentage of users in handover'
          data: if ready then @rateToPercent(data.handoverRate) else []
      handoversPerTimeframe: ->
        if @data.handovers.perTimeframe.status == 'ready'
          @data.handovers.perTimeframe.data
        else
          {}
      handoversPerChannel: ->
        data = if @data.handovers.perChannel.status == 'ready' then @data.handovers.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)

    methods:
      rateToPercent: (dataseries) ->
        dataseries.map (datapoint) ->
          (datapoint * 100).toFixed(2)

    components:
      Chart: Chart
      DistributionTable: DistributionTable
      Summary: Summary
