class window.GameValidator

  @allValid: (array) ->
    return false if !array?
    array.every((item) -> item.$valid)

  @geolocation: (object) ->
    @string(object) &&
      TypeValidator.valueValid(object, 'GeoLocation', null, false)

  @mailtoLink: (object) ->
    @string(object) &&
      /^mailto:[^\s@]+@[^\s@]+\.[^\s@]+$/.test(object)

  @placeholder: (object) ->
    @string(object) &&
      /^\$\{.+\}$/.test(object)

  @string: (object) ->
    return false if !object?
    return false if object.length == 0
    return true

  @textField: (object, state) ->
    @string(object[state.currentLanguage])

  @telLink: (object) ->
    @string(object) &&
      object.match(/^tel:\+?[\d\.\-\(\)]+$/)?

  @type: (object) ->
    object.type in TypeValidator.availableTypes.concat(state.references) &&
      object.list in [true, false]

  @unique: (object, list) ->
    list.filter((el) -> el == object).length <= 1

  @url: (object) ->
    @string(object) &&
      TypeValidator.valueValid(object, 'bareURL', null, false)

  @variationField: (object) ->
    return false if !object?
    variations = object[state.currentLanguage]
    return false if !variations?
    return false if variations.length == 0
    return false if variations.some((variation) -> variation.length == 0)
    return true
