
import { DisclosureButton, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { CogIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

export default
  data: ->
    open: false

  components:
    DisclosureButton: DisclosureButton
    Menu: Menu
    MenuButton: MenuButton
    MenuItem: MenuItem
    MenuItems: MenuItems
    MenuIcon: MenuIcon
    XIcon: XIcon
    CogIcon: CogIcon
