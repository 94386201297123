
import { countries } from 'rails_data'
import PlatformApi from 'models/platform_api.coffee'
import { Alert, H1, H3, Input, Listbox, Panel } from 'components/generic'

export default
  data: ->
    organization: {}
    country: {}
    owner: {}
    editing: false
    sending: false
    errors: {}
    countries: countries

  computed:
    mayEdit: ->
      Globals.currentUser.may('edit_organization', @$root.organization)
    mayChangeOwner: ->
      Globals.currentUser.id == @$root.organization.owner_id

  watch:
    country: -> @organization.shipping_address.country = @country.code
    owner: -> @organization.owner_id = @owner.id

  created: ->
    @cloneOrganization()

  methods:
    cloneOrganization: ->
      @organization = ObjectProcessor.clone(@$root.organization)
      @country = @countries.find((country) => @organization.shipping_address.country == country.code) || {}
      @owner = @$root.users.find (user) => @organization.owner_id == user.id
    cancel: ->
      @cloneOrganization()
      @editing = false
      @errors = {}
    save: ->
      @sending = true
      PlatformApi.Webapp.saveOrganization(@organization, save_address: 'shipping')
        .then (data) =>
          Object.assign(@$root.organization, data)
          @cloneOrganization()
          @editing = false
          @errors = {}
        .catch (response) =>
          Object.keys(response.errors).forEach (attribute) =>
            @errors[attribute] = response.errors[attribute].join('; ')
        .finally =>
          @sending = false

  components:
    Alert: Alert
    H1: H1
    H3: H3
    Input: Input
    Listbox: Listbox
    Panel: Panel
