
  import Project from 'models/project.coffee'

  import { EnvironmentBadge } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      environment: Globals.stageLevel
      environments: Project.ENVIRONMENTS

    methods:
      setEnvironment: (environment) ->
        url = window.location.href
          .replace(/\/(dev|test|live)\//, "/#{environment.toLowerCase()}/")
          .split('#')[0]
        Turbolinks.visit(url)

    components:
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      EnvironmentBadge: EnvironmentBadge
