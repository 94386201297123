
  import WorkflowBotOutput from 'models/workflow_bot_output.coffee'
  import { alert } from 'helpers'

  import DataTypeEditor from 'components/botbuilder/data_type_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Input, Modal, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { InformationCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: WorkflowBotOutput

    emits: ['update:modelValue', 'remove']

    data: ->
      output: @modelValue
      testOverrideActive: @modelValue.testOverrideValue?

    computed:
      testOverrideLabel: ->
        if @testOverrideActive
          'Value for tests and TryMe'
        else
          'Set value for tests and TryMe'

    methods:
      update: ->
        @$emit('update:modelValue', @output)
      setTestOverrideActive: ->
        @output.testOverrideValue = ''
        @testOverrideActive = true
        @update()
        @$nextTick => @$refs.mockInput.focus()
      showInfo: ->
        alert('As Dialog Tests and TryMe DO NOT run the actual workflows, you can provide a value that is used as a mock value in Dialog Tests and TryMe whenever the workflow is used.')

    components:
      DataTypeEditor:DataTypeEditor
      InformationCircleIcon: InformationCircleIcon
      Input: Input
      Modal: Modal
      Switch: Switch
      UniversalInput: UniversalInput
      XIcon: XIcon
