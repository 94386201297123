Vue.component 'new-widget-config',
  data: ->
    state: state
  computed:
    snippet: ->
      string = """
        <script type="application/javascript" src="https://cdn.mercury.ai/widget/v4/widget.js" id="mercury-widget-snippet" data-key="#{@state.channelKey}\">
        </script>
        <link rel="stylesheet" type="text/css" href="https://cdn.mercury.ai/channels/widget/#{@state.channelKey}/style.css">
        """
      string
  mounted: ->
    $(@$el).find('.btn-copy').copyButton()
  template: '
    <div class="flex-container">
      <pre class="no-margin">{{ snippet }}</pre>
      <div class="btn btn-copy">copy</div>
    </div>
  '
