
  import Validation from 'models/validation.coffee'

  import { Input } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Validation

    emits: ['update:modelValue', 'remove']

    data: ->
      validation: @modelValue
      pristine: true

    watch:
      modelValue: ->
        @validation = @modelValue

    methods:
      update: ->
        @pristine = false
        @$emit('update:modelValue', @validation)
      focus: ->
        @$refs.input.focus()

    components:
      Input: Input
      XIcon: XIcon
