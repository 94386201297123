export default class AvailabilityPeriod
  @ATTRIBUTES = [
    'days'
    'start'
    'end'
  ]

  constructor: (data) ->
    @$errors = {}
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  clone: ->
    new AvailabilityPeriod(ObjectProcessor.clone(@))

  validTime: (string) ->
    hours = [0..23].map (h) -> ('00' + h).slice(-2)
    minutes = [0..59].map (h) -> ('00' + h).slice(-2)
    valid = hours.includes(string.slice(0, 2)) &&
      string[2] == ':' &&
      minutes.includes(string.slice(3, 5)) &&
      (
        string.length == 5 ||
          string.length == 8 &&
          string[5] == ':' &&
          minutes.includes(string.slice(6, 8))
      )

  validate: ->
    @$errors = {}
    if !@days?.length > 0
      @$errors.days = 'Please select at least one day'
    if !@validTime(@start)
      @$errors.start = 'Invalid format (must be hh:mm)'
    if !@validTime(@end)
      @$errors.end = 'Invalid format (must be hh:mm)'
    if @validTime(@start) && @validTime(@end) && @start > @end
      @$errors.end = 'Must be later then start time'
    Object.keys(@$errors).length == 0

  Object.defineProperties @prototype,
    valid:
      get: ->
        @validate()

