import { confirm } from 'helpers'

Vue.component 'callback-editor',
  props: ['callback', 'index']
  data: ->
    state: state
  created: ->
    Vue.setDefault(@callback, 'trigger', {})
    Vue.setDefault(@callback, 'target', {})
    Vue.setDefault(@callback, '$key', GuidGenerator.newGuid())
    @validate()
  watch:
    callback:
      handler: -> @validate()
      deep: true
  methods:
    validate: ->
      Vue.set @callback, '$valid',
        @callback.trigger.$valid && @callback.target.$valid
    remove: (index) ->
      return unless await confirm('Do you really want to delete this callback?')
      @$emit('remove-callback', index)
  template: '
    <div>
      <label v-if="!callback.$valid">
        <span class="fa fa-exclamation-circle text-danger"></span>
      </label>
      <div class="form-group">
        <label>Global intent</label>
        <payload-editor :payload="callback.trigger" fixed-game="Hooks"/>
      </div>
      <div class="form-group">
        <label>Target intent</label>
        <payload-editor :payload="callback.target"/>
      </div>
      <div @click="remove(index)" class="btn btn-caution pull-right">
        remove callback
      </div>
    </div>
  '
