import BotbuilderApi from 'models/botbuilder_api.coffee'
import DialogNode from 'models/dialog_node.coffee'
import ObjectProcessor from 'models/object_processor.coffee'

export default class DialogTarget
  constructor: (@host, data) ->
    data ||= {}
    @nodeKey = data.nodeKey
    @moduleKey = data.moduleKey

  clone: ->
    new DialogTarget(
      @host,
      ObjectProcessor.clone(
        nodeKey: @nodeKey
        moduleKey: @moduleKey
      )
    )

  copy: (target) ->
    @moduleKey = target.moduleKey
    @nodeKey = target.nodeKey

  set: (node) ->
    @moduleKey = node.moduleKey
    @nodeKey = node.key
    @

  reset: ->
    @nodeKey = null
    @moduleKey = null

  aimsAt: (node) ->
    @nodeKey == node.key && (@moduleKey == node.moduleKey || @host.moduleKey == node.moduleKey)

  Object.defineProperties @prototype,
    ofOtherModule:
      get: ->
        @moduleKey? && @moduleKey != @host.moduleKey
    node:
      get: ->
        return null if !@host.dialogModule?
        if @ofOtherModule
          return @host.metaInfo?.externalTargetNodes.find (node) => node.moduleKey == @moduleKey && node.key == @nodeKey
        @host.dialogModule.nodeIndex[@nodeKey]
    label:
      get: ->
        @node?.label || @nodeKey
    identifier:
      get: ->
        "#{@moduleKey || @host.moduleKey}/#{@nodeKey}"
    export:
      get: ->
        if !@moduleKey? && !@nodeKey?
          null
        else if @ofOtherModule
          nodeKey: @nodeKey
          moduleKey: @moduleKey
        else
          nodeKey: @nodeKey
