
import BotCreator from 'components/botbuilder/bot_creator.vue'
import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
import { Button } from 'components/generic'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default
  props: ['projects']

  data: ->
    currentProjectId: Globals.projectId
    currentProjectName: Globals.projectName

  computed:
    mayAddProject: ->
      Globals.organizationRestrictions.projects > @projects.length

  created: ->
    @set()
    $(window).on 'turbolinks:load', => @set()

  methods:
    set: ->
      @currentProjectId = Globals.projectId
      @currentProjectName = Globals.projectName || 'no project'
    goTo: (closeFunction, project) ->
      @currentProjectName = project.name
      closeFunction()

  components:
    BotCreator: BotCreator
    Button: Button
    ChevronDownIcon: ChevronDownIcon
    Popover: Popover
    PopoverButton: PopoverButton
    PopoverPanel: PopoverPanel
    UpgradePrompt: UpgradePrompt
