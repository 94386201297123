Vue.component 'multilang-variation-field',
  props: ['dictionary']

  data: ->
    state: state
    list: []
    canonicalUtteranceList: [] # may only have one element!

  computed:
    languageDefined: ->
      @state.currentLanguage? && @dictionary[@state.currentLanguage]? && @dictionary[@state.currentLanguage].length > 0
    canonicalUtteranceIndex: ->
      @canonicalUtteranceList[-1..][0]

  created: ->
    Vue.setDefault(@dictionary, 'canonical', {})
    @setList()

  watch:
    'state.currentLanguage': ->
      @setList()
    list:
      handler: ->
        value = @list.map (utterance) -> utterance.s
        Vue.set(@dictionary, @state.currentLanguage, value)
        if (index = @list.findIndex((utterance) -> utterance.isCanonical)) >= 0
          @canonicalUtteranceList = [index]
        else
          @canonicalUtteranceList = []
        if @canonicalUtteranceIndex?
          Vue.set(@dictionary.canonical, @state.currentLanguage, @list[@canonicalUtteranceIndex].s)
        else
          Vue.delete(@dictionary.canonical, @state.currentLanguage)
      deep: true
    canonicalUtteranceList:
      handler: ->
        @canonicalUtteranceList.shift() if @canonicalUtteranceList.length > 1
        for utterance, index in @list
          utterance.isCanonical = index == @canonicalUtteranceIndex
      deep: true

  methods:
    newUtterance: (string = '', canonical = false) ->
      s: string
      isCanonical: canonical
    setList: ->
      # need to wrap utterances from dictionary in objects in order to have v-for and v-model combined
      @list = if @languageDefined
        for utterance in @dictionary[@state.currentLanguage]
          s: utterance
          isCanonical: utterance == @dictionary.canonical[@state.currentLanguage]
      else
        [@newUtterance('', true)]
    addUtterance: ->
      @list.push(@newUtterance())
      Vue.nextTick =>
        $(@$el).find('.utterance:last input[type="text"]').focus()
    insertUtterances: (index, items) ->
      return if items.length == 0
      newUtterances = items.map (item) => @newUtterance(item)
      Array.prototype.splice.apply(@list, [index, 0].concat(newUtterances))
      Vue.nextTick =>
        $(@$el).find(".utterance:nth-child(#{index + items.length}) input").first().focus()
    removeUtterance: (index) ->
      @list.splice(index, 1)
    paste: (index, event) ->
      clipboardData = event.clipboardData || window.clipboardData
      items = clipboardData.getData('Text').split('\n')
      # insert first item
      firstItem = items.shift()
      firstRemainder = @list[index].s.slice(0, event.target.selectionStart)
      lastRemainder = @list[index].s[event.target.selectionEnd..]
      @list[index].s = firstRemainder + firstItem + lastRemainder
      # insert remaining items
      @insertUtterances(index + 1, items)

  template: '
    <div>
      <div v-for="(utterance, index) in list" class="flex-container stretch-items sub-form-group utterance">
        <input
          v-model="utterance.s"
          type="text"
          class="form-control"
          @keyup.enter="addUtterance"
          @paste.prevent="paste(index, $event)"
          >
        <div class="canonical-checkbox">
          <label v-if="index == 0" class="non-bold">
            Active learning
            <info-button info-key="active_learning"/>
          </label>
          <input type="checkbox" :value="index" v-model="canonicalUtteranceList" title="canonical">
        </div>
        <div v-if="list.length > 1" @click="removeUtterance(index)" class="btn btn-remove">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>
      <plus-button label="add a variation" :method="addUtterance"/>
    </div>
  '
