<template>
  <div>
    <div class="flex items-start bg-white">
      <TextAttachmentEditor
        v-model="row[0]"
        :index="1"
        :count="3"
        :max-length="row[0].message.valueFor('maxRowTitleLength')"
        height="sm"
        placeholder="row title"
        @focus="edit"
        @blur="endEditing"
        @update:modelValue="update"
        ref="titleEditor"
      />
      <button v-if="editing && deletable" @click="$emit('remove')" class="m-2">
        <XIcon class="h-5 w-5 text-gray-400"/>
      </button>
    </div>

    <TextAttachmentEditor
      v-model="row[1]"
      :index="1"
      :count="3"
      :max-length="row[1].message.valueFor('maxRowDescriptionLength')"
      text-size="text-sm"
      height="sm"
      placeholder="row description"
      @focus="edit"
      @blur="endEditing"
      @update:modelValue="update"
    />
    <ButtonEditor
      v-if="editing"
      v-model="row[2]"
      :deletable="false"
      :hide-label="true"
      :always-editing="true"
      @update:modelValue="update"
      class="bg-white p-1"
    />
  </div>
</template>

<script lang="coffee">
  import ButtonEditor from './button_editor.vue'
  import TextAttachmentEditor from './text_attachment_editor.vue'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Array
      deletable: Boolean

    emits: ['update:modelValue', 'remove']

    data: ->
      row: @modelValue
      editing: false

    watch:
      modelValue: ->
        @row = @modelValue

    methods:
      edit: ->
        @editing = true
        setTimeout(=> # need this, otherwise the click that enabled editing mode triggers the handler
          $(document).on("click.#{@row[0].id}", @endEditing)
        , 0)
      endEditing: (e) ->
        return if @$el.contains(document.activeElement)
        return if @$el.contains(e?.target)
        @editing = false
        @row.forEach (row) -> row.pristine = false
        @row[0].bubble.message.pristine = false
        $(document).off "click.#{@row[0].id}"
      update: ->
        @$emit('update:modelValue', @row)
      focus: ->
        @$refs.titleEditor.focus()

    components:
      ButtonEditor: ButtonEditor
      TextAttachmentEditor: TextAttachmentEditor
      XIcon: XIcon
</script>
