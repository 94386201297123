import { createApp } from 'vue_shims'
import Role from 'models/role.coffee'
import Profile from 'components/profile/profile.vue'

initProfileApp = (element) ->
  createApp(
    data: ->
      user: $(element).data('user')
      organization: $(element).data('organization')
      organizationTfaEnabled: $(element).data('organization-tfa-enabled')
      organizationRoles: $(element).data('organization-roles').map (roleData) -> new Role(roleData)
      projectRoles: $(element).data('project-roles').map (roleData) -> new Role(roleData)

    components:
      Profile: Profile

    template: '
      <div class="flex-1 flex flex-col">
        <main class="flex-1 relative focus:outline-none px-4 sm:px-6 lg:px-8 py-6">
          <Profile context="profile" :original-user="user"/>
        </main>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('profile-app')
      initProfileApp(element)
