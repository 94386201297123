<template>
  <div class="flex-grow overflow-y-auto">
    <ul class="space-y-4">
      <li
        v-for="template in filteredTemplates"
        @click="$emit('select', template)"
        class="cursor-pointer rounded-lg border border-gray-300 bg-white hover:bg-gray-50 px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-MercuryBlue-600 focus-within:ring-offset-2 hover:border-gray-400 group"
      >
        <p class="mb-1 text-sm font-medium text-gray-500 group-hover:text-gray-600">
          {{ template.exampleQuestion }}
        </p>
        <p class="m-0 overflow-x-auto text-base font-medium text-gray-800 group-hover:text-gray-900">
          <template v-for="part in template.parsedMessageText">
            <Badge v-if="part.type == 'expression'" color="gray">
              {{ conversation.context[part.text] || '&nbsp;' }}
            </Badge>
            <template v-else>{{ part.text }}</template>
          </template>
        </p>
        <Badge v-for="tag in template.tags" class="mt-2 mr-2">{{ tag }}</Badge>
      </li>
    </ul>
  </div>

  <!-- search and tag filter -->
  <div class="mt-6 space-y-4">
    <Input
      v-model="filterString"
      :placeholder="t('inbox.filter.searchAnswer')"
    />
    <MultiselectDropdown
      v-model="filterTags"
      :items="$root.answerTemplateTags"
      :label-function="(item) => item"
      :placeholder="t('inbox.filter.selectTag')"
      direction="up"
    />
  </div>
</template>

<script lang="coffee">
  import marked from 'marked'
  import Conversation from 'models/conversation'

  import { Badge, Input, MultiselectDropdown } from 'components/generic'

  export default
    emits: ['select']

    props:
      conversation: Conversation

    data: ->
      filterString: ''
      filterTags: []

    computed:
      filteredTemplates: ->
        @$root.answerTemplates.filter (template) =>
          if @filterTags.length > 0 && @filterTags.filter((tag) => template.tags.includes(tag)).length == 0
            return false
          fullText = template.exampleQuestion + $(marked(template.messageText)).text() # strip off markdown
          fullText.toLowerCase().indexOf(@filterString.toLowerCase()) >= 0

    components:
      Badge: Badge
      Input: Input
      MultiselectDropdown: MultiselectDropdown
</script>
