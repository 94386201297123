import Agent from 'models/agent'

export default class AgentTeam
  @ATTRIBUTES = [
    'name'
    'agents'
    'availabilityMode'
  ]

  constructor: (data) ->
    @id = data.id
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      if attribute == 'agents' && data[attribute]?
        Vue.set(@, attribute, data[attribute].map((data) -> new Agent(data)))
      else
        Vue.set(@, attribute, data[attribute]) if data[attribute]?
    return this
