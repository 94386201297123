Vue.component 'number-input',
  props: ['modelValue', 'name']

  data: ->
    value: @modelValue

  watch:
    value: ->
      @$emit('update:modelValue', @value)
    modelValue: ->
      @value = @modelValue

  methods:
    btnUp: ->
      oldValue = parseInt(@value)
      @value = oldValue + 1
    btnDown: ->
      oldValue = parseInt(@value)
      @value = oldValue - 1

  template: '
    <div class="custom-number">
      <input type="number" step="1" :name="name" v-model="value">
      <div class="quantity-nav">
        <div class="quantity-button quantity-up">
          <span @click="btnUp" class="fa fa-chevron-up"></span>
        </div>
        <div class="quantity-button quantity-down">
          <span @click="btnDown" class="fa fa-chevron-down"></span>
        </div>
      </div>
    </div>
  '
