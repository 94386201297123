import BotbuilderApi from 'models/botbuilder_api.coffee'
import UtteranceCollection from 'models/utterance_collection.coffee'

export default class Hook
  constructor: (@botConfig, data = {}) ->
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key if data.key?
    @label = data.label if data.label?
    @description = data.description if data.description?
    @canBeDeleted = data.canBeDeleted if data.canBeDeleted?
    @utterances = new UtteranceCollection(data.utterances)
    this

  save: ->
    (
      if @key
        BotbuilderApi.updateHook(@)
      else
        BotbuilderApi.createHook(@)
    ).then (hookData) => @update(hookData)

  delete: ->
    BotbuilderApi.deleteHook(@)
      .then => @botConfig.hooks = @botConfig.hooks.filter (hook) => hook.key != @key

  reset: ->
    BotbuilderApi.deleteHook(@)
      .then => @botConfig.bot.loadConfig()

  Object.defineProperties @prototype,
    botId:
      get: ->
        @botConfig.id
    export:
      get: ->
        key: @key
        label: @label
        description: @description
        canBeDeleted: @canBeDeleted
        utterances: @utterances.export
    slotDefinitions:
      get: ->
        Array.unique(
          @utterances.utterances.map((utterance) -> utterance.slots.map((slot) -> slot.definition)).flat(2)
        , (slotDefinition) -> slotDefinition.slotRole
        ).filter (sd) -> sd.slotRole
