
  import FacebookChannel from 'models/facebook_channel.coffee'
  import { facebookAppId } from 'rails_data'

  import MenuEditor from '../menu_editor.vue'
  import WelcomeEditor from './welcome_editor.vue'
  import WhitelistingEditor from './whitelisting_editor.vue'
  import { Button, Listbox, Modal, Tabs } from 'components/generic'

  export default
    props:
      modelValue: FacebookChannel

    data: ->
      channel: @modelValue
      facebookPages: []
      selectedFacebookPage: null
      currentTab: 'welcome'
      tabs: [
        { id: 'welcome', name: 'Welcome messages' }
        { id: 'menu', name: 'Menu' }
        { id: 'whitelisting', name: 'Domain whitelisting' }
      ]

    created: ->
      if !@channel.connected && @$root.connectChannelOnVisit
        @$root.connectChannelOnVisit = false
        @startFacebookFlow()

    methods:
      update: ->
        @$emit('update:modelValue', @channel)
      startFacebookFlow: ->
        params = {
          client_id: facebookAppId
          scope: FacebookChannel.SCOPE
          redirect_uri: "#{window.location.protocol}//#{window.location.host}#{Routes.facebook_fe_callbacks_path()}"
          state: JSON.stringify(redirect_url: window.location.href)
          auth_type: 'rerequest'
        }
        paramsString = Object.entries(params)
          .map ([key, value]) -> key + '=' + encodeURIComponent(value)
          .join('&')
        window.open(
          "https://www.facebook.com/v17.0/dialog/oauth?#{paramsString}"
          'integratedOnboardingWindow',
          'toolbar=no, menubar=no, width=600, height=900, top=100, left=100',
        )
        window.addEventListener('message', @handleMessage)
      handleMessage: (event) ->
        window.removeEventListener('message', @handleMessage)
        queryString = "#{event.data}"
        params = new URLSearchParams(queryString)
        code = params.get('code')
        @channel.fetchPages(code)
          .then (data) =>
            @facebookPages = [name: 'Select'].concat(data)
            @selectedFacebookPage = @facebookPages[0]
            @$refs.facebookPagesModal.open()
      connect: ->
        return if !@selectedFacebookPage?.id?
        @$refs.facebookPagesModal.close()
        @channel.fetchCredentialsAndConnect(@selectedFacebookPage.id, @selectedFacebookPage.access_token)

    components:
      Button: Button
      Listbox: Listbox
      MenuEditor: MenuEditor
      Modal: Modal
      Tabs: Tabs
      WelcomeEditor: WelcomeEditor
      WhitelistingEditor: WhitelistingEditor
