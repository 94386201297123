
  import ContentEntity from 'models/content_entity.coffee'
  import { confirm } from 'helpers'

  import { ActionMenu, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      entity: ContentEntity

    emits: ['remove']

    data: ->
      actionMenuItems: [
        {icon: PencilIcon, label: 'Edit', method: => @$router.push(@routerTarget)}
        {icon: XIcon, label: 'Delete', method: @remove}
      ]

    computed:
      routerTarget: ->
        name: 'contentEntity'
        params: {entityKey: @entity.key}

    methods:
      remove: ->
        return unless await confirm("Do you really want to delete entity “#{@entity.label}”?")
        @entity.delete()
          .then => @$emit('remove', @entity)

    components:
      ActionMenu: ActionMenu
      TableCell: TableCell
      TableRow: TableRow
