Vue.component 'panel-togglable',
  props: ['open', 'parentSelector']

  data: ->
    isOpen: false

  computed:
    collapseElement: ->
      $(@$refs.collapseElement)

  mounted: ->
    if @parentSelector?
      @collapseElement.collapse(parent: @parentSelector, toggle: false)
    @expand() if @open
    # propagate bootstrap events
    @collapseElement.on 'show.bs.collapse', => @$emit('show')
    @collapseElement.on 'shown.bs.collapse', => @$emit('shown')
    @collapseElement.on 'hide.bs.collapse', => @$emit('hide')
    @collapseElement.on 'hidden.bs.collapse', => @$emit('hidden')

  methods:
    toggle: ->
      @isOpen = !@isOpen
      @collapseElement.collapse('toggle')
    expand: ->
      @isOpen = true
      @collapseElement.collapse('show')

  template: '
    <div class="panel panel-togglable">
      <div class="panel-heading">
        <div v-if="$slots.header" class="flex-container center-items">
          <slot name="header"/>
          <a @click="toggle" class="chevron" :aria-expanded="isOpen ? true : null"></a>
        </div>
        <a v-if="$slots.togglingheader" @click="toggle" class="flex-container center-items">
          <slot name="togglingheader"/>
          <div class="chevron"></div>
        </a>
      </div>

      <div ref="collapseElement" class="collapse">
        <div class="panel-body">
          <slot name="body"/>
        </div>
      </div>
    </div>
  '
