import { createApp } from 'vue_shims'

initRemoveProjectsApp = (element) ->
  createApp(
    data: ->
      quantity: $(element).data('quantity')
      currentAllowance: $(element).data('current-allowance')
      projectCount: $(element).data('project-count')
      projectsToBeDisabled: []
      submitted: false

    computed:
      valid: ->
        @projectsToBeDisabled.length == @quantityToBeDisabled
      quantityNumber: ->
        return @quantity if typeof @quantity == 'number'
        0
      quantityToBeDisabled: ->
        Math.max(@projectCount - @currentAllowance + @quantityNumber, 0)

    methods:
      submit: ->
        @submitted = true
        if @valid
          $(@$refs.form).submit()
  ).mount(element)

$(window).on 'turbolinks:load shown.bs.modal', ->
  element = document.getElementById('remove-projects-app')
  initRemoveProjectsApp(element) if element?
