<template>
  <img
    class="flex-none rounded-full bg-gray-50"
    :src="image"
    :alt="typeName"
  />
</template>

<script setup>
    import { computed } from 'vue';
    import instagram from 'images/channels/instagram.png';
    import phone from 'images/logo_phone.png';
    import messenger from 'images/channels/messenger.png';
    import webchat from 'images/channels/webchat.png';
    import whatsapp from 'images/channels/whatsapp.png';

    const props = defineProps({
        type: {
            type: String,
            required: true,
        },
    });

    const image = computed(() => {
        switch (props.type) {
            case 'INSTAGRAM':
                return instagram;
            case 'FACEBOOK':
                return messenger;
            case 'PHONE':
                return phone;
            case 'WHATSAPP':
                return whatsapp;
            case 'WEB':
                return webchat;
            default:
                return '';
        }
    });

    const typeName = computed(() => {
        return props.type.charAt(0).toUpperCase() + props.type.slice(1);
    });
</script>
