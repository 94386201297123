
  import BotbuilderApi from 'models/botbuilder_api'
  import Condition from 'models/condition'
  import KnowledgeSource from 'models/knowledge_source'
  import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
  import Project from 'models/project'
  import { confirm } from 'helpers'

  import BaseUrlEditor from './base_url_editor.vue'
  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import DocumentAdder from './document_adder.vue'
  import DocumentRow from './document_row.vue'
  import StatusIcon from './status_icon.vue'
  import { Api2PaginationWithNumbers, BackLink, Button, DocumentationLink, EnvironmentBadge, H3, H4, Input, Label, PageHeader, Spinner, Switch, Textarea } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon, PencilIcon, PlusIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      source: null
      allLanguages: true
      languages: Object.fromEntries(Object.keys(@$root.languages).map (code) -> [code, false])
      environments: Object.fromEntries(Object.keys(Project.ENVIRONMENTS).map (env) -> [env.key, false])
      pagination: null
      reload: false
      pollingTimeout: null
      searchText: ''
      debounceTimeout: null
      textFileName: ''
      textFileContent: ''
      editing: false
      uploading: false
      Project: Project
      PencilIcon: PencilIcon
      PlusIcon: PlusIcon
      RefreshIcon: RefreshIcon

    computed:
      displaySpinner: ->
        @pagination && @pagination.items.length == 0 &&
          (@pagination.loading || @source.inProgress)
      spinnerMessage: ->
        if @pagination?.loading
          'Loading documents ...'
        else if @source?.inProgress
          'Waiting for documents ...'

    created: ->
      @source = await KnowledgeSource.load(@$route.params.sourceId)
      @setListAttributes()
      window.breadcrumbs.strip()
        .append(text: 'Knowledge', callback: => @$router.push(name: 'knowledge'))
        .append(text: @source.label)
      @updateAndPoll()

    unmounted: ->
      clearTimeout(@pollingTimeout)

    methods:
      setListAttributes: ->
        @allLanguages = @source.languages.length == 0
        @source.languages.forEach (code) => @languages[code] = true
        @source.environments.forEach (env) => @environments[env] = true
      updateLanguages: ->
        @source.languages = Object.entries(@languages).map(([key, val]) -> key if val).compact()
        @allLanguages = @source.languages.length == 0
        @update()
      updateAllLanguages: ->
        if @allLanguages
          Object.keys(@languages).forEach (code) => @languages[code] = false
        else
          Object.keys(@languages).forEach (code) => @languages[code] = true
        @updateLanguages()
      updateEnvironments: (key, val) ->
        switch key
          when 'DEV'
            if val == false
              @environments.TEST = false
              @environments.LIVE = false
          when 'TEST'
            if val == true
              @environments.DEV = true
            else if val == false
              @environments.LIVE = false
          when 'LIVE'
            if val == true
              @environments.DEV = true
              @environments.TEST = true
        @source.environments = Object.entries(@environments).map(([key, val]) -> key if val).compact()
        @update()
      loadDocuments: ->
        if @pagination
          if @reload
            @reload = false
            @pagination.load(page: 0)
          else
            @pagination.load()
        else
          setTimeout(@loadDocuments, 100)
      reloadDocuments: ->
        @reload = true
        @loadDocuments()
        @poll()
      uploadFiles: (files) ->
        @uploading = true
        @source.upload(files)
          .then =>
            @searchText = ''
            @reloadDocuments()
          .finally =>
            @uploading = false
      deleteDocument: (doc, index) ->
        return unless await confirm("Do you really want do delete “#{doc.filename}” from this knowledge source?")
        doc.delete()
          .then => @pagination.remove(index)
      uploadText: ->
        files = [new File([@textFileContent], @textFileName)]
        @uploadFiles(files)
          .then =>
            @textFileName = ''
            @textFileContent = ''
      recrawl: ->
        @reload = true
        @source.recrawl()
          .then(@poll)
      addBaseUrl: ->
        @source.baseUrls.push(new KnowledgeSourceBaseUrl(@source))
      removeBaseUrl: (index) ->
        @source.baseUrls.splice(index, 1)
        @update()
      addCondition: ->
        @source.activeIf = new Condition()
      removeCondition: ->
        @source.activeIf = null
        @update()
      updateAndPoll: (data) ->
        @source.updateStatusModel(data)
        @loadDocuments()
        if @source.inProgress
          @pollingTimeout = setTimeout(@poll, 3000)
      poll: ->
        return if !@source.id
        BotbuilderApi.getKnowledgeSourceStatus(@source.id)
          .then (data) => @updateAndPoll(data)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @source.save()), 1000)
      setPaginationRef: (el) ->
        @pagination = el

    components:
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      BackLink: BackLink
      BaseUrlEditor: BaseUrlEditor
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      ConditionEditor: ConditionEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DocumentAdder: DocumentAdder
      DocumentationLink: DocumentationLink
      DocumentRow: DocumentRow
      EnvironmentBadge: EnvironmentBadge
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      PageHeader: PageHeader
      RefreshIcon: RefreshIcon
      Spinner: Spinner
      StatusIcon: StatusIcon
      Switch: Switch
      Textarea: Textarea
