
  import Button from './button.vue'
  import Label from './label.vue'
  import { SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      buttonLabel: String
      label: String
      disabled:
        type: Boolean
        default: false
      buttonType:
        type: String
        default: 'default'
      align:
        type: String
        default: 'left'
      noButton:
        type: Boolean
        default: false

    emits: ['open', 'opened', 'close']

    data: ->
      $key: GuidGenerator.newGuid()
      isOpen: false

    computed:
      selectedItemInList: ->
        @collection.includes(@modelValue)
      supplementedCollection: ->
        return @collection if @selectedItemInList
        [@modelValue].concat(@collection)

    watch:
      isOpen: ->
        if @isOpen
          @$emit('open')
          setTimeout =>
            $(document).on "click.#{@$key}", (event) =>
              return if $(event.target).closest(".tailwind-ui-dropdown-overlay").get(0) == @$refs.overlay
              @close()
          , 0
        if !@isOpen
          @$emit('close')
          $(document).off "click.#{@$key}"
      modelValue: ->
        @selectedItem = @modelValue

    methods:
      open: ->
        @isOpen = true
      close: ->
        @isOpen = false
      toggle: ->
        @isOpen = !@isOpen

    components:
      Button: Button,
      Label: Label,
      SelectorIcon: SelectorIcon,
