import WidgetTeaserButton from 'models/widget_teaser_button.coffee'
import ConditionalWidgetTeaser from 'models/conditional_widget_teaser.coffee'

export default class WidgetTeaserConfig
  constructor: (@channel, data = {}) ->
    @language = data.language || @channel.defaultLanguage
    @defaultMessage = data.defaultMessage || ''
    @defaultButton = new WidgetTeaserButton(data.defaultButton || {})
    @additionalTeaserMessages = (data.additionalTeaserMessages || []).map (teaserConfig) -> new ConditionalWidgetTeaser(teaserConfig)

  addConditionalTeaser: ->
    @additionalTeaserMessages.push(new ConditionalWidgetTeaser())

  Object.defineProperties @prototype,
    export:
      get: ->
        language: @language
        defaultMessage: @defaultMessage
        defaultButton: @defaultButton.export
        additionalTeaserMessages: @additionalTeaserMessages.map (teaser) -> teaser.export
