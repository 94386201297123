import { createApp } from 'vue_shims'

initUnremoveProjectsApp = (element) ->
  createApp(
    data: ->
      quantity: $(element).data('quantity')
      projects: $(element).data('projects')
      projectsToBeUndisabled: []
      submitted: false
    computed:
      valid: ->
        @projectsToBeUndisabled.length <= @quantity
    methods:
      submit: ->
        @submitted = true
        if @valid
          @$refs.form.submit()
  ).mount(element)

$(window).on 'turbolinks:load shown.bs.modal', ->
  element = document.getElementById('unremove-projects-app')
  initUnremoveProjectsApp(element) if element?
