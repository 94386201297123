Vue.component 'result-nlg',
  props: ['nlg', 'infoKey', 'title']
  template: '
    <panel-togglable>
      <template v-slot:togglingheader>
        <div class="expand">
          <span v-html="title ? title : \'Result presentation\'"></span>
          <info-button :info-key="infoKey"/>
        </div>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!nlg.$valid"></span>
        </div>
      </template>
      <template v-slot:body>
        <slot/>
        <div class="panel-body"></div>
        <nlg-editor :nlg="nlg" :is-result-nlg="true"/>
      </template>
    </panel-togglable>
  '
