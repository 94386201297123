
  import Attachment from 'models/attachment.coffee'

  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Input } from 'components/generic'
  import { FilmIcon, PencilAltIcon, PhotographIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Attachment
      label: String

    emits: ['update:modelValue']

    data: ->
      attachment: @modelValue
      editing: false

    computed:
      isBlank: ->
        @attachment.path?.length == 0
      computedLabel: ->
        return @label if @label?
        @attachment.type
      displayable: ->
        switch @attachment.type
          when 'Image' then @attachment.pathIsUrl
          when 'Video' then @attachment.isEmbeddedVideoPath

    watch:
      modelValue: ->
        @attachment = @modelValue

    created: ->
      @editing = if @isBlank then true else false

    methods:
      reset: ->
        @attachment.path = ''
        @update()
        @edit()
      edit: ->
        @editing = true
        @focus()
      endEditing: ->
        return if @isBlank
        @editing = false
        @attachment.pristine = false
        @attachment.bubble.message.pristine = false
        @update()
      update: ->
        @$emit('update:modelValue', @attachment)
      focus: ->
        @$nextTick => @$refs.input?.focus()

    components:
      Input: Input
      UniversalInput: UniversalInput
      XCircleIcon: XCircleIcon
