
  import BranchOption from 'models/branch_option.coffee'
  import Condition from 'models/condition.coffee'
  import { confirm } from 'helpers'
  import TargetEditor from './target_editor.vue'
  import ConditionEditor from '../condition_editor.vue'
  import { ActionMenu, Button, CollapsibleCard, H3, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: BranchOption
      conditioned:
        type: Boolean
        default: true

    emits: ['update:modelValue', 'remove']

    data: ->
      branch: @modelValue
      targetNode: null
      PlusIcon: PlusIcon
      actionMenuItems:
        [
          icon: XIcon
          label: 'Delete'
          method: =>
            return unless await confirm('Do you really want to delete this branch?')
            @$emit('remove')
        ]

    computed:
      cardColor: ->
        switch @branch.target.node?.type
          when 'BotIntent' then 'green'
          when 'DialogAction' then 'yellow'
          else 'grey'

    watch:
      modelValue: ->
        @branch = @modelValue

    methods:
      edit: ->
        @$refs.card.open()
        @$nextTick => @$refs.targetEditor.open()
      update: ->
        @$emit('update:modelValue', @branch)
      removeCondition: (index) ->
        @branch.conditions.splice(index, 1)
        @update()

    components:
      ActionMenu: ActionMenu
      Button: Button
      CollapsibleCard: CollapsibleCard
      ConditionEditor: ConditionEditor
      H3: H3
      Label: Label
      TargetEditor: TargetEditor
