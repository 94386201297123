
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Channel from 'models/channel.coffee'
  import DialogTest from 'models/dialog_test.coffee'
  import DialogTestElement from 'models/dialog_test_element.coffee'

  import ElementEditor from './element_editor.vue'
  import ElementList from './element_list.vue'
  import ElementTypeButton from './element_type_button.vue'
  import JsonEditor from './json_editor.vue'
  import ResultDisplay from './result_display.vue'
  import { Button, H3, Input, KeyListbox, Label, PageHeader, Switch, Textarea } from 'components/generic'
  import { PlayIcon, RefreshIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      test: null
      languagesForSelector: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}
      channelTypesForSelector: Channel.TYPES
      elementTypeButtons: [
        label: 'User'
        types: ['InvokeWithTextMessage', 'InvokeWithPayload', 'InvokeWithButtonClick']
        color: 'green'
      ,
        label: 'Bot'
        types: ['QueryResponseIntent', 'QueryResponseMessage', 'QueryContext']
        color: 'gray'
      ,
        label: 'System'
        types: ['UpdateContext', 'Reset', 'Comment', 'QueryInterpretedIntent']
        color: 'yellow'
      ]
      selectedElementType: null
      currentElement: null
      debounceTimeout: null
      testRunning: false
      selectedEditor: 'editor'
      PlayIcon: PlayIcon
      RefreshIcon: RefreshIcon
      DialogTestElement: DialogTestElement

    computed:
      pageTitle: ->
        return 'Dialog test' if !@test?
        "Dialog test “#{@test.label}”"

    watch:
      '$route.params.testId': ->
        @setTest()
      selectedElementType: ->
        return if !@selectedElementType?
        @currentElement = new DialogTestElement(@test, type: @selectedElementType)

    created: ->
      @setTest()

    methods:
      setTest: ->
        return if !@$route.params.testId?
        if @$route.params.testId == @test?.id
          return
        BotbuilderApi.getDialogTest(@$route.params.testId)
          .then (test) =>
            @test = test
            window.breadcrumbs.append(text: @test.label)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @debounceTimeout = null
          @test.save()
        , 1000)
      run: ->
        await @test.save() if @debounceTimeout?
        @testRunning = true
        @test.run()
          .finally => @testRunning = false
      editElement: (index) ->
        @currentElement = @test.elements[index]
        @selectedElementType = null
      updateElement: ->
        return if !@currentElement?.id?
        @update()
      addElement: ->
        @test.elements.push(@currentElement)
        @update()
        @resetEditor()
        @$nextTick =>
          @$refs.elementList.scrollToBottom()
      resetEditor: ->
        @selectedElementType = null
        @currentElement = null
        # need this to outsmart Vue:
        @$nextTick =>
          @currentElement = null

    components:
      Button: Button
      ElementEditor: ElementEditor
      ElementList: ElementList
      ElementTypeButton: ElementTypeButton
      H3: H3
      Input: Input
      JsonEditor: JsonEditor
      KeyListbox: KeyListbox
      Label: Label
      PageHeader: PageHeader
      ResultDisplay: ResultDisplay
      Switch: Switch
      Textarea: Textarea
