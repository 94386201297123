
  import PlatformApi from 'models/platform_api.coffee'
  import Agent from 'models/agent'
  import AvailabilityPeriod from 'models/availability_period'
  import { weekdays } from 'rails_data'
  import { confirm } from 'helpers'
  import { Button, DatetimePicker, H3, Label, Modal, MultiSelect, Panel, PanelBottom } from 'components/generic'

  export default
    props:
      user: Object
      project: Object
      disabled:
        type: Boolean
        default: false

    data: ->
      hours: [0..23].map (h) -> ('00' + h).slice(-2)
      weekdays: weekdays
      currentPeriod: null
      currentPeriodIndex: null
      sending: false
      datetimePickerConfig:
        format: 'HH:mm'

    computed:
      slots: ->
        return [] unless @project.agent?.availability?.serviceHours?
        @project.agent.availability.serviceHours.map (sh, index) ->
          sh.days.map (weekday) ->
            index: index
            day: weekday
            start: sh.start
            end: sh.end
        .flat(1)

    mounted: ->
      return unless @show
      # Set the container scroll position based on the current time.
      currentMinute = new Date().getHours() * 60
      @$refs.container.scrollTop =
        (
          (@$refs.container.scrollHeight - @$refs.containerNav.offsetHeight - @$refs.containerOffset.offsetHeight) *
          currentMinute
        ) / 1440

    methods:
      slotStyles: (slot) ->
        y = slot.start.split(':')[0] * 10 + Math.round(slot.start.split(':')[1] / 6)
        height = slot.end.split(':')[0] * 10 + Math.round(slot.end.split(':')[1] / 6) - y
        'grid-row': "#{y + 1} / span #{height + 1}"
      colClass: (slot) ->
        index = @weekdays.map((d) -> d.toUpperCase()).indexOf(slot.day)
        "sm:col-start-#{index + 1}"
      editAvailability: (slot) ->
        @currentPeriodIndex = slot.index
        @currentPeriod = @project.agent.availability.serviceHours[@currentPeriodIndex].clone()
        @$refs.availabilitiesModal.open()
      resetCurrentPeriod: ->
        @currentPeriodIndex = null
        @currentPeriod = null
      addAvailability: ->
        @currentPeriod = new AvailabilityPeriod(
          days: []
          start: '08:00'
          end: '16:00'
        )
        @$refs.availabilitiesModal.open()
      saveAvailability: ->
        return unless @currentPeriod.valid
        # prepare agent object
        agent = @project.agent.clone()
        if !@currentPeriodIndex?
          agent.availability.serviceHours.push(@currentPeriod)
        else
          agent.availability.serviceHours[@currentPeriodIndex] = @currentPeriod
        agent.availability.timezone = @user.time_zone
        # sent off agent object
        @saveAgent(agent)
      removeAvailability: ->
        return unless await confirm('Do you really want to delete this availability period?')
        # prepare agent object
        agent = @project.agent.clone()
        agent.availability.serviceHours.splice(@currentPeriodIndex, 1)
        agent.availability.timezone = @user.time_zone
        # sent off agent object
        @saveAgent(agent)
      saveAgent: (agent) ->
        @sending = true
        PlatformApi.Handover.saveAgent(@project, agent)
          .then (data) =>
            @project.agent = new Agent(data)
          .finally =>
            @sending = false
        @$refs.availabilitiesModal.close()
        @resetCurrentPeriod()

    components:
      Button: Button
      DatetimePicker: DatetimePicker
      H3: H3
      Label: Label
      Modal: Modal
      MultiSelect: MultiSelect
      Panel: Panel
      PanelBottom: PanelBottom
