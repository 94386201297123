import { permissionTypes } from 'rails_data'
import PlatformApi from 'models/platform_api.coffee'
import RolePermission from  'models/role_permission.coffee'

export default class Role
  constructor: (data) ->
    @id = data.id
    @scope = data.scope
    @organizationId = data.organization_id
    @name = data.name
    @deletable = data.deletable
    @permissions = if data.permissions
      data.permissions.map (rolePermissionData) =>
        new RolePermission(@, rolePermissionData)
    else
      permissionTypes[@scope].map (permissionType) =>
        new RolePermission(@, permission_type: permissionType.type, label: permissionType.type, enabled: false)

  clone: ->
    new Role(@export)

  save: ->
    if @id
      PlatformApi.Webapp.updateRole(@)
    else
      PlatformApi.Webapp.createRole(@)

  delete: ->
    PlatformApi.Webapp.deleteRole(@)

  heedImplications: (rolePermission, setter, value) ->
    if value
      permissionType = permissionTypes[@scope].find (pt) -> pt.type == rolePermission.type
      permissionType.implies.forEach (type) =>
        permission = @permissions.find (p) -> p.type == type
        permission[setter] = value
    else
      permissionTypes[@scope].forEach (pt) =>
        if pt.implies.includes(rolePermission.type)
          permission = @permissions.find (p) -> p.type == pt.type
          permission[setter] = value

  Object.defineProperties @prototype,
    export:
      get: ->
        id: @id
        scope: @scope
        organization_id: @organizationId
        name: @name
        deletable: @deletable
        permissions: @permissions.map (permission) -> permission.export
