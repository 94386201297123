
  import ParameterFilter from 'models/parameter_filter.coffee'
  import PlatformApi from 'models/platform_api.coffee'
  import BotbuilderApi from 'models/botbuilder_api.coffee'

  import { Alert, BackendPagination, Badge, Button, Dropdown, ParameterFiltering, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { DownloadIcon, TableIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      globals: Globals
      # request url
      listUrl: Routes.list_project_bot_users_path(Globals.projectId, Globals.stageLevel)
      # request params
      limit: 50
      offset: 0
      sortBy: {lastInteractionTime: '-'}
      filters: (@$root.botUsersSettings.filters || []).map (filter) -> new ParameterFilter(filter)
      initialized: false
      # display
      columns: @$root.botUsersSettings.columns || @$root.fixParameters.map((param) -> param.key)
      columnQuery: ''
      # pagination component
      pagination: null
      # stuff
      DownloadIcon: DownloadIcon

    computed:
      availableParameters: ->
        @$root.fixParameters.concat(@$root.contextParameters).sortByString('label')
      parametersForFiltering: ->
        @$root.contextParameters.sortByString('label')
      filteredParameters: ->
        @availableParameters.filter (param) =>
          param.label.toLowerCase().startsWith(@columnQuery.toLowerCase())
      orderedColumns: ->
        @availableParameters.filter (parameter) =>
          (@columns || []).includes(parameter.key)
      fetchParams: ->
        limit: @limit
        offset: @offset
        sortBy: (direction + parameter for parameter, direction of @sortBy).join(',')
        filters: JSON.stringify(@filters)

    watch:
      columns: ->
        @saveSettings()
      filters: ->
        @saveSettings()
        @fetch(true)

    created: ->
      window.breadcrumbs.enableEnvironmentSelector()

    mounted: ->
      @pagination = @$refs.pagination
      @fetch(true)

    methods:
      openExportUrl: ->
        window.location = Routes.list_project_bot_users_path(
          Globals.projectId,
          Globals.stageLevel,
          Object.assign({}, @fetchParams, {
            limit: 1000000
            format: 'xlsx'
            columns: @orderedColumns.map (parameter) ->
              key: parameter.key
              label: parameter.label + (if parameter.computedCategory then " (#{parameter.computedCategory})" else '')
            startId: null
          })
        )
      saveSettings: ->
        PlatformApi.Webapp.saveProjectSettings(
          Globals.projectId,
          Globals.stageLevel,
          bot_users:
            columns: @columns
            filters: @filters
        )
      selectAll: ->
        @columns = @availableParameters.map (parameter) -> parameter.key
      deselectAll: ->
        @columns = []
      fetch: (reload = false) ->
        @$nextTick => # wait for pagination component to have updated params
          @initialized = true
          @pagination.fetch(reload)
      setSortOption: (keyOrLabel) ->
        if @sortBy[keyOrLabel] == '+'
          @sortBy = {"#{keyOrLabel}": '-'}
        else
          @sortBy = {"#{keyOrLabel}": '+'}
        @fetch(true)
      value: (user, keyOrLabel) ->
        switch keyOrLabel
          when 'userId', 'firstName', 'lastName', 'lastInteractionTime'
            user[keyOrLabel]
          else
            if user.properties?[keyOrLabel] == 'true'
              '<span class="fa fa-check-circle"><span>'
            else if user.properties?[keyOrLabel] == 'false'
              '<span class="fa fa-times-circle"><span>'
            else
              user.properties?[keyOrLabel]
      inboxLink : (user) ->
        Routes.inbox3_project_path(Globals.projectId, Globals.stageLevel) + "/logs##{user['userId']}"

    components:
      Alert: Alert
      BackendPagination: BackendPagination
      Badge: Badge
      Button: Button
      Dropdown: Dropdown
      ParameterFiltering: ParameterFiltering
      TableIcon: TableIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
