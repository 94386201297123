import Addon from 'models/addon'

export default class Subscription
  @ATTRIBUTES = [
    'plan'
    'plan_id'
    'plan_name'
    'status'
    'start'
    'term_end'
    'trial_end'
    'renewal'
    'next_billing'
    'new_plan'
    'payment_cycle'
    'price'
    'payment_method'
    'has_credit_card'
    'addons'
    'available_addons'
    'available_plans'
  ]

  constructor: (data) ->
    @addons = []
    @available_addons = []
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      if ['addons', 'available_addons'].includes(attribute) && data[attribute]?
        @[attribute] = data[attribute].map (data) -> new Addon(data)
      else
        @[attribute] = data[attribute] if data[attribute]?
    return this

  Object.defineProperties @prototype,
    cancelled:
      get: ->
        @status == 'non_renewing' || @status == 'in_trial' && !@next_billing?
    hasPaymentMethod:
      get: ->
        @payment_method == 'invoice' || @payment_method == 'credit_card' && @has_credit_card
    renewalFormatted:
      get: ->
        return '' unless @renewal?
        moment(@renewal).format(t('formats.date'))
    nextBillingFormatted:
      get: ->
        return '' unless @next_billing?
        moment(@next_billing).format(t('formats.date'))
    termEndFormatted:
      get: ->
        return '' unless @term_end?
        moment(@term_end).format(t('formats.date'))
    trialEndFormatted:
      get: ->
        return '' unless @trial_end?
        moment(@trial_end).format(t('formats.date'))
