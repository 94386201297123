
  import UtteranceCollection from 'models/utterance_collection.coffee'
  import Utterance from 'models/utterance.coffee'
  import UtteranceEditor from './utterance_editor.vue'
  import UtteranceGenerator from './utterance_generator.vue'
  import { Button, Label } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: UtteranceCollection
      slotDefinition: Object
      host: Object
      disabled:
        type: Boolean
        default: false
      showLabel:
        type: Boolean
        default: true
      noNewSlots:
        type: Boolean
        default: false

    data: ->
      utteranceCollection: @modelValue
      languageUtterances: []
      showEditor: true
      editorRefs: []
      PlusIcon: PlusIcon

    watch:
      modelValue: ->
        @utteranceCollection = @modelValue
      '$root.currentLanguage': ->
        @showEditor = false
        @$nextTick =>
          @setLanguageUtterances()
          @showEditor = true

    created: ->
      @setLanguageUtterances()

    beforeUpdate: ->
      @editorRefs = []

    methods:
      setLanguageUtterances: ->
        @languageUtterances = @utteranceCollection.forLanguage(@$root.currentLanguage)
      update: ->
        @$emit('update:modelValue', @utteranceCollection)
      remove: (index) ->
        @languageUtterances.splice(index, 1)
        @update()
      addUtterance: ->
        @languageUtterances.push(new Utterance())
        @$nextTick => @editorRefs[-1..][0].focus()
      setEditorRef: (el) ->
        @editorRefs.push(el) if el?
      addUtterances: (utterances) ->
        @utteranceCollection.addUtterancesForLanguage(@$root.currentLanguage, utterances)
        @setLanguageUtterances()
        @update()
      addMultiple: (index, items) ->
        @languageUtterances.splice(index + 1, 0, ...items.map((item) -> new Utterance(text: item)))
        @update()

    components:
      Button: Button
      Label: Label
      UtteranceEditor: UtteranceEditor
      UtteranceGenerator: UtteranceGenerator
