window.WizardComponent =
  data: ->
    state: state
    editing: false
    pristine: true
  computed:
    complete: ->
      Object.keys(@attributes).every (attr) =>
        config = @attributes[attr]
        switch config.type
          when 'Array'
            @["#{attr}Copy"].every((item) -> item.$valid)
          when 'Boolean'
            true
          when 'MultilangString'
            @["#{attr}Copy"][@state.currentLanguage]?.length > 0
          when 'NLG'
            @["#{attr}Copy"].$valid
          when 'NLGfilled'
            @["#{attr}Copy"].$valid && @["#{attr}Copy"].$filled
          when 'String'
            @["#{attr}Copy"].length > 0
          when 'Utterances'
            Object.values(@["#{attr}Copy"]).every (list) ->
              !(list instanceof Array) || list.length >= 3 && list.every((utterance) -> utterance.length > 0)
    dirty: ->
      return true if !@complete
      Object.keys(@attributes).some (attr) =>
        config = @attributes[attr]
        switch config.type
          when 'Boolean', 'String'
            @entity[attr] != @["#{attr}Copy"]
          else
            !ObjectProcessor.equal(@entity[attr], ObjectProcessor.clone(@["#{attr}Copy"]))
    head: ->
      if @role == 'head' then @ else @$parent.head
    allComplete: ->
      if @role == 'head'
        @complete
      else
        @complete && @$parent.allComplete
    someDirty: ->
      if @role == 'head'
        @dirty
      else
        @dirty || @$parent.someDirty
  watch:
    'state.currentLanguage': ->
      for attr, config of @attributes
        continue if config.type != 'MultilangString'
        Vue.setDefault(@["#{attr}Copy"], @state.currentLanguage, config.default[@state.currentLanguage] || config.default.en)
  created: ->
    @state.registerRightColumn(@) if @role == 'tail'
  unmounted: ->
    @state.deregisterRightColumn(@) if @role == 'tail'
  methods:
    setCopy: ->
      for attr, config of @attributes
        @["#{attr}Copy"] = switch config.type
          when 'Boolean'
            if @entity[attr]? then @entity[attr] else config.default
          when 'MultilangString'
            if @entity[attr]
              Object.assign({"#{@state.currentLanguage}": config.default.en}, @entity[attr])
            else
              Object.assign({"#{@state.currentLanguage}": config.default.en}, config.default)
          when 'String'
            @entity[attr] || config.default
          else
            ObjectProcessor.clone(@entity[attr] || config.default)
    edit: ->
      @state.closeRightColumn()
        .then =>
          @editing = true
          Vue.nextTick =>
            $(@$el).find('input').first().focus()
        .catch -> null
    confirm: ->
      return unless @complete
      for attr, config of @attributes
        switch config.type
          when 'Boolean', 'String'
            Vue.set(@entity, attr, @["#{attr}Copy"])
          else
            Vue.set(@entity, attr, ObjectProcessor.clone(@["#{attr}Copy"]))
      if @role == 'tail'
        @$emit('finished') if @pristine
      @pristine = false
      return true
    discard: ->
      @setCopy()
      if @role == 'head'
        @editing = false
      else
        @$emit('discard')
    finished: ->
      return unless @complete
      @confirm()
      if @role == 'head'
        @editing = false
      else
        @$emit('finished')
    # only relevant for tail:
    close: ->
      new Promise (resolve, reject) =>
        if !@head.editing
          resolve()
          return
        if @someDirty
          @state.showDirtyModal(
            @head.name,
            @finished if @allComplete,
            @discard
          ).then(resolve).catch(reject)
        else
          @finished()
          resolve()
