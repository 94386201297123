
  import Label from './label.vue'
  import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Number
      label: String
      step:
        type: Number
        default: 1
      min:
        type: Number
        default: 0
      unit:
        type: String
        default: ''

    data: ->
      number: @modelValue

    watch:
      modelValue: -> @number = @modelValue

    methods:
      decrement: ->
        return if @number - @step < @min
        @number -= @step
        @update()
      increment: ->
        @number += @step
        @update()
      update: ->
        @$emit('update:modelValue', @number)

    components:
      ChevronDownIcon: ChevronDownIcon
      ChevronUpIcon: ChevronUpIcon
      Label: Label
