Vue.component 'pagination',
  props:
    collection:
      default: -> []
    perPage:
      default: 20
    paginationData:
      default: -> {offset: 0}
  data: ->
    currentPage: 0
  computed:
    paginatedCollection: ->
      @collection.slice(@perPage * @currentPage, @perPage * (@currentPage + 1))
    maxPageNumber: ->
      Math.ceil(@collection.length / @perPage) - 1
    pageNumbersForDisplay: ->
      pageNumbers = Array.apply(null, Array(@maxPageNumber + 1)).map((_, i) -> i)
      start = Math.max(@currentPage - 2, 0)
      end = Math.min(@currentPage + 2, @maxPageNumber)
      pageNumbers.slice(start, end + 1)
  methods:
    setPage: (page) ->
      page = @currentPage unless page?
      @currentPage = Math.min(Math.max(page, 0), @maxPageNumber)
      @paginationData.offset = @perPage * @currentPage
    setFirst: ->
      @setPage(0)
    setLast: ->
      @setPage(@maxPageNumber)
  template: '
    <div>
      <slot v-bind="paginatedCollection"></slot>
      <div v-if="maxPageNumber > 0" class="flex-container space-around-content">
        <ul class="pagination no-margin-top">
          <li :class="{disabled: currentPage == 0}">
            <a href="" @click.prevent="setPage(currentPage - 1)">&lsaquo;</a>
          </li>
          <li v-if="pageNumbersForDisplay[0] > 0">
            <a href="" @click.prevent="setFirst">1</a>
          </li>
          <li class="disabled" v-if="pageNumbersForDisplay[0] > 1">
            <a>&hellip;</a>
          </li>
          <li :class="{active: currentPage == number}" v-for="number in pageNumbersForDisplay">
            <a href="" @click.prevent="setPage(number)">{{ number + 1 }}</a>
          </li>
          <li class="disabled" v-if="maxPageNumber - 1 > pageNumbersForDisplay[pageNumbersForDisplay.length - 1]">
            <a>&hellip;</a>
          </li>
          <li v-if="maxPageNumber > pageNumbersForDisplay[pageNumbersForDisplay.length - 1]">
            <a href="" @click.prevent="setLast">{{ maxPageNumber + 1 }}</a>
          </li>
          <li :class="{disabled: currentPage == maxPageNumber}">
            <a href="" @click.prevent="setPage(currentPage + 1)">&rsaquo;</a>
          </li>
        </ul>
      </div>
    </div>
  '
