Vue.component 'quickreply-editor',
  props: ['quickreply', 'autogeneratedQuickrepliesEnabled', 'inSearchAttribute']

  data: ->
    editingState:
      editing: false
      pristine: false

  computed:
    showError: ->
      !@editingState.pristine && !@quickreply.$valid
    isVirtual: ->
      @quickreply.text.text?.match(/<.*>/)

  created: ->
    Vue.setDefault(@quickreply, 'payload', {})
    Vue.setDefault(@quickreply, 'text', {type: 'Text'})
    Vue.setDefault(@quickreply, '$key', GuidGenerator.newGuid())
    @validate()

  mounted: ->
    if @quickreply.isNew
      # user timeout in order to avoid click on "add quick reply" to end editing mode
      setTimeout =>
        @editingState.editing = true
        @editingState.pristine = true
        Vue.delete(@quickreply, 'isNew')
        $(@$el).find('.message-input').focus()
      , 0
    # end editing mode when clicked outside
    $(document).on "click.#{@quickreply.$key}", (event) =>
      if $(event.target).closest('.quickreply').get(0) != @$el
        @endEditing()
      true

  unmounted: ->
    $(document).off "click.#{@quickreply.$key}"

  watch:
    'quickreply.buttonType': -> # must come before "quickreply" watcher, so validation takes place after this one
      if @quickreply.buttonType == 'QuickReply'
        Vue.setDefault(@quickreply, 'payload', {})
      else
        Vue.delete(@quickreply, 'payload')
      if @quickreply.buttonType == 'QuickReplyAutogenerate'
        @$refs.attachmentEditor.setText('<generated>')
        Vue.set(@quickreply, 'maxCount', 10) if @inSearchAttribute
      else
        Vue.delete(@quickreply, 'maxCount')
    quickreply:
      handler: -> @validate()
      deep: true

  methods:
    validate: ->
      return Vue.set(@quickreply, '$valid', true) if @quickreply.buttonType != 'QuickReply'
      Vue.set @quickreply, '$valid',
        @quickreply.text?.$valid &&
        @quickreply.payload?.$valid
    endEditing: ->
      @editingState.editing = false
      @editingState.pristine = false
    remove: ->
      @$emit('remove-quickreply', @quickreply.$key)

  template: '
    <div class="quickreply" :class="{\'with-error\': showError, virtual: isVirtual}" @click.stop="">
      <attachment-editor :attachment="quickreply.text" :message-state="editingState" ref="attachmentEditor"/>
      <select v-show="editingState.editing" v-model="quickreply.buttonType" class="form-control button-type-select" required="">
        <option :value="undefined" disabled="" selected="" hidden="">select type</option>
        <option value="QuickReply">intent</option>
        <option v-if="autogeneratedQuickrepliesEnabled" value="QuickReplyAutogenerate">autogenerate</option>
      </select>
      <payload-editor
        v-if="quickreply.buttonType == \'QuickReply\'"
        v-show="editingState.editing"
        :payload="quickreply.payload"
        :class="{\'has-error\': !quickreply.payload.$valid}"
        />
      <template v-if="quickreply.buttonType == \'QuickReplyAutogenerate\' && quickreply.maxCount != null && editingState.editing">
        <small class="block body-text text-left">Maximal number of quick replies to show:</small>
        <select v-model="quickreply.maxCount" class="form-control button-type-select no-margin-top" required="">
          <option v-for="v in [2,3,4,5,6,7,8,9,10]">{{ v }}</option>
        </select>
      </template>
      <div v-if="editingState.editing" @click="remove" class="btn btn-caution remove-button">delete</div>
    </div>
  '
