
  import WorkflowTrace from 'models/workflow_trace.coffee'

  import { H3 } from 'components/generic'
  import { ChevronLeftIcon } from '@heroicons/vue/outline'
  import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      trace: WorkflowTrace

    emits: ['back']

    components:
      CheckCircleIcon: CheckCircleIcon
      ChevronLeftIcon: ChevronLeftIcon
      H3: H3
      XCircleIcon: XCircleIcon
