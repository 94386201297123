
  import AttributeMapping from 'models/attribute_mapping.coffee'
  import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'
  import Synonym from 'models/synonym.coffee'
  import { confirm } from 'helpers'

  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import SynonymEditor from './synonym_editor.vue'
  import { ActionMenu, Button, CollapsibleCard, KeyListbox, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: AttributeMapping
      attribute: EntitySchemaAttribute

    emits: ['remove']

    data: ->
      mapping: @modelValue
      operatorsForSelect: @attribute.activeComponents.map (component) -> {key: component, label: component}
      debounceTimeout: null
      synonymEditorRefs: []
      actionMenuItems: [
        {icon: XIcon, label: 'Delete', method: @remove}
      ]
      PlusIcon: PlusIcon

    computed:
      title: ->
        return 'New mapping' if !@mapping.component
        @attribute.label + ' ' + @mapping.component + ' ' + @mapping.value
      currentLanguageSynonyms: ->
        @mapping.synonyms[@$root.currentLanguage] ||= []
        @mapping.synonyms[@$root.currentLanguage]

    beforeUpdate: ->
      @synonymEditorRefs = []

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @mapping.save()), 1000)
      remove: ->
        return unless await confirm('Do you really want to delete this mapping?')
        @mapping.delete().then =>
          @$emit('remove')
      open: ->
        @$refs.card.open()
      addSynonym: ->
        @currentLanguageSynonyms.push(new Synonym())
        @$nextTick => @synonymEditorRefs.last.focus()
      removeSynonym: (index) ->
        @currentLanguageSynonyms.splice(index, 1)
        @update()
      setSynonymEditorRef: (index, el) ->
        @synonymEditorRefs[index] = el if el?

    components:
      ActionMenu: ActionMenu
      Button: Button
      CollapsibleCard: CollapsibleCard
      DataTypeDependentInput: DataTypeDependentInput
      KeyListbox: KeyListbox
      Label: Label
      LanguageSelector: LanguageSelector
      SynonymEditor: SynonymEditor
