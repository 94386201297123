# adapted from https://stackoverflow.com/questions/3160277/jquery-table-sort#answer-19947532

class window.TableSorter
  @selector: 'th.sorting-header'
  @initHeaders: =>
    $(@selector).each ->
      return if $(this).find('a').length > 0 # avoid multiple initialization
      $(this).contents().wrap('<a></a>')
      $(this).find('a').append('<div class="chevron"></div>')
  @sortRows: (e) =>
    $header = $(e.target).closest(@selector)
    $headers = $header.closest('tr').find(@selector)
    $table = $header.closest('table')
    $contentRows = $table.find('tr:gt(0)')
    columnIndex = $headers.index($header)

    direction = if $header.hasClass('asc') then 'desc' else 'asc'
    $headers.removeClass('asc').removeClass('desc')
    $header.addClass(direction)

    rows = $contentRows.toArray().sort(TableSorter.comparer(columnIndex))
    rows = rows.reverse() if direction == 'desc'
    $table.append(row) for row in rows
  @getRowValue: (row, columnIndex) =>
    $(row).find('td').eq(columnIndex).text()
  @comparer: (columnIndex) =>
    (a, b) =>
      valA = @getRowValue(a, columnIndex)
      valB = @getRowValue(b, columnIndex)
      if $.isNumeric(valA) && $.isNumeric(valB) then valA - valB else valA.toString().localeCompare(valB)

$(window).on 'turbolinks:load shown.bs.modal', TableSorter.initHeaders
$(document).on 'click', TableSorter.selector, TableSorter.sortRows
