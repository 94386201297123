class window.TrainingDataHelper
  @utteranceHtml: (trainingDatum) ->
    if trainingDatum.utterance && trainingDatum.entities
      string = trainingDatum.utterance
      entities = ObjectProcessor.clone(trainingDatum.entities)
      for entity in entities.sort((a, b) -> b.start - a.start)
        string = "#{string.slice(0, entity.start)}" +
          "<span class='entity'>" +
          "(#{entity.type}|#{entity.slot}|#{string.slice(entity.start, entity.end)})" +
          "</span>" +
          "#{string.slice(entity.end)}"
      string
    else
      trainingDatum.text
