
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

export default
  props:
    apiMethod: Function
    params: Object
    pageSize:
      type: Number
      default: 50

  emits: ['loaded']

  data: ->
    items: []
    error: null
    finished: false
    loading: false
    page: 0

  computed:
    computedParams: ->
      Object.assign({page: @page, pageSize: @pageSize}, @params)

  methods:
    load: ({page} = {page: null}) ->
      if page?
        @page = page
        @items = []
        @finished = false
      @loading = true
      @apiMethod(@computedParams)
        .then (items) =>
          @error = null
          @items = items || []
          @finished = true if @items.length < @pageSize
        .catch (response) =>
          @error = response.message
        .finally =>
          @loading = false
          @$emit('loaded')
    goToPrevious: ->
      return if @page == 0
      @page -= 1
      @load()
    goToNext: ->
      return if @finished
      @page += 1
      @load()

  components:
    ChevronLeftIcon: ChevronLeftIcon
    ChevronRightIcon: ChevronRightIcon
