Vue.component 'empty-result-nlg',
  props: ['nlg', 'title']
  template: '
    <panel-togglable>
      <template v-slot:togglingheader>
        <div class="expand" v-html="title ? title : \'No result\'"/>
        <div class="check">
          <span class="fa fa-exclamation-circle text-danger" v-if="!nlg.$valid"></span>
        </div>
      </template>
      <template v-slot:body>
        <nlg-editor :nlg="nlg"/>
      </template>
    </panel-togglable>
  '
