import { createApp } from 'vue_shims'

initGlobalContextParametersApp = (element) ->
  window.state =
    references: $(element).data('content-object-types')
    projectId: $(element).data('project-id')
    botId: $(element).data('bot-id')

  createApp(
    el: element
    data: ->
      parameters: $(element).data('parameters') || []
    template: '
      <global-context-parameters :parameters="parameters"/>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('global-context-parameters-app')
  initGlobalContextParametersApp(element) if element?
