
  import PlatformApi from 'models/platform_api.coffee'
  import CustomRoles from './custom_roles.vue'
  import { Button, H1, H3, Input, Label, Listbox, Panel, Switch } from 'components/generic'

  export default
    data: ->
      editing: false
      sending: false
      errors: {}
      mayEditRoles: Globals.currentUser.may('grant_organization_permissions', @$root.organization)
      timeout: null
      timeoutOptions: [
        id: 15, name: '15 minutes'
      ,
        id: 30, name: '30 minutes'
      ,
        id: 45, name: '45 minutes'
      ,
        id: 60, name: '60 minutes'
      ,
        id: 120, name: '120 minutes'
      ]
      tfaEnabled: false
      legalLinks: [
        title: 'Terms & conditions',
        routeName: 'terms',
      ,
        title: 'Data processing agreement',
        routeName: 'dpa',
      ]


    created: ->
      @setCopies()

    mounted: ->
      if match = window.location.hash.match(/^#role-(\d+)/)
        roleId = match[1]
        @$refs.customRoles.editById(Number(roleId))

    methods:
      setCopies: ->
        @timeout = @timeoutOptions.find (option) => parseInt(@$root.organization.timeout_in) == option.id
        if !@timeout?
          @timeout = id: parseInt(@$root.organization.timeout_in), name: "#{@$root.organization.timeout_in} minutes"
          @timeoutOptions.push(@timeout)
        @tfaEnabled = @$root.organization.tfa_enabled
      cancel: ->
        @setCopies()
        @editing = false
      save: ->
        PlatformApi.Webapp.saveOrganization(id: @$root.organization.id, tfa_enabled: @tfaEnabled, timeout_in: @timeout.id)
          .then (data) =>
            Object.assign(@$root.organization, data)
            @setCopies()
            @editing = false
            @errors = {}
          .catch (response) =>
            Object.keys(response.errors).forEach (attribute) =>
              @errors[attribute] = response.errors[attribute].join(', ')
          .finally =>
            @sending = false

    components:
      Button: Button
      CustomRoles: CustomRoles
      H1: H1
      H3: H3
      Input: Input
      Label: Label
      Listbox: Listbox
      Panel: Panel
      Switch: Switch
