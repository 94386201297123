import BotbuilderApi from 'models/botbuilder_api'
import ProgressItem from 'models/progress_item'

export default class ProgressCollection
  @load: ->
    BotbuilderApi.getProgressCollection().then (data) -> new ProgressCollection(data)

  constructor: (data = {}) ->
    @key = data.key
    @label = data.label
    @documentationUrl = data.documentationUrl
    @completed = data.completed
    @steps = data.steps.map (stepData) => new ProgressItem(@, stepData)
