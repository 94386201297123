
  import DialogNode from 'models/dialog_node.coffee'
  import Hook from 'models/hook.coffee'
  import Priming from 'models/priming.coffee'

  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { ActionMenu, Badge, Button, CollapsibleCard, Combobox, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Priming

    emits: ['update:modelValue', 'remove']

    data: ->
      priming: @modelValue
      addingNewHook: false
      newHook: null
      PlusIcon: PlusIcon
      actionMenuItems:
        [
          icon: XIcon
          label: 'Delete'
          method: => @$emit('remove')
        ]

    computed:
      hostingDialogNode: ->
        @priming.host if @priming.host instanceof DialogNode

    watch:
      modelValue: ->
        @priming = @modelValue

    methods:
      edit: ->
        @$refs.card.open()
      hookLabel: (hook) ->
        hook?.label
      hookLabelFromKey: (key) ->
        hook = @$root.currentBot.config.hooks.find (hook) -> hook.key == key
        hook?.label || key
      searchHooks: (query) ->
        @$root.currentBot.config.searchHooks(query)
      addHook: ->
        @addingNewHook = true
        @$nextTick => @$refs.newHookSelection.open()
      saveNewHook: ->
        @priming.hookKeys.push(@newHook.key)
        @addingNewHook = false
        @newHook = null
        @update()
      removeHook: (key) ->
        @priming.hookKeys = @priming.hookKeys.filter (hookKey) -> hookKey != key
        @update()
      update: ->
        @$emit('update:modelValue', @priming)
      close: ->
        @$refs.card.close()

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      CollapsibleCard: CollapsibleCard
      Combobox: Combobox
      Label: Label
      TargetEditor: TargetEditor
      XIcon: XIcon
