
  import { KeyListbox } from 'components/generic'

  export default
    props:
      align:
        type: String
        default: 'right'
    data: ->
      languagesForSelector: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}

    components:
      KeyListbox: KeyListbox
