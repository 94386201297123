Vue.component 'bot-reactions',
  props: ['host', 'contextParameters', 'allowLastActiveGame', 'botIntents', 'agentTeams']

  computed:
    elseReaction: ->
      @host.reactions.find((reaction) -> !reaction.conditions?)

  methods:
    addReaction: ->
      @host.reactions.push({conditions: []})
    removeReaction: (index) ->
      @host.reactions.splice(index, 1)

  template: '
    <div>
      <template v-for="(reaction, reactionIndex) in host.reactions" :key="reaction.$key">
        <div v-if="reaction.conditions" class="panel-bordered form-group">
          <bot-reaction
            @remove="removeReaction"
            :reaction="reaction"
            :index="reactionIndex"
            :count="host.reactions.length"
            :agent-teams="agentTeams"
            :bot-intents="botIntents"
            :parameters="host.slots ? contextParameters.concat(host.slots) : contextParameters"
            :allow-last-active-game="allowLastActiveGame"
            class="panel-body"
            />
        </div>
      </template>
      <div class="form-group">
        <plus-button label="add reaction" :method="addReaction"/>
      </div>
      <div class="panel-bordered form-group">
        <bot-reaction
          v-if="elseReaction"
          :reaction="elseReaction"
          :is-else-reaction="true"
          :count="host.reactions.length"
          :agent-teams="agentTeams"
          :bot-intents="botIntents"
          :parameters="host.slots ? contextParameters.concat(host.slots) : contextParameters"
          class="panel-body"
          />
      </div>
    </div>
  '
