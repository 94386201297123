import { createApp } from 'vue_shims'

import AgentTeam from 'models/agent_team'
import textIcon from 'images/icn_message_builder_text.svg'
import imageIcon from 'images/icn_message_builder_image.svg'
import videoIcon from 'images/icn_message_builder_video.svg'
import buttonIcon from 'images/icn_message_builder_button.svg'
import cardIcon from 'images/icn_message_builder_card.svg'
import galleryIcon from 'images/icn_message_builder_gallery.svg'
import resultIcon from 'images/icn_message_builder_result.svg'

initGameEditorApp = (element) ->
  window.state =
    languages: {}
    media: []
    currentLanguage: undefined
    references: undefined
    channelTypes: undefined
    globalContextParameters: undefined
    broadcastPlans: undefined
    projectId: undefined
    botId: undefined
    gameId: undefined
    gameType: undefined
    gameName: undefined
    gameConfig: undefined
    games: undefined
    defaultConfig: undefined
    agentTeams: undefined
    infoUrl: undefined
    intentSignatures: undefined
    intentSignaturesError: false
    intentSignaturesLoading: false
    reloadIntentSignatures: undefined
    allTemplates:
      text: textIcon
      image: imageIcon
      video: videoIcon
      button: buttonIcon
      card: cardIcon
      gallery: galleryIcon
      result: resultIcon

  createApp(
    data: ->
      dirty: false
      state: state
      config: null
      configHash: ''
      override: false
      jsonConfig: ''
      jsonLanguages: ''
      contentObjectType: {}
      languageStates: {}
      nextNavigationUrl: null
      turbolinksState: {}
      discardChangesFunction: -> null

    mounted: ->
      @config = JSON.parse($.htmlDecode(element.getAttribute('data-config'))) # do it like this to get rid of html entities
      @configHash = $(element).data('config-hash')
      # set configurations and available choices
      @state.contentObjectTypes = $(element).data('content-object-types')
      @state.references = Object.keys(@state.contentObjectTypes)
      @contentObjectType = @state.contentObjectTypes[@config.contentObjectType]
      @state.languages = $(element).data('languages')
      @state.currentLanguage = if $(element).data('default-language')
        $(element).data('default-language')
      else
        Object.keys(@state.languages)[0]
      @state.channelTypes = $(element).data('channel-types')
      @state.media = @state.channelTypes.map (medium) -> medium.toLowerCase()
      @state.computedContextParameters = $(element).data('computed-context-parameters')
      @state.globalContextParameters = $(element).data('global-context-parameters')
      @state.broadcastPlans = $(element).data('broadcast-plans')
      @state.projectId = $(element).data('project-id')
      @state.botId = $(element).data('bot-id')
      @state.gameId = $(element).data('game-id')
      @state.gameType = $(element).data('game-type')
      @state.gameName = $(element).data('game-name')
      @state.gameConfig = @config
      @state.games = $(element).data('games')
      @state.defaultConfig = $(element).data('default-config')
      @state.agentTeams = $(element).data('agent-teams').map (data) -> new AgentTeam(data)
      @state.infoUrl = (key) =>
        return unless @state.projectId && @state.botId
        Routes.info_project_bot_games_path(Globals.projectId, Globals.stageLevel, @state.botId, key)
      @state.reloadIntentSignatures = (ignoreGame = false) =>
        @state.intentSignaturesLoading = true
        @state.intentSignaturesError = false
        @buildJsonConfig()
        # fetch bot's intent signature (takes some time, so we do it asynchronically)
        $.post(
          Routes.intent_signatures_project_bot_path(Globals.projectId, Globals.stageLevel, @state.botId)
        , if ignoreGame then {} else
          game_id: @state.gameId
          config: @jsonConfig
          languages: @jsonLanguages
        , (data) =>
          @state.intentSignatures = data
        ).fail( =>
          @state.intentSignatures = null
          @state.intentSignaturesError = true
        ).always( =>
          @state.intentSignaturesLoading = false
        )
      @state.reloadIntentSignatures(ignoreGame: true)
      # show warning modal if user wants to save game but game has been changed in the meantime
      if $(element).data('game-has-changed')?
        $('.game-has-changed-modal #main-modal').modal()
      # warn if user wants to leave page with unsaved changes
      $(document).on 'turbolinks:before-visit', @checkBeforeTurbolinksNavigation
      @turbolinksState = history.state
      history.pushState({step: 1}, 'state 1', location.href) # needed for catching back navigation
      history.pushState({step: 2}, 'state 2', location.href) # needed for catching back navigation
      $(window).on 'popstate', @checkOnPopstate
      window.addEventListener 'beforeunload', @checkBeforeUnload
      # ensure correct slider rendering
      $(element).registerShownBsCollapseWatcherForSlickSliders()
      # init dirty checking
      Vue.nextTick =>
        @dirty = false

    unmounted: ->
      $(document).off 'turbolinks:before-visit'
      $(window).off 'popstate'
      window.removeEventListener 'beforeunload', @checkBeforeUnload

    computed:
      # needing this in order to get old value in config watcher:
      clonedConfig: ->
        ObjectProcessor.clone(@config, removeTransientProperties: false)

    watch:
      clonedConfig:
        handler: (newValue, oldValue) ->
          return if @dirty
          # ignore changes in transient properties:
          return if JSON.stringify(ObjectProcessor.clone(oldValue)) == JSON.stringify(ObjectProcessor.clone(newValue))
          @dirty = true
        deep: true

    methods:
      saveOverriding: ->
        @override = true
        @submit()
      submit: (e) ->
        e?.preventDefault()
        @buildJsonConfig()
        @dirty = false
        # wait until DOM is updated
        Vue.nextTick =>
          $(@$refs.form).submit()
      cancel: (e) ->
        $(document).off('turbolinks:before-visit')
        Turbolinks.visit(e.target.href)
      discardChanges: ->
        @$refs.unsavedChangesModal.close()
        return unless @nextNavigationUrl
        $(document).off('turbolinks:before-visit')
        history.replaceState(JSON.stringify(@turbolinksState), 'turbolinks state', location.href)
        Turbolinks.visit(@nextNavigationUrl)
      discardChangesBack: ->
        @$refs.unsavedChangesModal.close()
        @dirty = false
        history.go(-3)
      checkBeforeTurbolinksNavigation: (e) ->
        if !@dirty
          history.replaceState(JSON.stringify(@turbolinksState), 'turbolinks state', location.href)
          return true
        @nextNavigationUrl = e.originalEvent.data.url
        @discardChangesFunction = @discardChanges
        @$refs.unsavedChangesModal.show()
        false
      checkOnPopstate: (e) ->
        return if e.originalEvent.state.step != 1
        if !@dirty
          history.go(-2)
        else
          history.pushState({step: 2}, 'state 2', location.href)
          @discardChangesFunction = @discardChangesBack
          @$refs.unsavedChangesModal.show()
      checkBeforeUnload: (e) ->
        return if !@dirty
        e.preventDefault()
        e.returnValue = ''
      setLanguageStates: (selectors) ->
        states = {}
        for languageCode of @state.languages
          for medium in @state.media
            Vue.setDefault(states, languageCode, {})
            states[languageCode][medium] = LanguageValidator.filledIn(
              @config,
              languageCode,
              medium,
              selectors
            )
        @languageStates = states
      buildJsonConfig: ->
        @jsonConfig = JSON.stringify(ObjectProcessor.clone(@config))
        @jsonLanguages = JSON.stringify(
          Object.keys(@state.languages).filter((language) =>
            @languageStates[language] && Object.values(@languageStates[language]).some((value) => value != 'none')
          )
        )
  ).mount(element)

# initialize Vue app
$(window).on 'turbolinks:load', ->
  element = document.getElementById('game-editor-app')
  initGameEditorApp(element) if element?
