Vue.component 'recovery-game',
  props: ['config']
  data: ->
    state: state
  computed:
    availableContextParameters: ->
      @state.globalContextParameters.map((cp) -> Object.assign({}, cp, {label: "global.#{cp.label}"}))
  watch:
    config:
      handler: -> @setLanguageStates()
      deep: true
  created: ->
    Vue.setDefault(@config, 'userIntents', [])
    Vue.setDefault(@config, 'botIntents', [])
    # amend old format
    if @config.noParse? && @config.generalError? && @config.startOver
      Vue.set(@config, 'userIntents', [
        label: 'No parse – bot could not understand the user input'
        reactions: [botIntent: 'I did not understand']
      ,
        label: 'General error'
        reactions: [botIntent: 'General error message']
      ,
        label: 'Let’s start over'
        errorCount: @config.startOver.errorCount
        reactions: [botIntent: 'Start over message']
      ])
      Vue.set(@config, 'botIntents', [
        label: 'I did not understand'
        nlg: @config.noParse.nlg
      ,
        label: 'General error message'
        nlg: @config.generalError.nlg
      ,
        label: 'Start over message'
        nlg: @config.startOver.nlg
      ])
      Vue.delete(@config, 'noParse')
      Vue.delete(@config, 'generalError')
      Vue.delete(@config, 'startOver')
    # avoid empty language matrix
    @setLanguageStates()
  methods:
    setLanguageStates: ->
      @$parent.setLanguageStates(
        [
          'botIntents.nlg',
        ]
      )
    addBotIntent: ->
      @config.botIntents.push({})
      Vue.nextTick =>
        $(@$el).find('#bot-intent-accordion .collapse:last').collapse('show')
    removeBotIntent: (index) ->
      @config.botIntents.splice(index, 1)
    collapsibleIntents: (side, action) ->
      $(@$el).find("##{side}-intent-accordion .collapse").collapse(action)
  template: '
    <div>
      <div class="panel panel-default no-margin">
        <div class="panel-heading">
          <h3>Recovery messages</h3>
        </div>
        <div class="panel-body">
          <div class="responsive-grid-container">
            <div class="responsive-grid-item item-300">
              <h4 class="clearfix">
                Triggers
                <div class="pull-right flex-container columns bottom-items">
                  <div @click="collapsibleIntents(\'user\', \'show\')" class="btn btn-icon-light no-padding text-muted">
                    expand all &nbsp; <span class="fa fa-chevron-down"></span>
                  </div>
                  <div @click="collapsibleIntents(\'user\', \'hide\')" class="btn btn-icon-light no-padding text-muted">
                    collapse all &nbsp; <span class="fa fa-chevron-up"></span>
                  </div>
                </div>
              </h4>
              <div class="panel-group" id="user-intent-accordion">
                <user-intent
                  v-for="(intent, index) in config.userIntents"
                  :key="intent.$key"
                  :intent="intent"
                  :index="index"
                  :intent-list="config.userIntents"
                  :bot-intents="config.botIntents"
                  :context-parameters="availableContextParameters"
                  :is-recovery-intent="true"
                  :has-error-count="intent.label == \'Let’s start over\'"
                  />
              </div>
            </div>

            <div class="responsive-grid-item item-500">
              <h4 class="clearfix">
                Bot intents
                <div class="pull-right flex-container columns bottom-items">
                  <div @click="collapsibleIntents(\'bot\', \'show\')" class="btn btn-icon-light no-padding text-muted">
                    expand all &nbsp; <span class="fa fa-chevron-down"></span>
                  </div>
                  <div @click="collapsibleIntents(\'bot\', \'hide\')" class="btn btn-icon-light no-padding text-muted">
                    collapse all &nbsp; <span class="fa fa-chevron-up"></span>
                  </div>
                </div>
              </h4>
              <div class="panel-group" id="bot-intent-accordion">
                <bot-intent
                  v-for="(intent, index) in config.botIntents"
                  :key="intent.$key"
                  :intent="intent"
                  :index="index"
                  :intent-list="config.botIntents"
                  :context-parameters="availableContextParameters"
                  :is-recovery-intent="true"
                  @remove-intent="removeBotIntent"
                  />
              </div>
              <button @click="addBotIntent()" class="btn btn-primary">Add bot intent</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  '
