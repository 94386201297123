
  import Chart from './chart.vue'

  export default
    props:
      data: Object
      labels: Array

    emits: ['update-intents']

    computed:
      messagesPerChannel: ->
        data = if @data.messages.perChannel.status == 'ready' then @data.messages.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)
      usersPerChannel: ->
        data = if @data.users.perChannel.status == 'ready' then @data.users.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)
      handoversPerChannel: ->
        data = if @data.handovers.perChannel.status == 'ready' then @data.handovers.perChannel.data else {}
        ids = Object.keys(data)
        data: ids.map (id) => data[id]
        labels: ids.map (id) => @$root.channelLabel(id)

    components:
      Chart: Chart
