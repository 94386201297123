import DialogTarget from 'models/dialog_target.coffee'

export default class TriggerSettings
  constructor: (@dialogModule, data) ->
    data ||= {}
    if @dialogModule.type == 'ActiveLearning'
      @numberOfDisambiguationIntents = data.numberOfDisambiguationIntents
      @activateDisambiguation = data.activateDisambiguation
      @activateLowConfidence = data.activateLowConfidence
    if @dialogModule.type == 'Inactivity'
      @inactivityInMilliSeconds = data.inactivityInMilliSeconds
    if @dialogModule.type == 'Knowledge'
      @knowledgeTrigger = data.knowledgeTrigger
      @maxNumberOfKnowledgeSources = data.maxNumberOfKnowledgeSources
    if @dialogModule.type == 'OptInLegalPrivacy'
      @followUp = new DialogTarget({}, data.followUp)
      @interpretInitInput = data.interpretInitInput
      @askForConsent = data.askForConsent
    if @dialogModule.type == 'Recovery'
      @consecutiveFails = data.consecutiveFails
      @action = data.action
      @confirmHandover = data.confirmHandover

  Object.defineProperties @prototype,
    export:
      get: ->
        obj = {}
        if @dialogModule.type == 'ActiveLearning'
          obj.numberOfDisambiguationIntents = @numberOfDisambiguationIntents
          obj.activateDisambiguation = @activateDisambiguation
          obj.activateLowConfidence = @activateLowConfidence
        if @dialogModule.type == 'Inactivity'
          obj.inactivityInMilliSeconds = @inactivityInMilliSeconds
        if @dialogModule.type == 'Knowledge'
          obj.knowledgeTrigger = @knowledgeTrigger
          obj.maxNumberOfKnowledgeSources = @maxNumberOfKnowledgeSources
        if @dialogModule.type == 'OptInLegalPrivacy'
          obj.followUp = @followUp.export
          obj.interpretInitInput = @interpretInitInput
          obj.askForConsent = @askForConsent
        if @dialogModule.type == 'Recovery'
          obj.consecutiveFails = @consecutiveFails
          obj.action = @action
          obj.confirmHandover = @confirmHandover
        obj
