import { confirm } from 'helpers'

Vue.component 'support-option',
  props: ['option', 'index', 'topic']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    entity: @option
    role: 'head'
    name: 'a solution'
    attributes:
      name:
        type: 'String'
        default: ''
      preconditions:
        type: 'Array'
        default: []
    nameCopy: ''
    preconditionsCopy: []
    conditional: false
    currentPreconditionIndex: null
    viewing: false
    promptRefs: []
  computed:
    someDirty: ->
      @dirty || !@promptsComplete()
    allComplete: ->
      @complete && @promptsComplete()
    showRightColumn: ->
      return true if @viewing
      false
    currentCondition: ->
      return unless @currentPreconditionIndex?
      @option.preconditions[@currentPreconditionIndex]
    currentParameter: ->
      return unless @currentCondition
      @state.availableContextParameters.find((param) => param.label == @currentCondition.label)
    associatedPrompts: ->
      Vue.setDefault(@topic, 'prompts', [])
      Array.unique(
        @option.preconditions.map (condition) =>
          prompt = @topic.prompts.find((prompt) => prompt.contextParameter == condition.label)
          return prompt if prompt?
          prompt =
            contextParameter: condition.label
          @topic.prompts.push(prompt)
          prompt
      )
    currentPromptIndex: ->
      return unless @currentCondition?
      @associatedPrompts.findIndex (prompt) =>
        prompt.contextParameter == @currentCondition.label
  watch:
    editing: ->
      @viewing = false if @editing
    viewing: ->
      @editing = false if @viewing
    conditional: ->
      if @conditional
        @preconditionsCopy ||= []
        @preconditionsCopy.push({}) if @preconditionsCopy.length == 0
      else
        @preconditionsCopy = []
    currentPreconditionIndex: ->
      Vue.nextTick =>
        # ensure correct slider rendering of prompt nlg sliders
        $('.slick-slider').each -> this.slick.setPosition()
  mounted: -> # use mounted so we can immediately focus the input field
    if !@option.name?
      @edit()
    else
      @pristine = false
    @setCopy()
    @conditional = @option.preconditions?.length
  beforeUpdate: ->
    @promptRefs = []
  methods:
    promptsComplete: ->
      @promptRefs.every((component) => component.deepComplete())
    close: ->
      @editing = false
      @viewing = false
    confirm: ->
      WizardComponent.methods.confirm.bind(@)() # calling super
      if @conditional
        @viewing = true
        @currentPreconditionIndex = 0
      else
        @close()
        Vue.setDefault(@option, 'steps', [])
    addCondition: ->
      @preconditionsCopy.push({})
    removeCondition: (index) ->
      @preconditionsCopy.splice(index, 1)
    finished: ->
      return unless @complete
      missingPromptIndex = @promptRefs.findIndex((component) => !component.deepComplete())
      if missingPromptIndex >= 0
        @currentPreconditionIndex = missingPromptIndex
        return
      @confirm()
      @close()
      Vue.setDefault(@option, 'steps', [])
    discard: ->
      @setCopy()
      @close()
    remove: ->
      return unless await confirm("Do you really want do delete solution “#{@option.name}”?")
      @$emit('remove', @index)
    openContextParameterModal: ->
      @$refs.contextParametersModal.show()
    setPromptRef: (el) ->
      @promptRefs.push(el) if el?
  template: '
    <div>
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-2" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container center-items">
                <div class="expand">
                  <h5 v-if="editing" class="no-margin-top">Describe the solution</h5>
                  <label v-else class="text-light">Describe the solution</label>
                  <input
                    v-if="editing"
                    type="text"
                    v-model="nameCopy"
                    @keyup.enter="confirm"
                    class="form-control wizard-style"
                    placeholder="e.g. reset password, renew MFA, ..."
                    >
                  <div v-if="!editing" class="panel-label">{{ option.name }}</div>
                </div>
                <div v-if="!editing" @click="edit" class="btn btn-icon">
                  <span class="fa fa-pen"></span>
                </div>
                <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete solution\', method: remove}]"/>
              </div>
              <label v-if="editing" class="sliding-switch align text-light" style="align-items: flex-start">
                Is this solution only available under certain conditions?
                <input type="checkbox" v-model="conditional">
                <span class="slider"></span>
              </label>
              <div v-show="conditional && editing" class="conditions">
                <label>Set your conditions</label>
                <support-option-condition
                  v-for="condition, index in preconditionsCopy"
                  :condition="condition"
                  :index="index"
                  :count="preconditionsCopy.length"
                  @remove="removeCondition"
                  />
                <plus-button label="add condition" :method="addCondition"/>
              </div>
              <div v-if="conditional && viewing" class="conditions">
                Conditions
                <div v-for="condition, index in option.preconditions" class="radio">
                  <label class="text-light">
                    <input type="radio" v-model="currentPreconditionIndex" :value="index">
                    {{ condition.label }}{{ condition.operator }}{{ condition.value }}
                  </label>
                </div>
              </div>
              <support-confirm-button v-if="editing" :always-next="true">
                <div @click="openContextParameterModal" class="btn btn-default auto-margin-right">add new parameter</div>
              </support-confirm-button>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-prompt
              :ref="setPromptRef"
              v-for="prompt, index in associatedPrompts"
              v-show="currentPromptIndex == index"
              :prompt="prompt"
              @finished="finished"
              @discard="discard"
              />
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>

      <support-option-steps v-if="option.steps !== undefined" :option="option"/>

      <modal ref="contextParametersModal" title="Local context parameters" v-slot="slotProps">
        <context-parameters-table :parameters="state.gameConfig.contextParameters"/>
        <div class="button-container">
          <div @click="slotProps.close()" class="btn btn-default">Close</div>
        </div>
      </modal>
    </div>
  '
