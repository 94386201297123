
  import PlatformApi from 'models/platform_api.coffee'
  import AvailabilityCalendar from 'components/profile/availability_calendar.vue'
  import ProjectRole from 'components/profile/project_role.vue'
  import { Badge, DisclosureHandle, H3, Panel } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue"
  import { ChevronDownIcon } from '@heroicons/vue/solid'

  export default
    props:
      user: Object
      project: Object
      context: String

    computed:
      usersTeams: ->
        return [] unless @project.teams? && @project.agent?
        @project.teams.filter (team) => @project.agent.teamIds?.includes(team.id)
      showAvailabilityCalendar: ->
        @project.agent? && @project.teams? && @project.teams.some((team) -> team.availabilityMode == 'CALCULATED')
      showProjectRole: ->
        @context == 'users' && Globals.currentUser.may('grant_project_permissions', @project) ||
          @context == 'profile' && Globals.currentUser.id == @project.owner_id

    created: ->
      @loadTeamsAndAgent()

    methods:
      loadTeamsAndAgent: ->
        PlatformApi.Handover.getTeams(@project)
          .then (teams) => @project.teams = teams
          .catch (response) => null
        PlatformApi.Handover.getAgent(@project)
          .then (agent) => @project.agent = agent
          .catch (response) => null
      close: ->
        @$refs.disclosureHandle.close()

    components:
      AvailabilityCalendar: AvailabilityCalendar
      Badge: Badge
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DisclosureHandle: DisclosureHandle
      H3: H3
      Panel: Panel
      ProjectRole: ProjectRole
