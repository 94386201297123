import GuidGenerator from 'models/guid_generator.coffee'
import WidgetTeaserButton from 'models/widget_teaser_button.coffee'
import WidgetTeaserCondition from 'models/widget_teaser_condition.coffee'

export default class ConditionalWidgetTeaser
  @CONVERSATION_DISPLAY_MODES = [
    {key: 'before', label: 'before conversations'}
    {key: 'during', label: 'during conversations'}
  ]
  @TEASER_MODES = [
    {key: 'closed', label: 'stay closed'}
    {key: 'open', label: 'open to full mode to display message'}
    {key: 'minified', label: 'open to minified to display message'}
  ]
  @DISPLAY_IN_MODES = [
    {key: 'minifiedAndOpen', label: 'minified mode and open mode'}
    {key: 'minified', label: 'minified mode'}
    {key: 'open', label: 'open mode'}
  ]

  constructor: (data = {}) ->
    @id = GuidGenerator.newGuid()
    @message = data.message || ''
    @button = new WidgetTeaserButton(data.button || {})
    @urlPattern = data.urlPattern || ''
    @contextConditions = (data.contextConditions || []).map (conditionData) -> new WidgetTeaserCondition(conditionData)
    @conversationDisplay = data.conversationDisplay || ConditionalWidgetTeaser.CONVERSATION_DISPLAY_MODES[0].key
    @initialBehavior = if data.initialBehavior
      data.initialBehavior
    else if data.defaultTeaserMode # use legacy attribute
      desktop: {start: data.defaultTeaserMode}
      mobile: {start: data.defaultTeaserMode}
    else
      desktop: {start: 'closed'}
      mobile: {start: 'closed'}
    @displayInMode = data.displayInMode || ConditionalWidgetTeaser.DISPLAY_IN_MODES[0].key

  Object.defineProperties @prototype,
    export:
      get: ->
        message: @message
        button: @button.export
        urlPattern: @urlPattern
        contextConditions: @contextConditions.map (condition) -> condition.export
        conversationDisplay: @conversationDisplay
        initialBehavior: @initialBehavior
        displayInMode: @displayInMode
