
  import Integration from 'models/integration.coffee'
  import IntegrationParameter from 'models/integration_parameter'
  import IntegrationParameterMapping from 'models/integration_parameter_mapping'

  import SecretsEditor from './secrets_editor.vue'
  import { Button, H3, Input, KeyListbox, Label, Panel, Pre, Textarea } from 'components/generic'
  import { RefreshIcon, XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Integration

    data: ->
      integration: @modelValue
      requestMethods: Integration.REQUEST_METHODS
      headers: []
      contentTypeHeader: 'application/json'
      contentTypes: Integration.CONTENT_TYPES
      cachedBodies: {}
      executing: false
      PlusIcon: PlusIcon
      RefreshIcon: RefreshIcon

    computed:
      bodyIsTable: ->
        ['application/x-www-form-urlencoded', 'multipart/form-data'].includes(@integration.contentTypeHeader)

    watch:
      'integration.id': ->
        @setHeaders()
      'integration.headers': ->
        @setHeaders()
      'integration.contentTypeHeader': (newValue, oldValue) ->
        if ['application/x-www-form-urlencoded', 'multipart/form-data'].includes(newValue)
          if ['application/x-www-form-urlencoded', 'multipart/form-data'].includes(oldValue)
            @cachedBodies.form = @integration.body
          else
            @cachedBodies.default = @integration.body
          @integration.body = if @cachedBodies.form? then @cachedBodies.form else []
        else if ['application/x-www-form-urlencoded', 'multipart/form-data'].includes(oldValue)
          @cachedBodies.form = @integration.body
          @integration.body = if @cachedBodies.default? then @cachedBodies.default else ''

    created: ->
      @setHeaders()

    methods:
      setHeaders: ->
        @headers = @integration.headersArrayWithoutContentType
        @contentTypeHeader = @integration.contentTypeHeaderForSelector
      addHeader: ->
        @headers.push(key: '', value: '')
        @$refs.addHeaderButton.blur()
      removeHeader: (index) ->
        @headers.splice(index, 1)
        @updateHeaders()
      updateHeaders: ->
        @integration.setHeaders(@headers, @contentTypeHeader)
        @update()
      addBodyParam: ->
        @integration.body.push(key: '', value: '')
        @$refs.addBodyParamButton.blur()
      removeBodyParam: (index) ->
        @integration.body.splice(index, 1)
        @update()
      addInputParameter: ->
        @integration.inputParameters.push(new IntegrationParameter())
        @$refs.addInputParameterButton.blur()
        @update()
      removeInputParameter: (index) ->
        @integration.inputParameters.splice(index, 1)
        @update()
      addMapping: ->
        @integration.outputMapping.push(new IntegrationParameterMapping())
        @$refs.addMappingButton.blur()
        @update()
      removeMapping: (index) ->
        @integration.outputMapping.splice(index, 1)
        @update()
      execute: ->
        @executing = true
        @integration.execute()
          .finally => @executing = false
      update: ->
        @$emit('update:modelValue', @integration)

    components:
      Button: Button
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      Panel: Panel
      Pre: Pre
      SecretsEditor: SecretsEditor
      Textarea: Textarea
      XIcon: XIcon
