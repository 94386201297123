Vue.component 'support-game',
  props: ['config']
  data: ->
    state: state
    rightColumnComponents: []
    dirtyModal:
      entity: null
      save: null
      discard: null
      close: null
    topicRefs: []
  computed:
    showAddTopicButton: ->
      if @config.topics.length == 0
        return false if @config.fresh
        return true
      lastTopic = @config.topics[-1..][0]
      return false if !lastTopic.options?.length
      return false if !lastTopic.noOptionsMessage?.message
      !lastTopic.options[-1..][0].fresh
    addTopicPrompt: ->
      if @config.topics?.length
        'Add another support topic'
      else
        'Create your first support topic'
  watch:
    config:
      handler: ->
        # TODO: add selectors
        @$parent.setLanguageStates(
          [
            'topics.invocationUtterance'
            'topics.prompts.question'
            'topics.prompts.answers.text'
            'topics.options.done.message'
            'topics.options.failWithoutOptions.message'
            'topics.options.failWithOptions.text'
            'topics.options.failWithOptions.yes'
            'topics.options.failWithOptions.no'
            'topics.options.failWithOptions.startOver'
            'topics.options.steps.question'
            'topics.options.steps.answers.done'
            'topics.options.steps.answers.fail'
          ]
        )
      deep: true
    'config.contextParameters':
      handler: -> @setAvailableContextParameters()
      deep: true
  created: ->
    # make sure game config has all required properties
    Vue.setDefault(@config, 'contextParameters', [])
    Vue.setDefault(@config, 'topics', [])
    # set available context parameters for use in components
    @setAvailableContextParameters()
    # expose methods for managing non-local collapsing
    @state.registerRightColumn = @registerRightColumn
    @state.deregisterRightColumn = @deregisterRightColumn
    @state.closeRightColumn = @closeRightColumn
    @state.showDirtyModal = @showDirtyModal
  beforeUpdate: ->
    @topicRefs = []
  methods:
    setAvailableContextParameters: ->
      @state.availableContextParameters = @config.contextParameters.concat(
        @state.globalContextParameters.map((cp) -> Object.assign({}, cp, {label: "global.#{cp.label}"}))
      )
    createTopic: ->
      Vue.delete(topic, 'fresh') for topic in @config.topics
      topic.collapse() for topic in @topicRefs
      @config.topics.push({fresh: true})
    removeTopic: (index) ->
      @config.topics.splice(index, 1)
    registerRightColumn: (component) ->
      @rightColumnComponents.push(component)
    deregisterRightColumn: (component) ->
      index = @rightColumnComponents.indexOf(component)
      @rightColumnComponents.splice(index, 1) if index >= 0
    closeRightColumn: ->
      new Promise (resolve, reject) =>
        Promise.all(
          component.close() for component in @rightColumnComponents
        ).then(resolve).catch(reject)
    showDirtyModal: (entity, save, discard) ->
      new Promise (resolve, reject) =>
        @dirtyModal.entity = entity
        @dirtyModal.save = if save? then (-> save(); resolve()) else null
        @dirtyModal.discard = if discard? then (-> discard(); resolve()) else null
        @dirtyModal.close = -> reject()
        @$refs.dirtyModal.show()
    setTopicRef: (el) ->
      @topicRefs.push(el) if el?

  template: '
    <div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <a class="flex-container center-items" href="#context-parameters" data-toggle="collapse">
            <h3 class="expand">Context parameters</h3>
            <div class="chevron"></div>
          </a>
        </div>
        <div id="context-parameters" class="collapse">
          <div class="panel-body">
            <h4>Global context parameters</h4>
            <global-context-parameters :parameters="state.globalContextParameters"/>
          </div>
          <div class="panel-body">
            <h4>Local context parameters</h4>
            <context-parameters-table :parameters="config.contextParameters"/>
          </div>
        </div>
      </div>

      <support-area :config="config"/>

      <support-topic :ref="setTopicRef" v-for="topic, index in config.topics" :topic="topic" :index="index" @remove="removeTopic"/>

      <div v-if="showAddTopicButton" class="responsive-grid-container no-gutter">
        <div class="responsive-grid-item item-300">
          <div class="panel panel-transparent">
            <div class="panel-body text-center">
              <h5>{{ addTopicPrompt }}</h5>
              <div @click="createTopic" class="btn btn-primary">Create support topic</div>
            </div>
          </div>
        </div>
        <div class="responsive-grid-spacer item-300"></div>
      </div>

      <modal ref="dirtyModal" title="Unconfirmed changes" v-slot="slotProps">
        <div class="form-group">There are unconfirmed changes in {{ dirtyModal.entity }}.</div>
        <div class="button-container">
          <div @click="dirtyModal.close(); slotProps.close()" class="btn btn-default">Back to editing</div>
          <div v-if="dirtyModal.discard" @click="dirtyModal.discard(); slotProps.close()" class="btn btn-default">Discard changes</div>
          <div @click="dirtyModal.save(); slotProps.close()" :disabled="dirtyModal.save ? null : true" class="btn btn-primary">Save changes</div>
        </div>
      </modal>
    </div>
  '
