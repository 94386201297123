export default class WhatsappTemplateParameter
  constructor: (data = {}) ->
    data ||= {}
    @type = data.type || 'text'
    @text = data.text || ''
    @url = data.url || ''
    @fileName = data.fileName || ''
    @fallback = data.fallback || ''
    @placeholder = data.placeholder || '' # only for display in UI

  Object.defineProperties @prototype,
    valueField:
      get: ->
        if @type == 'text'
          @text
        else
          @url
      set: (val) ->
        if @type == 'text'
          @text = val
        else
          @url = val
    hasFallback:
      get: ->
        @valueField.match(/\$\{.*\}/)
    hasFilename:
      get: ->
        @type == 'document'
    interpolationValue:
      get: ->
        return (@fallback || @placeholder) if @hasFallback
        @valueField || @placeholder
    export:
      get: ->
        obj = {type: @type, fallback: @fallback}
        switch @type
          when 'text'
            obj.text = @text
          when 'image', 'video'
            obj.url = @url
          when 'document'
            obj.url = @url
            obj.fileName = @fileName
        obj
