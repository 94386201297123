import Utterance from 'models/utterance.coffee'

export default class UtteranceCollection
  constructor: (data = {}, options = {}) ->
    data ||= {}
    @utterancesPerLanguage = Object.fromEntries(
      Object.entries(data).map ([lang, list]) ->
        [lang, list.map (utteranceData) -> new Utterance(utteranceData, options)]
    )

  clone: ->
    new UtteranceCollection(@export)

  forLanguage: (lang) ->
    @utterancesPerLanguage[lang] ||= []
    @utterancesPerLanguage[lang]

  addUtterancesForLanguage: (lang, utterances) ->
    @utterancesPerLanguage[lang] = @forLanguage(lang).concat(utterances)

  Object.defineProperties @prototype,
    languages:
      get: ->
        Object.keys(@utterancesPerLanguage)
    export:
      get: ->
        Object.fromEntries(
          Object.entries(@utterancesPerLanguage).map ([lang, list]) ->
            [lang, list.map (utterance) -> utterance.export]
        )
    utterances:
      get: ->
        Object.values(@utterancesPerLanguage).flat()
