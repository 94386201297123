Vue.component 'support-option-fail',
  props: ['option']
  mixins: [RightColumnCollapser, WizardComponent]
  data: ->
    entity: @option.failWithoutOptions
    role: 'head'
    name: 'fallback'
    attributes:
      name:
        type: 'String'
        default: ''
    nameCopy: ''
  computed:
    showRightColumn: ->
      return false if @pristine
      return true if @editing
      false
  mounted: ->
    if @option.failWithoutOptions.name?
      @pristine = false
    else
      @edit()
    @setCopy()
  methods:
    finished: ->
      return unless @complete
      @confirm()
      @editing = false
      Vue.setDefault(@option, 'done', {})
  template: '
    <div>
      <div class="responsive-grid-container no-vertical-spacing no-gutter wizard-row-container">
        <div class="responsive-grid-item item-300">
          <div class="panel wizard-panel level-3" :class="{incomplete: !complete}">
            <div class="panel-body">
              <div class="flex-container center-items">
                <div class="expand">
                  <h5 v-if="editing" class="no-margin-top">Fallback</h5>
                  <label v-else class="text-light">Fallback</label>
                  <input
                    v-if="editing"
                    type="text"
                    v-model="nameCopy"
                    @keyup.enter="confirm"
                    class="form-control wizard-style"
                    placeholder="e.g. human handover, lead to website, ..."
                    >
                  <div v-if="!editing" class="panel-label">{{ option.failWithoutOptions.name }}</div>
                </div>
                <div v-if="!editing" @click="edit" class="btn btn-icon">
                  <span class="fa fa-pen"></span>
                </div>
              </div>
              <support-confirm-button v-if="editing"/>
            </div>
          </div>
          <div class="wizard-left-column-spacer"><div></div></div>
        </div>

        <div v-if="reallyShowRightColumn" class="responsive-grid-item item-300">
          <div class="collapse">
            <support-option-fail-message :fail-without-options="option.failWithoutOptions" @finished="finished" @discard="discard"/>
          </div>
        </div>
        <div v-else class="responsive-grid-spacer item-300"></div>
      </div>

      <support-option-done v-if="option.done" :option="option"/>
    </div>
  '
