
  import Secret from 'models/secret'
  import { alert, confirm } from 'helpers'

  import { ActionMenu, Button, DocumentationLink, Input, KeyListbox, Modal, PageHeader, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper, Textarea } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { ClipboardIcon, XIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      secrets: []
      newSecret: null
      creating: false
      availableTypes: Secret.TYPES
      PlusIcon: PlusIcon

    computed:
      createButtonDisabled: ->
        @creating || !@newSecret.label || (@newSecret.type == 'BasicAuth' && !(@newSecret.username && @newSecret.password))

    created: ->
      @loadList()

    methods:
      loadList: ->
        Secret.list()
          .then (secrets) =>
            @secrets = secrets.sortByString('timeCreated')
      promptCreation: ->
        @newSecret = new Secret()
        @$refs.creationModal.open()
      create: ->
        @creating = true
        @newSecret.create()
          .then =>
            @loadList()
            @$refs.creationModal.close()
            if @newSecret.type == 'BearerToken'
              @$refs.tokenDisplayModal.open()
          .finally =>
            @creating = false
      copyToken: ->
        navigator.clipboard.writeText(@newSecret.token)
          .then =>
            e.target.blur()
            await alert('Copied token to clipboard')
      actionMenuItems: (secret) ->
        [
          {icon: XIcon, label: 'Delete', method: => @remove(secret)}
        ]
      remove: (secret) ->
        return unless await confirm("Do you really want to delete secret “#{secret.label}”?")
        secret.delete().then =>
          @secrets = @secrets.filter (s) -> s.id != secret.id

    components:
      ActionMenu: ActionMenu
      Button: Button
      ClipboardIcon: ClipboardIcon
      DocumentationLink: DocumentationLink
      Input: Input
      KeyListbox: KeyListbox
      Modal: Modal
      PageHeader: PageHeader
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      Textarea: Textarea
