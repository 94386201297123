{fetch: origFetch} = window
window.fetch = (...args) =>
  # hook before fetch here
  response = await origFetch(...args)

  # work with the cloned response in a separate promise chain -- could use the same chain with `await`:
  clonedResponse = response.clone()
  clonedResponse
    .text()
    .then (responseText) => window.dispatchEvent(
      new CustomEvent('fetchComplete', detail: Object.assign(clonedResponse, response: responseText))
    )
    .catch (err) => console.error(err)
  # the original response is resolved unmodified:
  return response

send = XMLHttpRequest.prototype.send
XMLHttpRequest.prototype.send = ->
  @addEventListener('load', (e) ->
    window.dispatchEvent(
      new CustomEvent('ajaxComplete', detail: Object.assign(e.target, url: e.target.responseURL))
    )
  )
  send.apply(@, arguments)

Array.unique = (array, func) ->
  array.unique(func)

Array.equal = (a, b) ->
  if a.length != b.length
    return false
  for element, i in a
    # check for nested arrays
    if a[i] instanceof Array && b[i] instanceof Array
      return false if !Array.equal(a[i], b[i])
    else if a[i] != b[i] # Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
  true

Array.prototype.unique = (func) ->
  o = {}
  if typeof func == 'function'
    for e in @
      o[func(e)] = e
  else
    for e in @
      o[e] = e
  Object.values(o)

Array.prototype.sortByString = (attribute) ->
  @sort (a, b) ->
    aString = (a[attribute] || '').toLowerCase()
    bString = (b[attribute] || '').toLowerCase()
    return -1 if aString < bString
    return 1 if aString > bString
    0

Array.prototype.sum = ->
  @reduce(((a, b) -> a + b), 0)

Array.prototype.compact = ->
  @filter (item) -> item?

Object.defineProperties Array.prototype,
  last:
    get: ->
      @[-1..][0]

Object.filter = (object, func) ->
  Object.keys(object).filter (key) -> func(object[key])

# https://gist.github.com/dperini/729294
String.urlRegexpString = '' +
  # protocol identifier
  "(?:(?:https?|ftp)://)" +
  # user:pass authentication
  "(?:\\S+(?::\\S*)?@)?" +
  "(?:" +
    # IP address exclusion
    # private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    # IP address dotted notation octets
    # excludes loopback network 0.0.0.0
    # excludes reserved space >= 224.0.0.0
    # excludes network & broacast addresses
    # (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
  "|" +
    # host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
    # domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
    # TLD identifier
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
    # TLD may end with dot
    "\\.?" +
  ")" +
  # port number
  "(?::\\d{2,5})?" +
  # resource path
  "(?:[/?#]\\S*)?"

String.prototype.capitalize = ->
  this.charAt(0).toUpperCase() + this.slice(1)

String.prototype.decapitalize = ->
  this.charAt(0).toLowerCase() + this.slice(1)

String.prototype.isUrl = ->
  urlRegexp = new RegExp("^#{String.urlRegexpString}$", 'i')
  @match(urlRegexp)?

# https://github.com/jhermsmeier/uri.regex
String.uriRegexpString = "([A-Za-z][A-Za-z0-9+\\-.]*):(?:(//)(?:((?:[A-Za-z0-9\\-._~!$&'()*+,;=:]|%[0-9A-Fa-f]{2})*)@)?((?:\\[(?:(?:(?:(?:[0-9A-Fa-f]{1,4}:){6}|::(?:[0-9A-Fa-f]{1,4}:){5}|(?:[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,1}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){3}|(?:(?:[0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})?::(?:[0-9A-Fa-f]{1,4}:){2}|(?:(?:[0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}:|(?:(?:[0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})?::)(?:[0-9A-Fa-f]{1,4}:[0-9A-Fa-f]{1,4}|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(?:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}|(?:(?:[0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})?::)|[Vv][0-9A-Fa-f]+\\.[A-Za-z0-9\\-._~!$&'()*+,;=:]+)\\]|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:[A-Za-z0-9\\-._~!$&'()*+,;=]|%[0-9A-Fa-f]{2})*))(?::([0-9]*))?((?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|/((?:(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)?)|((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})+(?:/(?:[A-Za-z0-9\\-._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)|)(?:\\?((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?(?:\\#((?:[A-Za-z0-9\\-._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*))?"

String.prototype.isUri = ->
  @match("^#{String.uriRegexpString}$")?

String.prototype.isUuid = ->
  @match(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/)?

String.prototype.normalizeName = ->
  this.replace(/ö/g, 'oe')
    .replace(/ä/g, 'ae')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/[\[\]{}()<>:\"'«»!?;,.@#$%^*+=~\/]/g, '')
    .normalize('NFD')
    .replace(/\p{M}/g, '')
    .replace(/[-\s]+/g, ' ')
    .split(' ')
    .map((part) -> part.capitalize()).join('')
