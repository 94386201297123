
  import { Input, Label, KeyListbox, Switch } from 'components/generic'


  export default
    props:
      modelValue: Object
      label: String

    data: ->
      withDelay = if !!parseInt(@modelValue.changeAfter) then true else false

      behavior: @modelValue
      withDelay: withDelay
      displayWithDelay: withDelay
      modes: [
        {key: 'closed', label: 'Closed'}
        {key: 'minified', label: 'Minified'}
        {key: 'open', label: 'Open'}
      ]

    computed:
      modesForChangeTo: ->
        switch @behavior.start
          when 'closed'
            @modes.filter (mode) -> mode.key != 'closed'
          when 'minified'
            @modes.filter (mode) -> mode.key == 'open'
          else
            []

    methods:
      update: ->
        @$emit('update:modelValue', @behavior)
      updateStart: ->
        switch @behavior.start
          when 'open'
            @withDelay = false
            @updateDelay()
          when 'minified'
            @behavior.changeTo = 'open' if @behavior.changeTo == 'minified'
            @update()
          else
            @update()
      updateDelay: ->
        @displayWithDelay = @withDelay
        if @withDelay
          @behavior.changeAfter = 1000
          @behavior.changeTo = 'open' unless @behavior.changeTo
        else
          @behavior.changeAfter = 0
        @update()
      updateChangeAfter: ->
        @withDelay = if @behavior.changeAfter <= 0
          false
        else
          true
        @update()

    components:
      Input: Input
      Label: Label
      KeyListbox: KeyListbox
      Switch: Switch
