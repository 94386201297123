Vue.component 'utterances',
  props: ['utterances']

  data: ->
    state: state
    list: []
    canonicalUtteranceList: [] # may only have one element!

  computed:
    languageDefined: ->
      @state.currentLanguage? && @utterances[@state.currentLanguage]? && @utterances[@state.currentLanguage].length >= 3
    canonicalUtteranceIndex: ->
      @canonicalUtteranceList[-1..][0]

  created: ->
    Vue.setDefault(@utterances, 'canonical', {})
    @setList()

  watch:
    utterances:
      handler: (newValue, oldValue) ->
        return if ObjectProcessor.equal(newValue, oldValue)
        Vue.setDefault(@utterances, 'canonical', {})
        @setList()
      deep: true
    'state.currentLanguage': ->
      @setList()
    list:
      handler: ->
        value = @list.map (utterance) -> utterance.s
        @utterances[@state.currentLanguage] = value
        if (index = @list.findIndex((utterance) -> utterance.isCanonical)) >= 0
          @canonicalUtteranceList = [index]
        else
          @canonicalUtteranceList = []
        if @canonicalUtteranceIndex?
          Vue.set(@utterances.canonical, @state.currentLanguage, @list[@canonicalUtteranceIndex].s)
        else
          Vue.delete(@utterances.canonical, @state.currentLanguage)
      deep: true
    canonicalUtteranceList:
      handler: ->
        @canonicalUtteranceList.shift() if @canonicalUtteranceList.length > 1
        for utterance, index in @list
          utterance.isCanonical = index == @canonicalUtteranceIndex
      deep: true

  methods:
    newUtterance: (string = '', canonical = false) ->
      s: string
      isCanonical: canonical
    setList: ->
      # need to wrap utterances from list in objects in order to have v-for and v-model combined
      @list = if @languageDefined
        for utterance in @utterances[@state.currentLanguage]
          s: utterance
          isCanonical: utterance == @utterances.canonical[@state.currentLanguage]
      else
        [@newUtterance('', true), @newUtterance(), @newUtterance()]
    nextOrAdd: (index) ->
      if index == @list.length - 1
        @addUtterance()
      else
        $(@$el).find(".utterance:nth-child(#{index + 2})").find('input').focus()
    addUtterance: ->
      @list.push(@newUtterance())
      Vue.nextTick =>
        $(@$el).find('.utterance:last input[type="text"]').focus()
    insertUtterances: (index, items) ->
      return if items.length == 0
      newUtterances = items.map (item) => @newUtterance(item)
      Array.prototype.splice.apply(@list, [index, 0].concat(newUtterances))
      Vue.nextTick =>
        $(@$el).find(".utterance:nth-child(#{index + items.length}) input").focus()
    removeUtterance: (index) ->
      return if @list.length <= 3
      @list.splice(index, 1)
    paste: (index, event) ->
      clipboardData = event.clipboardData || window.clipboardData
      items = clipboardData.getData('Text').split('\n')
      # insert first item
      firstItem = items.shift()
      firstRemainder = @list[index].s.slice(0, event.target.selectionStart)
      lastRemainder = @list[index].s[event.target.selectionEnd..]
      @list[index].s = firstRemainder + firstItem + lastRemainder
      # insert remaining items
      @insertUtterances(index + 1, items)

  template: '
    <div class="utterances">
      <div class="flex-container">
        <label class="non-bold auto-margin-left" style="display: block">
          Active learning
          <info-button info-key="active_learning"/>
        </label>
        <div v-if="list.length > 3" class="btn btn-remove invisible">
          <span class="fa fa-times-circle"></span>
        </div>
      </div>
      <div class="utterance-list">
        <div v-for="(utterance, index) in list" class="utterance">
          <input
            v-model="utterance.s"
            type="text"
            class="form-control wizard-style"
            @keyup.enter="nextOrAdd(index)"
            @paste.prevent="paste(index, $event)"
            >
          <input type="checkbox" :value="index" v-model="canonicalUtteranceList" title="canonical">
          <div v-if="list.length > 3" @click="removeUtterance(index)" class="btn btn-remove">
            <span class="fa fa-times-circle"></span>
          </div>
        </div>
      </div>
      <plus-button label="add user utterance" :method="addUtterance"/>
    </div>
  '
