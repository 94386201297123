export default class WidgetTeaserButtonPayload
  constructor: (data = {}) ->
    @game = data.game || ''
    @intent = data.intent || ''
    @slots = data.slots || []

  Object.defineProperties @prototype,
    export:
      get: ->
        game: @game
        intent: @intent
        slots: @slots
