Vue.component 'persistent-menu-item',
  props: ['item', 'index', 'itemList', 'level']
  data: ->
    state: state
  computed:
    extendable: ->
      maxItems = if @level == 0 then 3 else 5
      @itemList.length < maxItems
    hasChildren: ->
      @item.call_to_actions?.length?
    mayHaveChildren: ->
      !@hasChildren && @level < 2
    childless: ->
      !@hasChildren
  watch:
    'item.type': ->
      if @item.type == 'web_url'
        Vue.set(@item, 'url', {})
      else
        Vue.delete(@item, 'url')
      if @item.type == 'postback'
        Vue.set(@item, 'payload', '')
      else
        Vue.delete(@item, 'payload')
      if @item.type == 'nested'
        Vue.set(@item, 'call_to_actions', [@defaultItem()])
      else
        Vue.delete(@item, 'call_to_actions')
  created: ->
    Vue.setDefault(@item, '$key', GuidGenerator.newGuid())
  methods:
    defaultItem: ->
      title: {}
      type: 'postback'
      payload: ''
    addItemAfter: ->
      @itemList.splice(@index + 1, 0, @defaultItem())
    addChild: ->
      Vue.set(@item, 'type', 'nested')
    removeItem: ->
      @$emit('remove-item', @index)
    clearedItems: ->
      @item.type = 'postback'
    setPredefinedItem: (item) ->
      @item.title = ObjectProcessor.clone(item.text)
      @item.type = if item.type == 'link' then 'web_url' else item.type
      Vue.nextTick => # wait for watcher
        if @item.type == 'web_url'
          Vue.set(@item, 'url', item.url)
        else if @item.type == 'postback'
          Vue.set(@item, 'payload', item.payload)
  template: '
    <div>
      <div class="panel channel-panel">
        <div class="panel-body">
          <div class="flex-container">
            <div>
              <label>Label</label><br>
              <input type="text" maxlength="30" class="styled-form-field" v-model="item.title[state.currentLanguage]" :disabled="state.disabled ? true : null">
            </div>&nbsp;
            <div v-if="item.type != \'nested\'">
              <label>Type</label><br>
              <label class="non-bold inline-radio">
                <input type="radio" v-model="item.type" value="postback">
                Postback
              </label>
              <label class="non-bold inline-radio">
                <input type="radio" v-model="item.type" value="web_url">
                URL
              </label>
            </div>&nbsp;
            <div class="expand" v-if="item.type == \'postback\'">
              <label>
                Intent <small class="text-normal">(currently only intents without obligatory slots)</small>
              </label><br>
              <select class="form-control" v-model="item.payload" :disabled="state.disabled ? true : null">
                <option disabled="disabled" value=""></option>
                <option v-for="option in state.intents" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="expand" v-if="item.type == \'web_url\'">
              <label>URL</label><br>
              <input type="text" class="form-control" v-model="item.url[state.currentLanguage]" :disabled="state.disabled ? true : null">
            </div>
          </div>
        </div>
        <div v-if="!state.disabled" class="panel-body non-first">
          <div class="btn btn-default" v-if="extendable" @click="addItemAfter">add another menu item</div>
          <div class="btn btn-default" v-if="mayHaveChildren" @click="addChild">add child</div>
          <dropdown chosen="choose predefined" align="left">
            <li v-for="predefinedItem in state.predefinedItems">
              <a @click="setPredefinedItem(predefinedItem)">
                {{ predefinedItem.text[state.currentLanguage] }}
              </a>
            </li>
          </dropdown>
          <div class="btn btn-caution pull-right" v-if="childless" @click="removeItem">
            <span class="fa fa-trash-alt"></span>
            remove menu item
          </div>
        </div>
      </div>
      <div class="persistent-menu-sublist" v-if="hasChildren">
        <persistent-menu-item-list
          :item-list="item.call_to_actions"
          :level="level + 1"
          @cleared-items="clearedItems"
          />
      </div>
    </div>'
