
  import DropArea from 'components/shared/drop_area.vue'

  export default
    props:
      show:
        type: Boolean
        default: true
      size:
        type: String
        default: 'sm'

    emits: ['drop']

    data: ->
      draggedOver: false

    components:
      DropArea: DropArea
