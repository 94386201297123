
  import BotbuilderApi from 'models/botbuilder_api.coffee'

  import { DocumentationLink, PageHeader, Tabs } from 'components/generic'

  export default
    data: ->
      tabs = [
        {id: 'content', name: 'Entities'}
        {id: 'entitySchemaList', name: 'Schemas'}
      ]

      currentTab: if @$route.name.startsWith('entitySchema') then 'entitySchemaList' else 'content'
      tabs: tabs

    methods:
      pushRoute: ->
        @$router.push(name: @currentTab)

    components:
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      Tabs: Tabs
