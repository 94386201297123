import ConditionType from 'models/condition_type'
import ContextUpdate from 'models/context_update'
import DialogTarget from 'models/dialog_target.coffee'
import Payload from 'models/payload.coffee'
import PayloadSlot from 'models/payload_slot.coffee'
import Utterance from 'models/utterance.coffee'

export default class DialogTestElement
  @TYPES =
    InvokeWithTextMessage:
      category: 'invoke'
      label: 'User message'
      buttonLabel: 'Message'
    InvokeWithPayload:
      category: 'invoke'
      label: 'Payload'
      buttonLabel: 'Payload'
    InvokeWithButtonClick:
      category: 'invoke'
      label: 'Button click'
      buttonLabel: 'Button click'
    QueryContext:
      category: 'query'
      label: 'Evaluate context'
      buttonLabel: 'Evaluate context'
    QueryResponseMessage:
      category: 'query'
      label: 'Bot message'
      buttonLabel: 'Message'
    QueryResponseIntent:
      category: 'query'
      label: 'Bot intent'
      buttonLabel: 'Intent'
    QueryInterpretedIntent:
      category: 'query'
      label: 'User interpretation'
      buttonLabel: 'User interpretation'
    UpdateContext:
      category: 'update'
      label: 'Set context'
      buttonLabel: 'Set context'
    Reset:
      category: 'reset'
      label: 'Reset conversation'
      buttonLabel: 'Reset conversation'
    Comment:
      category: 'comment'
      label: 'Comment'
      buttonLabel: 'Comment'

  constructor: (@test, data) ->
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @id = data.id
    # set type with validation
    validTypes = Object.keys(DialogTestElement.TYPES)
    if !validTypes.includes(data.type)
      throw new Error("Type “#{data.type}” does not exist. Type must be one of #{validTypes.map((type) -> "“#{type}”").join(', ')}")
    else
      @type = data.type
    # set type-dependent attributes
    switch @type
      when 'InvokeWithTextMessage'
        @utterance = data.utterance
      when 'InvokeWithPayload'
        @payload = new Payload({}, data.payload)
      when 'InvokeWithButtonClick'
        @label = data.label
        @index = switch typeof(data.index)
          when 'number' then data.index.toString()
          when 'string' then data.index
          else null
      when 'QueryContext'
        @parameterKey = data.parameterKey || ''
        @value = data.value || ''
        @conditionType = data.conditionType
      when 'QueryResponseMessage'
        @comparisonMode = data.comparisonMode || 'INJECTIVE'
        @responses = (data.responses || [{}]).map (utteranceData) => new Utterance(utteranceData)
      when 'QueryResponseIntent'
        @intent = new DialogTarget({}, data.intent)
      when 'QueryInterpretedIntent'
        @payload = new Payload({},
          target: data.intent
          slots: data.slots || []
        )
      when 'UpdateContext'
        @parameterKey = data.parameterKey || ''
        @value = data.value || ''
        @operator = data.operator
      when 'Reset'
        null
      when 'Comment'
        @comment = data.comment

  Object.defineProperties @prototype,
    typeLabel:
      get: ->
        DialogTestElement.TYPES[@type].label
    isUnary:
      get: ->
        switch @type
          when 'QueryContext'
            ConditionType.isUnary(@conditionType)
          when 'UpdateContext'
            ContextUpdate.isUnaryOperator(@operator)
    export:
      get: ->
        obj =
          id: @id,
          type: @type
        switch @type
          when 'InvokeWithTextMessage'
            obj.utterance = @utterance
          when 'InvokeWithPayload'
            obj.payload = @payload.export
          when 'InvokeWithButtonClick'
            obj.label = @label if @label
            obj.index = @index if @index # webapp-internally index is a string, so it will be exported only when not empty
          when 'QueryContext'
            obj.parameterKey = @parameterKey
            obj.value = @value
            obj.conditionType = @conditionType
          when 'QueryResponseMessage'
            obj.comparisonMode = @comparisonMode
            obj.responses = @responses.map (utterance) -> utterance.export
          when 'QueryResponseIntent'
            obj.intent = @intent.export
          when 'QueryInterpretedIntent'
            obj.intent = @payload.export.target
            obj.slots = @payload.export.slots
          when 'UpdateContext'
            obj.parameterKey = @parameterKey
            obj.value = @value
            obj.operator = @operator
          when 'Reset'
            null
          when 'Comment'
            obj.comment = @comment
        obj
