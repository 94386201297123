import { createApp } from 'vue_shims'
import PlatformApi from 'models/platform_api.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import WhatsappTemplate from 'models/whatsapp_template.coffee'
import PromotionList from 'components/whatsapp_templates/promotion_list.vue'
import PromotionBuilder from 'components/whatsapp_templates/promotion_builder.vue'
import { Button, Listbox, PageHeader } from 'components/generic'
import { ChevronRightIcon } from '@heroicons/vue/outline'

initWhatsappPromotionsApp = (element) ->
  createApp(
    data: ->
      isBotcore2 = $(element).data('is-botcore-2')

      action: 'home'
      isBotcore2: isBotcore2
      channels: $(element).data('channels')
      contextParameters: if isBotcore2 then [] else {}
      selectedChannel: $(element).data('channels')[0]
      stageModeIds: $(element).data('stage-mode-uuids')
      fixParameters: $(element).data('fix-parameters')
      stageBotMap: $(element).data('stage-bot-map')
      templates: []
      builderStep: null # only needed for going to digest page
      builderJob: null # only needed for going to digest page

    computed:
      stageIds: ->
        @stageModeIds[@selectedChannel.stage_mode]
      availableContextParameters: ->
        (
          if @isBotcore2
            @contextParameters.concat(@fixParameters)
          else
            Array.unique(
              @stageIds.map((stageId) =>
                @contextParameters[stageId]?.read
              )
              .flat()
              .filter (parameter) -> parameter?
              .concat(@fixParameters)
            , (parameter) -> parameter.label
            )
        )
        .sort (a, b) ->
          labelA = a.humanizedLabel || a.label
          labelB = b.humanizedLabel || b.label
          return -1 if labelA < labelB
          return 1 if labelA > labelB
          0

    watch:
      action: ->
        if @action == 'home'
          @builderStep = null
          @builderJob = null

    created: ->
      if Globals.projectUuid == '5931a71f-9988-42a9-bfa4-0f5e5d225917' # KIM 2.0
        $.getJSON Routes.kim_whatsapp_templates_project_path(Globals.projectId, Globals.stageLevel), (data) =>
          templates = data.filter (templateData) -> templateData.status == 'APPROVED'
          @templates = @channels
            .map (channel) ->
              ObjectProcessor.clone(templates).map (templateData) ->
                new WhatsappTemplate(
                  Object.assign({channelId: channel.uuid}, templateData)
                )
            .flat()
        return
      @channels.forEach (channel) =>
        PlatformApi.WaTemplates.getTemplates(channel.uuid).then (data) =>
          @templates = @templates.concat(data
            .filter (templateData) -> templateData.status.toUpperCase() == 'APPROVED'
            .map (templateData) ->
              new WhatsappTemplate(
                Object.assign({channelId: channel.uuid}, templateData)
              )
          )

    methods:
      loadContextParameters: (stageIds) ->
        if @isBotcore2
          return if @contextParameters.length > 0
          BotbuilderApi.getContextParameters()
            .then (contextParameters) => @contextParameters = contextParameters
        else
          @stageIds.forEach (stageId) =>
            return if @contextParameters[stageId]?
            PlatformApi.Webapp.contextParameters(stageId)
              .then (data) =>
                @contextParameters[stageId] = data
      parameterByLabel: (keyOrLabel) ->
        return '' unless keyOrLabel
        if @isBotcore2
          @contextParameters.concat(@fixParameters).find (parameter) -> parameter.key == keyOrLabel
        else
          allContextParameters = Object.values(@contextParameters).map((cps) -> cps?.read).flat().concat(@fixParameters).filter (parameter) -> parameter?
          allContextParameters.find (parameter) -> parameter.label == keyOrLabel
      channelLabel: (channel) ->
        "#{channel.name} | #{channel.phone_number} | #{channel.stage_label}"
      buildPromotion: ->
        @action = 'builder'
      goToDigestPage: (job) ->
        @selectedChannel = @channels.find((ch) => ch.uuid == job.channelId)
        @builderJob = job
        @builderStep = 3
        @action = 'builder'

    components:
      Btn: Button
      ChevronRightIcon: ChevronRightIcon
      PromotionList: PromotionList
      Listbox: Listbox
      PageHeader: PageHeader
      PromotionBuilder: PromotionBuilder

    template: '
      <div class="sm:px-6 lg:px-8">
        <PageHeader title="WhatsApp Promotion Builder">
          <template v-if="action == \'home\'" #cta>
            <Listbox
              label="Select WhatsApp number"
              v-model="selectedChannel"
              idAttribute="uuid"
              :collection="channels"
              :name-function="channelLabel"
              class="mr-4"
            />

            <Btn v-if="channels.length > 0" type="primary" @click="buildPromotion">
              <ChevronRightIcon class="h-5 w-5 -ml-1 mr-1"/>
              Create new promotion
            </Btn>
            <Btn v-if="channels.length == 0" type="primary" :disabled="true">
              No WhatsApp channel to create promotion
            </Btn>
          </template>
        </PageHeader>

        <PromotionList v-if="action == \'home\'" @digest-page="goToDigestPage"/>

        <PromotionBuilder v-if="action == \'builder\'" :step="builderStep" :given-job="builderJob"/>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('whatsapp-promotions-app')
      initWhatsappPromotionsApp(element)
