Vue.component 'form-field-dependencies',
  props: ['dependencies', 'fields']
  created: ->
    @dependencies.push({}) if @dependencies.length == 0
  methods:
    add: ->
      @dependencies.push(
        logicalOperator: 'and'
      )
    remove: (index) ->
      @dependencies.splice(index, 1)
      if @dependencies[0]?.logicalOperator in ['and', 'or']
        @dependencies[0]?.logicalOperator = 'if'
  template: '
    <div>
      <form-field-dependency
        v-for="(dependency, index) in dependencies"
        :key="dependency.$key"
        :dependency="dependency"
        :index="index"
        :count="dependencies.length"
        :fields="fields"
        @remove-dependency="remove"
        />
      <plus-button v-if="dependencies[0].logicalOperator == \'if\'" label="add dependency" :method="add"/>
    </div>
  '
