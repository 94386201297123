Vue.component 'user-intent-reaction-condition',
  props: ['condition', 'index', 'count', 'parameters', 'allowDeleteLast']
  data: ->
    state: state
  computed:
    valueType: ->
      parameter = @parameters.find((param) => param.label == @condition.parameter)
      parameter?.type
    availableOperators: ->
      Object.assign(
        {},
        TypeValidator.availableOperators(@valueType),
        {'is set': null, 'is not set': null}
      )
    valueLabel: ->
      return '' if @isSetOperator
      TypeValidator.valueLabel(@valueType, @condition.operator)
    parameterValid: ->
      @parameters.map((param) -> param.label).indexOf(@condition.parameter) >= 0
    isSetOperator: ->
      @condition.operator == 'is set' || @condition.operator == 'is not set'
    operatorValid: ->
      @valueType && (@condition.operator of @availableOperators)
    valueValid: ->
      GameValidator.placeholder(@condition.value) ||
        TypeValidator.valueValid(
          @condition.value,
          @valueType,
          @condition.operator
        ) || @isSetOperator
  watch:
    condition:
      handler: -> @validate()
      deep: true
    'condition.operator': (operator) ->
      @condition.value = '' if @isSetOperator
  created: ->
    Vue.setDefault(@condition, 'parameter', '')
    Vue.setDefault(@condition, 'operator', '')
    Vue.setDefault(@condition, 'value', '')
    Vue.setDefault(@condition, '$key', GuidGenerator.newGuid())
    @validate()
  methods:
    validate: ->
      Vue.set @condition, '$valid',
        @parameterValid && @operatorValid && @valueValid
    remove: ->
      @$emit('remove', @index)
  template: '
    <div class="flex-container center-items sub-form-group">

      <div :class="{\'has-error\': !parameterValid}" class="width-30">
        <select class="form-control" v-model="condition.parameter">
          <option disabled="" value="">parameter</option>
          <option v-for="param in parameters" :value="param.label">{{ param.humanizedLabel || param.label }}</option>
        </select>
      </div>&nbsp;

      <div :class="{\'has-error\': !operatorValid}" class="width-20">
        <select class="form-control" v-model="condition.operator">
          <option disabled="" value="">operator</option>
          <option :value="operator" v-for="(text, operator) in availableOperators">{{ text }} ({{ operator }})</option>
        </select>
      </div>&nbsp;

      <div :class="{\'has-error\': !valueValid}" class="expand">
        <input type="text"
          v-model="condition.value"
          :placeholder="valueLabel"
          :disabled="(!condition.operator || isSetOperator) ? true : null"
          class="form-control"
          >
      </div>&nbsp;

      <div v-if="count > 1 || allowDeleteLast" @click="remove" class="btn btn-remove">
        <span class="fa fa-times-circle"></span>
      </div>

    </div>
  '
