
  import Message from 'models/message.coffee'
  import { confirm } from 'helpers'

  import BubblesEditor from './bubbles_editor.vue'
  import ButtonTemplateEditor from './button_template_editor.vue'
  import CardTemplateEditor from './card_template_editor.vue'
  import GalleryTemplateEditor from './gallery_template_editor.vue'
  import MessageDelayEditor from './message_delay_editor.vue'
  import QuickrepliesEditor from './quickreplies_editor.vue'
  import WhatsappButtonTemplateEditor from './whatsapp_button_template_editor.vue'
  import WhatsappListTemplateEditor from './whatsapp_list_template_editor.vue'
  import { DuplicateIcon, XIcon } from '@heroicons/vue/outline'
  import { ExclamationCircleIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Message
      index: Number
      medium: String

    emits: ['remove', 'clone', 'update:modelValue']

    data: ->
      message: @modelValue
      templates: Message.TEMPLATES

    computed:
      showError: ->
        !@message.pristine && !@message.valid

    watch:
      modelValue: ->
        @message = @modelValue
        @removeNonTextAttachments()

    created: ->
      @removeNonTextAttachments() # remove attachments depending on medium

    methods:
      removeBubble: (id) ->
        @message.bubbles = @message.bubbles.filter (bubble) => bubble.id != id
        @update()
      remove: ->
        return unless await confirm('Do you really want delete this message?')
        @$emit('remove')
      clone: ->
        @$emit('clone')
      removeNonTextAttachments: ->
        return unless @medium in ['phone', 'alexa']
        for bubble in @message.bubbles
          bubble.attachments = bubble.attachments.filter (attachment) -> attachment.type == 'Text'
      update: ->
        @$emit('update:modelValue', @message)
      focus: ->
        @$refs.bubblesEditor.focus()

    components:
      ButtonTemplateEditor: ButtonTemplateEditor
      BubblesEditor: BubblesEditor
      CardTemplateEditor: CardTemplateEditor
      DuplicateIcon: DuplicateIcon
      ExclamationCircleIcon: ExclamationCircleIcon
      GalleryTemplateEditor: GalleryTemplateEditor
      MessageDelayEditor: MessageDelayEditor
      QuickrepliesEditor: QuickrepliesEditor
      WhatsappButtonTemplateEditor: WhatsappButtonTemplateEditor
      WhatsappListTemplateEditor: WhatsappListTemplateEditor
      XIcon: XIcon
