import { createApp } from 'vue_shims'

import { stageModeTranslations } from 'rails_data'

initTrainingDataApp = (element) ->
  createApp(
    data: ->
      languages: $(element).data('languages')
      stageBotMap: $(element).data('stage-bot-map')
      botGames: $(element).data('bot-games')
      trainingData: []
      # pagination
      currentPage: 0
      total: 0
      # filters
      availableOrigins: []
      availableGames: {}
      currentFilter:
        game: ''
        intent: ''
        origin: ''
        text: ''
        language: Object.keys($(element).data('languages'))[0]
      # request
      request:
        loading: false
        success: true
        error: null
      # adding data
      stagesWithSignatures: $(element).data('stages-with-signatures')
      stageForAdd: null
      # hints
      conflictCount: null
      suggestionCount: null
      weakIntentCount: null
      stageTranslations: stageModeTranslations
      #refs
      trainingDatumRefs: []

    computed:
      paginationData: ->
        page: @currentPage
        pageSize: 20
      maxPageNumber: ->
        Math.ceil(@total / @paginationData.pageSize) - 1
      pageNumbersForDisplay: ->
        pageNumbers = Array.apply(null, Array(@maxPageNumber + 1)).map((_, i) -> i)
        start = Math.max(@currentPage - 2, 0)
        end = Math.min(@currentPage + 2, @maxPageNumber)
        pageNumbers.slice(start, end + 1)
      sortedGames: ->
        Object.keys(@availableGames).sort()
      sortedIntents: ->
        (@availableGames[@currentFilter.game] || []).sort()
      currentStageLabel: ->
        Globals.stageLevel.toUpperCase()
      csvLink: ->
        params = Object.assign({},
          {page: 0, pageSize: 99999},
          @currentFilter
        )
        Routes.index_project_training_data_path(Globals.projectId, Globals.stageLevel, 'csv', params)

    watch:
      currentFilter:
        handler: ->
          availableIntents = @availableGames[@currentFilter.game] || []
          @currentFilter.intent = '' if !(@currentFilter.intent in availableIntents)
          @currentPage = 0
          @fetch()
        deep: true

    created: ->
      @fetch()

    beforeUpdate: ->
      @trainingDatumRefs = []

    methods:
      setPage: (page, callback) ->
        @currentPage = page
        @fetch(callback)
      setFilter: (options) ->
        Object.assign(@currentFilter, options)
      setTextSearch: (event) ->
        if $(event.target).is('input')
          @setFilter(text: event.target.value)
        else
          @setFilter(text: $(event.target).closest('.text-search').find('input').val())
      fetch: (callback) ->
        @request.loading = true
        @trainingData = []
        @total = 0
        params = Object.assign({},
          @paginationData,
          @currentFilter
        )
        $.getJSON(Routes.index_project_training_data_path(Globals.projectId, Globals.stageLevel, params), (response) =>
          @request.success = response.success
          @request.error = response.error
          return unless response.data?
          @trainingData = response.data.items || []
          @total = response.data.total
          @availableOrigins = [null].concat(response.data.origins)
          @availableGames = '': [].concat.apply([], Object.values(response.data.gameSignature))
          @availableGames = Object.assign(@availableGames, response.data.gameSignature)
          for game, intents of @availableGames
            intents.unshift('')
          callback() if callback?
        ).fail(=>
          @request.error = 'A server error occurred, please try again later.'
          @request.success = false
        ).always(=> @request.loading = false)
        $.getJSON(Routes.hint_counts_project_training_data_path(Globals.projectId, Globals.stageLevel, params), (response) =>
          @conflictCount = response.not_resolved_conflicts_count
          @suggestionCount = response.suggestions
          @weakIntentCount = response.weak_intents
        )
      cancelEditing: ->
        row.cancel() for row in @trainingDatumRefs
      add: ->
        return unless @stageForAdd
        if @currentPage == @maxPageNumber
          @addTrainingDatum()
        else
          @setPage(@maxPageNumber, @addTrainingDatum)
      addTrainingDatum: ->
        @trainingData.push(
          origin: 'MANUAL'
          stage: @stageForAdd
          game: ''
          intent: ''
          utterance: ''
          language: if @languages[@currentFilter.language] then @currentFilter.language else Object.keys(@languages)[0]
        )
        Vue.nextTick =>
          @trainingDatumRefs[-1..][0].edit()
      remove: (index) ->
        @trainingData.splice(index, 1)
      openConflicts: ->
        $.get(
          Routes.conflicts_project_training_data_path(Globals.projectId, Globals.stageLevel)
        ).done (data) -> window.showModal(data)
      openSuggestions: ->
        $.get(
          Routes.suggestions_project_training_data_path(Globals.projectId, Globals.stageLevel)
        ).done (data) -> window.showModal(data)
      openWeakIntents: ->
        $.get(
          Routes.weak_intents_project_training_data_path(Globals.projectId, Globals.stageLevel)
        ).done (data) -> window.showModal(data)
      setTrainingDatumRef: (el) ->
        @trainingDatumRefs.push(el) if el?

    template: '
      <div class="training-data">
        <div class="panel-body filter-container">
          <dropdown
            :chosen="currentFilter.game || \'all games\'"
            align="left"
            class="filter-element"
            >
            <li v-for="game in sortedGames">
              <a @click="setFilter({game: game})">{{ game || \'all\' }}</a>
            </li>
          </dropdown>
          <dropdown
            :chosen="currentFilter.intent || \'all intents\'"
            align="left"
            class="filter-element"
            >
            <li v-for="intent in sortedIntents">
              <a @click="setFilter({intent: intent})">{{ intent || \'all\' }}</a>
            </li>
          </dropdown>
          <dropdown
            :chosen="currentFilter.origin ? currentFilter.origin.toLowerCase() : \'all origins\'"
            align="left"
            class="filter-element"
            >
            <li v-for="origin in availableOrigins">
              <a @click="setFilter({origin: origin})">{{ origin ? origin.toLowerCase() : \'all\' }}</a>
            </li>
          </dropdown>
          <div class="text-search filter-element">
            <input type="text" @keyup.enter="setTextSearch" class="styled-form-field">
            <div @click="setTextSearch" class="btn">
              <span class="fa fa-search"></span>
            </div>
          </div>
          <div class="filter-element"></div>
          <select @change="setFilter({language: $event.target.value})" class="filter-element styled-form-field">
            <option v-for="language, code in languages" :value="code">{{ language }}</option>
            <option value="unknown">unknown</option>
          </select>
          <div class="filter-element"></div>

          <div class="flex-container">
            <div class="text-center">
              <p>
                There are <b>{{ suggestionCount == null ? 0 : suggestionCount }} learning suggestions</b><br>
                on {{ currentStageLabel }}
              </p>
              <button @click="openSuggestions" class="btn btn-primary">support learning</button>
            </div>
            &emsp;
            <div class="text-center">
              <p>
                There are <b>{{ weakIntentCount == null ? 0 : weakIntentCount }} weak intents</b><br>
                on {{ currentStageLabel }}
              </p>
              <button @click="openWeakIntents" class="btn btn-primary">cure weak intents</button>
            </div>
            &emsp;
            <div class="text-center">
              <p>
                There are <b>{{ conflictCount == null ? 0 : conflictCount}} possible conflicts</b><br>
                in the training data on {{ currentStageLabel }}
              </p>
              <button @click="openConflicts" class="btn btn-primary">resolve conflicts</button>
            </div>
          </div>

          <a :href="csvLink" download target="_blank" class="ml-auto mt-11 btn btn-default">
            <i class="fa fa-file-download"/>
            Download as CSV
          </a>
        </div>

        <div class="panel-body">
          <table class="table">
            <thead>
              <tr>
                <th class="game">Game</th>
                <th class="intent">Intent</th>
                <th class="origin">Origin</th>
                <th class="language">Language</th>
                <th class="data">Utterance</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <training-datum
                v-for="entry, index in trainingData"
                :ref="setTrainingDatumRef"
                :training-datum="entry"
                :index="index"
                :key="entry.$key"
                @edit-training-datum="cancelEditing"
                @remove-training-datum="remove"
                />
            </tbody>
          </table>
          <div v-if="maxPageNumber > 0" class="flex-container space-around-content">
            <ul class="pagination no-margin-top">
              <li :class="{disabled: currentPage == 0}">
                <a href="" @click.prevent="setPage(currentPage - 1)">&lsaquo;</a>
              </li>
              <li v-if="pageNumbersForDisplay[0] > 0">
                <a href="" @click.prevent="setPage(0)">1</a>
              </li>
              <li class="disabled" v-if="pageNumbersForDisplay[0] > 1">
                <a>&hellip;</a>
              </li>
              <li :class="{active: currentPage == number}" v-for="number in pageNumbersForDisplay">
                <a href="" @click.prevent="setPage(number)">{{ number + 1 }}</a>
              </li>
              <li class="disabled" v-if="maxPageNumber - 1 > pageNumbersForDisplay[pageNumbersForDisplay.length - 1]">
                <a>&hellip;</a>
              </li>
              <li v-if="maxPageNumber > pageNumbersForDisplay[pageNumbersForDisplay.length - 1]">
                <a href="" @click.prevent="setPage(maxPageNumber)">{{ maxPageNumber + 1 }}</a>
              </li>
              <li :class="{disabled: currentPage == maxPageNumber}">
                <a href="" @click.prevent="setPage(currentPage + 1)">&rsaquo;</a>
              </li>
            </ul>
          </div>
          <div v-if="request.loading">
            <h3 class="text-center">Loading data ...</h3>
            <div class="loading"></div>
          </div>
          <div v-else>
            <div v-show="request.success && trainingData.length == 0">
              <h3 class="text-center">No data</h3>
            </div>
            <div v-show="!request.success && request.error" class="alert alert-danger">
              {{ request.error }}
            </div>
          </div>
          <div v-if="!request.loading && request.success">
            <div @click="add" class="btn btn-primary">
              add training data
            </div>
            <select v-model="stageForAdd" class="styled-form-field">
              <option v-for="stage in stagesWithSignatures" :value="stage" v-text="stage.label"/>
            </select>
          </div>
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('training-data-app')
  initTrainingDataApp(element) if element?
