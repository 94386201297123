Vue.component 'support-confirm-button',
  props: ['alwaysNext']
  computed:
    htmlClass: ->
      if @$parent.pristine || @alwaysNext then 'btn btn-primary' else 'btn btn-default'
    label: ->
      if @$parent.pristine || @alwaysNext then 'next' else 'confirm'
  template: '
    <div class="button-container">
      <slot/>
      <button @click="$parent.confirm" :class="htmlClass" :disabled="(!$parent.complete || (!$parent.dirty && !alwaysNext)) ? true : null">
        {{ label }}
      </button>
    </div>
  '
