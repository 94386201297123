
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import { Button } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { ChevronDownIcon, UserCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      conversation: Conversation
      small: Boolean

    data: ->
      selectedAgent: null

    computed:
      assignedAgent: ->
        @$root.agents.find (agent) => agent.id == @conversation.handoverSession.assignedAgentId
      teams: ->
        @$root.agentTeams

    methods:
      assign: (closePopup) ->
        closePopup()
        @$root.api.reassignConversation(@conversation, @selectedAgent.teamId, @selectedAgent.agentId)

    components:
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
      UserCircleIcon: UserCircleIcon
