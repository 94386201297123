import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
// Resources for the codemirror editor
import Codemirror from 'codemirror'
// mode
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/vue/vue'
// edit
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets'
// placeholder
import 'codemirror/addon/display/placeholder'
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars'

VMdEditor.Codemirror = Codemirror
VMdEditor.use(githubTheme)
VMdEditor.lang.use('en-US', enUS)
VMdEditor.lang.add({
  'en-US': {
    link: {
      descPlaceholder: 'display text',
    },
  },
});

export default VMdEditor
