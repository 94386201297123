
  import ContextParameterSelector from 'components/botbuilder/inputs/context_parameter_selector.vue'
  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Object

    emits: ['update:modelValue', 'remove']

    data: ->
      contextUpdate: @modelValue
      contextParameter: {}
      oldValueType: null

    created: ->
      @setContextParameter()

    methods:
      setContextParameter: ->
        # don't use a computed property so we can keep track of the valueType
        @oldValueType = @contextParameter.valueType
        @contextParameter = @$root.contextParameterByKey(@contextUpdate.parameterKey) || {}
      updateParameterKey: ->
        @setContextParameter()
        @contextUpdate.value = '' if @oldValueType != @contextParameter.valueType
        @update()
      update: ->
        @$emit('update:modelValue', @contextUpdate)

    components:
      ContextParameterSelector: ContextParameterSelector
      DataTypeDependentInput: DataTypeDependentInput
      XIcon: XIcon
