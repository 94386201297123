
  import PlatformApi from 'models/platform_api.coffee'
  import Role from 'models/role.coffee'
  import { alert, confirm } from 'helpers'

  import CustomRoleEditor from './custom_role_editor.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { ActionMenu, Button, H3, Panel, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      currentRoleIndex: {}
      mayAddRoles: Globals.restrictions.custom_roles
      PlusIcon: PlusIcon

    methods:
      actionMenuItems: (roleIndex) ->
        roleIndex.index = @listByScope(roleIndex.scope).findIndex (role) -> role.id == roleIndex.role.id
        [
          if roleIndex.role.organizationId?
            {icon: PencilIcon, label: 'Edit', method: => @edit(roleIndex)}
          else
            {icon: PencilIcon, label: 'Show', method: => @edit(roleIndex)}
          if roleIndex.role.deletable then {icon: XIcon, label: 'Delete', method: => @remove(roleIndex)},
        ].filter (item) -> item?
      listByScope: (scope) ->
        switch scope
          when 'Project'
            @$root.projectRoles
          when 'Organization'
            @$root.organizationRoles
          else
            []
      edit: (roleIndex) ->
        @currentRoleIndex = roleIndex
      editById: (roleId) ->
        role = @$root.organizationRoles.concat(@$root.projectRoles).find (role) -> role.id == roleId
        return unless role?
        @currentRoleIndex =
          scope: role.scope
          index: @listByScope(role.scope).findIndex (role) -> role.id == roleId
      cancel: ->
        return if !@currentRoleIndex.scope
        if !@listByScope(@currentRoleIndex.scope)[@currentRoleIndex.index].id
          @listByScope(@currentRoleIndex.scope).pop()
        @currentRoleIndex = {}
      remove: (roleIndex) ->
        role = @listByScope(roleIndex.scope)[roleIndex.index]
        return unless await confirm("Do you really want to delete role “#{role.name}”?")
        role.delete()
          .then =>
            @listByScope(roleIndex.scope).splice(roleIndex.index, 1)
          .catch (response) =>
            await alert(response.message)
      addOrganizationRole: ->
        @$root.organizationRoles.push(new Role(scope: 'Organization', organization_id: @$root.organization.id))
        @edit(scope: 'Organization', index: @$root.organizationRoles.length - 1)
      addProjectRole: ->
        @$root.projectRoles.push(new Role(scope: 'Project', organization_id: @$root.organization.id))
        @edit(scope: 'Project', index: @$root.projectRoles.length - 1)

    components:
      ActionMenu: ActionMenu
      Button: Button
      CustomRoleEditor: CustomRoleEditor
      H3: H3
      Panel: Panel
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      UpgradePrompt: UpgradePrompt
