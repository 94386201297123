
  import Conversation from 'models/conversation'
  import { alert } from 'helpers'

  import { Textarea } from 'components/generic'

  export default
    props:
      conversation: Conversation

    data: ->
      debounceTimeout: null

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @conversation.saveNotes(@$root.api)
            .catch => await alert('Sorry, your changes couldn’t be saved.')
        , 1000
        )

    components:
      Textarea: Textarea
