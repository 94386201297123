import { railsEnv } from 'rails_data'

export default class Analytics
  @track: (eventName, payload = {}) =>
    return if railsEnv == 'development'
    fetch(
      Routes.proxy_send_event_path()
      method: 'POST'
      body: JSON.stringify(event: eventName, payload: payload)
      headers:
        'X-Requested-With': 'XMLHttpRequest'
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        'Content-Type': 'application/json'
        'Accept': 'application/json'
    )
