Vue.component 'support-option-step',
  props: ['step', 'index', 'option']
  mixins: [WizardComponent]
  data: ->
    entity: @step
    role: 'tail'
    attributes: []
    customAttributes:
      question:
        default:
          en: 'Did this step work?'
          de: 'Hat dieser Schritt funktioniert?'
      answers:
        done:
          default:
            en: 'Yes'
            de: 'Ja'
        fail:
          default:
            en: 'No'
            de: 'Nein'
    explanationCopy: null
    fixedMessageCopy: null
  computed:
    complete: ->
      @explanationCopy?.$valid
    otherStepsValid: ->
      @option.steps.every((step, index) => index == @index || step.$valid)
    saveAndCloseDisabled: ->
      !(@complete && @otherStepsValid)
    dirty: ->
      !ObjectProcessor.equal(@step.explanation, ObjectProcessor.clone(@explanationCopy)) ||
        !ObjectProcessor.equal(@step.question, ObjectProcessor.clone(@fixedMessageCopy.message)) ||
        @step.questionDelay != @fixedMessageCopy.delay ||
        !ObjectProcessor.equal(@step.answers, ObjectProcessor.clone(@fixedMessageCopy.buttons))
  watch:
    'state.currentLanguage': ->
      @setDefaultTexts()
  created: ->
    @pristine = false if @step.explanation?
    Vue.setDefault(@step, '$key', GuidGenerator.newGuid())
    Vue.setDefault(@step, 'question', {})
    Vue.setDefault(@step, 'questionDelay', 0)
    Vue.setDefault(@step, 'answers', {done: {}, fail: {}})
    @setCopy()
  mounted: -> # do it after the nlg component has been rendered
    Vue.nextTick =>
      Vue.set(@step, '$valid', @explanationCopy.$valid)
  methods:
    setCopy: ->
      @explanationCopy = ObjectProcessor.clone(@step.explanation || {})
      @fixedMessageCopy =
        title: 'The bot will reply'
        description: 'At this point we check if the step was completed successfully. The chatbot will ask for it automatically.'
        message: ObjectProcessor.clone(@step.question)
        delay: @step.questionDelay
        placeholder: 'How should the bot ask?'
        buttons: ObjectProcessor.clone(@step.answers)
        buttonPlaceholders:
          done: 'Yes'
          fail: 'No'
      @setDefaultTexts()
    setDefaultTexts: ->
      Vue.setDefault(@fixedMessageCopy.message, @state.currentLanguage, @defaultText(@customAttributes.question))
      Vue.setDefault(@fixedMessageCopy.buttons.done, @state.currentLanguage, @defaultText(@customAttributes.answers.done))
      Vue.setDefault(@fixedMessageCopy.buttons.fail, @state.currentLanguage, @defaultText(@customAttributes.answers.fail))
    defaultText: (attrConfig) ->
      attrConfig.default[@state.currentLanguage] || attrConfig.default.en
    confirm: (emit = true) ->
      return unless @complete
      Vue.set(@step, 'explanation', ObjectProcessor.clone(@explanationCopy))
      Vue.set(@step, 'question', ObjectProcessor.clone(@fixedMessageCopy.message))
      Vue.set(@step, 'questionDelay', @fixedMessageCopy.delay)
      Vue.set(@step, 'answers', ObjectProcessor.clone(@fixedMessageCopy.buttons))
      Vue.set(@step, '$valid', true)
      if emit
        @$emit('step-finished') if @pristine
      @pristine = false
    finished: ->
      return unless @complete
      @confirm(false)
      @$emit('finished')
  template: '
    <div>
      <div class="panel wizard-panel level-4" :class="{incomplete: !complete}">
        <div class="panel-body">
          <h5 class="no-margin-top">Step {{ index + 1 }}: How does the bot reply to start the solution dialogue?</h5>
          <nlg-editor :nlg="explanationCopy" :fixed-message="fixedMessageCopy"/>
          <support-confirm-button/>
        </div>
      </div>

      <div class="button-container">
        <button v-if="!pristine" :disabled="saveAndCloseDisabled ? true : null" @click="finished" class="btn btn-primary">confirm and close</button>
      </div>
    </div>
  '
