import IceBreakerItem from 'models/ice_breaker_item.coffee'

export default class IceBreaker
  constructor: (@channel, data = {}) ->
    @locale = data.locale || 'default'
    @items = (data.call_to_actions || []).map (itemData) => new IceBreakerItem(@, itemData)

  addItem: ->
    @items.push(new IceBreakerItem())

  Object.defineProperties @prototype,
    export:
      get: ->
        locale: @locale
        call_to_actions: @items.map (item) -> item.export
