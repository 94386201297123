
  import ContentEntity from 'models/content_entity'
  import ContextParameter from 'models/context_parameter'
  import KnowledgeSource from 'models/knowledge_source.coffee'
  import ValueType from 'models/value_type.coffee'

  import { BooleanInput, Combobox, DatetimePicker, Input, KeyListbox, Label } from 'components/generic'

  export default
    emits: ['cancel', 'confirm', 'update:modelValue']

    props:
      modelValue: [String, Number, Boolean]
      valueType: String
      collectionType: String
      entitySchemaKey: String
      contextParameter: [ContextParameter, Object]
      label: String
      placeholder: String
      asString:
        type: Boolean
        default: true

    data: ->
      value: @castValue(@modelValue)
      datetimeDisplayValue: ''
      entity: null
      knowledgeSource: null
      expectedValue: null
      initialComboboxItems: []
      pageSize: 25

    computed:
      availableLanguages: ->
        Object.entries(@$root.languages || {}).map ([code, label]) ->
          {key: code, label: label}
      inputType: ->
        return 'expectedValue' if @valueType != 'bool' && @contextParameter?.expectedValues?.length
        return 'number' if ValueType.isNumber(@valueType)
        switch @valueType
          when 'bool'
            'boolean'
          when 'date'
            'date'
          when 'entity'
            'entity'
          when 'module'
            'module'
          when 'knowledgeSource'
            'knowledgeSource'
          when 'language'
            'language'
          else
            'string'
      dialogModules: ->
        [label: 'Select', key: ''].concat(
          @$root.currentBot.config.allModules.sortByString('label')
        )

    watch:
      modelValue: ->
        @value = @castValue(@modelValue)
      inputType: ->
        @init()

    created: ->
      @init()

    methods:
      init: ->
        switch @inputType
          when 'date'
            @setDatetimeDisplayValue()
          when 'entity'
            @initEntity()
          when 'knowledgeSource'
            @initKnowledgeSource()
          when 'expectedValue'
            @initExpectedValue()
      castValue: (val) ->
        if ValueType.isNumber(@valueType)
          Number.parseFloat(val)
        else
          val
      setDatetimeDisplayValue: ->
        if !@value?
          @datetimeDisplayValue = ''
        else if match = @value.match(/^(\d{4}-\d\d-\d\d)T(\d\d:\d\d):\d\d/)
          @datetimeDisplayValue = "#{match[1]} #{match[2]}"
        else
          @datetimeDisplayValue = @value
      updateDatetimeValue: ->
        match = @datetimeDisplayValue.match(/^(\d{4}-\d\d-\d\d) (\d\d:\d\d)$/)
        return unless match?
        @value = "#{match[1]}T#{match[2]}:00[#{Intl.DateTimeFormat().resolvedOptions().timeZone}]"
        @update()
      initEntity: ->
        ContentEntity.load(@$root.currentLanguage, @$root.entitySchemas, @value.split('/').last, noAlert: true)
          .then (entity) => @entity = entity
        ContentEntity.list(
          @$root.currentLanguage
          @$root.entitySchemas
          entitySchemaKey: @entitySchemaKey, pageSize: @pageSize
        )
          .then (entities) => @initialComboboxItems = entities
      updateEntity: ->
        @value = if @entity? then @entity.schemaKey + '/' + @entity.key else ''
        @update()
        @$emit('confirm')
      initKnowledgeSource: ->
        KnowledgeSource.load(@value, noAlert: true)
          .then (source) => @knowledgeSource = source
        KnowledgeSource.list()
          .then (sources) => @initialComboboxItems = sources
      updateKnowledgeSource: ->
        @value = @knowledgeSource?.id || ''
        @update()
        @$emit('confirm')
      initExpectedValue: ->
        @initialComboboxItems = await @contextParameter.searchExpectedValues(@$root.currentBot.id, '')
        @expectedValue = @initialComboboxItems.find (item) => item.key == @value
      updateExpectedValue: ->
        @value = @expectedValue.key
        @update()
        @$emit('confirm')
      focus: ->
        @$refs.input.focus()
      update: ->
        @$emit('update:modelValue', @value)
      entityLabel: (entity) ->
        return '' if !entity?
        entity.label
      searchEntities: (query) ->
        ContentEntity.list(
          @$root.currentLanguage
          @$root.entitySchemas
          query: query, entitySchemaKey: @entitySchemaKey, pageSize: @pageSize
        )
      knowledgeSourceLabel: (source) ->
        return '' if !source?
        source.canonical || source.label
      searchKnowledgeSources: (query) ->
        @$root.activeBot.config.searchKnowledgeSources(query)
      expectedValueLabel: (expectedValue) ->
        return '' if !expectedValue?
        expectedValue.key
      searchExpectedValues: (query) ->
        @contextParameter.searchExpectedValues(@$root.currentBot.id, query)

    components:
      BooleanInput: BooleanInput
      Combobox: Combobox
      DatetimePicker: DatetimePicker
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
