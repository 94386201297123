Vue.component 'widget-config',
  data: ->
    state: state
    defaults:
      displayMode: 'overlay'
      fullscreen: false
      active: false
      teaser: false
      pollingInterval: null
    displayMode: 'overlay'
    fullscreen: false
    openAutomatically: false
    openAfter: 2000
    teaser: false
    usePolling: false
    pollingInterval: 10
    startIntent: null
  computed:
    snippet: ->
      string = """
        <script>
          var mercuryConfig = {
            apiKey: \'#{@state.channelKey}\',\n
        """
      string += "    displayMode: \'#{@config.displayMode()}\',\n" if @config.displayMode() != @defaults.displayMode
      string += "    fullscreen: #{@config.fullscreen()},\n" if @config.fullscreen() != @defaults.fullscreen
      string += "    active: #{@config.active()},\n" if @config.active() != @defaults.active
      string += "    teaser: #{@config.teaser()},\n" if @config.teaser() != @defaults.teaser
      string += "    pollingInterval: #{@config.pollingInterval()},\n" if @config.pollingInterval() != @defaults.pollingInterval
      string += "  };\n"
      string += "  var mercuryUser = {\n"
      string += "    startIntent: \'#{@startIntent}\',\n" if @startIntent?
      string += "  };\n"
      string += """
          !function(){var e=function(e,t,r){return[].slice.call(document.getElementsByTagName(e)).filter((function(e){return e.getAttribute(t)===r}))[0]},t=function(){var e=document.getElementById("mercury-widget");e||((e=document.createElement("div")).id="mercury-widget",document.getElementsByTagName("body")[0].appendChild(e)),Mercury.initChatWidget(e,mercuryConfig,mercuryUser)},r="https://widget.mercury.ai/v3/widget.js";if(!e("script","src",r)){var n=document.createElement("script");n.src=r,n.onload=t,document.getElementsByTagName("head")[0].appendChild(n)}var i="https://widget.mercury.ai/v3/widget.css";if(!e("link","href",i)){var c=document.createElement("link");c.rel="stylesheet",c.href=i,document.getElementsByTagName("head")[0].appendChild(c)}}();
        </script>
        """
      string
    config: ->
      displayMode: =>
        @displayMode
      fullscreen: =>
        @fullscreen
      active: =>
        if @openAutomatically && @openAfter > 0
          @openAfter
        else
          @openAutomatically
      teaser: =>
        @teaser
      pollingInterval: =>
        if @usePolling then @pollingInterval else null
      startIntent: =>
        @startIntent
    infoModalUrl: ->
      Routes.widget_info_project_channels_path(Globals.projectId, Globals.stageLevel)
  mounted: ->
    $(@$el).find('.btn-copy').copyButton()
  template: '
    <div class="panel-body">
      <h4>Embedding the web chat widget</h4>
      <p>
        In order to embed the chat widget on your site, paste the javascript snippet below into your HTML.
        Please make sure the <code>apiKey</code> in the snippet isn’t empty. If it is, save your channel first.
        <br>
        You may customize the snippet (and thus the behaviour of your widget) using the following settings.
        <br>
        For details and advanced customization, please refer to the
        <a :href="infoModalUrl" data-modal="true">
          instructions
          <span class="fa fa-info-circle"></span>
        </a>.
      </p>

      <div class="sub-form-group">
        <label class="non-bold">Display mode</label>&ensp;
        <label class="inline-radio body-text">
          <input type="radio" v-model="displayMode" value="overlay">
          overlay
        </label>
        <label class="inline-radio body-text">
          <input type="radio" v-model="displayMode" value="block">
          block
        </label>
        <div class="help-block no-margin-top">
          If set to "block", you must insert the element <code>&lt;div id="mercury-widget"&gt;&lt;/div&gt;</code> at the place in your HTML where the widget should be displayed.
        </div>
      </div>
      <div class="sub-form-group">
        <label class="sliding-switch align non-bold">
          Open widget in fullscreen mode
          <input type="checkbox" v-model="fullscreen">
          <span class="slider"></span>
        </label>
        <div class="help-block no-margin-top">Only applicable in overlay mode.</div>
      </div>
      <div class="sub-form-group">
        <div class="flex-container center-items">
          <label class="sliding-switch align non-bold">
            Open widget automatically
            <input type="checkbox" v-model="openAutomatically">
            <span class="slider"></span>
          </label>&emsp;
          <div :class="{invisible: !openAutomatically}">
            how many milliseconds after load?
            <input v-model="openAfter" type="number" min="0" class="styled-form-field">
          </div>
        </div>
        <div class="help-block no-margin-top">Only applicable in overlay mode.</div>
      </div>
      <div class="sub-form-group">
        <div class="flex-container center-items">
          <label class="sliding-switch align non-bold">
            Show teaser bubble when closed
            <input type="checkbox" v-model="teaser">
            <span class="slider"></span>
          </label>&emsp;
        </div>
        <div class="help-block no-margin-top">Only applicable in overlay mode.</div>
      </div>
      <div class="sub-form-group">
        <div class="flex-container center-items">
          <label class="sliding-switch align non-bold">
            Poll for new messages
            <input type="checkbox" v-model="usePolling">
            <span class="slider"></span>
          </label>&emsp;
          <div :class="{invisible: !usePolling}">
            polling interval in seconds:
            <input v-model="pollingInterval" type="number" min="2" class="styled-form-field">
          </div>
        </div>
        <div class="help-block no-margin-top">Only activate if you use handover in your bot.</div>
      </div>
      <div class="sub-form-group">
        <label class="block non-bold">Start intent</label>
        <p v-show="state.intents.length == 0">
          Connect the channel to a stage with a running bot instance in order to get a list of intents to choose from.
        </p>
        <select v-show="state.intents.length > 0" v-model="startIntent" class="styled-form-field">
          <option :value="null">No start intent</option>
          <option v-for="intent in state.intents" :value="intent">{{ intent }}</option>
        </select>
      </div>
      <div class="flex-container">
        <pre class="no-margin">{{ snippet }}</pre>
        <div class="btn btn-copy">copy</div>
      </div>
    </div>
  '
