
  import PlatformApi2 from 'models/platform_api2.coffee'
  import Channel from 'models/channel.coffee'
  import Project from 'models/project.coffee'
  import Status from 'models/status.coffee'
  import { availableLanguages } from 'rails_data'

  import ChannelCreator from './channel_creator.vue'
  import ChannelLogo from './channel_logo.vue'
  import StatusBadge from './status_badge.vue'
  import { Button, EnvironmentBadge, MultiselectDropdown, PageHeader } from 'components/generic'

  export default
    data: ->
      channels: []
      channelTypes: Channel.TYPES
      filters:
        types: []
        environments: []
        defaultLanguages: []
        statuses: []
      environments: Project.ENVIRONMENTS
      availableLanguages: availableLanguages
      channelTypesForFilter: Channel.TYPES
      languagesForFilter: @$root.languages.map (code) -> {key: code, label: availableLanguages[code]}
      statusesForFilter: Status.LEVELS

    computed:
      filteredChannels: ->
        @channels
          .filter (channel) =>
            @filters.types.length == 0 || @filters.types.map((type) -> type.key).includes(channel.type)
          .filter (channel) =>
            @filters.environments.length == 0 || @filters.environments.map((env) -> env.key).includes(channel.environment)
          .filter (channel) =>
            @filters.defaultLanguages.length == 0 || @filters.defaultLanguages.map((lang) -> lang.key).includes(channel.defaultLanguage)
          .filter (channel) =>
            @filters.statuses.length == 0 || @filters.statuses.map((status) -> status.key).includes(channel.status.level)

    created: ->
      @loadChannels()

    methods:
      loadChannels: ->
        PlatformApi2.getChannels().then (channels) =>
          @channels = channels.sortByString('label')
      promptCreate: (type) ->
        @$refs.creator.start(type)

    components:
      Button: Button
      ChannelCreator: ChannelCreator
      ChannelLogo: ChannelLogo
      EnvironmentBadge: EnvironmentBadge
      MultiselectDropdown: MultiselectDropdown
      PageHeader: PageHeader
      StatusBadge: StatusBadge
