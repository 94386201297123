
  import PlatformApi from 'models/platform_api.coffee'
  import User from 'models/user.coffee'
  import { H3, Listbox, Panel } from 'components/generic'

  export default
    props:
      project: Object
      user: Object

    data: ->
      currentUser: Globals.currentUser
      role: null
      editing: false
      sending: false
      errorMessage: null

    created: ->
      @setRole()

    methods:
      setRole: ->
        @role = @$root.projectRoles.find (role) => role.id == @project.role.id
        @role ||= {}
      cancel: ->
        @editing = false
        @errorMessage = null
        @setRole()
      save: ->
        @sending = true
        PlatformApi.Webapp.updateProjectUser(@project.id, Globals.stageLevel, {id: @user.id, role: {id: @role.id}})
          .then (data) =>
            @project.role = @role
            @editing = false
            @errorMessage = null
          .catch (response) =>
            @errorMessage = response.message
          .finally =>
            @sending = false

    components:
      H3: H3
      Listbox: Listbox
      Panel: Panel
