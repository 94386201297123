/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../../assets/images', true)
// const imagePath = (name) => images(name, true)

// import './styles.sass'

window.jQuery = $
window.$ = $
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "bootstrap"
import "bootstrap-datepicker"
import Vue from 'vue_shims'
window.Vue = Vue
import * as Routes from 'routes'
window.Routes = Routes
import moment from 'moment'
window.moment = moment
import 'javascripts/jquery.slick' // from vendor dir

Rails.start()
Turbolinks.start()

import setGlobals from 'globals'
// setGlobals must be called as first callback on turbolinks:load
// so all Vue apps that are initialized on the same event
// can use the `Globals` object
$(window).on('turbolinks:load', () => {
  setGlobals()
})
// import new Vue apps
function requireAll(r) { r.keys().forEach((f) => r(f).default()) }
requireAll(require.context('../apps', true, /\.(js|coffee)$/))

// import old Vue apps
function importAll(r) { r.keys().forEach(r) }
importAll(require.context("../../assets/javascripts"))
