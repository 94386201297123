
  import { Button, Listbox } from 'components/generic'
  import { RefreshIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Object

    data: ->
      channel: @modelValue
      connectedPage: null
      availablePages: []

    watch:
      modelValue: ->
        deep: true
        handler: -> @channel = @modelValue
      channel: ->
        deep: true
        handler: -> @$emit('update:modelValue', @channel)
      connectedPage: ->
        @channel.page_id = @connectedPage.id
        @channel.channel_credentials = @connectedPage.access_token

    created: ->
      @availablePages =
        if !@channel.page_id || @$root.facebookPages.find (page) => page.id == @channel.page_id
          [name: 'select'].concat(@$root.facebookPages)
        else
          [{name: 'select'}, {name: 'undisclosed page', id: @channel.page_id}].concat(@$root.facebookPages)
      @connectedPage = @availablePages.find((page) => @channel.page_id == page.id) || @availablePages[0]

    methods:
      facebookOauth: ->
        window.location = @$root.facebookLoginUrlForInstagram

    components:
      Button: Button
      Listbox: Listbox
      RefreshIcon: RefreshIcon
