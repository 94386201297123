
  import { ChevronRightIcon } from '@heroicons/vue/outline'

  export default
    props:
      if:
        type: Boolean
        default: true
      closable:
        type: Boolean
        default: true
      fixed:
        type: Boolean
        default: false

    emits: ['close']

    computed:
      widthClasses: ->
        if @fixed
          'fixed min-w-md'
        else
          'fixed lg:relative'

    watch:
      if: ->
        if @if
          @$nextTick => @setHeight()

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setHeight: ->
        return if !@$refs.container?
        top = @$refs.container.getBoundingClientRect().top
        @$refs.container.style.height = window.innerHeight - top + 'px'

    components:
      ChevronRightIcon: ChevronRightIcon
