import { confirm } from 'helpers'

Vue.component 'bookmarks-game',
  props: ['config']
  data: ->
    state: state
    newContentObjectType: null
  computed:
    availableContentObjectTypes: ->
      takenContentObjectTypes = @config.contentObjectTypes.map (type) -> type.contentObjectType
      Object.keys(@state.contentObjectTypes).filter (cot) ->
        !(cot in takenContentObjectTypes)
  watch:
    config:
      handler: ->
        @$parent.setLanguageStates(
          [
            'contentObjectTypes.resultPresentation',
          ]
        )
      deep: true
  created: ->
    Vue.setDefault(@config, 'contentObjectTypes', [])
  methods:
    add: ->
      return unless @newContentObjectType
      return if @newContentObjectType in @config.contentObjectTypes.map (type) -> type.contentObjectType
      @config.contentObjectTypes.push(
        $key: GuidGenerator.newGuid()
        contentObjectType: @newContentObjectType
        inviteBookmarking: 'always'
        resultPresentation: {}
      )
      @newContentObjectType = null
    remove: (index) ->
      return unless await confirm("Do you really want do delete the bookmarking configuration for “#{@config.contentObjectTypes[index].contentObjectType}”?")
      @config.contentObjectTypes.splice(index, 1)
    removeMethod: (index) ->
      => @remove(index)
  template: '
    <div class="panel panel-default no-margin">

      <div class="panel-heading">
        <h3>Available content for bookmarking</h3>
      </div>
      <div class="panel-body">
        <div class="form-group">
          Select the content object types that can be bookmarked:
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Content object type</th>
              <th>Proactively invite bookmarking</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="type, index in config.contentObjectTypes" :key="type.$key">
              <td>{{ type.contentObjectType }}</td>
              <td>
                <select v-model="type.inviteBookmarking" class="styled-form-field">
                  <option value="always">always</option>
                  <option value="once">once</option>
                  <option value="never">never</option>
                </select>
              </td>
              <td class="text-right">
                <action-menu :items="[{icon: \'far fa-times-circle\', label: \'Delete\', method: removeMethod(index)}]"/>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-group flex-container">
          <select v-model="newContentObjectType" class="styled-form-field">
            <option v-for="cot in availableContentObjectTypes" :value="cot">{{ cot }}</option>
          </select>
          &nbsp;
          <div class="btn btn-primary" @click="add">add content object type</div>
        </div>
      </div>

      <div class="panel-heading">
        <h3>Bot messages</h3>
      </div>
      <div class="panel-body">
        <div class="panel-group">
          <result-nlg
            v-for="(type, index) in config.contentObjectTypes"
            :key="type.$key"
            :nlg="type.resultPresentation"
            :title="\'Result presentation for bookmarks of <i>\' + type.contentObjectType + \'</i>\'"
            info-key="bookmarks_presentation"
            >
            <p>
              Available labels:
            </p>
            <result-labels
              :attributes="[\'name\', \'id\'].concat(Object.keys(state.contentObjectTypes[type.contentObjectType].signature))"
              />
          </result-nlg>
        </div>
      </div>

    </div>
  '
