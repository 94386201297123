import { createApp } from 'vue_shims'
import { chargebeePublishableKey, chargebeeSite } from 'rails_data'
import PlatformApi from 'models/platform_api.coffee'
import { alert } from 'helpers'

import { Alert, Button, H3 } from 'components/generic'
import { CardComponent, CardCvv, CardExpiry, CardNumber } from '@chargebee/chargebee-js-vue-wrapper'

initCardCollectorApp = (element) ->
  chargebeeInstance = Chargebee.init(
    site: chargebeeSite
    publishableKey: chargebeePublishableKey
  )

  app = createApp(
    data: ->
      organizationId: element.dataset.organizationId
      sending: false

    methods:
      saveCard: ->
        @sending = true
        @$refs.card.tokenize()
          .then (data) => PlatformApi.Webapp.addCard(@organizationId, data.vaultToken)
          .then => Turbolinks.visit(Routes.onboarding_path())
          .catch (response) => await alert(response.message)
          .finally => @sending = false

    components:
      Alert: Alert
      Button: Button
      CardComponent: CardComponent
      CardCvv: CardCvv
      CardExpiry: CardExpiry
      CardNumber: CardNumber
      H3: H3

    template: '
      <p class="text-2xl text-center mb-6">Create your AI chatbot based on your own knowledge sources within minutes.</p>
      <div class="mb-6 flex flex-col items-center">
        <CardComponent
          ref="card"
          currency="EUR"
          class="w-full max-w-sm space-y-3"
          :classes="{focus: \'!border-MercuryBlue-500\'}"
          :styles="{base: {\'::placeholder\': {color: \'#9CA3AF\'}}}"
        >
          <CardNumber
            placeholder="4242 4242 4242 4242"
            class="border rounded-md shadow-sm p-2 border-gray-300 block w-full placeholder-gray-400"
            style="height: 35px"
          />
          <div class="flex space-x-2">
            <CardExpiry
              placeholder="MM / YY"
              class="border rounded-md shadow-sm p-2 border-gray-300 block w-full placeholder-gray-400"
              style="height: 35px"
            />
            <CardCvv
              placeholder="CVV"
              class="border rounded-md shadow-sm p-2 border-gray-300 block w-full placeholder-gray-400"
              style="height: 35px"
            />
          </div>
        </CardComponent>
        <Button :disabled="sending" @click="saveCard" type="primary" class="w-full max-w-sm mt-4">
          Save card and activate my free trial
        </Button>
        <p class="text-sm mt-2 mb-0">
          Your card will not be charged until the end of your 30-day free trial.
        </p>
      </div>
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm">
          <div>
            <H3 class="mb-2">Why do we need your payment details?</H3>
            <ul class="list-disc pl-5 space-y-2">
              <li><strong>Security & Trust:</strong> We ask for your credit card to validate your identity. Our trial is completely free, and asking for payment information upfront allows us to reduce fraud and focus on delivering value to genuine users like you.</li>
              <li><strong>Seamless Transition:</strong> Enjoy uninterrupted service after your 30-day trial without any hassle. We’ll remind you before your trial ends, and you can cancel anytime.</li>
            </ul>
          </div>
        </div>
        <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm">
          <div>
            <H3 class="mb-2">Our Promise to You</H3>
            <ul class="list-disc pl-5 space-y-2">
              <li>No Charges During Trial: You won’t be billed until your 30-day trial period is up, and we’ll send you a reminder before it ends.</li>
              <li>Easy Cancellation: Cancel your subscription easily from your account settings—no questions asked.</li>
              <li>Data Privacy: Your information is encrypted and secure. We respect your privacy and will never share your data without your consent.</li>
            </ul>
          </div>
        </div>
      </div>
    '
  )
  app.config.unwrapInjectedRef = true
  app.mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('card-collection-app')
      initCardCollectorApp(element)
