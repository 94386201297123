
import { ExclamationIcon, InformationCircleIcon } from '@heroicons/vue/solid'

export default
  props:
    color:
      type: String
      default: 'red'

  computed:
    wrapperClass: ->
      switch @color
        when 'red'
          'bg-MercuryRed-50 border-MercuryRed-500'
        when 'yellow'
          'bg-MercuryYellow-50 border-MercuryYellow-400'
        when 'green'
          'bg-MercuryGreen-50 border-MercuryGreen-500'
        when 'blue'
          'bg-MercuryBlue-50 border-MercuryBlue-500'
    iconClass: ->
      switch @color
        when 'red'
          'text-MercuryRed-500'
        when 'yellow'
          'text-MercuryYellow-400'
        when 'green'
          'text-MercuryGreen-500'
        when 'blue'
          'text-MercuryBlue-500'
    textClass: ->
      switch @color
        when 'red'
          'text-MercuryRed-800'
        when 'yellow'
          'text-MercuryYellow-800'
        when 'green'
          'text-MercuryGreen-800'
        when 'blue'
          'text-MercuryBlue-800'
    icon: ->
      if ['green', 'blue'].includes(@color)
        InformationCircleIcon
      else
        ExclamationIcon
