export default class ParameterFilter
  @ATTRIBUTES = [
    'mode' # legacy
    'field'
    'operator'
    'value'
    'fix'
  ]

  @OPERATORS =
    '==': 'equals'
    '!=': 'notEquals'
    '<': 'equals'
    '<=': 'lessOrEquals'
    '>': 'greater'
    '>=': 'greaterOrEquals'
    '?': 'exists'
    '!': 'notExists'
    '+': 'contains'
    '-': 'notContains'

  constructor: (data = {}) ->
    id = GuidGenerator.newGuid()
    @id = -> id
    @mode = 'include'
    @field = ''
    @operator = '=='
    @value = ''
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  clone: ->
    new ParameterFilter(ObjectProcessor.clone(@))

  equals: (other) ->
    @mode == other.mode &&
      @field == other.field &&
      @operator == other.operator &&
      @value == other.value

  Object.defineProperties @prototype,
    valid:
      get: ->
        @field?.length > 0 && @operator?.length > 0 && (
          @value?.length > 0 || ['?', 'exists', 'notExists'].includes(@operator)
        )
    export:
      get: ->
        field: @field
        operator: @operator
        value: @value
