
  import { Button, Input, Label } from 'components/generic'
  import { InformationCircleIcon, XCircleIcon } from '@heroicons/vue/outline'

  export default
    props:
      button: Object
      index: Number

    computed:
      hasVariable: ->
        return unless @button.url?
        @button.url.match(/\{\{\d+\}\}/)?

    created: ->
      Vue.setDefault(@button, 'text', '')
      switch @button.type
        when 'URL'
          Vue.setDefault(@button, 'url', '')
        when 'PHONE_NUMBER'
          Vue.setDefault(@button, 'phone_number', '')

    methods:
      addVariable: ->
        return unless @button.url?
        @button.url += '{{1}}'
        @$refs.urlInput.focus()
      remove: ->
        @$emit('remove', @index)

    components:
      Btn: Button
      InformationCircleIcon: InformationCircleIcon
      Input: Input
      Label: Label
      XCircleIcon: XCircleIcon
