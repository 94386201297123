
  import BotbuilderApi from 'models/botbuilder_api'
  import NoParseMessage from 'models/no_parse_message'
  import Utterance from 'models/utterance'

  import CommandPalette from 'components/botbuilder/modules/command_palette.vue'
  import NoParseMessageTableRow from './no_parse_message_table_row.vue'
  import SourceCreatorModal from 'components/botbuilder/knowledge/source_creator_modal.vue'
  import UtteranceGenerator from 'components/botbuilder/utterances/utterance_generator.vue'
  import { Button, Checkbox, DocumentationLink, Modal, PageHeader, Spinner, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { RefreshIcon, XIcon } from '@heroicons/vue/outline'
  import { SparklesIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      loading: false
      select: null
      currentMessageIndex: null
      selectedIntent: null
      messages: []
      allSelected: false
      discarding: false
      utterances: []
      knowledgeSource: null
      RefreshIcon: RefreshIcon
      XIcon: XIcon

    computed:
      currentMessage: ->
        @messages[@currentMessageIndex]
      generatorParams: ->
        return if !@selectedIntent?
        return if !@currentMessage?
        noParseUtterance: @currentMessage.text
        language: @currentMessage.language
        nodeKey: @selectedIntent.key
        moduleKey: @selectedIntent.moduleKey
      discardable: ->
        !@discarding && @messages.some (message) -> message.selected

    created: ->
      window.breadcrumbs.enableEnvironmentSelector(last: true)
      @loading = true
      NoParseMessage.list(environment: Globals.stageLevel)
        .then (messages) =>
          @messages = messages
        .finally =>
          @loading = false

    unmounted: ->
      window.breadcrumbs.disableEnvironmentSelector()

    methods:
      remove: (index) ->
        @messages[index].delete()
          .then => @messages.splice(index, 1)
      startSelection: (index) ->
        @currentMessageIndex = index
        @select = 'userIntent'
      selectIntent: (intent) ->
        @selectedIntent = intent
        @select = null
        @$nextTick =>
          @$refs.utteranceGenerator.start()
      cancelSelect: ->
        @select = null
      addUtterances: (@utterances) ->
        @selectedIntent.utterances.addUtterancesForLanguage(
          @currentMessage.language
          @utterances
        )
        @selectedIntent.save().then =>
          @remove(@currentMessageIndex)
          @$refs.addedToIntentModal.open()
      openKnowledgeSourceCreator: (index) ->
        @currentMessageIndex = index
        @$refs.knowlegeSourceCreator.open()
      knowledgeSourceCreated: (@knowledgeSource) ->
        @remove(@currentMessageIndex)
        @$refs.createdKnowlegeSourceModal.open()
      selected: (index, value) ->
        @allSelected = false if !value
      selectAll: ->
        # wait for @allSelected to be changed
        setTimeout =>
          @messages.forEach (message) =>
            message.selected = @allSelected
        , 0
      discardSelected: ->
        @discarding = true
        Promise.allSettled(
          @messages
            .filter (message) -> message.selected
            .map (message) -> message.delete()
        )
        .then (results) =>
          discardedMessageIds = results
            .filter (res) -> res.status == 'fulfilled'
            .map (res) -> res.value.id
          @messages = @messages.filter (message) -> !discardedMessageIds.includes(message.id)
          @discarding = false
      goToIntent: ->
        @$router.push(
          name: if @selectedIntent.dialogModule.isCoreBehavior then 'coreBehaviour' else 'module'
          params: {moduleKey: @selectedIntent.moduleKey}
          hash: '#' + @selectedIntent.key
        )
      goToKnowledgeSource: ->
        @$router.push(
          name: 'knowledgeSource'
          params: {sourceId: @knowledgeSource.id}
        )

    components:
      Button: Button
      Checkbox: Checkbox
      CommandPalette: CommandPalette
      DocumentationLink: DocumentationLink
      Modal: Modal
      NoParseMessageTableRow: NoParseMessageTableRow
      PageHeader: PageHeader
      SourceCreatorModal: SourceCreatorModal
      SparklesIcon: SparklesIcon
      Spinner: Spinner
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      UtteranceGenerator: UtteranceGenerator
