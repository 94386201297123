
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Integration from 'models/integration.coffee'
  import Workflow from 'models/workflow.coffee'
  import WorkflowStep from 'models/workflow_step.coffee'
  import WorkflowParameterMapping from 'models/workflow_parameter_mapping'

  import Sidebar from 'components/botbuilder/sidebar.vue'
  import BotMappingEditor from './bot_mapping_editor.vue'
  import WorkflowStepEditor from './workflow_step_editor.vue'
  import StepDropArea from './step_drop_area.vue'
  import DraggableItem from 'components/shared/draggable_item.vue'
  import DropArea from 'components/shared/drop_area.vue'
  import Draggable from 'vuedraggable'
  import TraceList from './trace_list.vue'
  import TriggeringIntegrationDisplay from './triggering_integration_display.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { BackLink, Button, Card, DocumentationLink, H3, Input, Label, PageHeader, Panel, Tabs } from 'components/generic'
  import { ArrowUpIcon, BellIcon, PencilIcon, SwitchHorizontalIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      workflow: new Workflow()
      traces: []
      integrations: []
      editing: false
      debounceTimeout: null
      draggingTrigger: false
      draggingStep: false
      draggedOverTriggerDropArea: false
      tabs: [
        {id: 'steps', name: 'Steps'}
        {id: 'traces', name: 'History'}
      ]
      currentTab: 'steps'
      PencilIcon: PencilIcon

    computed:
      showAddTriggerPrompt: ->
        @workflow.triggerType == 'InboundApi' && !@workflow.trigger.integrationId
      showAddStepPrompt: ->
        !@showAddTriggerPrompt && @workflow.steps.length == 0
      showStepSidebar: ->
        !@showAddTriggerPrompt
      inboundIntegrations: ->
        @integrations.filter (integration) -> integration.type == 'InboundApi'
      outboundIntegrations: ->
        @integrations.filter (integration) -> integration.type == 'OutboundApi'
      triggeringIntegration: ->
        return if @workflow.triggerType != 'InboundApi'
        return if !@workflow.trigger.integrationId
        @integrations.find (integration) => integration.id == @workflow.trigger.integrationId
      stepTypes: ->
        if @workflow.triggerType == 'Bot'
          Object.fromEntries(
            Object.entries(WorkflowStep.TYPES).filter ([type, config]) ->
              !['InvokeIntegration', 'UpdateContext'].includes(type)
          )
        else
          Object.fromEntries(
            Object.entries(WorkflowStep.TYPES).filter ([type, config]) -> type != 'InvokeIntegration'
          )

    watch:
      '$route.params.workflowId': ->
        @setWorkflow() if @$route.params.workflowId?

    created: ->
      @setWorkflow()
      @loadIntegrations()

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setHeight: ->
        return if !@$refs.mainPanel?
        top = @$refs.mainPanel.$el.getBoundingClientRect().top
        @$refs.mainPanel.$el.style.height = window.innerHeight - top + 'px'
        @$refs.stepSidebar.setHeight() if @$refs.stepSidebar?
        @$refs.triggerSidebar.setHeight() if @$refs.triggerSidebar?
      setWorkflow: ->
        if @$route.params.workflowId == @workflow.id
          return
        BotbuilderApi.getWorkflow(@$route.params.workflowId)
          .then (workflow) =>
            @workflow = workflow
            window.breadcrumbs.append(text: @workflow.label)
            @$nextTick(@setHeight)
            @traces = await BotbuilderApi.getWorkflowTraces(@workflow)
      loadIntegrations: ->
        BotbuilderApi.getIntegrations()
          .then (integrations) => @integrations = integrations
      createIntegration: ->
        @$router.push(name: 'newIntegration')
      removeStep: (index) ->
        @workflow.steps.splice(index, 1)
        @update()
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @workflow.save()), 1000)
      dropTrigger: (integration) ->
        @draggingTrigger = false
        @draggedOverTriggerDropArea = false
        transferData = JSON.parse(event.dataTransfer.getData('value'))
        if transferData.integrationData?
          @workflow.setTrigger(new Integration(transferData.integrationData))
          @update()
      dropStep: (event, index) ->
        @draggingStep = false
        transferData = JSON.parse(event.dataTransfer.getData('value'))
        if transferData.stepType?
          @workflow.addStep(transferData.stepType, index)
          @workflow.save()
        else if transferData.integrationData?
          integration = new Integration(transferData.integrationData)
          @workflow.addStep(integration, index)
          BotbuilderApi.getIntegration(integration.id)
            .then (integration) =>
              integration.inputParameters.forEach (inputParameter) =>
                @workflow.steps[index].parameterMapping.push(
                  new WorkflowParameterMapping(key: inputParameter.key)
                )
              @update()

    components:
      ArrowUpIcon: ArrowUpIcon
      BackLink: BackLink
      BellIcon: BellIcon
      BotMappingEditor: BotMappingEditor
      Button: Button
      Card: Card
      DocumentationLink: DocumentationLink
      Draggable: Draggable
      DraggableItem: DraggableItem
      DropArea: DropArea
      H3: H3
      Input: Input
      Label: Label
      PageHeader: PageHeader
      Panel: Panel
      Sidebar: Sidebar
      StepDropArea: StepDropArea
      SwitchHorizontalIcon: SwitchHorizontalIcon
      Tabs: Tabs
      TraceList: TraceList
      TriggeringIntegrationDisplay: TriggeringIntegrationDisplay
      UniversalInput: UniversalInput
      WorkflowStepEditor: WorkflowStepEditor
