
  import { Alert, Label } from 'components/generic'

  export default
    props:
      channel: Object

    computed:
      color: ->
        switch @channel.status_details.status
          when 'OK' then 'green'
          when 'WARN' then 'yellow'
          when 'ERROR' then 'red'

    components:
      Alert: Alert
      Label: Label
