
  import ParameterFilter from 'models/parameter_filter.coffee'

  import { ParameterFiltering } from 'components/generic'

  export default
    computed:
      availableParameters: ->
        [
          key: 'channel'
          label: 'Channel'
          type: 'Selection'
          valueType: 'Selection'
          collection: @$root.channels
          operator: '='
          fix: true
        ,
          key: 'minNumberOfMessages'
          label: 'Min. number of user messages'
          valueType: 'number'
          collectionType: 'none'
          operator: '='
          fix: true
        ,
          key: 'hasMessagesBefore'
          label: 'Has messages before'
          valueType: 'date'
          collectionType: 'none'
          operator: '='
          fix: true
        ,
          key: 'hasMessagesAfter'
          label: 'Has messages after'
          valueType: 'date'
          collectionType: 'none'
          operator: '='
          fix: true
        ].concat(
          @$root.contextParameters.sortByString('label')
        )

    methods:
      setFilters: ->
        @$emit('update-filters')

    components:
      ParameterFiltering: ParameterFiltering
