
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import EntitySchemaAttribute from 'models/entity_schema_attribute.coffee'

  import AttributeTableRow from './attribute_table_row.vue'
  import DataTypeEditor from './data_type_editor.vue'
  import Wrapper from './wrapper.vue'
  import { BackLink, Button, H3, Input, Label, Modal, Panel, Switch, Table, TableHeadCell, TableHeadRow, TableRow, TableWrapper, Textarea } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    data: ->
      schema: null
      newAttribute: null
      debounceTimeout: null
      creating: false
      reloadContextParametersAfterUpdate: false
      PlusIcon: PlusIcon

    watch:
      '$root.entitySchemas': ->
        @setSchema()
      '$route.params.schemaKey': ->
        @setSchema()

    computed:
      createDisabled: ->
        @creating || !@newAttribute.label

    created: ->
      @setSchema()

    methods:
      setSchema: ->
        @schema = @$root.entitySchemas.find (schema) => schema.key == @$route.params.schemaKey
        return unless @schema?
        @newAttribute = new EntitySchemaAttribute(@schema)
        window.breadcrumbs.strip()
          .append(text: 'Content schema', callback: => @$router.push(name: 'entitySchemaList'))
          .append(text: @schema.label)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(=>
          @schema.save().then =>
            return if !@reloadContextParametersAfterUpdate
            @$root.loadContextParameters()
            @reloadContextParametersAfterUpdate = false
        , 1000)
      updateSearchEnabling: ->
        @reloadContextParametersAfterUpdate = true
        @update()
      cancel: ->
        @newAttribute = new EntitySchemaAttribute(@schema)
      addAttribute: ->
        @$refs.attributeCreationModal.open()
      createAttribute: ->
        @creating = true
        @schema.attributes.push(@newAttribute)
        @schema.save().then =>
          @$router.push(
            name: 'entitySchemaAttribute',
            params: {schemaKey: @schema.key, attributeKey: @schema.attributes.last.key}
          )
      removeAttribute: (attribute) ->
        @schema.attributes = @schema.attributes.filter (attr) -> attr.key != attribute.key
        @reloadContextParametersAfterUpdate = true
        @update()

    components:
      AttributeTableRow: AttributeTableRow
      BackLink: BackLink
      Button: Button
      DataTypeEditor: DataTypeEditor
      H3: H3
      Input: Input
      Label: Label
      Modal: Modal
      Panel: Panel
      Switch: Switch
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
      Textarea: Textarea
      Wrapper: Wrapper
