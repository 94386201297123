
  import PlatformApi from 'models/platform_api.coffee'
  import PlatformApi2 from 'models/platform_api2.coffee'
  import WhatsappTemplate from 'models/whatsapp_template'
  import WorkflowStep from 'models/workflow_step.coffee'

  import WhatsappTemplateDisplay from './whatsapp_template_display.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import WhatsappTemplateParametersEditor from './whatsapp_template_parameters_editor.vue'
  import { Listbox } from 'components/generic'

  export default
    props:
      modelValue: WorkflowStep

    data: ->
      step: @modelValue
      channels: []
      channel: null
      templates: []
      selectedTemplate: null
      WorkflowStep: WorkflowStep

    watch:
      'step.previousChannelId': ->
        @setTemplates()
      'step.language': ->
        @setTemplates()

    created: ->
      @setTemplates()

    methods:
      setTemplates: ->
        if @channels.length == 0
          @channels = await PlatformApi2.getChannels()
        @channel = @channels.find (channel) => channel.type == 'WHATSAPP' && channel.id == @step.previousChannelId
        return unless @channel?.nativeId
        PlatformApi.Webapp.getWabaTemplates(@channel.nativeId)
          .then (templateDataList) =>
            # make templates unique by name, preferring chosen language or channel's default language
            h = {}
            templateDataList.forEach (t) =>
              if !h[t.name]?
                h[t.name] = t
              else if t.language == @channel.defaultLanguage || t.language == @step.language
                h[t.name] = t
              else if t.language == @channel.defaultLanguage
                h[t.name] = t
            @templates = Object.values(h)
              .sortByString('name')
              .map (templateData) -> new WhatsappTemplate(templateData)
            @selectedTemplate = @templates.find (template) => template.name == @step.templateName
            @selectedTemplate ||= @templates[0]
      templateLabel: (template) ->
        return '&nbsp;' unless template?
        template.name.replaceAll('_', ' ').capitalize()
      update: ->
        @$emit('update:modelValue', @step)
      updateTemplate: ->
        @step.templateName = @selectedTemplate.name
        @step.templateNamespace = @selectedTemplate.namespace
        @update()

    components:
      Listbox: Listbox
      WhatsappTemplateDisplay: WhatsappTemplateDisplay
      UniversalInput: UniversalInput
      WhatsappTemplateParametersEditor: WhatsappTemplateParametersEditor
