export default
  methods:
    contextParameterByKey: (key) ->
      contextParameterKey = key.split('#')[0]
      @contextParameters.find (cp) -> cp.key == contextParameterKey
    componentByKey: (key) ->
      contextParameter = @contextParameterByKey(key)
      return unless contextParameter?.components?
      componentKey = key.split('#')[1]
      contextParameter.components.find (cmp) -> cmp.key == componentKey
    componentOrContextParameter: (key) ->
      @componentByKey(key) || @contextParameterByKey(key)
