
  import DialogTest from 'models/dialog_test.coffee'
  import DialogTestElement from 'models/dialog_test_element.coffee'
  import { alert } from 'helpers'

  import { Alert, Textarea } from 'components/generic'

  export default
    props:
      modelValue: DialogTest

    data: ->
      test: @modelValue
      debounceTimeout: null
      jsonString: ''
      errorMessage: null

    watch:
      modelValue: ->
        @test = @modelValue
      'modelValue.elements':
        deep: true
        handler: ->
          @buildJsonString()

    created: ->
      @buildJsonString()

    methods:
      buildJsonString: ->
        @jsonString = JSON.stringify(@test.elements.map((el) -> el.export), null, 2)
      parseWithDebounce: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@parse, 2000)
      parse: ->
        # parse JSON
        try
          data = JSON.parse(@jsonString)
        catch error
          @errorMessage = error.message
          return
        # check toplevel data structure
        if !(data instanceof Array)
          @errorMessage = 'Entered JSON must be an array'
          return
        # build models
        try
          @test.elements = data.map (elementData) => new DialogTestElement(@test, elementData)
        catch error # webapp-internal validations
          @errorMessage = error.message
          return
        @errorMessage = null
        @save()
      save: ->
        @test.save(noAlert: true)
          .then => @update()
          .catch (response) => @errorMessage = response.message
      update: ->
        @$emit('update:modelValue', @test)
      copyToClipboard: (e) ->
        navigator.clipboard.writeText(@jsonString)
          .then =>
            e.target.blur()
            await alert('Copied JSON to clipboard')

        navigator.clipboard.writeText(@jsonString)

    components:
      Alert: Alert
      Textarea: Textarea
