import { createApp } from 'vue_shims'

initChannelSettingsApp = (element) ->
  window.state =
    projectId: $(element).data('project-id')
    type: $(element).data('type')
    channelKey: $(element).data('channel-key')
    channelTypes: $(element).data('channel-types')
    channelName: $(element).data('channel-name')
    languages: $(element).data('languages')
    currentLanguage: Object.keys($(element).data('languages'))[0]
    intents: $(element).data('intents')
    predefinedItems: $(element).data('predefined-items')
    stages: $(element).data('stages')
    menuErrors: $(element).data('menu-errors')
    disabled: $(element).data('disabled')
    media: $(element).data('channel-types').map (medium) -> medium.toLowerCase()
    messengerLogo: $(element).data('messenger-logo')
    whatsappLogo: $(element).data('whatsapp-logo')
    cornerRadius: $(element).data('corner-radius')
    webLogo: $(element).data('web-logo')
    fontsize: $(element).data('fontsize')
    intentSignatures: $(element).data('intentSignatures')
    config: $(element).data('config')
    facebookChannels: $(element).data('facebook-channels')
    whatsappChannels: $(element).data('whatsapp-channels')

  createApp(
    data: ->
      state: state
      whitelistedDomains: $(element).data('whitelisted-domains')
      menuStructure: state.config.menu
      greeting: $(element).data('greeting')
      getStarted: $(element).data('get-started')
      languageStates: {}
      stageUuid: $(element).data('stage-uuid')
      optIn: state.config.optIn || false
      defaultLanguage: state.config.defaultLanguage || 'de'
      teaserMessages: $(element).data('teaser-messages')
      contextParameters: $(element).data('context-parameters')

    computed:
      menuOutput: ->
        JSON.stringify(
          ObjectProcessor.clone(@menuStructure)
        )
      whitelistedDomainsOutput: ->
        JSON.stringify(
          @whitelistedDomains.filter (string) -> string.length
        )
      teaserMessagesOutput: ->
        JSON.stringify(
          ObjectProcessor.clone(@teaserMessages)
        )

    methods:
      toggleOptIn: ->
        @optIn = !@optIn
      setDefaultLanguage: (value) ->
        @defaultLanguage = value
      setStageUuid: (uuid) ->
        @stageUuid = uuid
        $.getJSON Routes.data_for_channels_project_stage_path(Globals.projectId, Globals.stageLevel, @stageUuid), (data) =>
          @state.intents = data.intents
          @state.predefinedItems = data.predefined_menu_entries
          @state.intentSignatures = data.intent_signatures

    template: '
      <div>
        <general-configuration
          :webLogo="state.webLogo"
          :languages="state.languages"
          :defaultLanguage="defaultLanguage"
          :channelName="state.channelName"
          :stageUuid="stageUuid"
          :stages="state.stages"
          :optIn="optIn"
          @toggleOptIn="toggleOptIn"
          @setDefaultLanguage="setDefaultLanguage"
          @setStageUuid="setStageUuid"
        />
        <input type="hidden" name="umh_channel[config][menu]" v-model="menuOutput">
        <input type="hidden" name="umh_channel[config][teaserMessages]" v-model="teaserMessagesOutput">

        <div class="panel-body">
          <div class="form-group">
            <div class="col-sm-12" id="accordion">
              <panel-togglable :open="true" parent-selector="#accordion">
                <template v-slot:togglingheader>
                  <div class="expand">General appearance</div>
                </template>
                <template v-slot:body>
                  <general-appearance color="#000000" :cornerRadius="state.cornerRadius" />
                </template>
              </panel-togglable>

              <panel-togglable parent-selector="#accordion">
                <template v-slot:togglingheader>
                  <div class="expand">Bubble Design</div>
                </template>
                <template v-slot:body>
                  <bubble-design :cornerRadius="state.cornerRadius" :fontsize="state.fontsize" />
                </template>
              </panel-togglable>

              <panel-togglable parent-selector="#accordion">
                <template v-slot:togglingheader>
                  <div class="expand">Persistent menu</div>
                </template>
                <template v-slot:body>
                  <div v-if="state.menuErrors.length" class="alert alert-danger">
                    <p>Please fix the following error(s):</p>
                    <ul>
                      <li v-for="message in state.menuErrors">{{ message }}</li>
                    </ul>
                  </div>
                  <persistent-menu :menu-structure="menuStructure"/>
                </template>
              </panel-togglable>

              <panel-togglable parent-selector="#accordion">
                <template v-slot:togglingheader>
                  <div class="expand">Conversation & Message editor settings</div>
                </template>
                <template v-slot:body>
                  <message-editor-settings
                    :messengerLogo="state.messengerLogo"
                    :whatsappLogo="state.whatsappLogo"
                    :optIn="optIn"
                    :languages="state.languages"
                    :defaultLanguage="defaultLanguage"
                    :facebookChannels="state.facebookChannels"
                    :whatsappChannels="state.whatsappChannels"
                  />
                </template>
              </panel-togglable>

              <panel-togglable parent-selector="#accordion">
                <template v-slot:togglingheader>
                  <div class="expand">Embedding the chat widget</div>
                </template>
                <template v-slot:body>
                  <div class="form-group row">
                    <label class="col-sm-2">
                      Javascript Code
                    </label>
                    <div class="col-sm-12">
                      <new-widget-config />
                    </div>
                  </div>
                </template>
              </panel-togglable>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <teaser-message
            :defaultLanguage="defaultLanguage"
            :configTeaserMessages="teaserMessages"
            :contextParameters="contextParameters"
            />
        </div>
      </div>
    '
  ).mount(element)

$(window).on 'turbolinks:load', ->
  element = document.getElementById('web-channel-configuration-app')
  initChannelSettingsApp(element) if element?
