
  import PlatformApi2 from 'models/platform_api2.coffee'

  import UtteranceTableRow from './utterance_table_row.vue'
  import { Api2Pagination, Input, MultiselectDropdown, Spinner, Table, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'

  export default
    data: ->
      dialogModulesForSelect = @$root.activeBot.config.allModules.sortByString('label').concat([
        {key: 'HOOK'}, {key: 'CONTEXT_INITIATIVE'}
      ])
      languagesForSelect = Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}

      intents: []
      filters:
        modules: if @$route.query.modules
            moduleKeys = @$route.query.modules.split(',')
            dialogModulesForSelect.filter (module) => moduleKeys.includes(module.key)
          else
            []
        intents: if @$route.query.intents
            nodeKeys = @$route.query.intents.split(',')
            nodeKeys.map (key) -> {nodeKey: key}
          else
            []
        query: @$route.query.query || ''
        languages: if @$route.query.languages
            languagesCodes = @$route.query.languages.split(',')
            languagesForSelect.filter (l) => languagesCodes.includes(l.key)
          else
            []
      dialogModulesForSelect: dialogModulesForSelect
      languagesForSelect: languagesForSelect
      PlatformApi2: PlatformApi2

    computed:
      intentsForSelect: ->
        return @intents if @filters.modules.length == 0
        moduleKeys = @filters.modules.map((m) -> m.key)
        @intents.filter (i) -> moduleKeys.includes(i.moduleKey)
      filterParams: ->
        params =
          modules: @filters.modules.map((m) -> m.key).join(',')
          intents: @filters.intents.map((i) -> i.nodeKey).join(',')
          query: @filters.query
          languages: @filters.languages.map((l) -> l.key).join(',')
        Object.entries(params).forEach ([key, value]) -> delete params[key] if value.length == 0
        params

    created: ->
      @loadIntents()

    mounted: ->
      @loadTrainingData(page: 0, changeRoute: false)

    methods:
      loadTrainingData: ({page, changeRoute} = {}) ->
        changeRoute = if changeRoute? then changeRoute else true
        @$router.push(query: @filterParams) if changeRoute
        @$nextTick =>
          @$refs.pagination.load(page: page)
      loadIntents: ->
        @intents = (await PlatformApi2.getIntents(pageSize: 9999)).map (i) -> i.intent
        @intents.sortByString('nodeKey')
      setTableHeaderPosition: ->
        @$nextTick =>
          filterContainerTop = parseFloat(window.getComputedStyle(@$refs.filterContainer).top)
          filterContainerHeight = @$refs.filterContainer.offsetHeight
          @$refs.tableHeader.style.top = filterContainerTop + filterContainerHeight + 'px'

    components:
      Api2Pagination: Api2Pagination
      Input: Input
      MultiselectDropdown: MultiselectDropdown
      Spinner: Spinner
      Table: Table
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      UtteranceTableRow: UtteranceTableRow
