
  import ProjectUser from 'models/project_user.coffee'
  import { alert } from 'helpers'
  import { ActionMenu, Button, Listbox, MultiSelect, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: ProjectUser
      org: Object

    emits: ['update:modelValue', 'remove']

    data: ->
      user: @modelValue.clone()
      role: null
      teams: null
      selectedUser: null
      editing: false

    computed:
      teamList: ->
        @user.teams.map((team) -> team.name).join(', ')
      userList: ->
        @org.all_users.filter (user) => !@org.projectUsers.map((u) -> u.id).includes(user.id)
      roleList: ->
        @$root.roles[@user?.organizationId] || []
      actionMenuItems: ->
        mayEditRoles = Globals.currentUser.may('grant_organization_permissions', id: @role?.organizationId)
        [
          {icon: PencilIcon, label: 'Edit', method: @edit},
          {icon: PencilIcon, label: 'Edit role', method: => @editRole(@role.id)} if @role?.organizationId && mayEditRoles,
          {icon: XIcon, label: 'Remove from project', method: @remove},
        ].filter (item) -> item

    created: ->
      @setCopies()

    methods:
      setCopies: ->
        @user = @modelValue.clone()
        @selectedUser = @userList[0]
        @role = @roleList.find((role) => role.id == @user.role.id) || @roleList[0]
        @teams = @user.teams.map((team) -> team.id)
      edit: ->
        @editing = true
      cancel: ->
        @editing = false
        if @user.isNew
          @$emit('remove')
        else
          @setCopies()
      save: ->
        @user.role = {id: @role.id, name: @role.name}
        @user.teams = @$root.teams.filter (team) => @teams.includes(team.id)
        if @user.isNew
          @user.id = @selectedUser.id
          @user.name = @selectedUser.name
        @user.save()
          .then =>
            @editing = false
            @$emit('update:modelValue', @user)
          .catch (response) =>
            await alert(response.message)
      remove: ->
        @user.remove()
          .then =>
            @$emit('remove')
          .catch (response) =>
            await alert(response.message)
      editRole: (roleId) ->
        window.location = Routes.organization_path(Globals.currentUser.organizationId) +
          "/security_and_data_protection#role-#{roleId}"

    components:
      ActionMenu: ActionMenu
      Button: Button
      Listbox: Listbox
      MultiSelect: MultiSelect
      PencilIcon: PencilIcon
      TableCell: TableCell
      TableRow: TableRow
      XIcon: XIcon
