
  import Workflow from 'models/workflow'
  import WorkflowTrigger from 'models/workflow_trigger'

  import { BackLink, DocumentationLink, Input, KeyListbox, PageHeader, Panel } from 'components/generic'

  export default
    data: ->
      workflow: new Workflow()
      triggerTypes: Object.entries(WorkflowTrigger.TYPES).map ([key, config]) -> {key: key, label: config.label}

    methods:
      save: ->
        @workflow.save()
          .then =>
            @$router.push(name: 'workflow', params: {workflowId: @workflow.id})
      cancel: ->
        @$router.push(name: 'workflowList')

    components:
      BackLink: BackLink
      DocumentationLink: DocumentationLink
      Input: Input
      KeyListbox: KeyListbox
      PageHeader: PageHeader
      Panel: Panel
