Vue.component 'context-update-list',
  props: ['updates', 'contextParameters']
  methods:
    add: ->
      @updates.push(
        parameter: ''
        value: ''
      )
    remove: (index) ->
      @updates.splice(index, 1)
  template: '
    <div>
      <context-update
        v-for="(update, index) in updates"
        :key="update.$key"
        :update="update"
        :index="index"
        :context-parameters="contextParameters"
        @remove-update="remove"
        class="sub-form-group"
        />
      <plus-button label="add context update" :method="add"/>
    </div>
  '
