import ObjectProcessor from 'models/object_processor.coffee'
import DialogNode from 'models/dialog_node.coffee'
import NLG from 'models/nlg.coffee'
import Priming from 'models/priming.coffee'
import Target from 'models/dialog_target.coffee'

export default class BotIntent extends DialogNode
  constructor: (@dialogModule, data = {}) ->
    super()
    @type = 'BotIntent'
    @key = data.key
    @label = data.label || ''
    @primings = (data.primings || []).map (primingData) => new Priming(@, primingData)
    @nlg = new NLG(@, data.nlg || {})
    @sealed = if data.sealed? then data.sealed else false
    @triggeredInitiatives = data.triggeredInitiatives || []

  update: (data = {}) ->
    @key = data.key if !@key && data.key?
    @label = data.label if data.label?
    @primings = (data.primings).map((primingData) => new Priming(@, primingData)) if data.primings?
    @nlg = new NLG(@, data.nlg) if data.nlg?
    @sealed = data.sealed if data.sealed?
    @triggeredInitiatives = data.triggeredInitiatives if data.triggeredInitiatives?
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @primings = (data.primings || []).map((primingData) => new Priming(@, primingData))
    @triggeredInitiatives = data.triggeredInitiatives || []
    this

  clone: ->
    new BotIntent(@dialogModule, @export)

  addPriming: (userIntent) ->
    return if @primings.some (pr) -> pr.target.aimsAt(userIntent)
    @primings.push(
      new Priming(@, target: new Target(@).set(userIntent))
    )

  removePriming: (userIntent) ->
    @primings = @primings.filter (p) -> p.target.nodeKey != userIntent.key

  Object.defineProperties @prototype,
    color:
      get: -> 'green'
    export:
      get: ->
        type: @type
        key: @key
        label: @label
        primings: @primings.map (priming) => priming.export
        nlg: @nlg.export
        sealed: @sealed
        triggeredInitiatives: @triggeredInitiatives
    targetsWithType:
      get: ->
        buttonTargetIdentifiers = @nlg.buttons
          .filter((button) -> button.payload?)
          .map((button) -> button.payload.target.identifier)
        @primings
          .unique (priming) -> priming.target.identifier
          .map (priming) =>
            target: priming.target
            kind: if buttonTargetIdentifiers.includes(priming.target.identifier) then 'button' else 'priming'
